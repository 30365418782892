import React, { PureComponent } from 'react';
import { Tabs } from 'antd';
import classNames from 'classnames';
import './index.less';
import Breadcrumb from '../breadcrumb/index';

const { TabPane } = Tabs;

export default class PageHeader extends PureComponent {
  onChange = (key) => {
    if (this.props.onTabChange) {
      this.props.onTabChange(key);
    }
  };

  render() {
    const {
      title,
      logo,
      action,
      content,
      extraContent,
      tabList,
      className,
      tabActiveKey,
      tabDefaultActiveKey,
      tabBarExtraContent,
    } = this.props;

    const clsString = classNames('pageHeader', className);
    const activeKeyProps = {};
    if (tabDefaultActiveKey !== undefined) {
      activeKeyProps.defaultActiveKey = tabDefaultActiveKey;
    }
    if (tabActiveKey !== undefined) {
      activeKeyProps.activeKey = tabActiveKey;
    }

    return (
      <div className='page-header'>
        <div className={clsString}>
          <Breadcrumb></Breadcrumb>
          <div className={'detail'}>
            {logo && <div className={'logo'}>{logo}</div>}
            <div className={'main'}>
              <div className={'row'}>
                {title && <h1 className={'title'}>{title}</h1>}
                {action && <div className={'action'}>{action}</div>}
              </div>
              <div className={'row'}>
                {content && <div className={'content'}>{content}</div>}
                {extraContent && <div className={'extraContent'}>{extraContent}</div>}
              </div>
            </div>
          </div>
          {tabList && tabList.length && (
            <Tabs
              className={'tabs'}
              {...activeKeyProps}
              onChange={this.onChange}
              tabBarExtraContent={tabBarExtraContent}
            >
              {tabList.map((item) => (
                <TabPane tab={item.tab} key={item.key} />
              ))}
            </Tabs>
          )}
        </div>
      </div>
    );
  }
}
