//市场管理列表
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Table,
  Input,
  TreeSelect,
  Divider,
  Select,
  Popconfirm,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getMarketList, getAllDistrictInfo, updateMarket } from '../../state/action/market';
import { enableDataService } from '../../state/request/market';

import './style.less';

const FormItem = Form.Item;
const { Option } = Select;

@connect(
  (state) => {
    return { market: state.market };
  },
  { getMarketList, getAllDistrictInfo, updateMarket },
)
@Form.create()
class Market extends PureComponent {
  constructor(props) {
    super(props);

    const { searchValue, pageNo } = this.props.history.location.state || {};

    this.state = {
      loading: false,
      searchValue: searchValue || { enable: true }, //查询的数据
      pageNo: pageNo || 1, //分页
    };
  }

  componentDidMount() {
    const { searchValue } = this.props.history.location.state || {};
    this.getMarketList(
      searchValue || {
        enable: true,
        pageNo: 1,
        pageSize: 10,
      },
    );
    this.getAllDistrict();
  }
  //页面渲染获取市场列表
  async getMarketList(params) {
    this.setState({ loading: true });
    let reqParams = {};
    if (params && JSON.stringify(params) !== '{}') {
      if (params.name) {
        reqParams.name = params.name;
      }
      if (params.cityCode) {
        reqParams.cityCode = params.cityCode;
      }
      if (params.enable !== -1) {
        reqParams.enable = params.enable;
      }
      if (params.enableDataService !== -1) {
        reqParams.enableDataService = params.enableDataService;
      }
    }
    reqParams.pageNo = this.state.pageNo;
    reqParams.pageSize = 10;
    await this.props.getMarketList(reqParams);
    this.setState({ loading: false });
  }
  // 获取省市区信息
  async getAllDistrict() {
    this.setState({ loading: true });
    await this.props.getAllDistrictInfo();
    this.setState({ loading: false });
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (errors, values) => {
      if (!!errors) {
        return;
      }

      this.setState({ searchValue: values, pageNo: 1 });
      await this.getMarketList(values);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.form.setFieldsValue({
      name: '',
      cityCode: '',
      enable: true,
      enableDataService: -1,
    });
    this.setState({ searchValue: {}, pageNo: 1 });
    this.getMarketList({
      enable: true,
    });
  };

  // 修改市场enable字段
  async changeText(e, enable, id, cityCode) {
    e.preventDefault();
    await this.props.updateMarket({ cityCode, id, enable: !enable });
    this.getMarketList(this.state.searchValue);
  }

  //启用/禁用数据服务
  async enableDataService(e, record) {
    e.preventDefault();
    await enableDataService({ marketId: record.id, enableDataService: !record.enableDataService });
    this.getMarketList(this.state.searchValue);
  }

  // 修改
  toUpdate(e, id) {
    e.preventDefault();
    this.props.history.push(`/marketList/marketList/modify/${id}`);
  }
  // 展示货品管理
  toGoodsManage(e, record) {
    e.preventDefault();
    this.props.history.push({
      pathname: `/marketList/goodsManage/${record.id}`,
      state: {
        record,
        searchValue: this.state.searchValue,
        pageNo: this.state.pageNo,
      },
    });
  }

  // 新增
  toAdd = () => {
    this.props.history.push(`/marketList/marketList/modify`);
  };
  // 大数据账号的维护
  gotoBigDataAccount = (record) => {
    this.props.history.push({
      pathname: '/marketList/marketList/bigDataAccount',
      state: { record },
    });
  };
  onChangeTable = (pagination) => {
    this.setState({ pageNo: pagination.current }, function () {
      this.getMarketList(this.state.searchValue);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      market: { marketList: { lst = [], pageEntity = {} } = {}, districtInfo },
    } = this.props;
    const { searchValue } = this.state;

    var totalSize = 0;
    if (pageEntity == null) {
    } else {
      totalSize = pageEntity.totalSize;
    }
    let cityData = [];
    const { code, data } = districtInfo;
    if (code === 200) {
      cityData = data.mapping.origins;
    }

    // 表格的分页的设计
    const pagination = {
      pageSize: 10,
      current: this.state.pageNo,
      total: totalSize,
    };
    // 表格数据源
    const dataSource = lst;

    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
    const extraContent = (
      <div>
        <Form className='ant-advanced-search-form' onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col {...layout}>
              <FormItem label='市场名称'>
                {getFieldDecorator('name', {
                  initialValue: searchValue.name || '',
                })(<Input type='text' placeholder='请输入市场名称' allowClear />)}
              </FormItem>
            </Col>
            <Col {...layout}>
              <FormItem label='所在城市'>
                {getFieldDecorator('cityCode', {
                  initialValue: searchValue.cityCode || '',
                })(
                  <TreeSelect
                    allowClear
                    showSearch
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={cityData}
                    placeholder='请选择城市'
                    treeDefaultExpandAll={false}
                    filterTreeNode={(input, treeNode) => {
                      return treeNode.props.title.indexOf(input) >= 0;
                    }}
                  />,
                )}
              </FormItem>
            </Col>
            <Col {...layout}>
              <FormItem label='市场状态'>
                {getFieldDecorator('enable', {
                  initialValue: searchValue.enable || true,
                })(
                  <Select>
                    <Option value={-1}>全部</Option>
                    <Option value={true}>启用</Option>
                    <Option value={false}>禁用</Option>
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...layout}>
              <FormItem label='数据服务'>
                {getFieldDecorator('enableDataService', {
                  initialValue: searchValue.enableDataService || -1,
                })(
                  <Select>
                    <Option value={-1}>全部</Option>
                    <Option value={true}>启用</Option>
                    <Option value={false}>禁用</Option>
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={24}>
            <FormItem>
              <Button htmlType='submit' type='primary'>
                查询
              </Button>
              <Button onClick={this.handleReset} style={{ marginLeft: 25 }}>
                重置
              </Button>
              <Button type='primary' onClick={this.toAdd} style={{ marginLeft: 25 }}>
                新增
              </Button>
            </FormItem>
          </Row>
        </Form>
      </div>
    );
    return (
      <div className='market-index'>
        <PageHeaderLayout>
          <div className={'marketList'}>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.onChangeTable}
              >
                <Col align='center' title='市场ID' dataIndex='id' key='id' />
                <Col align='center' title='市场名称' dataIndex='name' key='name' />
                <Col
                  align='center'
                  title='所属城市'
                  dataIndex='provinceName'
                  key='provinceName'
                  render={(_, record) => {
                    const { provinceName = '', cityName = '' } = record;
                    return `${provinceName}${cityName}`;
                  }}
                />
                <Col align='center' title='地址' dataIndex='address' key='address' />
                <Col
                  align='center'
                  title='市场类型'
                  dataIndex='marketType'
                  key='marketType'
                  render={(_, record) => {
                    let marketType =
                      record.marketType === 1
                        ? '蔬菜'
                        : record.marketType === 5
                        ? '水果'
                        : record.marketType === 10
                        ? '水产'
                        : record.marketType === 15
                        ? '二批'
                        : '蔬菜';
                    return marketType;
                  }}
                />
                <Col align='center' title='市场规模(档口数)' dataIndex='stallNum' key='stallNum' />
                <Col
                  align='center'
                  title='市场状态'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    let statusStr = '禁用';
                    if (record.enable) {
                      statusStr = '启用';
                    }
                    return statusStr;
                  }}
                />
                <Col
                  align='center'
                  title='数据服务'
                  dataIndex='enableDataService'
                  key='enableDataService'
                  render={(text) => {
                    return text ? '启用' : '禁用';
                  }}
                />
                <Col
                  align='center'
                  title='操作'
                  dataIndex='operation'
                  key='operation'
                  render={(_, record) => {
                    const { id, cityCode, enable, enableDataService } = record;

                    return (
                      <div>
                        <Popconfirm
                          title={`确定${enable ? '禁用' : '启用'}？`}
                          onConfirm={(e) => this.changeText(e, enable, id, cityCode)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/' className={`${enable ? 'close' : 'open'}`}>
                            <span>{enable ? '禁用' : '启用'}</span>
                          </a>
                        </Popconfirm>
                        <Divider type='vertical' />
                        {!enable ? (
                          <span>修改</span>
                        ) : (
                          <a href='/' onClick={(e) => this.toUpdate(e, id)}>
                            修改
                          </a>
                        )}
                        <Divider type='vertical' />
                        {!enable ? (
                          <span>大数据账号</span>
                        ) : (
                          <a
                            href='/'
                            onClick={(e) => {
                              e.preventDefault();
                              this.gotoBigDataAccount(record);
                            }}
                          >
                            大数据账号
                          </a>
                        )}
                        <Divider type='vertical' />
                        <Popconfirm
                          title={`确定${enableDataService ? '禁用' : '启用'}？`}
                          onConfirm={(e) => this.enableDataService(e, record)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/' className={`${enableDataService ? 'close' : 'open'}`}>
                            {`${enableDataService ? '禁用' : '启用'}数据服务`}
                          </a>
                        </Popconfirm>

                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.toGoodsManage(e, record);
                          }}
                        >
                          展示货品管理
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default Market;
