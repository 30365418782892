import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, Input, Switch, message, Space, Popconfirm } from 'antd-v5';
import moment from 'moment';
import PageHeaderLayout from '@/component/page-header-layout';
import { useImmer } from 'use-immer';

import UploadFile from '@/component/upload';
import HtmlPreview from './components/htmlPreview';
import Editor from './components/editor';

import {
  getNoticeList,
  deleteNotice,
  updateNotice,
  addNotice,
  getNoticeDetail,
} from '@/state/request/notice';

import './index.less';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

let _modal = null;

const SystemAnnouncementManagement = () => {
  const [list, setList] = useState([]);
  const [store, setStore] = useImmer({
    open: false,
    data: null,
  });
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const ref = useRef();

  const getData = async () => {
    const res = await getNoticeList();
    if (res?.code === 200) {
      setList(res?.data?.list || []);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (store.open) {
      if (store.data?.id) {
        form.setFieldsValue(store.data);
        setTimeout(() => {
          ref.current?.set(store.data.contentUrl);
        }, 10);
      } else {
        form.setFieldsValue({
          stick: false,
        });
      }
    }
  }, [store.open]);

  const deleteOneAnnouncement = async (id) => {
    await deleteNotice(id);
    getData();
  };

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      render: (_, __, index) => index + 1,
    },
    {
      title: '标题',
      dataIndex: 'title',
      width: 260,
    },
    {
      title: '是否置顶',
      dataIndex: 'stick',
      width: 90,
      render: (stick) => (stick ? '是' : '否'),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      render: (createTime) => (createTime ? moment(createTime).format('YYYY-MM-DD HH:mm') : '--'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_, record) => (
        <Space>
          <span
            className='btn'
            style={{ color: 'green' }}
            onClick={async () => {
              const res = await getNoticeDetail(record.id);
              if (res?.code === 200) {
                setStore({ open: true, data: res?.data || {} });
              }
            }}
          >
            编辑
          </span>
          <Popconfirm title='是否要删除系统公告' onConfirm={() => deleteOneAnnouncement(record.id)}>
            <span className='btn' style={{ color: 'red' }}>
              删除
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const clearEditor = () => {
    ref.current?.clear();
  };

  const handleOk = () => {
    const editorState = ref.current?.get();
    const title = form.getFieldValue('title');
    const coverImgUrl = form.getFieldValue('coverImgUrl');
    const createTime = store.data?.id ? store.data?.createTime : null;
    if (!title) {
      message.error('请输入公告标题');
      return;
    }
    if (!coverImgUrl) {
      message.error('请上传封面图片');
      return;
    }
    if (_modal) {
      return;
    }
    _modal = modal.confirm({
      destroyOnClose: true,
      title: null,
      icon: null,
      content: (
        <HtmlPreview title={title} createTime={createTime} htmlContent={editorState?.toHTML()} />
      ),
      footer: () => {
        return [
          <div className='footer'>
            <Button
              className='btn'
              onClick={() => {
                _modal?.destroy();
                _modal = null;
              }}
            >
              返回编辑
            </Button>
            <Button
              type='primary'
              className='btn'
              onClick={() => {
                onFinish();
              }}
            >
              确定保存
            </Button>
          </div>,
        ];
      },
      style: {
        top: 20,
      },
    });
    clearEditor();
  };

  const onFinish = async () => {
    const editorState = ref.current?.get();
    const values = form.getFieldsValue();
    const params = {
      ...values,
      content: editorState?.toHTML(),
    };
    let res = {};
    if (store.data?.id) {
      res = await updateNotice(params);
    } else {
      res = await addNotice(params);
    }
    if (res.code === 200) {
      _modal.destroy();
      _modal = null;
      setStore({ open: false, data: null });
      getData();
    }
  };

  return (
    <PageHeaderLayout>
      <div className='system-announcement-management'>
        <Button
          type='primary'
          onClick={() => {
            setStore({ open: true, data: null });
          }}
          style={{ marginBottom: 20 }}
        >
          新增公告
        </Button>
        <Table
          rowKey='id'
          columns={columns}
          dataSource={list || []}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Modal
          title={store.data?.id ? '编辑公告' : '新增公告'}
          destroyOnClose
          open={store.open}
          onOk={handleOk}
          onCancel={() => {
            setStore({ open: false, data: null });
            clearEditor();
          }}
          width={1200}
          centered
        >
          <Form form={form} {...layout} onFinish={onFinish} preserve={false}>
            {store.data?.id && <Form.Item name='id' hidden />}
            <Form.Item
              label='公告标题'
              name='title'
              rules={[{ required: true, message: '请输入公告标题' }]}
            >
              <Input maxLength={30} placeholder='请输入公告标题' />
            </Form.Item>
            <Form.Item label='置顶' name='stick' valuePropName='checked'>
              <Switch />
            </Form.Item>
            <Form.Item
              label='封面图片'
              name='coverImgUrl'
              rules={[{ required: true, message: '请上传封面图片' }]}
            >
              <UploadFile
                action='file/uploadSysNoticeImg'
                url={store.data?.coverImgUrl}
                onChange={() => {}}
              />
            </Form.Item>
            <Form.Item
              label='公告摘要'
              name='resume'
              rules={[{ required: true, message: '请输入公告摘要' }]}
            >
              <Input maxLength={40} placeholder='请输入公告摘要' />
            </Form.Item>
            <Form.Item label='内容'>
              <Editor ref={ref} />
            </Form.Item>
          </Form>
        </Modal>
        {contextHolder}
      </div>
    </PageHeaderLayout>
  );
};

export default SystemAnnouncementManagement;
