//业务支付状态
export const payStatus = [
  [0, '待支付'],
  [1, '支付成功'],
  [2, '验签失败'],
  [3, '支付失败'],
  [4, '业务失败'],
];
const payStatusMap = new Map(payStatus);

export function getPayStatus(key) {
  return payStatusMap.get(key) || '';
}
//渠道支付状态
export const chanelPayStatus = [
  [0, '待支付'],
  [1, '支付成功'],
  [2, '支付失败'],
];
const chanelPayStatusMap = new Map(chanelPayStatus);
export function getChanelPayStatus(key) {
  return chanelPayStatusMap.get(key) || '';
}

const payBadgeMap = new Map([
  [0, 'lime'],
  [1, 'green'],
  [2, 'volcano'],
  [3, 'red'],
]);
//状态提示小图
export function getPayBadge(key) {
  return payBadgeMap.get(key) || 'cyan';
}
