/**
 * 系统配置
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Input,
  Tooltip,
  Table,
  Modal,
  message,
  Popconfirm,
  Divider,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';

import '../booth-management/index.less';
import { trim } from '../../utils/utils';
import { fileSelectList } from '../../state/action/batch';
import { getAllSysConfigPage, fetchConfig, saveOrUpdateConfig } from '../../state/action/stall';

const FormItem = Form.Item;

const { Column } = Table;
const { TextArea } = Input;

@connect(
  (state) => {
    return { stall: state.stall, batch: state.batch };
  },
  { fileSelectList, getAllSysConfigPage, fetchConfig, saveOrUpdateConfig },
)
@Form.create()
class BoothSysConfig extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      visibleModal: false,
      value: 1,
      id: '',
      type: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchAllConfig({
        pageNo: 1,
        pageSize: 10,
      }),
      this.props.fileSelectList(),
    ]);
    this.setState({ config: {} });
  }

  async fetchAllConfig(params) {
    await this.props.getAllSysConfigPage(params);
  }

  // 获取档口修改数据
  async fetchConfig(params) {
    await this.props.fetchConfig(params);
  }

  // 修改档口配置
  async saveOrUpdate(params) {
    await this.props.saveOrUpdateConfig(params);
    this.submit();
  }

  // 分页和查询请求
  modifyStatusBtn() {
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const searchDisplayName = fieldsValue.searchDisplayName;
      const searchConfigKey = fieldsValue.searchConfigKey;
      this.fetchAllConfig({
        searchDisplayName: searchDisplayName,
        searchConfigKey: searchConfigKey,
        pageNo: 1,
        pageSize: 10,
      });
    });
  }

  // 商行选择onchange事件
  onChange = (value) => {
    this.setState({
      configUuid: value,
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const searchDisplayName = fieldsValue.searchDisplayName;
      const searchConfigKey = fieldsValue.searchConfigKey;
      this.fetchAllConfig({
        searchDisplayName: searchDisplayName,
        searchConfigKey: searchConfigKey,
        pageSize: obj.pageSize,
        pageNo: obj.current,
      });
    });
  };

  // modal关闭事件
  handleCancel(e) {
    this.setState({
      visibleModal: false,
    });
  }

  // 修改数据
  modify(e, id) {
    e.preventDefault();
    this.fetchConfig({ id });
    this.setState({
      visibleModal: true,
      type: 'modify',
      id,
    });
  }

  // 修改信息的回调
  submit() {
    const {
      stall: { saveOrUpdateData },
    } = this.props;

    if (saveOrUpdateData.code === 200) {
      message.success('操作成功');
    } else {
      message.error(saveOrUpdateData.msg);
    }
    this.modifyStatusBtn();
  }

  // 删除档口信息回调
  delete() {
    const {
      stall: { stallUpdateData },
    } = this.props;

    if (stallUpdateData === 200) {
      message.info('删除成功');
    } else {
      message.info('删除失败');
    }
    this.modifyStatusBtn();
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      if (err) {
        return;
      }
      const { configKey, configValue, displayName, enable, remark } = fieldsValue;
      this.saveOrUpdate({
        id: this.state.type === 'add' ? null : this.state.id,
        channel: 'all',
        configKey: trim(configKey),
        configValue: trim(configValue),
        displayName: displayName,
        enable: enable,
        remark: remark,
        channelVersion: 'all',
        configUuid: this.state.config.configUuid,
      });

      this.setState({
        visibleModal: false,
      });
    });
  };

  // modal radio 单选事件
  selectRadio = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  // 新增档口配置
  add() {
    this.setState({
      visibleModal: true,
      type: 'add',
      id: null,
    });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      selectValue: '',
    });
  };

  // 启用/禁用档口分组
  updGroup(e, id, enable) {
    e.preventDefault();
    this.saveOrUpdate({
      id: id,
      enable: enable,
      flag: 1,
    });
  }

  render() {
    let {
      stall: { allConfig, getConfigData },
      batch: { fileSelectListData },
    } = this.props;
    if (allConfig == null) {
      allConfig = {};
    }
    const { pageEntity } = allConfig;
    const stallListData = allConfig.lst;
    const { getFieldDecorator } = this.props.form;
    let data = [];
    let total = 0;
    if (pageEntity) {
      total = pageEntity.totalSize;
    }

    if (getConfigData !== undefined && getConfigData !== null && this.state.type !== 'add') {
      data = getConfigData;
    }
    let { configKey, configValue, displayName, remark } = data;

    if (
      fileSelectListData !== null &&
      fileSelectListData !== undefined &&
      fileSelectListData.length
    ) {
      let tmpFileSelectListData = [];
      const tmp = { id: 0, name: '所有' };
      tmpFileSelectListData.push(tmp);
      for (let i = 0; i < fileSelectListData.length; i++) {
        tmpFileSelectListData.push(fileSelectListData[i]);
      }
      fileSelectListData = tmpFileSelectListData;
    }

    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 6,
      style: { marginBottom: 12 },
    };

    const extraContent = (
      <div className={'searchModule'}>
        <Form
          onSubmit={this.modifyStatusBtn}
          className='login-form'
          style={{ float: 'left', width: '100%' }}
        >
          <Row gutter={24}>
            <Col {...topColResponsiveProps}>
              <FormItem>
                {getFieldDecorator('searchDisplayName', {
                  rules: [{ required: false, message: '请输入配置名称!' }],
                })(<Input placeholder='请输入配置名称' />)}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps}>
              <FormItem>
                {getFieldDecorator('searchConfigKey', {
                  rules: [{ required: false, message: '请输入KEY!' }],
                })(<Input placeholder='请输入KEY' />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <Button type='primary' onClick={this.modifyStatusBtn.bind(this)}>
                查询
              </Button>
              <Button onClick={this.handleReset}>重置</Button>
              <Button type='primary' onClick={this.add.bind(this)}>
                新增
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const pagination = {
      pageSize: 10,
      total: total,
    };

    return (
      <div className='booth-index'>
        <PageHeaderLayout>
          <div className={'booth-sys-config'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={stallListData}
                pagination={pagination}
                bordered
                onChange={this.pageSwitch.bind(this)}
                scroll={{ x: '1400px' }}
              >
                <Column title='key' dataIndex='configKey' key='configKey' />
                <Column title='value' dataIndex='configValue' key='configValue' />
                <Column title='名称' dataIndex='displayName' key='displayName' />
                <Column title='平台' dataIndex='channel' key='channel' />
                <Column title='版本' dataIndex='channelVersion' key='channelVersion' />
                <Column
                  title='是否启用'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const { enable } = record;
                    return <span>{enable ? '是' : '否'}</span>;
                  }}
                />
                <Column
                  title='备注'
                  dataIndex='remark'
                  key='remark'
                  render={(text) => {
                    let txtVal = text;
                    if (text.length > 20) {
                      txtVal = text.substring(0, 20) + '...';
                    }
                    return (
                      <Tooltip placement='topLeft' title={text} arrowPointAtCenter>
                        {' '}
                        {txtVal}{' '}
                      </Tooltip>
                    );
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    const { id, enable } = record;
                    const title = `确定禁用当前配置?`;
                    return (
                      <div>
                        <a href='/' onClick={(e) => this.modify(e, id)}>
                          <span>{'修改'}</span>
                        </a>
                        <Divider type='vertical' />
                        <a href='/' disabled={enable} onClick={(e) => this.updGroup(e, id, true)}>
                          <span>{'启用'}</span>
                        </a>
                        <Divider type='vertical' />
                        <Popconfirm
                          title={title}
                          onConfirm={(e) => this.updGroup(e, id, false)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/' disabled={!enable}>
                            <span>{'禁用'}</span>
                          </a>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Card>
              <Modal
                title={this.state.type === 'add' ? '新增档口配置' : '修改档口配置'}
                visible={this.state.visibleModal}
                footer={null}
                onCancel={this.handleCancel}
                destroyOnClose='true'
                width='600px'
              >
                <div className={'extraContent'} style={{ width: '100%' }}>
                  <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label='key'>
                      {getFieldDecorator('configKey', {
                        initialValue: configKey,
                        rules: [
                          {
                            required: true,
                            message: ' 请输入key!',
                          },
                        ],
                      })(
                        <Input
                          readOnly={this.state.type === 'modify' ? true : false}
                          placeholder='请输入key'
                        />,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='value'>
                      {getFieldDecorator('configValue', {
                        initialValue: configValue,
                        rules: [
                          {
                            required: true,
                            message: ' 请输入value!',
                          },
                        ],
                      })(<Input placeholder='请输入value' />)}
                    </FormItem>

                    <FormItem {...formItemLayout} label='名称'>
                      {getFieldDecorator('displayName', {
                        initialValue: displayName,
                        rules: [
                          {
                            required: true,
                            message: '请输入名称!',
                          },
                        ],
                      })(<Input placeholder='请输入名称' />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label='配置描述'>
                      {getFieldDecorator('remark', {
                        initialValue: remark,
                        rules: [
                          {
                            required: true,
                            message: '请输入配置描述!',
                          },
                        ],
                      })(<TextArea placeholder='请输入名称' />)}
                    </FormItem>

                    <FormItem>
                      <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>
                          保存
                        </Button>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default BoothSysConfig;
