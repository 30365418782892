import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Input,
  Select,
  Table,
  Divider,
  message,
  InputNumber,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  getConfigGroup,
  getMarketList,
  getStallList,
  getAllDistrict,
  saveOrUpdateConfigGroup,
  resetFields,
} from '../../state/action/basic';

import EditGroup from './editGroup';

import './style.less';

const FormItem = Form.Item;
const { Option } = Select;
const { Column } = Table;

@connect(
  (state) => {
    return { basic: state.basic };
  },
  {
    getConfigGroup,
    getMarketList,
    getStallList,
    getAllDistrict,
    saveOrUpdateConfigGroup,
    resetFields,
  },
)
@Form.create()
class ConfigGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      loading: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    const {
      basic: { getConfigGroupParams = {} },
    } = this.props;
    const { pageSize = 10, pageNo = 1, ...other } = getConfigGroupParams;
    this.props.form.setFieldsValue({ ...other });
    this.getConfigGroup({ pageSize, pageNo });
    this.getAllDistrict({});
    this.getMarketList({});
  }

  componentWillUnmount() {}

  /***
   * 查询部分
   * **/
  async getConfigGroup(value) {
    const values = this.props.form.getFieldsValue();
    const { groupName, remark, stallName, searchMarketId, stallId } = values || {};
    const params = {
      groupName,
      remark,
      stallName,
      searchMarketId,
      stallId,
      ...value,
    };
    this.setState({ loading: true });
    await this.props.getConfigGroup(params);
    this.setState({ loading: false, pageNo: value.pageNo });
  }
  // 获取市场列表
  async getMarketList(value) {
    await this.props.getMarketList(value);
  }
  // 获取档口列表
  async getStallList(value) {
    await this.props.getStallList(value);
  }
  // 获取省市区信息
  async getAllDistrict(value) {
    await this.props.getAllDistrict(value);
  }
  // 查询搜索
  searchSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['stallId'], (errors, values) => {
      if (!!errors) {
        return;
      }
      let keyWords = {
        pageSize: 10,
        pageNo: 1,
      };
      // 调用接口
      this.getConfigGroup(keyWords);
    });
  };
  // 重置输入框
  searchReset = () => {
    this.props.form.resetFields();
    this.props.resetFields('getConfigGroupParams');
    this.getMarketList({});
    this.getConfigGroup({ pageSize: 10, pageNo: 1 });
  };
  // 分页查询
  pageChange = (obj) => {
    this.getConfigGroup({
      pageNo: obj.current,
      pageSize: obj.pageSize,
    });
  };

  /***
   * 修改或者查询的接口
   * **/
  async saveOrUpdateConfigGroup(value) {
    await this.props.saveOrUpdateConfigGroup(value);
    this.setState({
      loading: false,
    });
    message.success('操作成功!');
    this.getConfigGroup({ pageSize: 10, pageNo: 1 });
  }

  // 点击新建按钮修改的modal变量
  showModal = () => {
    if (this.ref.current && this.ref.current.show) {
      this.ref.current.show();
    }
  };

  // 市场选择框的onchange事件
  selectMarketEvent = (value) => {
    let keyCode = {
      marketId: value,
    };
    this.getStallList(keyCode);
    //清空打印机选项，反正连续添加同一个打印机
    this.props.form.setFieldsValue({
      stallName: '',
      reqStallId: '',
    });
  };

  // 点击table的启用停用事件
  updateConfigGroupByIdEvent = (e, enable, id) => {
    let keyWord = {
      enable: !enable,
      id,
    };
    this.saveOrUpdateConfigGroup(keyWord);
  };
  // 点击table的修改事件
  showUpdateModalEvent = (e, record) => {
    if (this.ref.current && this.ref.current.show) {
      this.ref.current.show(record);
    }
  };
  //点击分组配置跳转页面
  groupConfigEvent = (data) => {
    this.props.history.push({ pathname: '/count/configGroup/groupConfig', state: { data } });
  };
  //点击默认值显示配置
  configGroupEvent = (id) => {
    this.props.history.push({ pathname: '/count/configGroup/configGroupDisplay', state: { id } });
  };
  render() {
    const { loading } = this.state;
    // 市场列表
    let marketList = [];
    if (this.props.basic.marketList.code === 200) {
      marketList = this.props.basic.marketList.data;
    }
    // 市场列表
    const marketOption = marketList.map((market) => {
      return (
        <Option value={market.id} key={market.id}>
          {market.name}
        </Option>
      );
    });

    const {
      basic: { getConfigGroup },
    } = this.props;
    let configGroupList = getConfigGroup.lst ? getConfigGroup.lst : [];
    let totalSize = getConfigGroup.pageEntity ? getConfigGroup.pageEntity.totalSize : 0;

    const pagination = {
      current: this.state.pageNo,
      pageSize: 10,
      total: totalSize,
    };
    const { getFieldDecorator } = this.props.form;
    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
    const extraContent = (
      <Form
        onSubmit={this.searchSubmit}
        layout='inline'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Row>
          <Col {...layout}>
            <FormItem label='商行ID'>
              {getFieldDecorator('stallId', {
                rules: [{ required: false, message: '请输入商行ID' }],
              })(
                <InputNumber
                  placeholder='请输入商行ID'
                  allowClear
                  min={1}
                  controls={false}
                  style={{ width: '100%' }}
                />,
              )}
            </FormItem>
          </Col>
          <Col {...layout}>
            <FormItem label='市场'>
              {getFieldDecorator('searchMarketId', {
                rules: [{ required: false, message: '请选择市场!' }],
              })(
                <Select
                  placeholder='请选择市场'
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  onChange={this.selectMarketEvent}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {marketOption ? marketOption : ''}
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col {...layout}>
            <FormItem label='商行名称'>
              {getFieldDecorator('stallName', {
                rules: [{ required: false, message: '请输入商行名称' }],
              })(<Input placeholder='请输入商行名称' allowClear />)}
            </FormItem>
          </Col>
          <Col {...layout}>
            <FormItem label='分组名'>
              {getFieldDecorator(
                'groupName',
                {},
              )(<Input style={{ width: '100%' }} placeholder='请输入分组名' />)}
            </FormItem>
          </Col>
          <Col {...layout}>
            <FormItem label='备注'>
              {getFieldDecorator(
                'remark',
                {},
              )(<Input style={{ width: '100%' }} placeholder='请输入备注' />)}
            </FormItem>
          </Col>
        </Row>
        <br />
        <Row>
          <Button type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={this.searchReset}>重置</Button>
          <Button onClick={this.showModal}>新建</Button>
        </Row>
      </Form>
    );
    const dataSource = configGroupList;

    return (
      <div className='configGroup-index'>
        <PageHeaderLayout>
          <div className='configGroup'>
            <Card className='listCard' bordered={false} title='' extra={extraContent}>
              <Table
                dataSource={dataSource}
                bordered={false}
                pagination={pagination}
                loading={loading}
                rowKey='id'
                onChange={this.pageChange.bind(this)}
              >
                <Column title='商行ID' dataIndex='stallId' />
                <Column title='市场' dataIndex='stallMarketName' />
                <Column title='商行名称' dataIndex='stallName' />
                <Column title='分组名' dataIndex='groupName' />
                <Column title='备注' dataIndex='remark' />
                <Column
                  title='状态'
                  dataIndex='enables'
                  render={(_, record) => {
                    const { enable } = record;
                    return enable ? '启用' : '禁用';
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='enable'
                  render={(_, record) => {
                    const { enable, stallId, id } = record;
                    let data = stallId + '&&' + id;
                    return (
                      <div>
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.updateConfigGroupByIdEvent(e, enable, id);
                          }}
                        >
                          {enable ? '禁用' : '启用'}
                        </a>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.showUpdateModalEvent(e, record);
                          }}
                        >
                          修改
                        </a>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.groupConfigEvent(data);
                          }}
                        >
                          分组配置
                        </a>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.configGroupEvent(id);
                          }}
                        >
                          默认值显示配置
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <EditGroup
              ref={this.ref}
              refresh={() => {
                const {
                  basic: { getConfigGroupParams = {} },
                } = this.props;
                const { pageSize = 10, pageNo = 1 } = getConfigGroupParams;
                this.getConfigGroup({ pageSize, pageNo });
              }}
            />
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default ConfigGroup;
