import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Input, Table, DatePicker, message, Card, Select } from 'antd';
import PageHeaderLayout from '../../../component/page-header-layout/index';
import { fetchPaymentRecord } from '../../../state/action/bank';
import { exportBankPayment } from '../../../state/request/bank';
import { generalParamsWithPToStr } from '../../../utils/math';
import { API_ADMIN } from '../../../utils/urls';
import { exportFile } from '../../../utils/utils';

import './index.less';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

@connect(
  (state) => {
    let { pageList, loading, ...pagination } = state.bank;
    return { pageList, loading, pagination };
  },
  { fetchPaymentRecord },
)
@Form.create()
class BankPayment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: null,
      pageNo: 1,
      pageSize: 10,
    };
    this.pageSwitch = this.pageSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.doRequest = this.doRequest.bind(this);
    this.disabledStartDate = this.disabledStartDate.bind(this);
    this.typeList = [
      { value: null, desc: '全部' },
      { value: 1, desc: '远程支付' },
      { value: 2, desc: '助农经营款' },
      { value: 8, desc: '诉讼催收款' },
    ];
  }

  //初始加载
  componentDidMount() {
    this.doRequest(1);
  }

  doRequest(pageNo) {
    this.setState({
      pageNo: pageNo,
    });
    const { form } = this.props;
    const { pageSize } = this.state;
    form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const rangeValue = fieldsValue['range-picker'];
      const { stallId, stallName, type } = fieldsValue;

      let startDT = rangeValue[0];
      let endDT = rangeValue[1];

      if (endDT.diff(startDT, 'months') > 0) {
        message.warn('查询时间不能超过1个月');
        return;
      }
      // 还款记录流水
      this.props.fetchPaymentRecord({
        type,
        stallId,
        stallName,
        startTime: startDT.startOf('day').format('x'),
        endTime: moment(endDT).add(1, 'days').startOf('day').format('x'),
        pageNo: pageNo,
        pageSize: pageSize,
      });
    });
  }

  //提交请求
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ pageNo: 1 });
    this.doRequest(1);
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.doRequest(obj.current);
  };
  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.doRequest(1);
  };
  exportFile = async () => {
    this.props.form.validateFields(async (errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      const key = 'range-picker';
      const { [key]: date, ...rest } = fieldsValue;

      const startDT = date[0];
      const endDT = date[1];

      if (endDT.diff(startDT, 'months') > 0) {
        message.warn('导出时间不能超过1个月');
        return;
      }
      const response = await exportBankPayment({
        startTime: startDT.startOf('day').format('x'),
        endTime: moment(endDT).add(1, 'days').startOf('day').format('x'),
        ...rest,
      });
      exportFile(response, '支付/借贷自助进件统计.xlsx');
    });
  };

  disabledStartDate = (startValue) => {
    return startValue > new Date().getTime(); //大于今天的日期一律返回true，禁止选择
  };

  getExtraContent() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <div className='wrapper'>
        <Form layout='inline' onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('range-picker', {
              rules: [{ type: 'array', required: true, message: '请输入查询时间!' }],
              initialValue: [moment(), moment()],
            })(
              <RangePicker
                format='YYYY-MM-DD'
                disabledDate={this.disabledStartDate}
                ranges={{
                  今天: [moment(), moment()],
                  当月: [moment().startOf('month'), moment()],
                }}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('type', {
              rules: [{ required: false, message: '请选择申请类型!' }],
              initialValue: null,
            })(
              <Select style={{ width: 200 }} placeholder='请选择申请类型'>
                {this.typeList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.desc}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('stallId', {
              rules: [
                {
                  required: false,
                  message: '请输入商行ID',
                },
                {
                  pattern: /^\d+$/,
                  message: '只能输入数字!',
                },
              ],
            })(<Input placeholder='请输商行ID' allowClear autoComplete='off' />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('stallName', {
              rules: [{ required: false, message: '请输商行名称!' }],
            })(<Input placeholder='请输商行名称' allowClear autoComplete='off' />)}
          </FormItem>
          <FormItem>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button style={{ marginLeft: '18px' }} onClick={this.handleReset}>
              重置
            </Button>
            <Button style={{ marginLeft: '18px' }} onClick={this.exportFile}>
              导出
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }

  render() {
    const { pageList = [], pagination = {}, loading } = this.props;
    const extraContent = this.getExtraContent();

    let repayRecordList = pageList;
    const pagination2 = {
      pageSize: 10,
      current: pagination.pageNo,
      total: pagination.totalSize,
    };

    const downloadFile = (id, stallName) => {
      let params = {};
      window.open(
        API_ADMIN +
          'payEntryDataResource/download/' +
          id +
          '?stallName=' +
          stallName +
          '&' +
          generalParamsWithPToStr('payEntryDataResource/download/' + id, params),
      );
    };
    const columns = [
      {
        title: '时间',
        dataIndex: 'createTime',
        width: 200,
        render: (text) => {
          return (
            <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              {moment(text).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          );
        },
      },
      {
        title: '来源',
        dataIndex: 'source',
        width: 120,
        render: (text) => {
          return text === 'INTEGRATIONAPP' || text === 'APP'
            ? 'APP'
            : text === 'android'
            ? 'POS'
            : text === 'PURCHASEAPI'
            ? '小程序'
            : '';
        },
      },
      {
        title: '申请类型',
        dataIndex: 'type',
        width: 120,
        render: (text) => this.typeList.find((item) => item.value === text)?.desc || '',
      },
      {
        title: '商行ID',
        dataIndex: 'stallId',
        width: 180,
      },
      {
        title: '商行名称',
        dataIndex: 'stallName',
        width: 180,
      },
      {
        title: '联系人姓名',
        dataIndex: 'contactName',
        width: 180,
      },
      {
        title: '联系人手机号',
        dataIndex: 'contactPhone',
        width: 180,
      },
      {
        title: '追讨金额',
        dataIndex: 'creditAmount',
        width: 180,
      },
      {
        title: '操作',
        dataIndex: 'id',
        width: 280,
        render: (_, record) => {
          return (
            <Button
              type='link'
              size='small'
              disabled={!record.fileAppend}
              style={{ margin: 0, border: 'none' }}
              onClick={() => downloadFile(record.id, record.stallName)}
            >
              下载申请照片
            </Button>
          );
        },
      },
    ];
    return (
      <PageHeaderLayout>
        <Card bordered={false}>
          {extraContent}
          <Table
            rowKey={(record) => record.id}
            dataSource={repayRecordList}
            columns={columns}
            pagination={pagination2}
            onChange={this.pageSwitch}
            bordered
            loading={loading}
            scroll={{ x: true }}
          />
        </Card>
      </PageHeaderLayout>
    );
  }
}
export default BankPayment;
