import React from 'react';
import {Table, Button} from 'antd';

const uuidv4=()=>{
  let timeKey=performance.now();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,c=>{
    const r=(timeKey+Math.random()*16)%16|0;
    timeKey=Math.floor(timeKey/16);
    return (c==='x'?r:(r&0x3|0x8)).toString(16);
  });
};

const Index = ({list, isPending, actionList, columns}) => <div className="list-container">
  {
    actionList?.length?<div className="list-actions">
      {
        actionList?.length?<div className="action-item">
          {
            actionList.map(({key, type, label, icon, action, disabled})=><Button key={key} loading={isPending} onClick={e=>action()} type={type} icon={icon} disabled={disabled}>
              {label}
            </Button>)
          }
        </div>:null
      }
    </div>:null
  }
  <div className="list-wrap">
    <Table columns={columns} dataSource={list ?? []} loading={isPending} size="small" bordered rowKey={() => uuidv4()} />
  </div>
</div>;

export default Index;
