import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Form, Input, Spin, Space, Button, Select, message } from 'antd-v5';

import { positionEnum } from '../../../add-services/enum';
import UploadFile from '../../../../component/upload';
import { getAdvertiseAdd, getAdvertiseUpdate } from '../../../../state/request/serve';

import './index.less';

const { Option } = Select;

const AddNewEditor = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const onFinish = async (values) => {
    const api = data.id ? getAdvertiseUpdate : getAdvertiseAdd;
    const res = await api({ ...values });
    if (res.code === 200) {
      message.info(`${data.id ? '编辑' : '新增'}广告成功`);
      setOpen(false);
      props.refresh && props.refresh();
    }
  };

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      if (data) {
        form.setFieldsValue(data);
        setData(data);
      } else {
        setData({});
      }
    },
  }));

  const ExtraContent = () => {
    return (
      <Form
        className='add-new-editor-form'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        layout='horizontal'
        preserve={false}
        onFinish={onFinish}
        initialValues={{
          type: 1,
        }}
      >
        {data.id && <Form.Item name='id' hidden />}
        <Form.Item
          label='广告名称'
          name='name'
          rules={[{ required: true, message: '请输入广告名称' }]}
        >
          <Input maxLength={20} placeholder='请输入广告名称' />
        </Form.Item>
        <Form.Item
          label='广告banner'
          name='imgUrl'
          rules={[{ required: true, message: '请上传广告banner图' }]}
        >
          <UploadFile url={data.imgUrl} fileType={13} onChange={() => {}} />
        </Form.Item>
        <Form.Item
          label='展示位置'
          name='positionEnumList'
          rules={[{ required: true, message: '请选择广告banner图展示位置' }]}
        >
          <Select mode='multiple' style={{ width: '100%' }} allowClear>
            {positionEnum.map((item) => (
              <Option key={item.key} value={item.key}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          {...{
            wrapperCol: { offset: 12, span: 8 },
          }}
        >
          <Space>
            <Button htmlType='button' onClick={() => setOpen(false)}>
              取消
            </Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      title={`${data.id ? '编辑' : '新增'}广告`}
      open={open}
      destroyOnClose
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Spin spinning={loading}>
        <ExtraContent />
      </Spin>
    </Modal>
  );
});

export default AddNewEditor;
