import * as actionTypes from '../constant/contract';

//商行管理--设备管理
//初始状态
const initState = {
  listDeviceModesData: [], //设备类型
  listDeviceTypesData: [], //设备型号
  addDeviceMsg: {}, //新增设备
  updateDeviceMsg: {}, //编辑设备
  devicesList: {}, //获取设备列表
  connectPrinterMsg: {}, //禁用/启用设备
  //商行管理--合同列表
  listPactsData: {}, //合同管理列表
  stallConnectPrinterMsg: {}, //绑定解绑信息
  deviceIdByMachineCode: {}, //获取设备id
};

export function contract(state = initState, action) {
  switch (action.type) {
    case actionTypes.CONTRACT_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
