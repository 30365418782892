import * as actionTypes from '../constant/org';
import * as req from '../request/org';

//
export function stallDelete(params) {
  return async (dispatch) => {
    const res = await req.stallDelete(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { stallDelData: res } });
    }
  };
}
export function stallEnable(params) {
  return async (dispatch) => {
    const res = await req.stallEnable(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { stallEnableData: res } });
    }
  };
}
export function stallDisable(params) {
  return async (dispatch) => {
    const res = await req.stallDisable(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { stallEnableData: res } });
    }
  };
}

export function stallAdd(params) {
  return async (dispatch) => {
    const res = await req.stallAdd(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { stallAddData: res } });
    }
  };
}

//
export function queryStall(params) {
  return async (dispatch) => {
    const res = await req.queryStall(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { stallData: res } });
    }
  };
}
export function orgAccountList(params) {
  return async (dispatch) => {
    const res = await req.orgAccountList(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { orgAccountListData: res } });
    }
  };
}

//
export function getAllOrgList(params) {
  return async (dispatch) => {
    const res = await req.getAllOrgList(params);
    if (res) {
      dispatch({
        type: actionTypes.ORG_SAVE,
        payload: { allOrgDate: res, getAllOrgListParams: params },
      });
    }
  };
}
//新建保存
export function saveOrg(params) {
  return async (dispatch) => {
    const res = await req.saveOrg(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { saveOrgDate: res } });
    }
  };
}
//修改保存
export function updateOrg(params) {
  return async (dispatch) => {
    const res = await req.updateOrg(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { updateOrgDate: res } });
    }
  };
}

//添加集团账号
export function createOrgAccount(params) {
  return async (dispatch) => {
    const res = await req.createOrgAccount(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { createOrgAccountDate: res } });
    }
  };
}
// 移除集团账号
export function removeOrgAccount(params) {
  return async (dispatch) => {
    const res = await req.removeOrgAccount(params);
    if (res) {
      dispatch({ type: actionTypes.ORG_SAVE, payload: { removeOrgAccountData: res } });
    }
  };
}

//重置字段
export function resetFields(name) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ORG_RESET_FIELDS, payload: name });
  };
}
