import * as actionTypes from '../constant/bi';

//初始状态
const initState = {
  // 档口基本信息
  stallinfo: [],
  // 档口角色
  // 档主
  staller: [],
  // 档口经理
  stallManager: [],
  // 销售经理
  salers: [],
  // 收银人员
  cashiers: [],
  // 财务人员
  financer: [],
  members: [],
  // 本日数据
  today: [],
  yesterday: [],
  // 本日数据
  month: [],
  avarage: [],
  // 档口本月每日力资费
  stallSaleFeeData: [],
  //档口本月每日销售总额
  stallSaleAmountData: [],
  //销售经理销售总额
  salerData: [],
  loading: false,
  // 日销售货款
  dailySales: [],
  // 月销售货款
  monthlySales: [],
  // 赊欠金额,今日还款,在售车次数
  arrearsData: [],
  workPlaceData: [],
  trainTimeData: [],
  homeData: [],
};

//reducer
export function bi(state = initState, action) {
  switch (action.type) {
    case actionTypes.BI_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
