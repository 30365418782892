//商行管理---会员服务管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Table } from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { PRODUCT_TYPE_LIST } from '../../utils/deviceType';
import './index.less';

import { listProducts } from '../../state/action/memberManager';
import UpdateMemberServices from './modal/updateMemberServices';

@connect(
  (state) => {
    return { memberManager: state.memberManager };
  },
  { listProducts },
)
@Form.create()
class MemberServicesManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      rows: {},
    };
  }

  componentDidMount() {
    this.getListProducts({});
  }

  async getListProducts(params) {
    await this.props.listProducts(params);
  }

  showMemberServices = (visible, rows, isHidden) => {
    this.setState(
      {
        visible,
        rows,
      },
      () => {
        if (isHidden !== undefined && isHidden) {
          this.getListProducts({});
        }
      },
    );
  };

  render() {
    const {
      memberManager: { productsList },
    } = this.props;
    const { loading, visible, rows } = this.state;

    const dataSource = productsList && productsList.length > 0 ? productsList : [];

    const columns = [
      {
        title: '会员服务',
        dataIndex: 'productType',
        key: 'productType',
        width: 120,
        render: (text) => {
          return text ? PRODUCT_TYPE_LIST.get(text) : '- -';
        },
      },
      {
        title: '渠道类型',
        dataIndex: 'platformType',
        key: 'platformType',
        width: 120,
        render: (text) => {
          //1-android 2-ios
          return text === 1 ? 'android' : text === 2 ? 'ios' : '- -';
        },
      },
      {
        title: '合同类型',
        dataIndex: 'pactType',
        key: 'pactType',
        width: 120,
        render: (text) => {
          // 1.新签合同，2.续费合同
          return text === 1 ? '新签合同' : text === 2 ? '续费合同' : '- -';
        },
      },
      {
        title: '会员服务定价',
        dataIndex: 'productAmount',
        key: 'productAmount',
        width: 120,
      },
      {
        title: '活动价格',
        dataIndex: 'discountAmount',
        key: 'discountAmount',
        width: 120,
      },
      {
        title: '当前活动',
        dataIndex: 'productDesc',
        key: 'productDesc',
        width: 120,
      },
      {
        title: '操作',
        dataIndex: 'options',
        width: 120,
        render: (_, record) => {
          return (
            <span
              style={{ color: '#52C41A', cursor: 'pointer' }}
              onClick={(e) => this.showMemberServices(true, record)}
            >
              编辑
            </span>
          );
        },
      },
    ];

    return (
      <div className='memberServiceManagement'>
        <PageHeaderLayout>
          <div className='memberService-content'>
            <Table
              rowKey={(_, index) => index}
              dataSource={dataSource}
              pagination={false}
              columns={columns}
              scroll={{ x: 600 }}
              bordered
              loading={loading}
            />
            {visible && (
              <UpdateMemberServices
                visible={visible}
                rows={rows}
                showMemberServices={this.showMemberServices}
                getListProducts={this.getListProducts}
              />
            )}
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default MemberServicesManagement;
