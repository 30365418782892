//货品分类
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, Row, Col, message, Input, Table, Divider } from 'antd';

import '../index.less';
import {
  getCategoriesList,
  addCategories,
  updateCategories,
  deleteCategory,
} from '../../../state/action/onlineStore';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { onlineStore: state.onlineStore };
  },
  { getCategoriesList, addCategories, updateCategories, deleteCategory },
)
@Form.create()
class GoodsClassification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addVisible: false,
      title: '',
      item: {},
    };
  }

  componentDidMount() {
    const { record } = this.props;
    this.getCategoriesList({ stallId: record.stallId });
  }

  // 获取货品分类
  async getCategoriesList(value) {
    await this.props.getCategoriesList(value);
  }

  addGoodsModal = (visible, title, item) => {
    this.setState({
      addVisible: visible,
      title,
      item,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { record } = this.props;
        const { item } = this.state;
        const reqObject = {
          stallId: record.stallId,
          categoryName: values.categoryName,
        };
        if (JSON.stringify(item) === '{}') {
          this.addCategoriesFun(reqObject);
        } else {
          this.updateCategoriesFun({ ...reqObject, id: item.id });
        }
      }
    });
  };
  async addCategoriesFun(params) {
    const result = await this.props.addCategories(params);
    if (result.code === 200) {
      message.success('新增成功');
      const { record } = this.props;
      this.addGoodsModal(false);
      this.getCategoriesList({ stallId: record.stallId });
    }
  }
  async updateCategoriesFun(params) {
    const result = await this.props.updateCategories(params);
    if (result.code === 200) {
      message.success('编辑成功');
      const { record } = this.props;
      this.addGoodsModal(false);
      this.getCategoriesList({ stallId: record.stallId });
    }
  }

  async deleteCategory(params) {
    const result = await this.props.deleteCategory({ id: params.id });
    if (result.code === 200) {
      message.success('删除成功');
      const { record } = this.props;
      this.addGoodsModal(false);
      this.getCategoriesList({ stallId: record.stallId });
    }
  }

  render() {
    const {
      visible,
      content,
      handleClose,
      onlineStore: { categoriesList },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { addVisible, title, item } = this.state;

    const data = categoriesList && categoriesList.length > 0 ? categoriesList : [];

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        render: (_, __, index) => {
          return ++index;
        },
      },
      {
        title: '分类名称',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width: 120,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 180,
        render: (_, record) => {
          return (
            <div>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.addGoodsModal(true, '编辑分类', record);
                }}
              >
                编辑
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.addGoodsModal(true, '删除分类', record);
                }}
              >
                删除
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Modal
          title={content}
          visible={visible}
          onCancel={() => handleClose()}
          className='modalStyle'
          width='750px'
          footer={null}
        >
          <Button type='primary' onClick={() => this.addGoodsModal(true, '新增分类', {})}>
            新增分类
          </Button>
          <Table
            rowKey={(_, index) => index}
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={this.state.loading}
          />
          {addVisible && (
            <Modal
              title={title}
              visible={addVisible}
              onCancel={() => this.addGoodsModal(false)}
              className='modalStyle'
              width='600px'
              footer={null}
            >
              {title === '删除分类' ? (
                <div style={{ textAlign: 'center' }}>
                  <br />
                  <div>确认删除【{item.categoryName}】分类吗？</div>
                  <br />
                  <div>
                    <Button onClick={() => this.addGoodsModal(false)} style={{ marginRight: 12 }}>
                      取消
                    </Button>
                    <Button type='primary' onClick={() => this.deleteCategory(item)}>
                      确定
                    </Button>
                  </div>
                </div>
              ) : (
                <Form layout='inline' onSubmit={this.handleSubmit}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <FormItem label='分类名称' {...formItemLayout}>
                        {getFieldDecorator('categoryName', {
                          initialValue: item.categoryName || null,
                          rules: [
                            {
                              required: true,
                              validator: (rule, value, callback) => {
                                if (value) {
                                  if (value.length > 4) {
                                    callback('分类名称不超过4个字符');
                                  }
                                } else {
                                  callback('请输入分类名称');
                                }
                                callback();
                              },
                            },
                          ],
                        })(<Input placeholder='请输入分类名称' allowClear />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    <Button onClick={() => this.addGoodsModal(false)} style={{ marginRight: 12 }}>
                      关闭
                    </Button>
                    <Button type='primary' htmlType='submit'>
                      确定
                    </Button>
                  </div>
                </Form>
              )}
            </Modal>
          )}
        </Modal>
      </div>
    );
  }
}

export default GoodsClassification;
