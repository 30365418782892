import * as actionTypes from '../constant/stall';
import * as req from '../request/stall';
import { getAllList } from '../request/org';

//获取档口列表
export function list(params) {
  return async (dispatch) => {
    const res = await req.list(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allStallData: res.data } });
    }
  };
}

//获取档口数据
export function view(params) {
  return async (dispatch) => {
    const res = await req.view(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { stallsByIdData: res.data } });
    }
  };
}
//新增档口
export function saveStall(params) {
  return async (dispatch) => {
    const res = await req.save(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { fileStallData: res } });
    }
  };
}
//修改档口
export function updateStall(params) {
  return async (dispatch) => {
    const res = await req.update(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { fileStallData: res } });
    }
  };
}
//重置档主密码
export function restPassword(params) {
  return async (dispatch) => {
    const res = await req.restPassword(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { fileStallData: res } });
      return res;
    }
  };
}
//获取档主信息
export function getStallManger(params) {
  return async (dispatch) => {
    const res = await req.getStallManger(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { stallMangerInfoVo: res.data } });
    }
  };
}
//更新档主信息
export function updateStallManger(params) {
  return async (dispatch) => {
    const res = await req.updateStallManger(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { updateMangerInfoVo: res } });
    }
  };
}
//获取组织机构信息
export function getOrgList(params) {
  return async (dispatch) => {
    const res = await getAllList(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { orgListDate: res.data } });
    }
  };
}

//获取档口列表
export function fetchAllStallsConfig(params) {
  return async (dispatch) => {
    const res = await req.getAllStallsConfig(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allStallsConfigData: res.data } });
    }
  };
}
//获取档口列表
export function fetchAllStallsConfig2(params) {
  return async (dispatch) => {
    const res = await req.getAllStallsConfig(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allStallsData: res.data } });
    }
  };
}

//获取档口列表
export function fetchAllConfig(params) {
  return async (dispatch) => {
    const res = await req.getAllConfig(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allConfig: res.data } });
    }
  };
}
//获取档口修改信息
export function fetchConfig(params) {
  return async (dispatch) => {
    const res = await req.getConfig(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { getConfigData: res.data } });
    }
  };
}
//修改档口保存
export function saveOrUpdateGroupConfig(params) {
  return async (dispatch) => {
    const res = await req.saveOrUpdateGroupConfig(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { saveOrUpdateData: res } });
    }
  };
}
//删除档口保存
export function stallDelete(params) {
  return async (dispatch) => {
    const res = await req.stallDelete(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { stallDeleteData: res.code } });
    }
  };
}
//获取配置分组列表
export function fetchAllGroup(params) {
  return async (dispatch) => {
    const res = await req.getAllGroup(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allStallsGroupData: res.data } });
    }
  };
}
//新增修改分组档口列表
export function saveOrUpdateConfig(params) {
  return async (dispatch) => {
    const res = await req.saveOrUpdateConfig(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { saveOrUpdateData: res } });
    }
  };
}
//新增修改分组配置列表
export function saveOrUpdateGroup(params) {
  return async (dispatch) => {
    const res = await req.saveOrUpdateGroup(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { resData: res } });
    }
  };
}
//获取档口配置
export function getGroupById(params) {
  return async (dispatch) => {
    const res = await req.getGroupById(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { groupInfo: res.data } });
    }
  };
}
//获取配置分组列表
export function fetchAllDistinctGroup(params) {
  return async (dispatch) => {
    const res = await req.getAllDistinctGroup(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allStallsGroupData: res.data } });
    }
  };
}
//获取档口配置
export function getStallGroupById(params) {
  return async (dispatch) => {
    const res = await req.getStallGroupById(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { groupInfo: res.data } });
    }
  };
}
//获取所有配置
export function getAllSysConfigs(params) {
  return async (dispatch) => {
    const res = await req.getAllSysConfigs(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { sysConfigs: res.data } });
    }
  };
}
//修改档口配置关系
export function updateGroupConfig(params) {
  return async (dispatch) => {
    const res = await req.updateGroupConfig(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { saveOrUpdateData: res } });
    }
  };
}
//
export function getAllSysConfigPage(params) {
  return async (dispatch) => {
    const res = await req.getAllSysConfigPage(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allConfig: res.data } });
    }
  };
}
//
export function saveOrUpdateStallGroup(params) {
  return async (dispatch) => {
    const res = await req.saveOrUpdateStallGroup(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { resData: res } });
    }
  };
}
//获取市场列表
export function marketList(params) {
  return async (dispatch) => {
    const res = await req.marketList(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { marketData: res.data } });
    }
  };
}

/***商行管理部分 */

//商行管理
export function listAll(params) {
  return async (dispatch) => {
    const res = await req.listAll(params);
    if (res) {
      dispatch({
        type: actionTypes.STALL_SAVE,
        payload: { allStallBankData: res.data, searchStall: params },
      });
    }
  };
}
//商行管理 -- 查看详情
export function stallDetails(params) {
  return async (dispatch) => {
    const res = await req.getStallDetails(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { stallDetails: res.data } });
    }
  };
}
//获取打印机关联商行信息
export function searchPrinter(params) {
  return async (dispatch) => {
    const res = await req.searchPrinter(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { searchPrinterData: res.data } });
    }
  };
}
//获取档口的数据统计
export function statistical(params) {
  return async (dispatch) => {
    const res = await req.getStatistical(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { statistical: res.data } });
    }
  };
}
//获取所有档口的数据统计
export function allStatistical(params) {
  return async (dispatch) => {
    const res = await req.getAllStatistical(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { allStatistical: res.data } });
    }
  };
}
//商行单据统计查询
export function boothStaticAll(params) {
  return async (dispatch) => {
    const res = await req.boothStaticAll(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { boothStaticData: res.data } });
    }
  };
}

//商行单据分析列表查询
export function getStallAnalyseData(params) {
  return async (dispatch) => {
    const res = await req.getStallAnalyseData(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { boothBillData: res.data } });
    }
  };
}
//转换系统类型，简介：修改商行type类型
export function updateStallsType(params) {
  return async (dispatch) => {
    const res = await req.updateStallsType(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { updateStallsType: res } });
    }
  };
}

/** 商行管理-查看详情-设备管理  开始 */
//设备管理列表
export function getListDevices(params) {
  return async (dispatch) => {
    const res = await req.getListDevices(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { devicesList: res.data } });
    }
  };
}
/** 商行管理-查看详情-设备管理  结束 */

/** 商行管理-查看详情-合同管理  开始 */
//合同管理列表
export function getListPacts(params) {
  return async (dispatch) => {
    const res = await req.getListPacts(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { pactsList: res.data } });
      return res;
    }
  };
}

//新增合同
export function addPact(params) {
  return async () => {
    const res = await req.addPact(params);
    if (res) {
      return res;
    }
  };
}
//修改合同
export function updatePact(params) {
  return async () => {
    const res = await req.updatePact(params);
    if (res) {
      return res;
    }
  };
}

//手动失效合同
export function enablePact(params) {
  return async () => {
    const res = await req.enablePact(params);
    if (res) {
      return res;
    }
  };
}
/** 商行管理-查看详情-合同管理  结束 */

//获取商行下所有员工信息
export function getAllStallEmploy(params) {
  return async (dispatch) => {
    const res = await req.getAllStallEmploy(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { stallEmployData: res.data } });
      return res;
    }
  };
}
//档主权限移交
export function transformStallsManager(params) {
  return async () => {
    const res = await req.transformStallsManager(params);
    if (res) {
      return res;
    }
  };
}
//启用或者注销商行
export function enableMarket(params) {
  return async (dispatch) => {
    const res = await req.enableMarket(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { enableMarket: res } });
    }
  };
}
//注销登录
export function loginOutAll(params) {
  return async (dispatch) => {
    const res = await req.loginOutAll(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { loginOutAll: res } });
    }
  };
}

//固化库存
export function solidifyStock(stallId) {
  return async (dispatch) => {
    const res = await req.solidifyStock(stallId);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { solidifyStock: res } });
      return res;
    }
  };
}

//获取筐子列表
export function getListBasket(params) {
  return async (dispatch) => {
    const res = await req.getListBasket(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { listBasket: res.data } });
    }
  };
}
// 筐子功能是否开启
export async function configBasket(params) {
  const res = await req.configBasket(params);
  if (res) {
    return res;
  }
}
//新增筐子
export async function addBasket(params) {
  const res2 = await req.addBasket({ ...params });
  if (res2) {
    return res2;
  }
}
//更新筐子
export async function updateBasket(params) {
  const res = await req.updateBasket(params);
  if (res) {
    return res;
  }
}

// 结算费用列表
export function settlementFeeList(params) {
  return async (dispatch) => {
    const res = await req.settlementFeeList(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { settlementFeeList: res.data } });
    }
  };
}
// 结算费用的类型
export function settlementFeeDictDataFinance(params) {
  return async (dispatch) => {
    const res = await req.settlementFeeType(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { settlementFeeType: res.data } });
    }
  };
}
// 新增结算
export function addSettleFee(params) {
  return async () => {
    const res = await req.addSettleFee(params);
    if (res) {
      return res;
    }
  };
}
// 修改结算
export function updateSettleFee(params) {
  return async () => {
    const res = await req.updateSettleFee(params);
    if (res) {
      return res;
    }
  };
}

// 结算详情
export function settleFeeDetail(params) {
  return async () => {
    const res = await req.settleFeeDetail(params);
    if (res) {
      return res;
    }
  };
}

// 获取档口白名单
export function stallWhiteList() {
  return async (dispatch) => {
    const res = await req.getStallWhiteList({});
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { stallWhitList: res.data } });
    }
  };
}

// 添加商行白名单
export function addStallWhite(params) {
  return async () => {
    const res = await req.addStallWhite(params);
    if (res) {
      return res;
    }
  };
}

// 移除商行白名单
export function removeStallWhite(params) {
  return async () => {
    const res = await req.removeStallWhite(params);
    if (res) {
      return res;
    }
  };
}

// 一键下线
export function offineAllStall(params) {
  return async () => {
    const res = await req.offineAllStall(params);
    if (res) {
      return res;
    }
  };
}

// 获取附加费用列表
export function fetchAddtionFeeList(params) {
  return async (dispatch) => {
    const res = await req.addtionFeeList(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { addtionFeeList: res.data || [] } });
    }
  };
}

// 更新附加费商行展示名字
export function updateAddtionFeeListItem(params) {
  return async () => {
    const res = await req.updateAddtionFee(params);
    if (res) {
      return res;
    }
  };
}

// 更新附加费的状态
export function updateAddtionFeeEnable(params) {
  return async () => {
    const res = await req.updateAddtionFeeEnable(params);
    if (res) {
      return res;
    }
  };
}

// 手机收银端白名单列表
export function fetchMobileCashAppList(params) {
  return async (dispatch) => {
    const res = await req.fetchMobileCashAppList(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { mobileCahsAppData: res.data || {} } });
    }
  };
}

// 手机收银端白名单-新增商行
export function addMobileCashAppStall(params) {
  return async () => {
    const res = await req.addMobileCashAppStall(params);
    if (res) {
      return res;
    }
  };
}

// 手机收银端白名单-移除商行
export function removeMobileCashAppStall(params) {
  return async () => {
    const res = await req.removeMobileCashAppStall(params);
    if (res) {
      return res;
    }
  };
}

// 手机收银端白名单-一键下线
export function removeAllMobileCashAppStall(params) {
  return async () => {
    const res = await req.removeAllMobileCashAppStall(params);
    if (res) {
      return res;
    }
  };
}

//获取市场列表
export function marketStallStatistic(params) {
  return async (dispatch) => {
    const res = await req.marketStallStatistic(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { marketStallData: res.data } });
    }
  };
}

//获取市场列表
export function stallInfos(params) {
  return async (dispatch) => {
    const res = await req.stallInfos(params);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { stallInfoData: res.data } });
    }
  };
}

//获取商行自定义角色列表
export function getStallRoleList(stallId) {
  return async (dispatch) => {
    const res = await req.getStallRoleList(stallId);
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { roleList: res.data } });
    }
  };
}

//获取商行自定义角色详情
export function getStallRoleDetail(roleId) {
  return async () => {
    const res = await req.getStallRoleDetail(roleId);
    if (res) {
      return res;
    }
  };
}

//新增商行自定义角色
export function addRole(params) {
  return async () => {
    const res = await req.addRole(params);
    if (res) {
      return res;
    }
  };
}

//修改商行自定义角色
export function updateRole(params) {
  return async () => {
    const res = await req.updateRole(params);
    if (res) {
      return res;
    }
  };
}

//删除商行自定义角色
export function deleteRole(params) {
  return async () => {
    const res = await req.deleteRole(params);
    if (res) {
      return res;
    }
  };
}

//获取系统角色权限列表
export function getSystemRoleCode(roleCode) {
  return async () => {
    const res = await req.getSystemRoleCode(roleCode);
    if (res) {
      return res;
    }
  };
}

//获取系统角色列表
export function getPermissionList() {
  return async (dispatch) => {
    const res = await req.getPermissionList();
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { permissionList: res.data } });
    }
  };
}

//查询账号列表
export function getSupplierAppList() {
  return async (dispatch) => {
    const res = await req.getSupplierAppList();
    if (res) {
      dispatch({ type: actionTypes.STALL_SAVE, payload: { supplierAppList: res.data } });
    }
  };
}

export function removeSupplierAppAccount(phone) {
  return async (dispatch) => {
    const res = await req.removeSupplierAppAccount(phone);
    if (res) {
      return res;
    }
  };
}

