// 商行管理 -- 商行管理（查看详情--移交档主权限)
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, Row, Col, message, Select } from 'antd';

import './device.less';
import { getAllStallEmploy, transformStallsManager } from '../../../../state/action/stall';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { getAllStallEmploy, transformStallsManager },
)
@Form.create()
class TransferStall extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tipVisible: false,
      item: {},
    };
  }

  //获取下拉框的值
  handleChange = (value, item) => {
    if (value) {
      this.setState({
        item: item.props.item,
      });
    }
  };

  //点击确认按钮
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err) => {
      if (!err) {
        this.showTipVisible(true);
      }
    });
  };

  showTipVisible = (visible) => {
    this.setState({
      tipVisible: visible,
    });
  };

  //再次确认按钮
  handleConfirm = (e) => {
    e.preventDefault();
    this.transformStallsFun();
  };

  async transformStallsFun() {
    const { record } = this.props;
    const { item } = this.state;
    const result = await this.props.transformStallsManager({
      stallId: record.id,
      personId: item.personId,
    });
    if (result.code === 200) {
      message.success('移交权限成功');
      this.showTipVisible(false);
      this.props.handleCancelForTransfer(false, true);
    } else {
      message.error(result.msg);
    }
    this.props.form.resetFields();
  }

  render() {
    const {
      transferVisible,
      handleCancelForTransfer,
      stall: { stallEmployData, stallDetails = {} },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { item } = this.state;

    return (
      <div>
        <Modal
          title='档主权限移交'
          visible={transferVisible}
          onCancel={() => handleCancelForTransfer(false)}
          className='modalStyle'
          width='750px'
          footer={null}
        >
          <Form layout='inline' onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem label='当前档主' {...formItemLayout}>
                  {stallDetails.personName}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem label='移交人员' {...formItemLayout}>
                  {getFieldDecorator('personId', {
                    rules: [{ required: true, message: '请输入员工名称搜索' }],
                  })(
                    <Select
                      placeholder='请输入员工名称搜索'
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={this.handleChange}
                    >
                      {stallEmployData &&
                        stallEmployData.map((item) => (
                          <Option key={item.personId} value={item.personName} item={item}>
                            {item.personName}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <br />
            <div style={{ textAlign: 'center' }}>
              <Button onClick={() => handleCancelForTransfer(false)} style={{ marginRight: 12 }}>
                关闭
              </Button>
              <Button type='primary' htmlType='submit'>
                确定
              </Button>
            </div>
          </Form>
        </Modal>
        {this.state.tipVisible && (
          <Modal
            title={'请核对无误后操作'}
            visible={this.state.tipVisible}
            onCancel={() => this.showTipVisible(false)}
            footer={null}
            className={'modalStyle'}
            width='400px'
            style={{ marginTop: '2%' }}
          >
            <br />
            <Row style={{ textAlign: 'center' }}>确认要将档主移交给【{item.personName}】吗？</Row>
            <br />
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => this.showTipVisible(false)} style={{ marginRight: 12 }}>
                取消
              </Button>
              <Button type='primary' onClick={this.handleConfirm}>
                确认
              </Button>
            </Row>
          </Modal>
        )}
      </div>
    );
  }
}

export default TransferStall;
