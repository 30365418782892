import * as actionTypes from '../constant/sysLog';
import * as req from '../request/sysLog';

// 列表
export function getList(params) {
  return async (dispatch) => {
    const res = await req.getList(params);
    if (res) {
      dispatch({ type: actionTypes.SYS_LOG_GET_LIST, payload: res });
    }
  };
}
