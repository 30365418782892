// 数据统计
import React, { PureComponent } from 'react';
import PageHeaderLayout from '../../component/page-header-layout/index';
import AllStatistics from '../booth-stall-management/pages/stall/statistics';

export default class Statistics extends PureComponent {
  render() {
    return (
      <PageHeaderLayout>
        <AllStatistics />
      </PageHeaderLayout>
    );
  }
}
