//网店信息维护
import request from '../../utils/request';

//网店管理列表
export async function getStoreList(params) {
  return request({ url: 'store/list', params });
}

//网店管理-新增
export async function addStore(params) {
  return request({ url: 'store/add', params });
}

//网店管理-编辑
export async function updateStore(params) {
  return request({ url: 'store/update', params });
}

//分类管理-列表
export async function getCategoriesList(params) {
  return request({ url: 'categories/list', params });
}

//分类管理-新增
export async function addCategories(params) {
  return request({ url: 'categories/add', params });
}

//分类管理-编辑
export async function updateCategories(params) {
  return request({ url: 'categories/update', params });
}

//分类管理-删除
export async function deleteCategory(params) {
  return request({ url: 'categories/delete', params });
}

//商品管理-列表
export async function getProductList(params) {
  return request({ url: 'product/list', params });
}
//商品管理-新增
export async function addProduct(params) {
  return request({ url: 'product/add', params });
}
//商品管理-编辑
export async function updateProduct(params) {
  return request({ url: 'product/update', params });
}
//商品管理-删除
export async function deleteProduct(params) {
  return request({ url: 'product/delete', params });
}
