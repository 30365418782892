import React, { useState } from 'react';
import { Menu, Dropdown, Icon, Button, Modal, Upload, message, Spin } from 'antd';
import request from '../../../../utils//request';
import { API_ADMIN } from '../../../../utils/urls';
import { generalParamsWithPToStr } from '../../../../utils/math';

import './index.less';

const uploadTypes = [
  {
    label: '货品导入',
    value: 1,
    fileTypeEnum: 'PRODUCT',
    templateUrl:
      'https://files.caixm.cn/prod/template/%E8%B4%A7%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
    action: 'dataImport/basicData',
  },
  {
    label: '买家导入',
    value: 2,
    fileTypeEnum: 'CUSTOM',
    templateUrl:
      'https://files.caixm.cn/prod/template/%E4%B9%B0%E5%AE%B6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
    action: 'dataImport/basicData',
  },
  {
    label: '货主导入',
    value: 3,
    fileTypeEnum: 'SUPPLIER',
    templateUrl:
      'https://files.caixm.cn/prod/template/%E8%B4%A7%E4%B8%BB%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
    action: 'dataImport/basicData',
  },
  {
    label: '订单导入(含明细)',
    value: 4,
    fileTypeEnum: '',
    templateUrl:
      'https://files.caixm.cn/prod/template/%E8%AE%A2%E5%8D%95%E7%99%BD%E6%9D%A1%E6%A8%A1%E6%9D%BF.xlsx',
    action: 'dataImport/historyOrder',
  },
  {
    label: '订单导入(无货品)',
    value: 5,
    fileTypeEnum: '',
    templateUrl: 'https://files.caixm.cn/prod/template/白条模板.xlsx',
    action: 'dataImport/simpleHistoryOrder',
  },
];

const ButtonUploadFile = ({ stallId }) => {
  const [visible, setVisible] = useState(false);
  const [errorMessageList, setErrorMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [store, setStore] = useState({});

  const menu = (
    <Menu>
      {uploadTypes.map((item) => {
        return (
          <Menu.Item key={item.value}>
            <div
              onClick={() => {
                setStore(item);
                setVisible(true);
              }}
            >
              {item.label}
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const beforeUpload = (file) => {
    setLoading(true);
    const checkSize = file.size / 1024 / 1024 < 20;
    if (!checkSize) {
      message.error('单个文件不能超过20M!');
      setLoading(false);
    }
    return checkSize;
  };

  const uploadContractProps = {
    className: 'uploader',
    accept: '.xls,.xlsx',
    name: 'file',
    listType: 'picture-card',
    action: `${API_ADMIN}${store.action}?${generalParamsWithPToStr(`${store.action}`, {})}`,
    headers: {},
    beforeUpload: beforeUpload,
    showUploadList: false,
    maxCount: 1,
    customRequest: (options) => {
      const params = { file: options.file, stallId, fileTypeEnum: store.fileTypeEnum };
      setLoading(true);
      request({ url: options.action, params, headers: 'form-data', method: 'post' }).then((res) => {
        setLoading(false);
        const { code, data = {} } = res;
        if (code === 200) {
          if (data?.errorMessageList?.length > 0) {
            setVisible(false);
            setErrorMessageList(data?.errorMessageList || []);
            setErrorVisible(true);
          } else {
            Modal.success({
              content: '导入成功',
            });
            setVisible(false);
          }
        }
      });
    },
  };

  const { templateUrl, label } = uploadTypes.find((types) => types.value === store.value) || {};

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type='primary' style={{ background: '#1D9E60', width: 116, marginRight: 16 }}>
          导入 <Icon type='down' />
        </Button>
      </Dropdown>
      <Modal
        title={label || '导入'}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        destroyOnClose
        maskClosable={false}
      >
        <Spin spinning={loading}>
          <Upload {...uploadContractProps}>
            <Icon type='upload' />
            <p>点击此处选择文件</p>
          </Upload>
          <div>
            <p>1、仅支持Excel，单个文件不超过20M</p>
            <p>2、确保表格内只有一个工作簿，如果有多个工作簿只有第一个会被处理</p>
            <p>
              3、<a href={templateUrl}>{label}模板下载</a>
            </p>
          </div>
        </Spin>
      </Modal>
      <Modal
        title='导入结果提示'
        visible={errorVisible}
        onOk={() => errorVisible(false)}
        onCancel={() => setErrorVisible(false)}
        destroyOnClose
        footer={[
          <Button type='primary' onClick={() => setErrorVisible(false)}>
            我知道了
          </Button>,
        ]}
      >
        <h3>导入失败，错误信息如下：</h3>
        <div style={{ maxHeight: 360, overflowY: 'scroll' }}>
          {errorMessageList.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </div>
      </Modal>
    </>
  );
};

export default ButtonUploadFile;
