import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Input, Table, Badge, DatePicker, Select, message, Card } from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { payStatus, getPayStatus, getPayBadge, getChanelPayStatus } from '../../utils/repay';
import { fetchRepayRecord } from '../../state/action/repay';
import { exportRepayRecord } from '../../state/request/repay';
import './remotePayAnalysis.less';

import { exportFile } from '../../utils/utils';

const { Option } = Select;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const dateFormat = 'YYYY-MM-DD';

const channel = [
  { label: '湖北建行', value: 1 },
  { label: '惠市宝', value: 2 },
  { label: '建行龙支付', value: 3 },
  { label: '微企付', value: 4 },
];

@connect(
  (state) => {
    let { repayRecord, total, loading, stallCount } = state.repay;
    if (total) {
      return { repayRecord, total, loading, stallCount };
    } else {
      return { repayRecord, loading, stallCount };
    }
  },
  { fetchRepayRecord },
)
@Form.create()
class RemotePayAnalysis extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: null,
      pageNo: 1,
      pageSize: 10,
    };
    this.pageSwitch = this.pageSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.doRequest = this.doRequest.bind(this);
    this.disabledStartDate = this.disabledStartDate.bind(this);
  }

  //初始加载
  componentDidMount() {
    this.doRequest(1);
  }

  doRequest(pageNo) {
    this.setState({
      pageNo: pageNo,
    });
    const { form } = this.props;
    const { pageSize } = this.state;
    form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const rangeValue = fieldsValue['range-picker'];
      const { name, status, stallId, channel } = fieldsValue;

      let startDT = rangeValue[0];
      let endDT = rangeValue[1];
      if (endDT.diff(startDT, 'months') > 0) {
        message.warn('查询时间不能超过1个月');
        return;
      }
      // 还款记录流水
      this.props.fetchRepayRecord({
        businessType: 2, //查询远程支付
        stallId,
        name,
        status,
        startTime: startDT.format(dateFormat),
        endTime: endDT.format(dateFormat),
        pageNo,
        pageSize,
        channel,
      });
    });
  }

  //提交请求
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ pageNo: 1 });
    this.doRequest(1);
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.doRequest(obj.current);
  };
  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.doRequest(1);
  };
  exportFile = async () => {
    this.props.form.validateFields(async (errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      const key = 'range-picker';
      const { [key]: date, ...rest } = fieldsValue;

      const startDT = date[0];
      const endDT = date[1];

      if (endDT.diff(startDT, 'months') > 0) {
        message.warn('导出时间不能超过1个月');
        return;
      }
      const response = await exportRepayRecord({
        startTime: startDT.format(dateFormat),
        endTime: endDT.format(dateFormat),
        ...rest,
        businessType: 2,
      });
      exportFile(response, '在线还款流水表.xlsx');
    });
  };

  disabledStartDate = (startValue) => {
    return startValue > new Date().getTime(); //大于今天的日期一律返回true，禁止选择
  };

  getExtraContent() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <div className='PayAnalysis'>
        <Form layout='inline' onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('range-picker', {
              rules: [{ type: 'array', required: true, message: '请输入查询时间!' }],
              initialValue: [moment(), moment()],
            })(
              <RangePicker
                format='YYYY-MM-DD'
                disabledDate={this.disabledStartDate}
                ranges={{
                  今天: [moment(), moment()],
                  当月: [moment().startOf('month'), moment()],
                }}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('stallId', {
              rules: [
                {
                  required: false,
                  message: '请输入商行ID',
                },
                {
                  pattern: /^\d+$/,
                  message: '只能输入数字!',
                },
              ],
            })(<Input placeholder='请输商行ID' allowClear autoComplete='off' />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('name', {
              rules: [{ required: false, message: '请输商行名称!' }],
            })(<Input placeholder='请输商行名称' allowClear autoComplete='off' />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('status', {
              rules: [{ required: false }],
              initialValue: 1,
            })(
              <Select placeholder='请选择支付状态' style={{ width: 100 }} allowClear={false}>
                <Option key='key' value=''>
                  全部
                </Option>
                {payStatus.map((item) => (
                  <Option key={'key' + item[0]} value={item[0]}>
                    {item[1]}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('channel', {
              rules: [{ required: false }],
            })(
              <Select placeholder='请选择支付渠道' style={{ width: 150 }} allowClear>
                {channel.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button style={{ marginLeft: '18px' }} onClick={this.handleReset}>
              重置
            </Button>
            <Button style={{ marginLeft: '18px' }} onClick={this.exportFile}>
              导出
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }

  render() {
    const { repayRecord = {}, total = 0, loading } = this.props;
    const { pageNo } = this.state;
    const extraContent = this.getExtraContent();

    let totalSize = repayRecord.totalSize;
    let repayRecordList = repayRecord.pageList || [];
    const pagination = {
      pageSize: 10,
      current: pageNo,
      total: totalSize,
    };
    const columns = [
      {
        title: '序号',
        dataIndex: 'id',
        width: 65,
        render: (_, __, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        title: '订单创建时间',
        dataIndex: 'createTime',
        width: 180,
        render: (_, record) => {
          const { createTime } = record;
          return (
            <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              {moment(createTime).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          );
        },
      },
      {
        title: '买家名称',
        dataIndex: 'personName',
        width: 180,
        render: (_, record) => {
          const { personName } = record;
          return <span>{personName}</span>;
        },
      },
      {
        title: '还款金额',
        dataIndex: 'payAmount',
        width: 120,
        render: (_, record) => {
          const { payAmount } = record;
          return <span>{payAmount}</span>;
        },
      },
      {
        title: '支付通道',
        dataIndex: 'channel',
        width: 120,
        render: (val) => channel.find((item) => item.value === val)?.label,
      },
      {
        title: '商行ID',
        dataIndex: 'stallId',
      },
      {
        title: '还款商行',
        dataIndex: 'stallName',
        render: (text) => {
          return <span>{text || ''}</span>;
        },
      },
      {
        title: '支付状态',
        dataIndex: 'status',
        width: 110,
        render: (_, record) => {
          return <Badge color={getPayBadge(record.status)} text={getPayStatus(record.status)} />;
        },
      },
      {
        title: '微信状态',
        dataIndex: 'payStatus',
        width: 110,
        render: (_, record) => {
          return <span>{getChanelPayStatus(record.payStatus)}</span>;
        },
      },
    ];
    return (
      <PageHeaderLayout>
        <Card bordered={false}>
          {extraContent}
          <div
            style={{
              paddingLeft: 30,
              display: !totalSize ? 'none' : 'block',
              background: 'rgb(219 243 231)',
              height: 51,
            }}
          >
            <span
              style={{ height: 51, color: '#1D9E60', fontWeight: 500, fontSize: 18, lineHeight: 3 }}
            >
              <span>还款合计：{total}元</span>
              <span style={{ marginLeft: 12 }}>还款笔数：{totalSize}笔</span>
              <span style={{ marginLeft: 12 }}>还款商行数：{this.props.stallCount}家</span>
            </span>
          </div>
          <Table
            rowKey={(record) => record.id}
            dataSource={repayRecordList}
            columns={columns}
            pagination={pagination}
            onChange={this.pageSwitch}
            bordered
            loading={loading}
            scroll={{ x: 'max-content' }}
          />
        </Card>
      </PageHeaderLayout>
    );
  }
}
export default RemotePayAnalysis;
