import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import { ConfigProvider as ConfigProvider5 } from 'antd-v5';
import moment from 'moment';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';

import Error from './error';

import App from './App';
import reducers from './reducers';
import './ie9classList'; //解决ie9不支持classList属性的问题
import './index.css';

moment.locale('zh-cn');

let basename = '';

//开发环境
// const store = createStore(reducers, compose(applyMiddleware(thunk), window.devToolsExtension ? window.devToolsExtension() : f => f));
const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
  ),
);
//正式环境，不让浏览器redux插件查看状态
// const store = createStore(reducers, compose(applyMiddleware(thunk), f => f));

ReactDOM.render(
  <Provider store={store}>
    <Error>
      <ConfigProvider locale={zhCN}>
        <ConfigProvider5
          locale={zhCN}
          prefixCls='ant5'
          theme={{
            token: {
              colorPrimary: '#1D9E60',
            },
          }}
        >
          <Router basename={basename}>
            <App></App>
          </Router>
        </ConfigProvider5>
      </ConfigProvider>
    </Error>
  </Provider>,
  document.getElementById('root'),
);
