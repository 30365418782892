import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { Form, Button, Row, Col, TreeSelect, Table, Select } from 'antd';
import { getAllDistrictInfo } from '../../state/action/mergeSet';
import { marketList, marketStallStatistic } from '../../state/action/stall';
import { exportMarketStallStatistic } from '../../state/request/stall';
import { exportFile } from '../../utils/utils';
import './marketStallStatistic.less';

const FormItem = Form.Item;
const { Column } = Table;
const pageSize = 10;

@connect(
  (state) => {
    return { mergeSet: state.mergeSet, stall: state.stall };
  },
  { getAllDistrictInfo, marketList, marketStallStatistic },
)
@Form.create()
class MarketStallStatistic extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      marketStallData: {}, // 页面数据
      pageNo: 1, // 请求页码
      loading: true, // 加载中
    };
  }

  componentDidMount() {
    this.getData({ pageNo: this.state.pageNo, pageSize: pageSize });
    this.getAllDistrictInfo();
    this.getMarketList();
  }

  /**
   * 获取数据源
   */
  getData = (params) => {
    this.props.marketStallStatistic(params);
    this.setState({ loading: false, pageNo: params.pageNo });
  };

  // 获取省市区
  getAllDistrictInfo(params) {
    this.props.getAllDistrictInfo(params);
  }
  // 获得市场列表
  getMarketList() {
    this.props.marketList({});
  }

  // 重置按钮
  handleReset = () => {
    this.props.form.resetFields();
    this.getData({ pageNo: this.state.pageNo, pageSize: pageSize });
  };

  // 查询
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      // 查询
      this.getData({ ...fieldsValue, pageNo: 1, pageSize: pageSize });
    });
  };

  /**
   * 切换页面
   * @param {*} obj
   */
  pageSwitch = (obj) => {
    this.setState({ pageNo: obj.current }, () => {
      this.props.form.validateFields((errors, fieldsValue) => {
        if (!!errors) {
          return;
        }
        // 查询
        this.getData({ ...fieldsValue, pageNo: obj.current, pageSize: pageSize });
      });
    });
  };

  toStallActiveInfo(marketId) {
    this.props.history.push({
      pathname: `/statics/stallActiveInfo`,
      state: { marketId: marketId },
    });
  }

  //导出
  exportMarketStallStatistic = async () => {
    this.props.form.validateFields(async (errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      const response = await exportMarketStallStatistic({ ...fieldsValue });
      exportFile(response, '市场统计数据.xlsx');
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, pageNo } = this.state;
    const {
      mergeSet: {
        districtInfo: { mapping },
      },
      stall: { marketData = [], marketStallData = {} },
    } = this.props;
    const { origins } = mapping || [];
    const layout = { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 };
    const { pageList = [], totalSize = 1 } = marketStallData;
    const pagination = {
      current: pageNo,
      pageSize,
      total: totalSize,
    };
    if (origins instanceof Array) {
      for (const item of origins) {
        for (const itemChildren of item.children) {
          delete itemChildren.children;
        }
      }
    }

    return (
      <div>
        <PageHeaderLayout>
          <div className='marketStallStatistic'>
            <div className='ant-advanced-search-form'>
              <div>
                <Form layout='inline' onSubmit={this.handleSearch}>
                  <Row gutter={24}>
                    <Col {...layout}>
                      <FormItem label='省市'>
                        {getFieldDecorator('district', {
                          rules: [{ required: false, message: '请选择城市' }],
                        })(
                          <TreeSelect
                            allowClear
                            showSearch
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={origins}
                            placeholder='请选择城市'
                            treeDefaultExpandAll={false}
                            filterTreeNode={(input, treeNode) => {
                              return treeNode.props.title.indexOf(input) >= 0;
                            }}
                          />,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='市场名称'>
                        {getFieldDecorator('marketId', {
                          rules: [{ required: false, message: '请选择市场' }],
                        })(
                          <Select
                            placeholder='请选择市场'
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {marketData.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <FormItem>
                        <Button type='primary' htmlType='submit' style={{ marginTop: '20px' }}>
                          查询
                        </Button>
                        <Button
                          style={{ marginLeft: '20px', marginTop: '20px' }}
                          onClick={this.handleReset}
                        >
                          重置
                        </Button>
                        <Button
                          style={{ marginLeft: '20px', marginTop: '20px' }}
                          onClick={this.exportMarketStallStatistic}
                        >
                          导出
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </div>

              <Table
                rowKey={(_, index) => index}
                dataSource={pageList}
                pagination={pagination}
                scroll={{ x: 800 }}
                align='center'
                bordered
                onChange={this.pageSwitch}
                loading={loading}
              >
                <Column
                  title='序号'
                  width={40}
                  dataIndex='index'
                  key='index'
                  render={(_, __, index) => {
                    return (pageNo - 1) * pageSize + index + 1;
                  }}
                />
                <Column title='所属城市' width={80} dataIndex='cityName' key='cityName' />
                <Column
                  title='市场名称'
                  width={200}
                  dataIndex='marketName'
                  key='marketName'
                  render={(text, record) => {
                    return (
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.toStallActiveInfo(record.marketId);
                        }}
                      >
                        {text}
                      </a>
                    );
                  }}
                />
                <Column title='开通数' width={100} dataIndex='stallCount' key='stallCount' />
                <Column title='签约数' width={100} dataIndex='signCount' key='signCount' />
                <Column title='健康数' width={100} dataIndex='healthCount' key='healthCount' />
                <Column title='活跃数' width={100} dataIndex='activeCount' key='activeCount' />
                <Column title='沉睡数' width={100} dataIndex='lossCount' key='lossCount' />
              </Table>
            </div>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default MarketStallStatistic;
