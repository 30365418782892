import * as actionTypes from '../constant/market';

//初始状态
const initState = {
  marketList: [],
  districtInfo: [],
  marketInfo: [],
  newMarketInfo: [],
  bigDataAccountData: [],
};

//reducer
export function market(state = initState, action) {
  switch (action.type) {
    case actionTypes.MARKET_SAVE:
      return { ...state, ...action.payload };
    case actionTypes.MARKET_CLEAR:
      return initState;
    default:
      return state;
  }
}
