// 销售明细
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Col, Table, Row, Icon, Tag, Button, Input } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getBatchSaleList } from '../../state/action/batchManager';
import './index.less';

const FormItem = Form.Item;

@Form.create()
@connect(
  (state) => {
    return { batchManager: state.batchManager };
  },
  { getBatchSaleList },
)
class BatchSaleDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.record = (this.props.location.state && this.props.location.state.record) || {};
    this.state = {
      loading: false,
      pageNo: 1,
    };
  }

  componentDidMount() {
    const batchSaleType = this.record.batchSaleType;
    let batchId = this.record.id;
    if (2 === batchSaleType) {
      batchId = 0;
    }
    let pageInfo = {
      stallId: this.record.stallId,
      batchId: batchId,
      pageSize: 10,
      pageNo: 1,
    };
    this.getList(pageInfo);
    document.body.classList.add('sys-log-index-body');
  }

  componentWillUnmount() {
    document.body.classList.remove('sys-log-index-body');
  }

  // 获取table数据
  async getList(params) {
    this.setState({ loading: true });
    await this.props.getBatchSaleList(params);
    this.setState({ loading: false });
  }

  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    });
    const batchSaleType = this.record.batchSaleType;
    let batchId = this.record.id;
    if (2 === batchSaleType) {
      batchId = 0;
    }
    let pageInfo = {
      stallId: this.record.stallId,
      batchId: batchId,
      goodsAttrName: this.state.goodsAttrName,
      pageSize: 10,
      pageNo: pageNumber.current,
    };
    this.getList(pageInfo);
  };

  //返回
  back = (e) => {
    e.preventDefault();
    const record = this.record;
    this.props.history.push({ pathname: '/system/batchManager/', state: { record } });
  };

  // 根据条件搜索合单配置列表
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['goodsAttrName'], (error, value) => {
      this.setState(
        {
          goodsAttrName: value.goodsAttrName,
          pageNo: 1,
        },
        () => {
          const batchSaleType = this.record.batchSaleType;
          let batchId = this.record.id;
          if (2 === batchSaleType) {
            batchId = 0;
          }
          let keyInfo = {
            stallId: this.record.stallId,
            batchId: batchId,
            goodsAttrName: value.goodsAttrName,
            pageSize: 10,
            pageNo: 1,
          };
          this.getList(keyInfo);
        },
      );
    });
  };

  // 重置
  handleReset = () => {
    this.props.form.resetFields();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { batchSaleList } = this.props.batchManager;
    let list = [];
    let pageEntity = {};
    if (batchSaleList) {
      list = batchSaleList.pageList;
      pageEntity = batchSaleList;
    }
    var totalSize = 0;
    if (pageEntity === undefined) {
    } else {
      totalSize = pageEntity.totalSize;
    }

    // 表格数据源
    const dataSource = list;
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
    };

    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
    };

    const extraContent = (
      <div className='extraContent'>
        <Form layout='inline' onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='货品名称'>
                {getFieldDecorator('goodsAttrName', {})(<Input />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
          </Row>
        </Form>
      </div>
    );

    return (
      <div className='sys-index'>
        <PageHeaderLayout>
          <div className={'system-log'}>
            <Row className='backRow'>
              <a href='/' onClick={this.back}>
                <Icon type='left' /> 返回
              </a>
            </Row>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(record, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.pageChange.bind(this)}
                scroll={{ x: 'max-content' }}
                style={{ whiteSpace: 'nowrap' }}
              >
                <Col
                  align='center'
                  title='货主名称'
                  dataIndex='supplierName'
                  key='supplierName'
                  width={100}
                />
                <Col
                  align='center'
                  title='车次号'
                  dataIndex='batchCarNo'
                  key='batchCarNo'
                  width={100}
                />
                <Col
                  align='center'
                  title='货品名称'
                  dataIndex='goodsAttrName'
                  key='goodsAttrName'
                  width={150}
                />
                <Col
                  align='center'
                  title='买家名称'
                  dataIndex='buyerName'
                  key='buyerName'
                  width={120}
                />
                <Col
                  align='center'
                  title='包装类型'
                  dataIndex='packKind'
                  key='packKind'
                  width={100}
                  render={(_, record) => {
                    const packKind = record.packKind;
                    //1：定装 2：非定装 3：散装  5: 郊菜  7: 拆包
                    if (1 === packKind) {
                      return '定装';
                    } else if (2 === packKind) {
                      return '非定装';
                    } else if (3 === packKind) {
                      return '散装';
                    } else if (5 === packKind) {
                      return '郊菜';
                    } else if (7 === packKind) {
                      return '拆包';
                    }
                    return packKind;
                  }}
                />
                <Col align='center' title='订单号' dataIndex='orderNo' key='orderNo' width={200} />
                <Col align='center' title='票号' dataIndex='todayNo' key='todayNo' width={120} />
                <Col
                  align='center'
                  title='订单类型'
                  dataIndex='orderType'
                  key='orderType'
                  width={100}
                  render={(_, record) => {
                    //1 普通订单 3 团购订单 5 赊欠白条 7 码单
                    const orderType = record.orderType;
                    if (1 === orderType) {
                      return '普通订单';
                    } else if (3 === orderType) {
                      return '团购订单';
                    } else if (5 === orderType) {
                      return '赊欠白条';
                    } else if (7 === orderType) {
                      return '码单';
                    }
                    return orderType;
                  }}
                />
                <Col
                  align='center'
                  title='销售额'
                  dataIndex='saleAmount'
                  key='saleAmount'
                  width={120}
                />
                <Col
                  align='center'
                  title='货款'
                  dataIndex='goodsAmount'
                  key='goodsAmount'
                  width={120}
                />
                <Col
                  align='center'
                  title='销售件数'
                  dataIndex='saleNumber'
                  key='saleNumber'
                  width={120}
                />
                <Col
                  align='center'
                  title='销售重量'
                  dataIndex='saleWeight'
                  key='saleWeight'
                  width={120}
                />
                <Col
                  align='center'
                  title='力资费'
                  dataIndex='tradeFee'
                  key='tradeFee'
                  width={120}
                />
                <Col align='center' title='开票费' dataIndex='billFee' key='billFee' width={120} />
                <Col align='center' title='灌包费' dataIndex='packFee' key='packFee' width={120} />
                <Col
                  align='center'
                  title='优惠'
                  dataIndex='discountAmount'
                  key='discountAmount'
                  width={120}
                />
                <Col align='center' title='多收' dataIndex='extend4' key='extend4' width={120} />
                <Col
                  align='center'
                  title='单价'
                  dataIndex='unitPrice'
                  key='unitPrice'
                  width={120}
                />
                <Col
                  align='center'
                  title='状态'
                  dataIndex='enable'
                  key='enable'
                  width={80}
                  render={(_, record) => {
                    const enable = record.enable;
                    const orderStatus = record.orderStatus;
                    const payStatus = record.payStatus;
                    if (!enable) {
                      return (
                        <Tag color={'#a9adb1'} key={'作废'}>
                          作废
                        </Tag>
                      );
                    } else if (20 === orderStatus) {
                      return (
                        <Tag color={'orange'} key={'退款'}>
                          退款
                        </Tag>
                      );
                    } else if (1 === orderStatus) {
                      return (
                        <Tag color={'red'} key={'赊账'}>
                          赊账
                        </Tag>
                      );
                    } else if (10 === orderStatus) {
                      return (
                        <Tag color={'green'} key={'已结清'}>
                          已结清
                        </Tag>
                      );
                    } else if (5 === orderStatus && 20 === payStatus) {
                      return (
                        <Tag color={'red'} key={'部分还款'}>
                          部分还款
                        </Tag>
                      );
                    } else if (25 === orderStatus) {
                      return (
                        <Tag color={'red'} key={'退货'}>
                          退货
                        </Tag>
                      );
                    }
                    return '';
                  }}
                />
                <Col
                  align='center'
                  title='创建时间'
                  dataIndex='createTime'
                  key='createTime'
                  width={120}
                  render={(_, record) => {
                    const time = moment(record.createTime).format('YYYY-MM-DD HH:mm:ss');
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='收银员'
                  dataIndex='createName'
                  key='createName'
                  width={120}
                />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default BatchSaleDetail;
