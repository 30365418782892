//签字撤销
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Input, message, Modal, Card } from 'antd';
import * as req from '../../state/request/dunning';

const FormItem = Form.Item;
const { confirm } = Modal;

@connect((state) => {
  return { market: state.market };
})
@Form.create()
class CancelSign extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (errors, values) => {
      const { cancelSign } = this;
      if (!!errors) {
        return;
      }
      confirm({
        title: '是否撤销商行签字信息?',
        content: '',
        onOk() {
          cancelSign(values);
        },
        onCancel() {},
      });
    });
  };

  cancelSign = async (values) => {
    const { code } = await req.cancelSign(values);
    if (code === 200) {
      message.success('撤销成功');
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };

    const extraContent = (
      <Form layout='inline' onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={1}></Col>
          <Col span={6} {...layout}>
            <FormItem label='商行ID'>
              {getFieldDecorator('stallId', {
                rules: [{ required: true, message: '请输入商行ID' }],
              })(<Input type='text' placeholder='请输入商行ID' allowClear />)}
            </FormItem>
          </Col>
          <Col span={6} {...layout}>
            <FormItem label='UUID'>
              {getFieldDecorator('uuid', {
                rules: [{ required: true, message: '请输入UUID' }],
              })(<Input type='text' placeholder='请输入uuid' allowClear />)}
            </FormItem>
          </Col>
          <Col span={11} {...layout}>
            <FormItem>
              <Button htmlType='submit' type='primary'>
                撤销
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    );

    return (
      <div>
        <Card className='listCard' bordered={false} title='' extra={extraContent}></Card>
      </div>
    );
  }
}
export default CancelSign;
