import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Form,
  Button,
  Input,
  Table,
  Row,
  Col,
  DatePicker,
  TreeSelect,
  Checkbox,
  Select,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { STALL_TYPE, STALL_TYPES, STALL_SALE_TYPE } from '../../utils/deviceType';
import { getAllDistrictInfo } from '../../state/action/mergeSet';
import { listAll, marketList, getStallAnalyseData } from '../../state/action/stall';
import { exportStallAnalyseData } from '../../state/request/stall';
import './dataAnalysis.less';
import { exportFile } from '../../utils/utils';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const format1 = 'YYYY-MM-DD 00:00:00';
@connect(
  (state) => {
    return { stall: state.stall, mergeSet: state.mergeSet };
  },
  { marketList, getAllDistrictInfo, listAll, getStallAnalyseData },
)
@Form.create()
class DataAnalysis extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectDate: [],
      cutSreach: false,
      paramsList: {
        stallId: null,
        district: null,
        marketId: null,
        name: null,
        pactStatus: null,
        personName: null,
        createTimeStart: null,
        createTimeEnd: null,
        pactStartTimeStart: null,
        pactStartTimeEnd: null,
        queryStartTime: moment().subtract(1, 'days').startOf('day').valueOf(),
        queryEndTime: moment().subtract(1, 'days').endOf('day').valueOf(),
        pactStatusList: null,
        enableList: [true],
        typeList: null,
        saleTypeList: null,
      },
    };
  }
  componentDidMount() {
    this.getMarketList(); //获得市场列表
    this.getAllDistrictInfo();
    const { paramsList } = this.state;
    this.getStallAnalyseData({ ...paramsList, pageNo: 1, pageSize: 10 });
  }
  // 导出
  exportData = async () => {
    let params = this.initData();
    const response = await exportStallAnalyseData(params);
    exportFile(response, '商行数据分析.xlsx');
  };

  // 获得市场列表
  getMarketList() {
    this.setState({ loading: true });
    this.props.marketList();
    this.setState({ loading: false });
  }
  // 获取省市区
  getAllDistrictInfo(params) {
    this.props.getAllDistrictInfo(params);
  }
  // 获取商行列表
  getAllBankList(params) {
    this.props.listAll(params);
  }
  //
  getStallAnalyseData(params) {
    this.setState({ loading: true });
    this.props.getStallAnalyseData(params);
    this.setState({ loading: false });
  }
  // 查询
  handleSearch = (e) => {
    e.preventDefault();

    this.props.form.validateFields(
      [
        'id',
        'cityCode',
        'marketId',
        'name',
        'createTime',
        'pactStartTime',
        'queryTime',
        'pactStatus',
        'enableList',
        'typeList',
        'saleTypeList',
      ],
      (errors, values) => {
        if (errors) {
          return;
        }

        values = {
          stallId: values.id || null,
          district: values.cityCode ? values.cityCode : null,
          marketId: values.marketId ? values.marketId : null,
          name: values.name ? values.name : null,
          createTimeStart:
            values['createTime'] && values['createTime'].length === 2
              ? moment(values['createTime'][0].format(format1)).valueOf()
              : null,
          createTimeEnd:
            values['createTime'] && values['createTime'].length === 2
              ? moment(values['createTime'][1].clone().add(1, 'day').format(format1)).valueOf()
              : null,
          pactStartTimeStart:
            values['pactStartTime'] && values['pactStartTime'].length === 2
              ? moment(values['pactStartTime'][0].format(format1)).valueOf()
              : null,
          pactStartTimeEnd:
            values['pactStartTime'] && values['pactStartTime'].length === 2
              ? moment(values['pactStartTime'][1].clone().add(1, 'day').format(format1)).valueOf()
              : null,
          queryStartTime:
            values['queryTime'] && values['queryTime'].length === 2
              ? moment(values['queryTime'][0].format(format1)).valueOf()
              : null,
          queryEndTime:
            values['queryTime'] && values['queryTime'].length === 2
              ? moment(values['queryTime'][1].clone().add(1, 'day').format(format1)).valueOf()
              : null,
          pactStatusList: values.pactStatus ? values.pactStatus : null,
          enableList:
            values.enableList && values.enableList.length === 2 ? [] : values.enableList || [],
          typeList: values.typeList && values.typeList.length === 4 ? null : values.typeList,
          saleTypeList: values.saleTypeList || [],
        };
        this.getStallAnalyseData({ ...values, pageNo: 1, pageSize: 10 });
      },
    );
  };
  //重置
  handleReset = () => {
    const { paramsList } = this.state;
    this.getStallAnalyseData({ ...paramsList, pageNo: 1, pageSize: 10 });
    this.props.form.resetFields();
  };
  // 分页
  paginationChange = (e) => {
    let params = this.initData();
    this.getStallAnalyseData({ pageSize: e.pageSize, pageNo: e.current, ...params });
  };
  // 待选日期发生变化的回调
  DateChange = (val) => {
    this.setState({
      selectDate: val,
    });
  };
  // 禁止选的日期
  disabledDate = (current) => {
    if (!this.state.selectDate || this.state.selectDate.length === 0) {
      return false;
    }
    const tooLate = this.state.selectDate[0] && current.diff(this.state.selectDate[0], 'days') > 30;
    return tooLate;
  };
  initData = () => {
    const values = this.props.form.getFieldsValue([
      'id',
      'cityCode',
      'marketId',
      'name',
      'createTime',
      'pactStartTime',
      'queryTime',
      'pactStatus',
      'enableList',
      'typeList',
      'saleTypeList',
    ]); // 获取过滤条件
    return {
      stallId: values.id || null,
      district: values.cityCode ? values.cityCode : null,
      marketId: values.marketId ? values.marketId : null,
      name: values.name ? values.name : null,
      createTimeStart:
        values['createTime'] && values['createTime'].length === 2
          ? moment(values['createTime'][0].format(format1)).valueOf()
          : null,
      createTimeEnd:
        values['createTime'] && values['createTime'].length === 2
          ? moment(values['createTime'][1].clone().add(1, 'day').format(format1)).valueOf()
          : null,
      pactStartTimeStart:
        values['pactStartTime'] && values['pactStartTime'].length === 2
          ? moment(values['pactStartTime'][0].format(format1)).valueOf()
          : null,
      pactStartTimeEnd:
        values['pactStartTime'] && values['pactStartTime'].length === 2
          ? moment(values['pactStartTime'][1].clone().add(1, 'day').format(format1)).valueOf()
          : null,
      queryStartTime:
        values['queryTime'] && values['queryTime'].length === 2
          ? moment(values['queryTime'][0].format(format1)).valueOf()
          : null,
      queryEndTime:
        values['queryTime'] && values['queryTime'].length === 2
          ? moment(values['queryTime'][1].clone().add(1, 'day').format(format1)).valueOf()
          : null,
      pactStatusList: values.pactStatus ? values.pactStatus : null,
      enableList:
        values.enableList && values.enableList.length === 2 ? [] : values.enableList || [],
      typeList: values.typeList && values.typeList.length === 4 ? null : values.typeList,
      saleTypeList: values.saleTypeList || [],
    };
  };
  render() {
    const layout = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 };
    const { getFieldDecorator } = this.props.form;
    const {
      mergeSet: {
        districtInfo: { mapping },
      },
      stall: { marketData = [] },
    } = this.props;
    const {
      stall: { boothBillData = {} },
    } = this.props;
    const { pageNo = 1, totalSize = 1 } = boothBillData;
    const { origins } = mapping || [];
    let dataSource = [];
    if (boothBillData) {
      const { pageList } = boothBillData;
      if (pageList) {
        dataSource = pageList;
      }
    }

    //去掉区
    if (origins instanceof Array) {
      for (const iterator of origins) {
        for (const iteratorChildren of iterator.children) {
          delete iteratorChildren.children;
        }
      }
    }
    const pagination = {
      pageSize: 10,
      total: totalSize,
      current: pageNo,
    };
    const columns = [
      {
        title: '商行ID',
        dataIndex: 'stallId',
        width: 100,
        fixed: 'left',
      },
      {
        title: '商行全名',
        dataIndex: 'stallName',
        width: 180,
        fixed: 'left',
        render: (text) => {
          return <span>{text}</span>;
        },
      },
      {
        title: '市场名',
        dataIndex: 'marketName',
        width: 180,
        render: (text) => {
          return <span>{text}</span>;
        },
      },
      {
        title: '归属地',
        dataIndex: 'address',
        width: 180,
        render: (text) => {
          return <span>{text || ''}</span>;
        },
      },
      {
        title: '商行分类',
        dataIndex: 'pactStatus',
        width: 150,
        render: (text) => {
          return STALL_TYPES.get(text);
        },
      },
      {
        title: '商行版本',
        dataIndex: 'type',
        width: 150,
        render: (text) => {
          return STALL_TYPE.get(text);
        },
      },
      {
        title: '管理模式',
        dataIndex: 'saleType',
        width: 250,
        render: (text) => {
          return STALL_SALE_TYPE.get(text);
        },
      },
      {
        title: '创建日期',
        dataIndex: 'createTime',
        width: 180,
        render: (_, record) => {
          const { createTime } = record;
          if (createTime) {
            return createTime !== 0 ? moment(createTime).format('YYYY-MM-DD') : '-';
          } else {
            return '-';
          }
        },
      },
      {
        title: '签约日期',
        dataIndex: 'pactStartTime',
        width: 180,
        render: (_, record) => {
          const { pactStartTime } = record;
          if (pactStartTime) {
            return pactStartTime !== 0 ? moment(pactStartTime).format('YYYY-MM-DD') : '-';
          } else {
            return '-';
          }
        },
      },
      {
        title: '赊欠单数',
        dataIndex: 'creditOrderCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '赊欠分享数',
        dataIndex: 'creditShareCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '签字分享数',
        dataIndex: 'signShareCount',
        width: 180,
        render: (text) => {
          return <span>{text ?? '-'}</span>;
        },
      },
      {
        title: '结算批次数',
        dataIndex: 'batchSettlementCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '结算分享数',
        dataIndex: 'settlementShareCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '开码单数',
        dataIndex: 'memoOrderCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '地磅功能订单数',
        dataIndex: 'weighingOrderCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '押筐订单数',
        dataIndex: 'existBasketOrderCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '收筐次数',
        dataIndex: 'collectBasketCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '交账班次数',
        dataIndex: 'handCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '财务端使用次数',
        dataIndex: 'useFinanceCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '手机端使用次数',
        dataIndex: 'useAppCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '新建批次数',
        dataIndex: 'batchCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
      {
        title: '打款批次数',
        dataIndex: 'supplierPayCount',
        width: 180,
        render: (text) => {
          return <span>{text || '-'}</span>;
        },
      },
    ];
    return (
      <PageHeaderLayout>
        <div className='data-analysis'>
          <Form className='ant-advanced-search-form' onSubmit={this.handleSearch}>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem label='商行ID'>
                  {getFieldDecorator('id', {
                    rules: [
                      {
                        required: false,
                        message: '请输入商行ID',
                      },
                      {
                        pattern: /^\d+$/,
                        message: '只能输入数字!',
                      },
                    ],
                  })(<Input placeholder='请输入商行ID' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='城&emsp;&emsp;市'>
                  {getFieldDecorator('cityCode', {
                    rules: [{ required: false, message: '' }],
                  })(
                    <TreeSelect
                      allowClear
                      showSearch
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={origins}
                      placeholder='请选择城市'
                      treeDefaultExpandAll={false}
                      onChange={this.onCityChange}
                      filterTreeNode={(input, treeNode) => {
                        return treeNode.props.title.indexOf(input) >= 0;
                      }}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='市场名称'>
                  {getFieldDecorator('marketId', {
                    rules: [{ required: false, message: '请选择市场!' }],
                  })(
                    <Select
                      placeholder='请选择市场'
                      style={{ minWidth: '100%' }}
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.isEdit ? true : false}
                    >
                      {marketData.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行名称'>
                  {getFieldDecorator('name', {
                    rules: [{ required: false, message: '请输入商行名称!' }],
                  })(<Input placeholder='请输入商行名称' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 300 }}>
                <FormItem label='创建时间'>
                  {getFieldDecorator('createTime', {
                    rules: [{ required: false, message: '请选择时间' }],
                  })(
                    <RangePicker
                      format='YYYY-MM-DD'
                      placeholder={['开始时间', '结束时间']}
                    ></RangePicker>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 300 }}>
                <FormItem label='签约日期'>
                  {getFieldDecorator('pactStartTime', {
                    rules: [{ required: false, message: '请选择时间' }],
                  })(
                    <RangePicker
                      format='YYYY-MM-DD'
                      placeholder={['开始时间', '结束时间']}
                    ></RangePicker>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 300 }}>
                <FormItem label='查询日期'>
                  {getFieldDecorator('queryTime', {
                    initialValue: [
                      moment().subtract(1, 'days').startOf('day'),
                      moment().subtract(1, 'days').endOf('day'),
                    ],
                    rules: [{ required: false, message: '请选择时间' }],
                  })(
                    <RangePicker
                      disabledDate={this.disabledDate}
                      onChange={this.DateChange}
                      format='YYYY-MM-DD'
                      placeholder={['开始时间', '结束时间']}
                    ></RangePicker>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 300 }}>
                <FormItem label='商行分类'>
                  {getFieldDecorator('pactStatus', {
                    rules: [{ required: false, message: '' }],
                  })(
                    <Checkbox.Group onChange={this.onStateChange}>
                      {[...STALL_TYPES].map(([k, v]) => (
                        <Checkbox value={k} key={k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行状态'>
                  {getFieldDecorator('enableList', {
                    initialValue: [true],
                  })(
                    <Checkbox.Group>
                      <Checkbox value={true}>启用</Checkbox>
                      <Checkbox value={false}>注销</Checkbox>
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行版本'>
                  {getFieldDecorator(
                    'typeList',
                    {},
                  )(
                    <Checkbox.Group>
                      {[...STALL_TYPE].map(([k, v]) => (
                        <Checkbox value={k} key={k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
              <Col {...layout} style={{ minWidth: 600 }}>
                <FormItem label='管理模式'>
                  {getFieldDecorator(
                    'saleTypeList',
                    {},
                  )(
                    <Checkbox.Group>
                      {[...STALL_SALE_TYPE].map(([k, v]) => (
                        <Checkbox value={k} key={k}>
                          {v}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <FormItem>
                  <Button type='primary' htmlType='submit'>
                    查询
                  </Button>
                  <Button style={{ marginLeft: '18px' }} onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    type='primary'
                    icon='upload'
                    target='_blank'
                    style={{ marginLeft: '18px' }}
                    onClick={this.exportData}
                  >
                    导出
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={pagination}
            rowKey={(record) => record.id}
            align='center'
            onChange={this.paginationChange}
            scroll={{ x: 'max-content' }}
            loading={this.state.loading}
          />
        </div>
      </PageHeaderLayout>
    );
  }
}
export default DataAnalysis;
