import React, { useState, useEffect, useCallback } from 'react';
import { Form, Space, Input, Button, Table, Select, Checkbox, message } from 'antd-v5';
import { DatePicker } from 'antd';
import moment from 'moment';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { layout, tailLayout } from '../add-services/enum';
import {
  getPayRecordBusinessList,
  getPayRecordBusinessTotal,
  getPayRecordBusinessDownload,
} from '../../state/request/serve';
import { exportFile } from '../../utils/utils';

import './index.less';

const { RangePicker } = DatePicker;
const { Option } = Select;

const time = new Date();

const defaultTime = [moment(time).subtract('months', 3), moment(time)];

const payStatus = [
  { key: 0, value: '待支付' },
  { key: 1, value: '支付成功' },
  { key: 3, value: '支付失败' },
];
const businessTypeList = [
  { key: 'CREDIT_SIGN', value: '赊欠签字' },
  { key: 'DAILY_PRODUCT_PRICE', value: '每日菜价' },
  { key: 'CUSTOM_PURCHASE_EXPORT', value: '买家通订单导出' },
  { key: 'SUPPLIER_VIP', value: '货主通会员' },
];

const ServeOrder = () => {
  const [form] = Form.useForm();
  const [list, setList] = useState({});
  const [total, setTotal] = useState(0);
  const initValues = {
    pageNo: 1,
    pageSize: 10,
    startTime: defaultTime[0],
    endTime: defaultTime[1],
    status: 1,
    businessTypeEnum: 'CREDIT_SIGN',
  };
  const [query, setQuery] = useState(initValues);

  const getData = useCallback(async () => {
    const { startTime, endTime } = query;
    const diff = moment(endTime - 1).diff(moment(startTime), 'months');
    if (!startTime || !endTime) {
      message.warning('请选择时间');
      return;
    }
    if (diff >= 3) {
      message.warning('时间间隔不能超过3个月');
      return;
    }
    const res = await getPayRecordBusinessList({ ...query });
    const res1 = await getPayRecordBusinessTotal({ ...query });
    if (res?.code === 200) {
      setList(res.data);
    }
    if (res1.code === 200) {
      setTotal(res1.data);
    }
  }, [query]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFinish = (values) => {
    const { dates, ...rest } = values;
    const [st, et] = dates;
    setQuery({
      ...query,
      ...rest,
      pageNo: 1,
      startTime: st,
      endTime: et,
    });
    form.setFieldsValue(values);
  };

  const onDownload = async () => {
    const { startTime, endTime, ...rest } = query;
    const st = +moment(startTime).startOf('day').format('x');
    const et = +moment(endTime).endOf('day').format('x') + 1;
    const response = await getPayRecordBusinessDownload({ ...rest, startTime: st, endTime: et });
    exportFile(response, '增值服务订单列表.xlsx');
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const ExtraContent = () => {
    return (
      <Form
        {...layout}
        form={form}
        layout='inline'
        onFinish={onFinish}
        className='header'
        initialValues={{
          dates: defaultTime,
          status: 1,
          businessTypeEnum: 'CREDIT_SIGN',
        }}
      >
        <Space wrap>
          <Form.Item name='dates'>
            <RangePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item name='businessTypeEnum'>
            <Select style={{ width: 180 }} placeholder='请选择增值服务类型'>
              {businessTypeList.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='stallId'>
            <Input placeholder='请输入商行ID' />
          </Form.Item>
          <Form.Item name='stallName'>
            <Input placeholder='请输入商行名称' />
          </Form.Item>
          <Form.Item name='status'>
            <Select style={{ width: 180 }} allowClear placeholder='请选择支付状态'>
              {payStatus.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='amountFlag' valuePropName='checked'>
            <Checkbox>订单金额大于零</Checkbox>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button
                htmlType='button'
                onClick={() => {
                  form.resetFields();
                  setQuery({ ...initValues });
                }}
              >
                重置
              </Button>
              <Button htmlType='button' onClick={onDownload}>
                导出
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    );
  };

  const columns = [
    {
      title: '下单时间',
      dataIndex: 'createTime',
      render: (t) => !!t && moment(t).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '订单ID',
      dataIndex: 'orderNo',
    },
    {
      title: '商行ID',
      dataIndex: 'stallId',
    },
    {
      title: '商行名称',
      dataIndex: 'stallName',
    },
    {
      title: '付款人姓名',
      dataIndex: 'personName',
    },
    {
      title: '付款人手机号',
      dataIndex: 'personPhone',
    },
    {
      title: '增值服务',
      dataIndex: 'businessName',
    },
    {
      title: '产品规格',
      dataIndex: 'productSpec',
    },
    {
      title: '订单金额',
      dataIndex: 'orderAmount',
    },
    {
      title: '支付状态',
      dataIndex: 'status',
      render: (status) => {
        return payStatus.find((item) => item.key === status)?.value || '--';
      },
    },
  ];

  return (
    <PageHeaderLayout>
      <div className='serve-order'>
        <ExtraContent />
        <Table
          rowKey={(_, index) => index}
          dataSource={list.pageList}
          columns={columns}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            current: query.pageNo,
            pageSize: query.pageSize,
            total: list.totalSize,
            onChange: (pageNo, pageSize) => {
              setQuery({ ...query, pageNo, pageSize });
            },
          }}
          title={() => `总金额${total}元`}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </PageHeaderLayout>
  );
};

export default ServeOrder;
