/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-05 10:47:32
 * @LastEditors: lijing
 * @LastEditTime: 2022-07-18 20:22:34
 */
import * as actionTypes from '../constant/operationManager';
import * as req from '../request/personManager';

// 列表
export function getOperationList(params) {
  return async (dispatch) => {
    const res = await req.getOperationList(params);
    if (res) {
      dispatch({ type: actionTypes.PERSON_MANAGER_GET_OPERATION_LIST, payload: res });
    }
  };
}
