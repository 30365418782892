// 商行单据统计
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Form,
  Button,
  Input,
  message,
  Table,
  Row,
  Col,
  DatePicker,
  TreeSelect,
  Checkbox,
  Select,
  Spin,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  STALL_TYPE,
  STALL_TYPES,
  RENEW_STATUS,
  STALL_SALE_TYPE,
  BUSINESS_TYPE,
} from '../../utils/deviceType';
import './billStatistics.less';
import { myBeforFormat } from '../../utils/timer';

import { getAllDistrictInfo } from '../../state/action/mergeSet';
import { marketList, boothStaticAll } from '../../state/action/stall';
import { exportStatistic } from '../../state/request/stall';
import { exportFile } from '../../utils/utils';

const { RangePicker, MonthPicker } = DatePicker;
const FormItem = Form.Item;

const format1 = 'YYYY-MM-DD 00:00:00';
const format2 = 'YYYY-MM-DD 23:59:59';

@connect(
  (state) => {
    return { mergeSet: state.mergeSet, stall: state.stall };
  },
  { getAllDistrictInfo, marketList, boothStaticAll },
)
@Form.create()
class BillStatistics extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null, // 开始日期
      endTime: null, // 结束日期
      endOpen: false,
      dataListTable: [],
      paramsList: {
        district: null,
        marketId: null,
        pactStatus: null,
        enableList: [true],
        personName: null,
        name: null,
        createTimeStart: null,
        createTimeEnd: null,
        pactStartTimeStart: null,
        pactStartTimeEnd: null,
        saleOrderTimeStart: new Date(
          moment().subtract(7, 'day').format(format1).replace(/-/g, '/'),
        ).getTime(),
        saleOrderTimeEnd: new Date(
          moment().subtract(1, 'day').format(format2).replace(/-/g, '/'),
        ).getTime(),
        renewStatus: null,
        businessTypeList: null,
        saleTypeList: null,
        expireTime: 0,
        monthOrderStartTime: moment()
          .subtract(1, 'month')
          .set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
          .valueOf(),
      },
      loading: false,
      operLoading: false,
      tip: '',
    };
  }
  componentDidMount() {
    const curData = moment().subtract(1, 'day').format('YYYY-MM-DD');
    this.getAllDistrictInfo();
    this.getMarketList();
    this.getTableTitle(curData);
    const { paramsList } = this.state;
    this.getAllBankList({ ...paramsList, pageNo: 1, pageSize: 10 });
  }

  // 获取省市区
  getAllDistrictInfo(params) {
    this.props.getAllDistrictInfo(params);
  }
  // 获得市场列表
  getMarketList() {
    this.props.marketList({});
  }
  // 查询--获取商行单据列表
  getAllBankList(params) {
    this.setState({ loading: true });
    this.props.boothStaticAll(params);
    this.setState({ loading: false });
  }
  getTableTitle = (time) => {
    const timeListTable = [];
    for (let i = 0; i < 10; i++) {
      const dateList = myBeforFormat(i, false, time); // 获取订单开始日期-结束日期间的时间
      timeListTable.push(dateList); // 添加table list表头
    }
    timeListTable.reverse();
    this.setState({ dataListTable: timeListTable });
  };
  // 下拉选择城市
  onCityChange = (e) => {};
  // 重置按钮
  handleReset = () => {
    this.setState({ startTime: moment().subtract(7, 'day'), endTime: moment().subtract(1, 'day') }); // 点击重置 日期恢复成默认
    this.getTableTitle(moment().subtract(1, 'day').format('YYYY-MM-DD')); // 重置--table表头恢复默认
    const { paramsList } = this.state;
    this.getAllBankList({ ...paramsList, pageNo: 1, pageSize: 10 });
    this.props.form.resetFields();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
    this.props.form.setFieldsValue({
      [field]: value,
    });
  };
  // 开始日期 小于系统当前日期 且当前日期置灰不可选
  disabledStartDate = (current) => {
    const { endTime } = this.state;
    if (!current || !endTime) {
      return current && current > moment().subtract(7, 'days');
    }
    return (
      current.valueOf() > new Date().getTime() ||
      new Date().getTime() - current.valueOf() < 24 * 60 * 60 * 1000 * 7
    );
  };
  // 结束日期 距开始日期往后推7天 或者 往前推7天
  disabledEndDate = (endValue) => {
    const { startTime } = this.state;
    const timeStamp = 24 * 60 * 60 * 1000 * 6;
    const timeStamp1 = 24 * 60 * 60 * 1000 * 7;
    if (!endValue || !startTime) {
      return endValue.valueOf() > new Date().getTime();
    }
    const end = endValue.valueOf();
    const start = startTime.valueOf();

    return end - start < timeStamp || end - start > timeStamp1 || end > new Date().getTime();
  };
  // 开始日期
  onStartChange = (value) => {
    const timeStamp = new Date(value).getTime() + 9 * 24 * 60 * 60 * 1000;
    if (!value) {
      this.props.form.setFieldsValue({
        endTime: null,
      });
      return;
    }

    this.onChange('startTime', value);
    this.onEndChange(moment(timeStamp));
  };
  // 结束日期
  onEndChange = (value) => {
    this.onChange('endTime', value);
  };
  onDisabledDateChange = (current) => {
    const { endTime } = this.state;
    if (!current || !endTime) {
      return false;
    }
    // 开始日期不能早于当前日期的前一天，且和结束日期相隔7天
    return (
      current.valueOf() < endTime.valueOf() - 24 * 60 * 60 * 1000 * 7 ||
      current.valueOf() > endTime.valueOf()
    );
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  // 查询
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      const {
        cityCode,
        marketId,
        pactStatus,
        enableList,
        stallName,
        stallId,
        personName,
        createTime,
        pactStartTime,
        startTime,
        endTime,
        typeList,
        firstDepartment,
        pioneerDivision,
        saleName,
        renewStatus,
        businessTypeList,
        saleTypeList,
        expireTime,
        monthOrderStartTime,
      } = fieldsValue;

      if (!startTime || !endTime) {
        message.error('订单日期的开始时间和结束时间不得为空！');
        return;
      }
      if (endTime === new Date().getTime()) {
        message.error('当天日期不可选！');
        return;
      }
      const orderTime1 = startTime ? moment(startTime.format(format1)).valueOf() : null;
      const orderTime2 = startTime ? moment(endTime.format(format2)).valueOf() : null;
      this.getTableTitle(endTime.format('YYYY-MM-DD')); // 生成table 表头
      //把时间改为1号0点，eg:2021-05-01 00:00:00
      if (monthOrderStartTime) {
        monthOrderStartTime.set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
      }
      fieldsValue = {
        district: cityCode || null,
        marketId: marketId || null,
        pactStatus: pactStatus || null,
        enableList: enableList || [true],
        personName: personName ? personName.replace(/(^\s*)|(\s*$)/g, '') : null,
        name: stallName ? stallName.replace(/(^\s*)|(\s*$)/g, '') : null,
        stallId: stallId || null,
        // 创建日期
        createTimeStart:
          createTime && createTime.length === 2
            ? moment(createTime[0].format(format1)).valueOf()
            : null,
        createTimeEnd:
          createTime && createTime.length === 2
            ? moment(createTime[1].format(format2)).valueOf()
            : null,
        // 签约日期
        pactStartTimeStart:
          pactStartTime && pactStartTime.length === 2
            ? moment(pactStartTime[0].format(format1)).valueOf()
            : null,
        pactStartTimeEnd:
          pactStartTime && pactStartTime.length === 2
            ? moment(pactStartTime[1].format(format2)).valueOf()
            : null,
        // 订单日期
        saleOrderTimeStart: startTime ? orderTime1 : null,
        saleOrderTimeEnd: endTime ? orderTime2 : null,
        //销售人员
        typeList: typeList && typeList.length === 4 ? null : typeList,
        firstDepartment: firstDepartment || null,
        pioneerDivision: pioneerDivision || null,
        saleName: saleName || null,
        renewStatus: renewStatus || null,
        businessTypeList: businessTypeList || [],
        saleTypeList: saleTypeList || [],
        pactEndTimeStart: expireTime && expireTime.length === 2 ? expireTime[0].valueOf() : null,
        pactEndTimeEnd: expireTime && expireTime.length === 2 ? expireTime[1].valueOf() : null,
        monthOrderStartTime: monthOrderStartTime ? monthOrderStartTime.valueOf() : null, //月开单天数
      };
      // 查询
      this.getAllBankList({ ...fieldsValue, pageNo: 1, pageSize: 10 });
    });
  };
  // 分页
  paginationChange = (e) => {
    const { startTime, endTime } = this.state;
    let params = this.initData();
    params.saleOrderTimeStart =
      startTime === null
        ? new Date(moment().subtract(7, 'day').format(format1)).getTime()
        : moment(startTime.format(format1)).valueOf();
    params.saleOrderTimeEnd =
      endTime === null
        ? new Date(moment().subtract(1, 'day').format(format2)).getTime()
        : moment(endTime.format(format2)).valueOf();
    this.getAllBankList({ pageSize: e.pageSize, pageNo: e.current, ...params });
  };

  // 导出
  exportData = async () => {
    this.setState({ operLoading: true, tip: '导出中请稍后...' });
    let params = this.initData();
    const response = await exportStatistic(params);
    this.setState({ operLoading: false, tip: '' });
    exportFile(response, '商行单据统计.xlsx');
  };

  initData = () => {
    const values = this.props.form.getFieldsValue([
      'cityCode',
      'marketId',
      'pactStatus',
      'enableList',
      'personName',
      'stallName',
      'stallId',
      'createTime',
      'pactStartTime',
      'startTime',
      'endTime',
      'typeList',
      'firstDepartment',
      'pioneerDivision',
      'saleName',
      'renewStatus',
      'businessTypeList',
      'saleTypeList',
      'expireTime',
      'monthOrderStartTime',
    ]); // 获取过滤条件
    //把时间改为1号0点，eg:2021-05-01 00:00:00
    if (values.monthOrderStartTime) {
      values.monthOrderStartTime.set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    }
    return {
      district: values.cityCode || null,
      marketId: values.marketId || null,
      pactStatus: values.pactStatus || null,
      enableList: values.enableList || [true],
      personName: values.personName ? values.personName.replace(/(^\s*)|(\s*$)/g, '') : null,
      name: values.stallName ? values.stallName.replace(/(^\s*)|(\s*$)/g, '') : null,
      stallId: values.stallId || null,
      createTimeStart:
        values.createTime && values.createTime.length === 2
          ? moment(values.createTime[0].format(format1)).valueOf()
          : null,
      createTimeEnd:
        values.createTime && values.createTime.length === 2
          ? moment(values.createTime[1].format(format2)).valueOf()
          : null,
      pactStartTimeStart:
        values.pactStartTime && values.pactStartTime.length === 2
          ? moment(values.pactStartTime[0].format(format1)).valueOf()
          : null,
      pactStartTimeEnd:
        values.pactStartTime && values.pactStartTime.length === 2
          ? moment(values.pactStartTime[1].format(format2)).valueOf()
          : null,
      saleOrderTimeStart: values.startTime
        ? moment(values.startTime.format(format1)).valueOf()
        : null,
      saleOrderTimeEnd: values.endTime ? moment(values.endTime.format(format2)).valueOf() : null,
      //系统类型
      typeList: values.typeList && values.typeList.length === 4 ? null : values.typeList,
      firstDepartment: values.firstDepartment || null,
      pioneerDivision: values.pioneerDivision || null,
      saleName: values.saleName || null,
      renewStatus: values.renewStatus || null,
      businessTypeList: values.businessTypeList || [],
      saleTypeList: values.saleTypeList || [],
      expireTime: values.expireTime || 0,
      monthOrderStartTime: values.monthOrderStartTime ? values.monthOrderStartTime.valueOf() : null, //月开单天数
    };
  };

  /**
   * 月开单天数统计  不能选择未来的月份
   * @param {Moment} current
   * @returns
   */
  disabledDateByMonthOrder(current) {
    return current && current > moment().endOf('day');
  }

  render() {
    const layout = { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 };
    const layout1 = { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 };
    const layout2 = { xs: 24, sm: 24, md: 24, lg: 24, xl: 16 };
    const { getFieldDecorator } = this.props.form;
    const { startTime, endTime, dataListTable } = this.state;
    const {
      mergeSet: {
        districtInfo: { mapping },
      },
      stall: { marketData = [] },
    } = this.props;
    const { origins } = mapping || [];
    const {
      stall: { boothStaticData = {} },
    } = this.props;
    const { pageNo = 1, totalSize = 1 } = boothStaticData;
    const pagination = {
      pageSize: 10,
      total: totalSize,
      current: pageNo,
    };
    if (origins instanceof Array) {
      for (const item of origins) {
        for (const itemChildren of item.children) {
          delete itemChildren.children;
        }
      }
    }
    let dataSource = [];
    if (boothStaticData) {
      const { pageList } = boothStaticData;
      if (pageList) {
        pageList.forEach((item) => {
          if (item.billsTotalCount) {
            // 对应日期相应的数据
            for (const i in item.billsTotalCount) {
              item[i] = item.billsTotalCount[i];
            }
          }
        });
        dataSource = pageList;
      }
    }
    // 动态添加tbale表头
    dataSource.forEach((item) => {
      const timeInit = item.billsTotalCount;
      let arr = {},
        obj = [];
      for (const i in timeInit) {
        arr.data = i;
        arr.value = timeInit[i];
        obj.push(arr);
      }
    });
    const columns = [
      {
        title: '商行ID',
        dataIndex: 'id',
        width: 100,
        fixed: 'left',
      },
      {
        title: '商行全名',
        dataIndex: 'name',
        width: 180,
        fixed: 'left',
        render: (t) => t || '--',
      },
      {
        title: '集团名称',
        dataIndex: 'orgName',
        render: (t) => t || '--',
      },
      {
        title: '商行地址',
        dataIndex: 'address',
        render: (t) => t || '--',
      },
      {
        title: '商行类型',
        dataIndex: 'businessType',
        render: (text) => {
          return BUSINESS_TYPE[text] || '--';
        },
      },
      {
        title: '市场名',
        dataIndex: 'marketName',
        render: (t) => t || '--',
      },
      {
        title: '归属地',
        dataIndex: 'district',
        render: (_, record) => {
          return `${record.provinceName || ''} ${record.cityName || ''} `;
        },
      },
      {
        title: '档主',
        dataIndex: 'personName',
        render: (t) => t || '--',
      },
      {
        title: '联系人电话',
        dataIndex: 'personPhone',
        render: (t) => t || '--',
      },
      {
        title: '设备数',
        dataIndex: 'printerConfigCount',
        render: (t) => t || '--',
      },
      {
        title: '商行分类',
        dataIndex: 'pactStatus',
        render: (text) => {
          return STALL_TYPES.get(text) || '--';
        },
      },
      {
        title: '商行版本',
        dataIndex: 'type',
        render: (text) => {
          return STALL_TYPE.get(text) || '--';
        },
      },
      {
        title: '管理模式',
        dataIndex: 'saleType',
        render: (text) => {
          return STALL_SALE_TYPE.get(text) || '--';
        },
      },
      {
        title: '创建日期',
        dataIndex: 'createTime',
        render: (_, record) => {
          const { createTime } = record;
          if (createTime) {
            return createTime !== 0 ? moment(createTime).format('YYYY-MM-DD') : '--';
          } else {
            return '--';
          }
        },
      },
      {
        title: '签约日期',
        dataIndex: 'pactStartTime',
        render: (_, record) => {
          const { pactStartTime } = record;
          if (pactStartTime) {
            return pactStartTime !== 0 ? moment(pactStartTime).format('YYYY-MM-DD') : '--';
          } else {
            return '--';
          }
        },
      },
      {
        title: '到期时间',
        dataIndex: 'pactEndTime',
        render: (_, record) => {
          const { pactEndTime = 0 } = record;
          return pactEndTime !== 0 ? moment(pactEndTime).format('YYYY-MM-DD') : '--';
        },
      },
      {
        title: '续费状态',
        dataIndex: 'renewStatus',
        render: (text) => {
          return text ? RENEW_STATUS.get(text) : '--';
        },
      },
      {
        title: '归属BD',
        dataIndex: 'saleName',
        render: (t) => t || '--',
      },
      {
        title: '业务线',
        dataIndex: 'firstDepartment',
        render: (t) => t || '--',
      },
      {
        title: '所属部门',
        dataIndex: 'pioneerDivision',
        render: (t) => t || '--',
      },
      {
        title: '昨日开单时间',
        dataIndex: 'kaidan',
        render: (_, record) => {
          //昨日开单时间（昨天商行第一单开单时间和昨天商行最后一单开单时间，例 07:30-21:09）
          const { startOrderTime, endOrderTime } = record;
          if (startOrderTime && endOrderTime) {
            return (
              moment(startOrderTime).format('HH:mm') + '--' + moment(endOrderTime).format('HH:mm')
            );
          } else {
            return '--';
          }
        },
      },
      {
        title: '连续开单天数',
        dataIndex: 'continuousUseDays',
        render: (t) => t || '--',
      },
    ];
    //订单开始日期
    dataListTable.forEach((item, i) => {
      columns.push({
        title: item,
        dataIndex: item,
        render: (text) => {
          return text || '--';
        },
      });
    });
    //月开单天数统计
    if (dataSource.length > 0) {
      if (dataSource[0].monthOrderDayNumVOList && dataSource[0].monthOrderDayNumVOList.length > 0) {
        dataSource[0].monthOrderDayNumVOList.forEach((item, i) => {
          const { time } = item;
          columns.push({
            title: `${moment(time).month() + 1}月`,
            dataIndex: `monthOrderDayNumVOList[${i}].orderDayNum`,
            render: (text) => {
              return text || '--';
            },
          });
        });
      }
    }

    return (
      <PageHeaderLayout>
        <Spin spinning={this.state.operLoading} tip={this.state.tip}>
          <div className='BillStatistics'>
            <div className='extraContent' style={{ width: '100%' }}>
              <Form className='ant-advanced-search-form' onSubmit={this.handleSearch}>
                <Row gutter={24}>
                  <Col {...layout}>
                    <FormItem label='城市'>
                      {getFieldDecorator('cityCode', {
                        rules: [{ required: false, message: '请选择城市' }],
                      })(
                        <TreeSelect
                          allowClear
                          showSearch
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={origins}
                          placeholder='请选择城市'
                          treeDefaultExpandAll={false}
                          onChange={this.onCityChange}
                          filterTreeNode={(input, treeNode) => {
                            return treeNode.props.title.indexOf(input) >= 0;
                          }}
                        />,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='市场名称'>
                      {getFieldDecorator('marketId', {
                        rules: [{ required: false, message: '请选择市场' }],
                      })(
                        <Select
                          placeholder='请选择市场'
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {marketData.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='创建日期'>
                      {getFieldDecorator('createTime', {
                        rules: [{ required: false, message: '请选择时间' }],
                      })(
                        <RangePicker format='YYYY-MM-DD' placeholder={['开始时间', '结束时间']} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...layout}>
                    <FormItem label='商行ID'>
                      {getFieldDecorator('stallId', {
                        rules: [{ required: false, whitespace: true, message: '请输入商行ID' }],
                      })(<Input placeholder='请输入商行ID' allowClear />)}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='商行名称'>
                      {getFieldDecorator('stallName', {
                        rules: [{ required: false, whitespace: true, message: '请输入商行名称' }],
                      })(<Input placeholder='请输入商行名称' allowClear />)}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='档主姓名'>
                      {getFieldDecorator('personName', {
                        rules: [{ required: false, whitespace: true, message: '请输入档主姓名' }],
                      })(<Input placeholder='请输入档主姓名' allowClear />)}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='签约日期'>
                      {getFieldDecorator('pactStartTime', {
                        rules: [{ required: false, message: '请选择时间' }],
                      })(
                        <RangePicker format='YYYY-MM-DD' placeholder={['开始时间', '结束时间']} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...layout1}>
                    <FormItem label='订单日期'>
                      {getFieldDecorator('startTime', {
                        initialValue: startTime === null ? moment().subtract(10, 'day') : startTime,
                        rules: [{ required: false, message: '请选择开始日期' }],
                      })(
                        <DatePicker
                          disabledDate={this.disabledStartDate}
                          format='YYYY-MM-DD'
                          placeholder='请选择开始日期'
                          onChange={this.onStartChange}
                          onOpenChange={this.handleStartOpenChange}
                          showToday={false}
                          style={{ width: 125 }}
                        />,
                      )}
                      &nbsp;
                      {getFieldDecorator('endTime', {
                        initialValue: endTime === null ? moment().subtract(1, 'day') : endTime,
                        rules: [{ required: false, message: '请选择结束日期' }],
                      })(
                        <DatePicker
                          disabledDate={this.disabledEndDate}
                          format='YYYY-MM-DD'
                          placeholder='请选择结束日期'
                          onChange={this.onEndChange}
                          onOpenChange={this.handleEndOpenChange}
                          showToday={false}
                          disabled
                          style={{ width: 125 }}
                        />,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...layout1}>
                    <FormItem label='商行分类'>
                      {getFieldDecorator(
                        'pactStatus',
                        {},
                      )(
                        <Checkbox.Group>
                          {[...STALL_TYPES].map(([k, v]) => (
                            <Checkbox value={k} key={k}>
                              {v}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...layout1}>
                    <FormItem label='商行状态'>
                      {getFieldDecorator('enableList', {
                        initialValue: [true],
                      })(
                        <Checkbox.Group>
                          <Checkbox value={true}>启用</Checkbox>
                          <Checkbox value={false}>注销</Checkbox>
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...layout1}>
                    <FormItem label='商行版本'>
                      {getFieldDecorator(
                        'typeList',
                        {},
                      )(
                        <Checkbox.Group>
                          {[...STALL_TYPE].map(([k, v]) => (
                            <Checkbox value={k} key={k}>
                              {v}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...layout2}>
                    <Row gutter={24}>
                      <Col span={8}>
                        <FormItem label='业务线'>
                          {getFieldDecorator(
                            'firstDepartment',
                            {},
                          )(<Input placeholder='请输入业务线' allowClear />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label='所属部门' span={8}>
                          {getFieldDecorator(
                            'pioneerDivision',
                            {},
                          )(<Input placeholder='请输入所属部门' allowClear />)}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label='归属BD' span={8}>
                          {getFieldDecorator(
                            'saleName',
                            {},
                          )(<Input placeholder='请输入签约BD' allowClear />)}
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...layout}>
                    <FormItem label='到期时间'>
                      {getFieldDecorator('expireTime', {
                        rules: [{ required: false, message: '请选择时间' }],
                      })(
                        <RangePicker format='YYYY-MM-DD' placeholder={['开始时间', '结束时间']} />,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='续费状态'>
                      {getFieldDecorator(
                        'renewStatus',
                        {},
                      )(
                        <Checkbox.Group>
                          {[...RENEW_STATUS].map(([k, v]) => (
                            <Checkbox value={k} key={k}>
                              {v}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='商行类型'>
                      {getFieldDecorator(
                        'businessTypeList',
                        {},
                      )(
                        <Checkbox.Group>
                          {Object.keys(BUSINESS_TYPE).map((item) => (
                            <Checkbox value={Number(item)}>{BUSINESS_TYPE[item]}</Checkbox>
                          ))}
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col {...layout}>
                    <FormItem label='月开单天数统计'>
                      {getFieldDecorator('monthOrderStartTime', {
                        initialValue: moment().subtract(1, 'month'),
                        rules: [{ required: true, message: '请选择月开单天数统计' }],
                      })(
                        <MonthPicker
                          placeholder='请选择月份'
                          disabledDate={this.disabledDateByMonthOrder}
                        />,
                      )}
                    </FormItem>
                  </Col>
                  <Col span={16}>
                    <FormItem label='管理模式'>
                      {getFieldDecorator(
                        'saleTypeList',
                        {},
                      )(
                        <Checkbox.Group>
                          {[...STALL_SALE_TYPE].map(([k, v]) => (
                            <Checkbox value={k} key={k}>
                              {v}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem>
                      <Button type='primary' htmlType='submit' style={{ marginTop: '20px' }}>
                        查询
                      </Button>
                      <Button
                        style={{ marginLeft: '20px', marginTop: '20px' }}
                        onClick={this.handleReset}
                      >
                        重置
                      </Button>
                      <Button
                        style={{ marginLeft: '20px', marginTop: '20px' }}
                        onClick={this.exportData}
                      >
                        导出
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </div>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={pagination}
              rowKey={(record) => record.id}
              align='center'
              onChange={this.paginationChange}
              scroll={{ x: 'max-content' }}
              loading={this.state.loading}
            />
          </div>
        </Spin>
      </PageHeaderLayout>
    );
  }
}
export default BillStatistics;
