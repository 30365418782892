/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-26 17:28:37
 * @LastEditors: lijing
 * @LastEditTime: 2023-01-13 11:14:11
 */
//reducer
import * as actionTypes from '../constant/bank';
//初始状态
const initState = {};

//reducer
export function bank(state = initState, action) {
  switch (action.type) {
    case actionTypes.BANK_GET_LIST:
      return { ...state, ...action.payload, loading: false };
    case actionTypes.BANK_GET_FILES:
      return { ...state, loading: true };
    default:
      return state;
  }
}
