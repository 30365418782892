import * as actionTypes from '../constant/systemNotice';

//初始状态
const initState = {
  loading: false,
  listData: [],
  addData: [],
  updateData: [],
  getNoticePushListData: [],
  addNoticePushData: [],
  deleteNoticePushData: [],
  resetNoticePushStatusData: [],
  pushNoticeData: [],
};

//reducer
export function systemNotice(state = initState, action) {
  switch (action.type) {
    case actionTypes.SYSTEM_NOTICE_SAVE:
      return { ...state, ...action.payload };
    case actionTypes.SYSTEM_NOTICE_CLEAR:
      return initState;
    default:
      return state;
  }
}
