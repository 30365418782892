import request from '../../utils/request';

export async function getAllOrgList(params = {}) {
  const { pageSize, pageNo, ...restParams } = params;
  return request({
    url: `org/v640/list/${pageNo}/${pageSize}`,
    params: restParams,
    method: 'get',
    headers: 'json',
  });
}

export async function saveOrg(params) {
  return request({ url: 'org/save', params });
}

export async function updateOrg(params) {
  return request({ url: 'org/update', params });
}

export async function stallDelete(params) {
  return request({ url: 'org/stallDelete', params });
}
export async function stallEnable(params) {
  return request({ url: 'org/stallEnable', params });
}
export async function stallDisable(params) {
  return request({ url: 'org/stallDisable', params });
}

export async function stallAdd(params) {
  return request({ url: 'org/stallAdd', params });
}

export async function queryStall(params) {
  return request({ url: 'org/stallList', params });
}

export async function orgAccountList(params) {
  return request({ url: 'org/orgAccountList', params });
}

export async function getAllList(params) {
  return request({ url: 'org/getAllList', params });
}

//添加集团账号
export async function createOrgAccount(params) {
  return request({ url: 'org/createOrgAccount', params });
}
//移除集团账号
export async function removeOrgAccount(params) {
  return request({ url: 'org/removeOrgAccount', params });
}
// 获取用户商行列表
export async function getOrgUserStall(personId) {
  return request({ url: `org/userStallList/${personId}`, method: 'get', headers: 'json' });
}
// 保存用户商行列表
export async function updateUserStall(params) {
  return request({ url: 'org/updateUserStall', params, headers: 'json' });
}
