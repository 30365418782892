import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

//import wrong from './images/wrong2.jpg';
import './style.less'

@withRouter
class not404 extends React.Component {

    componentWillMount() {        
        document.getElementById("root").classList.add("body-bg");
    }

    componentWillUnmount(){
        document.getElementById("root").classList.remove("body-bg");
    }

    render() {
        // const { match } = this.props;
        return (
            <div className="not404">
                <Row className="row-class">404报错</Row>
                <div className="error-page">
                    <Row>
                        <Col span={12}>
                            <div className="errpr-img">
                                <img src='https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg' alt=""/>
                            </div>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={10}>
                            <Row className="title">404</Row>
                            <Row className="sub-title">抱歉，您访问的页面不存在</Row>
                            <br />
                            <Link to='/'><Button type="primary">返回到网站</Button></Link>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default not404;
