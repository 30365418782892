import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Form, Input, Spin, Space, Button, Radio, message } from 'antd-v5';

import { getBusinessUpdate } from '../../../../state/request/serve';
import { typeEnum } from '../../enum';

import UploadFile from '../../../../component/upload';

import './index.less';

const { TextArea } = Input;

const AddNewEditor = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const onFinish = async (values) => {
    const { extend = {} } = data;
    const param = { ...extend, detailUrl: values.detailUrl };
    delete values.detailUrl;
    // 增值服务需要传extend
    const res = await getBusinessUpdate({ ...values, extend: param });
    if (res.code === 200) {
      message.info('编辑增值服务成功');
      setOpen(false);
      props.refresh && props.refresh();
    }
  };

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      if (data) {
        form.setFieldsValue({ ...data, detailUrl: data.extend?.detailUrl });
        setData(data);
      }
    },
  }));

  const ExtraContent = () => {
    return (
      <Form
        className='add-new-editor-form'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        layout="horizontal"
        preserve={false}
        onFinish={onFinish}
        initialValues={{
          typeEnum: 1
        }}
      >
        <Form.Item name="id" hidden>
          <div />
        </Form.Item>
        <Form.Item
          label="增值功能名称"
          name="name"
          rules={[{ required: true, message: '请输入增值服务名称' }]}
        >
          <Input maxLength={8} placeholder='请输入增值功能名称' />
        </Form.Item>
        <Form.Item
          label="功能说明"
          name="description"
          rules={[{ required: true, message: '请输入增值服务功能说明' }]}
        >
          <TextArea maxLength={40} showCount placeholder='请输入功能说明' />
        </Form.Item>
        <Form.Item
          label="图标"
          name="iconUrl"
          rules={[{ required: true, message: '请上传图标' }]}
        >
          <UploadFile url={data.iconUrl} onChange={() => {}} />
        </Form.Item>
        <Form.Item
          label="详情页图片"
          name="detailUrl"
          rules={[{ required: true, message: '请上传详情页图片' }]}
        >
          <UploadFile max={5} url={data.extend?.detailUrl} onChange={() => {}} />
        </Form.Item>
        <Form.Item label='类型' name='typeEnum'>
          <Radio.Group>
            <Space>
              {typeEnum.map(item => <Radio key={item.key} value={item.key}>{item.label}</Radio>)}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...{
          wrapperCol: { offset: 16, span: 8 },
        }}>
          <Space>
            <Button  htmlType="button" onClick={() => setOpen(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    )
  };

  return (
    <Modal
      title={`${data.id ? '编辑' : '新增'}增值服务`}
      open={open}
      destroyOnClose
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Spin spinning={loading}>
        <ExtraContent />
      </Spin>
    </Modal>
    
  )
})

export default AddNewEditor;
