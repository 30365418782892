import moment from 'moment';

export function getCurrentDate() {
  let now = new Date();
  now.setTime(now.getTime());
  let year = now.getFullYear(); // 年
  let month = now.getMonth() + 1; // 月
  let day = now.getDate(); // 日
  let clock = `${year}-`;
  if (month < 10) clock += '0';
  clock += `${month}-`;
  if (day < 10) clock += '0';
  clock += day;
  return clock;
}

export function getNextDate() {
  var now = new Date();
  // var now = new Date();
  now.setTime(now.getTime() + 24 * 60 * 60 * 1000);
  let year = now.getFullYear(); // 年
  let month = now.getMonth() + 1; // 月
  let day = now.getDate(); // 日
  let clock = `${year}-`;
  if (month < 10) clock += '0';
  clock += `${month}-`;
  if (day < 10) clock += '0';
  clock += day;
  return clock;
}

export function getYearOfNextTime() {
  let dt = new Date();
  return dt.getMonth() === 11 ? dt.getFullYear() + 1 : dt.getFullYear();
}

export function getMonthOfNextTime() {
  let dt = new Date();
  return dt.getMonth() === 11 ? 0 : dt.getMonth() + 1;
}

export function strtotime(str) {
  if (typeof str === 'undefined') {
    return Date.parse(new Date()) / 1000;
  }
  let _arr = str.split(' ');
  let _day = _arr[0].split('-');
  _arr[1] = _arr[1] == null ? '0:0:0' : `${_arr[1]}:00`;
  let _time = _arr[1].split(':');
  for (var i = _day.length - 1; i >= 0; i--) {
    _day[i] = isNaN(parseInt(_day[i])) ? 0 : parseInt(_day[i]);
  }
  for (var j = _time.length - 1; j >= 0; j--) {
    _time[j] = isNaN(parseInt(_time[j])) ? 0 : parseInt(_time[j]);
  }
  //
  let _temp = new Date(_day[0], _day[1] - 1, _day[2], _time[0], _time[1], _time[2]);
  return _temp.getTime() / 1000;
  // return Date.parse(new Date( date ) )/1000 ;
}

export function getCurrentTime() {
  let now = new Date();
  let year = now.getFullYear(); // 年
  let month = now.getMonth() + 1; // 月
  let day = now.getDate(); // 日
  let hh = now.getHours(); // 时
  let mm = now.getMinutes(); // 分
  let clock = `${year}-`;
  if (month < 10) clock += '0';
  clock += `${month}-`;
  if (day < 10) clock += '0';
  clock += `${day}T`;
  if (hh < 10) clock += '0';
  clock += `${hh}:`;
  if (mm < 10) clock += '0';
  clock += mm;
  return clock;
}

export function getCutdown(sys_second) {
  let timer = ''; // 倒计时秒数
  //
  if (sys_second > 3600 * 25) {
    // 如果大于一天
    var day = Math.floor(sys_second / 3600 / 24),
      hour = Math.floor((sys_second / 3600) % 24);
    timer = `${day}天${fixZero(hour)}小时`;
  } else {
    hour = Math.floor(sys_second / 3600);
    var minute = Math.floor((sys_second / 60) % 60);
    var second = Math.floor(sys_second % 60);
    timer = `${fixZero(hour)}:${fixZero(minute)}:${fixZero(second)}`;
  }
  return timer;
}

export function gmtToYMD(time1) {
  var time = new Date(time1);
  let year = time.getFullYear();
  let month = time.getMonth();
  month += 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = time.getDate();
  if (day < 10) day = `0${day}`;
  return `${year}-${month}-${day}`;
}

export function formatDate(now, type) {
  now *= 1000;
  let day = new Date(now),
    year = day.getFullYear(),
    month = day.getMonth() + 1,
    date = day.getDate(),
    m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Spt', 'Oct', 'Nov', 'Dec'];
  switch (type) {
    case 'm/d':
      return `${fixZero(month, 2)}月${fixZero(date, 2)}日`;
    // break;
    case 'm,d':
      return `${m[month - 1]}，${fixZero(date, 2)}，${fixZero(year, 2)}`;
    // break;
    case 'm-d':
      // 显示month-date
      return `${fixZero(month, 2)}-${fixZero(date, 2)}`;
    // break;
    case 'y-m-d':
      // 只显示 year-month-date
      return `${year}-${formatDate(now / 1000, 'm-d')}`;
    // break;
    case 'y-m-d h:M':
      // 只显示 year-month-date hour:minute
      var hour = day.getHours();
      var minute = day.getMinutes();
      return `${year}-${formatDate(now / 1000, 'm-d h:M')}`;
    // break;
    case 'm-d h:M':
      // 只显示 month-date hour:minute
      hour = day.getHours();
      minute = day.getMinutes();
      return `${formatDate(now / 1000, 'm-d')} ${fixZero(hour, 2)}:${fixZero(minute, 2)}`;
    // break;
    case '(y-)m-d':
      // 不计算于现在的时间差
      var today = new Date(),
        toyear = today.getFullYear();
      if (toyear === year) {
        return formatDate(now / 1000, 'm-d');
      } else {
        return formatDate(now / 1000, 'y-m-d');
      }
    // break;
    case '(y-)m-d h:M':
      // 不计算于现在的时间差
      today = new Date();
      toyear = today.getFullYear();
      if (toyear === year) {
        return formatDate(now / 1000, 'm-d h:M');
      } else {
        return formatDate(now / 1000, 'y-m-d h:M');
      }
    // break;
    case 'h:M':
      hour = day.getHours();
      minute = day.getMinutes();
      return `${fixZero(hour, 2)}:${fixZero(minute, 2)}`;
    // break;
    case 'h:M:s':
      hour = day.getHours();
      minute = day.getMinutes();
      var second = day.getSeconds();
      return `${fixZero(hour, 2)}:${fixZero(minute, 2)}:${fixZero(second, 2)}`;
    // break;
    case '(y-m-d) h:M':
      // 只显示 hour:minute
      today = new Date();
      var nowtime = Date.parse(today);
      var toymd = formatDate(nowtime / 1000, 'y-m-d');
      var ymd = formatDate(now / 1000, 'y-m-d');

      if (toymd === ymd) {
        return formatDate(now / 1000, 'h:M');
      } else {
        return formatDate(now / 1000, '(y-)m-d h:M');
      }
    // break;
    case '(y-m-d) h:M:s':
      second = day.getSeconds();
      return `${formatDate(now / 1000, '(y-m-d) h:M')}:${second}`;
    // break;
    case '(h/M/s)ago':
      today = new Date();
      nowtime = Date.parse(today);
      if (nowtime - now > 48 * 3600000) {
        return `${parseInt((nowtime - now) / (24 * 3600000))}天前`;
      } else if (nowtime - now > 24 * 3600000 && nowtime - now < 48 * 3600000) {
        hour = day.getHours();
        minute = day.getMinutes();
        var yesterday = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() - 1}`;
        var yestertime = Date.parse(yesterday);
        if (now > yestertime) {
          return `昨天 ${fixZero(hour, 2)}:${fixZero(minute, 2)}`;
        } else {
          return `前天 ${fixZero(hour, 2)}:${fixZero(minute, 2)}`;
        }
      } else if (nowtime - now > 3600000) {
        var between = nowtime - now;
        hour = Math.floor(between / 1000 / 3600);
        return `${hour}小时前`;
      } else if (nowtime - now > 60000) {
        // 一个小时以内
        between = nowtime - now;
        var min = Math.floor(between / 1000 / 60);
        return `${min}分钟前`;
      } else {
        // 一分钟前
        between = nowtime - now;
        second = Math.floor((between / 1000) % 60);
        return `${second}秒前`;
      }
    // break;
    case '(y-)m-d h:M/(h/M/s)ago':
      today = new Date();
      nowtime = Date.parse(today);
      if (nowtime - now > 3 * 24 * 3600000) {
        // 如果大于3天
        return formatDate(now / 1000, '(y-)m-d h:M');
      } else {
        // 如果大于2天
        return formatDate(now / 1000, '(h/M/s)ago');
      }
    // break;
    case '(y-)m-d/(h/M/s)ago':
    default:
      today = new Date();
      nowtime = Date.parse(today);
      if (nowtime - now > 3 * 24 * 3600000) {
        // 如果大于3天
        return formatDate(now / 1000, '(y-)m-d');
      } else {
        // 如果大于2天
        return formatDate(now / 1000, '(h/M/s)ago');
      }
    // break;
  }
}

export function fixZero(num, length) {
  let str = `${num}`;
  let len = str.length;
  let s = '';
  for (let i = length; i > len; i--) {
    s += '0';
  }
  return s + str;
}

export function weekOfMonth(date) {
  if (!date) date = new Date();
  return new Date(getFullYear(date), getMonth(date), 1).getDay();
}

export function getMonth(date) {
  if (!date) date = new Date();
  return date.getMonth();
}

export function getFullYear(date) {
  if (!date) date = new Date();
  return date.getFullYear();
}

export function getDate(date) {
  if (!date) date = new Date();
  return date.getDate();
}

export function monthDayNumber(year) {
  let _date_array = [];
  for (let i = 0; i < 12; i++) {
    switch (i + 1) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        _date_array.push(31);
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        _date_array.push(30);
        break;
      case 2:
        if (isLeapYear(year)) {
          _date_array.push(29);
        } else {
          _date_array.push(28);
        }
        break;
      default:
        break;
    }
  }

  return _date_array;
}

export function isLeapYear(year) {
  if (!typeof +year === 'number') {
    throw new Error('年份格式不正确');
  }

  if (+year < 1790) {
    throw new Error('年份不能低于1790年');
  }

  // 计算闰年方法
  // 1.能被4整除而不能被100整除
  // 2.能被400整除

  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function isToday(str) {
  let date = new Date(str);
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  let offset = date.getTime() - today.getTime();
  return offset >= 0 && offset <= 1000 * 60 * 60 * 24;
}

export function getCurrentTime2(nowDate) {
  let now = new Date();
  if (nowDate) {
    now = nowDate;
  }
  let year = now.getFullYear(); // 年
  let month = now.getMonth() + 1; // 月
  let day = now.getDate(); // 日
  let hh = now.getHours(); // 时
  let mm = now.getMinutes(); // 分
  let clock = `${year}-`;
  if (month < 10) clock += '0';
  clock += `${month}-`;
  if (day < 10) clock += '0';
  clock += `${day} `;
  if (hh < 10) clock += '0';
  clock += `${hh}:`;
  if (mm < 10) clock += '0';
  clock += mm;
  return clock;
}
/**
 * 根据类型（+，-）获取相应的时间
 * @param {string} type + -
 * @param {Num} milliseconds 24*60*60*1000
 * @return {string} 时间的字符串
 */
function getDateByParams(type, milliseconds) {
  const currentDate = new Date();
  //

  let timeStr = new Date(currentDate).getTime(); // - 24 * 60 * 60 * 1000
  if (type === '+') {
    timeStr += milliseconds;
  } else {
    timeStr -= milliseconds;
  }
  timeStr.get();
  //
  const time = new Date(timeStr);
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  return `${y}-${add0(m)}-${add0(d)} 00:00`;
  // let prevDate = moment(timeStr).format('YYYY-MM_DD 00:00')
  // return prevDate
}
// 获得7天前的日期
export function getAfterDay7() {
  return getDateByParams('+', 24 * 60 * 60 * 1000 * 7);
}
export function add0(m) {
  return m < 10 ? `0${m}` : m;
}

/**
 * 取出要获取的日期
 *  count 需要前推或延迟的天数;
 * false: 前推(默认);
 * true: 延迟;
 * time 当前时间
 * */
export function myBeforFormat(count, flag, time) {
  let isDefeat = 1,
    cur_date = new Date().getTime();
  if (flag) isDefeat = -1;
  const timeStr = time.replace(/-/g, '/');
  if (time) cur_date = new Date(timeStr).getTime();
  return myFormat(cur_date - isDefeat * count * 24 * 60 * 60 * 1000);
}

export function myFormat(data) {
  let unixtimestamp = data ? new Date(data) : new Date();
  if (`${unixtimestamp}`.indexOf('Invalid Date') !== -1) {
    return false;
  }
  // let year = 1900 + unixtimestamp.getYear();
  let month = `0${unixtimestamp.getMonth() + 1}`;
  let date = `0${unixtimestamp.getDate()}`;
  // let hour = `0${unixtimestamp.getHours()}`;
  // let minute = `0${unixtimestamp.getMinutes()}`;
  // let second = `0${unixtimestamp.getSeconds()}`;
  let ymh = `${month.substring(month.length - 2, month.length)}/${date.substring(
    date.length - 2,
    date.length,
  )}`;
  // let hms =
  //   `${hour.substring(hour.length - 2, hour.length)}:${
  //   minute.substring(minute.length - 2, minute.length)}:${
  //   second.substring(second.length - 2, second.length)}`;
  // let total = `${ymh} ${hms}`;
  return ymh;
}
//获取当前月份的第一天
export function getCurMonthFirstDate() {
  var now = new Date();
  var month = now.getMonth() + 1; //js获取到的是月份是 0-11 所以要加1
  var year = now.getFullYear();
  // var nextMonthFirstDay = new Date([year,month + 1,1].join('-')).getTime();
  // var oneDay = 1000 * 24 * 60 * 60;
  // var monthLast = new Date(nextMonthFirstDay - oneDay).getDate();
  return `${year}-${add0(month)}-${add0(1)} 00:00:00`;
}

//获取当前日期的开始时间 "YYYY-MM-DD 00:00:00"
export function getStartTime(time) {
  return time ? moment(moment(time).clone().startOf('day')).valueOf() : null;
}

//获取当前日期的结束时间，需要是下1天 "YYYY-MM-DD 00:00:00"
export function getEndTime(time) {
  return time ? moment(moment(time).clone().add(1, 'day').startOf('day')).valueOf() : null;
}
