export function isEmptyObject(obj) {
  if (obj === null || obj === '' || typeof obj === 'undefined' || obj === 'undefined') {
    return true;
  }
  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return true;
    }
    return false;
  }
  for (var key in obj) {
    if (key !== undefined) return false;
  }
  return true;
}
export function trim(str) {
  return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
}

// 识别微信浏览器
export function isWeixinBrowser() {
  return /micromessenger/.test(navigator.userAgent.toLowerCase());
}
export function isPhone() {
  return /android|webos|iphone|ipod|blackberry/.test(navigator.userAgent.toLowerCase());
}

export function isPC() {
  if (
    /AppleWebKit.*Mobile/i.test(navigator.userAgent) ||
    /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
      navigator.userAgent,
    )
  ) {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return false;
    } else if (/iPad/i.test(navigator.userAgent)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
