import request from '../../utils/request';

// 获取合单配置列表
export async function getMergeSetList(params) {
  return request({ url: 'mergeSet/list', params });
}

// 获取省市区信息
export async function getAllDistrictInfo(params) {
  return request({ url: 'area/getAllDistrict', params });
}

// 新增合单配置
export async function getMarketAdd(params) {
  return request({ url: 'mergeSet/add', params });
}

// 修改合单配置
export async function updateMergeSet(params) {
  return request({ url: 'mergeSet/update', params });
}

// 根据合单ID获取档口列表
export async function stallListByRuleId(params) {
  return request({ url: 'mergeSet/stallListByRuleId', params });
}

// 根据合单ID保存档口信息
export async function associatedStall(params) {
  return request({ url: 'mergeSet/associatedStall', params });
}

// 根据合单ID删除档口信息
export async function removeStallSet(params) {
  return request({ url: 'mergeSet/removeStallSet', params });
}
