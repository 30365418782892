// 查看详情-合同管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Input, message, Table, Modal, Row, Col, Radio } from 'antd';
import {
  settlementFeeList,
  settlementFeeDictDataFinance,
  addSettleFee,
  updateSettleFee,
  settleFeeDetail,
} from '../../../../state/action/stall';

import './device.less';

const FormItem = Form.Item;

const layout = { xs: 24, sm: 12, md: 24, lg: 24, xl: 24 };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { stall: state.stall };
  },
  {
    getSettlementFeeList: settlementFeeList,
    getSettlementFeeDictDataFinance: settlementFeeDictDataFinance,
    addSettleFee: addSettleFee,
    updateSettleFee: updateSettleFee,
    settleFeeDetail: settleFeeDetail,
  },
)
@Form.create()
class SettlementFee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      record: {},
      content: '',
    };
  }

  componentDidMount() {
    this.settlementFeeList({
      stallId: this.props.stallId,
    });
    this.settlementFeeDictDataFinance({
      stallId: this.props.stallId,
    });
  }

  async settlementFeeList(params) {
    this.setState({ loading: true });
    await this.props.getSettlementFeeList(params);
    this.setState({ loading: false });
  }

  async settlementFeeDictDataFinance(params) {
    this.setState({ loading: true });
    await this.props.getSettlementFeeDictDataFinance(params);
    this.setState({ loading: false });
  }

  //点击新增和编辑Modal弹框
  showModal = (record, visible, content) => {
    this.setState({
      record,
      visible,
      content,
    });
  };

  // 新增, 修改
  addPact = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        const { record } = this.state;
        const isModified = JSON.stringify(record) === '{}';

        const params = {
          ...value,
          stallId: this.props.stallId,
        };

        if (isModified) {
          //新增
          this.addSettleFee(params);
        } else {
          this.updateSettleFee({ ...params, id: record.id });
        }
      }
    });
  };

  async addSettleFee(params) {
    const result = await this.props.addSettleFee(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('新增成功');
      this.settlementFeeList({
        //刷新列表
        stallId: this.props.stallId,
      });
    } else {
      // message.error(result.msg);
    }
  }

  async updateSettleFee(params) {
    const result = await this.props.updateSettleFee(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('编辑成功');
      this.settlementFeeList({
        //刷新列表
        stallId: this.props.stallId,
      });
    }
  }

  async getSettleFeeDetail(record) {
    const result = await this.props.settleFeeDetail({ id: record.id });
    if (result.code === 200) {
      this.showModal(result.data, true, '修改结算费用');
    }
  }

  // 为了适用checkbox的数据源结构修改数据
  radioGroupDataTransformation = (settlementFeeType = {}) => {
    let ruleList =
      settlementFeeType.ruleList &&
      settlementFeeType.ruleList.map((item) => {
        let obj = {};
        obj.label = item.desc;
        obj.value = item.code;
        return obj;
      });

    let attributeList =
      settlementFeeType.attributeList &&
      settlementFeeType.attributeList.map((item) => {
        let obj = {};
        obj.label = item.desc;
        obj.value = item.code;
        return obj;
      });

    let calcRuleList =
      settlementFeeType.calcRuleList &&
      settlementFeeType.calcRuleList.map((item) => {
        let obj = {};
        obj.label = item.desc;
        obj.value = item.code;
        return obj;
      });
    return {
      ruleList: ruleList || [],
      attributeList: attributeList || [],
      calcRuleList: calcRuleList || [],
    };
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { record, visible, content, loading } = this.state;
    const {
      stall: { settlementFeeList = {}, settlementFeeType = {} },
    } = this.props;
    const dataSource = settlementFeeList.pageList || [];

    const columns = [
      {
        title: '结算费用名称',
        dataIndex: 'settleName',
        key: 'settleName',
      },
      {
        title: '默认费用类型',
        dataIndex: 'financeRuleName',
        key: 'financeRuleName',
      },
      {
        title: '返利/支出',
        dataIndex: 'financeAttributeName',
        key: 'financeAttributeName',
      },
      {
        title: '启用/禁用',
        dataIndex: 'enable',
        key: 'enable',
        render: (_, record) => {
          let enableText = '';
          if (record.enable) {
            enableText = '启用';
          } else {
            enableText = '禁用';
          }
          return <span>{enableText}</span>;
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        render: (_, record) => {
          return (
            <span>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.getSettleFeeDetail(record);
                }}
              >
                修改
              </a>
            </span>
          );
        },
      },
    ];

    // 费用类型
    let feeTypeOptions = this.radioGroupDataTransformation(settlementFeeType).ruleList || [];
    // 扣减/返利
    let expendTypeOptions =
      this.radioGroupDataTransformation(settlementFeeType).attributeList || [];
    // 启用/禁用
    let enableOptions = [
      { label: '启用', value: true },
      { label: '禁用', value: false },
    ];
    // 非定装计算规则
    const computedRuleOptions = this.radioGroupDataTransformation(settlementFeeType).calcRuleList;

    return (
      <div className='basketSettings'>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              type='primary'
              icon='plus'
              onClick={() => this.showModal({}, true, '添加结算费用')}
            >
              添加结算费用
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(_, index) => index}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
        />
        {visible && (
          <Modal
            title={content}
            visible={visible}
            onCancel={() => this.showModal({}, false)}
            footer={null}
            className={'deviceModalStyle basketSettingsModal'}
            width='500px'
            style={{ maxHeight: 700 }}
          >
            <Form layout='inline' onSubmit={this.addPact}>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='费用名称'>
                    {getFieldDecorator('settleName', {
                      initialValue: record.settleName || '',
                      rules: [
                        {
                          required: true,
                          message: '请输入费用名称',
                        },
                        {
                          pattern: /^[\u4e00-\u9fa5a-zA-Z-z0-9]+$/i,
                          message: '只能输入中文、字母、数字!',
                        },
                      ],
                    })(
                      <Input
                        placeholder='请输入费用名称'
                        maxLength={5}
                        allowClear
                        disabled={record.used | false}
                      />,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='默认费用类型'>
                    {getFieldDecorator('financeRuleCode', {
                      initialValue:
                        JSON.stringify(record) === '{}'
                          ? feeTypeOptions[0].value
                          : record.financeRuleCode,
                      rules: [{ required: true, message: '必须选择一项' }],
                    })(<Radio.Group options={feeTypeOptions} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='非定装计算规则'>
                    {getFieldDecorator('calcTypeDictCode', {
                      initialValue:
                        JSON.stringify(record) === '{}'
                          ? computedRuleOptions[0].value
                          : record.financeCalcTypeCode || computedRuleOptions[0].value,
                      rules: [{ required: true, message: '必须选择一项' }],
                    })(
                      <Radio.Group
                        options={computedRuleOptions}
                        disabled={JSON.stringify(record) !== '{}'}
                      />,
                    )}
                  </FormItem>
                  <label>!定装、拆包为按数量计算，散装、郊菜为按重量计算不支持选择</label>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='支出/返利'>
                    {getFieldDecorator('financeAttributeCode', {
                      initialValue:
                        JSON.stringify(record) === '{}'
                          ? expendTypeOptions[0].value
                          : record.financeAttributeCode,
                      rules: [{ required: true, message: '必须选择一项' }],
                    })(<Radio.Group options={expendTypeOptions} disabled={record.used | false} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='启用/禁用'>
                    {getFieldDecorator('enable', {
                      initialValue:
                        JSON.stringify(record) === '{}' ? enableOptions[0].value : record.enable,
                      rules: [{ required: true, message: '必须选择一项' }],
                    })(<Radio.Group options={enableOptions} />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button onClick={() => this.showModal({}, false)}>关闭</Button>
                <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

export default SettlementFee;
