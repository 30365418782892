/*
 * @Description: t
 * @version:
 * @Author: lijing
 * @Date: 2022-07-15 14:04:16
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-19 09:48:23
 */
import moment from 'moment';
import { fixedZeroTo4Bit } from '../../../src/utils/utils';

const operationTypes = [
  { value: '', label: '全部' },
  { value: 1, label: '修改买家姓名' },
  { value: 2, label: '修改货主姓名' },
  { value: 3, label: '修改订单' },
  { value: 4, label: '作废订单' },
  { value: 5, label: '撤销还款' },
  { value: 6, label: '取消结算' },
  { value: 7, label: '买家合并' },
  { value: 8, label: '买家拆解' },
  { value: 10, label: '删除支出' },
  { value: 11, label: '删除收入' },
  { value: 12, label: '补录还款' },
];

const dateFormat = 'YYYY-MM-DD HH:mm';

const actionMap = {
  1: (before, after) => `将【${before.buyerName}】修改为【${after.buyerName}】`,
  2: (before, after) => `将【${before.supplierName}】修改为【${after.supplierName}】`,
  3: (before) =>
    `将 ${moment(before.createTime).format(dateFormat)} ${
      before.buyerName
    }的订单【${fixedZeroTo4Bit(before.todayNo, 4)}】改单`,
  4: (before) =>
    `将 ${moment(before.createTime).format(dateFormat)} ${
      before.buyerName
    } 的订单【${fixedZeroTo4Bit(before.todayNo, 4)}】作废`,
  5: (before) =>
    `将 ${moment(before.createTime).format(dateFormat)} ${before.buyerName}  还款的【${
      before.amount
    }元】撤销`,
  6: (before) =>
    `将 批次【${before.supplierName} ${fixedZeroTo4Bit(before.carNo, 4)}】的结算单 ${
      before.settleNo ? `【${fixedZeroTo4Bit(before.settleNo, 3)}】` : ''
    }取消结算`,
  7: (before) => `买家【${before.masterName}】将【${before.buyerName}】合并了`,
  8: (before) => `买家【${before.masterName}】将【${before.buyerName}】拆解了`,
  10: (before) =>
    `将 ${moment(before.createTime).format(dateFormat)} 支出的【${before.amount}元】删除`,
  11: (before) =>
    `补录 ${moment(before.createTime).format(dateFormat)} 收入的【${before.amount}元】删除`,
  12: (before) =>
    `补录 ${moment(before.createTime).format(dateFormat)} ${before.buyerName}还款【${
      before.amount
    }元】`,
};

export { operationTypes, actionMap };
