import * as actionTypes from '../constant/global';

//初始状态
const initState = {
  stall: {},
  collapsed: true,
};
//reducer
export function global(state = initState, action) {
  switch (action.type) {
    case actionTypes.GLOBAL_SAVE_STALL:
      return { ...state, stall: action.payload };
    case actionTypes.GLOBAL_CHANGE_LAYOUT_COLLAPSED:
      return { ...state, collapsed: action.payload };
    case actionTypes.GLOBAL_CLEAR_NOTICES:
    case actionTypes.GLOBAL_SAVE_NOTICES:
      const {
        data: { notices, nums },
      } = action.payload;
      return { ...state, notices, noticesNum: nums };
    default:
      return state;
  }
}
