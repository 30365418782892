//欢迎页面
import React, { PureComponent } from 'react';
import PageHeaderLayout from '../../component/page-header-layout/index';
import './style.less';

export default class Welcome extends PureComponent {
  render() {
    return (
      <PageHeaderLayout>
        <div className='welcome'>
          <div className='title'>欢迎登录菜小秘管理平台</div>
        </div>
      </PageHeaderLayout>
    );
  }
}
