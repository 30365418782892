import request from '../../utils/request';

export async function getAllStallConfig(params) {
  return request({ url: 'stallConfig/getAllStallConfig', params });
}

export async function getAllStallCalculationResult(params) {
  return request({ url: 'stallConfig/getAllStallCalculationResult', params });
}

export async function saveStallCalcTypeConfig(params) {
  return request({ url: 'stallCalcTypeConfig/saveStallCalcTypeConfig', params });
}

export async function getAllCalcTypeConfig(params) {
  return request({ url: 'stallCalcTypeConfig/getAllCalcTypeConfig', params });
}

// 获取档口列表
export async function getStallsByMarket(params) {
  return request({ url: 'stalls/getSimpleStallsByMarket', params });
}

export async function getAllMarketByCityCode(params) {
  return request({ url: 'market/getAllMarketByCityCode', params });
}

// 给档口分配合单配置
export async function getAssociatedStall(params) {
  return request({ url: 'mergeSet/associatedStall', params });
}

//修改档口配置
export async function updateStallCalcTypeConfig(params) {
  return request({ url: 'stallCalcTypeConfig/updateStallCalcTypeConfig', params });
}

//查询收银端显示规则配置
export async function getAllStallItem(params) {
  return request({ url: 'cxmRuleDisplay/getAllStallItem', params });
}

//新增收银端显示规则配置
export async function saveStallItem(params) {
  return request({ url: 'cxmRuleDisplay/saveStallItem', params });
}

//修改收银端显示规则配置
export async function updateStallItem(params) {
  return request({ url: 'cxmRuleDisplay/updateStallItem', params });
}

//查询收银端显示规则配置
export async function getAllGroupStallItem(params) {
  return request({ url: 'cxmRuleGroupDisplay/getAllStallItem', params });
}

//新增收银端显示规则配置
export async function saveGroupStallItem(params) {
  return request({ url: 'cxmRuleGroupDisplay/saveStallItem', params });
}

//修改收银端显示规则配置
export async function updateGroupStallItem(params) {
  return request({ url: 'cxmRuleGroupDisplay/updateStallItem', params });
}

//启用/禁用 包出门,甩货
export async function updateSaleType(params) {
  return request({ url: 'stallCalcTypeConfig/updateSaleType', params });
}
