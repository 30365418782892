//商行管理---会员管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import {
  Form,
  Input,
  Button,
  Card,
  Table,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Tooltip,
  message,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { strSplit } from '../../utils/utils';
import './index.less';
import { isEmptyObject } from '../../utils/reg';

import { getListMemebers } from '../../state/action/memberManager';
import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';

const CheckboxGroup = Checkbox.Group;

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const format1 = 'YYYY-MM-DD 00:00:00';
const format2 = 'YYYY-MM-DD 23:59:59';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@connect(
  (state) => {
    return { basic: state.basic, memberManager: state.memberManager };
  },
  { getListMemebers },
)
@Form.create()
@withRouter
class MemberManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expand: false, //筛选图标
      search: {
        stallName: '',
        phone: '',
        memberStatus: [],
        productType: '',
        startTime: null,
        endTime: null,
      },
      defaultCurrent: 1,
      memberStatusList: [],
      startDate: null,
      endDate: null,
    };
  }

  async componentDidMount() {
    const {
      memberManager: { searchList = {} },
    } = this.props;
    if (!isEmptyObject(searchList)) {
      await this.setState({
        search: { ...searchList },
        defaultCurrent: searchList.pageNo,
        memberStatusList: searchList.memberStatus,
        startDate: searchList.startTime ? moment(searchList.startTime).format(dateFormat) : null,
        endDate: searchList.endTime ? moment(searchList.endTime).format(dateFormat) : null,
      });
    }
    const { search, defaultCurrent } = this.state;
    this.getListMemebers({ ...search, pageNo: defaultCurrent, pageSize: 10 }); //会员管理列表
  }
  //获取会员管理table列表
  async getListMemebers(params) {
    this.setState({ loading: true });
    await this.props.getListMemebers(params);
    this.setState({ loading: false });
  }

  //筛选收起
  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['stallName', 'phone', 'pactStartTime', 'memberStatus', 'productType'],
      (errors, values) => {
        if (!!errors) {
          return;
        }
        const { stallName, phone, pactStartTime, memberStatus, productType } = values;
        if (values.phone) {
          if (!/^((0\d{2,3}-\d{8})|(0\d{2,3}\d{8})|((1[0-9]))\d{9})$/.test(values.phone)) {
            message.warning('请输入正确的电话号码!');
            return;
          }
        }

        const params = {
          stallName: stallName || '',
          phone: phone || '',
          startTime:
            (pactStartTime && pactStartTime.length) === 2
              ? moment(pactStartTime[0].format(format1)).valueOf()
              : '',
          endTime:
            (pactStartTime && pactStartTime.length) === 2
              ? moment(pactStartTime[1].format(format2)).valueOf() + 1000
              : '',
          memberStatus: memberStatus || [],
          productType: productType === 0 ? 0 : productType || '',
          pageNo: 1,
          pageSize: 10,
        };
        this.setState({
          search: { ...params },
          defaultCurrent: 1,
        });
        this.getListMemebers(params);
      },
    );
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.setState({ defaultCurrent: obj.current }, () => {
      const { search } = this.state;
      this.getListMemebers({ ...search, pageSize: 10, pageNo: obj.current });
    });
  };

  disabledDate = (current) => {
    return current.valueOf() > new Date().getTime();
  };

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.getListMemebers({ pageSize: 10, pageNo: 1 });
    this.setState({
      memberStatusList: [],
      startDate: null,
      endDate: null,
      search: {
        stallName: '',
        phone: '',
        memberStatus: [],
        productType: '',
        startTime: null,
        endTime: null,
      },
    });
  };

  //查看详情
  writeDetail = (record) => {
    this.props.history.push({ pathname: '/booth/memberManagementDetail', state: { record } });
  };
  // 复选框
  checkBoxChange = (checkedValue) => {
    this.setState({
      memberStatusList: checkedValue,
    });
  };
  // 导出
  exportData = () => {
    const values = this.props.form.getFieldsValue();
    const { stallName, phone, pactStartTime, memberStatus, productType } = values;
    const params = {
      stallName: stallName || null,
      phone: phone || null,
      startTime:
        (pactStartTime && pactStartTime.length) === 2
          ? moment(pactStartTime[0].format(format1)).valueOf()
          : null,
      endTime:
        (pactStartTime && pactStartTime.length) === 2
          ? moment(pactStartTime[1].format(format2)).valueOf() + 1000
          : null,
      memberStatus: memberStatus || [],
      productType: productType === 0 ? 0 : productType || null,
    };
    window.open(
      API_ADMIN +
        'stallMember/exportMemebers?' +
        generalParamsWithPToStr('stallMember/exportMemebers', params),
    );
  };
  render() {
    const {
      memberManager: { memebersList = {} },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { defaultCurrent, search, memberStatusList, startDate, endDate } = this.state;
    const { pageList, pageSize, totalSize = 1 } = memebersList;

    const options = [
      { label: '生效中', value: 1 },
      { label: '已过期', value: 0 },
    ];
    // 表格的分页的设计
    const pagination = {
      pageSize: pageSize,
      total: totalSize,
      current: defaultCurrent,
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        render: (_, __, index) => {
          return ++index;
        },
      },
      {
        title: '商行ID',
        dataIndex: 'stallId',
        key: 'stallId',
        width: 90,
        render: (text) => {
          return text ? text : '- -';
        },
      },
      {
        title: '商行名称',
        dataIndex: 'stallName',
        key: 'stallName',
        width: 180,
        render: (text) => {
          return text ? text : '- -';
        },
      },
      {
        title: '市场',
        dataIndex: 'marketName',
        key: 'marketName',
        width: 120,
        render: (text) => {
          const textTip = <span>{text}</span>;
          return (
            <Tooltip placement='rightTop' title={textTip}>
              <span>{strSplit(text, 8)}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '档主名称',
        dataIndex: 'personName',
        key: 'personName',
        width: 180,
        render: (text) => {
          return text ? text : '- -';
        },
      },
      {
        title: '档主电话',
        dataIndex: 'personPhone',
        key: 'personPhone',
        width: 150,
      },
      {
        title: '开户日期',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 200,
        render: (text) => {
          return text ? moment(text).format(dateFormat) : '- -';
        },
      },
      {
        title: '会员到期时间',
        dataIndex: 'expireTime',
        key: 'expireTime',
        width: 200,
        render: (_, record) => {
          let { expireTime } = record;
          return expireTime ? moment(expireTime).format(dateFormat) : '- -';
        },
      },
      {
        title: '剩余有效期',
        dataIndex: 'productDuration',
        key: 'productDuration',
        width: 120,
        render: (text) => {
          return text + '天';
        },
      },
      {
        title: '是否生效',
        dataIndex: 'memberStatus',
        key: 'memberStatus',
        width: 90,
        render: (_, record) => {
          const { memberStatus } = record;
          if (memberStatus === 1) {
            return <span>生效中</span>;
          }
          if (memberStatus === 0) {
            return <span style={{ color: '#EC634A' }}>已失效</span>;
          }
          return <span>试用中</span>;
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        width: 100,
        render: (_, record) => {
          return (
            <a
              href='/'
              onClick={(e) => {
                e.preventDefault();
                this.writeDetail(record);
              }}
            >
              查看详情
            </a>
          );
        },
      },
    ];

    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };

    return (
      <div className='memberManagement'>
        <PageHeaderLayout>
          <div>
            <Card className='listCard' bordered={false}>
              <div className='searchModule'>
                <Form layout='inline' onSubmit={this.handleSearch} {...formItemLayout}>
                  <Row gutter={12}>
                    <Col {...layout}>
                      <FormItem label='商行名称'>
                        {getFieldDecorator('stallName', {
                          initialValue: search.stallName,
                          rules: [{ required: false, message: '请输入商行名称!' }],
                        })(<Input placeholder='请输入商行名称' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='档主电话'>
                        {getFieldDecorator('phone', {
                          initialValue: search.phone || '',
                          rules: [{ required: false, message: '请输入档主电话!' }],
                        })(<Input placeholder='请输入档主电话' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='到期时间'>
                        {getFieldDecorator('pactStartTime', {
                          initialValue:
                            startDate && endDate
                              ? [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]
                              : null,
                          rules: [{ required: false, message: '请选择时间' }],
                        })(
                          <RangePicker
                            format='YYYY-MM-DD'
                            placeholder={['开始时间', '结束时间']}
                            style={{ width: '110%' }}
                          ></RangePicker>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='会员状态'>
                        {getFieldDecorator('memberStatus', {
                          initialValue: memberStatusList,
                          rules: [{ required: false, message: '' }],
                        })(<CheckboxGroup options={options} onChange={this.checkBoxChange} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 10 }}>
                    <Button type='primary' htmlType='submit'>
                      查询
                    </Button>
                    <Button style={{ marginLeft: '20px' }} onClick={this.handleReset}>
                      重置
                    </Button>
                    <Button style={{ marginLeft: '20px' }} onClick={this.exportData}>
                      导出
                    </Button>
                  </Row>
                </Form>
              </div>
              <div>
                <Table
                  rowKey={(_, index) => index}
                  dataSource={pageList}
                  columns={columns}
                  pagination={pagination}
                  onChange={this.pageSwitch}
                  loading={this.state.loading}
                  scroll={{ x: 1600 }}
                />
              </div>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default MemberManagement;
