import moment from 'moment';
import request from '../../utils/request';
import { API_EXPORT } from '../../utils/urls';

// 获取增值服务列表
export const getBusinessList = (params) => {
  return request({
    url: 'stall/business/list',
    params,
    method: 'get',
    headers: 'json',
  });
};

// 增值服务编辑
export const getBusinessUpdate = (params) => {
  return request({
    url: 'stall/business/update',
    params,
    method: 'put',
    headers: 'json',
  });
};

// 增值服务排序
export const getBusinessSequenceUpdate = (params) => {
  return request({
    url: 'stall/business/sequence/update',
    params,
    method: 'put',
    headers: 'json',
  });
};

// 增值服务启用/禁用
export const getBusinessEnable = (params) => {
  return request({
    url: 'stall/business/enable',
    params,
    method: 'put',
    headers: 'json',
  });
};

// 已开通商行列表
export const getOpenStallList = (params) => {
  const { pageNo, pageSize, ...rest } = params;
  return request({
    url: `stall/business/openStall/list/${pageNo}/${pageSize}`,
    params: rest,
    method: 'get',
    headers: 'json',
  });
};

// 已开通商行列表导出
export const getOpenStallDownload = (params) => {
  return request({
    url: 'stall/business/openStall/download',
    params,
    method: 'get',
    headers: 'json',
    responseType: 'blob',
  });
};

// 商行管理-增值服务
export const getBusinessStallList = (params) => {
  return request({
    url: 'stall/business/stall/list',
    params,
    method: 'get',
    headers: 'json',
  });
};

// 增值服务-订单列表
export const getPayRecordBusinessList = (params) => {
  const { startTime, endTime, ...rest } = params;
  const st = +moment(startTime).startOf('day').format('x');
  const et = +moment(endTime).endOf('day').format('x') + 1;
  return request({
    url: `payRecord/business/list`,
    params: { ...rest, startTime: st, endTime: et },
    method: 'get',
    headers: 'json',
  });
};

// 增值服务-订单合计
export const getPayRecordBusinessTotal = (params) => {
  const { startTime, endTime, ...rest } = params;
  const st = +moment(startTime).startOf('day').format('x');
  const et = +moment(endTime).endOf('day').format('x') + 1;
  return request({
    url: `payRecord/business/total`,
    params: { ...rest, startTime: st, endTime: et },
    method: 'get',
    headers: 'json',
  });
};

// 增值服务-订单列表导出
export const getPayRecordBusinessDownload = (params) => {
  return request({
    url: 'payRecord/business/download',
    params,
    method: 'get',
    headers: 'json',
    responseType: 'blob',
  });
};

// 广告管理列表
export const getAdvertiseList = (params) => {
  return request({
    url: 'advertise/list',
    params,
    method: 'get',
    headers: 'json',
  });
};

// 广告新增
export const getAdvertiseAdd = (params) => {
  return request({
    url: 'advertise/add',
    params,
    method: 'post',
    headers: 'json',
  });
};

// 广告修改
export const getAdvertiseUpdate = (params) => {
  return request({
    url: 'advertise/update',
    params,
    method: 'put',
    headers: 'json',
  });
};

// 广告停用/启用
export const getAdvertiseEnable = (params) => {
  return request({
    url: 'advertise/enable ',
    params,
    method: 'put',
    headers: 'json',
  });
};

// 新签交付统计表
export const getDeliveryStatList = (params) => {
  return request({
    url: 'deliveryStat/list',
    params,
    method: 'post',
    headers: 'json',
  });
};

// 新签交付统计表导出
export const getDeliveryStatDownload = (params) => {
  return request({
    url: 'deliveryStat/download',
    params,
    method: 'post',
    headers: 'json',
    responseType: 'blob',
  });
};

// (竞品)商户数据导出
export const getSystemDataExportQuery = (params) => {
  return request({
    baseURL: API_EXPORT,
    url: 'export/xds/data',
    params,
    method: 'post',
    headers: 'json',
  });
};

// (竞品)商户数据导出 - 轮询
export const getSystemDataExport = (id) => {
  return request({
    baseURL: API_EXPORT,
    url: `export/result?id=${id}`,
    method: 'get',
    headers: 'json',
    responseType: 'blob',
  });
};