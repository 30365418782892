/**
 * 手机收银端白名单
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Button, Select, Table, Modal } from 'antd';
import PageHeaderLayout from '../../../component/page-header-layout/index';

import { getStallList } from '../../../state/action/basic';
import {
  marketList,
  addMobileCashAppStall,
  removeMobileCashAppStall,
  removeAllMobileCashAppStall,
  fetchMobileCashAppList,
} from '../../../state/action/stall';
import './mobileCashApp.less';
const FormItem = Form.Item;
const Option = Select.Option;
const { Column } = Table;

@connect(
  (state) => {
    return { stall: state.stall, basic: state.basic };
  },
  {
    fetchMarketList: marketList,
    fetchStallList: getStallList,
    addStall: addMobileCashAppStall,
    removeStall: removeMobileCashAppStall,
    removeAllStall: removeAllMobileCashAppStall,
    fetchMobileCashAppList: fetchMobileCashAppList,
  },
)
@Form.create()
class MobileCashApp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      record: {},
      addVisible: false,
      removeVisible: false,
      marketId: 0,
      pageNo: 1,
      pageSize: 10,
      pageList: [],
      originPageList: [],
    };
  }

  async componentDidMount() {
    this.getMarketList(); //获得市场列表
    this.searchList();
    this.getStallList({ marketId: 0 });
  }

  // 获得市场列表
  getMarketList = () => {
    this.setState({ loading: true });
    this.props.fetchMarketList({});
    this.setState({ loading: false });
  };

  // 改变市场
  changeMarket = (marketId, type = 'search') => {
    this.getStallList({ marketId: marketId });
    // 搜索
    if (type === 'search') {
      // 查询联动商行
      this.props.form.setFieldsValue({
        stallId: undefined,
      });
      if (marketId !== this.state.marketId) {
        this.setState({
          marketId: marketId,
        });
      }
    }
    // 新增
    else {
      // 新增联动商行
      this.props.form.setFieldsValue({
        stallIds: undefined,
      });
    }
  };

  // 获取商行列表
  getStallList = async (params) => {
    this.setState({ loading: true });
    await this.props.fetchStallList(params);
    this.setState({ loading: false });
  };

  // 查询列表数据
  searchList = async () => {
    this.setState({ loading: true });
    await this.props.fetchMobileCashAppList({});
    let pageList = this.filterMobileCahsAppData(this.getReduxData());
    this.setState({ loading: false, pageList: pageList, pageNo: 1 });
  };

  // 获取redux的数据
  getReduxData = () => {
    const { mobileCahsAppData = {} } = this.props.stall || {};
    return mobileCahsAppData || [];
  };

  // 查询事件
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, values) => {
      let realList = this.filterMobileCahsAppData(this.getReduxData());
      this.setState({ pageList: realList, pageNo: 1 });
    });
  };

  // 重置函数
  resetList = () => {
    this.props.form.resetFields();
    this.setState({
      pageList: this.getReduxData(),
      pageNo: 1,
    });
    this.getMarketList(); //获得市场列表
    this.getStallList({ marketId: 0 });
    // 获取全部商行
    this.props.form.validateFields((errors, values) => {
      this.getStallList(values['marketId'] || 0);
    });
  };

  //  新增商行
  addStall = () => {
    this.props.form.validateFields(async (errors, values) => {
      let prams = {
        marketId: values['marketIds'],
        stallId: values['stallIds'],
      };
      let response = await this.props.addStall(prams);
      if (response.code === 200) {
      }
      this.searchList();
      this.changeMarket(this.state.marketId, 'add');

      this.controllAddModal(false);
    });
  };

  // 移除商行
  offLineStall = async () => {
    const { record } = this.state;
    const { stallId } = record;
    let response = await this.props.removeStall({
      stallId,
    });
    if (response.code === 200) {
      this.searchList();
    }
    this.controllRemoveModal({}, false);
  };

  // 一键下线
  offLineAllStall = async () => {
    let response = await this.props.removeAllStall({});
    if (response.code === 200) {
      this.searchList();
    }
  };

  // 显示新增弹层
  controllAddModal = (record, visible) => {
    this.setState({
      addVisible: visible,
      record,
    });
  };

  // 显示移除弹层
  controllRemoveModal = (record, visible) => {
    this.setState({
      record,
      removeVisible: visible,
    });
  };

  // 根据marketId和stallId进行数据过滤
  filterMobileCahsAppData = (pageList = []) => {
    let realPageList = pageList;
    this.props.form.validateFields((_, values) => {
      // 根据市场id做筛选
      if (values['marketId']) {
        realPageList = realPageList.filter((item = {}) => {
          return values['marketId'] === item.marketId;
        });
      }
      // 根据商行id做筛选
      if (values['stallId']) {
        realPageList = realPageList.filter((item = {}) => {
          return values['stallId'] === item.stallId;
        });
      }
    });
    // 最终return数据
    return realPageList;
  };

  render() {
    const {
      stall: { marketData = [] },
    } = this.props;
    const { pageNo = 1 } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { record } = this.state;
    const marketOption = marketData.map((market) => (
      <Option value={market.id} key={market.id}>
        {market.name}
      </Option>
    ));
    let stallList = [];
    if (this.props.basic.stallList.code === 200) {
      stallList = this.props.basic.stallList.data;
    }

    // 列表数据市场市场过滤
    let realPageList = this.state.pageList;
    const pagination = {
      pageSize: 10,
      pageNo: pageNo,
      current: pageNo,
      total: realPageList.length,
    };

    const stallOption = stallList.map((stall) => (
      <Option value={stall.id} key={stall.id}>
        {stall.name}
      </Option>
    ));

    const formItemLayout2 = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
    const extraContent = (
      <div className={'searchModule'}>
        <Form name='condition' layout='inline' {...formItemLayout2} onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col {...layout}>
              <FormItem label='市场名称'>
                {getFieldDecorator(
                  'marketId',
                  {},
                )(
                  <Select
                    placeholder='请选择市场'
                    onChange={(obj) => {
                      this.changeMarket(obj);
                    }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {marketOption}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...layout}>
              <FormItem label='商行名称'>
                {getFieldDecorator(
                  'stallId',
                  {},
                )(
                  <Select
                    placeholder='请选择商行'
                    onChange={() => {}}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {stallOption}
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button
                onClick={() => {
                  this.resetList();
                }}
              >
                重置
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.controllAddModal({}, true);
                }}
              >
                新增
              </Button>
              <Button
                onClick={() => {
                  this.offLineAllStall();
                }}
              >
                一键下线
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div className='booth-index'>
        <PageHeaderLayout>
          <div className={'booth-sys-config'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={realPageList || []}
                pagination={pagination}
                bordered
                onChange={(obj) => {
                  this.setState({
                    pageNo: obj.current,
                  });
                }}
              >
                <Column title='商行ID' dataIndex='stallId' key='stallId' />
                <Column title='市场名称' dataIndex='marketName' key='marketName' />
                <Column title='商行全称' dataIndex='stallName' key='stallName' />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(text, record) => {
                    return (
                      <div>
                        <a
                          style={{ color: 'red' }}
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.controllRemoveModal(record, true);
                          }}
                        >
                          移除
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Card>
              <Modal
                title={'新增商行'}
                visible={this.state.addVisible}
                footer={null}
                onCancel={() => {
                  this.controllAddModal({}, false);
                  this.changeMarket(this.state.marketId, 'add');
                }}
                destroyOnClose='true'
                width='600px'
              >
                <div className={'extraContent'}>
                  <Form name='addStall' onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label='市场名称'>
                      {getFieldDecorator('marketIds', {
                        initialValue: this.state.record.marketId,
                        rules: [
                          {
                            required: this.state.addVisible ? true : false,
                            message: '请选择市场!',
                          },
                        ],
                      })(
                        <Select
                          allowClear
                          placeholder='请选择市场'
                          onChange={(obj) => {
                            this.changeMarket(obj, 'add');
                          }}
                        >
                          {marketData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='商行名称' onChange={() => {}}>
                      {getFieldDecorator('stallIds', {
                        initialValue: this.state.record.stallId,
                        rules: [
                          {
                            required: this.state.addVisible ? true : false,
                            message: '请选择商行!',
                          },
                        ],
                      })(
                        <Select
                          allowClear
                          placeholder='请选择商行'
                          disabled={this.props.form.getFieldValue('marketIds') ? false : true}
                        >
                          {stallList.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem>
                      <Row style={{ textAlign: 'center' }}>
                        <Button
                          onClick={() => {
                            this.controllAddModal(false);
                            this.changeMarket(this.state.marketId, 'add');
                          }}
                        >
                          关闭
                        </Button>
                        <Button
                          disabled={
                            this.props.form.getFieldValue('marketIds') &&
                            this.props.form.getFieldValue('stallIds')
                              ? false
                              : true
                          }
                          type='primary'
                          onClick={() => {
                            this.addStall();
                          }}
                          style={{ marginLeft: '18px' }}
                        >
                          保存
                        </Button>
                      </Row>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
              <Modal
                title={'移除商行'}
                visible={this.state.removeVisible}
                footer={null}
                onCancel={() => {
                  this.controllRemoveModal({}, false);
                }}
                destroyOnClose='true'
                width='600px'
              >
                <div className={'extraContent'} style={{ width: '100%' }}>
                  <Form onSubmit={this.handleSubmit}>
                    <FormItem style={{ width: '100%' }} {...formItemLayout}>
                      <Row style={{ textAlign: 'center' }}>
                        从白名单移除后，该商行手机收端将无法使用，确定要移除【
                        {record.stallName || ''}】
                      </Row>
                    </FormItem>
                    <FormItem>
                      <Row style={{ textAlign: 'center' }}>
                        <Button
                          onClick={() => {
                            this.controllRemoveModal({}, false);
                          }}
                        >
                          取消
                        </Button>
                        <span class='remove'>
                          <Button
                            className='mobileCashAppRemoveStallButton'
                            onClick={() => {
                              this.offLineStall();
                            }}
                            style={{ marginLeft: '18px' }}
                          >
                            移除
                          </Button>
                        </span>
                      </Row>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default MobileCashApp;
