// 商行管理（查看详情--商行员工管理)
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Table, Modal, Row, Col, message, Popconfirm, Input, Select } from 'antd';
import { getStallEmploys, employResetBatch } from '@/state/request/stall';
import { cashierHandle } from '@/state/action/basic';
import { hasPermissionCode } from '../../../../utils/authority';
import './device.less';

const FormItem = Form.Item;
const layout = { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 };

@connect(
  (state) => {
    return { basic: state.basic };
  },
  { cashierHandle },
)
@Form.create()
class EmployeeManage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      personId: null,
      dataList: [],
      currentPageNo: 1
    };
  }

  componentDidMount() {
    this.getStallEmployList();
  }

  async getStallEmployList(params = {}) {
    const { stallId } = this.props;
    this.setState({ loading: true });
    const res = await getStallEmploys(stallId, params);
    const { data } = res;
    this.setState({ loading: false, dataList: data || [] });
  }

  async modalConfirm() {
    this.setState({ showModal: false });
    const { stallId } = this.props;
    const { personId } = this.state;
    const res =  await employResetBatch({ stallId, personId });
    const { code, msg } = res || {};
    if (code === 200) {
      message.info('操作成功');
    }
    this.setState({ personId: null });
  }

  async handleCashier(personId) {
    const { stallId } = this.props;
    this.setState({ loading: true });
    await this.props.cashierHandle({
      stallId,
      personId
    });
    const { basic: { cashierHandle } } = this.props;
    if (cashierHandle?.code === 200) {
      message.success('交账成功');
    }
    this.setState({ loading: false });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.getStallEmployList();
    this.setState({ currentPageNo: 1 });
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      // 查询
      this.getStallEmployList({
        ...fieldsValue,
        enable: fieldsValue.enable ? fieldsValue.enable : undefined
      });
    });
  }

  render() {
    const { loading, showModal, dataList, currentPageNo } = this.state;
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 100,
        render: (_, __, index) => {
          return index + 1;
        },
      },
      {
        title: '员工名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '电话',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '权限',
        dataIndex: 'roleName',
        key: 'roleName',
      },
      {
        title: '状态',
        dataIndex: 'enable',
        key: 'enable',
        width: 100,
        render: (text) => {
          return text === true ? (
            <div>
              <Button
                type='primary'
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5 }}
              />
              启用
            </div>
          ) : (
            <div>
              <Button
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5, background: '#ccc' }}
              />
              禁用
            </div>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        width: 180,
        render: (text, record) => {
          return (
            <>
              {hasPermissionCode('01.044.001') && (
                <Popconfirm
                  title="确定要交账吗？"
                  onConfirm={() => this.handleCashier(record.personId)}
                >
                  <a href="/" style={{paddingRight: '20px'}}>交账</a>
                </Popconfirm>
              )}
              <a href="/" onClick={(e) => { 
                e.preventDefault();
                this.setState({ showModal: true, personId: record.personId });
              }}>重置开票批次</a>
            </>
          );
        }
    },
    ];

    return (
      <div className='deviceManagement'>
        <Form layout='inline' onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col {...layout}>
              <FormItem label='员工名称'>
                {getFieldDecorator('name', {
                  rules: [{ required: false, message: '请输入员工名称!' }],
                })(<Input placeholder='请输入员工名称' allowClear />)}
              </FormItem>
            </Col>
            <Col {...layout}>
              <FormItem label='电话'>
                {getFieldDecorator('phone', {
                  rules: [{ required: false, message: '请输入电话!' }],
                })(<Input placeholder='请输入电话' allowClear />)}
              </FormItem>
            </Col>
            <Col {...layout}>
              <FormItem label='状态'>
                {getFieldDecorator('enable', {
                  initialValue: 0,
                  rules: [{ required: false, message: '请选择状态' }],
                })(
                  <Select
                    placeholder='请选择状态'
                    allowClear
                    showSearch
                  >
                    <Select.Option key={1} value={0}>全部</Select.Option>
                    <Select.Option key={2} value={'true'}>启用</Select.Option>
                    <Select.Option key={3} value={'false'}>禁用</Select.Option>
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...layout}>
              <FormItem>
                <Button type='primary' htmlType='submit'>
                  查询
                </Button>
                <Button style={{ marginLeft: '20px' }} onClick={this.handleReset}>
                  重置
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>

        <Table
          rowKey={(r) => r.id}
          dataSource={dataList}
          columns={columns}
          loading={loading}
          pagination={{
            pageSize: 10, //每页条数
            current: currentPageNo, //当前页码
            onChange:(page) => {
              this.setState({ currentPageNo: page })
            }
          }}
        />
        <Modal
          visible={showModal}
          onCancel={() => this.setState({ showModal: false, personId: null })}
          destroyOnClose='true'
          className='employeeManage-modal'
          width='480px'
          footer={
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => this.setState({ showModal: false, personId: null })}>取消</Button>
              <Button type='primary' style={{ marginLeft: '18px' }} onClick={() => this.modalConfirm()}>
                确定
              </Button>
            </Row>
          }
        >
          <div className='employeeManage-modal-content'>
            <div className='employeeManage-modal-content-title'>重置开票批次</div>
            <div className='employeeManage-modal-content-desc'>
              是否确认重置该员工开票批次，重置后该员工开票批次将被清空
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EmployeeManage;
