import * as actionTypes from '../constant/repay';
import * as req from '../request/repay';
//获取支付流水列表
export function fetchRepayRecord(params) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.REPAY_REQ });
    const res = await req.fetchRepayRecord(params);
    if (res) {
      let { page, total, stallCount = 0 } = res.data;
      let record = {
        repayRecord: page,
      };

      if (total) {
        record.total = total;
      }
      record.stallCount = stallCount;
      dispatch({ type: actionTypes.REPAY_SAVE, payload: record });
    }
  };
}
