import * as actionTypes from '../constant/batchManager';

//初始状态
const initState = {
  supplierList: {},
  batchList: {},
  batchItemList: {},
  batchItemLogsList: {},
  batchSaleList: {},
};

//reducer
export function batchManager(state = initState, action) {
  switch (action.type) {
    case actionTypes.SUPPLIER_GET_LIST:
      return { ...state, supplierList: action.payload.data };
    case actionTypes.BATCH_GET_LIST:
      return { ...state, batchList: action.payload.data };
    case actionTypes.BATCH_ITEM_GET_LIST:
      return { ...state, batchItemList: action.payload.data };
    case actionTypes.BATCH_ITEM_LOGS_GET_LIST:
      return { ...state, batchItemLogsList: action.payload.data };
    case actionTypes.BATCH_SALE_GET_LIST:
      return { ...state, batchSaleList: action.payload.data };
    default:
      return state;
  }
}
