// 商品库管理-商品基础库
import React, { PureComponent } from 'react';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { Form, Input, Button, Table, Row, Col, Popconfirm } from 'antd';
import './aliasMaintenance.less';
import { productMappingAlias, productMappingDeleteAlias } from '../../state/request/dataService';
const FormItem = Form.Item;
const { Column } = Table;

const pageSize = 10;
@Form.create()
class GoodsAliasMaintenance extends PureComponent {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: {}, // 页面数据
      visibleModal: false, // 展示编辑modal
      pageNo: 1, // 请求页码
      loading: true, // 加载中
    };
    // 维护别名的货品
    this.routeState = this.props.location.state;
  }

  componentDidMount() {
    this.getData();
  }

  /**
   * 获取数据源
   */
  getData = () => {
    this.setState({ loading: true });
    const { pageNo } = this.state;
    const { id } = this.routeState;
    this.props.form.validateFields(['productName'], async (errors, values) => {
      if (!!errors) {
        return;
      }
      let param = { productId: id, pageSize, pageNo };
      if (!!values.productName) {
        param.productName = values.productName;
      }
      // 请求货品列表
      const response = await productMappingAlias(param);
      const { code, data } = response;
      if (code === 200) {
        const { pageList = [] } = data;
        if (pageList.length === 0 && pageNo !== 1) {
          this.setState({ pageNo: pageNo - 1 }, () => this.getData());
        } else {
          this.setState({ data, loading: false });
        }
      }
    });
  };

  /**
   * 开始搜索
   */
  handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ pageNo: 1 }, () => this.getData());
  };

  /**
   * 重置
   */
  handleReset = () => {
    this.props.form.resetFields();
    this.handleSearch();
  };

  /**
   * 切换页面
   * @param {*} obj
   */
  pageSwitch = (obj) => {
    this.setState({ pageNo: obj.current }, () => this.getData());
  };
  /**
   * 别名删除
   */
  aliasToDelete = async (record) => {
    const { id } = record;
    const response = await productMappingDeleteAlias(id);
    if (response.code === 200) {
      this.getData();
    }
  };

  /**
   * 返回
   */
  back = () => {
    this.props.history.push({ pathname: '/data-service/goodsLibrary', state: this.routeState });
  };

  /**
   * render
   * @returns
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      data: { pageList, totalSize },
      loading,
      pageNo,
    } = this.state;
    const pagination = {
      current: pageNo,
      pageSize,
      total: totalSize,
    };
    const { productName, categoryName = '' } = this.routeState;
    return (
      <div className='goods-alias-maintenance'>
        <PageHeaderLayout>
          <div className='goods-container'>
            <div className='backRow'>
              <Button onClick={this.back} style={{ marginLeft: '5px' }}>
                返回
              </Button>
              <div className='productName'>{productName}</div>
              <div className='categoryName'>{categoryName}</div>
            </div>
            <div className='goods-content'>
              <div>
                <div className='goods-content-title'>已关联别名</div>
                <Form layout='inline' onSubmit={this.handleSearch}>
                  <Row gutter={12}>
                    <Col span={6} style={{ textAlign: 'left' }}>
                      <FormItem label='货品别名:'>
                        {getFieldDecorator('productName', {
                          initialValue: '',
                          rules: [{ required: false, message: '请输入货品别名!' }],
                        })(<Input placeholder='请输入货品别名搜索' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col span={18} style={{ textAlign: 'right' }}>
                      <Button type='primary' htmlType='submit'>
                        查询
                      </Button>
                      <Button style={{ marginLeft: '20px' }} onClick={this.handleReset}>
                        重置
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>

              <Table
                rowKey={(_, index) => index}
                dataSource={pageList}
                pagination={pagination}
                scroll={{ x: 800 }}
                bordered
                onChange={this.pageSwitch}
                loading={loading}
              >
                <Column
                  title='序号'
                  width={100}
                  dataIndex='index'
                  key='index'
                  render={(_, __, index) => {
                    return (pageNo - 1) * pageSize + index + 1;
                  }}
                />
                <Column title='货品别名' width={250} dataIndex='productName' key='productName' />
                <Column
                  className='operating'
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    return (
                      <div>
                        <Popconfirm
                          title='确定删除吗？'
                          onConfirm={(e) => this.aliasToDelete(record)}
                          okText='确认'
                          cancelText='取消'
                        >
                          删除
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            </div>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default GoodsAliasMaintenance;
