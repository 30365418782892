// 合同管理--编辑
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  DatePicker,
  Form,
  Button,
  Input,
  message,
  Modal,
  Row,
  Col,
  Select,
  Upload,
  Icon,
} from 'antd';
import { getListPacts, addPact, updatePact, enablePact } from '../../../state/action/stall';
import { API_ADMIN } from '../../../utils/urls';
import { generalParamsWithPToStr } from '../../../utils/math';
import { CONTRACT_TYPES } from '../../../utils/deviceType';

import '../../booth-stall-management/pages/stall/device.less';

const FormItem = Form.Item;
const { TextArea } = Input;

const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { getListPacts, addPact, updatePact, enablePact },
)
@Form.create()
class ContractManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      record: {},
      content: '',
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
  }

  componentDidMount() {
    const { record, fileList } = this.props;
    this.setState({
      record,
      fileList,
    });
  }

  async getListPacts(params) {
    this.setState({ loading: true });
    await this.props.getListPacts(params);
    this.setState({ loading: false });
  }

  addPact = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        const { fileList, record } = this.state;
        let fileArr = [];
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            const arr = fileList[i].name.split('.');
            if (arr[arr.length - 1] === 'pdf') {
              fileArr.push({
                fileName: fileList[i].name,
                fileUrl: fileList[i].url1,
              });
            } else {
              fileArr.push({
                fileName: fileList[i].name,
                fileUrl: fileList[i].url,
              });
            }
          }
        }
        // 当选择“活动赠送”时，合同编号禁止输入，变为系统自动生成；签约日期禁止输入，变为系统自动生成
        {
          const params = {
            id: record.id,
            stallId: record.stallId,
            pactNo: value.pactNo,
            pactType: value.pactType,
            systemType: value.systemType,
            saleId: value.saleId,
            signUser: value.signUser,
            signPhone: value.signPhone,
            pactStartTime: moment(value.pactStartTime.format('YYYY-MM-DD')).valueOf(),
            validity: value.validity,
            payTime: value.payTime ? moment(value.payTime.format('YYYY-MM-DD')).valueOf() : null,
            pactAmount: parseFloat(value.pactAmount),
            payAmount: value.payAmount ? parseFloat(value.payAmount) : null,
            remark: value.remark ? value.remark : '',
            bdImageRequestList: fileArr,
          };
          this.updatePactFun(params);
        }
      }
    });
  };

  async updatePactFun(params) {
    const result = await this.props.updatePact(params);
    if (result.code === 200) {
      this.props.showModal({}, false);
      message.success('编辑成功');
      const { defaultCurrent } = this.props;
      this.props.refreshIndex({
        //刷新主页面列表
        pageNo: defaultCurrent,
        pageSize: 10,
      });
    } else {
      message.error(result.msg);
    }
  }
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file, fileList }) => {
    const arr = file.name.split('.');
    const limitSize = file.size / 1024 / 1024 < 20;
    if (fileList && fileList.length > 0) {
      if (
        arr[arr.length - 1] === 'pdf' ||
        arr[arr.length - 1] === 'png' ||
        arr[arr.length - 1] === 'jpg'
      ) {
        if (file.size && !limitSize) {
          message.error('文件大小不能超过20M');
          return;
        }
        if (file.name.length > 32) {
          message.error('文件名称长度不能超过32位');
          return;
        }
        fileList.forEach((imgItem) => {
          if (imgItem && imgItem.status === 'uploading') {
            imgItem.thumbUrl = '';
          } else if (
            imgItem &&
            imgItem.status === 'done' &&
            imgItem.response &&
            imgItem.response.data
          ) {
            imgItem.thumbUrl = '';
            const arr = imgItem.name.split('.');
            if (arr[arr.length - 1] === 'pdf') {
              imgItem.url1 = imgItem.response.data[imgItem.name];
            } else {
              imgItem.url = imgItem.response.data[imgItem.name];
            }
          } else if (
            imgItem &&
            imgItem.status === 'error' &&
            imgItem.response &&
            imgItem.response.code !== 200
          ) {
            message.error(imgItem.response.msg);
            return;
          }
        });
        this.setState({ fileList });
      } else {
        message.error('仅支持上传PNG/JPG/PDF格式文件');
      }
    }
  };

  handleRemove = (file) => {
    const { fileList } = this.state;
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    this.setState({
      fileList: newFileList,
    });
  };

  disabledDate = (current) => {
    return current && current > moment();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { record, visible, showModal } = this.props;
    const { fileList } = this.state;
    const params = { fileList, fileType: 1 };
    const props = {
      action: `${API_ADMIN}file/uploadBatchFile?${generalParamsWithPToStr(
        'file/uploadBatchFile',
        params,
      )}`,
      listType: 'picture-card',
      fileList: [...fileList],
      showUploadList: { showPreviewIcon: false },
      accept: '.png,.jpg,.pdf',
      multiple: true,
      onPreview: this.handlePreview,
      onChange: this.handleChange,
      onRemove: this.handleRemove,
    };

    const yearsArr = [];
    for (let i = 1; i <= 100; i++) {
      yearsArr.push(i);
    }
    // 当选择“活动赠送”时，合同编号禁止输入，变为系统自动生成；签约日期禁止输入，变为系统自动生成
    const selectedPactType3 = record.pactType === 3;
    return (
      <div className='contractManagement'>
        <Modal
          title={`编辑合同`}
          visible={visible}
          onCancel={() => showModal({}, false)}
          footer={null}
          className={'deviceModalStyle'}
          width='750px'
          style={{ maxHeight: 700 }}
        >
          <Form layout='inline' onSubmit={this.addPact}>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='合同编号'>
                  {getFieldDecorator('pactNo', {
                    initialValue: record.pactNo || '',
                    rules: [{ required: true, message: '请输入合同编号' }],
                  })(<Input placeholder='请输入合同编号' disabled />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='合同类型'>
                  {getFieldDecorator('pactType', {
                    initialValue: record.pactType || '',
                    rules: [{ required: true, message: '请选择合同类型' }],
                  })(
                    <Select placeholder='请选择合同类型' disabled>
                      {[...CONTRACT_TYPES].map(([k, v]) => (
                        <Select.Option value={k} key={k}>
                          {v}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='签约BD'>
                  {getFieldDecorator('signBd', {
                    initialValue: record.signBd || '',
                    rules: [{ required: true, message: '请输入签约BD人员名称' }],
                  })(<Input placeholder='请输入签约BD' disabled />)}
                  {getFieldDecorator('saleId', {
                    initialValue: record.saleId || '',
                  })(<Input type='hidden' />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='业务线'>
                  {getFieldDecorator('firstDepartment', {
                    initialValue: record.firstDepartment || '',
                    rules: [{ required: true, message: '请选择签约BD' }],
                  })(<Input placeholder='' disabled />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='所属部门'>
                  {getFieldDecorator('pioneerDivision', {
                    initialValue: record.pioneerDivision || '',
                    rules: [{ required: true, message: '请选择签约BD' }],
                  })(<Input placeholder='' disabled />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='签约人'>
                  {getFieldDecorator('signUser', {
                    initialValue: record.signUser || '',
                    rules: [{ required: true, message: '请输入签约人' }],
                  })(<Input placeholder='请输入商行相应签约人' disabled />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='联系方式'>
                  {getFieldDecorator('signPhone', {
                    initialValue: record.signPhone || '',
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 11 || !/^[1][0-9]{10}$/.test(value)) {
                              callback('请输入正确的联系方式');
                            }
                          } else {
                            callback('请输入签约人联系方式');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入签约人联系方式' maxLength={11} disabled />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='签约日期'>
                  {getFieldDecorator('pactStartTime', {
                    initialValue: record.pactStartTime ? moment(record.pactStartTime) : '',
                    rules: [{ required: true, message: '请选择签约日期' }],
                  })(
                    <DatePicker
                      placeholder='请选择签约日期'
                      style={{ width: 210 }}
                      disabledDate={this.disabledDate}
                      disabled
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='有效期'>
                  {getFieldDecorator('validity', {
                    initialValue: record.validity || '',
                    rules: [{ required: true, message: '请输入有效期' }],
                  })(
                    <Select
                      placeholder='请选择有效期'
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {selectedPactType3
                        ? Array(365)
                            .fill(0)
                            .map((_, index) => (
                              <Select.Option value={index + 1} key={index}>{`${
                                index + 1
                              }天`}</Select.Option>
                            ))
                        : yearsArr.map((item, index) => (
                            <Select.Option value={item} key={index}>{`${item}年`}</Select.Option>
                          ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='合同金额'>
                  {getFieldDecorator('pactAmount', {
                    initialValue: record.pactAmount,
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value || value === '0' || value === 0) {
                            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
                              callback('合同金额最小为0，且最多两位小数');
                            }
                          } else {
                            callback('请输入合同金额');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入合同金额' disabled />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='回款金额'>
                  {getFieldDecorator('payAmount', {
                    initialValue:
                      record.payAmount || record.payAmount === 0 ? record.payAmount : '',
                    rules: [
                      {
                        required: false,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (!/^\d+(\.\d{0,2})?$/.test(value)) {
                              callback('回款金额最小为0，且最多两位小数');
                            }
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入回款金额' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='回款日期'>
                  {getFieldDecorator('payTime', {
                    initialValue: record.payTime ? moment(record.payTime) : '',
                  })(
                    <DatePicker
                      placeholder='请选择回款日期'
                      style={{ width: 210 }}
                      disabledDate={this.disabledDate}
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='合同备注'>
                  {getFieldDecorator('remark', {
                    initialValue: record.remark,
                  })(<TextArea rows={4} placeholder='请输入合同备注' allowClear maxLength={225} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='合同附件'>
                  仅支持上传PNG/JPG/PDF格式文件
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={3} />
              <Col span={21}>
                <FormItem {...formItemLayout} label=''>
                  <Upload {...props}>
                    <div>
                      <Icon type='plus' />
                      <div className='ant-upload-text'>上传附件</div>
                    </div>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => showModal({}, false)}>关闭</Button>
              <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                保存
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ContractManagement;
