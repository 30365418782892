import * as actionTypes from '../constant/caixm';
import * as req from '../request/caixm';

//获取档口列表
export function getListCaixmPhone(params) {
  return async (dispatch) => {
    const res = await req.getListCaixmPhone(params);
    if (res) {
      dispatch({
        type: actionTypes.CAIXM_SAVE,
        payload: {
          caixmPhone: res.data,
          searchCaixmPhone: params,
        },
      });
    }
  };
}
// 每日新增统计列表
export function getDailyAddStall(params) {
  return async (dispatch) => {
    const res = await req.getDailyAddStall(params);

    if (res) {
      let newPage = {
        ...params,
        pageNo: res.data ? res.data.pageNo : 1,
      };

      dispatch({
        type: actionTypes.CAIXM_SAVE,
        payload: {
          dailyAddStall: res.data,
          dailAddStallParams: newPage,
        },
      });
    }
  };
}
// 每日交易统计
export function getDailyBussinessStall(params) {
  return async (dispatch) => {
    const res = await req.getDailyBussinessStall(params);

    if (res) {
      let newPage = {
        ...params,
        pageNo: res.data ? res.data.pageNo : 1,
      };
      dispatch({
        type: actionTypes.CAIXM_SAVE,
        payload: {
          dailyBussinessStall: res.data,
          dailBussinessStallParams: newPage,
        },
      });
    }
  };
}
