//reducer
import * as actionTypes from '../constant/repay';
//初始状态
const initState = {};

//reducer
export function repay(state = initState, action) {
  switch (action.type) {
    case actionTypes.REPAY_SAVE:
      return { ...state, ...action.payload, loading: false };
    case actionTypes.REPAY_REQ:
      return { ...state, loading: true };
    default:
      return state;
  }
}
