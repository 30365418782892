// 销售明细
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Col, Table, Row, Icon, Tag, Button, DatePicker } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getBatchItemLogs } from '../../state/action/batchManager';
import './index.less';

const FormItem = Form.Item;

@Form.create()
@connect(
  (state) => {
    return { batchManager: state.batchManager };
  },
  { getBatchItemLogs },
)
class BatchItemLogs extends PureComponent {
  constructor(props) {
    super(props);
    this.record = (this.props.location.state && this.props.location.state.record) || {};
    this.state = {
      loading: false,
      pageNo: 1,
    };
  }

  componentDidMount() {
    const batchSaleType = this.record.batchSaleType;
    let batchId = this.record.batchId;
    if (2 === batchSaleType) {
      batchId = 0;
    }
    let pageInfo = {
      stallId: this.record.stallId,
      batchId: batchId,
      batchItemId: this.record.id,
      supplierName: this.record.supplierName,
      carNo: this.record.carNo,
      pageSize: 10,
      pageNo: 1,
    };
    this.getList(pageInfo);
    document.body.classList.add('sys-log-index-body');
  }

  componentWillUnmount() {
    document.body.classList.remove('sys-log-index-body');
  }

  // 获取table数据
  async getList(params) {
    this.setState({ loading: true });
    await this.props.getBatchItemLogs(params);
    this.setState({ loading: false });
  }

  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    });
    const batchSaleType = this.record.batchSaleType;
    let batchId = this.record.batchId;
    if (2 === batchSaleType) {
      batchId = 0;
    }
    let pageInfo = {
      stallId: this.record.stallId,
      batchId: batchId,
      batchItemId: this.record.id,
      supplierName: this.record.supplierName,
      carNo: this.record.carNo,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      pageSize: 10,
      pageNo: pageNumber.current,
    };
    this.getList(pageInfo);
  };

  //返回
  back = (e) => {
    e.preventDefault();
    const record = this.record;
    record.id = record.batchId;
    this.props.history.push({ pathname: '/system/batchManager/batchItem', state: { record } });
  };

  // 根据条件搜索合单配置列表
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['startTime', 'endTime'], (error, value) => {
      this.setState(
        {
          startTime: value.startTime ? moment(value.startTime).valueOf() : null,
          endTime: value.endTime ? moment(value.endTime).valueOf() : null,
          pageNo: 1,
        },
        () => {
          const batchSaleType = this.record.batchSaleType;
          let batchId = this.record.batchId;
          if (2 === batchSaleType) {
            batchId = 0;
          }
          let keyInfo = {
            stallId: this.record.stallId,
            batchId: batchId,
            batchItemId: this.record.id,
            supplierName: this.record.supplierName,
            carNo: this.record.carNo,
            startTime: value.startTime ? moment(value.startTime).valueOf() : null,
            endTime: value.endTime ? moment(value.endTime).valueOf() : null,
            pageSize: 10,
            pageNo: 1,
          };
          this.getList(keyInfo);
        },
      );
    });
  };

  // 重置
  handleReset = () => {
    this.setState({
      startTime: null,
      endTime: null,
    });
    this.props.form.resetFields();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { batchItemLogsList } = this.props.batchManager;
    let list = [];
    let pageEntity = {};
    if (batchItemLogsList) {
      list = batchItemLogsList.pageList;
      pageEntity = batchItemLogsList;
    }
    var totalSize = 0;
    if (pageEntity === undefined) {
    } else {
      totalSize = pageEntity.totalSize;
    }

    // 表格数据源
    const dataSource = list;
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
    };

    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
    };

    const extraContent = (
      <div className='extraContent'>
        <Form layout='inline' onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='开始时间'>
                {getFieldDecorator('startTime', {})(<DatePicker showTime />)}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='截止时间'>
                {getFieldDecorator('endTime', {})(<DatePicker showTime />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
          </Row>
        </Form>
      </div>
    );

    return (
      <div className='sys-index'>
        <PageHeaderLayout>
          <div className={'system-log'}>
            <Row className='backRow'>
              <a href='/' onClick={this.back}>
                <Icon type='left' /> 返回
              </a>
            </Row>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.pageChange.bind(this)}
                scroll={{ x: 'max-content' }}
                style={{ whiteSpace: 'nowrap' }}
              >
                <Col
                  align='center'
                  title='货主名称'
                  dataIndex='supplierName'
                  key='supplierName'
                  width={100}
                />
                <Col align='center' title='车次号' dataIndex='carNo' key='carNo' width={100} />
                <Col
                  align='center'
                  title='货品名称'
                  dataIndex='attrName'
                  key='attrName'
                  width={150}
                  render={() => {
                    return this.record.attrName;
                  }}
                />
                <Col
                  align='center'
                  title='修改后总件数'
                  dataIndex='changeAftTotalNums'
                  key='changeAftTotalNums'
                  width={120}
                />
                <Col
                  align='center'
                  title='当前修改件数'
                  dataIndex='changeNums'
                  key='changeNums'
                  width={200}
                />
                <Col
                  align='center'
                  title='修改后销售件数'
                  dataIndex='changeAftSaleNums'
                  key='changeAftSaleNums'
                  width={150}
                />
                <Col
                  align='center'
                  title='修改后总重量'
                  dataIndex='changeAftTotalWeight'
                  key='changeAftTotalWeight'
                  width={120}
                />
                <Col
                  align='center'
                  title='当前修改重量'
                  dataIndex='changeWeight'
                  key='changeWeight'
                  width={120}
                />
                <Col
                  align='center'
                  title='修改后销售重量'
                  dataIndex='changeAftSaleWeight'
                  key='changeAftSaleWeight'
                  width={150}
                />
                <Col
                  align='center'
                  title='修改说明'
                  dataIndex='changeDesc'
                  key='changeDesc'
                  width={120}
                />
                <Col align='center' title='订单号' dataIndex='orderNo' key='orderNo' width={200} />
                <Col
                  align='center'
                  title='操作时间'
                  dataIndex='createTime'
                  key='createTime'
                  width={120}
                  render={(_, record) => {
                    const time = moment(record.createTime).format('YYYY-MM-DD HH:mm:ss');
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='操作人'
                  dataIndex='createPersonName'
                  key='createPersonName'
                  width={120}
                />
                <Col
                  align='center'
                  title='类型'
                  dataIndex='type'
                  key='type'
                  width={120}
                  render={(_, record) => {
                    const type = record.type;
                    // 类型 1 盘点 2 入库 3 下单 4 报损 5 报溢
                    if (1 === type) {
                      return '盘点';
                    } else if (2 === type) {
                      return '入库 ';
                    } else if (3 === type) {
                      return '下单';
                    } else if (4 === type) {
                      return '报损';
                    } else if (5 === type) {
                      return '报溢';
                    } else if (6 === type) {
                      return '开码单';
                    } else if (7 === type) {
                      return '取码单';
                    } else if (8 === type) {
                      return '删码单';
                    } else if (9 === type) {
                      return '订单作废';
                    } else if (10 === type) {
                      return '订单退款';
                    } else if (11 === type) {
                      return '新增入库- 新增';
                    } else if (12 === type) {
                      return '新增入库- 修改';
                    } else if (13 === type) {
                      return '修改入库- 新增';
                    } else if (14 === type) {
                      return '修改入库- 修改';
                    } else if (15 === type) {
                      return '修改入库- 删除';
                    } else if (16 === type) {
                      return '删除报损';
                    } else if (17 === type) {
                      return '退货入库';
                    } else if (18 === type) {
                      return '作废退货单';
                    } else if (19 === type) {
                      return '调出';
                    } else if (20 === type) {
                      return '删除调出';
                    }
                    return type;
                  }}
                />
                <Col
                  align='center'
                  title='状态'
                  dataIndex='enable'
                  key='enable'
                  width={80}
                  render={(_, record) => {
                    const enable = record.enable;
                    if (!enable) {
                      return (
                        <Tag color={'red'} key={'禁用'}>
                          禁用
                        </Tag>
                      );
                    }
                    return (
                      <Tag color={'green'} key={'正常'}>
                        正常
                      </Tag>
                    );
                  }}
                />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default BatchItemLogs;
