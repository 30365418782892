import * as actionTypes from '../constant/memberManager';

//初始状态
const initState = {
  memebersList: {},
  addVipPayMsg: {},
  selectedProduct: {},
  productsList: [],
  vipPayList: {},
  searchList: {},
};

//reducer
export function memberManager(state = initState, action) {
  switch (action.type) {
    case actionTypes.MEMBER_MANGER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
