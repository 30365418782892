import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Card,
  Table,
  Modal,
  Select,
  Row,
  Col,
  message,
  Popconfirm,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getAllWhiteListByPage, deleteWhiteList, addWhiteList } from '../../state/action/batch';

import '../booth-management/index.less';
const { Option } = Select;
const FormItem = Form.Item;

@connect(
  (state) => {
    return { batch: state.batch };
  },
  { getAllWhiteListByPage, deleteWhiteList, addWhiteList },
)
@Form.create()
class BoothWhiteList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: null,
      isVisible: false,
      allWhiteListByPage: [],
      SelectType: null,
      marketType: null,
      account: null,
    };
    this.updataState = this.updataState.bind(this);
    this.updateAddWhiteList = this.updateAddWhiteList.bind(this);
    this.deleteWhite = this.deleteWhite.bind(this);
  }
  componentDidMount() {
    this.getAllWhiteListByPage({
      pageSize: 10,
      pageNo: 1,
    });
  }
  onChange = (e) => {
    const { value } = e.target;
    this.updataState(value, 1);
  };
  onSelectType = (e) => {
    this.updataState(e, 2);
  };
  modifyStatusBtn(value) {
    const { marketType, account } = value;
    this.setState({
      marketType: marketType,
      account: account,
    });
    let params = {
      pageSize: 10,
      pageNo: 1,
      marketType: marketType,
      account: account,
    };

    this.getAllWhiteListByPage(params);
  }
  updataState(value, num) {
    if (num === 1) {
      this.setState({
        query: value,
      });
    } else {
      this.setState({
        SelectType: value,
      });
    }
  }

  showModal = () => {
    //新增模态框开启
    this.setState({
      isVisible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      isVisible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      isVisible: false,
    });
  };

  // 获取全部白名单的请求
  async getAllWhiteListByPage(params) {
    await this.props.getAllWhiteListByPage(params);
  }
  //删除白名单的请求
  async deleteWhiteList(params) {
    await this.props.deleteWhiteList(params);
    message.info('删除成功');
    const { account, marketType } = this.state;
    this.getAllWhiteListByPage({
      account: account,
      marketType: marketType,
      pageSize: 10,
      pageNo: 1,
    });
  }
  // 分页切换
  pageSwitch = (obj) => {
    const { account, marketType } = this.state;
    this.getAllWhiteListByPage({
      account: account,
      marketType: marketType,
      pageSize: obj.pageSize,
      pageNo: obj.current,
    });
  };
  updateAddWhiteList(e) {
    e.preventDefault();
    this.props.form.validateFields(
      ['account', 'marketType', 'remark', 'operationType'],
      async (errors, values) => {
        if (!!errors) {
          return;
        }
        const { marketType, account, remark, operationType } = values;
        let params = {
          account: account,
          marketType: marketType,
          remark: remark,
          operationType: operationType,
        };
        await this.props.addWhiteList(params);
        message.info('新增档口信息成功！');
        this.getAllWhiteListByPage({
          marketType: this.state.marketType,
          account: this.state.account,
          pageSize: 10,
          pageNo: 1,
        });

        this.setState({ isVisible: false });
      },
    );
  }
  deleteWhite(e, id) {
    e.preventDefault();
    this.deleteWhiteList({ id });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const isMarketType = [
      {
        key: 1,
        value: '蔬菜',
      },
      {
        key: 5,
        value: '水果',
      },
      {
        key: 10,
        value: '水产',
      },
      {
        key: 15,
        value: '二批',
      },
    ];

    const {
      batch: { WhiteListByPage = [] },
    } = this.props;
    let dataSource = [];
    let TotalPageEntity = 1;
    if (WhiteListByPage) {
      const { lst, pageEntity } = WhiteListByPage;
      if (lst) {
        dataSource = lst;
        TotalPageEntity = pageEntity;
      }
    }

    const columns = [
      {
        title: '手机号',
        dataIndex: 'account',
        key: 'account',
      },
      {
        title: '市场类型',
        dataIndex: 'marketType',
        key: 'marketType',
        render: (marketType) => {
          switch (marketType) {
            case 1:
              return <span>蔬菜</span>;
            case 5:
              return <span>水果</span>;
            case 10:
              return <span>水产</span>;
            case 15:
              return <span>二批</span>;
            default:
              return '';
          }
        },
      },
      {
        title: '操作类型',
        dataIndex: 'operationType',
        key: 'operationType',
        render: (operationType) => {
          if (operationType === 1) {
            return <span> 登录</span>;
          }
        },
      },
      {
        title: '操作',
        key: 'tags',
        dataIndex: 'tags',
        render: (text, record) => {
          const { id } = record;
          return (
            <Popconfirm
              title='确定从白名单内删除?'
              onConfirm={(e) => this.deleteWhite(e, id)}
              okText='确定'
              cancelText='取消'
            >
              <a href='/'>
                <span>删除</span>
              </a>
            </Popconfirm>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const pagination = {
      pageSize: 10,
      total: TotalPageEntity !== undefined ? TotalPageEntity.totalSize : 1,
    };
    return (
      <div className='booth-index'>
        <PageHeaderLayout>
          <div className={'batchLayout'}>
            <Card className={'listCard'} bordered={false}>
              <div className={'searchModule'} style={{ marginTop: '18px', marginBottom: '18px' }}>
                <Select
                  style={{ width: 200 }}
                  placeholder='请选择市场类型'
                  onChange={this.onSelectType.bind(this)}
                  allowClear
                >
                  {isMarketType.map((item) => {
                    return <Option key={item.key}>{item.value}</Option>;
                  })}
                </Select>

                <Input
                  placeholder='请输入手机号'
                  onChange={this.onChange.bind(this)}
                  style={{ width: '200px', marginLeft: '18px' }}
                  allowClear
                />
                <Button
                  type='primary'
                  onClick={this.modifyStatusBtn.bind(this, {
                    marketType: this.state.SelectType,
                    account: this.state.query,
                  })}
                  style={{ marginLeft: '18px' }}
                >
                  查询
                </Button>
                <Button onClick={this.showModal} style={{ marginLeft: '18px' }}>
                  新增
                </Button>
              </div>
              <div>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={pagination}
                  onChange={this.pageSwitch.bind(this)}
                  rowKey={(_, index) => index}
                />
              </div>
              <Modal
                key={this.state.isVisible}
                title='新增白名单'
                visible={this.state.isVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
              >
                <Form onSubmit={this.updateAddWhiteList}>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem {...formItemLayout} label='手机号'>
                        {getFieldDecorator('account', {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              pattern: /^(1)\d{10}$/,
                              message: '请输入正确的电话号码!',
                            },
                          ],
                        })(<Input placeholder='请输入档主手机号' style={{ width: '200px' }} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem {...formItemLayout} label='市场类型'>
                        {getFieldDecorator('marketType', {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: ' 请选择市场类型',
                            },
                          ],
                        })(
                          <Select style={{ width: '200px' }} placeholder='请选择市场类型'>
                            {isMarketType.map((item) => {
                              return <Option key={item.key}>{item.value}</Option>;
                            })}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem {...formItemLayout} label='操作类型'>
                        {getFieldDecorator('operationType', {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: ' 请选择操作类型',
                            },
                          ],
                        })(
                          <Select style={{ width: '200px' }} placeholder='请选择操作类型'>
                            <Option key={1}>登录</Option>
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem {...formItemLayout} label='备注'>
                        {getFieldDecorator('remark', {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: ' 请输入备注',
                            },
                          ],
                        })(<Input placeholder='请输入备注' style={{ width: '200px' }} />)}
                      </FormItem>
                    </Col>
                  </Row>

                  <div style={{ display: 'flex', height: '36px' }}>
                    <Button type='primary' htmlType='submit' ref='saveOrUpdate'>
                      确定
                    </Button>
                  </div>
                </Form>
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default BoothWhiteList;
