// 批次管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Col, Button, Table, InputNumber, Row, Select, Divider, Tag } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getSupplierList, getBatchList } from '../../state/action/batchManager';
import { getMarketList, getStallList } from '../../state/action/basic';
import './index.less';

const FormItem = Form.Item;
const { Option } = Select;

@Form.create()
@connect(
  (state) => {
    return { batchManager: state.batchManager, basic: state.basic };
  },
  { getSupplierList, getBatchList, getMarketList, getStallList },
)
class BatchManager extends PureComponent {
  constructor(props) {
    super(props);
    //子页面返回过来的record
    this.BackRecord = (this.props.location.state && this.props.location.state.record) || {};
    this.state = {
      loading: false,
      pageNo: 1,
      stallId: this.BackRecord.stallId,
      searchCarNo: this.BackRecord.searchCarNo,
      supplierId: this.BackRecord.supplierId,
      marketId: this.BackRecord.marketId,
    };
  }

  componentDidMount() {
    this.getMarketList(); //获得市场列表
    document.body.classList.add('sys-log-index-body');
  }

  componentWillUnmount() {
    document.body.classList.remove('sys-log-index-body');
  }

  // 获取市场列表
  async getMarketList(value) {
    await this.props.getMarketList(value);
  }
  // 获取档口列表
  getStallListByMarketId = async (value) => {
    await this.props.getStallList({ marketId: value });
  };

  // 获取档口列表
  getSupplierListByStallId = async (value) => {
    await this.props.getSupplierList({ stallId: value });
  };

  // 获取table数据
  async getList(params) {
    this.setState({ loading: true });
    await this.props.getBatchList(params);
    this.setState({ loading: false });
  }

  // 根据条件搜索合单配置列表
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['marketId', 'stallId', 'supplierId', 'carNo'],
      (error, value) => {
        if (!error) {
          this.setState(
            {
              stallId: value.stallId,
              supplierId: value.supplierId,
              searchCarNo: value.carNo,
              pageNo: 1,
              marketId: value.marketId,
            },
            () => {
              let keyInfo2 = {
                stallId: value.stallId,
                supplierId: value.supplierId,
                carNo: value.carNo,
                pageSize: 10,
                pageNo: 1,
              };
              this.getList(keyInfo2);
            },
          );
        }
      },
    );
  };

  // 重置
  handleReset = () => {
    this.props.form.resetFields();
    this.props.form.setFieldsValue({
      marketId: undefined,
      stallId: undefined,
      supplierId: undefined,
      carNo: undefined,
    });
  };

  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    });
    let pageInfo = {
      stallId: this.state.stallId,
      supplierId: this.state.supplierId,
      carNo: this.state.carNo,
      pageSize: 10,
      pageNo: pageNumber.current,
    };
    this.getList(pageInfo);
  };

  toPage = (type, record) => {
    record.marketId = this.state.marketId;
    record.supplierId = this.state.supplierId;
    record.searchCarNo = this.state.searchCarNo;
    //1批次明细 2 销售明细
    if (1 === type) {
      this.props.history.push({ pathname: '/system/batchManager/batchItem', state: { record } });
    } else if (2 === type) {
      this.props.history.push({
        pathname: '/system/batchManager/batchSaleDetail',
        state: { record },
      });
    }
  };

  render() {
    // 市场列表
    let marketList = [];
    if (this.props.basic.marketList.code === 200) {
      marketList = this.props.basic.marketList.data;
    }
    const marketOption = marketList.map((market) => {
      return (
        <Option value={market.id} key={market.id}>
          {market.name}
        </Option>
      );
    });

    // 档口列表
    let stallList = [];
    if (this.props.basic.stallList.code === 200) {
      stallList = this.props.basic.stallList.data;
    }
    const stallOption = stallList.map((stall) => {
      return (
        <Option value={stall.id} key={stall.id}>
          {stall.name}
        </Option>
      );
    });

    // 货主列表
    let supplierList = [];
    if (this.props.batchManager.supplierList && this.props.batchManager.supplierList.length > 0) {
      supplierList = this.props.batchManager.supplierList;
    }
    const supplierOption = supplierList.map((supplier) => {
      return (
        <Option value={supplier.personId} key={supplier.personId}>
          {supplier.name}
        </Option>
      );
    });

    const { getFieldDecorator } = this.props.form;
    const { batchList } = this.props.batchManager;
    let list = [];
    let pageEntity = {};

    if (batchList) {
      list = batchList.pageList;
      pageEntity = batchList;
    }
    var totalSize = 0;
    if (pageEntity === undefined) {
    } else {
      totalSize = pageEntity.totalSize;
    }

    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
    };

    // 表格数据源
    const dataSource = list;
    const extraContent = (
      <div className='extraContent'>
        <Form layout='inline' onSubmit={this.handleSearch}>
          <Row gutter={24}>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='市场'>
                {getFieldDecorator('marketId', {
                  initialValue: this.BackRecord.marketId,
                  rules: [{ required: true, message: '请选择市场!' }],
                })(
                  <Select
                    placeholder='请选择市场'
                    style={{ minWidth: 200 }}
                    showSearch
                    allowClear
                    onChange={this.getStallListByMarketId}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {marketOption ? marketOption : ''}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='商行'>
                {getFieldDecorator('stallId', {
                  initialValue: this.BackRecord.stallId,
                  rules: [{ required: true, message: '请选择商行!' }],
                })(
                  <Select
                    placeholder='请选择商行'
                    style={{ minWidth: 200 }}
                    showSearch
                    allowClear
                    onChange={this.getSupplierListByStallId}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {stallOption ? stallOption : ''}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='货主名称'>
                {getFieldDecorator('supplierId', {
                  initialValue: this.BackRecord.supplierId,
                  rules: [{ required: true, message: '请选择货主!' }],
                })(
                  <Select
                    placeholder='请选择货主'
                    style={{ minWidth: 200 }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {supplierOption ? supplierOption : ''}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='车次号'>
                {getFieldDecorator('carNo', {
                  initialValue: this.BackRecord.searchCarNo,
                })(<InputNumber min={1} step={1} precision={0} />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
          </Row>
        </Form>
      </div>
    );
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
    };
    return (
      <div className='sys-index'>
        <PageHeaderLayout>
          <div className={'system-log'}>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.pageChange.bind(this)}
              >
                <Col align='center' title='货主名称' dataIndex='supplierName' key='supplierName' />
                <Col align='center' title='车次号' dataIndex='carNo' key='carNo' />
                <Col
                  align='center'
                  title='车次状态'
                  dataIndex='status'
                  key='status'
                  render={(_, record) => {
                    //车次状态 1:待设置行费 5:在售 7:售罄 9:结算
                    const status = record.status;
                    if (1 === status || 5 === status) {
                      return '在售';
                    } else if (7 === status) {
                      return '售罄';
                    } else if (11 === status) {
                      return '结算';
                    }
                    return status;
                  }}
                />
                <Col
                  align='center'
                  title='售卖类型'
                  dataIndex='batchSaleType'
                  key='batchSaleType'
                  render={(_, record) => {
                    const batchSaleType = record.batchSaleType;
                    if (1 === batchSaleType) {
                      return '代卖';
                    } else if (2 === batchSaleType) {
                      return '自营';
                    }
                    return batchSaleType;
                  }}
                />
                <Col
                  align='center'
                  title='状态'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const enable = record.enable;
                    if (!enable) {
                      return (
                        <Tag color={'red'} key={'禁用'}>
                          禁用
                        </Tag>
                      );
                    }
                    return (
                      <Tag color={'green'} key={'正常'}>
                        正常
                      </Tag>
                    );
                  }}
                />
                <Col
                  align='center'
                  title='创建时间'
                  dataIndex='createTime'
                  key='createTime'
                  render={(_, record) => {
                    const time = moment(record.createTime).format('YYYY-MM-DD HH:mm:ss');
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='售罄时间'
                  dataIndex='endTime'
                  key='endTime'
                  render={(text) => {
                    return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : null;
                  }}
                />
                <Col
                  align='center'
                  title='操作时间'
                  dataIndex='updateTime'
                  key='updateTime'
                  render={(_, record) => {
                    const time = moment(record.updateTime).format('YYYY-MM-DD HH:mm:ss');
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='操作人'
                  dataIndex='updatePersionName'
                  key='updatePersionName'
                />
                <Col
                  align='center'
                  title='操作'
                  dataIndex='oper'
                  key='oper'
                  render={(_, record) => {
                    return (
                      <span>
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.toPage(1, record);
                          }}
                        >
                          批次明细
                        </a>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.toPage(2, record);
                          }}
                        >
                          销售明细
                        </a>
                      </span>
                    );
                  }}
                />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default BatchManager;
