// 车次管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Select, Input, Modal, Table, Popconfirm } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  getGoodsList,
  getGoodsListByMark,
  deleteMarketProduct,
  addMarketProduct,
} from '../../state/request/market';
import './goodsManage.less';

const { Column } = Table;

let FormItem = Form.Item;

@connect((state) => {
  return { market: state.market };
}, {})
@Form.create()
class GoodsManage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, //新增货品弹框
      goodsList: [], //货品列表
      goodsListByMark: {}, //市场下面的货品
      productName: '',
    };
    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.getGoodsList(); //获得商品库货品列表
    this.getGoodsListByMark(); //根据市场获得货品列表
  }

  //获得商品库货品列表
  getGoodsList = async () => {
    const response = await getGoodsList();
    const { code, data } = response;
    if (code === 200) {
      this.setState({ goodsList: data.pageList });
    }
  };
  //根据市场获得货品列表
  getGoodsListByMark = async (pageNo = 1) => {
    const { productName } = this.state;
    const params = {
      marketId: this.id,
      pageNo,
      pageSize: 10,
    };
    if (productName !== '') {
      params.productName = productName;
    }
    const response = await getGoodsListByMark(params);
    const { code, data } = response;
    if (code === 200) {
      this.setState({ goodsListByMark: data });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['productName'], async (errors, values) => {
      if (!!errors) {
        return;
      }

      this.setState({ productName: values.productName }, () => {
        this.getGoodsListByMark(); //根据市场获得货品列表
      });
    });
  };
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({ productName: '' }, () => {
      this.getGoodsListByMark(); //根据市场获得货品列表
    });
  };
  showModalAddGoods = () => {
    this.setState({ visible: true });
  };
  addGoods = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['basicProductId'], async (errors, values) => {
      if (!!errors) {
        return;
      }
      const response = await addMarketProduct({
        basicProductId: values.basicProductId,
        marketId: this.id,
      });
      const { code } = response;
      if (code === 200) {
        this.getGoodsListByMark(); //根据市场获得货品列表
        this.setState({ visible: false });
        this.props.form.setFieldsValue({ basicProductId: undefined });
      }
    });
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };

  //返回
  back = () => {
    const { searchValue, pageNo } = this.props.history.location.state || {};
    this.props.history.push({
      pathname: '/marketList/marketList',
      state: {
        searchValue,
        pageNo,
      },
    });
  };
  // 删除货品
  delGoods = async (e, record) => {
    e.preventDefault();
    const response = await deleteMarketProduct(record.id);
    const { code } = response;
    if (code === 200) {
      this.getGoodsListByMark(); //根据市场获得货品列表
    }
  };
  onChangeTable = (pagination) => {
    this.getGoodsListByMark(pagination.current); //根据市场获得货品列表
  };

  render() {
    const { record } = this.props.history.location.state || {};
    const { getFieldDecorator } = this.props.form;
    const { visible, goodsList, goodsListByMark } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };

    // 表格的分页的设计
    const { pageNo = 1, totalSize = 0 } = goodsListByMark;
    const pagination = {
      pageSize: 10,
      current: pageNo,
      total: totalSize,
    };

    return (
      <div className='goods-manage'>
        <PageHeaderLayout>
          <div className='goods-manage-content'>
            <div className='header'>
              <Button onClick={this.back}>返回</Button>
              <span>{record.name}</span>
            </div>
            <div className='content'>
              <Form className='ant-advanced-search-form' onSubmit={this.handleSearch}>
                <Row gutter={24}>
                  <Col {...layout}>
                    <FormItem label='货品名称'>
                      {getFieldDecorator(
                        'productName',
                        {},
                      )(<Input type='text' placeholder='请输入货品名称' allowClear />)}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label=''>
                      <Button htmlType='submit' type='primary'>
                        查询
                      </Button>
                      <Button onClick={this.handleReset} style={{ marginLeft: 25 }}>
                        重置
                      </Button>
                      <Button
                        type='primary'
                        onClick={this.showModalAddGoods}
                        style={{ marginLeft: 25 }}
                      >
                        新增货品
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
              <Table
                rowKey={(_, index) => index}
                dataSource={goodsListByMark.pageList}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.onChangeTable}
              >
                <Column
                  title='序号'
                  width={100}
                  dataIndex='index'
                  key='index'
                  render={(_, __, index) => {
                    return index + 1;
                  }}
                />
                <Col
                  align='center'
                  title='货品名称'
                  dataIndex='basicProductName'
                  key='basicProductName'
                />
                <Col
                  align='center'
                  title='操作'
                  dataIndex='operation'
                  key='operation'
                  render={(_, record) => {
                    return (
                      <div>
                        <Popconfirm
                          title={'确定删除？'}
                          onConfirm={(e) => this.delGoods(e, record)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/' className='close'>
                            <span>{'删除'}</span>
                          </a>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
              <Modal
                title='新增货品'
                visible={visible}
                onCancel={this.handleCancel}
                footer={null}
                width='600px'
                className='add-modal'
              >
                <Form
                  {...formItemLayout}
                  onSubmit={this.addGoods}
                  layout='inline'
                  className='login-form'
                >
                  <Row gutter={24}>
                    <Col span={12}>
                      <FormItem label='货品'>
                        {getFieldDecorator('basicProductId', {
                          rules: [{ required: true, message: '请选择货品!' }],
                        })(
                          <Select
                            placeholder='请选择货品'
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {goodsList &&
                              goodsList.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.productName}
                                </Select.Option>
                              ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label=''>
                        <Button type='primary' htmlType='submit'>
                          保存
                        </Button>
                        <Button onClick={this.handleCancel} style={{ marginLeft: '18px' }}>
                          关闭
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default GoodsManage;
