// 配置分组-新增、修改分组
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Form, Modal, Select, Space, Input, InputNumber, Button, message } from 'antd-v5';

import { view } from '../../state/request/stall';
import { saveOrUpdateConfigGroup } from '../../state/request/basic';

const Option = Select.Option;

let isEdit = false;

const options = [
  { label: '定装', value: '1' },
  { label: '非定装', value: '2' },
  { label: '散装', value: '3' },
  { label: '郊菜', value: '5' },
  { label: '拆包', value: '7' },
];

const EditGroup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [form] = Form.useForm();
  const refs = useRef();

  const key = Form.useWatch('key', form);

  useEffect(() => {
    if (key) {
      if (!isEdit) {
        const groupName = options.find((item) => item.value === key)?.label;
        form.setFieldValue('groupName', groupName);
      }
    }
  }, [key, form]);

  useImperativeHandle(ref, () => ({
    show(data) {
      setOpen(true);
      form.setFieldsValue({
        ...data,
      });
      isEdit = !!data;
      refs.current = data;
    },
  }));

  const onCancel = () => {
    setOpen(false);
    setStatus('');
    form.setFieldsValue({});
  };

  const onChange = async (value) => {
    if (String(value)?.length < 5) {
      form.setFieldValue('stallName', '');
      setStatus('error');
      return;
    }
    const { code, data = {} } = await view({ id: value });
    if (code === 200) {
      form.setFieldValue('stallName', data?.shortName);
      if (!data?.id) {
        setStatus('error');
      } else {
        setStatus('');
        refs.current = data;
      }
    }
  };

  const onFinish = async (values) => {
    if (!!status) {
      message.error('请输入正确的商行ID');
      return;
    }
    onCancel();
    /**
     * cityCode：城市code
     * marketId：市场ID
     * stallId：档口ID
     * id：分组ID
     * 'groupName', 'remark', 'key'
     */
    const { id, stallId, groupName, remark, key } = values;
    const params = {
      ...(isEdit ? { id } : {}),
      cityCode: refs.current?.cityCode || refs.current?.district,
      marketId: refs.current?.marketId,
      reqStallId: stallId,
      groupName,
      remark,
      key,
    };
    const { code } = await saveOrUpdateConfigGroup(params);
    if (code === 200) {
      message.success('操作成功!');
      props.refresh && props.refresh();
    }
  };

  return (
    <Modal
      title={`${isEdit ? '编辑' : '新增'}分组`}
      open={open}
      destroyOnClose
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout='horizontal'
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          <Form.Item
            name='key'
            label='key'
            rules={[
              {
                required: true,
                message: '请选择key',
              },
            ]}
          >
            <Select placeholder='请选择key'>
              {options.map(({ label, value }) => {
                return <Option value={value} key={value}>{`${value}: ${label}`}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name='stallId'
            label='商行ID'
            rules={[
              {
                required: true,
                message: '请输入商行ID',
              },
            ]}
          >
            <InputNumber
              placeholder='请输入商行ID'
              status={status}
              controls={false}
              onChange={onChange}
              maxLength={6}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name='stallName' label='商行名称'>
            <Input disabled />
          </Form.Item>
          {isEdit && <Form.Item name='id' hidden />}
          <Form.Item
            name='groupName'
            label='分组名'
            rules={[
              {
                required: true,
                message: '请输入分组名',
              },
            ]}
          >
            <Input placeholder='请输入分组名' />
          </Form.Item>
          <Form.Item name='remark' label='备注'>
            <Input placeholder='请输入备注' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' style={{ marginTop: 24 }}>
              保存
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
});

export default EditGroup;
