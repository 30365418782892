//合并所有reducer 并且返回
import { combineReducers } from 'redux';
import { home } from './state/reducer/home';
import { login } from './state/reducer/login';
import { global } from './state/reducer/global';
import { sysLog } from './state/reducer/sysLog';
import { sysMenu } from './state/reducer/sysMenu';
import { stall } from './state/reducer/stall';
import { basic } from './state/reducer/basic';
import { org } from './state/reducer/org';
import { batch } from './state/reducer/batch';
import { appVersion } from './state/reducer/appVersion';
import { systemNotice } from './state/reducer/systemNotice';
import { market } from './state/reducer/market';
import { count } from './state/reducer/count';
import { mergeSet } from './state/reducer/mergeSet';
import { commodity } from './state/reducer/commodity';
import { bi } from './state/reducer/bi';
import { contract } from './state/reducer/contract';
import { memberManager } from './state/reducer/memberManager';
import { onlineStore } from './state/reducer/onlineStore';
import { caixm } from './state/reducer/caixm';
import { hotUpdate } from './state/reducer/hotUpdate';
import { salesman } from './state/reducer/salesman'; //销售人员
import { voiceCDKEY } from './state/reducer/voiceCDKEY'; //销售人员
import { personManager } from './state/reducer/personManager';
import { operationManager } from './state/reducer/operationManager';
import { batchManager } from './state/reducer/batchManager';
import { repay } from './state/reducer/repay';
import { bank } from './state/reducer/bank';

export default combineReducers({
  home,
  login,
  global,
  sysLog,
  sysMenu,
  stall,
  basic,
  org,
  batch,
  appVersion,
  systemNotice,
  market,
  count,
  mergeSet,
  commodity,
  bi,
  contract,
  memberManager,
  onlineStore,
  caixm,
  hotUpdate,
  salesman, //销售人员
  voiceCDKEY,
  personManager,
  batchManager,
  operationManager,
  repay,
  bank,
});
