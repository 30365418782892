//主要用于系统类型的数据
export const appTypeList = [
  {
    value: 'all',
    name: '全部',
  },
  {
    value: 'android',
    name: 'android',
  },
  {
    value: 'ios',
    name: 'ios',
  },
  {
    value: 'h5',
    name: 'h5',
  },
  {
    value: 'androidPhone',
    name: '收银手机端',
  },
  {
    value: 'stallPhone',
    name: '档主端',
  },
  {
    value: 'purchase',
    name: '买家端',
  },
  {
    value: 'supplier',
    name: '货主端',
  },
  {
    value: 'INTEGRATIONAPP',
    name: '菜小秘',
  },
];
