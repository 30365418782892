import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../page-header/index';
import './index.less';

export default ({ children, wrapperClassName, top, ...restProps }) => (
    <div className={`page-header-layout ${wrapperClassName}`}>
        {top}
        <PageHeader key="pageheader" {...restProps} linkElement={Link} />
        {children ? <div className={'content'}>{children}</div> : null}
    </div>
);
