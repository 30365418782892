/** 组织架构管理 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Row,
  Col,
  Button,
  Input,
  Form,
  Modal,
  Divider,
  Pagination,
  message,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { getAllOrgList, saveOrg, updateOrg, queryStall, resetFields } from '../../state/action/org';

import './style.less';

const { Column } = Table;
const FormItem = Form.Item;

@connect(
  (state) => {
    return { org: state.org };
  },
  { getAllOrgList, saveOrg, updateOrg, queryStall, resetFields },
)
@Form.create()
class BoothOrgManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      modalTital: '新建机构',
      searchValue: '',
      modalInfo: [
        {
          id: '',
          pid: '',
          orgname: '',
          enable: 1,
          text: '新建',
          remark: '',
          hide: true,
        },
      ],
    };
  }
  componentDidMount() {
    const {
      org: { getAllOrgListParams },
    } = this.props;
    this.getAllOrgList({ pageSize: 10, pageNo: 1, key: '', ...getAllOrgListParams });
  }

  componentWillUnmount() {
    const { location } = this.props.history;
    //离开的页面不是子页面，那么需要清空redux里面的值，方便下次进来显示原始的状态的页面，
    //如果是子页面，那么从第2页进入的子页面，返回时也要显示第2页
    if (location.pathname.indexOf('/booth/orgManagement') === -1) {
      this.props.resetFields('getAllOrgListParams');
    }
  }

  /***
   *
   *  查询部分
   *
   * ***/

  // 查询接口口
  async getAllOrgList(value) {
    this.setState({ loading: true });
    await this.props.getAllOrgList(value);
    this.setState({ loading: false });
  }

  // 重置查询的输入框
  handleReset = () => {
    this.props.form.resetFields();
    this.getAllOrgList({ pageSize: 10, pageNo: 1, key: '' });
  };
  // 自定义规则
  customReg = (_, value, callback) => {
    let reg = /^[A-Za-z0-9_\-\u4e00-\u9fa5]{0,}$/; //最低为0位，将可以在输入框为空的时候进行查询数据
    if (reg.test(value)) {
      callback();
    } else {
      callback('请输入数字、字母后者下划线');
    }
  };
  // 查询事件
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['searchKey', 'stallName'], (errors, values) => {
      if (!!errors) {
        return;
      }
      this.setState({
        searchValue: values.searchKey,
      });
      let keyWords = {
        pageSize: 10,
        pageNo: 1,
        orgName: values.searchKey,
        stallName: values.stallName,
      };
      this.getAllOrgList(keyWords);
    });
  };
  // 点击分页请求新的数据
  pageChange = (pageNumber) => {
    let pageInfo = {
      orgName: this.state.searchValue,
      pageSize: 10,
      pageNo: pageNumber,
    };
    this.getAllOrgList(pageInfo);
  };

  /***
   *
   * 修改和
   * 新增部分
   *
   * ***/

  // 新增或者修改的接口
  saveOrg = async (values) => {
    this.setState({ loading: true });
    await this.props.saveOrg(values);
    const {
      org: { saveOrgDate },
    } = this.props;
    if (saveOrgDate.code === 200) {
      message.success(saveOrgDate.msg);
      this.setState({
        show: false,
      });
    } else {
      message.error(saveOrgDate.msg);
    }
    const {
      org: { getAllOrgListParams },
    } = this.props;
    this.getAllOrgList({ pageSize: 10, pageNo: getAllOrgListParams.pageNo });
    this.setState({ loading: false });
  };

  updateOrg = async (values, flag) => {
    this.setState({ loading: true });
    await this.props.updateOrg(values);
    const {
      org: { updateOrgDate },
    } = this.props;
    if (updateOrgDate.code === 200) {
      if (flag) {
        message.success(flag + '成功!');
        this.setState({
          show: false,
        });
      } else {
        message.success((values.enable ? '启用' : '禁用') + '成功！');
      }
    } else {
      message.error(updateOrgDate.msg);
    }
    const {
      org: { getAllOrgListParams },
    } = this.props;
    this.getAllOrgList({ pageSize: 10, pageNo: getAllOrgListParams.pageNo });
    this.setState({
      loading: false,
    });
  };

  // 新建 点击新增按钮，显示model框并进行modalTital的赋值
  showModal = () => {
    this.setState({
      show: true,
      modalTital: '新建集团',
      modalInfo: [
        {
          id: '',
          pId: '0',
          orgName: '',
          enable: 1,
          remark: '',
          text: '新建',
          hide: true,
        },
      ],
    });
  };
  //修改 点击修改按钮 显示modal框并进行modalTital和modalInfo的赋值
  showUpdateModal = (e, id, pId, orgName, enable, remark, text = '修改', hide = false) => {
    e.preventDefault();
    let configInfomation = [{ id, pId, orgName, enable, remark, text, hide }];
    this.setState({
      show: true,
      modalInfo: configInfomation,
      modalTital: '修改集团',
    });
  };

  toOrgRelationStall(e, id) {
    e.preventDefault();
    this.props.history.push('/booth/orgManagement/stall/' + id);
  }
  toOrgRelationAccount(e, id) {
    e.preventDefault();
    this.props.history.push('/booth/orgManagement/account/' + id);
  }

  // 查询机构辖下商行
  async getStaffList(e, id) {
    await this.props.queryStall({ id });
  }

  //点击遮罩层或者 x 号让modal消失
  modalHide = () => {
    this.setState({
      show: false,
    });
  };
  // 点击modal下面的新建（保存）按钮
  saveOrUpdateBasicConfig = (e) => {
    e.preventDefault();
    let keyInfo = {};
    if (this.refs.saveOrUpdate.props.children === '新建') {
      this.props.form.validateFields(
        ['orgName', 'pId', 'enable', 'id', 'remark'],
        (errors, values) => {
          if (!!errors) {
            return;
          }
          keyInfo = values;
          return keyInfo;
        },
      );
      this.saveOrg(keyInfo, '新增');
    } else {
      this.props.form.validateFields(
        ['orgName', 'pId', 'enable', 'id', 'remark'],
        (errors, values) => {
          if (!!errors) {
            return;
          }

          keyInfo = values;
          return keyInfo;
        },
      );
      this.updateOrg(keyInfo, '修改');
    }
  };
  //修改 点击启用禁用的按钮修改config的enable的值
  updateEnableByKey = (e, enable, id, orgName, pId) => {
    e.preventDefault();
    let updateCofigEnable = {
      enable: !enable,
      id,
      orgName,
      pId,
    };
    this.updateOrg(updateCofigEnable);
  };
  render() {
    const {
      org: {
        getAllOrgListParams = {},
        allOrgDate: { code, data },
      },
    } = this.props;
    const { loading } = this.state;
    let orgList = [];
    let totalSize = 0;
    if (code === 200) {
      const { list, pageEntity } = data;
      orgList = list;
      totalSize = pageEntity.totalSize;
    }
    const { getFieldDecorator } = this.props.form;
    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
    };
    const extraContent = (
      <div className='extraContent'>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={30}>
            <Col {...topColResponsiveProps}>
              <FormItem label='集团名称'>
                {getFieldDecorator('searchKey', {
                  rules: [{ validator: this.customReg }],
                })(<Input style={{ width: '100%' }} placeholder='请输入集团名称' />)}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps}>
              <FormItem label='商行名称'>
                {getFieldDecorator('stallName', {
                  rules: [{ validator: this.customReg }],
                })(<Input style={{ width: '100%' }} placeholder='请输入商行名称' />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
            <Button type='primary' onClick={this.showModal}>
              新建
            </Button>
          </Row>
        </Form>
      </div>
    );
    const dataSource = orgList;
    // 响应式col
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    return (
      <div className='org-management'>
        <PageHeaderLayout>
          <div className={'basicSetting'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                bordered
                pagination={false}
                loading={loading}
              >
                <Column title='集团名称' dataIndex='orgName' key='orgName' />
                <Column title='备注' dataIndex='remark' key='remark' />
                <Column
                  title='状态'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const { enable } = record;
                    const txt = enable ? '启用' : '禁用';
                    return <span>{txt}</span>;
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    const { pId, orgName, id, enable, remark } = record;
                    return (
                      <div>
                        <a
                          href='/'
                          onClick={(e) => this.showUpdateModal(e, id, pId, orgName, enable, remark)}
                        >
                          修改
                        </a>
                        <Divider type='vertical' />
                        <a href='/' onClick={(e) => this.toOrgRelationAccount(e, id)}>
                          <span>集团账号管理</span>
                        </a>
                        <Divider type='vertical' />
                        <a href='/' onClick={(e) => this.toOrgRelationStall(e, id)}>
                          <span>集团商行管理</span>
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
              <Pagination
                className={'pagination'}
                current={getAllOrgListParams.pageNo || 1}
                total={totalSize}
                onChange={this.pageChange}
              ></Pagination>
            </Card>
            <Card className={'newConfigModalCard'}>
              <Modal
                className={'modal'}
                title={this.state.modalTital}
                visible={this.state.show}
                onCancel={this.modalHide}
                destroyOnClose='true'
                width='700px'
                footer={[]}
              >
                {this.state.modalInfo.map((inputValue) => {
                  return (
                    <Form onSubmit={this.saveOrUpdateBasicConfig} key={inputValue.id}>
                      <FormItem {...formItemLayout} label='id' style={{ display: 'none' }}>
                        {getFieldDecorator('id', {
                          initialValue: inputValue.id,
                        })(<Input />)}
                      </FormItem>
                      <FormItem {...formItemLayout} label='enable' style={{ display: 'none' }}>
                        {getFieldDecorator('enable', {
                          initialValue: inputValue.enable,
                        })(<Input />)}
                      </FormItem>
                      <FormItem
                        {...formItemLayout}
                        label='机构父节点ID'
                        style={{ display: 'none' }}
                      >
                        {getFieldDecorator('pId', {
                          initialValue: inputValue.pId,
                        })(<Input placeholder='机构父节点ID' />)}
                      </FormItem>
                      <FormItem {...formItemLayout} label='集团名称'>
                        {getFieldDecorator('orgName', {
                          initialValue: inputValue.orgName,
                          rules: [{ required: true, message: '名称必输!' }],
                        })(<Input placeholder='请输入集团的名称' />)}
                      </FormItem>

                      <FormItem {...formItemLayout} label='备注'>
                        {getFieldDecorator('remark', {
                          initialValue: inputValue.remark,
                        })(<Input placeholder='请输入备注' />)}
                      </FormItem>

                      <div style={{ display: 'flex', height: '36px' }}>
                        <Button
                          style={{ float: 'right', marginRight: '5px', marginTop: '5px' }}
                          type='primary'
                          htmlType='submit'
                          ref='saveOrUpdate'
                        >
                          {inputValue.text}
                        </Button>
                      </div>
                    </Form>
                  );
                })}
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default BoothOrgManagement;
