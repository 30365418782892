/**
 * 货款
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Select } from 'antd';

import '../style.less';
import PaymentTypePage from './PaymentType';

import { fetchAllStallCalculationResult, fetchAllStallConfig } from '../../../state/action/count';

const { Option } = Select;

@connect(
  (state) => {
    return { count: state.count, mergeSet: state.mergeSet };
  },
  {
    fetchAllStallCalculationResult,
    fetchAllStallConfig,
  },
)
class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 选择计算元素后显示隐藏
      visibility: false,
      // 选择元素数组
      descData: [],
      // 子组件数组
      comps: [],
    };
  }

  fetchAllStallCalculationResult(params) {
    this.props.fetchAllStallCalculationResult(params);
  }

  fetchAllStallConfig(params) {
    this.props.fetchAllStallConfig(params);
  }

  // 货款计算元素选择
  eleSelect = (key) => {
    const { descData } = this.state;
    descData.push({
      key: key.split('-')[0],
      name: key.split('-')[1],
      type: key.split('-')[2],
      unit: key.split('-')[3] || [],
    });
    this.setState({
      visibility: true,
      descData: descData,
    });
    localStorage.setItem('count_desc_data', JSON.stringify(descData));
  };

  // 给全部档口配置规则
  allMarket = () => {
    this.fetchAllStallCalculationResult({ reqStallId: 0 });
    this.fetchAllStallConfig({ reqStallId: 0 });
    localStorage.setItem('reqStallId', 0);
  };

  render() {
    const {
      count: { allResultData, allStallConfigData },
    } = this.props;
    const { visibility, descData, comps } = this.state;

    return (
      <div className='tabLayout'>
        <Card title='档口选择' bordered={false}>
          <Button type='primary' onClick={this.allMarket}>
            给全部档口配置
          </Button>
        </Card>
        <Card title='货款计算元素选择' bordered={false}>
          <div className='feeList'>
            {allResultData &&
              allResultData.map((item, index) => {
                const { desc } = item;
                return (
                  <Select
                    key={index}
                    defaultValue={desc[0]}
                    style={{ width: 120 }}
                    onChange={this.eleSelect.bind(this)}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {desc &&
                      desc.map((list) => {
                        return (
                          <Option key={`${item.key}-${list}-${item.className}-${item.unit}`}>
                            {list}
                          </Option>
                        );
                      })}
                  </Select>
                );
              })}
          </div>
          <div className='add'>
            <Button
              type='primary'
              icon='plus'
              onClick={() => this.setState({ comps: comps.concat([Date.now()]) })}
            >
              新增货款计算
            </Button>
          </div>
        </Card>
        {comps.map((comp) => {
          return (
            <PaymentTypePage
              type={this.props.type}
              data={descData}
              formulaData={allStallConfigData || []}
              key={new Date()}
              title={this.props.title}
            />
          );
        })}
        {visibility ? (
          <PaymentTypePage
            type={this.props.type}
            data={descData}
            formulaData={allStallConfigData || []}
            key={this.state.descData}
            title={this.props.title}
          />
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
export default PaymentPage;
