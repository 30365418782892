import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Row, Col, Input, Button, Form, message, Modal, Select, Divider } from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { queryStall, stallEnable, stallDisable, stallAdd } from '../../state/action/org';
import { marketList } from '../../state/action/stall';
import { getStallList } from '../../state/action/basic';
import './style.less';
import { isEmptyObject } from '../../utils/reg';
import { getValidStallList, getStallRoleList, addStallStaff } from '../../state/request/basic';

const { Column } = Table;
const FormItem = Form.Item;
const Option = Select.Option;
const { confirm } = Modal;

@connect(
  (state) => {
    return { stall: state.stall, basic: state.basic, org: state.org };
  },
  { queryStall, stallEnable, marketList, getStallList, stallAdd, stallDisable },
)
@Form.create()
class BoothOrgRelationStall extends PureComponent {
  constructor(props) {
    super(props);
    this.id = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    this.state = {
      loading: false,
      isValidateStaffPhone: false,
      stallList: [],
      modalInfo: [
        {
          id: this.id || 0,
          stallId: 0,
          marketId: 0,
          hide: true,
        },
      ],
      addStaffShow: false,
    };
  }
  componentDidMount() {
    this.queryStall(this.id);
    this.getMarketList(); //获得市场列表
    this.getStallList({}); //商行名称列表(档口)
  }

  // 获得市场列表
  getMarketList() {
    this.props.marketList();
  }
  // 获取商行名称列表
  async getStallList(value) {
    this.props.getStallList(value);
  }

  //查询机构辖下商行
  queryStall(id) {
    this.props.queryStall({ orgId: id });
  }

  /***
   *
   *  查询部分
   *
   * ***/

  // 修改或者新增的接口
  updateEnable = async (e, values) => {
    e.preventDefault();
    const that = this;
    confirm({
      title: `确定要${values.enable ? '禁用' : '启用'}吗?`,
      content: '',
      async onOk() {
        that.setState({ loading: true });
        const { id, enable } = values;
        if (enable) {
          await that.props.stallDisable({ id });
        } else {
          await that.props.stallEnable({ id });
        }

        const {
          org: { stallEnableData },
        } = that.props;

        if (stallEnableData.code === 200) {
          message.success(stallEnableData.msg);
        }
        that.queryStall(that.id);
        that.setState({ loading: false });
      },
      onCancel() {},
    });
  };

  back = () => {
    this.props.history.push('/booth/orgManagement');
  };
  showModal = () => {
    this.setState({
      show: true,
      modalTital: '新建商行',
      modalInfo: [
        {
          id: this.id || 0,
          stallId: 0,
          marketId: 0,
          text: '新建',
          hide: true,
        },
      ],
    });
  };
  // 点击modal下面的新建（保存）按钮
  saveOrUpdateBasicConfig = (e) => {
    e.preventDefault();
    if (this.refs.saveOrUpdate.props.children === '新建') {
      this.props.form.validateFields(['id', 'marketId', 'stallId'], (errors, values) => {
        if (!!errors) {
          return;
        }
        this.saveOrg({
          orgId: values.id,
          bdMarketId: values.marketId,
          bdStallId: values.stallId,
        });
      });
    }
  };
  // 新增或者修改的接口
  saveOrg = async (values) => {
    this.setState({ loading: true });
    await this.props.stallAdd(values);
    const {
      org: { stallAddData = {} },
    } = this.props;
    if (stallAddData.code === 200) {
      message.success(stallAddData.msg);
      this.setState({
        show: false,
      });
    }
    this.queryStall(this.id);
    this.setState({ loading: false });
  };

  //点击遮罩层或者 x 号让modal消失
  modalHide = () => {
    this.setState({ show: false });
  };

  // 市场选择框的onchange事件
  selectMarketEvent = (value) => {
    let keyCode = {
      marketId: value,
    };
    this.getStallList(keyCode);
    //清空打印机选项，反正连续添加同一个打印机
    this.props.form.setFieldsValue({
      stallId: '',
    });
  };

  // 添加员工
  handleAddStaff = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['name', 'phone', 'stallIdList', 'roleIdList'],
      async (err, values) => {
        if (!err) {
          const { code } = await addStallStaff(values);
          if (code === 200) {
            message.success('添加成功');
            this.setState({
              addStaffShow: false,
            });
          }
        }
      },
    );
  };

  getStaffStallList = async (phone) => {
    const { code, data } = await getValidStallList({
      orgId: this.id,
      phone,
    });
    if (code === 200) {
      this.setState({
        stallList: data ?? [],
      });
    }
  };

  getDefaultRole = async () => {
    const { code, data } = await getStallRoleList();
    if (code === 200) {
      this.setState({
        roleList: data,
      });
    }
  };

  render() {
    const {
      stall: { marketData = [] },
    } = this.props;
    const {
      basic: { stallList },
      org: { stallData },
    } = this.props;
    const { code, data } = stallData;
    let orgList = [];
    if (code === 200) {
      orgList = data;
    }
    const dataSource = orgList;
    const extraContent = (
      <div className={'searchModule'}>
        <Row gutter={24}>
          <Col span={24}>
            <Button type='primary' onClick={this.back}>
              返回集团列表
            </Button>
            <Button type='primary' onClick={this.showModal}>
              添加商行
            </Button>
            <Button
              type='primary'
              onClick={() => {
                this.getDefaultRole();
                this.setState({
                  addStaffShow: true,
                });
              }}
            >
              添加员工
            </Button>
          </Col>
        </Row>
      </div>
    );
    const { getFieldDecorator } = this.props.form;
    // 响应式col
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    // 商行名称列表
    const stallListCur = stallList && stallList.data ? stallList.data : [];

    const stallOption = stallListCur.map((stall) => (
      <Option value={stall.id} key={stall.id}>
        {stall.name}
      </Option>
    ));
    return (
      <div className='org-management'>
        <PageHeaderLayout>
          <div className={'basicSetting'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(record) => record.id}
                dataSource={dataSource}
                bordered
                pagination={false}
              >
                <Column title='市场' dataIndex='stallMarketName' key='stallMarketName' />
                <Column title='商行' dataIndex='stallName' key='stallName' />
                <Column
                  title='状态'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const { enable } = record;
                    const txt = enable ? '启用' : '禁用';
                    return <span>{txt}</span>;
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    const { enable, stallId } = record;
                    return (
                      <div>
                        <a href='/' onClick={(e) => this.updateEnable(e, record)}>
                          {enable ? '禁用' : '启用'}
                        </a>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          className={!enable ? 'disabled' : ''}
                          onClick={(e) => {
                            e.preventDefault();
                            if (!enable) return message.warn('请先启用商行');
                            this.props.history.push(`/booth/orgManagement/staffList/${stallId}`);
                          }}
                        >
                          员工列表
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>

            <Modal
              className={'modal'}
              title={this.state.modalTital}
              visible={this.state.show}
              onCancel={this.modalHide}
              destroyOnClose='true'
              width='700px'
              footer={[]}
            >
              {this.state.modalInfo.map((inputValue) => {
                return (
                  <Form onSubmit={this.saveOrUpdateBasicConfig} key={inputValue.id}>
                    <FormItem {...formItemLayout} label='id' style={{ display: 'none' }}>
                      {getFieldDecorator('id', {
                        initialValue: inputValue.id,
                      })(<Input />)}
                    </FormItem>
                    <FormItem label='市场名称'>
                      {getFieldDecorator('marketId', {
                        rules: [{ required: true, message: '请选择市场!' }],
                      })(
                        <Select
                          placeholder='请选择市场'
                          style={{ minWidth: '100%' }}
                          showSearch
                          allowClear
                          onChange={this.selectMarketEvent}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {marketData.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem label='商行名称'>
                      {getFieldDecorator('stallId', {
                        rules: [{ required: true, message: '请选择商行名称!' }],
                      })(
                        <Select
                          placeholder='请选择商行'
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {stallOption}
                        </Select>,
                      )}
                    </FormItem>
                    <div style={{ display: 'flex', height: '36px' }}>
                      <Button
                        style={{ float: 'right', marginRight: '5px', marginTop: '5px' }}
                        type='primary'
                        htmlType='submit'
                        ref='saveOrUpdate'
                      >
                        {inputValue.text}
                      </Button>
                    </div>
                  </Form>
                );
              })}
            </Modal>
            <Modal
              className={'modal'}
              title='添加员工'
              visible={this.state.addStaffShow}
              onCancel={() => {
                this.setState({
                  addStaffShow: false,
                });
              }}
              destroyOnClose='true'
              width='700px'
              footer={null}
            >
              <Form labelCol={{ span: 3 }} onSubmit={this.handleAddStaff}>
                <FormItem label='姓名'>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: '请输入姓名' }],
                  })(<Input maxLength={9} />)}
                </FormItem>
                <FormItem label='手机号'>
                  {getFieldDecorator('phone', {
                    rules: [
                      { required: true, message: '请输入手机号' },
                      { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号' },
                    ],
                  })(
                    <Input
                      onChange={(e) => {
                        const phone = e.target.value;
                        const isValidate = /^1[3456789]\d{9}$/.test(phone);
                        this.setState(
                          {
                            isValidateStaffPhone: isValidate,
                            staffList: [],
                          },
                          () => {
                            if (isValidate) {
                              this.getStaffStallList(phone);
                            }
                          },
                        );
                      }}
                    />,
                  )}
                </FormItem>
                <FormItem label='商行'>
                  {getFieldDecorator('stallIdList', {
                    rules: [{ required: true, message: '请选择商行' }],
                  })(
                    <Select
                      mode='multiple'
                      showSearch
                      allowClear
                      optionFilterProp='children'
                      notFoundContent={
                        !this.state.isValidateStaffPhone ? '请先输入正确手机号' : undefined
                      }
                    >
                      {this.state.isValidateStaffPhone &&
                        this.state.stallList.map((stall) => (
                          <Option key={stall.stallId}>{stall?.stallName || '--'}</Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
                <FormItem label='角色'>
                  {getFieldDecorator('roleIdList', {
                    rules: [{ required: true, message: '请选择角色' }],
                  })(
                    <Select mode='multiple' optionFilterProp='children' allowClear>
                      {/* 前端屏蔽档口经理角色（id = 3） */}
                      {this.state.roleList
                        ?.filter(({ enable, id }) => enable && id !== 3)
                        ?.map((role) => (
                          <Option key={role.id}>{role.remark}</Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
                <FormItem wrapperCol={{ offset: 3 }}>
                  <Button type='primary' htmlType='submit'>
                    新建
                  </Button>
                </FormItem>
              </Form>
            </Modal>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default BoothOrgRelationStall;
