// 数据服务管理 -- 未关联货品列表
import { Button, Card, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { marketList } from '../../state/action/stall';
import { getStallList } from '../../state/request/basic';
import { trim } from '../../utils/utils';

import {
  addProductAlias,
  allProducts,
  getUnlinkProductUsing,
} from '../../state/request/dataService';
import './unconnectedGoods.less';

const FormItem = Form.Item;
const pageSize = 10; //每页条数

//表单属性
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
@connect(
  (state) => {
    return { stall: state.stall };
  },
  { marketList },
)
@Form.create()
class UnConnectGoodsManagement extends PureComponent {
  constructor() {
    super();

    this.state = {
      productResult: {}, //货品列表结果(含分页)
      stallPageList: [], //商行结果列表
      basicProductData: [], //基础货品列表
      searchValue: {
        searchMarketId: -1, //搜索用到的市场id
        searchStallId: -1, //搜索用到的商行id
        searchProductName: null, //搜索用到的货品名称
      },
      defaultGoodsId: 0, //默认的基础货品ID,选了一次，下次再不用选第2次了，方便产品添加
    };
  }

  //请求市场列表
  async getMarketList() {
    await this.props.marketList({});
  }

  //请求市场下商行列表
  async getStallByMarket(marketId) {
    const { code, data } = await getStallList({ marketId });
    if (200 === code) {
      this.setState({
        stallPageList: data,
      });
    }
  }

  componentDidMount() {
    this.getMarketList(); //获得市场列表
  }

  //表单搜索
  searchForm = (f) => {
    f.preventDefault(); //防止提交刷新页面

    this.props.form.validateFields(
      ['searchMarketId', 'searchStallId', 'searchProductName'],
      (errors, values) => {
        if (errors) {
          return;
        }
        const {
          searchMarketId: marketId,
          searchProductName: productName = '',
          searchStallId: stallId,
        } = values;

        //去空格
        const productNameTrim = trim(productName);

        //保存搜索条件
        this.setState({
          searchValue: {
            searchMarketId: marketId,
            searchStallId: stallId,
            searchProductName: productNameTrim,
          },
        });
        this.requestSearch(marketId, stallId, productNameTrim);
      },
    );
  };

  //选择市场 更新商行列表
  searchMarketChange = (value) => {
    //清空商行名称
    this.props.form.setFieldsValue({
      searchStallId: undefined,
    });
    this.getStallByMarket(value);
  };

  /**
   * 请求搜索
   * @param {Number} marketId     市场id,必填
   * @param {String} stallId      商行id
   * @param {String} productName  货品名称
   */
  async requestSearch(marketId, stallId, productName) {
    const params = { marketId, stallId, productName };
    if (stallId == null) {
      delete params.stallId;
    }

    const { code, data } = await getUnlinkProductUsing(params);
    if (200 === code) {
      //填充数据列表
      this.setState({
        productResult: { ...data, pageNo: 1, pageSize: pageSize },
      });
    }
  }

  //请求所有基础货品
  async requestAllBasicProduct() {
    const { code, data } = await allProducts();
    if (200 === code) {
      this.setState({
        basicProductData: data.pageList,
      });
    }
  }

  //货品关联提交
  connectGoodshForm = (f) => {
    f.preventDefault();

    this.props.form.validateFields(['dialogProductName', 'dialogBasicGoodsId'], (error, values) => {
      if (error) {
        return;
      }
      const { dialogProductName: productName, dialogBasicGoodsId: basicGoodsId } = values;

      this.saveProductALias({ basicProductId: basicGoodsId, productName: productName });
    });
  };

  //别名关联保存
  saveProductALias = async (param) => {
    const { code } = await addProductAlias(param);
    //保存成功刷新列表
    if (200 === code) {
      this.setState({
        enableShowConnectGoods: false,
        connectGoodshForm: null,
        defaultGoodsId: param.basicProductId,
      });
      this.reloadAliasList();
    }
  };

  //重新加载别名列表(不改变搜索条件)
  reloadAliasList = () => {
    const searchValue = this.state.searchValue;
    if (searchValue.searchMarketId && searchValue.searchMarketId > 0) {
      const searchMarketId = searchValue.searchMarketId;
      const searchStallId = searchValue.searchStallId;
      const searchProductName = searchValue.searchProductName;
      this.requestSearch(searchMarketId, searchStallId, searchProductName);
    }
  };

  //表单重置
  resetForm = () => {
    this.props.form.resetFields();
    this.setState({ productResult: {} });
  };

  //展示关联货品库弹框
  showConnectGoods = (record) => {
    //未加载过基础库数据，重新加载并获取
    if (this.state.basicProductData.length === 0) {
      this.requestAllBasicProduct();
    }
    this.setState({
      enableShowConnectGoods: true,
      waitConnectGoods: record,
    });
  };

  //弹框取消事件
  onCancelDialog = () => {
    this.setState({
      enableShowConnectGoods: false,
      connectGoodshForm: null,
    });
  };

  render() {
    //列表标题
    const datacolumns = [
      {
        title: '货品别名',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: '市场',
        dataIndex: 'marketName',
        key: 'marketName',
      },
      {
        title: '商行',
        dataIndex: 'stallName',
        key: 'stallName',
      },
      {
        title: '商行ID',
        dataIndex: 'stallId',
        key: 'stallId',
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        render: (_, record) => {
          return (
            <a
              href='/'
              onClick={(e) => {
                e.preventDefault();
                this.showConnectGoods(record);
              }}
            >
              关联货品库
            </a>
          );
        },
      },
    ];

    const { getFieldDecorator } = this.props.form;
    const searchItemLayout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };

    //货品列表数据源
    const { pageList } = this.state.productResult;
    const pagination = {
      pageSize: pageSize,
    };
    const productDatasource = pageList;

    //关联货品操作
    let waitConnectGoods = null;
    if (this.state.enableShowConnectGoods) {
      waitConnectGoods = this.state.waitConnectGoods;
    }

    //市场列表
    const {
      stall: { marketData = [] },
    } = this.props;
    const basicProductData = this.state.basicProductData;

    //商行列表
    const stallPageList = this.state.stallPageList || [];
    const { defaultGoodsId } = this.state;

    return (
      <div className='goodsConnect'>
        <PageHeaderLayout>
          <div>
            <Card className='listCard' bordered={false}>
              <div>
                <Form form='form' layout='inline' onSubmit={this.searchForm} {...formItemLayout}>
                  <Row gutter={12}>
                    <Col {...searchItemLayout}>
                      <FormItem label='市场名称'>
                        {getFieldDecorator('searchMarketId', {
                          rules: [{ required: true, message: '请选择市场!' }],
                        })(
                          <Select
                            placeholder='请选择市场'
                            style={{ minWidth: '100%' }}
                            showSearch
                            allowClear
                            onChange={this.searchMarketChange}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {marketData.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...searchItemLayout}>
                      <FormItem label='商行名称'>
                        {getFieldDecorator('searchStallId', {
                          rules: [{ required: false, message: '商行名称' }],
                        })(
                          <Select
                            placeholder='请选择商行'
                            style={{ minWidth: '100%' }}
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {stallPageList.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...searchItemLayout}>
                      <FormItem label='货品别名'>
                        {getFieldDecorator('searchProductName', {
                          rules: [{ required: false, message: '请输入货品别名搜索' }],
                        })(<Input placeholder='请输入货品别名搜索' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...searchItemLayout}>
                      <FormItem label=''>
                        <Button
                          type='primary'
                          htmlType='submit'
                          ref={(r) => (this.refSearchButton = r)}
                        >
                          查询
                        </Button>
                        <Button
                          style={{ marginLeft: '20px' }}
                          htmlType='reset'
                          onClick={this.resetForm}
                        >
                          重置
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div>
                <Table
                  rowKey={(_, index) => index}
                  dataSource={productDatasource}
                  columns={datacolumns}
                  pagination={pagination}
                />
              </div>
            </Card>
            {/* 货品关联弹框 */}
            <Modal
              title={'关联货品库'}
              visible={this.state.enableShowConnectGoods}
              destroyOnClose={true}
              onCancel={this.onCancelDialog}
              footer={null}
              className='add-modal'
            >
              <div>
                <Form
                  form='form2'
                  layout='inline'
                  onSubmit={this.connectGoodshForm}
                  {...formItemLayout}
                >
                  <Row>
                    <Col>
                      <FormItem label='货品别名:'>
                        <span>{waitConnectGoods && waitConnectGoods.productName}</span>
                      </FormItem>

                      <FormItem style={{ display: 'none' }}>
                        {getFieldDecorator('dialogProductName', {
                          initialValue: waitConnectGoods && waitConnectGoods.productName,
                        })(<Input type='hidden' />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormItem label='市    场:'>
                        <span>{waitConnectGoods && waitConnectGoods.marketName}</span>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormItem label='商    行:'>
                        <span>{waitConnectGoods && waitConnectGoods.stallName}</span>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormItem label='关联货品:'>
                        {getFieldDecorator('dialogBasicGoodsId', {
                          initialValue: defaultGoodsId || '',
                          rules: [{ required: true, message: '请选择关联基础库货品' }],
                        })(
                          <Select
                            placeholder='请选择关联基础库货品'
                            style={{ minWidth: '100%' }}
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {basicProductData.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.productName}
                              </Select.Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormItem label=''>
                        <Button type='primary' htmlType='submit'>
                          确定
                        </Button>
                        <Button onClick={this.onCancelDialog} style={{ marginLeft: '16px' }}>
                          取消
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default UnConnectGoodsManagement;
