/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-05 10:47:32
 * @LastEditors: lijing
 * @LastEditTime: 2022-11-14 16:26:28
 */
//商行管理--设备管理
import * as actionTypes from '../constant/contract';
import * as req from '../request/contract';

// 获取设备类型
export function getListDeviceModes(params) {
  return async (dispatch) => {
    const res = await req.getListDeviceModes(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { listDeviceModesData: res.data } });
    }
  };
}
// 获取设备型号
export function getListDeviceTypes(params) {
  return async (dispatch) => {
    const res = await req.getListDeviceTypes(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { listDeviceTypesData: res.data } });
    }
  };
}
//新增设备
export function getAddDevice(params) {
  return async (dispatch) => {
    const res = await req.getAddDevice(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { addDeviceMsg: res } });
      return res;
    }
  };
}
//编辑设备
export function getUpdateDevice(params) {
  return async (dispatch) => {
    const res = await req.getUpdateDevice(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { updateDeviceMsg: res } });
      return res;
    }
  };
}
//获取设备列表
export function getListDevices(params) {
  return async (dispatch) => {
    const res = await req.getListDevices(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { devicesList: res.data } });
    }
  };
}
// 关联/解绑(启用/禁用)
export function getConnectPrinter(params) {
  return async (dispatch) => {
    const res = await req.getConnectPrinter(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { connectPrinterMsg: res } });
    }
  };
}
///////////////////////////////
//商行管理--合同管理
//获取合同管理列表
export function getListPacts(params) {
  return async (dispatch) => {
    const res = await req.getListPacts(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { listPactsData: res.data } });
    }
  };
}
//绑定/解绑

export function getStallConnectPrinter(params) {
  return async (dispatch) => {
    const res = await req.getStallConnectPrinter(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { stallConnectPrinterMsg: res } });
    }
  };
}
//绑定先查询设备id
export function getDeviceIdByMachineCode(params) {
  return async (dispatch) => {
    const res = await req.getDeviceIdByMachineCode(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { deviceIdByMachineCode: res.data } });
    }
  };
}

export function upgradeDevice(params) {
  return async (dispatch) => {
    const res = await req.upgradeDevice(params);
    if (res) {
      dispatch({ type: actionTypes.CONTRACT_SAVE, payload: { upgradeDeviceMsg: res } });
    }
  };
}
