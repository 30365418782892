// 市场列表-大数据账号维护
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Divider, Modal, Table, Input, message } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import './style.less';
import moment from 'moment';
import {
  getBigDataAccountList,
  updateBigDataAccount,
  updateEnable,
} from '../../state/action/market';

const { Column } = Table;

const FormItem = Form.Item;

@connect(
  (state) => {
    return { market: state.market };
  },
  { getBigDataAccountList, updateBigDataAccount, updateEnable },
)
@Form.create()
class BigDataAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      defaultCurrent: 1,
      visiable: false,
      rows: {},
      flag: '',
      contentTitle: '',
    };
  }

  componentDidMount() {
    this.getBigDataAccountList(null);
  }

  // 大数据账号列表
  async getBigDataAccountList(account) {
    const { record } = this.props.history.location.state;
    const { defaultCurrent } = this.state;
    const params = {
      marketId: this.isUndefied(record.id),
      account: this.isUndefied(account),
      pageNo: defaultCurrent,
      pageSize: 10,
    };
    this.setState({ loading: true });
    const result = await this.props.getBigDataAccountList(params);
    this.setState({ loading: false });
    if (result.code !== 200) {
      message.error(result.msg);
    }
  }

  isUndefied = (value) => {
    return value || null;
  };

  // 查询
  handleSumbit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const accountName = this.isUndefied(values.accountName);
        this.setState(
          {
            defaultCurrent: 1,
          },
          () => {
            this.getBigDataAccountList(accountName);
          },
        );
      }
    });
  };

  // 重置
  handleReset = () => {
    this.props.form.resetFields();
    this.setState(
      {
        defaultCurrent: 1,
      },
      () => {
        this.getBigDataAccountList(null);
      },
    );
  };

  // 分页
  pageSwitch = (obj) => {
    this.setState(
      {
        defaultCurrent: obj.current,
      },
      () => {
        this.refreshTable();
      },
    );
  };

  // 表格分页查询-获取查询条件
  refreshTable = () => {
    const formData = this.props.form.getFieldsValue();
    const accountName = this.isUndefied(formData.accountName);
    this.getBigDataAccountList(accountName);
  };

  // 启用、禁用
  updateStatus = (e, enable, id, rows) => {
    //
    const { record } = this.props.history.location.state; // 市场列表
    let updEnable = false;
    if (enable) {
      e.target.innerHTML = '禁用';
      updEnable = false;
    } else {
      e.target.innerHTML = '启用';
      updEnable = true;
    }
    this.updateEnable({ id, enable: updEnable, marketId: record.id, account: rows.account });
  };

  async updateEnable(params) {
    const result = await this.props.updateEnable(params);
    if (result.code === 200) {
      this.refreshTable();
    } else {
      message.error(result.msg);
    }
  }

  showAccountInfoModal = (rows, value, flag, title) => {
    this.setState({
      rows,
      visiable: value,
      flag,
      contentTitle: title,
    });
  };

  // 新增、修改
  editHandleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { record } = this.props.history.location.state; // 市场列表
        const { flag, rows } = this.state; // rows:大数据账号的某一行数据
        let params = {};
        if (flag === 'CREATE') {
          // 0新增 1修改信息 2修改密码
          params = {
            type: 0,
            name: this.isUndefied(values.name),
            account: this.isUndefied(values.account),
            password: this.isUndefied(values.password),
            marketId: record.id,
          };
        } else if (flag === 'UPDATE') {
          params = {
            type: 1,
            id: rows.id,
            personId: rows.personId,
            name: this.isUndefied(values.name),
            marketId: record.id,
          };
        } else if (flag === 'PASSWORD') {
          params = {
            type: 2,
            id: rows.id,
            password: this.isUndefied(values.password),
            personId: rows.personId,
            marketId: record.id,
          };
        }
        //
        this.updateBigDataAccount(params);
      }
    });
  };

  async updateBigDataAccount(params) {
    const result = await this.props.updateBigDataAccount(params);
    if (result.code === 200) {
      if (params.type === 0) {
        // 新增回到第一页
        message.success('保存成功');
        this.setState(
          {
            visiable: false,
            defaultCurrent: 1,
          },
          () => {
            this.refreshTable();
          },
        );
      } else {
        // 修改不需要
        message.success('修改成功');
        this.setState(
          {
            visiable: false,
          },
          () => {
            this.refreshTable();
          },
        );
      }
    } else {
      message.error(result.msg);
    }
  }

  gotoBack = () => {
    this.props.history.push({ pathname: '/marketList/marketList' });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { record } = this.props.history.location.state;
    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
    const layout1 = { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 };
    const { loading, defaultCurrent, visiable, rows, flag, contentTitle } = this.state;
    const {
      market: { bigDataAccountData },
    } = this.props;

    const pageList = bigDataAccountData ? bigDataAccountData.pageList : [];
    const dataSource = pageList && pageList.length > 0 ? pageList : [];

    const pagination = {
      pageSize: 10,
      total: bigDataAccountData && bigDataAccountData.totalSize,
      current: defaultCurrent,
    };

    return (
      <div className='market-index'>
        <PageHeaderLayout>
          <div className='bigDataAccount'>
            <Form ref='formRef' className='ant-advanced-search-form'>
              <h1 style={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>{record.name}</h1>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem label='账号'>
                    {getFieldDecorator('accountName', {
                      rules: [
                        {
                          message: '账号只能输入数字',
                          pattern: /^[0-9]+$/,
                        },
                      ],
                    })(<Input type='text' maxLength={11} placeholder='请输入账号' allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row gutter={10}>
                <Col span={24}>
                  <Button type='primary' onClick={this.handleSumbit}>
                    查询
                  </Button>
                  <Button style={{ marginLeft: 25 }} onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    style={{ marginLeft: 25 }}
                    onClick={() => this.showAccountInfoModal({}, true, 'CREATE', '新增账号')}
                  >
                    新增账号
                  </Button>
                  <Button type='primary' style={{ marginLeft: 25 }} onClick={this.gotoBack}>
                    返回市场列表
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              rowKey={(_, index) => index}
              dataSource={dataSource}
              pagination={pagination}
              scroll={{ x: 600 }}
              bordered
              loading={loading}
              onChange={this.pageSwitch.bind(this)}
            >
              <Column title='账号名' dataIndex='personName' key='personName' width='200px' />
              <Column title='账号' dataIndex='account' key='account' width='200px' />
              <Column
                title='创建时间'
                dataIndex='createTime'
                key='createTime'
                width='100px'
                render={(text) => {
                  return text ? moment(text).format('YYYY-MM-DD') : '';
                }}
              />
              <Column
                title='状态'
                dataIndex='enable'
                key='enable'
                width='100px'
                render={(_, record) => {
                  return record.enable ? '启用' : '禁用';
                }}
              />
              <Column
                title='操作'
                dataIndex='option'
                key='option'
                width='200px'
                render={(_, record) => {
                  const { enable, id } = record;
                  return (
                    <div>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateStatus(e, enable, id, record);
                        }}
                      >
                        {enable ? '禁用' : '启用'}
                      </a>
                      <Divider type='vertical' />
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.showAccountInfoModal(record, true, 'UPDATE', '修改信息');
                        }}
                      >
                        修改信息
                      </a>
                      <Divider type='vertical' />
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.showAccountInfoModal(record, true, 'PASSWORD', '修改密码');
                        }}
                      >
                        修改密码
                      </a>
                    </div>
                  );
                }}
              />
            </Table>
            {/** 新增账号、 修改信息、修改密码 */}
            {visiable && (
              <Modal
                title={null}
                visible={visiable}
                onCancel={() => this.showAccountInfoModal({}, false)}
                destroyOnClose='true'
                width='600px'
                className='modal'
                footer={null}
              >
                <Form ref='modalFormRef'>
                  <Row style={{ textAlign: 'center' }}>
                    <h3>{contentTitle}</h3>
                  </Row>
                  {flag !== 'PASSWORD' && (
                    <div>
                      <Row gutter={24}>
                        <Col {...layout1}>
                          <FormItem label='账号姓名'>
                            {getFieldDecorator('name', {
                              initialValue: rows.personName || '',
                              rules: [
                                {
                                  required: true,
                                  validator: (_, value, callback) => {
                                    if (value) {
                                      if (value.length > 9 || !/^[^\s]*$/.test(value)) {
                                        callback('账号名称不超过9位且不能输入空格');
                                      }
                                    } else {
                                      callback('请输入账号姓名');
                                    }
                                    callback();
                                  },
                                },
                              ],
                            })(<Input type='text' placeholder='请输入账号姓名' allowClear />)}
                          </FormItem>
                        </Col>
                      </Row>
                      {flag === 'CREATE' && (
                        <Row gutter={24}>
                          <Col {...layout1}>
                            <FormItem label='账&emsp;&emsp;号'>
                              {getFieldDecorator('account', {
                                initialValue: rows.account || '',
                                rules: [
                                  {
                                    required: true,
                                    validator: (_, value, callback) => {
                                      if (value) {
                                        if (value.length > 11 || !/^[1][0-9]{10}$/.test(value)) {
                                          callback('请输入正确的账号');
                                        }
                                      } else {
                                        callback('请输入账号');
                                      }
                                      callback();
                                    },
                                  },
                                ],
                              })(<Input type='text' placeholder='请输入账号' allowClear />)}
                            </FormItem>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                  {flag === 'CREATE' ? (
                    <Row gutter={24}>
                      <Col {...layout1}>
                        <FormItem label='密&emsp;&emsp;码'>
                          {getFieldDecorator('password', {
                            initialValue: rows.password || '',
                            rules: [
                              {
                                required: true,
                                message: '请输入密码',
                                whitespace: true,
                              },
                              {
                                len: 6,
                                message: '密码需6位数',
                              },
                              {
                                message: '密码只能输入数字',
                                pattern: /^[0-9]+$/,
                              },
                            ],
                          })(
                            <Input type='text' maxLength={6} placeholder='请输入密码' allowClear />,
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  ) : null}
                  {flag === 'PASSWORD' ? (
                    <Row gutter={24}>
                      <Col {...layout1}>
                        <FormItem label='密&emsp;&emsp;码'>
                          {getFieldDecorator('password', {
                            initialValue: rows.password || '',
                            rules: [
                              {
                                required: true,
                                message: '请输入密码',
                                whitespace: true,
                              },
                              {
                                len: 6,
                                message: '密码需6位数',
                              },
                              {
                                message: '密码只能输入数字',
                                pattern: /^[0-9]+$/,
                              },
                            ],
                          })(
                            <Input
                              type='text'
                              maxLength={6}
                              placeholder='请输入新密码'
                              allowClear
                            />,
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  ) : null}
                  <br />
                  <Row gutter={10} style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Button type='primary' style={{ width: 80 }} onClick={this.editHandleSubmit}>
                        保存
                      </Button>
                      <Button
                        style={{ marginLeft: 25, width: 80 }}
                        onClick={() => this.showAccountInfoModal({}, false)}
                      >
                        取消
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            )}
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default BigDataAccount;
