import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './index.less';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { Table, Modal, Card, message } from 'antd';
import { useParams } from 'react-router-dom';
import { getStallStaffList, updateStallStaffStatus } from '../../state/request/basic';
const { confirm } = Modal;

const StaffList = () => {
  const { stallId } = useParams();
  const [data, setData] = useState({
    loading: false,
    list: [],
  });

  const getList = async () => {
    setData({ ...data, loading: true });
    const { code, data: res } = await getStallStaffList(stallId);
    setData({ ...data, loading: false });
    if (code === 200) {
      setData({
        list: res ?? [],
      });
    }
  };

  const handleChangeStatus = (record = {}) => {
    confirm({
      title: `确定要${!record.enable ? '启用' : '禁用'}吗?`,
      content: '',
      async onOk() {
        const { code } = await updateStallStaffStatus({
          employId: record.employId,
          enable: !record.enable,
          stallId,
        });

        if (code === 200) {
          message.success('操作成功');
          getList();
        }
      },
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
    },
    {
      title: '状态',
      dataIndex: 'enable',
      render(val) {
        return val ? '启用' : '禁用';
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_, record = {}) => {
        const { enable, adminFlag } = record;
        const desc = '档主不可以修改状态';
        return (
          <span
            className={classnames('action', { 'action--disable': adminFlag })}
            onClick={() => {
              if (!adminFlag) {
                handleChangeStatus(record);
              } else {
                message.warning(desc);
              }
            }}
          >
            {enable ? '禁用' : '启用'}
          </span>
        );
      },
    },
  ];
  return (
    <div className='staff-list'>
      <PageHeaderLayout>
        <Card className='listCard' bordered={false} title=''>
          <Table
            rowKey='personId'
            columns={columns}
            loading={data.loading}
            dataSource={data.list}
          />
        </Card>
      </PageHeaderLayout>
    </div>
  );
};

export default StaffList;
