// // 版本管理----新增
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Button, Input, Col, Row, Select, message, InputNumber, Radio } from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import { fetchSaveOrUpdateVer, fetchAppVerById } from '../../../state/action/appVersion';
import '../style.less';

import { isEmptyObject } from '../../../utils/reg';
import { appTypeList } from '../../../utils/appType';

const Option = Select.Option;
const FormItem = Form.Item;
const { TextArea } = Input;

@connect(
  (state) => {
    return { appVersion: state.appVersion };
  },
  { fetchSaveOrUpdateVer, fetchAppVerById },
)
@Form.create()
class VersionAdd extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.id = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
  }
  componentDidMount() {
    if (this.id !== undefined) {
      this.fetchAppVerById({ id: this.id });
    }
  }
  // 新增版本信息
  async fetchSaveOrUpdateVer(params) {
    await this.props.fetchSaveOrUpdateVer(params);
  }
  // 查看版本号
  async fetchAppVerById(params) {
    await this.props.fetchAppVerById(params);
    const {
      appVersion: { appVerByIdData },
    } = this.props;
    if (!Array.isArray(appVerByIdData) && appVerByIdData !== undefined && appVerByIdData !== null) {
      if (appVerByIdData.isForce === 1) {
        this.setState({ versionTypeEnable: true });
      }
    }
  }

  // 提交
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      const {
        version,
        os,
        url,
        isForce,
        content,
        versionNo,
        versionType,
        channel,
        releaseStatus,
        enable,
        system,
      } = fieldsValue;
      await this.fetchSaveOrUpdateVer({
        version: version,
        os: os,
        url: url,
        isForce: isForce,
        content: content,
        id: this.id,
        enable,
        versionNo: versionNo,
        versionType: versionType,
        channel,
        releaseStatus,
        system,
      });
      const {
        appVersion: { saveOrUpdateVerData },
      } = this.props;
      if (saveOrUpdateVerData.code && saveOrUpdateVerData.code === 200) {
        message.info(`${!this.id ? '新增' : '修改'}版本管理成功！`);
        this.props.history.push('/version/versionmanagement');
      } else {
        message.error(saveOrUpdateVerData.msg);
      }
    });
  };
  //返回
  back = () => {
    this.props.history.push('/version/versionmanagement');
  };

  onChange = (e) => {
    let system = 1;
    if (e === 1 || e === 2) {
      system = 1;
    } else {
      system = 2;
    }
    this.props.form.setFieldsValue({
      system: system,
    });
  };

  render() {
    const {
      appVersion: { appVerByIdData },
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    let isForce = null,
      os = null,
      url = null,
      version = null,
      content = null,
      versionNo = null,
      versionType = null,
      channel = null,
      releaseStatus = null,
      enable = null,
      system = null;

    if (this.id !== undefined) {
      if (
        !Array.isArray(appVerByIdData) &&
        appVerByIdData !== undefined &&
        appVerByIdData !== null
      ) {
        os = appVerByIdData.os;
        url = appVerByIdData.url;
        isForce = appVerByIdData.isForce + '';
        version = appVerByIdData.version;
        content = appVerByIdData.content;
        versionNo = appVerByIdData.versionNo;
        versionType = appVerByIdData.versionType + '';
        channel = appVerByIdData.channel;
        releaseStatus = appVerByIdData.releaseStatus;
        enable = appVerByIdData.enable;
        system = appVerByIdData.system;
      }
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const extraContent = (
      <div className={'extraContent'}>
        <Form
          onSubmit={this.handleSubmit}
          className='login-form'
          style={{ marginBottom: '12px', float: 'left', width: '100%' }}
        >
          <FormItem {...formItemLayout} label='版本号'>
            {getFieldDecorator('version', {
              initialValue: version,
              rules: [{ required: true, message: '请输入版本号!' }],
            })(<Input placeholder='请输入版本号' />)}
          </FormItem>
          <FormItem {...formItemLayout} label='版本号码'>
            {getFieldDecorator('versionNo', {
              initialValue: versionNo,
              rules: [{ required: true, message: '请输入版本号码!' }],
            })(<InputNumber style={{ width: '200px' }} placeholder='请输入版本号码' />)}
          </FormItem>
          <FormItem {...formItemLayout} label='类型'>
            {getFieldDecorator('os', {
              initialValue: os,
              rules: [{ required: true, message: '请选择类型!' }],
            })(
              <Select placeholder='类型'>
                {appTypeList.map((item) => (
                  <Option key={item.value}>{item.name}</Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='是否强制升级'>
            {getFieldDecorator('isForce', {
              initialValue: isForce,
              rules: [{ required: true, message: '是否强制升级!' }],
            })(
              <Select placeholder='是否强制升级'>
                <Option key='1'>是</Option>
                <Option key='0'>否</Option>
              </Select>,
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='版本类型'>
            {getFieldDecorator('versionType', {
              initialValue: versionType,
              rules: [{ required: true, message: '请选择版本类型!' }],
            })(
              <Select placeholder='请选择版本类型'>
                <Option key='1'>全量</Option>
                <Option key='2'>灰度</Option>
              </Select>,
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='版本类型说明'>
            <p>
              <font color='red'>
                <b>收银端灰度版本获取方式</b>
              </font>
              &nbsp;个人中心点击10次"关于菜小秘"后弹出新版本提示
            </p>
          </FormItem>
          <FormItem {...formItemLayout} label='菜小秘url'>
            {getFieldDecorator('url', {
              initialValue: url,
              rules: [{ required: true, message: '请输入网址!' }],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='版本说明'>
            {getFieldDecorator('content', {
              initialValue: content,
              rules: [{ required: true, message: '请输入版本说明!' }],
            })(
              <TextArea
                placeholder='请输入版本说明'
                autosize={{ minRows: 2, maxRows: 10 }}
                maxLength={255}
              />,
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='渠道'>
            {getFieldDecorator('channel', {
              initialValue: channel || 1,
              rules: [{ required: true, message: '请选择渠道!' }],
            })(
              <Select onChange={this.onChange}>
                <Option value={1}>官方渠道</Option>
                <Option value={2}>android</Option>
                <Option value={3}>ios</Option>
              </Select>,
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='操作类型'>
            {getFieldDecorator('system', {
              initialValue: system || 1,
              rules: [{ required: true, message: '请选择操作类型!' }],
            })(
              <Radio.Group>
                <Radio value={1} disabled>
                  Android
                </Radio>
                <Radio value={2} disabled>
                  IOS
                </Radio>
              </Radio.Group>,
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='审核状态'>
            {getFieldDecorator('releaseStatus', {
              initialValue: releaseStatus || 2,
              rules: [{ required: true, message: '请选择审核状态!' }],
            })(
              <Radio.Group>
                <Radio value={2}>审核通过</Radio>
                <Radio value={1}>审核中</Radio>
              </Radio.Group>,
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='是否维护'>
            {getFieldDecorator('enable', {
              initialValue: enable === 0 ? 0 : 1,
              rules: [{ required: true, message: '请选择是否维护!' }],
            })(
              <Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否（强制该版本用户升级到最新版）</Radio>
              </Radio.Group>,
            )}
          </FormItem>
          <Row>
            <Col align='center'>
              <Button
                type='primary'
                style={{ float: 'center', width: '100px', textAlign: 'center' }}
                htmlType='submit'
              >
                保存
              </Button>
              <Button
                style={{ marginLeft: '5px', width: '100px', textAlign: 'center' }}
                onClick={this.back}
              >
                返回
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );

    return (
      <div className='version-index'>
        <PageHeaderLayout>
          <div className={'batchLayout'}>
            <Card
              className={'listCard'}
              bordered={false}
              title=''
              style={{ marginTop: 24 }}
              bodyStyle={{ padding: '0 16px 40px 16px' }}
              extra={extraContent}
            >
              <div />
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default VersionAdd;
