import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Layout, Affix, message, Icon } from 'antd';
import { withRouter, Switch, Route } from 'react-router-dom';
import { enquireScreen, unenquireScreen } from 'enquire-js';

import { hasRoles } from '../../utils/authority';
import { getAuthorityUserInfo } from '../../utils/authority';
import Authorized from '../../utils/Authorized';
import { isEmptyObject } from '../../utils/reg';

import SiderMenu from '../../component/sider-menu/index';
import GlobalHeader from '../../component/global-header/index';
import logoImg from '../../common/images/WechatIMG874.png';
import { getMenuData } from '../../common/js/menu';
import GlobalFooter from '../../component/global-footer/index';

import {
  saveStall,
  changeLayoutCollapsed,
  clearNotices,
  saveNotices,
} from '../../state/action/global';
import { logout } from '../../state/action/login';

import SysLog from '../sys-log/index'; //系统日志
import StallManageMent from '../booth-stall-management/index'; //商行管理
import AddStallManageMent from '../booth-stall-management/pages/AddStallManage'; //商行管理--新增商行
import MobileCashApp from '../booth-stall-management/pages/mobileCashApp'; //商行管理--手机收银端白名单
import StallManageDetail from '../booth-stall-management/pages/StallManageDetail'; //商行管理--查看详情

import NewDeviceManagement from '../device-newManagement/index'; //设备管理（新）
import BoothOrgManagement from '../booth-org-management/index'; //组织架构管理
import BoothOrgRelationStall from '../booth-org-management/OrgRelationStall'; //组织架构管理-机构辖下商行
import BoothOrgRelationAccount from '../booth-org-management/OrgRelationAccount'; //组织架构管理-机构辖下商行
import BoothAllGroup from '../booth-all-group/index'; //商行管理-档口分组配置
import BoothGroup from '../booth-all-group/BoothGroup'; //商行管理-档口分组配置-分组档口
import BoothConfig from '../booth-all-group/BoothConfig'; //商行管理-档口分组配置-分组配置
import BoothSysConfig from '../booth-sys-config/index'; //商行管理-系统配置
import BoothWhiteList from '../booth-white-list/index'; //商行管理-白名单查询
import ContractManagement from '../contract-management/index'; //商行管理-合同管理
import MemberManagement from '../member-management/index'; //商行管理-会员管理
import MemberServicesManagement from '../member-services-management/index'; //商行管理-会员服务管理
import MemberManagementDetail from '../member-management/detail'; //商行管理-会员管理--查看详情
import OnlineStoreManage from '../online-store-manage/index'; //商行管理-网店信息维护
import VoiceCDKEY from '../voiceCDKEY/index'; //商行管理-语音包激活码管理
import WihteListMaintain from '../stall-management/white-list-maintain/index'; //商行管理-老档主端白名单维护

import CountPaymentCalculation from '../count-payment-calculation/index'; //计算配置-贷款计算
import MergeSetList from '../count-mergeSet-allocation/index'; //计算配置-合单配置
import MergeSetGroup from '../count-mergeSet-allocation/conAllocationGroup'; //计算配置-合单配置
import ConfigGroup from '../count-result-setting/ConfigGroup'; //计算配置-配置分组
import GroupConfig from '../count-result-setting/GroupConfig'; //计算配置-配置分组(分组配置)
import ConfigGroupDisplay from '../count-result-setting/ConfigGroupDisplay'; //计算配置-配置分组(默认值显示配置)

import ConfigList from '../count-config-list/index'; //计算配置-配置列表
import ConfigDisplay from '../count-config-list/ConfigDisplay'; //计算配置-配置列表(前台显示配置)

import Statistics from '../data-statics/statics'; //数据统计 --数据统计
import BillStatistics from '../data-statics/billStatistics'; //数据统计 --商行单据统计
import DataAnalysis from '../data-statics/dataAnalysis'; //数据统计 --商行数据分析
import RemotePayAnalysis from '../data-statics/remotePayAnalysis'; //数据统计 --在线还款流水表
import DunningByLaw from '../data-statics/dunningByLaw'; //数据统计 --法律催款
import CaixmPhone from '../data-statics/caixmPhone'; //数据统计 --商行单据统计
import SalesManagement from '../data-statics/salesManagement'; //数据统计 --销售人员管理页面
import DailyAddStall from '../data-statics/dailyAddStall'; //数据统计 --每日新增统计
import DailyBusinessStall from '../data-statics/dailyBusinessStall'; //数据统计 --每日交易统计

import VersionManagement from '../version-management/index'; //版本管理-版本管理
import VersionAdd from '../version-management/add/index'; //版本管理-版本管理-新增或修改
import VersionPushConfig from '../version-management/push-config/index'; //版本管理-版本管理-推送配置
import VersionTradingManagement from '../version-management/trading-management/index'; //版本管理-版本管理-商行管理

import Market from '../market/index'; //市场列表
import MarketModify from '../market/modify'; //市场列表-添加和修改
import BigDataAccount from '../market/bigDataAccount'; //市场列表-大数据账号维护
import GoodsManage from '../market/goodsManage'; //市场列表-添加和修改

import CommodityBase from '../commodity-management/commodity-base/index'; //商品库管理-商品基础库
import AddAlias from '../commodity-management/add-alias/index'; //商品库管理-商品基础库-别名维护
import UnassociatedGoods from '../commodity-management/unassociated-goods/index'; //商品库管理-未关联货品表

import Welcome from '../welcome/index'; //欢迎页面

import BatchManager from '../sys-batch/index'; //批次管理
import PersonManager from '../sys-person/index'; //人员信息
import abnormalOperation from '../sys-operation/index'; //异常信息查询
import BatchItem from '../sys-batch/batchItem'; //批次管理
import BatchSaleDetail from '../sys-batch/batchSaleDetail'; //批次销售明细
import BatchItemLogs from '../sys-batch/batchItemLogs'; //库存记录
// 系统公告管理
import SystemNoticeManagement from '../system-announcement-management';
import DataExport from '../system-data-export';
import supplierAppList from '../system-supplier-list';


import GoodsLibrary from '../data-service/goodsLibrary'; //商品库管理-商品基础库-别名维护
import GoodsAliasMaintenance from '../data-service/aliasMaintenance'; //商品库管理-别名维护
import UnconnectedGoods from '../data-service/unconnectedGoods'; //商品库管理-未关联货品表
import MarketStallStatistic from '../data-bi/marketStallStatistic'; //市场统计数据
import StallActiveInfo from '../data-bi/stallActiveInfo'; //商行活跃信息
import BankPayment from '../data-statics/bankPayment/index'; //银行支付
import GoodsSale from '../data-service/goodsSale'; //数据服务管理-数据服务基库货品
import GoodsSaleDetail from '../data-service/goodsSaleDetail'; //数据服务管理-数据服务基库货品-该货品该市场的明细页面
import GoodsSaleDetailTime from '../data-service/goodsSaleDetailTime'; //数据服务管理-数据服务基库货品-该货品该市场的明细页面-进入到时间段明细页面
import cancelSign from '../sign/cancelSign'; //系统管理-撤销签字
import StaffList from '../staff-list/index';
// 增值服务管理
import AddServices from '../add-services/index';
// 已开通商行
import OpenStall from '../add-services/components/OpenStall/index';
// 数据统计-增值服务订单
import ServeOrder from '../serve-order';
// 商行管理-广告管理
import AdvertisingManagement from '../advertising-management/index';
// 数据统计-新签交付统计表
import NewSign from '../new-sign';
import './style.less';

const { Header, Footer, Content } = Layout;
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

@withRouter
@connect((state) => state.global, {
  saveStall,
  changeLayoutCollapsed,
  clearNotices,
  saveNotices,
})
class Home extends Component {
  state = {
    isMobile,
  };

  async componentDidMount() {
    if (!hasRoles()) {
      this.props.history.push('/login');
    } else {
      this.props.saveStall();
      this.enquireHandler = enquireScreen((mobile) => {
        this.setState({
          isMobile: mobile,
        });
      });
    }
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler);
  }

  handleMenuCollapse = (collapsed) => {
    this.props.changeLayoutCollapsed(collapsed);
  };

  //header
  handleNoticeClear = (type) => {
    message.success(`清空了${type}`);
    this.props.clearNotices(type);
  };
  handleMenuClick = ({ key }) => {
    if (key === 'workplace') {
      this.props.history.push('/stall/workplace');
    }
    if (key === 'triggerError') {
      this.props.history.push('/exception/trigger');
    }
    if (key === 'modify') {
      this.props.history.push('/employee/modify');
    }
    if (key === 'logout') {
      logout();
    }
  };
  handleNoticeVisibleChange = (visible) => {
    if (visible) {
      this.props.saveNotices();
    }
  };

  render() {
    if (!hasRoles()) return null;
    const { collapsed, fetchingNotices, notices, noticesNum, location } = this.props;
    let currentUser = getAuthorityUserInfo();
    return (
      <div className='home'>
        <Layout>
          {/* <Sider> */}
          <SiderMenu
            logo={logoImg}
            // 不带Authorized参数的情况下如果没有权限,会强制跳到403界面
            // If you do not have the Authorized parameter
            // you will be forced to jump to the 403 interface without permission
            Authorized={Authorized}
            menuData={getMenuData()}
            collapsed={collapsed}
            location={location}
            isMobile={this.state.isMobile}
            onCollapse={this.handleMenuCollapse}
          />
          {/* </Sider> */}
          <Layout>
            <Affix offsetTop={0}>
              <Header style={{ padding: 0 }}>
                <GlobalHeader
                  logo={logoImg}
                  currentUser={currentUser}
                  fetchingNotices={fetchingNotices}
                  notices={notices}
                  noticesNum={noticesNum}
                  collapsed={collapsed}
                  isMobile={this.state.isMobile}
                  onNoticeClear={this.handleNoticeClear}
                  onCollapse={this.handleMenuCollapse}
                  onMenuClick={this.handleMenuClick}
                  onNoticeVisibleChange={this.handleNoticeVisibleChange}
                />
              </Header>
            </Affix>

            <Content>
              <Switch>
                {/** 系统管理 */}
                <Route path='/system/sysLog' component={SysLog}></Route>
                <Route path='/system/batchManager/batchItem' component={BatchItem}></Route>
                <Route
                  path='/system/batchManager/batchSaleDetail'
                  component={BatchSaleDetail}
                ></Route>
                <Route path='/system/batchManager/batchItemLogs' component={BatchItemLogs}></Route>
                <Route path='/system/batchManager' component={BatchManager}></Route>
                <Route path='/system/personManager' component={PersonManager}></Route>
                <Route path='/system/abnormalOperation' component={abnormalOperation}></Route>
                <Route path='/system/systemNotice' component={SystemNoticeManagement}></Route>
                <Route path='/system/dataExport' component={DataExport}></Route>
                <Route path='/system/supplierAppList' component={supplierAppList}></Route>
                {/** 商行管理 */}
                <Route
                  path='/booth/stallManager/stallManagementDetail'
                  component={StallManageDetail}
                ></Route>
                <Route
                  path='/booth/stallManager/NewStallManage'
                  component={AddStallManageMent}
                ></Route>
                <Route path='/booth/stallManager' component={StallManageMent}></Route>
                <Route path='/booth/newDeviceManagement' component={NewDeviceManagement}></Route>
                <Route path='/booth/contractManagement' component={ContractManagement}></Route>
                {/* 商行管理/合同管理 */}
                {/* 会员管理 */}
                <Route path='/booth/memberManagement' component={MemberManagement}></Route>
                <Route
                  path='/booth/memberManagementDetail'
                  component={MemberManagementDetail}
                ></Route>
                <Route
                  path='/booth/memberServicesManagement'
                  component={MemberServicesManagement}
                ></Route>
                {/* 员工列表 */}
                <Route path='/booth/orgManagement/staffList/:stallId' component={StaffList}></Route>
                <Route path='/booth/addService' component={AddServices} />
                <Route path='/booth/openStall/:id/:name' component={OpenStall} />
                <Route path='/booth/advertisingManagement' component={AdvertisingManagement} />
                <Route
                  path='/booth/orgManagement/account/:id'
                  component={BoothOrgRelationAccount}
                ></Route>
                <Route
                  path='/booth/orgManagement/stall/:id'
                  component={BoothOrgRelationStall}
                ></Route>
                <Route path='/booth/orgManagement' component={BoothOrgManagement}></Route>
                <Route path='/booth/boothAllGroup/boothGroup/:id' component={BoothGroup}></Route>
                <Route path='/booth/boothAllGroup/boothConfig/:id' component={BoothConfig}></Route>
                <Route path='/booth/boothAllGroup' component={BoothAllGroup}></Route>
                <Route path='/booth/sysConfig' component={BoothSysConfig}></Route>
                <Route path='/booth/WhiteList' component={BoothWhiteList}></Route>
                <Route path='/booth/onlineStoreManage' component={OnlineStoreManage}></Route>
                <Route path='/booth/voiceCDKEY' component={VoiceCDKEY}></Route>
                <Route
                  path='/booth/oldStallOwnerWihteListMaintain'
                  component={WihteListMaintain}
                ></Route>
                <Route path='/booth/mobileCashApp' component={MobileCashApp}></Route>
                {/** 计算配置 */}
                <Route path='/count/paymentcalculation' component={CountPaymentCalculation}></Route>
                <Route path='/count/mergeSet/MergeSetGroup' component={MergeSetGroup}></Route>
                <Route path='/count/mergeSet' component={MergeSetList}></Route>
                <Route path='/count/configGroup/groupConfig' component={GroupConfig}></Route>
                <Route
                  path='/count/configGroup/configGroupDisplay'
                  component={ConfigGroupDisplay}
                ></Route>
                <Route path='/count/configGroup' component={ConfigGroup}></Route>
                <Route path='/count/configlist/configDisplay/:id' component={ConfigDisplay}></Route>
                <Route path='/count/configlist' component={ConfigList}></Route>
                {/** 数据统计 */}
                <Route path='/statics/statistics' component={Statistics}></Route>
                <Route path='/statics/billStatistics' component={BillStatistics}></Route>
                {/* 数据统计 - 商行单据统计 */}
                <Route path='/statics/dataAnalysis' component={DataAnalysis}></Route>
                {/* 数据统计 - 商行数据分析 */}
                <Route path='/statics/remotePayAnalysis' component={RemotePayAnalysis}></Route>
                <Route path='/statics/dunningByLaw' component={DunningByLaw}></Route>
                {/* 数据统计 - 在线还款流水表 */}
                <Route path='/statics/caixmPhone' component={CaixmPhone}></Route>
                <Route path='/statics/salesManagement' component={SalesManagement}></Route>{' '}
                {/* 数据统计 - 销售人员管理页面 */}
                <Route path='/statics/dailyAddStall' component={DailyAddStall}></Route>{' '}
                {/* 数据统计 - 每日新增统计 */}
                <Route
                  path='/statics/dailyBusinessStall'
                  component={DailyBusinessStall}
                ></Route>{' '}
                <Route path='/statics/serveOrder' component={ServeOrder} />
                <Route path='/statics/newSign' component={NewSign} />
                {/* 数据统计 - 每日交易统计 */}
                {/** 版本管理 */}
                <Route path='/version/versionmanagement/view/:id' component={VersionAdd}></Route>
                <Route path='/version/versionmanagement/add' component={VersionAdd}></Route>
                <Route
                  path='/version/versionmanagement/pushConfig/:id'
                  component={VersionPushConfig}
                ></Route>
                <Route
                  path='/version/versionmanagement/tradingManagement/:versionId'
                  component={VersionTradingManagement}
                ></Route>
                <Route path='/version/versionmanagement' component={VersionManagement}></Route>
                {/** 市场列表 */}
                <Route path='/marketList/marketList/modify/:id' component={MarketModify}></Route>
                <Route path='/marketList/goodsManage/:id' component={GoodsManage}></Route>
                <Route path='/marketList/marketList/modify' component={MarketModify}></Route>
                <Route
                  path='/marketList/marketList/bigDataAccount'
                  component={BigDataAccount}
                ></Route>
                <Route path='/marketList/marketList' component={Market}></Route>
                <Route path='/marketList' component={Market}></Route>
                {/** 商品库管理 */}
                <Route path='/commodityManage/commodityBase/addAlias' component={AddAlias}></Route>
                <Route path='/commodityManage/commodityBase' component={CommodityBase}></Route>
                <Route
                  path='/commodityManage/unassociatedGoods'
                  component={UnassociatedGoods}
                ></Route>
                {/** 数据服务管理 */}
                {/* 数据服务商品库管理-别名维护 */}
                <Route
                  path='/data-service/goodsLibrary/:id'
                  component={GoodsAliasMaintenance}
                ></Route>
                {/* 数据服务商品库管理 */}
                <Route path='/data-service/goodsLibrary' component={GoodsLibrary}></Route>
                {/* 未关联货品表 */}
                <Route path='/data-service/unconnectedGoods' component={UnconnectedGoods}></Route>
                {/* 数据服务基库货品-该货品该市场的明细页面-进入到时间段明细页面 */}
                <Route
                  path='/data-service/goodsSale/:id/:hourPriceId'
                  component={GoodsSaleDetailTime}
                ></Route>
                {/* 数据服务基库货品-该货品该市场的明细页面 */}
                <Route path='/data-service/goodsSale/:id' component={GoodsSaleDetail}></Route>
                {/* 数据服务基库货品 */}
                <Route path='/data-service/goodsSale' component={GoodsSale}></Route>
                {/* 市场统计数据 */}
                <Route
                  path='/statics/marketStallStatistic'
                  component={MarketStallStatistic}
                ></Route>
                {/* 商行活跃信息 */}
                <Route path='/statics/stallActiveInfo' component={StallActiveInfo}></Route>
                {/* 建行支付申请 */}
                <Route path='/statics/bankPayment' component={BankPayment}></Route>
                <Route path='/booth/cancelSign' component={cancelSign}></Route>
                <Route path='/' component={Welcome}></Route>
              </Switch>
            </Content>
            <Footer>
              <GlobalFooter
                links={[
                  {
                    key: '菜小秘APP下载',
                    title: '菜小秘APP下载',
                    href: 'http://www.caixm.cn/#downloadHtml',
                    blankTarget: true,
                  },
                ]}
                copyright={
                  <Fragment>
                    Copyright <Icon type='copyright' /> {new Date().getFullYear()} @菜小秘
                  </Fragment>
                }
                type='page'
              />
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default Home;
