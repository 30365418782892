// 版本管理页面
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Table, Card, Button, Icon, Input, Divider, Select, Row } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { appTypeList } from '../../utils/appType';
import { fetchAppList, fetchAppVerById } from '../../state/action/appVersion';

import './style.less';

const { Column } = Table;
const FormItem = Form.Item;

@connect(
  (state) => {
    return { appVersion: state.appVersion };
  },
  { fetchAppList, fetchAppVerById },
)
@Form.create()
class VersionManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      loading: true,
    };
    this.updataState = this.updataState.bind(this);
  }

  componentDidMount() {
    this.fetchAppList({ pageSize: 10, pageNo: 1 });
  }

  updataState(value) {
    this.setState({
      value: value,
    });
  }

  // 获取版本新增信息列表
  async fetchAppList(params) {
    await this.props.fetchAppList(params);
    this.setState({ loading: false });
  }

  // 查询版本号
  async fetchAppVerById(params) {
    await this.props.fetchAppVerById(params);
  }

  // 查询
  handleOnQuery = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const { version, appType } = fieldsValue;
      let params = {
        pageSize: 10,
        pageNo: 1,
      };
      if (version !== undefined) {
        params.version = version;
      }
      if (appType !== undefined && appType !== 'all' && appType !== '全部') {
        params.appType = appType;
      }
      // 其他系统类型后台未生效
      this.fetchAppList(params);
      this.setState({ ...params, ...fieldsValue });
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    const params = {};
    const { version, appType } = this.state;
    if (appType !== undefined && appType !== 'all' && appType !== '全部') {
      params.appType = appType;
    }
    params.version = version;
    this.fetchAppList({
      pageSize: obj.pageSize,
      pageNo: obj.current,
      ...params,
    });
  };

  toTradingCompanyManagement(e, record) {
    e.preventDefault();
    if (record.versionType !== 1) {
      this.props.history.push(`/version/versionmanagement/tradingManagement/${record.id}`);
    }
  }
  toView(e, id) {
    e.preventDefault();
    this.props.history.push(`/version/versionmanagement/view/${id}`);
  }
  toPushConfig(e, id) {
    e.preventDefault();
    this.props.history.push(`/version/versionmanagement/pushConfig/${id}`);
  }
  toAdd() {
    this.props.history.push(`/version/versionmanagement/add`);
  }

  render() {
    const {
      appVersion: { appListData },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    let versionLst = appListData.data;

    const total = appListData.total;
    const pagination = {
      pageSize: 10,
      total: total,
    };
    const extraContent = (
      <div style={{ width: '100%' }}>
        <Form layout='inline' onSubmit={this.handleOnQuery} className='login-form'>
          <FormItem label='版本号'>
            {getFieldDecorator('version', {
              rules: [{ required: false, message: '搜索版本号!' }],
            })(
              <Input
                prefix={<Icon type='search' style={{ color: 'rgba(0,0,0,.25)' }} />}
                style={{ marginTop: '5px' }}
                placeholder='搜索版本号'
                allowClear={true}
              />,
            )}
          </FormItem>
          <FormItem label='系统类型'>
            {getFieldDecorator('appType', {
              rules: [{ required: false, message: '请选择市场类型!' }],
              initialValue: 'all',
            })(
              <Select style={{ width: 250 }}>
                {appTypeList.map((appType) => {
                  const { name, value } = appType;
                  return <Select.Option key={value}>{name ? name : ''}</Select.Option>;
                })}
              </Select>,
            )}
          </FormItem>
          <Row>
            <div style={{ marginTop: '5px' }}>
              <Button type='primary' style={{ width: 80 }} htmlType='submit'>
                查询
              </Button>
              <Button style={{ marginLeft: '12px', width: 80 }} onClick={() => this.toAdd()}>
                新增
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    );

    return (
      <div className='version-index'>
        <PageHeaderLayout>
          <div className={'batchLayout'}>
            <Card
              className={'listCard'}
              bordered={false}
              title=''
              bodyStyle={{ padding: '0 16px 40px 16px', marginTop: 12 }}
              extra={extraContent}
            >
              <Table
                rowKey={(record, index) => index}
                dataSource={versionLst}
                pagination={pagination}
                scroll={{ x: 600 }}
                bordered
                loading={this.state.loading}
                onChange={this.pageSwitch.bind(this)}
              >
                <Column title='版本号' dataIndex='version' key='version' />
                {/*  <Column title="系统类型" dataIndex="os" key="os" /> */}
                <Column
                  title='系统类型'
                  dataIndex='os'
                  key='os'
                  render={(text, record) => {
                    const { os } = record;
                    if (os === 'androidPhone') {
                      return '收银手机端';
                    } else if (os === 'stallPhone') {
                      return '档主端';
                    } else if (os === 'purchase') {
                      return '买家端';
                    } else if (os === 'supplier') {
                      return '货主端';
                    } else if (os === 'INTEGRATIONAPP') {
                      return '菜小秘';
                    } else {
                      return os;
                    }
                  }}
                />
                <Column
                  title='是否强制升级'
                  dataIndex='isForce'
                  key='isForce'
                  render={(text, record) => {
                    const { isForce } = record;
                    return <span>{isForce ? '是' : '否'}</span>;
                  }}
                />
                <Column
                  title='版本类型'
                  dataIndex='versionType'
                  key='versionType'
                  render={(text, record) => {
                    if (text === 2) {
                      return '灰度';
                    }
                    return '全量';
                  }}
                />
                <Column
                  title='操作类型'
                  dataIndex='system'
                  key='system'
                  render={(text, record) => {
                    const { system } = record;
                    if (1 === system) {
                      return 'Android';
                    } else if (2 === system) {
                      return 'IOS';
                    } else {
                      return '-';
                    }
                  }}
                />
                <Column
                  title='审核状态'
                  dataIndex='releaseStatus'
                  key='releaseStatus'
                  render={(text, record) => {
                    const { releaseStatus } = record;
                    if (2 === releaseStatus) {
                      return '审核通过';
                    } else if (1 === releaseStatus) {
                      return '审核中';
                    } else {
                      return '-';
                    }
                  }}
                />
                <Column
                  title='是否维护'
                  dataIndex='enable'
                  key='enable'
                  render={(text, record) => {
                    const { enable } = record;
                    if (0 === enable) {
                      return '否';
                    } else if (1 === enable) {
                      return '是';
                    } else {
                      return '-';
                    }
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='currDayCreditAmount'
                  key='currDayCreditAmount'
                  render={(text, record) => {
                    const { id, versionType } = record;
                    //  href={`/version/view/${id}`}  href={`version/pushConfig/${id}`}
                    return (
                      <div>
                        <a href='/' onClick={(e) => this.toView(e, id)}>
                          修改
                        </a>
                        <Divider type='vertical' />
                        <a href='/' onClick={(e) => this.toPushConfig(e, id)}>
                          推送配置
                        </a>
                        <Divider type='vertical' />
                        <a
                          style={{ color: versionType !== 1 ? '#1D9E60' : '#c5bcbc' }}
                          onClick={(e) => this.toTradingCompanyManagement(e, record)}
                          // href={versionType !== 1 ? `version/tradingCompanyManagement/${id}` : "javascript:;"}
                          href='/'
                        >
                          商行列表
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default VersionManagement;
