/**
 * 设备管理（新）
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Card,
  Table,
  Select,
  Row,
  Col,
  message,
  Divider,
  Checkbox,
  Icon,
  Tooltip,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import './index.less';
import AddDeviceModal from './addManagement/index';
import { strSplit } from '../../utils/utils';
import { marketList } from '../../state/action/stall';
import { getStallList } from '../../state/action/basic';

import {
  getListDeviceModes,
  getListDeviceTypes,
  getAddDevice,
  getUpdateDevice,
  getListDevices,
  getConnectPrinter,
} from '../../state/action/contract';
const FormItem = Form.Item;

@connect(
  (state) => {
    return { stall: state.stall, basic: state.basic, contract: state.contract };
  },
  {
    marketList,
    getStallList,
    getListDeviceModes,
    getListDeviceTypes,
    getAddDevice,
    getUpdateDevice,
    getListDevices,
    getConnectPrinter,
  },
)
@Form.create()
class NewDeviceManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expand: false, //筛选图标
      deviceModelData: null, //打印机类型
      deviceTypeData: null, //打印机型号
      showModal: false,
      sysPrinterConfig: {},
      cxmBdStalls: {},
      isEdit: false, //新增/编辑
      defaultCurrent: 1,
      search: {
        type: 0,
        deviceModel: null,
        deviceType: null,
        name: null,
        stallId: null,
        machineCode: '', //SN码如果为空 后端要求传空字符串
        enable: null,
        status: null,
        stallName: null,
      },
    };
  }
  componentDidMount() {
    this.getListDeviceModes(); //获取设备类型
    this.resetSearch(); //获取列表信息
  }
  //刷新列表
  resetSearch = () => {
    const { search } = this.state;
    this.getListDevices({ ...search, pageSize: 10, pageNo: 1 }); //获取设备列表type:0,(后端为兼容老版本需传)
  };
  // 获取设备类型
  async getListDeviceModes(params) {
    await this.props.getListDeviceModes(params);
  }
  // 获取设备型号
  async getListDeviceTypes(params) {
    await this.props.getListDeviceTypes(params);
  }
  //新增设备
  async getAddDevice(params) {
    await this.props.getAddDevice(params);
  }
  //编辑设备
  async getUpdateDevice(params) {
    await this.props.getUpdateDevice(params);
  }
  // 获取设备列表
  async getListDevices(params) {
    await this.props.getListDevices(params);
  }
  //启用/禁用
  //编辑设备
  async getConnectPrinter(params) {
    this.setState({ loading: true });
    await this.props.getConnectPrinter(params);
    this.setState({ loading: false });
  }
  // 获取档口列表
  async getStallList(value) {
    await this.props.getStallList(value);
  }
  //筛选收起
  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };
  //查询
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      [
        'deviceModel',
        'deviceType',
        'name',
        'stallId',
        'machineCode',
        'enable',
        'status',
        'stallName',
      ],
      (errors, values) => {
        if (!!errors) {
          return;
        }
        const { deviceModel, deviceType, name, stallId, machineCode, enable, status, stallName } =
          values;
        values = {
          deviceModel: deviceModel || null,
          deviceType: deviceType || null,
          name: name || null,
          stallId: stallId || null,
          machineCode: machineCode || '',
          enable: enable || null,
          status: status || null,
          stallName: stallName || null,
        };
        if (values.deviceType) {
          if (!this.state.deviceModelData) {
            message.warning('请先选择设备类型!');
            return;
          }
        }
        if (values.stallId) {
          if (!/^[0-9]+(.?[0-9])?$/.test(values.stallId)) {
            message.warning('商行ID只能为数字');
            return;
          }
        }

        //全选或者全不选 则不传值给后端
        const enableCur =
          values.enable && values.enable.length > 1
            ? null
            : values.enable && values.enable.length === 1
            ? Number(enable[0])
            : null;
        const statusCur =
          values.status && values.status.length > 1
            ? null
            : values.status && values.status.length === 1
            ? Number(status[0])
            : null;
        this.setState({ search: { ...values, enable: enableCur, status: statusCur, type: 0 } });
        this.getListDevices({
          ...values,
          enable: enableCur,
          status: statusCur,
          type: 0,
          pageSize: 10,
          pageNo: 1,
        });
      },
    );
  };

  showModal = () => {
    //新增模态框开启
    this.setState({
      isEdit: false,
      showModal: true,
      id: '',
      machineCode: '',
      name: '',
      type: '',
      machineKey: '',
      remark: '',
    });
  };

  handleCancel = (e) => {
    this.setState({
      showModal: false,
      id: '',
      machineCode: '',
      name: '',
      type: '',
      machineKey: '',
      remark: '',
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.setState({ defaultCurrent: obj.current });
    const { search } = this.state;
    this.getListDevices({ pageSize: obj.pageSize, pageNo: obj.current, ...search });
  };

  //禁用启用
  ableSysPrinterConfig = async (id, enable) => {
    await this.getConnectPrinter({ id, enable });
    const { search, defaultCurrent } = this.state;
    const {
      contract: { connectPrinterMsg },
    } = this.props;
    if (connectPrinterMsg && connectPrinterMsg.code && connectPrinterMsg.code === 200) {
      message.info(`${enable === 1 ? '启用' : '禁用'}成功！`);
      this.getListDevices({ ...search, pageSize: 10, pageNo: defaultCurrent });
    } else {
      message.warning(connectPrinterMsg.msg);
    }
  };

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.getListDeviceTypes({ name: '' });
    this.setState({ deviceModelData: null });
  };
  //编辑
  showModalByEdit = (sysPrinterConfig, cxmBdStalls) => {
    //点击修改弹出新增模态框
    const { id, deviceModel } = sysPrinterConfig; //id设备id
    const { marketId } = cxmBdStalls;
    let keyCode = { marketId };
    this.setState({ loading: true });
    if (marketId) {
      this.getStallList(keyCode).then(() => {});
    }
    this.setState({
      showModal: true,
      id,
      sysPrinterConfig,
      cxmBdStalls,
      isEdit: true,
      loading: false,
    });
    this.getListDeviceTypes({ name: deviceModel }); //获取设备型号联动
  };

  //设备类型改变
  changeDeviceModel = (v) => {
    this.props.form.setFieldsValue({ deviceType: undefined }); //先清空之前选择的设备型号
    this.setState({ deviceModelData: v });
    this.getListDeviceTypes({ name: v }); //获取设备型号联动
  };
  //设备型号改变
  changeDeviceType = () => {};

  render() {
    const {
      contract: { devicesList = {}, listDeviceModesData = [], listDeviceTypesData = [] },
    } = this.props;
    const { showModal, sysPrinterConfig, cxmBdStalls, isEdit } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { pageList, pageNo = 1, pageSize, totalSize = 1 } = devicesList;
    const pagination = {
      pageSize: pageSize,
      total: totalSize,
      current: pageNo,
    };

    const columns = [
      {
        title: '商行ID',
        dataIndex: 'cxmBdStalls.id',
        key: 'cxmBdStalls.id',
        width: 90,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: '商行名称',
        dataIndex: 'cxmBdStalls.name',
        key: 'cxmBdStalls.name',
        width: 120,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: '设备编号',
        dataIndex: 'sysPrinterConfig.name',
        key: 'sysPrinterConfig.name',
        width: 120,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: '设备类型',
        dataIndex: 'sysPrinterConfig.deviceModel',
        key: 'sysPrinterConfig.deviceModel',
        width: 120,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: '设备型号',
        dataIndex: 'sysPrinterConfig.deviceTypeDesc',
        key: 'sysPrinterConfig.deviceTypeDesc',
        width: 120,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: 'SN码',
        dataIndex: 'sysPrinterConfig.machineCode',
        key: 'sysPrinterConfig.machineCode',
        width: 120,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: '密钥',
        dataIndex: 'sysPrinterConfig.machineKey',
        key: 'sysPrinterConfig.machineKey',
        width: 120,
        render: (text) => {
          return text ? text : '--';
        },
      },
      {
        title: '状态',
        dataIndex: 'sysPrinterConfig.stallId',
        key: 'sysPrinterConfig.stallId',
        width: 100,
        //0-空闲中 不为0时表示使用中
        render: (text) => {
          if (text === 0) {
            return '空闲中';
          } else {
            return '使用中';
          }
        },
      },
      {
        title: '是否启用',
        dataIndex: 'sysPrinterConfig.enable',
        key: 'sysPrinterConfig.enable',
        width: 100,
        //0-禁用 其他均为启用
        render: (_, record) => {
          const { sysPrinterConfig } = record;
          return sysPrinterConfig.enable === 0 ? (
            <div>
              <Button
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5, background: '#ccc' }}
              />
              禁用
            </div>
          ) : (
            <div>
              <Button
                type='primary'
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5 }}
              />
              启用
            </div>
          );
        },
      },
      {
        title: '备注',
        dataIndex: 'sysPrinterConfig.remark',
        key: 'sysPrinterConfig.remark',
        width: 120,
        render: (_, record) => {
          const { sysPrinterConfig } = record;
          const textTip = <span>{sysPrinterConfig.remark}</span>;
          return (
            <Tooltip placement='rightTop' title={textTip}>
              <span>{strSplit(sysPrinterConfig.remark, 8)}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '操作',
        key: 'tags',
        dataIndex: 'tags',
        width: 100,
        render: (_, record) => {
          const { sysPrinterConfig } = record;
          return (
            <div>
              <span
                style={{ color: '#1D9E60', cursor: 'pointer' }}
                onClick={() => {
                  this.showModalByEdit(record.sysPrinterConfig, record.cxmBdStalls);
                }}
              >
                编辑
              </span>
              <Divider type='vertical' />
              {sysPrinterConfig.enable === 1 ? (
                <span
                  style={{ color: '#1D9E60', cursor: 'pointer' }}
                  onClick={() => {
                    this.ableSysPrinterConfig(record.sysPrinterConfig.id, 0);
                  }}
                >
                  禁用
                </span>
              ) : (
                <span
                  style={{ color: '#EC634A', cursor: 'pointer' }}
                  onClick={() => {
                    this.ableSysPrinterConfig(record.sysPrinterConfig.id, 1);
                  }}
                >
                  启用
                </span>
              )}
            </div>
          );
        },
      },
    ];

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const layout = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 };

    return (
      <div className='newDeviceManagement'>
        <PageHeaderLayout>
          <div>
            <Card className='listCard' bordered={false}>
              <div className='searchModule' style={{ padding: 5 }}>
                <Form
                  {...formItemLayout}
                  layout='inline'
                  onSubmit={this.handleSearch}
                  className='login-form'
                >
                  <Row gutter={12}>
                    <Col {...layout}>
                      <FormItem label='设备类型'>
                        {getFieldDecorator('deviceModel', {
                          rules: [{ required: false, message: '请选择设备类型!' }],
                        })(
                          <Select
                            placeholder='请选择设备类型'
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={this.changeDeviceModel}
                          >
                            {listDeviceModesData &&
                              listDeviceModesData.map((item) => (
                                <Select.Option key={item} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='设备型号'>
                        {getFieldDecorator('deviceType', {
                          rules: [{ required: false, message: '请选择设备型号!' }],
                        })(
                          <Select
                            placeholder={
                              this.state.deviceModelData ? '请选择设备型号' : '请先选择设备类型'
                            }
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={this.changeDeviceType}
                          >
                            {listDeviceTypesData &&
                              listDeviceTypesData.map((item) => (
                                <Select.Option key={item.code}>{item.value}</Select.Option>
                              ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='商行名称'>
                        {getFieldDecorator('stallName', {
                          rules: [{ required: false, message: '请输入商行名称!' }],
                        })(<Input placeholder='请输入商行名称' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='商行ID'>
                        {getFieldDecorator('stallId', {
                          rules: [
                            {
                              required: false,
                              message: '请输入商行ID!',
                            },
                          ],
                        })(<Input placeholder='请输入商行ID' allowClear maxLength='11' />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={12} style={{ display: this.state.expand ? 'none' : 'block' }}>
                    <Col {...layout}>
                      <FormItem label='SN &nbsp;&nbsp; 码'>
                        {getFieldDecorator('machineCode', {
                          initialValue: '',
                          rules: [{ required: false, message: '请输入SN码!' }],
                        })(<Input placeholder='请输入SN码' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col {...layout}>
                      <FormItem label='是否启用'>
                        {getFieldDecorator('enable', {
                          rules: [{ required: false, message: '' }],
                        })(
                          //是否启用 0-禁用 1-启用
                          <Checkbox.Group
                            style={{ width: '100%', paddingBottom: '30px' }}
                            onChange={this.onStateChange}
                          >
                            <Checkbox value='1'>启用</Checkbox>
                            <Checkbox value='0'>禁用</Checkbox>
                          </Checkbox.Group>,
                        )}
                      </FormItem>
                    </Col>
                    <Col span={7}>
                      <FormItem label='状 &nbsp;&nbsp; 态'>
                        {getFieldDecorator('status', {
                          rules: [{ required: false, message: '' }],
                        })(
                          //状态 0-空闲中 1-使用中
                          <Checkbox.Group
                            style={{ width: '100%', paddingBottom: '30px' }}
                            onChange={this.onStateChange}
                          >
                            <Checkbox value='1'>使用中</Checkbox>
                            <Checkbox value='0'>空闲中</Checkbox>
                          </Checkbox.Group>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row style={{ padding: '5px 10px' }}>
                    <Button onClick={this.handleReset}>重置</Button>
                    <Button type='primary' htmlType='submit' style={{ marginLeft: '8px' }}>
                      查询
                    </Button>
                    <span style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={this.toggle}>
                      收起筛选 <Icon type={this.state.expand ? 'down' : 'up'} />
                    </span>
                    <Button
                      onClick={() => this.showModal()}
                      type='primary'
                      style={{ float: 'right' }}
                    >
                      <Icon type='plus' />
                      新增设备
                    </Button>
                  </Row>
                </Form>
              </div>
              <div>
                <Table
                  rowKey={(_, index) => index}
                  dataSource={pageList}
                  columns={columns}
                  pagination={pagination}
                  onChange={this.pageSwitch}
                  loading={this.state.loading}
                  scroll={{ x: 1300 }}
                />
              </div>
              {/* 新增编辑设备Modal  showModalVisible显示隐藏； showModal显示Modal方法； handleCancel隐藏Modal方法 getListDevices设备列表方法  id设备id sysPrinterConfig,cxmBdStalls   */}
              {showModal ? (
                <AddDeviceModal
                  showModalVisible={showModal}
                  handleCancel={this.handleCancel}
                  resetSearch={this.resetSearch}
                  sysPrinterConfig={sysPrinterConfig}
                  cxmBdStalls={cxmBdStalls}
                  isEdit={isEdit}
                ></AddDeviceModal>
              ) : null}
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default NewDeviceManagement;
