/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-05 10:47:32
 * @LastEditors: lijing
 * @LastEditTime: 2022-07-18 19:28:26
 */
import request from '../../utils/request';

export function getList(params) {
  return request({ url: 'person/list', params });
}
export function getOperationList(params) {
  return request({
    url: `ope/hunt/listByPage`,
    params,
    method: 'get',
    headers: 'json',
  });
}
