import request from '../../utils/request';

// 获取货品类别
export async function getAllProducts(params) {
  return request({ url: 'products/getAllProducts', params });
}

// 获取商品基库列表
export async function getCommodityBaseList(params) {
  return request({ url: 'products/list', params });
}

// 商品基础库新增编辑
export async function updateCommodity(params) {
  return request({ url: 'products/update', params });
}

// 商品基础库删除
export async function deleteCommodity(params) {
  return request({ url: 'products/delete', params });
}

// 获取所有未关联的别名
export async function getUnrelatedAlias(params) {
  return request({ url: 'products/getUnrelatedAlias', params });
}

// 货品已关联别名查询
export async function getAllAlias(params) {
  return request({ url: 'products/getAllAlias', params });
}

// 关联别名新增
export async function insertAlias(params) {
  return request({ url: 'products/insertAlias', params });
}

// 已关联别名删除
export async function deleteAlias(params) {
  return request({ url: 'products/deleteAlias', params });
}

// 未关联货品-列表
export async function getUnrelatedList(params) {
  return request({ url: 'products/unrelatedList', params });
}

// 未关联货品显示隐藏
export async function updateUnrelatedEnable(params) {
  return request({ url: 'products/enableUnrelated', params });
}

// 未关联货品来源查看
export async function getUnrelatedSource(params) {
  return request({ url: 'products/unrelatedSource', params });
}

// 手动新增货品别名
export async function insertAliasName(params) {
  return request({ url: 'products/insertAliasName', params });
}
