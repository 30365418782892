// 计算配置--配置列表
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Card, Input, Button, Table, Divider, Modal, Popconfirm } from 'antd';

import './style.less';
import ReactJson from 'react-json-view';

import {
  fetchAllCalcTypeConfig,
  updateStallCalcTypeConfig,
  updateSaleType,
} from '../../state/action/count';

const FormItem = Form.Item;
const { TextArea } = Input;

@connect(
  (state) => {
    return { count: state.count };
  },
  { fetchAllCalcTypeConfig, updateStallCalcTypeConfig, updateSaleType },
)
@Form.create()
class ConfigList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updShow: false,
      updInfo: {},
      searchInfo: {},
      searchShow: false,
    };
  }

  componentDidMount() {
    const {
      count: { searchParams },
    } = this.props;
    this.fetchAllStallCalculationResult({ pageNo: 1, pageSize: 10, ...searchParams });
  }

  fetchAllStallCalculationResult(params) {
    const {
      count: { searchParams },
    } = this.props;
    this.props.fetchAllCalcTypeConfig({ ...searchParams, ...params });
  }

  updateConfig(params) {
    this.props.updateStallCalcTypeConfig(params).then(() => {
      this.fetchAllStallCalculationResult();
    });
  }

  updateSaleType(params) {
    this.props.updateSaleType(params).then(() => {
      this.fetchAllStallCalculationResult();
    });
  }

  showUpdateModal(record) {
    this.setState({
      updShow: true,
      updInfo: { id: record.id, calcTypeName: record.calcTypeName, remark: record.remark },
    });
  }

  // 分页查询
  pageChange = (e) => {
    const {
      count: { searchParams },
    } = this.props;
    this.fetchAllStallCalculationResult({
      ...searchParams,
      pageNo: e.current,
      pageSize: e.pageSize,
    });
  };

  // 模态框取消
  handleCancel = () => {
    this.setState({
      updShow: false,
      searchShow: false,
    });
  };

  showSearchModal(record) {
    this.setState({
      searchShow: true,
      searchInfo: { calcRule: JSON.parse(JSON.stringify(record)) },
    });
  }
  showConfigDisplay(id) {
    this.props.history.push({ pathname: `/count/configlist/configDisplay/${id}` });
  }

  // 修改规则名称/备注
  updateCalcTypeNameAndRemark = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['calcTypeName', 'remark'], (err, fieldsValue) => {
      if (err) {
        return;
      }
      let params = {
        id: this.state.updInfo.id,
        calcTypeName: fieldsValue.calcTypeName,
        remark: fieldsValue.remark,
      };
      this.updateConfig(params);
      this.handleCancel();
    });
  };

  // 修改规则名称/备注
  updateEnable = (id, enable) => {
    this.updateConfig({ id: id, enable: enable });
  };

  // 修改菜品售卖方式
  updateGoodsSaleType = (id, goodsSaleType) => {
    this.updateConfig({ id: id, goodsSaleType: goodsSaleType });
  };

  // 查询搜索
  searchSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['searchRemark', 'id'], (errors, values) => {
      if (!!errors) {
        return;
      }
      let keyWords = {
        pageSize: 10,
        pageNo: 1,
        remark: values.searchRemark,
        id: values.id,
      };
      // 调用接口
      this.fetchAllStallCalculationResult(keyWords);
    });
  };
  // 重置输入框
  searchReset = () => {
    this.props.form.resetFields();
    this.fetchAllStallCalculationResult({ pageNo: 1, pageSize: 10, remark: '', id: '' });
  };

  enableConfirm = (id, enable) => {
    this.updateEnable(id, !enable);
  };

  render() {
    const {
      count: { allConfigList, searchParams },
    } = this.props;
    const { lst, pageEntity } = allConfigList || {};
    const { pageSize, totalSize } = pageEntity || { pageSize: 10, totalSize: 0 };
    const { getFieldDecorator } = this.props.form;

    const pagination = {
      pageSize: pageSize,
      total: totalSize,
      current: searchParams.pageNo || 1,
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const extraContent = (
      <div className='extraContent'>
        <Form onSubmit={this.searchSubmit}>
          <Row>
            <Col span={1} style={{ paddingTop: '8px' }}>
              备注:
            </Col>

            <Col span={8}>
              <FormItem>
                {getFieldDecorator('searchRemark', {
                  initialValue: searchParams.remark,
                })(<Input style={{ width: '100%' }} placeholder='请输入备注' />)}
              </FormItem>
            </Col>
            <Col span={1} style={{ paddingTop: '8px' }}>
              ID:
            </Col>

            <Col span={8}>
              <FormItem>
                {getFieldDecorator('id', {
                  initialValue: searchParams.id,
                })(<Input style={{ width: '100%' }} placeholder='请输入ID' />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.searchReset}>重置</Button>
          </Row>
        </Form>
      </div>
    );

    const columns = [
      {
        title: '名称',
        dataIndex: 'calcTypeName',
      },
      {
        title: '配置备注',
        dataIndex: 'remark',
      },
      {
        title: '补货',
        dataIndex: 'replenishFlag',
        render: (_, record) => {
          const { saleType } = record;
          let enableStr = '禁用';
          if (saleType) {
            for (var i = 0; i < saleType.length; i++) {
              if (saleType[i] === 'replenishFlag') {
                enableStr = '启用';
                break;
              }
            }
          }
          return <span>{enableStr}</span>;
        },
      },
      {
        title: '包出门',
        dataIndex: 'isIncludeTrade',
        render: (_, record) => {
          const { saleType } = record;
          let enableStr = '禁用';
          if (saleType) {
            for (var i = 0; i < saleType.length; i++) {
              if (saleType[i] === 'isIncludeTrade') {
                enableStr = '启用';
                break;
              }
            }
          }
          return <span>{enableStr}</span>;
        },
      },
      {
        title: '货品售卖方式',
        dataIndex: 'goodsSaleType',
        render: (_, record) => {
          const { goodsSaleType } = record;
          let str = '按公斤';
          if (goodsSaleType) {
            if ('2' === goodsSaleType) {
              str = '按件';
            }
          }
          return <span>{str}</span>;
        },
      },
      {
        title: '状态',
        dataIndex: 'enable',
        render: (_, record) => {
          const { enable } = record;
          let enableStr = '启用';
          if (!enable) {
            enableStr = '禁用';
          }
          return <span>{enableStr}</span>;
        },
      },
      {
        title: '操作',
        dataIndex: 'oper',
        render: (_, record) => {
          const { enable, saleType, goodsSaleType } = record;
          let replenishFlag = false;
          let isIncludeTrade = false;
          if (saleType) {
            for (var i = 0; i < saleType.length; i++) {
              if (saleType[i] === 'replenishFlag') {
                replenishFlag = true;
              }
              if (saleType[i] === 'isIncludeTrade') {
                isIncludeTrade = true;
              }
            }
          }
          let goodsSaleTypeFlag = false; //按公斤卖为 false, 按件卖为true
          let gSaleType = 2;
          if (goodsSaleType) {
            if ('2' === goodsSaleType) {
              goodsSaleTypeFlag = true;
              gSaleType = 1;
            }
          }
          let enableStr = enable ? '禁用' : '启用';
          return (
            <div>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateGoodsSaleType(record.id, gSaleType);
                }}
              >
                {goodsSaleTypeFlag ? '按公斤售卖' : '按件售卖'}
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateSaleType({
                    id: record.id,
                    enable: !replenishFlag,
                    saleTypeStr: 'replenishFlag',
                  });
                }}
              >
                {replenishFlag ? '禁用补货' : '启用补货'}
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateSaleType({
                    id: record.id,
                    enable: !isIncludeTrade,
                    saleTypeStr: 'isIncludeTrade',
                  });
                }}
              >
                {isIncludeTrade ? '禁用包出门' : '启用包出门'}
              </a>
              <Divider type='vertical' />
              <Popconfirm
                placement='top'
                title={'是否确定' + enableStr + '当前计算逻辑?'}
                onConfirm={(e) => {
                  e.preventDefault();
                  this.enableConfirm(record.id, enable);
                }}
                okText='确定'
                cancelText='取消'
              >
                <a href='/'>{enableStr}</a>
              </Popconfirm>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showUpdateModal(record);
                }}
              >
                修改
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showSearchModal(record);
                }}
              >
                查看
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showConfigDisplay(record.id);
                }}
              >
                前台显示配置
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <div className='tabLayout'>
        <Card className='listCard' bordered={false} title='' extra={extraContent}>
          <Table
            dataSource={lst || []}
            columns={columns}
            pagination={pagination}
            onChange={this.pageChange.bind(this)}
            rowKey='id'
          />
        </Card>
        <Card className='modalCard'>
          <Modal
            title='修改'
            visible={this.state.updShow}
            onCancel={this.handleCancel}
            destroyOnClose='true'
            width='600px'
            className='modal'
            footer={[]}
          >
            <Form onSubmit={this.updateCalcTypeNameAndRemark} className='login-form'>
              <FormItem {...formItemLayout} label='配置名称'>
                {getFieldDecorator('calcTypeName', {
                  initialValue: this.state.updInfo.calcTypeName,
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label='配置描述'>
                {getFieldDecorator('remark', {
                  initialValue: this.state.updInfo.remark,
                })(<TextArea rows={4} />)}
              </FormItem>
              <FormItem wrapperCol={{ span: 24, offset: 11 }}>
                <Button
                  style={{ float: 'center', marginRight: '5px', marginTop: '5px' }}
                  type='primary'
                  htmlType='submit'
                >
                  保存
                </Button>
              </FormItem>
            </Form>
          </Modal>
        </Card>
        <Card className='modalCard'>
          <Modal
            title='查看'
            visible={this.state.searchShow}
            onCancel={this.handleCancel}
            destroyOnClose='true'
            width='600px'
            className='modal'
            footer={[]}
          >
            <ReactJson src={this.state.searchInfo.calcRule ? this.state.searchInfo.calcRule : ''} />
          </Modal>
        </Card>
      </div>
    );
  }
}
export default ConfigList;
