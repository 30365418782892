import React, { PureComponent } from 'react';
import { Breadcrumb } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { menuData } from '../../common/js/menu';

@withRouter
class Index extends PureComponent {
  componentDidMount() {}

  getName(item, index) {
    if (index === 0) {
      const obj = menuData.filter((v) => v.path === item);

      if (obj && obj.length > 0 && obj[0].name) {
        this.data = obj[0]; //2级目录或3,4,5，...级目录
        return obj[0].name;
      } else {
        return '';
      }
    } else {
      if (this.data && this.data.children && this.data.children.length > 0) {
        const obj = this.data.children.filter((v) => v.path === item);
        if (obj && obj.length > 0 && obj[0].name) {
          this.data = obj[0]; //2级目录或3,4,5，...级目录
          return obj[0].name;
        } else {
          return '';
        }
      }
    }
  }

  render() {
    const { pathname } = this.props.location;
    const arr = (pathname && pathname.substring(1).split('/')) || [];

    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/'>首页</Link>
        </Breadcrumb.Item>
        {arr.map((v, i) => (
          <Breadcrumb.Item key={i}>{this.getName(v, i)}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
}

export default Index;
