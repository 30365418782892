import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import crypto from 'crypto';
import PageHeaderLayout from '@/component/page-header-layout';
import { getSystemDataExportQuery, getSystemDataExport } from '@/state/request/serve';
import { exportFile } from '@/utils/utils';
import './index.less';

const DataExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [pwd, setPwd] = useState('');
  let timer = null;

  useEffect(() => {
    return () => {
      timer && clearInterval(timer);
    }
  })

  const getEncryptPwd = (data) => {
    const key = 'RuMhbTayLJuSzS2n' // 10 位秘钥密钥
    const iv = 'p4XJ6v6c8XFf3mb6' // 初始化向量（iv）
    const cipher = crypto.createCipheriv('aes-128-cbc', key, iv)
    let crypted = cipher.update(data, 'utf8', 'base64')
    crypted += cipher.final('base64')
    return crypted
  }

  const handleExport = async () => {
    if (!phone || !pwd) {
      message.info('请填写用户名和密码');
    }
    setIsLoading(true);
    const { code, data, msg } = await getSystemDataExportQuery({
      userName: phone,
      password: getEncryptPwd(pwd)
    });
    if (code === 200 && data) {
      // 轮询接口获取导出文件
      timer = setInterval(async () => {
        const res = await getSystemDataExport(data);
        if (res && res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && res.size) {
          clearInterval(timer);
          exportFile(res, "商户数据.xlsx");
          setIsLoading(false);
        }
      }, 1000 * 5)
    } else {
      setIsLoading(false)
      code !== 200 && message.info(msg || '请求失败，请重试！');
    }
  }

  return (
    <PageHeaderLayout>
      <div className='system-data-export'>
        <div className='input-item'>
          <div>用户名</div>
          <Input
            allowClear
            placeholder='请输入用户名'
            style={{ width: '220px' }}
            value={phone}
            onChange={e => setPhone(e.target.value || '')}
          />
        </div>
        <div className='input-item'>
          <div>密码</div>
          <Input.Password
            allowClear
            placeholder='请输入密码'
            style={{ width: '220px' }}
            value={pwd}
            onChange={e => setPwd(e.target.value || '')}
          />
        </div>
        <div className='export-btn-wrap'>
          <Button
            type='primary'
            className='export-btn'
            loading={isLoading}
            onClick={() => handleExport()}
          >
            {isLoading ? '导出中...' : '数据导出'}
          </Button>
          <div className='tips'>（数据导出操作比较耗时，请耐心等待）</div>
        </div>
      </div>
    </PageHeaderLayout>
  );
};

export default DataExport;