import * as actionTypes from '../constant/count';
import * as req from '../request/count';

export function fetchAllStallConfig(params) {
  return async (dispatch) => {
    const res = await req.getAllStallConfig(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { allStallConfigData: res.data } });
    }
  };
}
export function fetchAllStallCalculationResult(params) {
  return async (dispatch) => {
    const res = await req.getAllStallCalculationResult(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { allResultData: res.data } });
    }
  };
}
export function fetchSave(params) {
  return async (dispatch) => {
    const res = await req.saveStallCalcTypeConfig(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { saveData: res } });
    }
  };
}
export function fetchAllCalcTypeConfig(params) {
  return async (dispatch) => {
    const res = await req.getAllCalcTypeConfig(params);
    if (res) {
      dispatch({
        type: actionTypes.COUNT_SAVE,
        payload: { allConfigList: res.data, searchParams: params },
      });
    }
  };
}

export function fetchAssociatedStall(params) {
  return async (dispatch) => {
    const res = await req.getAssociatedStall(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { associatedStall: res } });
    }
  };
}
export function updateStallCalcTypeConfig(params) {
  return async (dispatch) => {
    const res = await req.updateStallCalcTypeConfig(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallCalcTypeConfigData: res } });
    }
  };
}
export function saveStallItem(params) {
  return async (dispatch) => {
    const res = await req.saveStallItem(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallItem: res } });
    }
  };
}
export function getAllStallItem(params) {
  return async (dispatch) => {
    const res = await req.getAllStallItem(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallItem: res } });
    }
  };
}

export function updateStallItem(params) {
  return async (dispatch) => {
    const res = await req.updateStallItem(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallItem: res } });
    }
  };
}
export function getAllGroupStallItem(params) {
  return async (dispatch) => {
    const res = await req.getAllGroupStallItem(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallItem: res } });
    }
  };
}
export function saveGroupStallItem(params) {
  return async (dispatch) => {
    const res = await req.saveGroupStallItem(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallItem: res } });
    }
  };
}
export function updateGroupStallItem(params) {
  return async (dispatch) => {
    const res = await req.updateGroupStallItem(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallItem: res } });
    }
  };
}
export function updateSaleType(params) {
  return async (dispatch) => {
    const res = await req.updateSaleType(params);
    if (res) {
      dispatch({ type: actionTypes.COUNT_SAVE, payload: { stallCalcTypeConfigData: res } });
    }
  };
}
