import * as actionTypes from '../constant/basic';

//初始状态
const initState = {
  allConfigList: [],
  updateMsg: [],
  configList: [],
  allCalculationResult: [],
  updateOrSaveMsgAboutCalculationResult: [],
  getConfigGroup: {},
  configGroupUpdateOrSaveMsg: [],
  getAllDistrict: [],
  marketList: {},
  stallList: {},
  cashierList: {},
  getConfigOfStalledList: [],
  stallCalcTypeConfigList: [],
  addStallConfigGroupMsg: [],
  removeStallConfigGroupMsg: [],
  configCommonList: [],
  addOrUpdateConfigCommonMsg: [],
  configCommonDetail: {},
  updateConfigItems: [],
  getConfigStall: [],
  addConfigStall: [],
  deleteConfigStall: [],
  getStallRelationConfigCommon: [],
  allStallConfigData: [],
};

//reducer
export function basic(state = initState, action) {
  switch (action.type) {
    case actionTypes.BASIC_SAVE:
      return { ...state, ...action.payload };
    case actionTypes.RESET_FIELDS:
      return { ...state, [action.payload]: {} };
    default:
      return state;
  }
}
