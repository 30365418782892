//货品维护
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Button,
  Modal,
  Row,
  Col,
  message,
  Select,
  Input,
  Table,
  Divider,
  Upload,
  Icon,
} from 'antd';

import '../index.less';
import { API_ADMIN } from '../../../utils/urls';
import { generalParamsWithPToStr } from '../../../utils/math';
import {
  getProductList,
  addProduct,
  updateProduct,
  deleteProduct,
  getCategoriesList,
} from '../../../state/action/onlineStore';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

@connect(
  (state) => {
    return { onlineStore: state.onlineStore };
  },
  { getProductList, addProduct, updateProduct, deleteProduct, getCategoriesList },
)
@Form.create()
class GoodsManage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addVisible: false,
      title: '',
      item: {},
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
  }

  componentDidMount() {
    const { record } = this.props;
    this.getProductList({ stallId: record.stallId, categoryId: '' });
  }

  // 获取货品分类
  async getProductList(value) {
    await this.props.getProductList(value);
  }

  addGoodsModal = (visible, title, item, flag) => {
    let fileArr = [];
    if (flag === 1 || flag === 2) {
      const { record } = this.props;
      this.getCategoriesList({ stallId: record.stallId });
      if (flag === 2) {
        //编辑回显
        fileArr.push({
          uid: item.imgUid,
          name: '',
          status: 'done',
          url: item.imageList && item.imageList[0],
        });
      }
    }
    this.setState({
      addVisible: visible,
      title,
      item,
      fileList: fileArr,
    });
  };

  // 获取货品分类
  async getCategoriesList(value) {
    await this.props.getCategoriesList(value);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { record } = this.props;
        const { item, fileList } = this.state;

        const reqObject = {
          stallId: record.stallId,
          productName: values.productName,
          categoryId: values.categoryId,
          saleWeight: values.saleWeight,
          slogan: values.slogan,
          imageUrl: fileList[0].url,
        };
        if (JSON.stringify(item) === '{}') {
          this.addProductFun(reqObject);
        } else {
          this.updateProductFun({ ...reqObject, id: item.id });
        }
      }
    });
  };
  async addProductFun(params) {
    const result = await this.props.addProduct(params);
    if (result.code === 200) {
      message.success('新增成功');
      const { record } = this.props;
      this.addGoodsModal(false);
      this.getProductList({ stallId: record.stallId });
    }
  }
  async updateProductFun(params) {
    const result = await this.props.updateProduct(params);
    if (result.code === 200) {
      message.success('编辑成功');
      const { record } = this.props;
      this.addGoodsModal(false);
      this.getProductList({ stallId: record.stallId });
    }
  }
  async deleteProduct(params) {
    const result = await this.props.deleteProduct({ id: params.id });
    if (result.code === 200) {
      message.success('删除成功');
      const { record } = this.props;
      this.addGoodsModal(false);
      this.getProductList({ stallId: record.stallId });
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file, fileList }) => {
    const arr = file.name.split('.');
    const limitSize = file.size / 1024 / 1024 < 2;
    if (fileList && fileList.length > 0) {
      if (
        arr[arr.length - 1] === 'jpeg' ||
        arr[arr.length - 1] === 'png' ||
        arr[arr.length - 1] === 'jpg'
      ) {
        if (file.size && !limitSize) {
          message.error('图片大小不能超过2M');
          return;
        }
        if (file.name.length > 32) {
          message.error('图片名称长度不能超过32位');
          return;
        }
        fileList.forEach((imgItem) => {
          if (imgItem && imgItem.status === 'uploading') {
            imgItem.thumbUrl = '';
          } else if (
            imgItem &&
            imgItem.status === 'done' &&
            imgItem.response &&
            imgItem.response.data
          ) {
            imgItem.thumbUrl = '';
            imgItem.url = imgItem.response.data;
          } else if (
            imgItem &&
            imgItem.status === 'error' &&
            imgItem.response &&
            imgItem.response.code !== 200
          ) {
            message.error(imgItem.response.msg);
            return;
          }
        });
        this.setState({ fileList });
      } else {
        message.error('仅支持上传PNG/JPG/JPEG格式文件');
      }
    }
  };

  handleRemove = (file) => {
    const { fileList } = this.state;
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    this.setState({
      fileList: newFileList,
    });
  };

  render() {
    const {
      visible,
      content,
      handleClose,
      onlineStore: { productList, categoriesList },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { addVisible, title, item, fileList, previewVisible, previewImage } = this.state;

    const params = { fileList, fileType: 2 };
    const props = {
      action: `${API_ADMIN}file/uploadFile?${generalParamsWithPToStr('file/uploadFile', params)}`,
      listType: 'picture-card',
      fileList: [...fileList],
      showUploadList: { showDownloadIcon: false },
      accept: '.png,.jpg,.jpeg',
      onPreview: this.handlePreview,
      onChange: this.handleChange,
      onRemove: this.handleRemove,
    };

    const data = productList && productList.length > 0 ? productList : [];

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        render: (_, __, index) => {
          return ++index;
        },
      },
      {
        title: '货品名称',
        dataIndex: 'productName',
        key: 'productName',
        width: 120,
      },
      {
        title: '广告语',
        dataIndex: 'slogan',
        key: 'slogan',
        width: 200,
      },
      {
        title: '分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width: 120,
      },
      {
        title: '点赞数',
        dataIndex: 'likeNum',
        key: 'likeNum',
        width: 120,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 180,
        render: (_, record) => {
          return (
            <div>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.addGoodsModal(true, '编辑货品', record, 2);
                }}
              >
                编辑
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.addGoodsModal(true, '删除', record);
                }}
              >
                删除
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Modal
          title={content}
          visible={visible}
          onCancel={() => handleClose()}
          className='modalStyle'
          width='900px'
          footer={null}
        >
          <Button type='primary' onClick={() => this.addGoodsModal(true, '新增货品', {}, 1)}>
            新增货品
          </Button>
          <Table
            rowKey={(_, index) => index}
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={this.state.loading}
          />
          {addVisible && (
            <Modal
              title={title}
              visible={addVisible}
              onCancel={() => this.addGoodsModal(false)}
              className='modalStyle'
              width='700px'
              footer={null}
            >
              {title === '删除' ? (
                <div style={{ textAlign: 'center' }}>
                  <br />
                  <div>确认删除【{item.productName}】商品吗？</div>
                  <br />
                  <div>
                    <Button onClick={() => this.addGoodsModal(false)} style={{ marginRight: 12 }}>
                      取消
                    </Button>
                    <Button type='primary' onClick={() => this.deleteProduct(item)}>
                      确定
                    </Button>
                  </div>
                </div>
              ) : (
                <Form layout='inline' onSubmit={this.handleSubmit}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <FormItem label='货品名称' {...formItemLayout}>
                        {getFieldDecorator('productName', {
                          initialValue: item.productName || null,
                          rules: [
                            {
                              required: true,
                              validator: (_, value, callback) => {
                                if (value) {
                                  if (value.length > 6) {
                                    callback('货品名称不超过6个字符');
                                  }
                                } else {
                                  callback('请输入货品名称');
                                }
                                callback();
                              },
                            },
                          ],
                        })(<Input placeholder='请输入货品名称' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem label='广告语' {...formItemLayout}>
                        {getFieldDecorator('slogan', {
                          initialValue: item.slogan || null,
                          rules: [
                            {
                              required: true,
                              validator: (_, value, callback) => {
                                if (value) {
                                  if (value.length > 15) {
                                    callback('广告语不超过15个字符');
                                  }
                                } else {
                                  callback('请输入广告语');
                                }
                                callback();
                              },
                            },
                          ],
                        })(<Input placeholder='请输入广告语' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem label='成交量' {...formItemLayout}>
                        {getFieldDecorator('saleWeight', {
                          initialValue: item.saleWeight || null,
                          rules: [
                            {
                              required: true,
                              validator: (rule, value, callback) => {
                                if (value) {
                                  if (value.length > 15) {
                                    callback('成交量不超过15个字符');
                                  }
                                } else {
                                  callback('请输入成交量');
                                }
                                callback();
                              },
                            },
                          ],
                        })(<Input placeholder='请输入成交量' allowClear />)}
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem label='选择分类' {...formItemLayout}>
                        {getFieldDecorator('categoryId', {
                          initialValue: item.categoryId || null,
                          rules: [{ required: true, message: '请选择分类' }],
                        })(
                          <Select
                            placeholder='请选择分类'
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {categoriesList.map((item) => (
                              <Option value={item.id} key={item.id}>
                                {item.categoryName}
                              </Option>
                            ))}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                    <Col span={24}>
                      <FormItem {...formItemLayout} label='货品图片'>
                        {getFieldDecorator('imageUrl', {
                          initialValue: item.imageList || null,
                          rules: [{ required: true, message: '请上传货品图片' }],
                        })(
                          <div>
                            <Upload {...props}>
                              {fileList.length >= 1 ? null : (
                                <div>
                                  <Icon type='plus' />
                                  <div className='ant-upload-text'>上传图片</div>
                                </div>
                              )}
                            </Upload>
                            <Modal
                              visible={previewVisible}
                              footer={null}
                              onCancel={this.handleCancel}
                            >
                              <img alt='example' style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                          </div>,
                        )}
                        仅支持上传PNG/JPG/JPEG格式图片
                      </FormItem>
                    </Col>
                  </Row>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    <Button onClick={() => this.addGoodsModal(false)} style={{ marginRight: 12 }}>
                      关闭
                    </Button>
                    <Button type='primary' htmlType='submit'>
                      确定
                    </Button>
                  </div>
                </Form>
              )}
            </Modal>
          )}
        </Modal>
      </div>
    );
  }
}

export default GoodsManage;
