import * as actionTypes from '../constant/hotUpdate';

//初始状态
const initState = {
  list: {},
  search: {},
  enable: {},
  detail: {},
  versionList: {},
  add: {},
};

//reducer
export function hotUpdate(state = initState, action) {
  switch (action.type) {
    case actionTypes.HOT_UPDATE_GET_LIST:
      return { ...state, list: action.payload, search: action.search };
    case actionTypes.HOT_UPDATE_ENABLE:
      return { ...state, enable: action.payload };
    case actionTypes.HOT_UPDATE_GET_DETAIL_BY_ID:
      return { ...state, detail: action.payload };
    case actionTypes.HOT_UPDATE_GET_VERSION_LIST:
      return { ...state, versionList: action.payload };
    case actionTypes.HOT_UPDATE_ADD:
      return { ...state, add: action.payload };
    default:
      return state;
  }
}
