import * as actionTypes from '../constant/salesman';

//数据统计--销售人员管理
//初始状态
const initState = {
  querySaleList: [], //销售人员列表
  addSale: {}, //新增销售人员
  updateSale: {}, //修改销售人员
  updateSaleEnable: {}, //启用/禁用
  getSaleById: {}, //根据ID获取销售人员
  search: {}, //查询条件
  getAllSale: [], //获取所有销售信息
};

export function salesman(state = initState, action) {
  switch (action.type) {
    case actionTypes.SALESMAN_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
