/**
 * 合单配置分配档口
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Row, Col, Button, Select, Table, Modal, message, Popconfirm } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';

import {
  fetchStallListByRuleId,
  fetchRemoveStallSet,
  fetchAssociatedStall,
} from '../../state/action/mergeSet';
import {
  getStallGroupById,
  fetchAllStallsConfig,
  fetchAllStallsConfig2,
  marketList,
} from '../../state/action/stall';

import './style.less';
import { isEmptyObject } from '../../utils/reg';

const FormItem = Form.Item;
const Option = Select.Option;
const { Column } = Table;

@connect(
  (state) => {
    return { mergeSet: state.mergeSet, stall: state.stall };
  },
  {
    fetchStallListByRuleId,
    fetchRemoveStallSet,
    fetchAssociatedStall,
    getStallGroupById,
    fetchAllStallsConfig,
    fetchAllStallsConfig2,
    marketList,
  },
)
@Form.create()
class MergeSetGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      visibleModal: false,
      value: 1,
      id: '',
      type: '',
    };
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const ruleId = this.props.location.params ? this.props.location.params.id : 0;
    this.fetchAllGroup({
      pageNo: 1,
      pageSize: 10,
      ruleId: ruleId,
    });
    this.fetchAllStallsGroupInfos({ flag: 1 });
    this.fetchAllMarkets({});
  }

  async fetchAllGroup(params) {
    await this.props.fetchStallListByRuleId(params);
  }

  // 获取档口修改数据
  async fetchConfig(params) {
    await this.props.getStallGroupById(params);
  }

  // 获取档口列表
  async fetchAllStallsGroupInfos(params) {
    await this.props.fetchAllStallsConfig(params);
  }

  // 获取档口列表
  async fetchAllStallsGroupInfos2(params) {
    await this.props.fetchAllStallsConfig2(params);
  }

  // 获取档口列表
  async fetchAllMarkets(params) {
    await this.props.marketList(params);
  }

  // 修改档口配置
  async saveOrUpdate(params) {
    await this.props.fetchAssociatedStall(params).then(() => this.submit());
  }

  // 删除档口配置
  async stallDelete(params) {
    await this.props.fetchRemoveStallSet(params).then(() => this.delete());
  }

  // 分页和查询请求
  handleOnQuery = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const ruleId = this.props.location.params ? this.props.location.params.id : 0;
      const stallId = fieldsValue.searchStallId;
      this.fetchAllGroup({
        id: this.state.id,
        ruleId: ruleId,
        stallId: stallId,
        pageNo: 1,
        pageSize: 10,
      });
    });
  };

  // 商行选择onchange事件
  onChange = (value) => {
    this.setState({
      selectValue: value,
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const ruleId = this.props.location.params ? this.props.location.params.id : 0;
      const stallId = fieldsValue.stallId;
      this.fetchAllGroup({
        ruleId: ruleId,
        stallId: stallId,
        pageNo: obj.current,
        pageSize: obj.pageSize,
      });
    });
  };

  // modal关闭事件
  handleCancel(e) {
    this.setState({
      visibleModal: false,
    });
  }

  // 修改数据
  modify(id) {
    this.fetchConfig({
      id: id,
    });
    this.setState({
      visibleModal: true,
      type: 'modify',
    });
  }

  // 删除档口数据
  deleteMessage(id, stallId) {
    this.stallDelete({
      id: id,
      stallId: stallId,
    });
  }

  // 启用/禁用档口分组
  updGroup(id, enable) {
    this.saveOrUpdate({
      id: id,
      enable: enable,
    });
  }

  // 修改信息的回调
  submit() {
    const {
      mergeSet: { stallSet },
    } = this.props;
    if (stallSet && stallSet.code === 200) {
      message.success('操作成功');
    } else if (stallSet && stallSet.msg) {
      message.error(stallSet.msg);
    } else {
      message.error('操作失败');
    }
    this.handleOnQuery();
  }

  // 删除档口信息回调
  delete() {
    const {
      mergeSet: { stallSet },
    } = this.props;
    if (stallSet.code === 200) {
      message.success('删除成功');
    } else {
      message.error('删除失败');
    }
    this.handleOnQuery();
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      if (err) {
        return;
      }
      const { stallId, marketId } = fieldsValue;
      const ruleId = this.props.location.params ? this.props.location.params.id : 0;
      this.saveOrUpdate({
        id: this.state.type === 'add' ? null : this.state.id,
        ruleId: ruleId,
        stallId: stallId,
        marketId: marketId,
      });

      this.setState({
        visibleModal: false,
      });
    });
  };

  // 新增档口配置
  add() {
    this.setState({
      visibleModal: true,
      type: 'add',
      id: null,
    });
  }

  // 重置
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      selectValue: '',
    });
    const ruleId = this.props.location.params ? this.props.location.params.id : 0;
    this.fetchAllGroup({
      pageNo: 1,
      pageSize: 10,
      ruleId: ruleId,
    });
  };

  handleChange(value) {
    if (0 === value) {
      value = null;
    }
    this.fetchAllStallsGroupInfos2({ flag: 1, marketId: value });
  }

  goBack() {
    this.props.history.push('/count/mergeSet');
  }
  render() {
    let {
      stall: { groupInfo, allStallsConfigData, marketData, allStallsData },
      mergeSet: { stallList },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    if (stallList === undefined) {
      stallList = [];
    }
    let allStallGroup = [];
    if (stallList !== undefined && stallList !== null) {
      allStallGroup = stallList.lst;
    }
    const { pageEntity } = stallList;
    let total = 0;
    if (pageEntity) {
      total = pageEntity.totalSize;
    }

    let data = {};

    if (groupInfo !== undefined && groupInfo !== null && this.state.type !== 'add') {
      data = groupInfo;
      this.setState({
        id: data.id,
      });
    }
    let { groupNo, type, stallId, marketId } = data;
    if (groupNo === undefined || groupNo === null) {
      groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    }
    if (type === undefined || type === null) {
      type = '';
    }
    if (stallId === undefined || stallId === null) {
      stallId = '';
    }
    if (marketId === undefined || marketId === null) {
      marketId = '';
    }

    if (
      allStallsConfigData !== null &&
      allStallsConfigData !== undefined &&
      allStallsConfigData.length
    ) {
      let tmpAllStallsConfigData = [];
      const tmp = { id: 0, name: '所有档口' };
      tmpAllStallsConfigData.push(tmp);
      for (let i = 0; i < allStallsConfigData.length; i++) {
        tmpAllStallsConfigData.push(allStallsConfigData[i]);
      }
      allStallsConfigData = tmpAllStallsConfigData;
    }

    if (marketData !== null && marketData !== undefined && marketData.length) {
      let tmpMarketData = [];
      const tmp = { id: 0, name: '所有市场' };
      tmpMarketData.push(tmp);
      for (let i = 0; i < marketData.length; i++) {
        tmpMarketData.push(marketData[i]);
      }
      marketData = tmpMarketData;
    } else {
      marketData = [];
    }

    if (allStallsData !== null && allStallsData !== undefined && allStallsData.length) {
      let tmpallStallsData = [];
      const tmp = { id: 0, name: '所有档口' };
      tmpallStallsData.push(tmp);
      for (let i = 0; i < allStallsData.length; i++) {
        tmpallStallsData.push(allStallsData[i]);
      }
      allStallsData = tmpallStallsData;
    } else {
      allStallsData = [];
      const tmp = { id: 0, name: '所有档口' };
      allStallsData.push(tmp);
    }

    const layout = { xs: 24, sm: 18, md: 18, lg: 18, xl: 10 };
    const layout1 = { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 };

    const extraContent = (
      <div>
        <Form onSubmit={this.handleOnQuery} layout='inline'>
          <Row gutter={24}>
            <Col {...layout}>
              <FormItem>
                {getFieldDecorator('searchStallId', {
                  rules: [{ required: false, message: '请选择档口!' }],
                })(
                  <Select
                    placeholder='请选择档口'
                    style={{ width: '100%' }}
                    onChange={this.onChange.bind(this)}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allStallsConfigData.map((item) => (
                      <Option key={item.id}>{item.name}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>
          <br />
          <Row gutter={24}>
            <Col {...layout}>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button style={{ marginLeft: '12px' }} onClick={this.handleReset}>
                重置
              </Button>
              <Button style={{ marginLeft: '12px' }} onClick={this.add.bind(this)} type='primary'>
                新增
              </Button>
              <Button
                type='primary'
                style={{ marginLeft: '12px' }}
                onClick={this.goBack.bind(this)}
              >
                返回合单配置
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );

    const pagination = {
      pageSize: 10,
      total: total,
    };

    return (
      <div className='count-mergeSet-index'>
        <PageHeaderLayout>
          <div className='count-allocation-group'>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                dataSource={allStallGroup}
                pagination={pagination}
                rowKey={(record, index) => index}
                bordered
                onChange={this.pageSwitch.bind(this)}
              >
                <Column title='档口号' dataIndex='stallId' key='stallId' />
                <Column title='档口名称' dataIndex='stallName' key='stallName' />
                <Column title='市场名称' dataIndex='marketName' key='marketName' />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    const { id, stallId } = record;
                    const title = `确定需要删除`;
                    return (
                      <div>
                        <Popconfirm
                          title={title}
                          onConfirm={this.deleteMessage.bind(this, id, stallId)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <span style={{ color: '#79C41A' }}>删除</span>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Card>
              {this.state.visibleModal && (
                <Modal
                  title={this.state.type === 'add' ? '新增档口分组' : '修改档口分组'}
                  visible={this.state.visibleModal}
                  footer={null}
                  onCancel={this.handleCancel}
                  destroyOnClose='true'
                  width='600px'
                  className='mergeSetModal'
                >
                  <div className='extraContent'>
                    <Form onSubmit={this.handleSubmit} layout='inline'>
                      <Row gutter={24}>
                        <Col {...layout1}>
                          <FormItem label='市场'>
                            {getFieldDecorator('marketId', {
                              initialValue: marketId + '',
                              rules: [
                                {
                                  required: true,
                                  message: ' 请选择市场!',
                                },
                              ],
                            })(
                              <Select
                                placeholder='请选择市场'
                                showSearch
                                allowClear
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={this.handleChange.bind(this)}
                              >
                                {marketData.map((item) => (
                                  <Option key={item.id}>{item.name}</Option>
                                ))}
                              </Select>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col {...layout1}>
                          <FormItem label='档口'>
                            {getFieldDecorator('stallId', {
                              initialValue: stallId + '',
                              rules: [
                                {
                                  required: true,
                                  message: ' 请选择档口!',
                                },
                              ],
                            })(
                              <Select
                                placeholder='请选择档口'
                                showSearch
                                allowClear
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {allStallsData.map((item) => (
                                  <Option key={item.id}>{item.name}</Option>
                                ))}
                              </Select>,
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>
                          保存
                        </Button>
                      </Row>
                    </Form>
                  </div>
                </Modal>
              )}
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default MergeSetGroup;
