// 商行详情-附加费用配置
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Input, message, Table, Modal, Row, Col, Radio } from 'antd';
import {
  fetchAddtionFeeList,
  updateAddtionFeeListItem,
  updateAddtionFeeEnable,
} from '../../../../state/action/stall';

import './device.less';

const FormItem = Form.Item;

const layout = { xs: 24, sm: 12, md: 24, lg: 24, xl: 24 };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { fetchAddtionFeeList, updateAddtionFeeListItem, updateAddtionFeeEnable },
)
@Form.create()
class AddtionFeeConfig extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      record: {},
      content: '',
    };
  }

  componentDidMount() {
    this.getAddtionFeeList({ stallId: this.props.stallId });
  }

  async getAddtionFeeList(params) {
    this.props.fetchAddtionFeeList(params);
  }

  // 点击修改的显示modal
  showUpdateModal = (record, visible, content) => {
    this.setState({
      record,
      visible,
      content,
    });
  };

  // 修改附加费用
  updateAddtionFee = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        let params = {
          stallId: this.props.stallId,
          key: value.key,
          showName: value.showName,
          attribute: value.attribute,
        };
        this.updateAddtionFeeAsync(params);
      }
    });
  };

  // 更新附加费
  async updateAddtionFeeAsync(params) {
    const result = await this.props.updateAddtionFeeListItem(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('修改成功');
      this.getAddtionFeeList({ stallId: this.props.stallId });
    } else {
      // message.error(result.msg);
    }
  }

  // 修改附加费用的显示与隐藏的状态
  updateAddtionFeeStatus = (record) => {
    const { key = '', enable = false } = record;
    const q = key === 'chargeOrderFree' ? { attribute: '0' } : {};
    let params = {
      key,
      enable: !enable,
      stallId: this.props.stallId,
      ...q,
    };
    this.updateAddtionFeeEnable(params);
  };

  async updateAddtionFeeEnable(params) {
    const result = await this.props.updateAddtionFeeEnable(params);
    if (result.code === 200) {
      this.getAddtionFeeList({ stallId: this.props.stallId });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { record, visible, content, loading } = this.state;
    const {
      stall: { addtionFeeList },
    } = this.props;
    const dataSource = addtionFeeList || [];
    const columns = [
      {
        title: 'KEY',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: '系统费用名称',
        dataIndex: 'defaultName',
        key: 'defaultName',
      },
      {
        title: '商行显示名称',
        dataIndex: 'showName',
        key: 'showName',
      },
      {
        title: '状态',
        dataIndex: 'enable',
        key: 'enable',
        render: (_, record) => {
          const { enable = false } = record;
          let classStyle = enable ? 'ball green' : 'ball red';
          return (
            <div className='tableItem'>
              <span className={classStyle}></span>
              <span>{enable ? '显示' : '隐藏'}</span>
            </div>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        render: (_, record) => {
          const { enable = false } = record;
          let classStyle = enable ? 'enableTrueText' : 'enableFalseText';
          return (
            <div className='tableItem'>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showUpdateModal(record, true, '修改显示名称');
                }}
                className='optionText'
              >
                修改显示名称
              </a>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateAddtionFeeStatus(record);
                }}
                className={classStyle}
              >
                {!enable ? '显示' : '隐藏'}
              </a>
            </div>
          );
        },
      },
    ];
    return (
      <div className='addtionFeeConfig'>
        <div className='table'>
          <Table
            rowKey={(_, index) => index}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </div>
        {visible && (
          <Modal
            title={content}
            visible={visible}
            onCancel={() => this.showUpdateModal({}, false, '')}
            footer={null}
            className={'deviceModalStyle basketSettingsModal'}
            width='750px'
            style={{ maxHeight: 700 }}
          >
            <Form layout='inline' onSubmit={this.updateAddtionFee}>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='KEY'>
                    {getFieldDecorator('key', {
                      initialValue: record.key || '',
                      rules: [{ required: true }],
                    })(<Input placeholder='' disabled={true} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='系统费用名称'>
                    {getFieldDecorator('defaultName', {
                      initialValue: record.defaultName || '',
                      rules: [{ required: true }],
                    })(<Input placeholder='' disabled={true} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='商行显示名称'>
                    {getFieldDecorator('showName', {
                      initialValue: record.showName || '',
                      rules: [
                        { required: true, message: '请输入商行显示名称' },
                        {
                          pattern: /^[\u4e00-\u9fa5]+$/i,
                          message: '只能输入中文!',
                        },
                      ],
                    })(<Input placeholder='请输入商行显示名称' maxLength={4} />)}
                  </FormItem>
                </Col>
              </Row>
              {record.key === 'chargeOrderFree' && (
                <Row gutter={24}>
                  <Col {...layout}>
                    <FormItem {...formItemLayout} label='费用规则'>
                      {getFieldDecorator('attribute', {
                        initialValue: record.attribute || '0',
                        rules: [{ required: true, message: '请选择费用规则' }],
                      })(
                        <Radio.Group>
                          <Radio value='0'>买家出</Radio>
                          <Radio value='1'>卖家出</Radio>
                        </Radio.Group>,
                      )}
                    </FormItem>
                  </Col>
                </Row>
              )}
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button type='primary' htmlType='submit' style={{ marginRight: '30px' }}>
                  保存
                </Button>
                <Button onClick={() => this.showUpdateModal({}, false, '')}>关闭</Button>
              </Row>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

export default AddtionFeeConfig;
