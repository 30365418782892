/**
 * 档口分组配置
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Input,
  Tooltip,
  Table,
  Modal,
  message,
  Popconfirm,
  Divider,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  fetchAllDistinctGroup,
  getGroupById,
  fetchAllStallsConfig,
  saveOrUpdateGroup,
  stallDelete,
} from '../../state/action/stall';
import '../booth-management/index.less';
import { isEmptyObject } from '../../utils/reg';

const FormItem = Form.Item;
const { Column } = Table;

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { fetchAllDistinctGroup, getGroupById, fetchAllStallsConfig, saveOrUpdateGroup, stallDelete },
)
@Form.create()
class BoothAllGroup extends PureComponent {
  componentDidMount() {
    this.fetchAllGroup({
      pageNo: 1,
      pageSize: 10,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      visibleModal: false,
      value: 1,
      id: '',
      type: '',
      groupName: '',
      pageNo: 1,
      pageSize: 10,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.versionId = !isEmptyObject(this.props.match) ? this.props.match.params.versionId : 0;
  }

  async fetchAllGroup(params) {
    await this.props.fetchAllDistinctGroup(params);
  }

  // 获取档口修改数据
  async fetchConfig(params) {
    await this.props.getGroupById(params);
  }

  // 修改档口配置
  async saveOrUpdate(params) {
    await this.props.saveOrUpdateGroup(params);
    this.submit();
  }

  // 删除档口配置
  async stallDelete(params) {
    await this.props.stallDelete(params);
    this.delete();
  }

  // 分页和查询请求
  handleOnQuery = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.form.validateFields(['searchGroupName'], (err, fieldsValue) => {
      if (err) return;
      const groupName = fieldsValue.searchGroupName;
      const params = {
        groupName: groupName,
        pageNo: this.state.pageNo || 1,
        pageSize: 10,
      };
      this.fetchAllGroup({
        id: this.state.id,
        ...params,
      });
      this.setState(params);
    });
  };

  // 商行选择onchange事件
  onChange = (value) => {
    this.setState({
      selectValue: value,
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    const params = {
      groupName: this.state.groupName,
      pageNo: obj.current,
      pageSize: obj.pageSize,
    };
    this.fetchAllGroup(params);
    this.setState(params);
    window.scrollTo(0, 0); //滚动到顶端
  };

  // modal关闭事件
  handleCancel(e) {
    this.setState({
      visibleModal: false,
    });
  }

  // 修改数据
  modify(e, id) {
    e.preventDefault();
    this.fetchConfig({ id: id });
    this.setState({
      visibleModal: true,
      type: 'modify',
      id: id,
    });
  }

  // 删除档口数据
  deleteMessage(id) {
    this.stallDelete({
      id: id,
    });
  }

  // 启用/禁用档口分组
  updGroup(e, id, enable) {
    e.preventDefault();
    this.saveOrUpdate({
      id: id,
      enable: enable,
    });
  }

  // 修改信息的回调
  submit() {
    const {
      stall: { resData },
    } = this.props;
    let msg = '操作';
    if (resData.code === 200) {
      message.success(msg + '成功');
    } else {
      message.fail(msg + '失败');
    }
    this.handleOnQuery();
  }

  // 删除档口信息回调
  delete() {
    const {
      stall: { resData },
    } = this.props;
    if (resData === 200) {
      message.info('删除成功');
    } else {
      message.info('删除失败');
    }
    this.handleOnQuery();
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['groupName', 'remark'], (err, fieldsValue) => {
      if (err) {
        return;
      }
      const { groupName, remark } = fieldsValue;
      this.saveOrUpdate({
        id: this.state.type === 'add' ? null : this.state.id,
        groupName: groupName,
        remark: remark,
      });

      this.setState({
        visibleModal: false,
      });
    });
  };

  // modal radio 单选事件
  selectRadio = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  // 新增档口配置
  add() {
    this.setState({
      visibleModal: true,
      type: 'add',
      id: null,
    });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.setState(
      {
        selectValue: '',
      },
      () => {
        this.handleOnQuery();
      },
    );
  };

  toBoothGroup(e, groupNo) {
    e.preventDefault();
    this.props.history.push('/booth/boothAllGroup/boothGroup/' + groupNo);
  }
  toBoothConfig(e, groupNo) {
    e.preventDefault();
    this.props.history.push('/booth/boothAllGroup/boothConfig/' + groupNo);
  }

  render() {
    let {
      stall: { groupInfo, allStallsGroupData = [] },
    } = this.props;
    let allStallGroup = [];
    if (allStallsGroupData !== undefined && allStallsGroupData !== null) {
      allStallGroup = allStallsGroupData.lst;
    }
    const { pageEntity } = allStallsGroupData;
    const { getFieldDecorator } = this.props.form;
    let data = {};
    let total = 0;
    if (pageEntity) {
      total = pageEntity.totalSize;
    }

    if (groupInfo !== undefined && groupInfo !== null && this.state.type !== 'add') {
      data = groupInfo;
      this.setState({
        id: data.id,
      });
    }
    let { groupName, remark } = data;

    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 6,
      style: { marginBottom: 12 },
    };

    const extraContent = (
      <div className={'searchModule'}>
        <Form
          onSubmit={this.handleOnQuery}
          className='login-form'
          style={{ marginBottom: '12px', float: 'left', width: '100%' }}
        >
          <Row gutter={24}>
            <Col {...topColResponsiveProps}>
              <FormItem>
                {getFieldDecorator('searchGroupName', {
                  rules: [{ required: false, message: '请输入分组名称!' }],
                })(<Input placeholder='请输入分组名称' />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button onClick={this.handleReset}>重置</Button>
              <Button type='primary' onClick={this.add.bind(this)}>
                新增
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const pagination = {
      current: this.state.pageNo,
      pageSize: 10,
      total: total,
    };

    return (
      <div className='booth-index'>
        <PageHeaderLayout>
          <div className={'booth-all-group'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                dataSource={allStallGroup}
                pagination={pagination}
                bordered
                onChange={this.pageSwitch.bind(this)}
                rowKey={(_, index) => index}
              >
                <Column title='分组编号' dataIndex='groupNo' key='groupNo' />
                <Column title='分组名称' dataIndex='groupName' key='groupName' />
                <Column
                  title='备注'
                  dataIndex='remark'
                  key='remark'
                  render={(text) => {
                    let txtVal = text;
                    if (null != text && text.length > 20) {
                      txtVal = text.substring(0, 20) + '...';
                    }
                    return (
                      <Tooltip placement='topLeft' title={text} arrowPointAtCenter>
                        {' '}
                        {txtVal}{' '}
                      </Tooltip>
                    );
                  }}
                />
                <Column
                  title='是否启用'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const { enable } = record;
                    const txt = enable ? '启用' : '禁用';
                    return <span>{txt}</span>;
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    const { id, enable, groupNo } = record;
                    const title = `确定需要禁用`;
                    return (
                      <div>
                        <a
                          href='/'
                          onClick={(e) => {
                            this.modify(e, id);
                          }}
                        >
                          <span>{'修改'}</span>
                        </a>
                        <Divider type='vertical' />
                        <a href='/' disabled={enable} onClick={(e) => this.updGroup(e, id, true)}>
                          <span>{'启用'}</span>
                        </a>
                        <Divider type='vertical' />
                        <Popconfirm
                          title={title}
                          onConfirm={(e) => this.updGroup(e, id, false)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/' disabled={!enable}>
                            <span>{'禁用'}</span>
                          </a>
                        </Popconfirm>
                        <Divider type='vertical' />
                        <a href='/' onClick={(e) => this.toBoothGroup(e, groupNo)}>
                          <span>{'分组档口'}</span>
                        </a>
                        <Divider type='vertical' />
                        <a href='/' onClick={(e) => this.toBoothConfig(e, groupNo)}>
                          <span>{'分组配置'}</span>
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Card>
              <Modal
                title={this.state.type === 'add' ? '新增档口分组' : '修改档口分组'}
                visible={this.state.visibleModal}
                footer={null}
                onCancel={this.handleCancel}
                destroyOnClose='true'
                width='600px'
              >
                <div className={'extraContent'} style={{ width: '100%' }}>
                  <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label='分组名称'>
                      {getFieldDecorator('groupName', {
                        initialValue: groupName,
                        rules: [
                          {
                            required: true,
                            message: ' 请输入分组名称!',
                          },
                        ],
                      })(
                        <Input
                          style={{ paddingLeft: '0px', width: '100%' }}
                          placeholder='请输入分组名称'
                        />,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='备注'>
                      {getFieldDecorator('remark', {
                        initialValue: remark,
                        rules: [
                          {
                            required: true,
                            message: ' 备注!',
                          },
                        ],
                      })(
                        <Input
                          style={{ paddingLeft: '0px', width: '100%' }}
                          placeholder='请输入备注'
                        />,
                      )}
                    </FormItem>
                    <FormItem>
                      <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>
                          保存
                        </Button>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default BoothAllGroup;
