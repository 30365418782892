import * as actionTypes from '../constant/appVersion';
import * as req from '../request/appVersion';

//移除目标商行
export function deleteVersionRefer(params) {
  return async (dispatch) => {
    const res = await req.deleteVersionRefer(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { deleteStallResult: res } });
    }
  };
}
//灰度升级新增关联档口
export function fetchVersionReferAdd(params) {
  return async (dispatch) => {
    const res = await req.getVersionReferAdd(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { addStallResult: res } });
    }
  };
}
//获取灰度发版的商行列表
export function fetchTradingCompanyList(params) {
  return async (dispatch) => {
    const res = await req.getTradingCompanyList(params);
    if (res) {
      dispatch({
        type: actionTypes.APP_VERSION_SAVE,
        payload: { grayReleasedTradingCompanyList: res.data },
      });
    }
  };
}
//
export function fetchAppList(params) {
  return async (dispatch) => {
    const res = await req.getAppList(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { appListData: res.data } });
    }
  };
}
//
export function fetchSaveOrUpdateVer(params) {
  return async (dispatch) => {
    const res = await req.saveOrUpdateVer(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { saveOrUpdateVerData: res } });
    }
  };
}
//
export function fetchAppVerById(params) {
  return async (dispatch) => {
    const res = await req.getAppVerById(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { appVerByIdData: res.data } });
    }
  };
}
//警告消息推送
export function pushWarning(params) {
  return async (dispatch) => {
    const res = await req.pushWarning(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { pushWarningData: res } });
    }
  };
}
//版本更新推送消息
export function pushSync(params) {
  return async (dispatch) => {
    const res = await req.pushSync(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { pushSyncData: res } });
    }
  };
}
//获取版本推送设置列表
export function getVersionPushList(params) {
  return async (dispatch) => {
    const res = await req.getVersionPushList(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { getVersionPushListData: res } });
    }
  };
}
//新增版本推送设置
export function addVersionPush(params) {
  return async (dispatch) => {
    const res = await req.addVersionPush(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { addVersionPushData: res } });
    }
  };
}
//删除版本推送设置
export function deleteVersionPush(params) {
  return async (dispatch) => {
    const res = await req.deleteVersionPush(params);
    if (res) {
      dispatch({ type: actionTypes.APP_VERSION_SAVE, payload: { deleteVersionPushData: res } });
    }
  };
}
//重置版本推送状态
export function resetVersionPushStatus(params) {
  return async (dispatch) => {
    const res = await req.resetVersionPushStatus(params);
    if (res) {
      dispatch({
        type: actionTypes.APP_VERSION_SAVE,
        payload: { resetVersionPushStatusData: res },
      });
    }
  };
}
//
export function clearData() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.APP_VERSION_CLEAR });
  };
}
