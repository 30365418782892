// 商行管理（查看详情--商行配置管理)
import React, { PureComponent } from 'react';
import { Form, Button, message, Table, Modal, Row, Col, Popconfirm, Select, Input } from 'antd';

import {
  deleteStallGroup,
  getStallGroup,
  getStallGroupConfigs,
  saveOrUpdateStallGroup,
} from '@/state/request/stall';

import './device.less';

const pageSize = 10;

@Form.create()
class ConfigManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      current: 1,
      total: 0,
      dataList: [],
      groupName: '',
      configList: [],
      selected: null,
    };
  }

  componentDidMount() {
    this.getStallGroupConfigsList();
    this.getStallGroupList();
  }

  // 删除档口分组
  async deleteGroup(e, groupNo) {
    e.preventDefault();
    const { stallId } = this.props;
    const resData = await deleteStallGroup({ groupNo, stallId });
    const msg = '移除';
    if (resData.code === 200) {
      message.success(`${msg}成功`);
    } else if (resData.msg) {
      message.error(resData.msg);
    } else {
      message.error(`${msg}失败`);
    }
    this.getStallGroupList();
  }

  async getStallGroupConfigsList() {
    const res = await getStallGroupConfigs();
    const { data } = res;
    this.setState({ configList: data });
  }

  getStallGroupList = async () => {
    const { current, groupName } = this.state;
    const { stallId } = this.props;
    this.setState({ loading: true });
    const res = await getStallGroup({ pageNo: current, pageSize, stallId, groupName });
    const { data } = res;
    this.setState({ loading: false, dataList: data.pageList || [], total: data.totalSize || 0 });
  };

  async saveConfig() {
    const { selected } = this.state;
    const { stallId, marketId } = this.props;
    if (selected) {
      const res = await saveOrUpdateStallGroup({
        groupNo: selected,
        submitStallId: stallId,
        marketId,
      });
      const { code, msg } = res;
      this.setState({
        selected: null,
      });
      if (code === 200) {
        message.success('保存成功', 5);
        this.getStallGroupList();
      } else if (msg) {
        message.error(msg);
      }
    } else {
      message.error('输入配置不能为空', 5);
    }
  }

  pageSwitch = (obj) => {
    this.setState(
      {
        current: obj.current,
      },
      this.getStallGroupList,
    );
  };

  //新增、编辑Modal框
  showModal = (visible) => {
    this.setState({
      showModal: visible,
      selected: null,
    });
  };

  selectConfig = (e) => {
    this.setState({
      selected: e,
    });
  };
  render() {
    const { selected, loading, current, total, showModal, dataList, configList, groupName } =
      this.state;
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 100,
        render: (_, __, index) => {
          return index + 1;
        },
      },
      {
        title: '功能名称',
        dataIndex: 'groupName',
        key: 'groupName',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 120,
        render: (_, record) => {
          const { groupNo } = record;
          return (
            <div>
              <Popconfirm
                title='是否确定移除'
                onConfirm={(e) => this.deleteGroup(e, groupNo)}
                okText='确认'
                cancelText='取消'
              >
                <a href='/'>
                  <span>移除</span>
                </a>
              </Popconfirm>
            </div>
          );
        },
      },
    ];
    const pagination = {
      pageSize: 10,
      total: total,
      current: current,
      showTotal: (total) => `共 ${total} 条`,
    };
    return (
      <div className='deviceManagement'>
        <Row>
          <Form
            onSubmit={(e) => {
              e && e.preventDefault();
              this.setState(
                {
                  current: 1,
                },
                this.getStallGroupList,
              );
            }}
          >
            <Col span={10} style={{ textAlign: 'left', display: 'flex' }}>
              <Input
                allowClear
                value={groupName}
                onChange={(e) => {
                  this.setState({
                    groupName: e.target.value,
                  });
                }}
                placeholder='请输入功能名称查询'
                style={{ marginRight: 20, marginBottom: 12 }}
              />

              <Button htmlType='submit' type='primary'>
                查询
              </Button>
            </Col>
          </Form>
          <Col span={14} style={{ textAlign: 'right' }}>
            <Button type='primary' icon='plus' onClick={() => this.showModal(true)}>
              添加配置
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(r) => r.id}
          dataSource={dataList}
          columns={columns}
          pagination={pagination}
          onChange={this.pageSwitch.bind(this)}
          loading={loading}
        />
        <Modal
          title='添加配置'
          visible={showModal}
          onCancel={() => this.showModal(false)}
          footer={
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => this.showModal(false)}>取消</Button>
              <Button
                type='primary'
                onClick={() => this.saveConfig()}
                style={{ marginLeft: '18px' }}
              >
                保存
              </Button>
            </Row>
          }
          width='450px'
          className='add-modal'
        >
          <Select
            style={{ width: '100%' }}
            placeholder='请选择配置'
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selected}
            onChange={this.selectConfig}
          >
            {configList &&
              configList.map((item) => (
                <Select.Option
                  title={`${item.groupName}-${item.remark}`}
                  key={item.groupNo}
                  value={item.groupNo}
                >
                  {`${item.groupName}-${item.remark}`}
                </Select.Option>
              ))}
          </Select>
        </Modal>
      </div>
    );
  }
}

export default ConfigManagement;
