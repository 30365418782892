/**
 * 逻辑
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Button } from 'antd';

import '../style.less';
import PaymentTypePage from './PaymentType';

@connect(({ count }) => ({
  count,
}))
@Form.create()
class LogicPage extends PureComponent {
  constructor(props) {
    super(props);
    const paymentData = JSON.parse(localStorage.getItem('count_payment_rule')) || [];
    const addFeeData = JSON.parse(localStorage.getItem('count_addFee_rule')) || [];
    const data =
      Array.isArray(paymentData) && Array.isArray(addFeeData)
        ? [].concat(paymentData, addFeeData)
        : [];
    this.state = {
      descData: data,
      countDescData: JSON.parse(localStorage.getItem('count_desc_data')),
      comps: [],
    };
  }

  componentDidMount() {
    const reqStallId = localStorage.getItem('reqStallId') || 0;
    this.fetchAllStallConfig({ reqStallId: reqStallId });
  }

  fetchAllStallConfig(params) {
    this.props.dispatch({
      type: 'count/fetchAllStallConfig',
      payload: params,
    });
  }

  render() {
    const { descData, comps, countDescData } = this.state;
    const {
      count: { allStallConfigData },
    } = this.props;
    return (
      <div className='tabLayout'>
        <Card title='新建计算规则' bordered={false}></Card>
        <PaymentTypePage
          type={this.props.type}
          data={descData}
          key={Math.random()}
          title={this.props.title}
        />
        {comps.map(() => {
          return (
            <PaymentTypePage
              type={this.props.secType}
              data={countDescData}
              formulaData={allStallConfigData || []}
              key={Math.random()}
              title='货品记录项'
            />
          );
        })}
        <div className='addRecord'>
          <Button
            type='primary'
            icon='plus'
            onClick={() => this.setState({ comps: comps.concat([Date.now()]) })}
          >
            新建货品记录项
          </Button>
        </div>
      </div>
    );
  }
}
export default LogicPage;
