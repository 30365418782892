import React, { useState, useEffect, useCallback } from 'react'
import { Form, Table, Space, Button, Input, Select } from 'antd-v5'
import moment from 'moment'

import { marketList } from '../../../../state/request/stall'
import { getOpenStallList, getOpenStallDownload } from '../../../../state/request/serve'

import PageHeaderLayout from '../../../../component/page-header-layout'

import { layout, tailLayout } from '../../enum'
import { exportFile } from '../../../../utils/utils'

import './index.less'

const { Option } = Select

const OpenStall = (props) => {
  const [form] = Form.useForm()
  const [list, setList] = useState({})
  const [markList, setMarkList] = useState([])
  const { id, name } = props.match.params || {}
  const [query, setQuery] = useState({
    pageNo: 1,
    pageSize: 10,
    businessNo: id,
  })

  // 获取开通商行列表
  const getOpenStall = useCallback(async () => {
    const { code, data = {} } = await getOpenStallList(query)
    if (code === 200) {
      setList(data)
    }
  }, [query])

  // 获取市场列表
  const getMarketList = useCallback(async () => {
    const res = await marketList({})
    setMarkList(res.data || [])
  }, [])

  useEffect(() => {
    getOpenStall()
  }, [getOpenStall])

  useEffect(() => {
    getMarketList()
  }, [getMarketList])

  const onFinish = (values) => {
    setQuery({ ...query, ...values })
  }

  const onReset = () => {
    form.resetFields()
    setQuery({ pageNo: 1, pageSize: 10, businessNo: id })
  }

  const onDownload = async () => {
    const response = await getOpenStallDownload({ ...query });
    exportFile(response, "已开通商行列表.xlsx");
  }

  const ExtraContent = () => {
    return (
      <Form
        {...layout}
        form={form}
        layout="inline"
        onFinish={onFinish}
        className="header"
      >
        <Space wrap>
          <Form.Item label="市场名称" name="marketId">
            <Select
              style={{ width: 180 }}
              allowClear
              showSearch
              placeholder="请选择市场名称"
              onClear={() => {
                const { marketId, ...rest } = query
                setQuery(rest)
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {markList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="商行名称" name="stallName">
            <Input placeholder='请输入商行名称' />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button htmlType="button" onClick={onReset}>
                重置
              </Button>
              <Button htmlType="button" onClick={onDownload}>导出</Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    )
  }

  const columns = [
    {
      title: '商行ID',
      dataIndex: 'stallId',
    },
    {
      title: '商行名称',
      dataIndex: 'stallName',
    },
    {
      title: '市场名称',
      dataIndex: 'marketName',
    },
    {
      title: '剩余数量',
      dataIndex: 'balance',
      render: (_, record) => {
        const { balance, businessTypeEnum } = record
        const unit = businessTypeEnum === 'CREDIT_SIGN' ? '元' : ''
        return balance ? `${balance}${unit}` : '--'
      },
    },
    {
      title: '到期时间',
      dataIndex: 'expireTime',
      render: (t) => (t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : '--'),
    },
    {
      title: '剩余有效期',
      dataIndex: 'duration',
      render: (t) => (t ? `${t}天` : '--'),
    },
    {
      title: '是否过期',
      dataIndex: 'expireFlag',
      // false-未过期，true-过期
      render: (flag) => flag ? '是' : '否',
    },
  ]

  return (
    <PageHeaderLayout>
      <div className="open-stall">
        <div className='back'>
          <Button onClick={() => props.history.goBack()}>返回</Button>
          <span className='txt'>增值服务: {name}</span>
        </div>
        <ExtraContent />
        <Table
          rowKey="stallId"
          dataSource={list.pageList || []}
          columns={columns}
          scroll={{ x: 'max-content' }}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            current: query.pageNo,
            pageSize: query.pageSize,
            total: list.totalSize,
            onChange: (pageNo, pageSize) => {
              setQuery({ ...query, pageNo, pageSize })
            },
          }}
        />
      </div>
    </PageHeaderLayout>
  )
}

export default OpenStall
