import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Alert, Icon, Input, Button, Row, Col } from 'antd';

import { genaralPassword } from '../../../utils/math';
import { setAuthority } from '../../../utils/authority';
import { InitUser } from '../../../utils/profile';
import { postLogin, smsSend } from '../../../state/action/login';

import './login.less';

@Form.create()
@connect((state) => state.login, { postLogin, smsSend })
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'account',
      autoLogin: true,
      notice: '',
      time: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handSmsSend = this.handSmsSend.bind(this);
  }
  componentWillUnmount() {
    if (this.secondTimer) {
      clearInterval(this.secondTimer);
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        await this.props.postLogin({
          phone: values.username.split(' ').join(''),
          pwd: genaralPassword(values.password),
          loginMode: 2,
          code: values.code,
        });

        const { code, msg, data } = this.props.login;
        if (code === 200) {
          setAuthority(data); //存localStorage
          InitUser(data);
          this.setState({ notice: '' });
          this.props.history.push('/');
        } else {
          this.setState({ notice: msg });
        }
      }
    });
  }

  async handSmsSend(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        await this.props.smsSend({
          phone: values.username.split(' ').join(''),
          smsType: 'login',
        });

        const { code, msg } = this.props.login;
        if (code !== 200) {
          this.setState({ notice: msg });
        }

        this.setState({ time: 60 });
        this.secondTimer = setInterval(() => {
          const second = this.state.time;
          if (second >= 1) {
            this.setState({ time: second - 1 });
          } else {
            clearInterval(this.secondTimer);
          }
        }, 1000);
      }
    });
  }

  render() {
    const { notice } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='container-login-index-login'>
        <div className='main'>
          {notice && (
            <Alert
              style={{ marginBottom: 24 }}
              message={notice}
              type='error'
              showIcon
              closable={false}
            />
          )}
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label=''>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: '请输入用户名!' }],
              })(
                <Input
                  className='login-input'
                  prefix={<Icon type='user' style={{ color: 'rgba(255,255,255,1)' }} />}
                  placeholder='请输入用户名'
                  size='large'
                />,
              )}
            </Form.Item>

            <Form.Item label=''>
              <Row gutter={8}>
                <Col span={12}>
                  {getFieldDecorator('code', {
                    rules: [{ required: false, message: '请输入验证码!' }],
                  })(
                    <Input
                      className='login-input'
                      prefix={<Icon type='message' style={{ color: 'rgba(255,255,255,1)' }} />}
                      placeholder='请输入验证码'
                      size='large'
                    />,
                  )}
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                  {this.state.time === 0 ? (
                    <a
                      href='javascript:void(0);'
                      onClick={this.handSmsSend}
                      style={{ color: 'white' }}
                    >
                      {'获取验证码'}
                    </a>
                  ) : (
                    <font style={{ color: 'white' }}>{'倒计时(' + this.state.time + ')'}</font>
                  )}
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit' size='large' className='submit'>
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Login;
