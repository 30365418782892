import request from '../../utils/request';
/**
 * 商行购买服务记录
 * @param {objcet} params
 * @returns
 */

export async function fetchDunningRecord(params) {
  return request({
    url: `stall/business/purchase/records`,
    headers: 'json',
    method: 'post',
    params,
  });
}
export async function exportDunningRecord(params) {
  return request({
    url: `stall/business/records/download`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}
export async function fetchDunningRecordSummery(params) {
  return request({ url: `stall/business/sign/calculate`, headers: 'json', method: 'post', params });
}

// 撤销商行签字
export async function cancelSign(params) {
  return request({ url: `stall/business/sign/invalid`, params, headers: 'json' });
}
