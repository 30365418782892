import * as actionTypes from '../constant/count';

//初始状态
const initState = {
  allStallConfigData: [],
  allResultData: [],
  saveData: [],
  allConfigList: [],
  stallsByMarket: [],
  allMarketByCityCode: [],
  associatedStall: [],
  searchParams: {},
};

export function count(state = initState, action) {
  switch (action.type) {
    case actionTypes.COUNT_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
