import React, { useState, useEffect, useCallback } from 'react';
import { Form, Space, Input, Button, Table } from 'antd-v5';
import moment from 'moment';

import { getBusinessStallList } from '../../../../../state/request/serve';
import { layout, tailLayout } from '../../../../add-services/enum';

import './index.less';

const Serve = (props) => {
  const [form] = Form.useForm();
  const [list, setList] = useState([]);

  const { stallId } = props;

  const getData = useCallback(async (query = {}) => {
    const { code, data = [] } = await getBusinessStallList({ stallId, ...query });
    if (code === 200) {
      setList(data);
    }
  }, [stallId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFinish = (values) => {
    getData(values);
  };

  const ExtraContent = () => {
    return (
      <Form
        {...layout}
        form={form}
        layout='inline'
        onFinish={onFinish}
        className='header'
      >
        <Space wrap>
          <Form.Item
            name='businessName'
          >
            <Input placeholder='请输入功能名称查询' style={{ width: 240 }} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Form.Item>
        </Space>
      </Form>
    )
  }

  const columns = [
    {
      title: '增值服务名称',
      dataIndex: 'name',
    },
    {
      title: '剩余数量',
      dataIndex: 'balance',
      render: (t) => t || '--'
    },
    {
      title: '到期时间',
      dataIndex: 'expireTime',
      render: (t) => t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : '--'
    },
    {
      title: '剩余有效期',
      dataIndex: 'duration',
      render: (t) => t ? `${t}天` : '--'
    },
    {
      title: '是否过期',
      dataIndex: 'expireFlag',
      render: (flag) => flag ? '是' : '否',
    },
  ]

  return (
    <div className='serve'>
      <ExtraContent />
      <Table
        dataSource={list}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default Serve;