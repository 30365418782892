import * as actionTypes from '../constant/onlineStore';
import * as req from '../request/onlineStore';

//网店列表
export function getStoreList(params) {
  return async (dispatch) => {
    const res = await req.getStoreList(params);
    if (res) {
      dispatch({
        type: actionTypes.ONLINE_STORE,
        payload: { storeList: res.data, searchStore: params },
      });
    }
  };
}
//网店管理-新增
export function addStore(params) {
  return async () => {
    const res = await req.addStore(params);
    if (res) {
      return res;
    }
  };
}
//网店管理-编辑
export function updateStore(params) {
  return async () => {
    const res = await req.updateStore(params);
    if (res) {
      return res;
    }
  };
}
//分类管理-列表
export function getCategoriesList(params) {
  return async (dispatch) => {
    const res = await req.getCategoriesList(params);
    if (res) {
      dispatch({ type: actionTypes.ONLINE_STORE, payload: { categoriesList: res.data } });
    }
  };
}
//分类管理-新增
export function addCategories(params) {
  return async () => {
    const res = await req.addCategories(params);
    if (res) {
      return res;
    }
  };
}
//分类管理-编辑
export function updateCategories(params) {
  return async () => {
    const res = await req.updateCategories(params);
    if (res) {
      return res;
    }
  };
}
//分类管理-删除
export function deleteCategory(params) {
  return async () => {
    const res = await req.deleteCategory(params);
    if (res) {
      return res;
    }
  };
}
//商品管理-列表
export function getProductList(params) {
  return async (dispatch) => {
    const res = await req.getProductList(params);
    if (res) {
      dispatch({ type: actionTypes.ONLINE_STORE, payload: { productList: res.data } });
    }
  };
}
//商品管理-新增
export function addProduct(params) {
  return async () => {
    const res = await req.addProduct(params);
    if (res) {
      return res;
    }
  };
}
//商品管理-编辑
export function updateProduct(params) {
  return async () => {
    const res = await req.updateProduct(params);
    if (res) {
      return res;
    }
  };
}
//商品管理-删除
export function deleteProduct(params) {
  return async () => {
    const res = await req.deleteProduct(params);
    if (res) {
      return res;
    }
  };
}
