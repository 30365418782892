import * as actionTypes from '../constant/batchManager';
import * as req from '../request/batchManager';

// 获取货主列表
export function getSupplierList(params) {
  return async (dispatch) => {
    const res = await req.getSupplierList(params);
    if (res) {
      dispatch({ type: actionTypes.SUPPLIER_GET_LIST, payload: res });
    }
  };
}

//根据商行批次列表
export function getBatchList(params) {
  return async (dispatch) => {
    const res = await req.getBatchList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_GET_LIST, payload: res });
    }
  };
}

//根据批次ID获取批次明细列表
export function getBatchItemList(params) {
  return async (dispatch) => {
    const res = await req.getBatchItemList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_ITEM_GET_LIST, payload: res });
    }
  };
}

//根据商行ID,批次ID，批次明细ID获取库存操作记录列表
export function getBatchItemLogs(params) {
  return async (dispatch) => {
    const res = await req.getBatchItemLogs(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_ITEM_LOGS_GET_LIST, payload: res });
    }
  };
}

//根据商行ID,批次ID获取销售明细列表
export function getBatchSaleList(params) {
  return async (dispatch) => {
    const res = await req.getBatchSaleList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_SALE_GET_LIST, payload: res });
    }
  };
}
