// 商品库管理-商品基础库
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, Input, Table, Modal, Select, Divider, message } from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';

import './index.less';

import {
  getAllProducts,
  getCommodityBaseList,
  updateCommodity,
  deleteCommodity,
} from '../../../state/action/commodity';

const { Column } = Table;

const FormItem = Form.Item;
const { confirm } = Modal;

const pageSize = 10; // 表格

const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
const layout1 = { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 };

@connect(
  (state) => {
    return { commodity: state.commodity };
  },
  { getAllProducts, getCommodityBaseList, updateCommodity, deleteCommodity },
)
@Form.create()
class CommodityBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: {},
      visible: false,
      contentTitle: '',
      defaultCurrent: 1, //当前页
    };
  }

  componentDidMount() {
    const {
      commodity: { searchForm },
    } = this.props;
    let formData = { ...searchForm };
    if (formData.pageNo) {
      this.setState({
        defaultCurrent: formData.pageNo,
      });
    }
    const params = {
      ...searchForm,
      pageNo: formData.pageNo ? formData.pageNo : 1,
      pageSize: pageSize,
    };
    this.getAllProducts(); // 获取货品分类
    this.getCommodityBaseList(params); // 获取商品基库列表
  }

  // 获取货品分类
  async getAllProducts() {
    await this.props.getAllProducts();
  }

  // 获取商品基库列表
  async getCommodityBaseList(params) {
    this.setState({ loading: true });
    await this.props.getCommodityBaseList(params);
    this.setState({ loading: false });
    const {
      commodity: { commodityBaseList },
    } = this.props;
    if (commodityBaseList.code !== 200) {
      message.error(commodityBaseList.msg);
    }
  }

  isUndefied = (value) => {
    return value || null;
  };

  // 查询
  handleSumbit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((_, values) => {
      //
      this.setState(
        {
          defaultCurrent: 1,
        },
        () => {
          const params = {
            name: this.isUndefied(values.goodsName),
            aliasName: this.isUndefied(values.goodsAlias),
            category: this.isUndefied(values.categoryName),
            pageNo: 1,
            pageSize: pageSize,
          };
          this.getCommodityBaseList(params);
        },
      );
    });
  };
  // 重置
  handleReset = () => {
    this.props.form.resetFields();
    this.setState(
      {
        defaultCurrent: 1,
      },
      () => {
        const params = {
          name: null,
          aliasName: null,
          category: null,
          pageNo: 1,
          pageSize: pageSize,
        };
        this.getCommodityBaseList(params);
      },
    );
  };

  pageSwitch = (obj) => {
    this.setState(
      {
        defaultCurrent: obj.current,
      },
      () => {
        this.refreshTable();
      },
    );
  };

  // 表格分页查询-获取查询条件
  refreshTable = () => {
    const formData = this.props.form.getFieldsValue();
    const { defaultCurrent } = this.state;
    const params = {
      name: this.isUndefied(formData.goodsName),
      aliasName: this.isUndefied(formData.goodsAlias),
      category: this.isUndefied(formData.categoryName),
      pageNo: defaultCurrent,
      pageSize: pageSize,
    };
    //
    this.getCommodityBaseList(params);
  };

  // 新增、编辑
  showGoodsModal = (rows, visible, title) => {
    //
    this.setState({
      rows,
      visible,
      contentTitle: title,
    });
  };

  // 新增、编辑保存按钮
  editHandleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { rows } = this.state;
        let params = {};
        if (JSON.stringify(rows) === '{}') {
          //新增
          params = {
            type: 0,
            name: this.isUndefied(values.name),
            category: this.isUndefied(values.category),
          };
        } else {
          // 修改
          params = {
            type: 1,
            id: rows.id,
            name: this.isUndefied(values.name),
            category: this.isUndefied(values.category),
          };
        }
        this.updateCommodity(params);
      }
    });
  };

  async updateCommodity(params) {
    await this.props.updateCommodity(params);
    const {
      commodity: { updateCommodityData },
    } = this.props;
    if (updateCommodityData.code === 200) {
      if (params.type === 0) {
        message.success('保存成功');
        this.setState(
          {
            visible: false,
            defaultCurrent: 1,
          },
          () => {
            this.refreshTable();
          },
        );
      } else {
        message.success('修改成功');
        this.setState(
          {
            visible: false,
          },
          () => {
            this.refreshTable();
          },
        );
      }
    } else {
      message.error(updateCommodityData.msg);
    }
  }

  // 维护别名
  gotoGoods = (rows) => {
    this.props.history.push({
      pathname: '/commodityManage/commodityBase/addAlias',
      state: { rows },
    });
  };

  // 删除
  delete = (record) => {
    const that = this;
    confirm({
      title: '提示',
      content: `确定删除【${record.name}】该货品么？`,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        that.deleteCommodity({ id: record.id });
      },
      onCancel() {},
    });
  };

  async deleteCommodity(params) {
    await this.props.deleteCommodity(params);
    const {
      commodity: { deleteCommodityData },
    } = this.props;
    if (deleteCommodityData.code === 200) {
      message.success('删除成功');
      this.refreshTable();
    } else {
      message.error(deleteCommodityData.msg);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, rows, visible, contentTitle, defaultCurrent } = this.state;
    const {
      commodity: { productsData, commodityBaseList, searchForm },
    } = this.props;
    const formData = { ...searchForm };
    const categoryData = productsData && productsData.length > 0 ? [''].concat(productsData) : [];
    let pageList = [],
      dataSource = [],
      totalSize = 0;
    if (commodityBaseList.code === 200) {
      //判断表格查询
      pageList = commodityBaseList.data ? commodityBaseList.data.pageList : [];
      dataSource = pageList && pageList.length > 0 ? pageList : [];
      totalSize = commodityBaseList.data.totalSize;
    }
    const pagination = {
      pageSize: 10,
      total: totalSize,
      current: defaultCurrent,
    };

    return (
      <div className='commodity-index'>
        <PageHeaderLayout>
          <div className='commodityManage'>
            <Form onSubmit={this.handleSumbit} layout='inline'>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem label='名称搜索'>
                    {getFieldDecorator('goodsName', {
                      initialValue: formData.name || null,
                    })(<Input placeholder='请输入货品名称' allowClear />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem label='货品别名搜索'>
                    {getFieldDecorator('goodsAlias', {
                      initialValue: formData.aliasName || null,
                    })(<Input placeholder='请输入货品别名' allowClear />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem label='货品类筛选'>
                    {getFieldDecorator('categoryName', {
                      initialValue: formData.category || '',
                    })(
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {categoryData &&
                          categoryData.map((item, index) => {
                            return (
                              <Select.Option key={index} value={item}>
                                {item === '' ? '全部' : item}
                              </Select.Option>
                            );
                          })}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row gutter={10}>
                <Col span={24}>
                  <Button type='primary' style={{ width: 80 }} htmlType='submit'>
                    查询
                  </Button>
                  <Button style={{ marginLeft: 25, width: 80 }} onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    style={{ marginLeft: 25 }}
                    onClick={() => this.showGoodsModal({}, true, '新增基库货品')}
                  >
                    新增货品
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              rowKey={(_, index) => index}
              dataSource={dataSource}
              pagination={pagination}
              scroll={{ x: 600 }}
              bordered
              loading={loading}
              onChange={this.pageSwitch.bind(this)}
            >
              <Column title='货品ID' dataIndex='id' key='id' width='100px' />
              <Column title='货品名称' dataIndex='name' key='name' width='100px' />
              <Column title='货品分类' dataIndex='category' key='category' width='100px' />
              <Column title='别名数' dataIndex='aliasNum' key='aliasNum' width='80px' />
              <Column
                title='货品别名'
                dataIndex='aliasName'
                key='aliasName'
                width='400px'
                onCell={() => {
                  // 内容过长添加...
                  return {
                    style: {
                      maxWidth: 150,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    },
                  };
                }}
                render={(text) => {
                  return text ? JSON.parse(text).join('、') : '';
                }}
              />
              <Column
                title='操作'
                dataIndex='option'
                key='option'
                width='200px'
                render={(_, record) => {
                  return (
                    <div>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.gotoGoods(record);
                        }}
                      >
                        别名维护
                      </a>
                      <Divider type='vertical' />
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.showGoodsModal(record, true, '编辑基库货品');
                        }}
                      >
                        编辑基础库货品
                      </a>
                      <Divider type='vertical' />
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.delete(record);
                        }}
                      >
                        删除
                      </a>
                    </div>
                  );
                }}
              />
            </Table>
            {/** 新增货品、 修改货品 */}
            {visible && (
              <Modal
                title={null}
                visible={visible}
                onCancel={() => this.showGoodsModal({}, false)}
                destroyOnClose='true'
                width='600px'
                className='commodity-modal'
                footer={null}
              >
                <Form ref='modalFormRef'>
                  <Row style={{ textAlign: 'center' }}>
                    <h3>{contentTitle}</h3>
                  </Row>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='货品名称'>
                        {getFieldDecorator('name', {
                          initialValue: rows.name || '',
                          rules: [
                            {
                              required: true,
                              validator: (_, value, callback) => {
                                if (value) {
                                  if (value.length > 6) {
                                    callback('货品名称不超过6位');
                                  }
                                } else {
                                  callback('请输入货品名称');
                                }
                                callback();
                              },
                            },
                          ],
                        })(<Input type='text' placeholder='请输入货品名称' allowClear />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='货品分类'>
                        {getFieldDecorator('category', {
                          initialValue: rows.category || '',
                          rules: [{ required: true, message: '请选择货品分类' }],
                        })(
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {productsData &&
                              productsData.map((item, index) => {
                                return (
                                  <Select.Option key={index} value={item}>
                                    {item}
                                  </Select.Option>
                                );
                              })}
                          </Select>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={10} style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Button type='primary' style={{ width: 80 }} onClick={this.editHandleSubmit}>
                        保存
                      </Button>
                      <Button
                        style={{ marginLeft: 25, width: 80 }}
                        onClick={() => this.showGoodsModal({}, false)}
                      >
                        取消
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            )}
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default CommodityBase;
