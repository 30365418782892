import request from '@/utils/request';

// 系统公告列表
export async function getNoticeList(params) {
  return request({ url: 'sysNotice/admin/list', params, method: 'get', headers: 'json' });
}

// 删除系统公告
export async function deleteNotice(id) {
  return request({
    url: `sysNotice/admin/delete/${id}`,
    params: {},
    method: 'delete',
    headers: 'json',
  });
}

// 修改系统公告
export async function updateNotice(params) {
  return request({
    url: 'sysNotice/admin/update',
    params,
    method: 'post',
    headers: 'json',
  });
}

// 新增系统公告
export async function addNotice(params) {
  return request({
    url: 'sysNotice/admin/add',
    params,
    method: 'post',
    headers: 'json',
  });
}

// 获取系统公告详情
export async function getNoticeDetail(id) {
  return request({
    url: `sysNotice/admin/detail?id=${id}`,
    method: 'get',
    headers: 'json',
  });
}
