import React from 'react';
import numeral from 'numeral';
import crypto from 'crypto';
import { Buffer } from 'buffer';
import qs from 'qs';
import JSEncrypt from 'jsencrypt';
import { isEmptyObject } from './reg';
import { getAuthorityUserInfo } from './authority';

const TOKEN = 'CaiXM';
export function generalSignature(timestamp, nonce, method) {
  let userinfo = getAuthorityUserInfo();
  let hash = !isEmptyObject(userinfo) ? userinfo.access_token : '';
  var arr = new Array(5);
  arr[0] = TOKEN;
  arr[1] = timestamp;
  arr[2] = nonce;
  arr[3] = method;
  arr[4] = hash;
  arr.sort();
  let temp = arr.join('');
  return SHA1(temp);
}

export function generalParames(method) {
  let timestamp = new Date().getTime();
  let nonce = 'CaiXM';
  let userinfo = getAuthorityUserInfo();
  let hash = !isEmptyObject(userinfo) ? userinfo.access_token : '';
  let signature = generalSignature(timestamp, nonce, method);

  // let stall = getStallInfo();
  // let stallid = !isEmptyObject(stall) ? stall.id : 0;

  return {
    time: timestamp,
    nonce: nonce,
    method: method,
    signature: signature,
    accessToken: hash,
    // stallsId: stallid,
    isjs: 'js',
    appType: 'admin',
    pageNo: 0,
    pageSize: 20,
  };
}

export function generalParamsWithP(url, avrg, normalP = false) {
  var addition = generalParames(url);

  let stallid = addition.stallsId;

  // 获取加解密消息
  let params = { stallId: stallid };
  let avrgstr = '';

  if (!isEmptyObject(avrg)) {
    avrgstr = JSON.stringify({ ...avrg });
  } else {
    avrgstr = JSON.stringify({});
  }

  if (avrgstr.length > 0) {
    if (normalP) {
      const avrgObj = JSON.parse(avrgstr);
      Object.keys(avrg).map(v => {
        params[v] = avrgObj[v];
      })
    } else {
      params = { p: BASE64_encode(avrgstr) };
    }
  }

  if (!isEmptyObject(params)) {
    params = { ...params, ...addition };
  } else {
    params = addition;
  }

  return params;
}

export function generalParamsWithPToStr(url, avrg) {
  var obj = generalParamsWithP(url, avrg, false);
  console.log('参数', qs.stringify(obj));
  return qs.stringify(obj);
}

export function mathFormat(val, decnum = 0) {
  let integer = null;
  if (Number.isInteger(val)) {
    integer = numeral(val).format('0,0');
  } else {
    val = Number(val);
    if (val > 0) {
      integer = val.toFixed(0) > val ? numeral(val - 1).format('0,0') : numeral(val).format('0,0');
    } else if (val === 0) {
      integer = numeral(val).format('0,0');
    } else {
      integer = val.toFixed(0) > val ? numeral(val).format('0,0') : numeral(val + 1).format('0,0');
    }
  }

  if (decnum > 0) {
    let po = Math.pow(10, decnum);
    let format = '';
    for (let i = 0; i < decnum; i++) {
      format += '0';
    }
    const decimal = numeral(Math.round(val * po) % po).format(format);
    if (decimal.length === decnum) {
      return { integer: integer, decimal: decimal };
    }
    return { integer: integer, decimal: Math.abs(decimal) };
  } else {
    return { integer: integer };
  }
}

export function Yuan(amount, decnum = 2, numExtraWrap) {
  if (decnum === 0) {
    return (
      <span className={numExtraWrap}>
        <span>{numeral(amount).format('0,0')}</span>
        <small />
      </span>
    );
  } else {
    let { integer, decimal } = mathFormat(amount, decnum);
    return (
      <span className={numExtraWrap}>
        <span>{integer}</span>.<small>{decimal}</small>
      </span>
    );
  }
}

export function MD5(sin) {
  const hash = crypto.createHash('md5');
  hash.update(sin);
  return hash.digest('hex');
}

export function SHA1(sin) {
  // const cipher = crypto.createCipher('base64');
  // var crypted = cipher.update( sin , 'utf8', 'hex');
  //   crypted += cipher.final('hex');
  //
  const hash = crypto.createHash('sha1');
  hash.update(sin);
  return hash.digest('hex');
}

export const encrypt = (publicKey, data) => {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  const encrypted = encryptor.encrypt(data);
  return encrypted;
};

export function commEncode(str, source = 'utf8', cry = 'base64') {
  var encoder = new Buffer(str, source).toString(cry);
  return encoder;
}

export function BASE64_encode(str) {
  // var base64Str = new Buffer( str ).toString('base64');
  // return commEncode(str, 'utf8', 'base64');
  // return window.btoa(str) // 编码
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function BASE64_decode(str) {
  // return commEncode(str, 'base64', 'utf8');
  // return window.atob(str) // 解码
  return decodeURIComponent(escape(window.atob(str)));
}

export function genaralPassword(password) {
  password = password + 'eason';
  password = SHA1(password);
  return password.toLowerCase();
}

// 按中文首字母排序
export function sort(arr, field) {
  if (arr.length) {
    arr.sort((item1, item2) => {
      let a = typeof item1[field] !== 'string' ? item1[field] + '' : item1[field];
      let b = typeof item2[field] !== 'string' ? item2[field] + '' : item2[field];
      return a.localeCompare(b, 'zh');
    });
  } else {
    return [];
  }
}
