import moment from 'moment';

export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: '额度',
    dataIndex: 'remark',
    key: 'remark',
  },
  {
    title: '价值',
    dataIndex: 'payAmount',
    key: 'payAmount',
  },
  {
    title: '操作人',
    dataIndex: 'createName',
    key: 'createName',
  },
  {
    title: '操作时间',
    dataIndex: 'createTime',
    key: 'createTime',
    render: (text) => {
      return moment(text).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];
