import { isEmptyObject } from './reg';
import * as calmath from './math';

/**
 * 判断是否有权限
 */
export function hasRoles() {
  const userRole = getAuthority();
  const authority = ['staller'];
  if (authority.indexOf(userRole) >= 0) {
    //有权限
    return true;
  } else {
    //无权限
    return false;
  }
}
// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority() {
  let userInfo = getAuthorityUserInfo();
  if (isEmptyObject(userInfo)) {
    return 'guide';
  }
  return userInfo.role || 'guide';
}

export function getAuthorityUserInfo() {
  let str = localStorage.getItem('cxm-user-info');
  if (isEmptyObject(str)) {
    return null;
  }
  let userInfo = JSON.parse(calmath.BASE64_decode(str));
  //
  return userInfo;
}

export function setAuthority(authority) {
  if (isEmptyObject(authority)) {
    localStorage.removeItem('cxm-stall-info');
    localStorage.removeItem('cxm-user-info');
    return true;
  }
  // 存储档口信息、默认为第一个
  let stallInfo = isEmptyObject(authority.stalls) ? null : authority.stalls[0];
  setStallInfo(stallInfo);
  let authorityStr = calmath.BASE64_encode(JSON.stringify(authority));
  return localStorage.setItem('cxm-user-info', authorityStr);
}

export function getStallInfo() {
  let str = localStorage.getItem('cxm-stall-info');
  if (isEmptyObject(str)) {
    return null;
  }
  let stallInfo = JSON.parse(calmath.BASE64_decode(str));
  return stallInfo;
}

export function setStallInfo(stallinfo) {
  //
  if (isEmptyObject(stallinfo)) {
    let userInfo = getAuthorityUserInfo();
    if (isEmptyObject(userInfo)) {
      return localStorage.removeItem('cxm-stall-info');
    }
    stallinfo = isEmptyObject(userInfo.stalls) ? null : userInfo.stalls[0];
    if (isEmptyObject(stallinfo)) {
      return;
    }
  }
  let stallinfoStr = calmath.BASE64_encode(JSON.stringify(stallinfo));
  return localStorage.setItem('cxm-stall-info', stallinfoStr);
}
//根据指定的权限码（01.003.006）来判断是否有权限
export function hasPermissionCode(code) {
  const userInfo = getAuthorityUserInfo();
  if (userInfo != null) {
    // permissionCodes code值判断不同角色权限
    const userRoles = userInfo.permissionCodes || [];
    return userRoles.findIndex((v) => v === code) === -1 ? false : true;
  }
  return false;
}
