import * as actionTypes from '../constant/batch';
import * as req from '../request/batch';

//档口选择列表接口
export function fileSelectList(params) {
  return async (dispatch) => {
    const res = await req.getFileSelectList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_SAVE, payload: { fileSelectListData: res.data } });
    }
  };
}

//
export function getAllWhiteListByPage(params) {
  return async (dispatch) => {
    const res = await req.getAllWhiteListByPage(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_SAVE, payload: { WhiteListByPage: res.data } });
    }
  };
}
//
export function addWhiteList(params) {
  return async (dispatch) => {
    const res = await req.addWhiteList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_SAVE, payload: { addWhiteList: res } });
    }
  };
}
//
export function deleteWhiteList(params) {
  return async (dispatch) => {
    const res = await req.deleteWhiteList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_SAVE, payload: { deleteWhiteList: res } });
    }
  };
}
//
export function clearData() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.BATCH_CLEAR });
  };
}

export function productList(params) {
  return async (dispatch) => {
    const res = await req.productList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_SAVE, payload: { productList: res.data } });
    }
  };
}

// 分类列表
export function categoryList(params) {
  return async (dispatch) => {
    const res = await req.categoryList(params);
    if (res) {
      dispatch({ type: actionTypes.BATCH_SAVE, payload: { categoryList: res } });
    }
  };
}
