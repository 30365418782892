import * as actionTypes from '../constant/sysLog';

//初始状态
const initState = {
  syslogList: {},
  sysLogList: {},
};

//reducer
export function sysLog(state = initState, action) {
  switch (action.type) {
    case actionTypes.SYS_LOG_GET_LIST:
      return { ...state, sysLogList: action.payload.data };
    case actionTypes.SYS_LOG_GET_ALL_DISTRICT_INFO:
      return { ...state, districtInfo: action.payload };
    case actionTypes.SYS_LOG_GET_MARKET_INFO:
      return { ...state, newMarketInfo: action.payload.data };
    case actionTypes.SYS_LOG_GET_MARKET_INFO_BY_ID:
      return { ...state, marketInfo: action.payload.data };
    case actionTypes.SYS_LOG_GET_VIEW_LIST:
      return { ...state, syslogList: action.payload };
    default:
      return state;
  }
}
