// 商行管理 -- 商行管理（查看详情--修改档主信息)
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, Row, Col, Input, message } from 'antd';

import './device.less';
import { updateStallManger } from '../../../../state/action/stall';

const FormItem = Form.Item;

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { updateStallManger },
)
@Form.create()
class UpdateStall extends PureComponent {
  // 修改档主信息
  updateStallManger = (e) => {
    e.preventDefault();
    const {
      stall: { stallMangerInfoVo },
    } = this.props;
    const { bdStallId } = stallMangerInfoVo;
    this.props.form.validateFields(['owner', 'ownerphone'], (errors, values) => {
      if (!!errors) {
        return;
      }
      this.updateStallIdea({ name: values.owner, phone: values.ownerphone, bdStallId: bdStallId });
    });
  };

  //修改档主信息
  async updateStallIdea(params) {
    await this.props.updateStallManger(params);
    const {
      stall: { updateMangerInfoVo },
    } = this.props;
    if (updateMangerInfoVo && updateMangerInfoVo.code === 200) {
      message.info('修改成功！');
      this.props.handleCancel(true);
    } else if (updateMangerInfoVo && updateMangerInfoVo.code && updateMangerInfoVo.code !== 200) {
      message.error(updateMangerInfoVo.msg);
    }
  }

  render() {
    const { stallVisible, stallMangerInfoVo, handleCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title='修改档主信息'
        visible={stallVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        className='modalStyle'
        width='600px'
        footer={[]}
      >
        <div className='extraContent' style={{ width: '100%' }}>
          <Form onSubmit={this.updateStallManger}>
            <Row gutter={24}>
              <Col span={24} style={{ paddingTop: '10px' }}>
                <FormItem label='档主名称'>
                  {getFieldDecorator('owner', {
                    initialValue: stallMangerInfoVo.name,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: ' 请输入档主名称!',
                      },
                    ],
                  })(<Input placeholder='请输入档主名称' />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} style={{ paddingTop: '10px' }}>
                <FormItem label='档主手机号'>
                  {getFieldDecorator('ownerphone', {
                    initialValue: stallMangerInfoVo.phone,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        pattern: /^1[3456789]\d{9}$/,
                        message: '请输入正确的电话号码!',
                      },
                    ],
                  })(
                    <Input
                      readOnly
                      onClick={() => {
                        message.warn('运营端不支持修改档主手机号');
                      }}
                      placeholder='请输入档主手机号'
                      style={{ width: '100%' }}
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <br />
            <div style={{ textAlign: 'center' }}>
              <Button onClick={handleCancel} style={{ marginRight: 12 }}>
                关闭
              </Button>
              <Button type='primary' htmlType='submit' ref='saveOrUpdate'>
                确定
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default UpdateStall;
