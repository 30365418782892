import * as actionTypes from '../constant/stall';

//初始状态
const initState = {
  // 档口基本信息
  stallinfo: [],
  // 档口角色
  // 档主
  staller: [],
  // 档口经理
  stallManager: [],
  // 销售经理
  salers: [],
  // 收银人员
  cashiers: [],
  // 财务人员
  financer: [],

  members: [],
  // 本日数据
  today: [],
  yesterday: [],
  // 本日数据
  month: [],
  avarage: [],
  // 档口本月每日力资费
  stallSaleFeeData: [],
  //档口本月每日销售总额
  stallSaleAmountData: [],
  //销售经理销售总额
  salerData: [],
  loading: false,

  // 档口员工列表
  allStallData: [],
  stallsByIdData: [],
  getConfigData: [],

  allStallsConfigData: [],
  saveOrUpdateData: [],
  stallDeleteData: [],

  // 组织机构列表
  orgListDate: [],
  stallMangerInfoVo: {},

  stallPrinter: [], //根据档口id获取打印机信息
  printer: [], //获取未关联的打印机列表
  stallConnectPrinter: {}, //stallConnectPrinter

  addSysPrinterConfig: {}, //保存打印机信息
  updateSysPrinterConfig: {}, //修改打印机信息成功！
  allStallBankData: [], //商行列表
  boothStaticData: {}, //商行数据统计列表
  searchStall: {}, //商行管理列表查询数据
  stallDetails: {}, //商行管理--详情
  searchPrinterData: {}, //获取打印机关联商行信息
  allStatistical: { gmv: 1, billsTotalCount: 1, activeUserNum: 1 }, //所有档口的数据统计
  statistical: { gmv: 1, billsTotalCount: 1 }, //档口的数据统计
  devicesList: {}, //设备管理列表
  pactsList: {}, //合同管理列表
  pactImgs: [], //合同附件
  listBasket: [], //筐子列表
  settlementFeeList: [], //结算费用列表
  settlementFeeType: {}, //结算类型
  stallWhitList: [], //商行白名单
  addtionFeeList: [], //付姐费用列表
};

//reducer
export function stall(state = initState, action) {
  switch (action.type) {
    case actionTypes.STALL_SAVE:
      return { ...state, ...action.payload };
    case actionTypes.STALL_SAVE_INFO:
      return { ...state, stallinfo: action.payload };
    default:
      return state;
  }
}
