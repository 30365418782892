import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Input,
  Button,
  Select,
  Table,
  Divider,
  Modal,
  Radio,
  InputNumber,
  message,
} from 'antd';

import './style.less';
import { isEmptyObject } from '../../utils/reg';

import {
  getAllStallItem,
  saveStallItem,
  updateStallItem,
  fetchAllStallConfig,
} from '../../state/action/count';
import { unitRules } from '../../utils/deviceType';

const RadioGroup = Radio.Group;
const { Option } = Select;
const FormItem = Form.Item;

@connect(
  (state) => {
    return { basic: state.basic, count: state.count };
  },
  { getAllStallItem, saveStallItem, updateStallItem, fetchAllStallConfig },
)
@connect(({ count, basic, mergeSet }) => ({
  count,
  basic,
  mergeSet,
}))
@Form.create()
class ConfigList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updShow: false,
      updInfo: {},
      value: 1,
    };
  }

  componentDidMount() {
    this.fetchAllStallItem({});
    this.props.fetchAllStallConfig({ reqStallId: 0 });
  }

  fetchAllStallItem(params) {
    let id = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    params.id = id;
    this.props.getAllStallItem(params);
  }

  saveStallItem = (params) => {
    this.props.saveStallItem(params).then(() => {
      let {
        count: { stallItem },
      } = this.props;
      this.fetchAllStallItem({});
      if (stallItem.code === 200) {
        message.success('操作成功!');
      } else {
        message.error(stallItem.msg);
      }
    });
  };

  updateStallItem = (params) => {
    this.props.updateStallItem(params).then(() => {
      this.fetchAllStallItem({});
      message.success('操作成功!');
    });
  };

  showUpdateModal(record) {
    this.setState({
      updShow: true,
      showDesc: record.showDesc,
      updInfo: record,
    });
  }

  showAddModal = () => {
    this.setState({
      updShow: true,
      showDesc: true,
      updInfo: {},
    });
  };

  // 分页查询
  pageChange = () => {
    this.fetchAllStallItem({});
  };

  // 模态框取消
  handleCancel = () => {
    this.setState({
      updShow: false,
    });
  };

  // 修改规则名称/备注
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['key', 'showDesc', 'desc', 'unit', 'unitRule', 'order', 'type'],
      (err, fieldsValue) => {
        if (err) {
          return;
        }
        let id = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
        let calcResult = this.getCalcResult(fieldsValue.key);
        let params = {
          id: id,
          itemKeyLst: [
            {
              id: this.state.updInfo.id,
              key: fieldsValue.key,
              showDesc: fieldsValue.showDesc,
              desc: fieldsValue.desc,
              unit: fieldsValue.unit,
              unitRule: fieldsValue.unitRule,
              order: fieldsValue.order,
              validateReg: calcResult ? calcResult.regex : '',
              validateMsg: calcResult ? calcResult.errMsg : '',
              stallId: calcResult ? calcResult.stallId : '',
              marketId: calcResult ? calcResult.marketId : '',
              type: fieldsValue.type,
            },
          ],
        };
        if (this.state.updInfo.id) {
          this.updateStallItem(params);
        } else {
          this.saveStallItem(params);
        }
        this.handleCancel();
      },
    );
  };

  // 获取返回结果对象
  getCalcResult = (key) => {
    let {
      count: { allStallConfigData },
    } = this.props;
    for (var i = 0; i < allStallConfigData.length; i++) {
      if (allStallConfigData[i].key === key) {
        return allStallConfigData[i];
      }
    }
  };

  // 修改规则名称/备注
  updateEnable = (kid, enable) => {
    let id = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    let params = {
      id: id,
      itemKeyLst: [
        {
          id: kid,
          enable: enable,
        },
      ],
    };
    this.updateStallItem(params);
  };

  handleChange = (e) => {
    this.setState({
      showDesc: e.target.value,
    });
  };
  goBack = (e) => {
    this.props.history.push('/count/configlist');
  };

  render() {
    let {
      count: { stallItem, allStallConfigData },
    } = this.props;
    let lst = stallItem ? stallItem.data : [];
    const { getFieldDecorator } = this.props.form;
    const { updShow } = this.state;
    if (!allStallConfigData) {
      allStallConfigData = [];
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const columns = [
      {
        title: 'KEY',
        dataIndex: 'key',
      },
      {
        title: '名称',
        dataIndex: 'keyDesc',
      },
      {
        title: '是否显示title',
        dataIndex: 'showDesc',
        render: (_, record) => {
          const { showDesc } = record;
          if (showDesc) {
            return '是';
          }
          return '否';
        },
      },
      {
        title: 'title名称',
        dataIndex: 'desc',
      },
      {
        title: '排序',
        dataIndex: 'order',
      },
      {
        title: '状态',
        dataIndex: 'enable',
        render: (_, record) => {
          const { enable } = record;
          let enableStr = '启用';
          if (!enable) {
            enableStr = '禁用';
          }
          return <span>{enableStr}</span>;
        },
      },
      {
        title: '操作',
        dataIndex: 'oper',
        render: (_, record) => {
          const { enable } = record;
          return (
            <div>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showUpdateModal(record);
                }}
              >
                编辑
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateEnable(record.id, !enable);
                }}
              >
                {enable ? '禁用' : '启用'}
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <div className='tabLayout'>
        <Card title='配置列表' bordered={false}>
          <Button onClick={this.showAddModal}>新增菜品计算显示项</Button>
          <Button onClick={this.goBack}>返回配置列表</Button>
          <Table dataSource={lst || []} columns={columns} rowKey='id' />
        </Card>
        <Card className='modalCard'>
          <Modal
            title='前台显示配置'
            visible={updShow}
            onCancel={this.handleCancel}
            destroyOnClose='true'
            width='600px'
            className='modal'
            footer={[]}
          >
            <Form onSubmit={this.onSubmit} className='login-form'>
              <FormItem {...formItemLayout} label='KEY'>
                {getFieldDecorator('key', {
                  initialValue: this.state.updInfo.key,
                  rules: [{ required: true, message: '请选择KEY' }],
                })(
                  <Select
                    disabled={this.state.updInfo.id ? true : false}
                    placeholder='请选择KEY'
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children &&
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allStallConfigData.map((item) => (
                      <Option key={item.key} value={item.key}>
                        {item.desc && item.desc[0]}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='是否显示title'>
                {getFieldDecorator('showDesc', {
                  initialValue: this.state.updInfo.showDesc ? this.state.updInfo.showDesc : true,
                })(
                  <RadioGroup onChange={this.handleChange} value={this.state.value}>
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                  </RadioGroup>,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='title名称'>
                {getFieldDecorator('desc', {
                  initialValue: this.state.updInfo.desc,
                  rules: [{ required: true, message: '请输入title名称' }],
                })(<Input disabled={!this.state.showDesc} />)}
              </FormItem>
              <FormItem {...formItemLayout} label='单位'>
                {getFieldDecorator('unit', {
                  initialValue: this.state.updInfo.unit,
                  rules: [{ required: false, message: '请输入单位' }],
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label='单位规则'>
                {getFieldDecorator('unitRule', {
                  initialValue: this.state.updInfo.unitRule || 0,
                  rules: [{ required: true, message: '请选择单位规则' }],
                })(
                  <Select
                    placeholder='请选择单位规则'
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {[...unitRules].map(([k, v]) => {
                      return (
                        <Option key={k} value={k}>
                          {v}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='排序'>
                {getFieldDecorator('order', {
                  initialValue: this.state.updInfo.order,
                  rules: [{ required: true, message: '请输入排序' }],
                })(<InputNumber min={1} step={1} />)}
              </FormItem>
              <FormItem {...formItemLayout} label='值类型'>
                {getFieldDecorator('type', {
                  initialValue: this.state.updInfo.type,
                  rules: [{ required: false, message: '请选择类型' }],
                })(
                  <Select
                    placeholder='请选择类型'
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={'java.lang.Integer'}>{'整数'}</Option>
                    <Option key={'java.math.BigDecimal'}>{'小数'}</Option>
                    <Option key={'java.lang.Boolean'}>{'开关(布尔值)'}</Option>
                  </Select>,
                )}
              </FormItem>
              <FormItem wrapperCol={{ span: 24, offset: 11 }}>
                <Button
                  style={{ float: 'center', marginRight: '5px', marginTop: '5px' }}
                  type='primary'
                  htmlType='submit'
                >
                  保存
                </Button>
              </FormItem>
            </Form>
          </Modal>
        </Card>
      </div>
    );
  }
}
export default ConfigList;
