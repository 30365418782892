import * as actionTypes from '../constant/memberManager';
import * as req from '../request/memberManager';

//会员管理 会员服务管理
//会员列表
export function getListMemebers(params) {
  return async (dispatch) => {
    const res = await req.getListMemebers(params);
    if (res) {
      dispatch({
        type: actionTypes.MEMBER_MANGER,
        payload: { memebersList: res.data, searchList: params },
      });
    }
  };
}
//新增会员续费记录
export function addVipPay(params) {
  return async (dispatch) => {
    const res = await req.addVipPay(params);
    if (res) {
      dispatch({ type: actionTypes.MEMBER_MANGER, payload: { addVipPayMsg: res } });
    }
  };
}
//新增续费时选中会员服务
export function selectedProduct(params) {
  return async (dispatch) => {
    const res = await req.selectedProduct(params);
    if (res) {
      dispatch({ type: actionTypes.MEMBER_MANGER, payload: { selectedProduct: res.data } });
    }
  };
}
//会员服务列表
export function listProducts(params) {
  return async (dispatch) => {
    const res = await req.listProducts(params);
    if (res) {
      dispatch({ type: actionTypes.MEMBER_MANGER, payload: { productsList: res.data } });
    }
  };
}
//会员续费列表
export function listVipPay(params) {
  return async (dispatch) => {
    const res = await req.listVipPay(params);
    if (res) {
      dispatch({ type: actionTypes.MEMBER_MANGER, payload: { vipPayList: res.data } });
      return res;
    }
  };
}
//编辑会员服务
export function updateMemberServices(params) {
  return async (dispatch) => {
    const res = await req.updateMemberServices(params);
    if (res) {
      return res;
    }
  };
}
