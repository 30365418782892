import * as actionTypes from '../constant/mergeSet';
import * as req from '../request/mergeSet';

export function getMergeSetList(params) {
  return async (dispatch) => {
    const res = await req.getMergeSetList(params);
    if (res) {
      dispatch({ type: actionTypes.MERGESET_SAVE, payload: { mergeList: res.data } });
    }
  };
}
export function getAllDistrictInfo(params) {
  return async (dispatch) => {
    const res = await req.getAllDistrictInfo(params);
    if (res) {
      dispatch({ type: actionTypes.MERGESET_SAVE, payload: { districtInfo: res.data || {} } });
    }
  };
}

export function fetchMarketAdd(params) {
  return async (dispatch) => {
    const res = await req.getMarketAdd(params);
    if (res) {
      dispatch({ type: actionTypes.MERGESET_SAVE, payload: { add: res } });
    }
  };
}
export function fetchUpdateMergeSet(params) {
  return async (dispatch) => {
    const res = await req.updateMergeSet(params);
    if (res) {
      dispatch({ type: actionTypes.MERGESET_SAVE, payload: { update: res } });
    }
  };
}
export function fetchStallListByRuleId(params) {
  return async (dispatch) => {
    const res = await req.stallListByRuleId(params);
    if (res) {
      dispatch({ type: actionTypes.MERGESET_SAVE, payload: { stallList: res.data } });
    }
  };
}
export function fetchAssociatedStall(params) {
  return async (dispatch) => {
    const res = await req.associatedStall(params);
    if (res) {
      dispatch({ type: actionTypes.MERGESET_SAVE, payload: { stallSet: res } });
    }
  };
}
export function fetchRemoveStallSet(params) {
  return async (dispatch) => {
    const res = await req.removeStallSet(params);
    if (res) {
      dispatch({ type: actionTypes.MERGESET_SAVE, payload: { stallSet: res } });
    }
  };
}
