import * as actionTypes from '../constant/sysMenu';

//初始状态
const initState = {
  data: {
    list: [],
  },
  // 左侧树结构对象存储
  trees: {
    list: [],
  },
  // 左侧点击选择了哪个上级菜单对象存储
  selectedMenu: {},
  // 菜单权限列表
  menuPermList: [],
};

//reducer
export function sysMenu(state = initState, action) {
  switch (action.type) {
    case actionTypes.SYS_MENU_SAVE:
      return { ...state, data: { list: action.payload.data } };
    case actionTypes.SYS_MENU_SAVE_TREE:
      return {
        ...state,
        trees: {
          list:
            action.payload.data === undefined || action.payload.data === null
              ? []
              : action.payload.data,
        },
      };
    case actionTypes.SYS_MENU_SAVE_SELECTED:
      return { ...state, selectedMenu: action.payload.data };
    case actionTypes.SYS_MENU_SAVE_PERM_LIST:
      return { ...state, menuPermList: action.payload.data };
    default:
      return state;
  }
}
