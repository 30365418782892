import React, { useState, forwardRef, useImperativeHandle } from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import axios from 'axios';
import { API_ADMIN } from '../../../../utils/urls';
import { generalParamsWithPToStr } from '../../../../utils/math';

import './index.less';

const getFile = async (url) => {
  try {
    const res = await axios.get(url, { responseType: 'text' });
    return res.data;
  } catch (error) {
    console.error('获取文件流失败:', error);
  }
};

const Editor = forwardRef((_, ref) => {
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null));

  useImperativeHandle(ref, () => ({
    get: () => editorState,
    set: async (data) => {
      const file = await getFile(data);
      setEditorState(BraftEditor.createEditorState(file));
    },
    clear: () => BraftEditor.createEditorState(null),
  }));

  const uploadFn = (param) => {
    const serverURL = `${API_ADMIN}file/uploadFile?${generalParamsWithPToStr('file/uploadFile', {
      fileType: 12,
      ...param,
    })}`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();

    const successFn = (response) => {
      // 成功上传后调用 param.success 并传入上传后的文件地址
      param.success({
        url: JSON.parse(xhr.responseText).data,
      });
    };

    const progressFn = (event) => {
      // 上传进度变化时调用 param.progress
      param.progress((event.loaded / event.total) * 100);
    };

    const errorFn = (response) => {
      // 上传发生错误时调用 param.error
      param.error({
        msg: 'unable to upload.',
      });
    };

    xhr.upload.addEventListener('progress', progressFn, false);
    xhr.addEventListener('load', successFn, false);
    xhr.addEventListener('error', errorFn, false);
    xhr.addEventListener('abort', errorFn, false);

    fd.append('file', param.file);
    xhr.open('POST', serverURL, true);
    xhr.send(fd);
  };

  return (
    <BraftEditor
      className=''
      placeholder='请输入内容！'
      excludeControls={['emoji']}
      style={{
        border: 'solid 1px rgb(0,0,0,0.2)',
        height: '360px',
      }}
      value={editorState}
      onChange={(editorState) => {
        setEditorState(editorState);
      }}
      media={{
        image: true,
        video: true,
        uploadFn,
      }}
    />
  );
});

export default Editor;
