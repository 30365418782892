import React, { Component, Fragment } from 'react';
import { withRouter, Link, Switch, Route } from 'react-router-dom';
import { Icon } from 'antd';

import logoImg from '../../common/images/caixm-logo.png';
import GlobalFooter from '../../component/global-footer/index';
import Login from './index/login';

import './style.less';

const links = [
  {
    key: 'helpprivacy',
    title: '帮助隐私条款',
    href: 'https://www.caixm.cn/agreement.html',
  },
];
const copyright = (
  <Fragment>
    Copyright <Icon type='copyright' /> {new Date().getFullYear()} @菜小秘
  </Fragment>
);

@withRouter
class LoginIndex extends Component {
  async componentDidMount() {
    document.body.classList.add('login-body');
  }

  componentWillUnmount() {
    document.body.classList.remove('login-body');
  }

  render() {
    return (
      <div className='login-index'>
        <div className='container'>
          <div className='content'>
            <div className='top'>
              <div className='header'>
                <Link to='/'>
                  <img alt='logo' className='logo' src={logoImg} />
                </Link>
              </div>
              <div className='desc'>菜小秘您的蔬菜批发档口管理小秘书</div>
            </div>
            <Switch>
              <Route path='/' component={Login}></Route>
            </Switch>
          </div>
        </div>
        <GlobalFooter links={links} copyright={copyright} type='login' />
      </div>
    );
  }
}

export default LoginIndex;
