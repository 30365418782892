import request from '../../utils/request';

//根据商行ID获取货主列表
export function getSupplierList(params) {
  return request({ url: 'supplier/list', params });
}

//根据商行批次列表
export function getBatchList(params) {
  return request({ url: 'batch/list', params });
}

//根据批次ID获取批次明细列表
export function getBatchItemList(params) {
  return request({ url: 'batchItem/list', params });
}

//根据商行ID,批次ID，批次明细ID获取库存操作记录列表
export function getBatchItemLogs(params) {
  return request({ url: 'batchItem/batchItemLogs', params });
}

//根据商行ID,批次ID获取销售明细列表
export function getBatchSaleList(params) {
  return request({ url: 'batch/batchSaleList', params });
}

//获取费用
export async function getProductById(stallId, productId) {
  return request({ url: `batchItem/${stallId}/${productId}`, method: 'get', headers: 'json' });
  // return request({ url: `https://rap2.caixm.cn/caixm/app/mock/data/1342`, method: "get", headers: 'json' });
}
//
/**
 * 修改费用
 * {
  "productId": 0,
  "unitPrice": 0,
  "packFee": 1,
  "tradeFee": 1,
  "billFee": 0,
  "stallId": 1
}
 * @param {*} params 
 */
export async function updateProductPrice(params) {
  return request({ url: 'batchItem/update', params, method: 'post', headers: 'json' });
}
