//商行管理---会员管理(查看详情--订单详情)
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Table, message } from 'antd';
import { PRODUCT_TYPE_LIST, Plat_Form_TYPE, Pay_TYPE_LIST } from '../../../utils/deviceType';
import { Yuan } from '../../../utils/math';

import { listVipPay } from '../../../state/action/memberManager';
import '../index.less';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@connect(
  (state) => {
    return { memberManager: state.memberManager };
  },
  { listVipPay },
)
@Form.create()
class OrderDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      stallId: '',
      defaultCurrent: 1,
    };
  }
  componentDidMount() {
    const { stallId } = this.props;
    this.setState({ stallId });
    this.listVipPay({ pageNo: 1, pageSize: 10, stallId: this.props.stallId }); //Table列表
    this.props.onRef(this);
  }

  //会员续费列表
  async listVipPay(value) {
    this.setState({ loading: true });
    const result = await this.props.listVipPay(value);
    if (result && result.code !== 200) {
      message.error(result.msg);
    }
    this.setState({ loading: false });
  }

  //分页
  pageSwitch = (obj) => {
    this.setState(
      {
        defaultCurrent: obj.current,
      },
      () => {
        this.listVipPay({
          stallId: this.state.stallId,
          pageSize: 10,
          pageNo: obj.current,
        });
      },
    );
  };
  getEndTimeNew = (value) => {
    const oneDay = 24 * 60 * 60 * 1000 * 1;
    return value - oneDay;
  };
  render() {
    const {
      memberManager: { vipPayList = {} },
    } = this.props;

    const { pageList, pageNo = 1, pageSize, totalSize = 1 } = vipPayList;
    // 表格的分页的设计
    const pagination = {
      pageSize: pageSize,
      total: totalSize,
      current: pageNo,
    };
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        render: (_, __, index) => {
          return ++index;
        },
      },
      {
        title: '订单ID',
        dataIndex: 'orderNo',
        key: 'orderNo',
        render: (text) => {
          return text ? text : '- -';
        },
      },
      {
        title: '订单创建日期',
        dataIndex: 'createTime',
        key: 'createTime',
        render: (text) => {
          return text ? moment(text).format(dateFormat) : '- -';
        },
      },
      {
        title: '会员服务',
        dataIndex: 'productType',
        key: 'productType',
        render: (text) => {
          return text ? PRODUCT_TYPE_LIST.get(text) : '- -';
        },
      },
      {
        title: '渠道类型',
        dataIndex: 'platformType',
        key: 'platformType',
        render: (text) => {
          return text ? Plat_Form_TYPE.get(text) : '- -';
        },
      },
      {
        title: '产品原价',
        dataIndex: 'productAmount',
        key: 'productAmount',
        render: (text) => {
          return text ? <span>{Yuan(text, 2)}</span> : '- -';
        },
      },
      {
        title: '支付金额',
        dataIndex: 'orderAmount',
        key: 'orderAmount',
        render: (text) => {
          return text ? <span>{Yuan(text, 2)}</span> : '- -';
        },
      },
      {
        title: '支付类型',
        dataIndex: 'payType',
        key: 'payType',
        render: (text) => {
          return text ? Pay_TYPE_LIST.get(text) : '- -';
        },
      },
      {
        title: '购买时长',
        dataIndex: 'productDuration',
        key: 'productDuration',
        render: (text) => {
          return text ? text + '天' : '- -';
        },
      },
      {
        title: '生效日期',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (text) => {
          return text ? moment(text).format(dateFormat) : '- -';
        },
      },
      {
        title: '截止日期',
        dataIndex: 'endTime',
        render: (_, record) => {
          let { endTime } = record;
          return endTime ? moment(endTime).format(dateFormat) : '- -';
        },
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
      },
    ];

    return (
      <div className='OrderDetail'>
        <Table
          rowKey={(_, index) => index}
          dataSource={pageList}
          columns={columns}
          pagination={pagination}
          onChange={this.pageSwitch}
          loading={this.state.loading}
          scroll={{ x: 'max-content' }}
        />
      </div>
    );
  }
}

export default OrderDetail;
