import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';

const UploadFile = (props) => {
  const [fileList, setFileList] = useState([]);

  const { max = 5, fileType = 12, action = 'file/uploadFile' } = props;

  useEffect(() => {
    if (props.url) {
      setFileList([{ uid: props.url, status: 'done', url: props.url }]);
    }
  }, [props.url]);

  const handleChange = (data) => {
    let { fileList } = data;
    const { response = {}, uid } = fileList[0] || {};
    if (data.fileList[0]?.response) {
      const url = response.data;
      fileList = [{ uid, status: 'done', url }];
    }
    setFileList(fileList);
    props.onChange && props.onChange(fileList[0]?.url);
  };

  const beforeUpload = (file) => {
    const checkSize = file.size / 1024 / 1024 < max;
    if (!checkSize) {
      message.error(`单个文件不能超过${max}M!`);
    }
    return checkSize;
  };

  const uploadProps = () => {
    const params = { fileList, fileType };
    const param = {
      action: `${API_ADMIN}${action}?${generalParamsWithPToStr(action, params)}`,
      listType: 'picture-card',
      showUploadList: { showPreviewIcon: false },
      accept: '.png,.jpg,.jpeg',
      fileList,
      onChange: handleChange,
    };
    if (max) {
      return {
        ...param,
        beforeUpload: beforeUpload,
      };
    }
    return param;
  };

  return <Upload {...uploadProps({})}>{fileList.length ? null : <PlusOutlined />}</Upload>;
};

export default UploadFile;
