import request from '../../utils/request';

// 获取列表
export async function getListCaixmPhone(params) {
  return request({ url: 'channelRegister/page', params });
}
// 获取每日新增统计的列表
export async function getDailyAddStall(params) {
  return request({ url: 'dailyIncreasedStatistics/queryIncreasedStatistics', params });
}
// 获取每日交易统计的列表
export async function getDailyBussinessStall(params) {
  return request({ url: 'dailyTradingStatistics/queryTradingStatistics', params });
}
