import React, { useEffect, useState, useCallback, useRef } from 'react';
import { message } from 'antd';

import getColumns from './getColumns';
import List from './searchList';
import AddForm from './add';

import { actionList, colsCfg, tableHeader } from './configs';

import {
  getDictDataPay,
  deleteDictDataPay,
  addDictDataPay,
  updateDictDataPay,
  getSplitAccountRule,
} from './apis';

import { getCashierList } from '../../../../../state/request/basic';

import './index.less';

const Index = ({ stallId }) => {
  const formRef = useRef();
  const [item, setItem] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [cashier, setCashier] = useState([]);
  const [ruleList, setRuleList] = useState([]);

  const update = useCallback(async () => {
    setIsPending(true);
    const result = await getDictDataPay({ stallId });
    const ruleResult = await getSplitAccountRule();
    setIsPending(false);
    setList(result.data);
    setRuleList(ruleResult.data);
  }, []);

  useEffect(() => {
    update();
    const getCashier = async () => {
      const { code, data } = await getCashierList({ stallId });
      if (code === 200) {
        setCashier(data);
      }
    };
    getCashier();
  }, []);

  const handleEdit = (item) => {
    setVisible(true);
    setItem(item);
  };
  const handleDelete = async ({ dictTypeCode, cashierUid }) => {
    const { code, msg } = await deleteDictDataPay({ stallId, dictTypeCode, cashierUid });
    if (code === 200) {
      message.success(msg);
      update();
    }
  };

  const handleSubmit = (values) => {
    const handler = item ? updateDictDataPay : addDictDataPay;
    const { form } = formRef.current;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      let params = { stallId, ...item, ...values };
      if (item) {
        params.oldCashierUid = item.cashierUid;
      }
      if (params.payMethod !== '2') {
        params.ruleId = '';
      }
      setConfirmLoading(true);
      const { code, msg } = await handler(params);
      setConfirmLoading(false);
      if (code === 200) {
        message.success(msg);
        update();
        setVisible(false);
        form.resetFields();
      }
    });
  };

  const colActions = {
    handleEdit,
    handleDelete,
  };
  const topActions = {
    handleEdit,
  };
  const tableProps = {
    columns: getColumns(tableHeader, colsCfg(colActions, ruleList)),
    list,
    isPending,
    actionList: actionList(topActions),
  };

  return (
    <div className='config-list'>
      <List {...tableProps} />
      <AddForm
        item={item}
        cashier={cashier}
        title={item ? '编辑柜台' : '添加柜台'}
        visible={visible}
        confirmLoading={confirmLoading}
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
        wrappedComponentRef={(ref) => (formRef.current = ref)}
        ruleList={ruleList}
      />
    </div>
  );
};

export default Index;
