// 查看详情-合同管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  DatePicker,
  Form,
  Button,
  Input,
  message,
  Table,
  Modal,
  Row,
  Col,
  Select,
  Upload,
  Divider,
  Icon,
  Tooltip,
} from 'antd';
import {
  getListPacts,
  addPact,
  updatePact,
  enablePact,
  stallDetails,
} from '../../../../state/action/stall';
import { getAllSale } from '../../../../state/action/salesman';
import { CONTRACT_TYPES, STALL_TYPES, STALL_TYPE } from '../../../../utils/deviceType';
import { API_ADMIN } from '../../../../utils/urls';
import { strSplit } from '../../../../utils/utils';
import { generalParamsWithPToStr } from '../../../../utils/math';
import pdfPic from '../../../../common/images/icon/pdf.png';

import './device.less';
const { confirm } = Modal;

const FormItem = Form.Item;
const { TextArea } = Input;

const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const pageSize = 10;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

@connect(
  (state) => {
    return { stall: state.stall, salesman: state.salesman };
  },
  { getListPacts, addPact, updatePact, enablePact, getAllSale, stallDetails },
)
@Form.create()
class ContractManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      record: {},
      content: '',
      defaultCurrent: 1,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      fileVisible: false,
      selectedPactType: -1, //选择的合同类型
    };
  }

  componentDidMount() {
    const { defaultCurrent } = this.state;
    this.getListPacts({
      stallId: this.props.stallId,
      pageNo: defaultCurrent,
      pageSize,
    });
    this.props.getAllSale(); //获取所有的销售人员
  }

  async getListPacts(params) {
    this.setState({ loading: true });
    await this.props.getListPacts(params);
    this.setState({ loading: false });
  }

  pageSwitch = (obj) => {
    this.setState(
      {
        defaultCurrent: obj.current,
      },
      () => {
        this.getListPacts({
          stallId: this.props.stallId,
          pageNo: obj.current,
          pageSize,
        });
      },
    );
  };
  //点击新增和编辑Modal弹框
  showModal = (record, visible, content) => {
    let fileArr = [];
    if (record.bdImageResultList && record.bdImageResultList.length > 0) {
      //回显合同附件
      for (let i = 0; i < record.bdImageResultList.length; i++) {
        const arr = record.bdImageResultList[i].fileName.split('.');
        if (arr[arr.length - 1] === 'pdf') {
          fileArr.push({
            uid: i,
            name: record.bdImageResultList[i].fileName,
            status: 'done',
            url1: record.bdImageResultList[i].fileUrl,
          });
        } else {
          fileArr.push({
            uid: i,
            name: record.bdImageResultList[i].fileName,
            status: 'done',
            url: record.bdImageResultList[i].fileUrl,
          });
        }
      }
    }
    this.setState(
      {
        record,
        visible,
        content,
        fileList: fileArr,
        selectedPactType: this.isAddPact(record) ? -1 : record.pactType,
      },
      () => {
        // 新增合同 需要进行一次默认选中签约bd
        if (this.isAddPact(record)) {
          this.handleChangeBySignBD(this.props.signSaleId);
        }
      },
    );
  };

  //判断时新增还是修改合同
  isAddPact = (record) => {
    if (JSON.stringify(record) === '{}') {
      return true;
    } else {
      return false;
    }
  };

  // 新增合同, 修改合同
  addPact = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, value) => {
      if (!err) {
        const { fileList, record } = this.state;
        let fileArr = [];
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            const arr = fileList[i].name.split('.');
            if (arr[arr.length - 1] === 'pdf') {
              fileArr.push({
                fileName: fileList[i].name,
                fileUrl: fileList[i].url1,
              });
            } else {
              fileArr.push({
                fileName: fileList[i].name,
                fileUrl: fileList[i].url,
              });
            }
          }
        }
        // 当选择“活动赠送”时，合同编号禁止输入，变为系统自动生成；签约日期禁止输入，变为系统自动生成
        const selectedPactType3 = value.pactType === 3;
        if (JSON.stringify(record) === '{}') {
          //新增
          const params = {
            stallId: this.props.stallId,
            pactType: value.pactType,
            saleId: value.saleId,
            signUser: value.signUser,
            signPhone: value.signPhone,
            pactStartTime: moment(value.pactStartTime.format('YYYY-MM-DD')).valueOf(),
            remark: value.remark ? value.remark : '',
            validity: value.validity,
            payTime: value.payTime ? moment(value.payTime.format('YYYY-MM-DD')).valueOf() : null,
            pactAmount: parseFloat(value.pactAmount),
            payAmount: value.payAmount ? parseFloat(value.payAmount) : null,
            bdImageRequestList: fileArr,
          };
          if (selectedPactType3) {
            params.pactNo = '';
          } else {
            params.pactNo = value.pactNo;
          }
          await this.addPactFun(params);
        } else {
          const params = {
            id: record.id,
            stallId: this.props.stallId,
            pactNo: value.pactNo,
            pactType: value.pactType,
            saleId: value.saleId,
            signUser: value.signUser,
            signPhone: value.signPhone,
            pactStartTime: moment(value.pactStartTime.format('YYYY-MM-DD')).valueOf(),
            validity: value.validity,
            payTime: value.payTime ? moment(value.payTime.format('YYYY-MM-DD')).valueOf() : null,
            pactAmount: parseFloat(value.pactAmount),
            payAmount: value.payAmount ? parseFloat(value.payAmount) : null,
            remark: value.remark ? value.remark : '',
            bdImageRequestList: fileArr,
          };
          await this.updatePactFun(params);
        }
        this.props.refreshStall(); //刷新商行信息
      }
    });
  };

  async addPactFun(params) {
    const result = await this.props.addPact(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('新增成功');
      // 如果这个商行是新开商行，需要刷新这个商行详情信息
      if (this.props.stallType === STALL_TYPES.get(1)) {
        await this.props.stallDetails({ id: this.props.stallId });
      }
      const { defaultCurrent } = this.state;
      await this.getListPacts({
        //刷新列表
        stallId: this.props.stallId,
        pageNo: defaultCurrent,
        pageSize,
      });
    }
  }

  async updatePactFun(params) {
    const result = await this.props.updatePact(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('编辑成功');
      const { defaultCurrent } = this.state;
      await this.getListPacts({
        //刷新列表
        stallId: this.props.stallId,
        pageNo: defaultCurrent,
        pageSize,
      });
    } else {
      message.error(result.msg);
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ file, fileList }) => {
    const arr = file.name.split('.');
    const limitSize = file.size / 1024 / 1024 < 20;
    if (fileList && fileList.length > 0) {
      if (
        arr[arr.length - 1] === 'pdf' ||
        arr[arr.length - 1] === 'png' ||
        arr[arr.length - 1] === 'jpg'
      ) {
        if (file.size && !limitSize) {
          message.error('文件大小不能超过20M');
          return;
        }
        if (file.name.length > 32) {
          message.error('文件名称长度不能超过32位');
          return;
        }
        fileList.forEach((imgItem) => {
          if (imgItem && imgItem.status === 'uploading') {
            imgItem.thumbUrl = '';
          } else if (
            imgItem &&
            imgItem.status === 'done' &&
            imgItem.response &&
            imgItem.response.data
          ) {
            imgItem.thumbUrl = '';
            const arr = imgItem.name.split('.');
            if (arr[arr.length - 1] === 'pdf') {
              imgItem.url1 = imgItem.response.data[imgItem.name];
            } else {
              imgItem.url = imgItem.response.data[imgItem.name];
            }
          } else if (
            imgItem &&
            imgItem.status === 'error' &&
            imgItem.response &&
            imgItem.response.code !== 200
          ) {
            message.error(imgItem.response.msg);
            return;
          }
        });
        this.setState({ fileList });
      } else {
        message.error('仅支持上传PNG/JPG/PDF格式文件');
      }
    }
  };

  handleRemove = (file) => {
    const { fileList } = this.state;
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    this.setState({
      fileList: newFileList,
    });
  };

  // 查看附件
  showFile = (record, fileVisible) => {
    this.setState({
      record,
      fileVisible,
    });
  };

  // 手动失效
  enablePact = (record) => {
    const that = this;
    confirm({
      title: '提示',
      content: '确定手动失效该合同么？',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        that.enablePactFun({ id: record.id });
      },
      onCancel() {},
    });
  };

  async enablePactFun(params) {
    const result = await this.props.enablePact(params);
    if (result.code === 200) {
      message.success('该合同已失效');
      const { defaultCurrent } = this.state;
      await this.getListPacts({
        //刷新列表
        stallId: this.props.stallId,
        pageNo: defaultCurrent,
        pageSize,
      });
      this.props.refreshStall(); //刷新商行信息
    } else {
      message.error(result.msg);
    }
  }

  disabledDate = (current) => {
    return current && current > moment();
  };

  //选择签约BD
  handleChangeBySignBD = (value) => {
    if (value !== undefined) {
      const {
        salesman: { getAllSale = [] },
      } = this.props;
      const arr = getAllSale.filter((v) => v.id === value);
      const obj = arr && arr.length > 0 ? arr[0] : {};
      this.props.form.setFieldsValue({ firstDepartment: obj.firstDepartment });
      this.props.form.setFieldsValue({ saleId: value });
      this.props.form.setFieldsValue({ pioneerDivision: obj.pioneerDivision });
    }
  };

  //合同类型改变
  changePactType = (v) => {
    this.setState({
      selectedPactType: v,
    });
    if (this.isAddPact(this.state.record)) {
      this.props.form.setFieldsValue({ pactNo: '' });
      this.props.form.setFieldsValue({ pactStartTime: '' });
    } else {
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      record,
      visible,
      content,
      loading,
      defaultCurrent,
      fileList,
      fileVisible,
      selectedPactType,
    } = this.state;
    const {
      stall: { pactsList, stallDetails = {} },
      salesman: { getAllSale = [] },
    } = this.props;
    const dataSource = pactsList && pactsList.pageList ? pactsList.pageList : [];
    const urlList = record.bdImageResultList ? record.bdImageResultList : [];
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        width: 80,
        render: (_, __, index) => {
          return index + 1;
        },
      },
      {
        title: '合同编号',
        dataIndex: 'pactNo',
        width: 100,
      },
      {
        title: '合同类型',
        dataIndex: 'pactType',
        width: 100,
        render: (text) => {
          return CONTRACT_TYPES.get(text) || '--';
        },
      },
      {
        title: '商行版本',
        dataIndex: 'systemType',
        width: 100,
        render: (text) => {
          return STALL_TYPE.get(text) || '- -';
        },
      },
      {
        title: '签约日期',
        dataIndex: 'pactStartTime',
        width: 120,
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD') : '--';
        },
      },
      {
        title: '有效期',
        dataIndex: 'validity',
        width: 80,
        render: (text, record) => {
          const { pactType } = record;
          if (pactType === 3) {
            return text ? text + '天' : '--';
          } else {
            return text ? text + '年' : '--';
          }
        },
      },
      {
        title: '业务线',
        dataIndex: 'firstDepartment',
        width: 80,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '所属部门',
        dataIndex: 'pioneerDivision',
        width: 100,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '签约人',
        dataIndex: 'signUser',
        width: 100,
      },
      {
        title: '签约BD',
        dataIndex: 'signBd',
        width: 100,
      },
      {
        title: '是否生效',
        dataIndex: 'enable',
        width: 100,
        render: (text) => {
          return text === 0 ? (
            <span style={{ color: '#EC634A' }}>已失效</span>
          ) : text === 1 ? (
            '生效中'
          ) : (
            '--'
          );
        },
      },
      {
        title: '合同金额',
        dataIndex: 'pactAmount',
        width: 100,
      },
      {
        title: '回款金额',
        dataIndex: 'payAmount',
        width: 100,
        render: (text) => {
          return text || text === 0 ? text : '--';
        },
      },
      {
        title: '回款日期',
        dataIndex: 'payTime',
        width: 120,
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD') : '--';
        },
      },
      {
        title: '合同备注',
        dataIndex: 'remark',
        width: 300,
        render: (text) => {
          const textTip = <span>{text}</span>;
          return text ? (
            <Tooltip placement='rightTop' title={textTip}>
              <span>{strSplit(text, 15)}</span>
            </Tooltip>
          ) : (
            '--'
          );
        },
      },
      {
        title: '最后一次操作人',
        dataIndex: 'updateName',
        width: 160,
        render: (t) => t || '--',
      },
      {
        title: '操作',
        dataIndex: 'option',
        width: 250,
        render: (_, record) => {
          const bdImageResultList = record.bdImageResultList && record.bdImageResultList.length > 0;
          return (
            <span>
              {bdImageResultList ? (
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    this.showFile(record, true);
                  }}
                >
                  查看附件
                </a>
              ) : (
                <span style={{ color: '#E3E3E3' }}>查看附件</span>
              )}
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showModal(record, true, '编辑合同');
                }}
              >
                编辑
              </a>
              <Divider type='vertical' />
              {record.enable === 0 ? (
                <span style={{ color: '#E3E3E3' }}>手动失效</span>
              ) : (
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    this.enablePact(record);
                  }}
                  style={{ color: '#EC634A' }}
                >
                  手动失效
                </a>
              )}
            </span>
          );
        },
      },
    ];
    const pagination = {
      pageSize: 10,
      total: pactsList ? pactsList.totalSize : 0,
      current: defaultCurrent,
    };
    const params = { fileList, fileType: 1 };
    const props = {
      action: `${API_ADMIN}file/uploadBatchFile?${generalParamsWithPToStr(
        'file/uploadBatchFile',
        params,
      )}`,
      listType: 'picture-card',
      fileList: [...fileList],
      showUploadList: { showPreviewIcon: false },
      accept: '.png,.jpg,.pdf',
      multiple: true,
      onPreview: this.handlePreview,
      onChange: this.handleChange,
      onRemove: this.handleRemove,
    };
    const yearsArr = [];
    for (let i = 1; i <= 100; i++) {
      yearsArr.push(i);
    }
    // 当选择“活动赠送”时，合同编号禁止输入，变为系统自动生成；签约日期禁止输入，变为系统自动生成
    const selectedPactType3 = selectedPactType === 3;
    return (
      <div className='contractManagement'>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              type='primary'
              icon='plus'
              onClick={() => {
                this.showModal({}, true, '新增合同');
              }}
            >
              新增
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(_, index) => index}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 1700 }}
          pagination={pagination}
          onChange={this.pageSwitch.bind(this)}
          loading={loading}
        />
        {visible && (
          <Modal
            title={content}
            visible={visible}
            onCancel={() => this.showModal({}, false, '')}
            footer={null}
            className={'deviceModalStyle'}
            width='750px'
            style={{ maxHeight: 700 }}
          >
            <Form layout='inline' onSubmit={this.addPact}>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='合同编号'>
                    {getFieldDecorator('pactNo', {
                      initialValue: record.pactNo || '',
                      rules: [
                        {
                          required: selectedPactType3 ? false : true,
                          message: '请输入合同编号',
                        },
                      ],
                    })(
                      <Input
                        placeholder='请输入合同编号'
                        allowClear
                        disabled={selectedPactType3 ? true : false}
                      />,
                    )}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='合同类型'>
                    {getFieldDecorator('pactType', {
                      initialValue: record.pactType || '',
                      rules: [{ required: true, message: '请选择合同类型' }],
                    })(
                      <Select
                        placeholder='请选择合同类型'
                        allowClear
                        onChange={this.changePactType}
                      >
                        {[...CONTRACT_TYPES].map(([k, v]) => (
                          <Select.Option value={k} key={k}>
                            {v}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='签约BD'>
                    {getFieldDecorator('saleId', {
                      initialValue: record.saleId,
                      rules: [{ required: true, message: '请输入签约BD人员名称' }],
                    })(
                      <Select
                        placeholder='请选择签约BD'
                        allowClear
                        onChange={this.handleChangeBySignBD}
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {getAllSale.map((v) => (
                          <Select.Option value={v.id} key={v.id}>
                            {v.name}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='业务线'>
                    {getFieldDecorator('firstDepartment', {
                      initialValue: record.firstDepartment || '',
                      rules: [{ required: true, message: '请输入业务线' }],
                    })(<Input placeholder='' disabled />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='所属部门'>
                    {getFieldDecorator('pioneerDivision', {
                      initialValue: record.pioneerDivision || '',
                      rules: [{ required: true, message: '请输入所属部门' }],
                    })(<Input placeholder='' disabled />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='签约人'>
                    {getFieldDecorator('signUser', {
                      initialValue: (record.id ? record.signUser : stallDetails?.personName) || '',
                      rules: [{ required: true, message: '请输入签约人' }],
                    })(<Input placeholder='请输入商行相应签约人' allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='联系方式'>
                    {getFieldDecorator('signPhone', {
                      initialValue:
                        (record.id ? record.signPhone : stallDetails?.personPhone) || '',
                      rules: [
                        {
                          required: true,
                          validator: (_, value, callback) => {
                            if (value) {
                              if (value.length > 11 || !/^[1][0-9]{10}$/.test(value)) {
                                callback('请输入正确的联系方式');
                              }
                            } else {
                              callback('请输入签约人联系方式');
                            }
                            callback();
                          },
                        },
                      ],
                    })(<Input placeholder='请输入签约人联系方式' maxLength={11} allowClear />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='签约日期'>
                    {getFieldDecorator('pactStartTime', {
                      initialValue: record.pactStartTime ? moment(record.pactStartTime) : null,
                      rules: [{ required: true, message: '请选择签约日期' }],
                    })(<DatePicker placeholder='请选择签约日期' style={{ width: 210 }} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='有效期'>
                    {getFieldDecorator('validity', {
                      initialValue: record.validity || '',
                      rules: [{ required: true, message: '请输入有效期' }],
                    })(
                      <Select
                        placeholder='请选择有效期'
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {selectedPactType3
                          ? Array(365)
                              .fill(0)
                              .map((item, index) => (
                                <Select.Option value={index + 1} key={index}>{`${
                                  index + 1
                                }天`}</Select.Option>
                              ))
                          : yearsArr.map((item, index) => (
                              <Select.Option value={item} key={index}>{`${item}年`}</Select.Option>
                            ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem {...formItemLayout} label='合同金额'>
                    {getFieldDecorator('pactAmount', {
                      initialValue: record.pactAmount,
                      rules: [
                        {
                          required: true,
                          validator: (rule, value, callback) => {
                            if (value || value === '0' || value === 0) {
                              if (!/^\d+(\.\d{0,2})?$/.test(value)) {
                                callback('合同金额最小为0，且最多两位小数');
                              }
                            } else {
                              callback('请输入合同金额');
                            }
                            callback();
                          },
                        },
                      ],
                    })(<Input placeholder='请输入合同金额' allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='回款金额'>
                    {getFieldDecorator('payAmount', {
                      initialValue:
                        record.payAmount || record.payAmount === 0 ? record.payAmount : '',
                      rules: [
                        {
                          required: false,
                          validator: (_, value, callback) => {
                            if (value) {
                              if (!/^\d+(\.\d{0,2})?$/.test(value)) {
                                callback('回款金额最小为0，且最多两位小数');
                              }
                            }
                            callback();
                          },
                        },
                      ],
                    })(<Input placeholder='请输入回款金额' allowClear />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='回款日期'>
                    {getFieldDecorator('payTime', {
                      initialValue: record.payTime ? moment(record.payTime) : null,
                    })(
                      <DatePicker
                        placeholder='请选择回款日期'
                        style={{ width: 210 }}
                        disabledDate={this.disabledDate}
                      />,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem {...formItemLayout} label='合同备注'>
                    {getFieldDecorator('remark', {
                      initialValue: record.remark,
                    })(
                      <TextArea rows={4} placeholder='请输入合同备注' allowClear maxLength={225} />,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem {...formItemLayout} label='合同附件'>
                    仅支持上传PNG/JPG/PDF格式文件
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={3} />
                <Col span={21}>
                  <FormItem {...formItemLayout} label=''>
                    <Upload {...props}>
                      <div>
                        <Icon type='plus' />
                        <div className='ant-upload-text'>上传附件</div>
                      </div>
                    </Upload>
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button onClick={() => this.showModal({}, false, '')}>关闭</Button>
                <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        )}

        {fileVisible && (
          <Modal
            title={'查看附件'}
            visible={fileVisible}
            onCancel={() => this.showFile({}, false)}
            footer={null}
            className={'deviceModalStyle'}
            width='600px'
          >
            <div style={{ maxHeight: 600, overflow: 'auto' }}>
              {urlList.map((item, index) => {
                const arr = item.fileName.split('.');
                if (arr[arr.length - 1] === 'pdf') {
                  return (
                    <div
                      style={{ width: '100%', padding: '10px 0', cursor: 'pointer' }}
                      key={index}
                    >
                      <img
                        alt={item.fileName}
                        src={pdfPic}
                        title='点击查看原文件'
                        onClick={() => {
                          window.open(item.fileUrl);
                        }}
                      />
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(item.fileUrl);
                        }}
                      >
                        {item.fileName}
                      </a>
                    </div>
                  );
                } else {
                  return (
                    <img
                      key={index}
                      alt={item.fileName}
                      style={{ width: '100%', padding: '10px 0', cursor: 'pointer' }}
                      src={item.fileUrl}
                      title='点击查看原图'
                      onClick={() => {
                        window.open(item.fileUrl);
                      }}
                    />
                  );
                }
              })}
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default ContractManagement;
