/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-26 16:38:34
 * @LastEditors: lijing
 * @LastEditTime: 2022-11-11 10:00:00
 */

import request from '../../utils/request';
/**
 * 支付进件列表查询接口
 * @param {objcet} params
 * @returns
 */
export async function fetchBankPayment(params) {
  return request({ url: `payEntryDataResource/list`, headers: 'json', method: 'post', params });
}
export async function exportBankPayment(params) {
  return request({
    url: `payEntryDataResource/list/download`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}
