//商行管理---网店信息维护
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { Form, Card, Row, Col, Input, Button, Table, Divider } from 'antd';
import AddOnlineSore from './modalPage/addOnlineSore';

import './index.less';
import GoodsClassification from './modalPage/goodsClassification';
import GoodsManage from './modalPage/goodsManage';
import { URL_STORE } from '../../utils/urls';

import { getStoreList } from '../../state/action/onlineStore';
import { getEncryptStr } from '../../state/action/basic';

const FormItem = Form.Item;

const PAGESIZE = 10;

@connect(
  (state) => {
    return { onlineStore: state.onlineStore };
  },
  { getStoreList, getEncryptStr },
)
@Form.create()
class OnlineStoreManage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addVisible: false,
      goodsVisible: false,
      goodsManaVisible: false,
      content: '',
      record: {},
    };
  }

  componentDidMount() {
    this.getStoreList({ storeName: '', pageNo: 1, pageSize: PAGESIZE });
  }

  async getStoreList(params) {
    this.setState({ loading: true });
    await this.props.getStoreList(params);
    this.setState({ loading: false });
  }
  //分页
  setPageChange = (e) => {
    const { searchStore } = this.props.onlineStore;
    this.props.getStoreList({
      ...searchStore,
      pageNo: e.current,
      pageSize: e.pageSize,
    });
  };

  //查询
  query = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.getStoreList({
        storeName: this.isUndefined(values.storeName),
        pageNo: 1,
        pageSize: PAGESIZE,
      });
    });
  };

  //重置
  reset = () => {
    this.props.form.resetFields();
    this.getStoreList({ storeName: '', pageNo: 1, pageSize: PAGESIZE });
  };

  isUndefined = (value) => {
    return value || null;
  };

  //新增、编辑、货品分类
  handleModal = (visible, content, record, flag) => {
    if (flag === 1 || flag === 2) {
      this.setState({
        addVisible: visible,
        content,
        record,
      });
    } else if (flag === 3) {
      this.setState({
        goodsVisible: visible,
        content,
        record,
      });
    } else if (flag === 4) {
      this.setState({
        goodsManaVisible: visible,
        content,
        record,
      });
    }
  };

  handleClose = (flag) => {
    if (flag != null && !flag) {
      //关闭刷新
      const { searchStore } = this.props.onlineStore;
      this.getStoreList({ ...searchStore });
    }
    this.setState({
      addVisible: false,
    });
  };
  handleClose1 = () => {
    this.setState({
      goodsVisible: false,
    });
  };
  handleClose2 = () => {
    this.setState({
      goodsManaVisible: false,
    });
  };
  //预览
  preview = async (record) => {
    let obj = {
      stallId: record.stallId,
    };
    const jsonStr = JSON.stringify(obj);
    let params = {
      p: {
        content: jsonStr,
      },
    };
    const res = await this.props.getEncryptStr(params);
    const { code, data } = res || {};
    if (code === 200) {
      window.open(
        `${URL_STORE}?sn=${data}&title=${encodeURIComponent(
          '同心战疫，网上商铺也精彩',
        )}&content=${encodeURIComponent('共克时艰，稳定供应，快来我的网上商铺看看吧！')}`,
      );
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
    const { addVisible, goodsVisible, goodsManaVisible, content, record } = this.state;
    const {
      storeList: { pageList = [], pageNo = 1, totalSize = 1 },
    } = this.props.onlineStore;

    const data = pageList;
    const pagination = {
      pageSize: PAGESIZE,
      total: totalSize,
      current: pageNo,
    };

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        render: (_, __, index) => {
          return ++index;
        },
      },
      {
        title: '网店名称',
        dataIndex: 'storeName',
        key: 'storeName',
        width: 120,
      },
      {
        title: '档口位置',
        dataIndex: 'address',
        key: 'address',
        width: 120,
      },
      {
        title: '主营业务',
        dataIndex: 'mainBusiness',
        key: 'mainBusiness',
        width: 120,
      },
      {
        title: '访问量',
        dataIndex: 'scan',
        key: 'scan',
        width: 120,
      },
      {
        title: '总点赞数',
        dataIndex: 'likeNum',
        key: 'likeNum',
        width: 120,
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 200,
        render: (_, record) => {
          return (
            <div>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.handleModal(true, '编辑网店', record, 2);
                }}
              >
                编辑
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.handleModal(true, '货品分类', record, 3);
                }}
              >
                货品分类
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.handleModal(true, '货品维护', record, 4);
                }}
              >
                货品维护
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.preview(record);
                }}
              >
                预览
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <div className='onlineStoreManage'>
        <PageHeaderLayout>
          <Card className='listCard' bordered={false}>
            <Form layout='inline' onSubmit={this.query}>
              <Row gutter={12}>
                <Col {...layout}>
                  <FormItem label='网店名称'>
                    {getFieldDecorator(
                      'storeName',
                      {},
                    )(<Input placeholder='请输入网店名称' allowClear />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <Button type='primary' htmlType='submit'>
                    查询
                  </Button>
                  <Button onClick={this.reset}>重置</Button>
                  <Button type='primary' onClick={() => this.handleModal(true, '新增网店', {}, 1)}>
                    新增
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              rowKey={(_, index) => index}
              dataSource={data}
              columns={columns}
              pagination={pagination}
              onChange={this.setPageChange.bind(this)}
              loading={this.state.loading}
              scroll={{ x: 1300 }}
            />
          </Card>
          {addVisible && (
            <AddOnlineSore
              visible={addVisible}
              content={content}
              record={record}
              handleClose={this.handleClose}
            />
          )}
          {goodsVisible && (
            <GoodsClassification
              visible={goodsVisible}
              content={content}
              record={record}
              handleClose={this.handleClose1}
            />
          )}
          {goodsManaVisible && (
            <GoodsManage
              visible={goodsManaVisible}
              content={content}
              record={record}
              handleClose={this.handleClose2}
            />
          )}
        </PageHeaderLayout>
      </div>
    );
  }
}
export default OnlineStoreManage;
