import React, { useState, useEffect, useCallback } from 'react';
import { Form, Table, Input, Select, Button, Space, Checkbox, TreeSelect, message } from 'antd-v5';
import { DatePicker } from 'antd';
import moment from 'moment';

import PageHeaderLayout from '@/component/page-header-layout';

import { marketList } from '@/state/request/stall';
import { getAllDistrictInfo } from '@/state/request/mergeSet';
import { getDeliveryStatList, getDeliveryStatDownload } from '@/state/request/serve';

import { STALL_TYPE } from '@/utils/deviceType';
import { getStartTime, getEndTime } from '@/utils//timer';
import { exportFile } from '@/utils/utils';

import './index.less';

const { RangePicker } = DatePicker;

const initQuery = {
  pageNo: 1,
  pageSize: 10,
  typeList: [2, 5, 6, 7, 8],
  startTime: moment().subtract(13, 'months').valueOf(),
  endTime: moment().valueOf(),
};

const NewSign = () => {
  const [form] = Form.useForm();
  const [marketData, setMarketData] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [list, setList] = useState({});
  const [query, setQuery] = useState(initQuery);

  const getData = useCallback(async () => {
    const params = {
      ...query,
      startTime: query.startTime && getStartTime(moment(query.startTime)),
      endTime: query.endTime && getEndTime(moment(query.endTime)),
    };
    const res = await getDeliveryStatList(params);
    if (res?.code === 200) {
      setList(res.data);
    }
  }, [query]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const getMarketData = async () => {
      const res = await marketList();
      if (res?.code === 200) {
        setMarketData(res.data);
      }
    };
    const getMap = async () => {
      const { code, data = {} } = await getAllDistrictInfo();
      if (code === 200) {
        const { mapping = {} } = data;
        const { origins } = mapping;
        //去掉区
        if (origins instanceof Array) {
          for (const iterator of origins) {
            for (const iteratorChildren of iterator.children) {
              delete iteratorChildren.children;
            }
          }
        }
        setOrigins(origins);
      }
    };
    getMarketData();
    getMap();
  }, []);

  // 提交
  const onFinish = (values) => {
    const { times = [], ...rest } = values;
    const [st, et] = times;
    const startTime = st;
    const endTime = et;
    setQuery({ ...rest, startTime, endTime, pageNo: 1, pageSize: 10 });
  };

  // 重置
  const onReset = () => {
    form.resetFields();
    setQuery(initQuery);
  };

  // 导出
  const exportData = async () => {
    const { times = [], ...rest } = form.getFieldsValue();
    const [st, et] = times;
    let startTime = st;
    let endTime = et;
    if (!st || !et) {
      endTime = moment();
      startTime = moment().subtract(13, 'months');
    }
    if (moment(moment(et)).diff(moment(st), 'months', true) > 13) {
      message.info('导出时间不得超过13个月');
      return;
    }
    const params = {
      ...rest,
      startTime: getStartTime(moment(startTime)),
      endTime: getEndTime(moment(endTime)),
    };
    const response = await getDeliveryStatDownload({ ...params });
    exportFile(response, '新签交付统计表.xlsx');
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const pagination = {
    hideOnSinglePage: true,
    showSizeChanger: false,
    size: 'default',
    pageSize: 10,
    current: list.pageNo || 1,
    total: list.totalSize,
    onChange: (page, pageSize) => {
      setQuery({ ...query, pageNo: page, pageSize });
    },
  };

  const columns = [
    {
      title: '业务线',
      dataIndex: 'firstDepartment',
      render: (t) => t || '--',
    },
    {
      title: '所属部门',
      dataIndex: 'pioneerDivision',
      render: (t) => t || '--',
    },
    {
      title: '签约BD',
      dataIndex: 'saleName',
      render: (t) => t || '--',
    },
    {
      title: '商行ID',
      dataIndex: 'stallId',
      render: (t) => t || '--',
    },
    {
      title: '商行名称',
      dataIndex: 'stallName',
      render: (t) => t || '--',
    },
    {
      title: '所在市场',
      dataIndex: 'marketName',
    },
    {
      title: '所在省/市',
      dataIndex: 'district',
    },
    {
      title: '商行版本',
      dataIndex: 'typeDesc',
    },
    {
      title: '商行创建时间',
      dataIndex: 'createTime',
      render: (t) => (t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'),
    },
    {
      title: '创建30天内使用情况',
      dataIndex: 'action',
      children: [
        {
          title: '开单天数',
          dataIndex: 'firstOrderDays',
          align: 'center',
        },
        {
          title: '开单量',
          dataIndex: 'firstOrderCount',
          align: 'center',
        },
        {
          title: '使用状态',
          dataIndex: 'firstUseStatus',
          align: 'center',
        },
      ],
    },
    {
      title: '近30天使用情况',
      dataIndex: 'action',
      children: [
        {
          title: '开单天数',
          dataIndex: 'lastOrderDays',
          align: 'center',
        },
        {
          title: '开单量',
          dataIndex: 'lastOrderCount',
          align: 'center',
        },
        {
          title: '使用状态',
          dataIndex: 'lastUseStatus',
          align: 'center',
        },
      ],
    },
  ];

  const ExtraContent = () => {
    return (
      <Form
        form={form}
        layout='inline'
        onFinish={onFinish}
        className='header'
        initialValues={{
          typeList: initQuery.typeList,
          times: [moment(initQuery.startTime), moment(initQuery.endTime)],
        }}
      >
        <Space wrap>
          <Form.Item label='业务线' name='firstDepartment'>
            <Input allowClear style={{ width: 180 }} placeholder='请输入业务线' />
          </Form.Item>
          <Form.Item label='所属部门' name='pioneerDivision'>
            <Input allowClear style={{ width: 180 }} placeholder='请输入所属部门' />
          </Form.Item>
          <Form.Item label='签约BD' name='saleName'>
            <Input allowClear style={{ width: 180 }} placeholder='请输入签约BD' />
          </Form.Item>
          <Form.Item label='商行ID' name='stallId'>
            <Input allowClear style={{ width: 180 }} placeholder='请输入商行ID' />
          </Form.Item>
          <Form.Item label='商行名称' name='stallName'>
            <Input allowClear style={{ width: 180 }} placeholder='请输入商行名称' />
          </Form.Item>
          <Form.Item label='市场名称' name='marketId'>
            <Select
              style={{ width: 180 }}
              allowClear
              showSearch
              placeholder='请选择市场'
              options={marketData}
              fieldNames={{ label: 'name', value: 'id' }}
              filterOption={(input, option) => {
                return (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item label='所在省/市' name='district'>
            <TreeSelect
              allowClear
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              style={{ width: 180 }}
              treeData={origins}
              placeholder='请选择城市'
              treeDefaultExpandAll={false}
              filterTreeNode={(input, treeNode) => {
                return treeNode.props.title.indexOf(input) >= 0;
              }}
            />
          </Form.Item>
          <Form.Item label='商行版本' name='typeList'>
            <Checkbox.Group>
              {[...STALL_TYPE].map(([k, v]) => (
                <Checkbox value={k} key={k}>
                  {v}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label='创建时间' name='times'>
            <RangePicker disabledDate={disabledDate} />
          </Form.Item>
        </Space>
        <Form.Item style={{ marginTop: 8 }}>
          <Space>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button htmlType='button' onClick={onReset}>
              重置
            </Button>
            <Button htmlType='button' onClick={exportData}>
              导出
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  return (
    <PageHeaderLayout>
      <div className='add-services'>
        <ExtraContent />
        <Table
          rowKey='businessNo'
          dataSource={list.pageList || []}
          columns={columns}
          pagination={pagination}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </PageHeaderLayout>
  );
};

export default NewSign;
