import { isUrl } from '../../utils/utils';
import { getAuthorityUserInfo } from '../../utils/authority';

export let menuData = [
  {
    name: '系统管理',
    icon: 'contacts',
    path: 'system',
    children: [
      {
        name: '系统日志',
        path: 'sysLog',
        code: '01.002.001',
      },
      {
        name: '人员信息',
        path: 'personManager',
        code: '01.041.001',
      },
      {
        name: '商行异常操作查询',
        path: 'abnormalOperation',
        code: '01.053.001',
      },
      {
        name: '系统公告管理',
        path: 'systemNotice',
        code: '01.067.001',
      },
      {
        name: '数据导出',
        path: 'dataExport',
        code: '01.068.050',
      },
      {
        name: '货主端APP用户列表',
        path: 'supplierAppList',
        code: '01.041.001',
      }
    ],
  },
  {
    name: '商行管理',
    icon: 'pay-circle-o',
    path: 'booth',
    children: [
      {
        name: '商行管理',
        path: 'stallManager', //'stallManager',
        code: '01.003.001', //01.003.006是转换权限的用户
      },
      {
        name: '合同管理',
        path: 'contractManagement',
        code: '01.029.001',
      },
      {
        name: '设备管理',
        path: 'newDeviceManagement',
        code: '01.030.001',
      },
      {
        name: '会员管理',
        path: 'memberManagement',
        code: '01.031.001',
      },
      {
        name: '会员服务管理',
        path: 'memberServicesManagement',
        code: '01.032.001',
      },
      {
        name: '集团管理',
        path: 'orgManagement',
        code: '01.005.001',
      },
      {
        name: '档口分组配置',
        path: 'boothAllGroup',
        code: '01.006.001',
      },
      {
        name: '系统配置',
        path: 'sysConfig',
        code: '01.007.001',
      },
      {
        name: '白名单查询',
        path: 'WhiteList',
        code: '01.008.001',
      },
      {
        name: '语音包激活码管理',
        path: 'voiceCDKEY',
        code: '01.037.001',
      },
      {
        name: '签字撤销',
        path: 'cancelSign',
        code: '01.055.004',
      },
      {
        name: '增值服务管理',
        path: 'addService',
        code: '01.061.001',
      },
      {
        name: '广告管理',
        path: 'advertisingManagement',
        code: '01.063.001',
      },
    ],
  },
  {
    name: '计算配置',
    icon: 'contacts',
    path: 'count',
    children: [
      {
        name: '货款计算',
        path: 'paymentcalculation',
        code: '01.009.001',
      },
      {
        name: '配置分组',
        path: 'configGroup',
        code: '01.013.001',
      },
      {
        name: '配置列表',
        path: 'configlist',
        code: '01.015.001',
      },
    ],
  },
  {
    name: '数据统计',
    icon: 'contacts',
    path: 'statics',
    children: [
      {
        name: '数据统计',
        path: 'statistics',
        code: '01.019.001',
      },
      {
        name: '商行单据统计',
        path: 'billStatistics',
        code: '01.023.001', // 新添加
      },
      {
        name: '商行数据分析',
        path: 'dataAnalysis',
        // code: '01.023.001',  // 新添加
        code: '01.045.001', // 新添加
      },
      {
        name: '在线还款流水表',
        path: 'remotePayAnalysis',
        code: '01.051.001', // 新添加
      },
      {
        name: '官网用户收集',
        path: 'caixmPhone',
        code: '01.034.001', // 新添加
      },
      {
        name: '销售人员管理',
        path: 'salesManagement',
        code: '01.036.001', // 新添加
      },
      {
        name: '每日新增统计',
        path: 'dailyAddStall',
        code: '01.038.001', // 新添加
      },
      {
        name: '每日交易统计',
        path: 'dailyBusinessStall',
        code: '01.039.001', // 新添加
      },
      {
        name: '市场统计数据',
        path: 'marketStallStatistic',
        code: '01.048.001', // 新添加
      },
      {
        name: '商行活跃信息',
        path: 'stallActiveInfo',
        code: '01.049.001', // 新添加
      },
      {
        name: '支付/借贷自助进件统计',
        path: 'bankPayment',
        code: '01.052.001',
        // code: '01.002.001',  // todo
      },
      // {
      //   name: '法律催款服务',
      //   path: 'dunningByLaw',
      //   code: '01.054.001',
      // },
      {
        name: '增值服务订单',
        path: 'serveOrder',
        code: '01.062.001',
      },
      {
        name: '新签交付统计表',
        path: 'newSign',
        code: '01.065.001',
      },
    ],
  },
  {
    name: '版本管理',
    icon: 'contacts',
    path: 'version',
    children: [
      {
        name: '版本管理',
        path: 'versionmanagement',
        code: '01.020.001',
      },
    ],
  },
  {
    name: '市场列表',
    icon: 'contacts',
    path: 'marketList',
    children: [
      {
        name: '市场列表',
        path: 'marketList', //'marketList',
        code: '01.022.001',
      },
    ],
  },
  {
    name: '商品库管理',
    icon: 'contacts',
    path: 'commodityManage',
    children: [
      {
        name: '商品基础库',
        path: 'commodityBase',
        code: '01.027.001',
      },
      {
        name: '未关联货品表',
        path: 'unassociatedGoods',
        code: '01.028.001',
      },
    ],
  },
  {
    name: '数据服务管理',
    icon: 'contacts',
    path: 'data-service',
    children: [
      {
        name: '数据服务商品库管理',
        path: 'goodsLibrary',
        code: '01.046.001',
      },
      {
        name: '未关联货品表',
        path: 'unconnectedGoods',
        code: '01.047.001',
      },
      {
        name: '数据服务基库货品',
        path: 'goodsSale',
        code: '01.050.001',
      },
    ],
  },
];

function formatter(data, parentPath = '/', parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority,
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}

export const getMenuData = () =>
  formatter(
    (function (menuData) {
      const userInfo = getAuthorityUserInfo();
      if (userInfo != null) {
        const userRoles = userInfo.permissionCodes || [];
        menuData.forEach((item, index) => {
          item.children = item.children.filter((v) => {
            return userRoles.includes(v.code);
          });
        });
        menuData = menuData.filter((v) => {
          return v.children.length > 0;
        });
      }
      return menuData;
    })(menuData),
  );
