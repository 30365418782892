// 人员信息
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Col, Button, Table, Input, Row, Tag } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getList } from '../../state/action/personManager';
import './index.less';

const FormItem = Form.Item;

@Form.create()
@connect((state) => state.personManager, { getList })
class PersonManager extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageNo: 1,
    };
  }

  componentDidMount() {
    document.body.classList.add('sys-log-index-body');
  }

  componentWillUnmount() {
    document.body.classList.remove('sys-log-index-body');
  }

  // 获取table数据
  async getList(params) {
    this.setState({ loading: true });
    await this.props.getList(params);
    this.setState({ loading: false });
  }

  // 时间转化函数
  changeStardTime(params) {
    var date = new Date(params);
    let date_value =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds();
    return date_value;
  }

  // 转化时间戳函数
  changeTimeToTimestamp(params) {
    let newDate = new Date(params);
    let time = newDate.getTime();
    return time;
  }

  // 根据条件搜索合单配置列表
  handleSubmit = (e) => {
    e.preventDefault();
    this.changeTimeToTimestamp(this.state.startValue);
    this.props.form.validateFields(['phone'], (error, value) => {
      if (!error) {
        this.setState(
          {
            pageNo: 1,
          },
          () => {
            this.setState(
              {
                phone: value.phone,
                pageNo: 1,
              },
              () => {
                let keyInfo = {
                  phone: value.phone,
                  pageSize: 10,
                  pageNo: 1,
                };
                this.getList(keyInfo);
              },
            );
          },
        );
      }
    });
  };

  // 重置
  handleReset = () => {
    this.props.form.resetFields();
  };

  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    });
    let pageInfo = {
      phone: this.state.phone,
      pageSize: 10,
      pageNo: pageNumber.current,
    };
    this.getList(pageInfo);
  };

  render() {
    const personManagerList = this.props.personManagerList;
    // 表格数据源
    let dataSource = [];
    let pageEntity = {};
    if (personManagerList) {
      dataSource = personManagerList.pageList;
      pageEntity = personManagerList;
    } else {
      dataSource = [];
      pageEntity = {};
    }
    const { getFieldDecorator } = this.props.form;
    var totalSize = 0;
    if (pageEntity === undefined) {
    } else {
      totalSize = pageEntity.totalSize;
    }
    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
    };

    const extraContent = (
      <div className='extraContent'>
        <Form layout='inline' onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col {...topColResponsiveProps}>
              <FormItem label='手机号' style={{ minWidth: 300 }}>
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: '请输入手机号!' }],
                })(<Input placeholder='请输入手机号' allowClear />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
          </Row>
        </Form>
      </div>
    );
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
    };
    return (
      <div className='sys-index'>
        <PageHeaderLayout>
          <div className={'system-log'}>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.pageChange.bind(this)}
              >
                <Col align='center' title='姓名' dataIndex='name' key='name' />
                <Col align='center' title='手机号' dataIndex='phone' key='phone' />
                <Col align='center' title='商行ID' dataIndex='stallId' key='stallId' />
                <Col align='center' title='所在商行' dataIndex='stallName' key='stallName' />
                <Col
                  align='center'
                  title='状态'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const { enable } = record;
                    if (!enable) {
                      return (
                        <Tag color={'red'} key={'禁用'}>
                          禁用
                        </Tag>
                      );
                    }
                    return (
                      <Tag color={'green'} key={'正常'}>
                        正常
                      </Tag>
                    );
                  }}
                />
                <Col
                  align='center'
                  title='创建时间'
                  dataIndex='createTime'
                  key='createTime'
                  render={(_, record) => {
                    const time = record.createTime
                      ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')
                      : '';
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='操作时间'
                  dataIndex='updateTime'
                  key='updateTime'
                  render={(_, record) => {
                    const time = record.updateTime
                      ? moment(record.updateTime).format('YYYY-MM-DD HH:mm:ss')
                      : '';
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='操作人'
                  dataIndex='updatePersionName'
                  key='updatePersionName'
                />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default PersonManager;
