/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-05 10:47:32
 * @LastEditors: lijing
 * @LastEditTime: 2022-07-18 19:48:25
 */
import * as actionTypes from '../constant/operationManager';

//初始状态
const initState = {
  operationList: {},
};
//reducer
export function operationManager(state = initState, action) {
  switch (action.type) {
    case actionTypes.PERSON_MANAGER_GET_OPERATION_LIST:
      return { ...state, operationList: action.payload.data };
    default:
      return state;
  }
}
