import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Input,
  Button,
  Select,
  Table,
  Divider,
  Modal,
  InputNumber,
  message,
} from 'antd';

import './style.less';
import { isEmptyObject } from '../../utils/reg';

import {
  fetchAllStallCalculationResult,
  getAllGroupStallItem,
  saveGroupStallItem,
  updateGroupStallItem,
} from '../../state/action/count';
import { unitRules } from '../../utils/deviceType';

const { Option } = Select;
const FormItem = Form.Item;

@connect(
  (state) => {
    return { count: state.count };
  },
  {
    fetchAllStallCalculationResult,
    getAllGroupStallItem,
    saveGroupStallItem,
    updateGroupStallItem,
  },
)
@Form.create()
class ConfigGroupDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updShow: false,
      updInfo: {},
    };
  }

  componentDidMount() {
    this.fetchAllStallItem({});
  }

  fetchAllCalcResult(params) {
    this.props.fetchAllStallCalculationResult(params);
  }

  fetchAllStallItem(params) {
    let id = !isEmptyObject(this.props.location) ? this.props.location.state.id : 0;
    params.id = id;
    this.props.getAllGroupStallItem(params);
  }

  saveStallItem = (params) => {
    this.props.saveGroupStallItem(params).then((data) => {
      this.fetchAllStallItem({});
      let {
        count: { stallItem },
      } = this.props;
      if (stallItem.code === 200) {
        message.success('操作成功!');
      } else {
        message.error(stallItem.msg);
      }
    });
  };

  updateStallItem = (params) => {
    this.props.updateGroupStallItem(params).then(() => {
      this.fetchAllStallItem({});
      message.success('操作成功!');
    });
  };

  showUpdateModal(record) {
    this.setState({
      updShow: true,
      showDesc: record.showDesc,
      updInfo: {
        id: record.id,
        key: record.key,
        desc: record.desc,
        unit: record.unit,
        unitRule: record.unitRule,
        order: record.order,
        type: record.type,
      },
    });
  }

  showAddModal() {
    this.setState({
      updShow: true,
      showDesc: true,
      updInfo: {},
    });
  }

  // 分页查询
  pageChange = () => {
    this.fetchAllStallItem({});
  };

  // 模态框取消
  handleCancel = () => {
    this.setState({
      updShow: false,
    });
  };

  // 修改规则名称/备注
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['key', 'desc', 'unit', 'unitRule', 'order', 'type'],
      (err, fieldsValue) => {
        if (err) {
          return;
        }
        let id = !isEmptyObject(this.props.location) ? this.props.location.state.id : 0;

        // 多单位
        let multiUnits = [];
        if (fieldsValue.key === 'subUnit' && fieldsValue.type === 'multi') {
          multiUnits = [
            {
              level: 2,
              unitFactorKey: 'factor',
              unitNameKey: 'unitName',
              unitPriceKey: 'price',
              inputList: ['text', 'select', 'button'],
            },
            {
              level: 3,
              unitFactorKey: 'factor',
              unitNameKey: 'unitName',
              unitPriceKey: 'price',
              inputList: ['text', 'select', 'button'],
            },
          ];
        }
        let params = {
          id: id,
          itemKeyLst: [
            {
              id: this.state.updInfo.id,
              key: fieldsValue.key,
              desc: fieldsValue.desc,
              unit: fieldsValue.unit,
              unitRule: fieldsValue.unitRule,
              order: fieldsValue.order,
              type: fieldsValue.type,
              multiUnits: multiUnits,
            },
          ],
        };
        if (this.state.updInfo.id) {
          this.updateStallItem(params);
        } else {
          this.saveStallItem(params);
        }
        this.handleCancel();
      },
    );
  };

  // 获取返回结果对象
  getCalcResult = (key) => {
    let {
      count: { allResultData },
    } = this.props;
    for (var i = 0; i < allResultData.length; i++) {
      if (allResultData[i].key === key) {
        return allResultData[i];
      }
    }
  };

  // 修改规则名称/备注
  updateEnable = (kid, enable) => {
    let id = !isEmptyObject(this.props.location) ? this.props.location.state.id : 0;
    let params = {
      id: id,
      itemKeyLst: [
        {
          id: kid,
          enable: enable,
        },
      ],
    };
    this.updateStallItem(params);
  };

  // 修改规则名称/备注
  updateSaleShowFlag = (kid) => {
    let id = !isEmptyObject(this.props.location) ? this.props.location.state.id : 0;
    let params = {
      id: id,
      itemKeyLst: [
        {
          id: kid,
        },
      ],
    };
    this.updateStallItem(params);
  };

  // 修改规则名称/备注
  updateSelfSaleShowFlag = (kid) => {
    let id = this.props.location.state.id;
    let params = {
      id: id,
      itemKeyLst: [
        {
          id: kid,
        },
      ],
    };
    this.updateStallItem(params);
  };

  handleChange = (e) => {
    this.setState({
      showDesc: e.target.value,
    });
  };

  goBack() {
    this.props.history.push('/count/configGroup');
  }

  render() {
    let {
      count: { stallItem },
    } = this.props;
    let lst = stallItem ? stallItem.data : [];
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    const columns = [
      {
        title: 'KEY',
        dataIndex: 'key',
      },
      {
        title: '页面配置',
        dataIndex: 'desc',
      },
      {
        title: '排序',
        dataIndex: 'order',
      },
      {
        title: '状态',
        dataIndex: 'enable',
        render: (_, record) => {
          const { enable } = record;
          let enableStr = '启用';
          if (!enable) {
            enableStr = '禁用';
          }
          return <span>{enableStr}</span>;
        },
      },
      {
        title: '操作',
        dataIndex: 'oper',
        render: (_, record) => {
          const { enable } = record;
          return (
            <div>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.showUpdateModal(record);
                }}
              >
                编辑
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateEnable(record.id, !enable);
                }}
              >
                {enable ? '禁用' : '启用'}
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <div className='tabLayout'>
        <Card title='配置列表' bordered={false}>
          <Button onClick={(e) => this.showAddModal()}>新增默认值</Button>
          <Button style={{ margin: '0 12px 12px' }} onClick={() => this.goBack()}>
            {' '}
            返回
          </Button>
          <Table dataSource={lst || []} columns={columns} rowKey='id' />
        </Card>
        <Card className='modalCard'>
          <Modal
            title='前台显示配置'
            visible={this.state.updShow}
            onCancel={this.handleCancel}
            destroyOnClose='true'
            width='600px'
            className='modal'
            footer={[]}
          >
            <Form onSubmit={this.onSubmit} className='login-form'>
              <FormItem {...formItemLayout} label='KEY'>
                {getFieldDecorator('key', {
                  initialValue: this.state.updInfo.key,
                  rules: [{ required: true, message: '请选择KEY' }],
                })(
                  <Select
                    placeholder='请选择KEY'
                    disabled={this.state.updInfo.id ? true : false}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={'goodsNumber'}>{'商品数量'}</Option>
                    <Option key={'packWeight'}>{'定装规格'}</Option>
                    <Option key={'goodsWeight'}>{'商品重量'}</Option>
                    <Option key={'unitPrice'}>{'销售单价'}</Option>
                    <Option key={'tradeFee'}>{'力资费'}</Option>
                    <Option key={'billFee'}>{'开票费'}</Option>
                    <Option key={'packFee'}>{'灌包费'}</Option>
                    <Option key={'is_promotion'}>{'甩货'}</Option>
                    <Option key={'is_include_trade'}>{'包出门'}</Option>
                    <Option key={'purchaseUnitPrice'}>{'进货单价'}</Option>
                    <Option key={'mainUnit'}>{'主单位'}</Option>
                    <Option key={'subUnit'}>{'单位换算'}</Option>
                    <Option key={'mainPrice'}>{'多级销售单价'}</Option>
                  </Select>,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='默认值名称'>
                {getFieldDecorator('desc', {
                  initialValue: this.state.updInfo.desc,
                  rules: [{ required: true, message: '请输入默认值名称' }],
                })(<Input disabled={!this.state.showDesc} />)}
              </FormItem>
              <FormItem {...formItemLayout} label='单位'>
                {getFieldDecorator('unit', {
                  initialValue: this.state.updInfo.unit,
                  rules: [{ required: false, message: '请输入单位' }],
                })(<Input />)}
              </FormItem>
              <FormItem {...formItemLayout} label='单位规则'>
                {getFieldDecorator('unitRule', {
                  initialValue: this.state.updInfo.unitRule || 0,
                  rules: [{ required: true, message: '请选择单位规则' }],
                })(
                  <Select
                    placeholder='请选择单位规则'
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {[...unitRules].map(([k, v]) => {
                      return (
                        <Option key={k} value={k}>
                          {v}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label='排序'>
                {getFieldDecorator('order', {
                  initialValue: this.state.updInfo.order,
                  rules: [{ required: true, message: '请输入排序' }],
                })(<InputNumber step={1} />)}
              </FormItem>
              <FormItem {...formItemLayout} label='值类型'>
                {getFieldDecorator('type', {
                  initialValue: this.state.updInfo.type,
                  rules: [{ required: false, message: '请选择类型' }],
                })(
                  <Select
                    placeholder='请选择类型'
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={'java.lang.Integer'}>{'整数'}</Option>
                    <Option key={'java.math.BigDecimal'}>{'小数'}</Option>
                    <Option key={'java.lang.Boolean'}>{'开关(布尔值)'}</Option>
                    <Option key={'select'}>{'下拉框(select)'}</Option>
                    <Option key={'multi'}>{'单位换算(multi)'}</Option>
                  </Select>,
                )}
              </FormItem>
              <FormItem wrapperCol={{ span: 24, offset: 11 }}>
                <Button
                  style={{ float: 'center', marginRight: '5px', marginTop: '5px' }}
                  type='primary'
                  htmlType='submit'
                >
                  保存
                </Button>
              </FormItem>
            </Form>
          </Modal>
        </Card>
      </div>
    );
  }
}
export default ConfigGroupDisplay;
