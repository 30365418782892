import request from '../../utils/request';

//
export async function getAppList(params) {
  return request({ url: 'app/getAppList', params });
}

//
export async function saveOrUpdateVer(params) {
  return request({ url: 'app/saveOrUpdateVer', params, normalP: true});
}

//
export async function getAppVerById(params) {
  return request({ url: 'app/getAppVerById', params, normalP: true });
}

// 警告消息推送
export async function pushWarning(params) {
  return request({ url: 'app/pushWarning', params });
}

// 版本更新推送消息
export async function pushSync(params) {
  return request({ url: 'app/pushSync', params, normalP: true });
}

// 获取版本推送设置列表
export async function getVersionPushList(params) {
  return request({ url: 'app/getVersionPushList', params });
}

// 新增版本推送设置
export async function addVersionPush(params) {
  return request({ url: 'app/addVersionPush', params });
}

// 删除版本推送设置
export async function deleteVersionPush(params) {
  return request({ url: 'app/deleteVersionPush', params });
}

// 重置版本推送状态
export async function resetVersionPushStatus(params) {
  return request({ url: 'app/resetVersionPushStatus', params });
}

// 灰度版本的商行列表
export async function getTradingCompanyList(params) {
  return request({ url: 'versionRefer/list', params });
}

// 灰度版本的新增商行
export async function getVersionReferAdd(params) {
  return request({ url: 'versionRefer/add', params });
}

// 在灰度版本升级的商行列表移除目标商行
export async function deleteVersionRefer(params) {
  return request({ url: 'versionRefer/delete', params });
}
