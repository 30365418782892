// code 取值
export const HTTP_ERROR_STATUS_CONTINUE = 100;
export const HTTP_ERROR_STATUS_PROCESSING = 102;
export const HTTP_ERROR_STATUS_OK = 200;
export const HTTP_ERROR_STATUS_PUT = 201;
export const HTTP_ERROR_STATUS_ACCEPTED = 202;
export const HTTP_ERROR_STATUS_DELETE = 204;
export const HTTP_ERROR_STATUS_RESET = 205;
export const HTTP_ERROR_STATUS_NOT_MODIFIED = 304;
export const HTTP_ERROR_STATUS_BADREQUEST = 400;
export const HTTP_ERROR_STATUS_UNAUTHENTICATE = 401;
export const HTTP_ERROR_STATUS_FORBIDEN = 403;
export const HTTP_ERROR_STATUS_FILENOTFOUND = 404;
export const HTTP_ERROR_STATUS_GET_NONE = 406;
export const HTTP_ERROR_STATUS_LOAD_NONE = 410;
export const HTTP_ERROR_STATUS_AUTH_ERROR = 422;
export const HTTP_ERROR_STATUS_INTERNALSERVERERROR = 500;
export const HTTP_ERROR_STATUS_GATEWAY_ERROR = 502;
export const HTTP_ERROR_STATUS_SERVER_OVERLOAD = 503;
export const HTTP_ERROR_STATUS_GATEWAY_TIMEOUT = 504;

export const codeMessage = {
  HTTP_ERROR_STATUS_OK: '服务器成功返回请求的数据。',
  HTTP_ERROR_STATUS_PUT: '新建或修改数据成功。',
  HTTP_ERROR_STATUS_ACCEPTED: '一个请求已经进入后台排队（异步任务）。',
  HTTP_ERROR_STATUS_DELETE: '删除数据成功。',
  HTTP_ERROR_STATUS_RESET: '链接已重置。',
  HTTP_ERROR_STATUS_NOT_MODIFIED: '重定向。',
  HTTP_ERROR_STATUS_BADREQUEST: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  HTTP_ERROR_STATUS_UNAUTHENTICATE: '用户没有权限（令牌、用户名、密码错误）。',
  HTTP_ERROR_STATUS_FORBIDEN: '用户得到授权，但是访问是被禁止的。',
  HTTP_ERROR_STATUS_FILENOTFOUND: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  HTTP_ERROR_STATUS_GET_NONE: '请求的格式不可得。',
  HTTP_ERROR_STATUS_LOAD_NONE: '请求的资源被永久删除，且不会再得到的。',
  HTTP_ERROR_STATUS_AUTH_ERROR: '当创建一个对象时，发生一个验证错误。',
  HTTP_ERROR_STATUS_INTERNALSERVERERROR: '服务器发生错误，请检查服务器。',
  HTTP_ERROR_STATUS_GATEWAY_ERROR: '网关错误。',
  HTTP_ERROR_STATUS_SERVER_OVERLOAD: '服务不可用，服务器暂时过载或维护。',
  HTTP_ERROR_STATUS_GATEWAY_TIMEOUT: '网关超时。',
};

// 进行收银操作后,TRANSIENT表中订单状态删除
export const ORDER_STATUS_CASHIER_TRANSIENT_DELETE = 0;
// 待付款
export const ORDER_STATUS_WAIT_PAYMENT = 1;
// 部分付款--经过收银员的赊账，挂账操作，订单存在欠款
export const ORDER_STATUS_PORTION_PAYMENT = 5;
// 已付款--经过收银员的收银，或者还款（订单的所有赊账都还完）操作
export const ORDER_STATUS_ALL_PAYMENT = 10;
// 部分退款
export const OORDER_STATUS_PORTION_REFUND = 15;
// 全部退款
export const ORDER_STATUS_ALL_REFUND = 20;
// 自动作废
export const ORDER_STATUS_AUTO_CANCEL = 25;

export const orderStatus = {
  0: { txt: '已删除', color: '#bfbfbf' },
  1: { txt: '赊账', color: '#876800' },
  5: { txt: '部分付款', color: '#876800' },
  10: { txt: '已付款', color: '#52c41a' },
  15: { txt: '部分退款', color: '#2f54eb' },
  20: { txt: '全部退款', color: '#00474f' },
  25: { txt: '已作废', color: '#bfbfbf' },
};

// api地址
export const API_PRE = 'api';
// 加密密文
export const PUBLICK_KEY =
  '-----BEGIN RSA PUBLIC KEY-----' +
  'MIICXQIBAAKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQ' +
  'WMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNR' +
  'aY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQAB' +
  'AoGAfY9LpnuWK5Bs50UVep5c93SJdUi82u7yMx4iHFMc/Z2hfenfYEzu+57fI4fv' +
  'xTQ//5DbzRR/XKb8ulNv6+CHyPF31xk7YOBfkGI8qjLoq06V+FyBfDSwL8KbLyeH' +
  'm7KUZnLNQbk8yGLzB3iYKkRHlmUanQGaNMIJziWOkN+N9dECQQD0ONYRNZeuM8zd' +
  '-----END RSA PUBLIC KEY-----';

// 新增商行密码加密
// dev+test
export const DEV_PASSWORD_ENCRYPT_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCI/16pWHQUTGJu+5nfmHB5RYukzCsfsNpe0Ca7VxhrYr8pf70LvJGI3Dat9W4xSctqZOzxfyxgw6gnr0QjFxoXKVJUEgOkP9puS7LohOvWKY5t0wRxUSHI6ZB9KkF1JBHm6sA6qHj/pSpgPCh3N9K5UK5TzL/qLNLQ1yJdLWewCwIDAQAB';

// prep+prod
export const PROD_PASSWORD_ENCRYPT_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCKtaGbVKYGqeFsG859K0qiBlKwc8kxX2QfXM0npokr7J49YhyAEdOx0ehr/HlxBihwhJ4GHXxjGD8eub2yYrgEx8kaGypn+5UJQee+ErYquMTJcAN7fNbjowB+ERvTrfMfCuVWxID+/tvb9JirEsqmXEX/ZY1XkAKo32bYVef8RwIDAQAB';

export const ENCRYPT_KEY = ['local', 'dev', 'development', 'test'].includes(
  process.env.REACT_APP_API,
)
  ? DEV_PASSWORD_ENCRYPT_KEY
  : PROD_PASSWORD_ENCRYPT_KEY;
