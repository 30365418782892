// 数据服务管理-数据服务基库货品
// 车次管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Input, Modal, Table } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';

import { getDailyHourPrice, editPrice } from '../../state/request/dataService';
import './goodsSaleDetail.less';
import moment from 'moment';

const { Column } = Table;

let FormItem = Form.Item;

@connect((state) => {
  return { market: state.market };
}, {})
@Form.create()
class GoodsSaleDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, //新增货品弹框
      goodsList: [], //货品列表
      editData: {}, //编辑
    };
    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    this.getData(); //获得页面数据
  }

  //获得商品库货品列表
  getData = async () => {
    const { record1, searchValue1 } = this.props.history.location.state || {};
    const param = {
      basicProductId: this.id,
      dateStartTime: searchValue1.dateStartTime,
      marketId: record1.marketId,
    };
    const response = await getDailyHourPrice(param);
    const { code, data } = response;
    if (code === 200) {
      this.setState({ goodsList: data.pageList });
    }
  };

  showModal = (e, record) => {
    e.preventDefault();
    this.setState({ visible: true, editData: record });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['price'], async (errors, values) => {
      if (!!errors) {
        return;
      }
      const { editData } = this.state || {};
      // 请求货品列表
      const response = await editPrice({ id: editData.id, price: values.price });
      const { code } = response;
      if (code === 200) {
        this.getData();
        this.setState({ visible: false });
      }
    });
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };
  //返回
  back = () => {
    const { searchValue1, pageNo1 } = this.props.history.location.state || {};
    this.props.history.push({
      pathname: '/data-service/goodsSale',
      state: {
        searchValue1,
        pageNo1,
      },
    });
  };

  // 展示货品管理
  toDetail(e, record) {
    e.preventDefault();
    const { record1, searchValue1, pageNo1 } = this.props.history.location.state || {};
    this.props.history.push({
      pathname: `/data-service/goodsSale/${this.id}/${record.id}`,
      state: {
        record1,
        searchValue1,
        pageNo1,
        hourPriceId: record.id,
        timeDesc: record.timeDesc,
      },
    });
  }
  render() {
    const { record1, searchValue1 } = this.props.history.location.state || {};
    const { getFieldDecorator } = this.props.form;
    const { visible, goodsList, editData } = this.state;

    return (
      <div className='goods-sale-detail'>
        <PageHeaderLayout>
          <div className='goods-sale-detail-content'>
            <div className='header'>
              <Button onClick={this.back}>返回</Button>
              <span>{record1.marketName || ''}</span>
              <span>{record1.basicProductName || ''}</span>
              <span>{moment(searchValue1.dateStartTime).format('YYYY-MM-DD') || ''}</span>
            </div>
            <div className='content'>
              <Table
                rowKey={(record, index) => index}
                dataSource={goodsList}
                pagination={false}
                loading={this.state.loading}
              >
                <Column title='时间' dataIndex='timeDesc' key='timeDesc' />
                <Column
                  title='均价(元/公斤)'
                  align='center'
                  dataIndex='price'
                  key='price'
                  render={(text) => {
                    return text || '--';
                  }}
                />
                <Column
                  align='center'
                  title='操作'
                  dataIndex='operation'
                  key='operation'
                  render={(_, record) => {
                    const { price } = record;
                    return (
                      <div>
                        <a
                          disabled={price ? false : true}
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.showModal(e, record);
                          }}
                        >
                          修改均价
                        </a>
                        <a
                          disabled={price ? false : true}
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.toDetail(e, record);
                          }}
                          style={{ marginLeft: 16 }}
                        >
                          查看详情
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>

              <Modal
                title='修改均价'
                visible={visible}
                onCancel={this.handleCancel}
                footer={null}
                width='600px'
                className='add-modal'
              >
                <Form
                  onSubmit={this.onSubmit}
                  layout='inline'
                  className='login-form'
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 12 }}
                >
                  <FormItem label='当前显示均价'>
                    <span className='ant-form-text'>{editData.price}</span>
                  </FormItem>
                  <FormItem label='均价'>
                    {getFieldDecorator('price', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          pattern: /^-?\d+(\.\d{1,2})?$/,
                          message: '请输入正确的金额',
                        },
                      ],
                    })(<Input placeholder='请输入均价' allowClear />)}
                  </FormItem>

                  <FormItem label='' wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit'>
                      保存
                    </Button>
                    <Button onClick={this.handleCancel} style={{ marginLeft: '18px' }}>
                      关闭
                    </Button>
                  </FormItem>
                </Form>
              </Modal>
            </div>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default GoodsSaleDetail;
