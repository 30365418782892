import * as actionTypes from '../constant/login';
import * as req from '../request/login';

/**
 *登录
 */
export function postLogin(params) {
  return async (dispatch) => {
    const res = await req.login(params);
    if (res) {
      dispatch({ type: actionTypes.LOGIN_LOGIN, payload: res });
    }
  };
}

/**
 * 登出
 */
export function logout() {
  localStorage.removeItem('cxm-user');
  localStorage.removeItem('permissions');
  localStorage.removeItem('cxm-stall-info');
  localStorage.removeItem('cxm-user-info');
  window.location.href = '/login';
}

/**
 *发送验证码
 */
export function smsSend(params) {
  return async (dispatch) => {
    const res = await req.smsSend(params);
    if (res) {
      dispatch({ type: actionTypes.LOGIN_LOGIN, payload: res });
    }
  };
}
