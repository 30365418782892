// 版本管理-商行管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Table,
  Card,
  Input,
  Button,
  message,
  Select,
  Row,
  Modal,
  Upload,
  Popconfirm,
} from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import { getMarketList, getStallList } from '../../../state/action/basic';
import {
  fetchTradingCompanyList,
  fetchVersionReferAdd,
  deleteVersionRefer,
} from '../../../state/action/appVersion';
import { API_ADMIN } from '../../../utils/urls';
import { generalParamsWithPToStr } from '../../../utils/math';
import '../style.less';
import { isEmptyObject } from '../../../utils/reg';

const { Column } = Table;
const FormItem = Form.Item;

@connect(
  (state) => {
    return { appVersion: state.appVersion, basic: state.basic };
  },
  {
    getMarketList,
    getStallList,
    fetchTradingCompanyList,
    fetchVersionReferAdd,
    deleteVersionRefer,
  },
)
@Form.create()
class TradingManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      // 新增商行的modal
      addVisible: false,
      // 市场名称
      marketName: undefined,
      // 商行名字
      stallName: undefined,
    };
    this.versionId = !isEmptyObject(this.props.match) ? this.props.match.params.versionId : 0;
  }

  componentDidMount() {
    const versionId = this.versionId;
    const params = {
      versionId,
      pageNo: 1,
      pageSize: 10,
    };
    const p = this.getGrayReleasedTradingCompanyListParams(params);

    this.fetchTradingCompanyList(p);

    this.getMarketList({});
  }

  // 获取市场列表
  async getMarketList(params) {
    await this.props.getMarketList(params);
  }

  // 获取档口列表
  async getStallList(params) {
    await this.props.getStallList(params);
  }

  // 获取灰度发版的商行列表参数
  getGrayReleasedTradingCompanyListParams = (params) => {
    const { versionId, marketName, stallName, pageNo, pageSize } = params;
    const p = {
      versionId: versionId,
      pageNo: pageNo,
      pageSize: pageSize,
    };
    if (marketName !== undefined) {
      p.marketId = marketName;
    }
    if (stallName !== undefined) {
      p.stallId = stallName;
    }
    return p;
  };

  // 获取灰度发版的商行列表
  fetchTradingCompanyList = async (params) => {
    await this.props.fetchTradingCompanyList(params);
    this.setState({
      loading: false,
    });
  };

  // 查询
  handleOnQuery = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }

      const { marketName, stallName } = fieldsValue;
      this.setState({
        marketName,
        stallName,
      });
      const obj = {
        versionId: this.versionId,
        pageNo: 1,
        pageSize: 10,
        marketName,
        stallName,
      };
      const params = this.getGrayReleasedTradingCompanyListParams(obj);
      // 其他系统类型后台未生效

      this.fetchTradingCompanyList(params);
    });
  };

  // 重置市场和档口的选择条件
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      marketName: undefined,
      stallName: undefined,
    });
    const obj = {
      versionId: this.versionId,
      pageNo: 1,
      pageSize: 10,
    };
    const params = this.getGrayReleasedTradingCompanyListParams(obj);
    // 其他系统类型后台未生效

    this.fetchTradingCompanyList(params);
  };

  // 点击新增新增灰度版本的档口
  addStall = () => {
    this.changeStateAddModal(true);
  };

  // 隐藏addModal
  hideAddModal = () => {
    this.changeStateAddModal(false);
    this.props.form.resetFields();
  };

  // 显示新增商行的modal
  changeStateAddModal = (addVisible) => {
    this.setState({
      addVisible: addVisible,
    });
  };
  // 确认删除
  confirm = (id) => {
    const params = {
      id,
    };
    this.deleteVersionRefer(params);
  };
  // 取消删除
  cancel = (e) => {};

  // 点击新增modal的确认
  addStallSubmit = (e) => {
    e.preventDefault();
    this.changeStateAddModal(true);
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const { stallID } = fieldsValue;
      const params = { versionId: this.versionId };
      if (stallID) {
        params.stallId = stallID;
      } else {
        message.info('请先输入商行ID');
        return;
      }
      this.fetchVersionReferAdd(params);
    });
  };

  // 新增关联档口
  fetchVersionReferAdd = async (params) => {
    this.setState({
      loading: true,
    });
    this.changeStateAddModal(false);
    await this.props.fetchVersionReferAdd(params);
    const { addStallResult } = this.props.appVersion;
    if (addStallResult) {
      if (addStallResult.code === 200) {
        const { marketName, stallName } = this.state;
        const obj = {
          marketName,
          stallName,
          versionId: this.versionId,
          pageNo: 1,
          pageSize: 10,
        };
        const params2 = this.getGrayReleasedTradingCompanyListParams(obj);
        // 其他系统类型后台未生效
        this.fetchTradingCompanyList(params2);
      }
      this.props.form.resetFields();
    }
    this.setState({
      loading: false,
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      const { marketName, stallName } = fieldsValue;
      const p = {
        versionId: this.versionId,
        pageNo: obj.current,
        pageSize: obj.pageSize,
        marketName,
        stallName,
      };
      const params = this.getGrayReleasedTradingCompanyListParams(p);
      this.fetchTradingCompanyList(params);
    });
  };

  // 请求后台移除该商行
  deleteVersionRefer = async (params) => {
    await this.props.deleteVersionRefer(params);
    const { deleteStallResult } = this.props.appVersion;
    if (deleteStallResult.code === 200) {
      const { marketName, stallName } = this.state;
      const obj = {
        marketName,
        stallName,
        versionId: this.versionId,
        pageNo: 1,
        pageSize: 10,
      };
      const params2 = this.getGrayReleasedTradingCompanyListParams(obj);
      // 其他系统类型后台未生效

      this.fetchTradingCompanyList(params2);
    } else {
      message.info(deleteStallResult.msg);
    }
  };

  // 选择市场
  changeMarket = (marketId) => {
    // 置空档口数据
    this.props.form.setFieldsValue({ stallName: undefined });

    const params = {
      marketId: marketId,
    };

    this.getStallList(marketId ? params : {});
  };
  //返回
  back = () => {
    this.props.history.push('/version/versionmanagement');
  };

  //上传之前拦截下
  beforeUpload = (file) => {
    const str = file.name.split('.')[1];
    const isRightType = str === 'xls' || str === 'xlsx';
    const limitSize = file.size / 1024 / 1024 < 1;
    if (!isRightType) {
      message.error('仅支持Excel文件!', 3);
    }
    if (!limitSize) {
      message.error('文件大小不超过1M');
    }

    return limitSize;
  };
  //上传之后的改变事件
  handleChangeByUpload = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
    }
    if (info.file.status === 'done') {
      this.setState({ loading: false });
      if (info.file.response.code === 200) {
        message.success(`${info.file.name} 上传成功!`, 5);

        this.handleReset();
      } else if (info.file.response.code === 8009) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(info.file.response.msg, 5);
      }
    } else if (info.file.status === 'error') {
      if (info.file && info.file.response && info.file.response.msg) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(`${info.file.name} 上传失败`, 5);
      }
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      appVersion: { grayReleasedTradingCompanyList },
      basic: { marketList, stallList },
    } = this.props;
    const { addVisible } = this.state;

    const { getFieldDecorator } = this.props.form;
    // 列表数据组
    const grayReleasedList =
      grayReleasedTradingCompanyList.pageList && grayReleasedTradingCompanyList.pageList.length
        ? grayReleasedTradingCompanyList.pageList
        : [];
    // 市场列表
    const marketData = [];
    if (marketList) {
      if (marketList.code === 200) {
        if (marketList.data) {
          marketData.push(...marketList.data);
        }
      }
    }
    // 商行列表
    const stallData = [];
    if (stallList) {
      if (stallList.code === 200) {
        if (stallList.data) {
          stallData.push(...stallList.data);
        }
      }
    }
    const total = grayReleasedTradingCompanyList.totalSize
      ? grayReleasedTradingCompanyList.totalSize
      : 0;
    const pagination = {
      pageSize: 10,
      total: total,
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    //上传合同或者设备用到的参数
    const uploadParams = {
      versionId: this.versionId,
    };
    //上传合同的属性值
    const uploadContractProps = {
      name: 'file',
      action: `${API_ADMIN}versionRefer/import?${generalParamsWithPToStr(
        'versionRefer/import',
        uploadParams,
      )}`,
      headers: { authorization: 'authorization-text' },
      beforeUpload: this.beforeUpload,
      showUploadList: false,
      onChange: this.handleChangeByUpload,
    };
    const extraContent = (
      <div style={{ width: '100%' }}>
        <Form layout='inline' onSubmit={this.handleOnQuery} className='login-form'>
          <FormItem label='市场'>
            {getFieldDecorator('marketName', {
              rules: [{ required: false, message: '请选择市场!' }],
            })(
              <Select
                showSearch
                style={{ width: 250 }}
                placeholder='请选择市场'
                onChange={(value) => {
                  this.changeMarket(value);
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {marketData.map((market) => {
                  const { name, id } = market;
                  return (
                    <Select.Option key={id} value={id}>
                      {name || ''}
                    </Select.Option>
                  );
                })}
              </Select>,
            )}
          </FormItem>
          <FormItem label='商行'>
            {getFieldDecorator('stallName', {
              rules: [{ required: false, message: '请选择商行!' }],
            })(
              <Select
                showSearch
                style={{ width: 250 }}
                placeholder='请选择商行'
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {stallData.map((stall) => {
                  const { name, id } = stall;
                  return (
                    <Select.Option value={id} key={id}>
                      {name || ''}
                    </Select.Option>
                  );
                })}
              </Select>,
            )}
          </FormItem>
          <Row>
            <div style={{ marginTop: '5px' }}>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button onClick={this.handleReset} style={{ marginLeft: '12px' }}>
                重置
              </Button>
              <Button type='primary' onClick={this.addStall} style={{ marginLeft: '12px' }}>
                新增
              </Button>
              <Button onClick={this.back} style={{ marginLeft: '12px' }}>
                返回版本管理
              </Button>

              <Button
                type='primary'
                style={{ marginLeft: '18px' }}
                target='_blank'
                onClick={() =>
                  window.open(
                    `${API_ADMIN}versionRefer/downloadTemplet?${generalParamsWithPToStr(
                      'versionRefer/downloadTemplet',
                      {},
                    )}`,
                  )
                }
              >
                灰度导入模板下载
              </Button>

              <Upload {...uploadContractProps}>
                <Button type='primary' icon='upload' target='_blank' style={{ marginLeft: '18px' }}>
                  灰度批量导入
                </Button>
              </Upload>
            </div>
          </Row>
        </Form>
      </div>
    );

    return (
      <div className='version-index'>
        <PageHeaderLayout>
          <div className={'batchLayout'}>
            <Card
              className={'listCard'}
              bordered={false}
              title=''
              style={{ marginTop: 24 }}
              bodyStyle={{ padding: '0 6px 40px' }}
              extra={extraContent}
            >
              <Table
                rowKey={(_, index) => index}
                dataSource={grayReleasedList}
                pagination={pagination}
                scroll={{ x: 600 }}
                bordered
                loading={this.state.loading}
                onChange={this.pageSwitch.bind(this)}
              >
                <Column
                  title='市场ID'
                  dataIndex='marketId'
                  key='marketId'
                  render={(_, record) => {
                    const { marketId } = record;
                    return <span>{marketId || 0}</span>;
                  }}
                />
                <Column
                  title='市场名称'
                  dataIndex='marketName'
                  key='marketName'
                  render={(_, record) => {
                    const { marketName } = record;
                    return <span>{marketName || ''}</span>;
                  }}
                />
                <Column
                  title='商行ID'
                  dataIndex='stallId'
                  key='stallId'
                  render={(_, record) => {
                    const { stallId } = record;
                    return <span>{stallId || ''}</span>;
                  }}
                />
                <Column
                  title='商行名字'
                  dataIndex='stallName'
                  key='stallName'
                  render={(_, record) => {
                    const { stallName } = record;
                    return <span>{stallName || ''}</span>;
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='currDayCreditAmount'
                  key='currDayCreditAmount'
                  render={(_, record) => {
                    const { id } = record;

                    return (
                      <div>
                        <Popconfirm
                          title='确定要删除此商行名单吗?'
                          onConfirm={() => {
                            this.confirm(id);
                          }}
                          onCancel={this.cancel}
                          okText='确定'
                          cancelText='取消'
                        >
                          <span>删除</span>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Modal
              title='新增商行名单'
              visible={addVisible}
              centered
              destroyOnClose
              closable
              onOk={this.addStallSubmit}
              afterClose={this.hideAddModal}
              onCancel={this.hideAddModal}
            >
              <Form layout='horizontal' className='login-form' onSubmit={this.addStallSubmit}>
                <Row gutter={24}>
                  <FormItem {...formItemLayout} label='商行ID'>
                    {getFieldDecorator('stallID', {
                      rules: [{
                        required: false,
                        pattern: /^[0-9]*$/,
                        message: '请输入正确的商行ID！'
                      }]
                    })(
                      <Input allowClear style={{ width: 180 }} placeholder='请输入商行ID' />
                    )}
                  </FormItem>
                </Row>
              </Form>
            </Modal>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default TradingManagement;
