// 商行单据统计
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Input, Table, Row, Col, DatePicker } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';

import './caixmPhone.less';
import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';
import { getCurMonthFirstDate } from '../../utils/timer';

import { getListCaixmPhone } from '../../state/action/caixm';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const dateFormat = 'YYYY-MM-DD';
const format1 = 'YYYY-MM-DD 00:00:00';
const format2 = 'YYYY-MM-DD 23:59:59';

@connect(
  (state) => {
    return { caixm: state.caixm };
  },
  { getListCaixmPhone },
)
@Form.create()
class CaixmPhone extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.startDate = getCurMonthFirstDate();
    this.endDate = moment().format(format2);
  }
  componentDidMount() {
    this.getCaixmPhone({
      startTime: moment(this.startDate).valueOf(),
      endTime: moment(this.endDate).valueOf(),
      pageNo: 1,
      pageSize: 10,
    });
  }

  // 列表
  async getCaixmPhone(params) {
    this.setState({ loading: true });
    await this.props.getListCaixmPhone(params);
    this.setState({ loading: false });
  }

  // 重置按钮
  handleReset = () => {
    this.getCaixmPhone({
      startTime: moment(this.startDate).valueOf(),
      endTime: moment(this.endDate).valueOf(),
      pageNo: 1,
      pageSize: 10,
    });
    this.props.form.resetFields();
  };

  // 查询
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((_, fieldsValue) => {
      const { createTime, contactPhone } = fieldsValue;
      // 查询
      this.getCaixmPhone({
        contactPhone: contactPhone || null,
        startTime:
          createTime && createTime.length === 2
            ? moment(createTime[0].format(format1)).valueOf()
            : null,
        endTime:
          createTime && createTime.length === 2
            ? moment(createTime[1].format(format2)).valueOf()
            : null,
        pageNo: 1,
        pageSize: 10,
      });
    });
  };
  // 分页
  paginationChange = (e) => {
    const {
      caixm: { searchCaixmPhone },
    } = this.props;
    this.getCaixmPhone({ ...searchCaixmPhone, pageSize: e.pageSize, pageNo: e.current });
  };

  // 导出
  exportData = () => {
    const values = this.props.form.getFieldsValue();
    const { createTime, contactPhone } = values;
    const params = {
      contactPhone: contactPhone || null,
      startTime:
        createTime && createTime.length === 2
          ? moment(createTime[0].format(format1)).valueOf()
          : null,
      endTime:
        createTime && createTime.length === 2
          ? moment(createTime[1].format(format2)).valueOf()
          : null,
    };
    window.open(
      API_ADMIN +
        'channelRegister/exportRegisterInfo?' +
        generalParamsWithPToStr('channelRegister/exportRegisterInfo', params),
    );
  };

  render() {
    const { loading } = this.state;
    const layout = { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 };
    const { getFieldDecorator } = this.props.form;
    const {
      caixm: { caixmPhone = {} },
    } = this.props;
    const { pageNo = 1, totalSize = 1, pageList = [] } = caixmPhone;
    const pagination = {
      pageSize: 10,
      total: totalSize,
      current: pageNo,
    };

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        render: (_, __, index) => {
          return ++index;
        },
      },
      {
        title: '手机号',
        dataIndex: 'contactPhone',
      },
      {
        title: '创建日期',
        dataIndex: 'createTime',
        render: (_, record) => {
          const { createTime } = record;
          if (createTime) {
            return createTime !== 0 ? moment(createTime).format('YYYY-MM-DD HH:mm:ss') : '-';
          } else {
            return '-';
          }
        },
      },
    ];
    return (
      <PageHeaderLayout>
        <div className='caixm-phone'>
          <div className='extraContent' style={{ width: '100%' }}>
            <Form className='ant-advanced-search-form' onSubmit={this.handleSearch}>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem label='日期'>
                    {getFieldDecorator('createTime', {
                      initialValue: [
                        moment(this.startDate, dateFormat),
                        moment(this.endDate, dateFormat),
                      ],
                      rules: [{ required: false, message: '请选择时间' }],
                    })(<RangePicker format='YYYY-MM-DD' placeholder={['开始时间', '结束时间']} />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem label='手机号'>
                    {getFieldDecorator('contactPhone', {
                      rules: [{ required: false, whitespace: true, message: '请输入手机号' }],
                    })(<Input placeholder='请输入手机号' allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem>
                    <Button type='primary' htmlType='submit' style={{ marginTop: '20px' }}>
                      查询
                    </Button>
                    <Button
                      style={{ marginLeft: '20px', marginTop: '20px' }}
                      onClick={this.handleReset}
                    >
                      重置
                    </Button>
                    <Button
                      style={{ marginLeft: '20px', marginTop: '20px' }}
                      onClick={this.exportData}
                    >
                      导出
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
          <Table
            dataSource={pageList}
            columns={columns}
            pagination={pagination}
            rowKey={(record) => record.id}
            align='center'
            onChange={this.paginationChange}
            loading={loading}
          />
        </div>
      </PageHeaderLayout>
    );
  }
}
export default CaixmPhone;
