//初始状态
const initState = {
  home: {},
};
//reducer
export function home(state = initState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
