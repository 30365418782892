import request from '../../utils/request';
//商行管理--设备管理
import * as actionTypes from '../constant/salesman';
import * as req from '../request/salesman';

// 销售人员列表
export function querySaleList(params) {
  return async (dispatch) => {
    const res = await req.querySaleList(params);
    if (res) {
      dispatch({ type: actionTypes.SALESMAN_SAVE, payload: { querySaleList: res.data } });
    }
  };
}

//启用/禁用
export function updateSaleEnable(params) {
  return async (dispatch) => {
    const res = await req.updateSaleEnable(params);
    if (res) {
      dispatch({ type: actionTypes.SALESMAN_SAVE, payload: { updateSaleEnable: res } });
    }
  };
}

//获取所有销售信息
export function getAllSale() {
  return async (dispatch) => {
    const res = await req.getAllSale();
    if (res) {
      dispatch({ type: actionTypes.SALESMAN_SAVE, payload: { getAllSale: res.data } });
    }
  };
}

export const getSaleDownload = (params) => {
  return request({
    url: 'sale/download',
    params,
    method: 'get',
    headers: 'json',
    responseType: 'blob',
  });
};
