import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Input,
  Select,
  Table,
  Modal,
  message,
  Popconfirm,
  Divider,
} from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import {
  getVersionPushList,
  addVersionPush,
  deleteVersionPush,
  pushSync,
  pushWarning,
  resetVersionPushStatus,
} from '../../../state/action/appVersion';
import { marketList, fetchAllStallsConfig2 } from '../../../state/action/stall';

import '../style.less';

import { isEmptyObject } from '../../../utils/reg';

const FormItem = Form.Item;
const { Option } = Select;
const { Column } = Table;
const { TextArea } = Input;

const envData = [
  { key: 'caixm_dev', name: '收银端菜小秘开发版' },
  { key: 'caixm_fruit_dev', name: '收银端果小秘开发版' },
  { key: 'caixm_fish_dev', name: '收银端鱼小秘开发版' },
  { key: 'caixm_test', name: '收银端菜小秘测试版' },
  { key: 'caixm_fruit_test', name: '收银端果小秘测试版' },
  { key: 'caixm_fish_test', name: '收银端鱼小秘测试版' },
  { key: 'caixm_prep', name: '收银端菜小秘预生产版' },
  { key: 'caixm_fruit_prep', name: '收银端果小秘预生产版' },
  { key: 'caixm_fish_prep', name: '收银端鱼小秘预生产版' },
  { key: 'caixm_prod', name: '收银端菜小秘生产版' },
  { key: 'caixm_fruit_prod', name: '收银端果小秘生产版' },
  { key: 'caixm_fish_prod', name: '收银端鱼小秘生产版' },
];

@connect(
  (state) => {
    return { appVersion: state.appVersion, stall: state.stall };
  },
  {
    getVersionPushList,
    addVersionPush,
    deleteVersionPush,
    pushSync,
    pushWarning,
    resetVersionPushStatus,
    marketList,
    fetchAllStallsConfig2,
  },
)
@Form.create()
class PushConfig extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visibleModal: false,
      visibleMsgModal: false,
      defaultWarningMsg: '系统即将在10分钟后升级，请保存数据',
      selectedId: 0,
      marketId: 0,
      versionId: 0,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleMsgCancel = this.handleMsgCancel.bind(this);
  }

  componentDidMount() {
    const versionId = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    if (versionId !== undefined) {
      const params = {
        versionId: versionId,
      };
      this.getVersionPushList(params);
    }
    this.fetchAllMarkets({});
  }

  // 获取版本推送设置列表
  async getVersionPushList(params) {
    this.setState({
      loading: true,
      versionId: params.versionId,
    });
    await this.props.getVersionPushList(params);
  }

  // 查询请求
  handleOnQuery = (e) => {
    if (e) {
      e.preventDefault();
    }
    let name = null;
    this.props.form.validateFields((_, fieldsValue) => {
      const { stallName } = fieldsValue;
      name = stallName;
    });
    const params = {
      versionId: this.state.versionId,
      stallName: name,
    };
    this.getVersionPushList(params);
  };

  // modal关闭事件
  handleCancel(e) {
    this.setState({
      visibleModal: false,
    });
  }

  // 预警modal关闭事件
  handleMsgCancel(e) {
    this.setState({
      visibleMsgModal: false,
    });
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    let params = {};
    this.props.form.validateFields((_, fieldsValue) => {
      const { stallId, marketId, env } = fieldsValue;
      if (stallId !== undefined && marketId !== undefined && env !== undefined) {
        params = {
          versionId: this.state.versionId,
          stallId: this.state.stallId,
          marketId: this.state.marketId,
          env: env,
        };
        this.addVersionPush(params);
      } else {
        message.error('参数不得为空！');
      }
    });
  };

  // 发送预警确定
  handleMsgSubmit = (e) => {
    e.preventDefault();
    let params = {};
    this.props.form.validateFields((err, fieldsValue) => {
      const { warningMsg } = fieldsValue;
      if (warningMsg !== undefined) {
        params = {
          id: this.state.selectedId,
          warningMsg: warningMsg,
        };
        this.pushWarning(params);
      } else {
        message.error('参数不得为空！');
      }
    });
  };

  // 新增档口配置
  add() {
    this.setState({
      visibleModal: true,
    });
  }

  async addVersionPush(val) {
    await this.props.addVersionPush(val);
    const {
      appVersion: { addVersionPushData },
    } = this.props;
    if (addVersionPushData.code === 200) {
      message.success('操作成功!');
    } else {
      message.error(addVersionPushData.msg);
    }
    const params = {
      versionId: this.state.versionId,
    };
    await this.getVersionPushList(params);
    this.setState({
      visibleModal: false,
    });
  }

  // 删除档口
  async deleteVersionPush(e, record) {
    e.preventDefault();
    await this.props.deleteVersionPush({ id: record.id });
    message.success('删除成功！');
    const params = {
      versionId: this.state.versionId,
    };
    await this.getVersionPushList(params);
  }

  // 打开预警对话框
  openWarningModal(e, record) {
    e.preventDefault();
    this.setState({
      visibleMsgModal: true,
      selectedId: record.id,
    });
  }

  // 推送更新
  async pushSync(e, record) {
    e.preventDefault();
    await this.props.pushSync({ id: record.id, versionId: this.state.versionId });
    const params = {
      versionId: this.state.versionId,
    };
    await this.getVersionPushList(params);
    const {
      appVersion: { pushSyncData },
    } = this.props;
    if (pushSyncData.code === 200) {
      message.success('操作成功!');
    } else {
      message.error(pushSyncData.msg);
    }
  }

  // 推送更新预警
  pushWarning = async (params) => {
    await this.props.pushWarning(params);
    const param = {
      versionId: this.state.versionId,
    };
    await this.getVersionPushList(param);
    const {
      appVersion: { pushWarningData },
    } = this.props;
    if (pushWarningData.code === 200) {
      message.success('操作成功!');
    } else {
      message.error(pushWarningData.msg);
    }
    this.setState({
      visibleMsgModal: false,
      defaultWarningMsg: params.warningMsg,
    });
  };

  // 重置推送状态
  async resetVersionPushStatus(e, record) {
    e.preventDefault();
    await this.props.resetVersionPushStatus({ id: record.id });
    const params = {
      versionId: this.state.versionId,
    };
    await this.getVersionPushList(params);
    const {
      appVersion: { resetVersionPushStatusData },
    } = this.props;
    if (resetVersionPushStatusData.code === 200) {
      message.success('操作成功!');
    } else {
      message.error(resetVersionPushStatusData.msg);
    }
  }

  handleReset = () => {
    this.props.form.resetFields();
  };
  // 获取市场列表
  async fetchAllMarkets(params) {
    await this.props.marketList(params);
  }
  // 获取档口列表
  async fetchAllStallsGroupInfos2(params) {
    await this.props.fetchAllStallsConfig2(params);
  }
  // 选择市场后加载当前选择的档口列表数据
  handleChange(value) {
    let marketId = null;
    if (value !== '0') {
      marketId = value;
    }
    this.fetchAllStallsGroupInfos2({ flag: 1, marketId: marketId });
    this.setState({ marketId: value });
  }
  selectStall = (e) => {
    const stallId = e.split('/')[0];
    const marketId = e.split('/')[1];

    this.setState({
      marketId: marketId,
      stallId: stallId,
    });
  };
  //返回
  back = () => {
    this.props.history.push('/version/versionmanagement');
  };

  render() {
    let {
      stall: { marketData, allStallsData },
    } = this.props;
    // 市场列表
    if (marketData !== null && marketData !== undefined && marketData.length) {
      const tmpMarketData = [];
      const tmp = { id: 0, name: '所有市场' };
      tmpMarketData.push(tmp);
      for (let i = 0; i < marketData.length; i++) {
        tmpMarketData.push(marketData[i]);
      }
      marketData = tmpMarketData;
    } else {
      marketData = [];
    }

    const { getFieldDecorator } = this.props.form;

    // 档口列表数据
    if (allStallsData !== null && allStallsData !== undefined && allStallsData.length) {
      const tmpallStallsData = [];
      const tmp = { id: 0, marketId: this.state.marketId, name: '所有档口' };
      tmpallStallsData.push(tmp);
      for (let i = 0; i < allStallsData.length; i++) {
        tmpallStallsData.push(allStallsData[i]);
      }
      allStallsData = tmpallStallsData;
    } else {
      allStallsData = [];
    }

    const {
      appVersion: { getVersionPushListData },
    } = this.props;
    let versionPushListData = [];
    if (getVersionPushListData) {
      if (getVersionPushListData.code === 200) {
        if (getVersionPushListData.data) {
          versionPushListData = getVersionPushListData.data;
        }
      }
    }

    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 6,
      style: { marginBottom: 12 },
    };

    const extraContent = (
      <div className={'searchModule'}>
        <Form
          onSubmit={this.handleOnQuery}
          className='login-form'
          style={{ marginBottom: '12px', float: 'left', width: '100%' }}
        >
          <Row gutter={24}>
            <Col {...topColResponsiveProps}>
              <FormItem>
                {getFieldDecorator('stallName', {
                  rules: [{ required: false, message: '请输入档口名称!' }],
                })(<Input placeholder='请输入档口名称' style={{ width: '100%' }} allowClear />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <Button style={{ width: '100px', textAlign: 'center' }} htmlType='submit'>
                查询
              </Button>
              <Button
                style={{ marginLeft: '5px', width: '100px', textAlign: 'center' }}
                onClick={this.handleReset}
              >
                重置
              </Button>
              <Button
                style={{ marginLeft: '5px', width: '100px', textAlign: 'center' }}
                onClick={this.add.bind(this)}
              >
                新增
              </Button>
              <Button
                style={{ marginLeft: '5px', width: '120px', textAlign: 'center' }}
                onClick={this.back}
              >
                返回版本管理
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div className='version-index'>
        <PageHeaderLayout>
          <div className={'batchLayout'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table dataSource={versionPushListData} rowKey='id' bordered>
                <Column title='市场Id' dataIndex='marketId' key='marketId' />
                <Column
                  title='市场名称'
                  dataIndex='marketName'
                  key='marketName'
                  render={(text, record) => {
                    if (record.marketId === 0) {
                      return '所有市场';
                    } else if (text == null) {
                      return record.stallMarketName;
                    } else {
                      return text;
                    }
                  }}
                />
                <Column title='档口Id' dataIndex='stallId' key='stallId' />
                <Column
                  title='档口名称'
                  dataIndex='stallName'
                  key='stallName'
                  render={(text, record) => {
                    if (record.stallId === 0) {
                      return '所有档口';
                    } else {
                      return text;
                    }
                  }}
                />
                <Column title='环境' dataIndex='env' key='env' />
                <Column
                  title='发送状态'
                  dataIndex='status'
                  key='status'
                  render={(text) => {
                    if (text === 0) {
                      return '未发送';
                    } else if (text === 1) {
                      return '发送成功';
                    } else if (text === 2) {
                      return '发送失败';
                    }
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    return (
                      <div>
                        <Popconfirm
                          title='确认删除此配置？'
                          onConfirm={(e) => this.deleteVersionPush(e, record)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/'>
                            <span>{'删除'}</span>
                          </a>
                        </Popconfirm>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => this.openWarningModal(e, record)}
                          disabled={record.status !== 0}
                        >
                          推送更新预警
                        </a>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => this.pushSync(e, record)}
                          disabled={record.status !== 0}
                        >
                          推送更新
                        </a>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => this.resetVersionPushStatus(e, record)}
                          disabled={record.status === 0}
                        >
                          重置推送状态
                        </a>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Card>
              <Modal
                title='新增推送分组'
                visible={this.state.visibleModal}
                footer={null}
                onCancel={this.handleCancel}
                destroyOnClose='true'
                width='600px'
              >
                <div className={'extraContent'} style={{ width: '100%' }}>
                  <Form onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label='市场'>
                      {getFieldDecorator('marketId', {
                        rules: [
                          {
                            required: true,
                            message: ' 请选择市场!',
                          },
                        ],
                      })(
                        <Select
                          placeholder='请选择市场'
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={this.handleChange.bind(this)}
                          style={{ width: '100%' }}
                        >
                          {marketData.map((item) => (
                            <Option key={item.id}>{item.name}</Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='档口'>
                      {getFieldDecorator('stallId', {
                        rules: [
                          {
                            required: true,
                            message: '请输入档口!',
                          },
                        ],
                      })(
                        <Select
                          placeholder='请选择档口'
                          showSearch
                          allowClear
                          onChange={this.selectStall}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: '100%' }}
                        >
                          {allStallsData.map((item) => (
                            <Option key={item.id} value={item.id + '/' + item.marketId}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='环境'>
                      {getFieldDecorator('env', {
                        rules: [
                          {
                            required: true,
                            message: '请选择环境!',
                          },
                        ],
                      })(
                        <Select
                          placeholder='请选择环境'
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: '100%' }}
                        >
                          {envData.map((item) => (
                            <Option key={item.key}>{item.name}</Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem>
                      <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>
                          保存
                        </Button>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
            </Card>
            <Card>
              <Modal
                title='警告消息设置'
                visible={this.state.visibleMsgModal}
                footer={null}
                onCancel={this.handleMsgCancel}
                destroyOnClose='true'
                width='500px'
              >
                <div className={'extraContent'} style={{ width: '100%' }}>
                  <Form onSubmit={this.handleMsgSubmit}>
                    <FormItem {...formItemLayout} label='预警消息'>
                      {getFieldDecorator('warningMsg', {
                        initialValue: this.state.defaultWarningMsg,
                        rules: [
                          {
                            required: true,
                            message: ' 请输入预警消息!',
                          },
                        ],
                      })(<TextArea rows={5} placeholder='请输入预警消息' />)}
                    </FormItem>
                    <FormItem>
                      <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>
                          发送
                        </Button>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default PushConfig;
