import * as actionTypes from '../constant/voiceCDKEY';

//初始状态
const initState = {
  list: {},
  search: {},
};

//reducer
export function voiceCDKEY(state = initState, action) {
  switch (action.type) {
    case actionTypes.VOICE_CDKEY_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
