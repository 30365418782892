import request from '../../utils/request';

// 获取市场列表
export async function getMarketList(params) {
  return request({ url: 'market/getAllMarket', params });
}

// 获取省市区信息
export async function getAllDistrictInfo(params) {
  return request({ url: 'area/getAllDistrict', params });
}

// 修改对应id的enable字段
export async function getMarketInfo(params) {
  return request({ url: 'market/saveOrUpdateMarket', params });
}
// 启用/禁用数据服务
export async function enableDataService(params) {
  return request({ url: `market/enableDataService`, params, method: 'post', headers: 'json' });
}

// 获取对应id的市场信息
export async function getMarketInfoById(params) {
  return request({ url: 'market/getCxmMarketById', params });
}

// 大数据账号列表
export async function getBigDataAccountList(params) {
  return request({ url: 'market/bigDataAccountList', params });
}

// 大数据账号-新增、修改账号、修改密码
export async function updateBigDataAccount(params) {
  return request({ url: 'market/updateBigDataAccount', params });
}

// 大数据账号-启用、禁用
export async function updateEnable(params) {
  return request({ url: 'market/enableAccount', params });
}
// 获取商品库列表
export async function getGoodsList(params) {
  return request({
    url: 'basicProductResource/allProducts',
    params,
    method: 'get',
    headers: 'json',
  });
}
// 根据市场获得货品列表
export async function getGoodsListByMark(params) {
  const { marketId, pageNo, pageSize, productName } = params;
  return request({
    url: `marketProductResource/marketProducts/${marketId}`,
    params: { pageNo, pageSize, productName },
    method: 'get',
    headers: 'json',
  });
}

// 删除市场关联货品
export async function deleteMarketProduct(id) {
  return request({
    url: `marketProductResource/deleteMarketProduct`,
    params: { id },
    method: 'post',
    headers: 'json',
  });
}
// 增加市场关联基础货品
export async function addMarketProduct(params) {
  return request({
    url: `marketProductResource/addMarketProduct`,
    params,
    method: 'post',
    headers: 'json',
  });
}

export async function uploadMarketBusinessLicense(params) {
  return request({
    url: `file/uploadMarketBusinessLicense`,
    params,
    method: 'post',
    headers: 'form-data',
  });
}
