// 商品库管理-商品基础库-别名维护页面
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, Modal, Select, message } from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import './index.less';
import {
  stallWhiteList,
  fetchAllStallsConfig2,
  addStallWhite,
  removeStallWhite,
  offineAllStall,
} from '../../../state/action/stall';
const { confirm } = Modal;
const FormItem = Form.Item;
@connect(
  (state) => {
    return { stall: state.stall };
  },
  {
    getStallWhiteList: stallWhiteList,
    fetchAllStallsConfig: fetchAllStallsConfig2,
    addStallWhite: addStallWhite,
    removeStallWhite: removeStallWhite,
    offineAllStall: offineAllStall,
  },
)
@Form.create()
class WhiteListMaintain extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // 商行id
      stallId: 0,
    };
  }

  componentDidMount() {
    this.getStallWhiteList();
    this.getAllStallList({ flag: 1, marketId: null });
  }

  // 商行白名单
  async getStallWhiteList() {
    await this.props.getStallWhiteList({});
  }

  // 获取档口列表
  async getAllStallList(params) {
    await this.props.fetchAllStallsConfig(params);
  }

  // 下拉框改变
  onSelectChange = (value, index) => {
    this.setState({
      stallId: value,
    });
  };

  // 添加商行白名单
  async addStallWhite() {
    const { stallId } = this.state;
    let res = await this.props.addStallWhite({ stallId });
    if (res.code === 200) {
      message.success('新增白名单成功');
      this.getStallWhiteList();
      this.props.form.resetFields();
      this.setState({
        stallId: 0,
      });
    }
  }

  // 移除白名单
  async removeStallWhite(tag) {
    let res = await this.props.removeStallWhite({ stallId: tag });
    if (res.code === 200) {
      message.success('移除白名单成功');
      this.getStallWhiteList();
    }
  }

  // 一键下线
  async offineAll() {
    confirm({
      title: '确定要注销所有档主端的登录吗?',
      content: '',
      onOk: this.confirmOffineAllStall.bind(this),
      onCancel() {},
    });
  }

  // 确定下线
  async confirmOffineAllStall() {
    let res = await this.props.offineAllStall({});
    if (res.code === 200) {
      message.success('档主端注销登录成功');
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      stall: { stallWhitList = [], allStallsData = [] },
    } = this.props;
    const layout1 = { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 };
    return (
      <div className='commodity-index'>
        <PageHeaderLayout>
          <div className='commodityManage'>
            <Form>
              <Row gutter={24}>
                <Col {...layout1} className='mb'>
                  <FormItem>
                    {getFieldDecorator(
                      'stallName',
                      {},
                    )(
                      <Select
                        onChange={this.onSelectChange}
                        placeholder='请输入商行名称'
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '100%' }}
                      >
                        {allStallsData.map((item) => (
                          <Select.Option key={item.id}>{item.name}</Select.Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col {...layout1} className='mb'>
                  <Button
                    type='primary'
                    style={{ width: 80 }}
                    htmlType='submit'
                    disabled={!this.state.stallId ? true : false}
                    onClick={this.addStallWhite.bind(this)}
                  >
                    添加
                  </Button>
                  <Button
                    type='primary'
                    className='ml'
                    style={{ width: 80 }}
                    onClick={this.offineAll.bind(this)}
                  >
                    一键下线
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                {stallWhitList &&
                  stallWhitList.map((tag) => {
                    return (
                      <Button
                        className='tag-button'
                        onClick={this.removeStallWhite.bind(this, tag)}
                      >
                        <span className='tag-text'>{tag}</span>
                        <span className='tag-text ml'>x</span>
                      </Button>
                    );
                  })}
              </Row>
            </Form>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default WhiteListMaintain;
