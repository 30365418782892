import request from '../../utils/request';

// 导出车次结算 xujinkai 07-09
export async function getFileSelectList() {
  return request({ url: 'market/marketList' });
}

// 白名单查询
export async function getAllWhiteListByPage(params) {
  return request({ url: 'whiteList/getAllWhiteListByPage', params });
}
// 新增白名单
export async function addWhiteList(params) {
  return request({ url: 'whiteList/addWhiteList', params });
}
// 删除白名单
export async function deleteWhiteList(params) {
  return request({ url: 'whiteList/deleteWhiteList', params });
}

// 货品列表
export async function productList(params) {
  return request({
    url: `batchItem/list/${params.stallId}`,
    params,
    method: 'get',
    headers: 'json',
  });
}

// 分类列表
export async function categoryList(params) {
  return request({
    url: `category/list/${params.stallId}`,
    params,
    method: 'get',
    headers: 'json',
  });
}
