/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-05 10:47:32
 * @LastEditors: lijing
 * @LastEditTime: 2022-07-18 20:23:35
 */
import * as actionTypes from '../constant/personManager';
import * as req from '../request/personManager';

// 列表
export function getList(params) {
  return async (dispatch) => {
    const res = await req.getList(params);
    if (res) {
      dispatch({ type: actionTypes.PERSON_MANAGER_GET_LIST, payload: res });
    }
  };
}
