import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Modal,
  Checkbox,
  Divider,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  orgAccountList,
  stallDelete,
  createOrgAccount,
  removeOrgAccount,
  queryStall,
} from '../../state/action/org';
import { getOrgUserStall, updateUserStall } from '@/state/request/org';

import './style.less';
import { isEmptyObject } from '../../utils/reg';

const { Column } = Table;
const FormItem = Form.Item;
const { confirm } = Modal;

@connect(
  (state) => {
    return { org: state.org };
  },
  { orgAccountList, stallDelete, createOrgAccount, removeOrgAccount, queryStall },
)
@Form.create()
class BoothOrgRelationAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.id = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    this.selectedRecord = null;
    this.state = {
      checked: [],
      loading: false,
      visible: false,
      modalInfo: [
        {
          id: this.id || 0,
          account: '',
          text: '新建',
        },
      ],
    };
  }
  componentDidMount() {
    this.queryStall(this.id);
    this.orgAccountList(this.id);
  }

  getOrgUserStallList = async (personId) => {
    const data = await getOrgUserStall(personId);
    this.setState({
      checked: data.data || [],
    });
  };

  updateUserStallList = async () => {
    const { checked } = this.state;
    const params = {
      personId: this.selectedRecord.personId,
      stallIdList: checked,
    };
    if (checked && checked.length > 0) {
      const data = await updateUserStall(params);
      const { code } = data;
      if (code === 200) {
        message.success('保存成功');
        this.closeModal();
      }
    } else {
      message.warn('每个账号至少保留一个商行！');
    }
  };

  //查询机构辖下账号
  orgAccountList(id) {
    this.props.orgAccountList({ orgId: id });
  }

  //查询机构辖下商行
  queryStall(id) {
    this.props.queryStall({ orgId: id });
  }
  /***
   *
   *  查询部分
   *
   * ***/
  // 修改或者新增的接口
  updateEnable = async (e, values) => {
    e.preventDefault();

    const that = this;
    confirm({
      title: '确定要移除吗?',
      content: '',
      async onOk() {
        that.setState({ loading: true });
        const { orgId, account } = values;
        await that.props.removeOrgAccount({ orgId, account });
        const {
          org: { removeOrgAccountData },
        } = that.props;

        if (removeOrgAccountData.code === 200) {
          message.success(removeOrgAccountData.msg);
        } else {
          message.error(removeOrgAccountData.msg);
        }
        that.orgAccountList(that.id);
        that.setState({ loading: false });
      },
      onCancel() {},
    });
  };

  configStall = async (e, values) => {
    e.preventDefault();
    this.selectedRecord = values;
    this.getOrgUserStallList(values.personId);
    this.setState({ visible: true });
  };

  back = () => {
    this.props.history.push('/booth/orgManagement');
  };
  showModal = () => {
    this.setState({
      show: true,
      modalTital: '新建账号',
      modalInfo: [
        {
          id: this.id || 0,
          account: '',
          text: '新建',
        },
      ],
    });
  };
  // 点击modal下面的新建（保存）按钮
  createOrgAccount = (e) => {
    e.preventDefault();
    if (this.refs.saveOrUpdate.props.children === '新建') {
      this.props.form.validateFields(['id', 'account'], (errors, values) => {
        if (!!errors) {
          return;
        }

        this.saveOrg({
          orgId: values.id,
          account: values.account,
        });
      });
    }
  };
  // 新增或者修改的接口
  saveOrg = async (values) => {
    this.setState({ loading: true });
    await this.props.createOrgAccount(values);
    const {
      org: { createOrgAccountDate },
    } = this.props;
    if (createOrgAccountDate.code === 200) {
      message.success(createOrgAccountDate.msg);
      this.setState({
        show: false,
      });
    }
    this.orgAccountList(this.id);
    this.setState({ loading: false });
  };

  //点击遮罩层或者 x 号让modal消失
  modalHide = () => {
    this.setState({ show: false });
  };

  closeModal = () => {
    this.setState({ visible: false, checked: [] });
  };

  render() {
    const { orgAccountListData: { code, data } = {}, stallData = {} } = this.props.org;
    const { visible, checked } = this.state;
    const { data: checkList = [] } = stallData;
    let orgList = [];
    if (code === 200) {
      orgList = data;
    }
    const dataSource = orgList;
    const extraContent = (
      <div className={'searchModule'}>
        <Row gutter={24}>
          <Col span={24}>
            <Button type='primary' onClick={this.back}>
              返回集团列表
            </Button>
            <Button type='primary' disabled onClick={this.showModal}>
              添加账号
            </Button>
          </Col>
        </Row>
      </div>
    );
    const { getFieldDecorator } = this.props.form;
    // 响应式col
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    return (
      <div className='org-management'>
        <PageHeaderLayout>
          <div className={'basicSetting'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                bordered
                pagination={false}
              >
                <Column title='姓名' dataIndex='name' key='name' />
                <Column title='手机号' dataIndex='account' key='account' />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={() => {
                    return (
                      <div>
                        <span className='disabled'>移除</span>
                        <Divider type='vertical' />
                        <span className='disabled'>配置商行</span>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>

            <Modal
              className={'modal'}
              title={this.state.modalTital}
              visible={this.state.show}
              onCancel={this.modalHide}
              destroyOnClose='true'
              width='700px'
              footer={[]}
            >
              {this.state.modalInfo.map((inputValue) => {
                return (
                  <Form onSubmit={this.createOrgAccount} key={inputValue.id}>
                    <FormItem {...formItemLayout} label='id' style={{ display: 'none' }}>
                      {getFieldDecorator('id', {
                        initialValue: inputValue.id,
                      })(<Input />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label='手机号'>
                      {getFieldDecorator('account', {
                        initialValue: inputValue.account,
                        rules: [{ required: true, message: '请输入手机号!' }],
                      })(<Input placeholder='请输入手机号' phone />)}
                    </FormItem>
                    <div style={{ display: 'flex', height: '36px' }}>
                      <Button
                        style={{ float: 'right', marginRight: '5px', marginTop: '5px' }}
                        type='primary'
                        htmlType='submit'
                        ref='saveOrUpdate'
                      >
                        {inputValue.text}
                      </Button>
                    </div>
                  </Form>
                );
              })}
            </Modal>
          </div>
        </PageHeaderLayout>
        <Modal
          title='集团名称'
          visible={visible}
          onCancel={this.closeModal}
          destroyOnClose='true'
          width='300px'
          footer={
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={this.closeModal}>取消</Button>
              <Button
                type='primary'
                onClick={() => this.updateUserStallList()}
                style={{ marginLeft: '18px' }}
              >
                确定
              </Button>
            </Row>
          }
        >
          <Checkbox.Group
            style={{
              maxHeight: '300px',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
            }}
            onChange={(e) => {
              this.setState({
                checked: e,
              });
            }}
            value={checked}
            options={checkList
              .filter((g) => g.enable === true)
              .map((g) => ({
                label: g.stallName,
                value: g.stallId,
                disabled: g.stallId === (this.selectedRecord && this.selectedRecord.stallId),
              }))}
          />
        </Modal>
      </div>
    );
  }
}
export default BoothOrgRelationAccount;
