// 数据服务管理-数据服务基库货品
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Form, Select, Button, Table, Row, Col, DatePicker, Input } from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { marketList } from '../../state/action/stall';
import { exportMarketProductList, getMarketProductList } from '../../state/request/dataService';
import { exportFile } from '../../utils/utils';
import './goodsSale.less';
const FormItem = Form.Item;
const { Column } = Table;
const defaultTime = moment().milliseconds(0).second(0).minute(0).hour(0).valueOf(); //默认当前时间0点
@connect(
  (state) => {
    return { stall: state.stall };
  },
  { marketList },
)
@withRouter
@Form.create()
class GoodsSale extends PureComponent {
  constructor(props) {
    super(props);
    const { searchValue1 = {}, pageNo1 } = this.props.history.location.state || {};
    this.state = {
      loading: false,
      pageNo: pageNo1 || 1,
      searchValue: {
        dateStartTime: searchValue1.dateStartTime || defaultTime, //默认当前时间0点
        marketId: searchValue1.marketId || 0,
        productName: searchValue1.productName || '',
      },
      data: {},
    };
  }

  componentDidMount() {
    this.getMarketList();
    this.getData(1, this.state.searchValue);
  }

  getMarketList = async () => {
    await this.props.marketList({});
  };

  /**
   * 获取数据源
   */
  getData = async (pageNo, searchValue) => {
    this.setState({ loading: true });
    const param = {
      dateStartTime: searchValue.dateStartTime || defaultTime,
      marketId: searchValue.marketId || undefined,
      productName: searchValue.productName || undefined,
      pageNo,
      pageSize: 10,
    };
    const response = await getMarketProductList(param);
    const { code, data } = response;
    if (code === 200) {
      this.setState({ data, loading: false });
    }
  };

  handleSearch = (f) => {
    f.preventDefault(); //防止提交刷新页面
    this.props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }
      const { dateStartTime, marketId, productName } = values;
      const searchValue = {
        dateStartTime: dateStartTime.milliseconds(0).second(0).minute(0).hour(0).valueOf(),
        marketId,
        productName,
      };
      this.setState({ pageNo: 1, searchValue });
      this.getData(1, searchValue);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    const defaultSearchValue = {
      dateStartTime: defaultTime, //默认当前时间0点
      marketId: 0,
      productName: '',
    };
    this.setState({
      pageNo: 1,
      searchValue: defaultSearchValue,
    });
    this.getData(1, defaultSearchValue);
  };

  handleExport = () => {
    this.props.form.validateFields(async (errors, values) => {
      if (errors) {
        return;
      }
      const { dateStartTime, marketId, productName } = values;
      const searchValue = {
        dateStartTime: dateStartTime.valueOf(),
        marketId,
        productName,
      };
      const response = await exportMarketProductList(searchValue);
      exportFile(response, '数据服务基库货品.xlsx');
    });
  };

  onChangeTable = (pagination, filters, sorter, extra) => {
    this.setState({ pageNo: pagination.current });
    this.getData(pagination.current, this.state.searchValue);
  };

  // 开始日期 小于系统当前日期 且当前日期置灰不可选
  disabledStartDate = (current) => {
    return current.valueOf() > moment().valueOf();
  };

  // 展示货品管理
  toDetail = (e, record) => {
    e.preventDefault();
    const { searchValue, pageNo } = this.state;
    this.props.history.push({
      pathname: `/data-service/goodsSale/${record.basicProductId}`,
      state: {
        record1: record,
        searchValue1: searchValue,
        pageNo1: pageNo,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      stall: { marketData = [] },
    } = this.props;
    const {
      data: { pageList, totalSize },
      loading,
      pageNo,
      searchValue,
    } = this.state;
    const dataSource = pageList || [];
    const pagination = {
      current: pageNo,
      pageSize: 10,
      total: totalSize,
    };

    const searchItemLayout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };

    return (
      <div className='goods-sale'>
        <PageHeaderLayout>
          <div className='content'>
            <div className='header'>
              <Form layout='inline' onSubmit={this.handleSearch}>
                <Row gutter={12}>
                  <Col {...searchItemLayout}>
                    <FormItem label='时间'>
                      {getFieldDecorator('dateStartTime', {
                        initialValue: moment(searchValue.dateStartTime) || moment(),
                        rules: [{ required: true, message: '请选择开始日期' }],
                      })(
                        <DatePicker
                          disabledDate={this.disabledStartDate}
                          format='YYYY-MM-DD'
                          placeholder='请选择日期'
                        />,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...searchItemLayout}>
                    <FormItem label='市场'>
                      {getFieldDecorator('marketId', {
                        initialValue: searchValue.marketId || undefined,
                        rules: [{ required: false, message: '请选择市场!' }],
                      })(
                        <Select
                          placeholder='请选择市场'
                          style={{ minWidth: '100%' }}
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {marketData.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                  </Col>
                  <Col {...searchItemLayout}>
                    <FormItem label='货品'>
                      {getFieldDecorator('productName', {
                        initialValue: searchValue.productName || undefined,
                      })(<Input placeholder='请输入货品' allowClear />)}
                    </FormItem>
                  </Col>
                  <Col {...searchItemLayout}>
                    <FormItem label=''>
                      <Button type='primary' htmlType='submit'>
                        查询
                      </Button>
                      <Button style={{ marginLeft: '16px' }} onClick={this.handleReset}>
                        重置
                      </Button>
                      <Button style={{ marginLeft: '16px' }} onClick={this.handleExport}>
                        导出
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </div>
            <Table
              rowKey={(_, index) => index}
              dataSource={dataSource}
              pagination={pagination}
              bordered
              loading={loading}
              onChange={this.onChangeTable}
            >
              <Column
                title='序号'
                width={100}
                dataIndex='index'
                key='index'
                render={(_, __, index) => {
                  return (pagination.current - 1) * pagination.pageSize + index + 1;
                }}
              />
              <Column title='货品名称' dataIndex='basicProductName' key='basicProductName' />
              <Column title='市场名称' dataIndex='marketName' key='marketName' />
              <Column title='单数' dataIndex='totalOrder' key='totalOrder' />
              <Column title='均价(元/公斤)' dataIndex='avgUnitPrice' key='avgUnitPrice' />
              <Column
                title='数据服务'
                dataIndex='enableDataService'
                key='enableDataService'
                render={(_, record) => {
                  const enable = record.enableDataService;
                  return (
                    <span className={`${enable ? 'open' : 'close'}`}>
                      {enable ? '展示' : '未展示'}
                    </span>
                  );
                }}
              />
              <Column
                title='操作'
                width={150}
                dataIndex='option'
                key='option'
                render={(_, record) => {
                  return (
                    <a
                      href='/'
                      onClick={(e) => {
                        e.preventDefault();
                        this.toDetail(e, record);
                      }}
                    >
                      查看详情
                    </a>
                  );
                }}
              />
            </Table>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default GoodsSale;
