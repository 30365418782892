import request from '../../utils/request';
/**
 * 支付流水查询
 * @param {objcet} params
 * @returns
 */
export async function fetchRepayRecord(params) {
  return request({ url: `payRecord/list`, headers: 'json', method: 'post', params });
}

export async function exportRepayRecord(params) {
  return request({
    url: `payRecord/list/download`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}
