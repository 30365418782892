/**
 * 附加费
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Button } from 'antd';

import styles from '../style.less';
import PaymentTypePage from './PaymentType';

let id = 0;

@connect(({ count }) => ({
  count,
}))
@Form.create()
class PaymentPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      descData: JSON.parse(localStorage.getItem('count_desc_data')),
      comps: [],
    };
  }

  componentDidMount() {
    const reqStallId = localStorage.getItem('reqStallId') || 0;
    this.fetchAllStallConfig({ reqStallId: reqStallId });
  }

  fetchAllStallConfig(params) {
    this.props.dispatch({
      type: 'count/fetchAllStallConfig',
      payload: params,
    });
  }

  render() {
    const {
      count: { allStallConfigData },
    } = this.props;
    const { descData, comps } = this.state;

    return (
      <div className={styles.tabLayout}>
        <PaymentTypePage
          type={this.props.type}
          data={descData}
          formulaData={allStallConfigData || []}
          key={this.state.descData}
          title={this.props.title}
        />
        {comps.map((k, index) => {
          return (
            <PaymentTypePage
              type={this.props.type}
              data={descData}
              formulaData={allStallConfigData || []}
              key={k}
              title={this.props.title}
            />
          );
        })}
        <Card title='附加费元素选择' bordered={false}>
          <div className={styles.add}>
            <Button
              type='primary'
              icon='plus'
              onClick={() => this.setState({ comps: comps.concat('sec' + ++id) })}
            >
              新增附加费计算
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}
export default PaymentPage;
