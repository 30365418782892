// 批次管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Col,
  Button,
  Table,
  Row,
  Popconfirm,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getSupplierAppList,removeSupplierAppAccount } from '../../state/action/stall';
import './index.less';

const FormItem = Form.Item;

@Form.create()
@connect(
  state => { return { stall: state.stall } },
  {getSupplierAppList, removeSupplierAppAccount}
)
class BatchManager extends PureComponent {
  constructor(props) {
    super(props);
    //子页面返回过来的record
    this.BackRecord = (this.props.location.state && this.props.location.state.record) || {};
    this.state = {
      loading: false,
      pageNo: 1,
    };
  }

  componentDidMount() {
    this.getList();
  }

  componentWillUnmount() {
    
  }

  // 获取table数据
  async getList() {
    this.setState({ loading: true, });
    this.props.getSupplierAppList();
    this.setState({ loading: false, });
  }

  // 根据条件搜索列表
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["name","code"], (error, value) => {
      if (!error) {
        this.getList();
      }
    })
  }
  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    })
    this.getList();
  }

  removeAccount = (record) => {
    this.props.removeSupplierAppAccount(record);
    this.getList();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      stall: { supplierAppList },
    } = this.props;

    let list = [];
    let pageEntity = {};

    if (supplierAppList) {
      list = supplierAppList;
    }
    var totalSize = 0;
    if (pageEntity === undefined) {
    } else {
      totalSize = pageEntity.totalSize;
    }

    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
      // style: { marginBottom: 2 },
    };

    // 表格数据源
    const dataSource = list;
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
    }

    const extraContent = (
      <div className="extraContent">
        <Form 
          layout="inline" 
          onSubmit={this.handleSearch}
        >
          <Row>
            <Button type="primary" htmlType="submit">查询</Button>
          </Row>
        </Form>
      </div>
    );

    return (<div className="sys-index">
      <PageHeaderLayout>
        <div className={'system-log'}>
          <Card className="" bordered={false} title="" extra={extraContent}>
            <Table
              rowKey={(record, index) => index}
              dataSource={dataSource}
              pagination={pagination}
              loading={this.state.loading}
              onChange={this.pageChange.bind(this)}
            >
              <Col align="center" title="登录账号" dataIndex="name" key="name" 
              render={(text, record) => {
                return record;
              }}
              />      
              <Col align="center" title="操作" dataIndex="oper" key="oper"
                render={(text, record) => {
                  return <div>                   
                      <Popconfirm
                      title='是否确定移除'
                      onConfirm={(e) => this.removeAccount(record)}
                      okText='确认'
                      cancelText='取消'
                    >
                      <a href='/'>
                        <span>移除</span>
                      </a>
                    </Popconfirm>
                    </div>;
                }}
              />
            </Table>
          </Card>

        </div>
      </PageHeaderLayout>
    </div>
    );
  }
}

export default BatchManager;
