// 后端api地址
let api_admin = 'http://dev.api.caixm.cn/caixmAdmin/';
let url_store = 'http://test.h5.caixm.cn/#/cml/h5/store';
let api_stall = 'http://dev.api.caixm.cn/cxm-stall/'; //档主端的接口
let api_export = 'http://dev.api.caixm.cn/cxm-export/'; // 数据导出

const NODE_ENV = process.env.REACT_APP_API; //process.env.NODE_ENV;

if (NODE_ENV === 'dev') {
  //开发环境
  api_admin = 'http://dev.api.caixm.cn/caixmAdmin/';
  url_store = 'http://test.h5.caixm.cn/#/cml/h5/store';
  api_stall = 'http://dev.api.caixm.cn/cxm-stall/';
  api_export = 'http://dev.api.caixm.cn/cxm-export/';
} else if (NODE_ENV === 'test') {
  //测试环境
  api_admin = 'http://test.api2x.caixm.cn/caixmAdmin/';
  url_store = 'http://test.h5.caixm.cn/#/cml/h5/store';
  api_stall = 'http://test.api2x.caixm.cn/cxm-stall/';
  api_export = 'http://test.api.caixm.cn/cxm-export/';
} else if (NODE_ENV === 'preview') {
  //预发布
  api_admin = 'http://pre.api2x.caixm.cn/caixmAdmin/';
  url_store = 'http://pre.newh5.caixm.cn/#/cml/h5/store';
  api_stall = 'http://pre.api3x.caixm.cn/cxm-stall/';
  api_export = 'https://pre.api.caixm.cn/cxm-export/';
} else if (NODE_ENV === 'production') {
  //正式环境
  api_admin = 'https://stall.admin.caixm.cn/caixmAdmin/';
  url_store = 'http://newh5.caixm.cn/#/cml/h5/store';
  api_stall = 'https://stall.mp.caixm.cn/cxm-stall/';
  api_export = 'https://api.caixm.cn/cxm-export/';
  //如果是正式环境，需要从http:跳转到https://
  var protocolStr = document.location.protocol;
  var hostname = document.location.hostname;
  if (protocolStr === 'http:' && hostname !== 'localhost') {
    console.log('protocol = ' + protocolStr);
    var url = window.location.href;
    window.location.href = 'https://' + url.substring(7, url.length);
  }
  //正式环境去掉log日志
  window.console.log = function () {};
} else {
  //本地开发环境
  api_admin = 'http://dev.api.caixm.cn/caixmAdmin/';
  // api_admin = 'http://test.api2x.caixm.cn/caixmAdmin/';
  // api_admin = 'http://pre.api2x.caixm.cn/caixmAdmin/';
  // api_admin = 'https://stall.admin.caixm.cn/caixmAdmin/';
  // api_admin = 'http://192.168.5.106:8080/caixmAdmin/'

  url_store = 'http://test.h5.caixm.cn/#/cml/h5/store';

  api_stall = 'http://dev.api.caixm.cn/cxm-stall/';
  // api_stall = 'http://test.api2x.caixm.cn/cxm-stall/';
  // api_stall = 'http://pre.api3x.caixm.cn/cxm-stall/';
  // api_stall = 'https://stall.mp.caixm.cn/cxm-stall/';

  api_export = 'http://dev.api.caixm.cn/cxm-export/'
  // api_export = 'http://192.168.4.95:8088/cxm-export/';
}

export const API_ADMIN = api_admin; //后端接口
export const API_ADMIN2 = 'https://stall.api3x.caixm.cn/caixmAdmin/'; //后端接口
export const URL_STORE = url_store; //后端接口
export const API_STALL = api_stall; //后端接口
export const API_EXPORT = api_export; //后端接口
