//商行管理--设备管理列表
import request from '../../utils/request';

// 销售人员列表
export async function querySaleList(params) {
  return request({ url: 'sale/list', params, method: 'get', headers: 'json' });
}
//新增销售人员
export async function addSale(params) {
  return request({ url: 'sale/add', headers: 'json', method: 'post', params });
}
//修改销售人员
export async function updateSale(params) {
  return request({
    url: 'sale/update',
    headers: 'json',
    method: 'put',
    params,
  });
}
//启用/禁用
export async function updateSaleEnable(params) {
  return request({ url: 'sale/enable/update', params, method: 'put', headers: 'json' });
}

//获取所有销售信息
export async function getAllSale() {
  return request({ url: 'sale/dropDown/list', method: 'get', headers: 'json' });
}
