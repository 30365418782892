import * as actionTypes from '../constant/global';
import * as req from '../request/global';
import { getStallInfo, setStallInfo } from '../../utils/authority';

export function saveStall(params) {
  return async (dispatch) => {
    let stall = params || getStallInfo();
    setStallInfo(stall);
    dispatch({ type: actionTypes.GLOBAL_SAVE_STALL, payload: stall });
  };
}
export function changeLayoutCollapsed(params) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.GLOBAL_CHANGE_LAYOUT_COLLAPSED, payload: params });
  };
}
export function clearNotices(params) {
  return async (dispatch) => {
    const res = await req.clearNotices(params);
    if (res) {
      dispatch({ type: actionTypes.GLOBAL_CLEAR_NOTICES, payload: res });
    }
  };
}
export function saveNotices() {
  return async (dispatch) => {
    const res = await req.getNotices();
    if (res) {
      dispatch({ type: actionTypes.GLOBAL_SAVE_NOTICES, payload: res });
    }
  };
}
