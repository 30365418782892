// 商行管理 -- 商行管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Form, Button, Input, message, Table, Row, Col, Upload } from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';
import { getAuthorityUserInfo } from '../../utils/authority';
import './style.less';

import { listAll } from '../../state/action/voiceCDKEY';

const { Column } = Table;
const FormItem = Form.Item;

const userInfo = getAuthorityUserInfo();
const userId = (userInfo && userInfo.user_id) || -1;

@connect(
  (state) => {
    return { voiceCDKEY: state.voiceCDKEY };
  },
  { listAll },
)
@Form.create()
class Index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      pageSize: 10,
      loading: false,
    };
  }

  componentDidMount() {
    const {
      voiceCDKEY: { search = {} },
    } = this.props;
    this.getActivationCodeList({ ...search, pageNo: 1, pageSize: 10 });
  }

  // 获取商行列表
  async getActivationCodeList(params) {
    this.setState({ loading: true });
    await this.props.listAll(params);
    this.setState({ loading: false });
  }

  // 查询
  handleSearch = (e) => {
    e.preventDefault();

    this.props.form.validateFields(
      ['activationCode', 'deviceSn', 'stallName'],
      (errors, values) => {
        if (errors) {
          return;
        }
        this.getActivationCodeList({ ...values, pageNo: 1, pageSize: 10 });
      },
    );
  };

  //分页
  setPageChange = (e) => {
    const {
      voiceCDKEY: { search },
    } = this.props;
    this.getActivationCodeList({
      ...search,
      pageNo: e.current,
      pageSize: e.pageSize,
    });
  };

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.getActivationCodeList({ pageSize: 10, pageNo: 1 });
  };

  //上传之前拦截下
  beforeUpload = (file) => {
    const str = file.name.split('.')[1];
    const isRightType = str === 'xls' || str === 'xlsx';
    const limitSize = file.size / 1024 / 1024 < 1;
    if (!isRightType) {
      message.error('仅支持Excel文件!', 3);
    }
    if (!limitSize) {
      message.error('文件大小不超过1M');
    }

    return limitSize;
  };
  //上传之后的改变事件
  handleChangeByUpload = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
    }
    if (info.file.status === 'done') {
      this.setState({ loading: false });
      if (info.file.response.code === 200) {
        message.success(`${info.file.name} 上传成功!`, 5);

        // 刷新页面
        this.getActivationCodeList({ pageNo: 1, pageSize: 10 });
      } else if (info.file.response.code === 8009) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(info.file.response.msg, 5);
      }
    } else if (info.file.status === 'error') {
      if (info.file && info.file.response && info.file.response.msg) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(`${info.file.name} 上传失败`, 5);
      }
      this.setState({ loading: false });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      voiceCDKEY: { list = {}, search = {} },
    } = this.props;

    const { pageList, pageNo = 1, totalSize = 1 } = list;
    let dataSource = pageList || [];
    const pagination = {
      pageSize: 10,
      total: totalSize,
      current: pageNo,
    };
    const layout = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 };

    //上传合同或者设备用到的参数
    const uploadParams = {
      createUid: userId,
    };
    //批量导入激活码
    const uploadActivationCode = {
      name: 'file',
      action: `${API_ADMIN}device/activationCode/import?${generalParamsWithPToStr(
        'device/activationCode/import',
        uploadParams,
      )}`,
      headers: { authorization: 'authorization-text' },
      beforeUpload: this.beforeUpload,
      showUploadList: false,
      onChange: this.handleChangeByUpload,
    };

    return (
      <PageHeaderLayout>
        <div className='StallManagement'>
          <Form onSubmit={this.handleSearch} className='ant-advanced-search-form'>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem label='激活码'>
                  {getFieldDecorator('activationCode', {
                    initialValue: search.activationCode,
                  })(<Input placeholder='请输入激活码' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='SN码'>
                  {getFieldDecorator('deviceSn', {
                    initialValue: search.deviceSn,
                  })(<Input placeholder='请输入SN码' allowClear />)}
                </FormItem>
              </Col>
              <Col {...layout}>
                <FormItem label='商行名称'>
                  {getFieldDecorator('stallName', {
                    initialValue: search.stallName,
                  })(<Input placeholder='请输入商行名称' allowClear />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <FormItem>
                  <Button type='primary' htmlType='submit'>
                    查询
                  </Button>
                  <Button style={{ marginLeft: '18px' }} onClick={this.handleReset}>
                    重置
                  </Button>
                  <div className='template-download'>
                    <Button
                      type='primary'
                      style={{ marginLeft: '18px' }}
                      target='_blank'
                      onClick={() =>
                        window.open(
                          `${API_ADMIN}device/activationCode/downloadTemplet?${generalParamsWithPToStr(
                            'device/activationCode/downloadTemplet',
                            {},
                          )}`,
                        )
                      }
                    >
                      <span>激活码模板</span>
                      <span>下载</span>
                    </Button>
                  </div>
                  <Upload {...uploadActivationCode}>
                    <Button
                      type='primary'
                      icon='upload'
                      target='_blank'
                      style={{ marginLeft: '18px' }}
                    >
                      激活码导入
                    </Button>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
          </Form>

          <Table
            dataSource={dataSource}
            pagination={pagination}
            rowKey={(_, index) => index}
            align='center'
            onChange={this.setPageChange.bind(this)}
            loading={this.state.loading}
            scroll={{ x: 'max-content' }}
          >
            <Column
              title='语音包激活码'
              dataIndex='activationCode'
              key='activationCode'
              width={400}
            />
            <Column
              title='APP品牌'
              dataIndex='appBrand'
              width={120}
              render={(type) => {
                return type === 1 ? '农可以' : '菜小秘';
              }}
            />
            <Column title='绑定设备SN码' dataIndex='deviceSn' key='deviceSn' width={200} />
            <Column title='设备名称' dataIndex='deviceName' key='deviceName' width={200} />
            <Column title='设备关联商行' dataIndex='stallName' key='stallName' width={200} />
            <Column
              title='状态'
              dataIndex='enable'
              key='enable'
              width={200}
              render={(_, record) => {
                const { enable } = record;
                return <span>{enable ? '启用' : '禁用'}</span>;
              }}
            />
            <Column title='备注' dataIndex='remark' key='remark' />
          </Table>
        </div>
      </PageHeaderLayout>
    );
  }
}
export default Index;
