// 查看详情-合同管理
import React, { PureComponent } from 'react';

import { Form, Button, InputNumber, message, Table, Modal, Row, Col } from 'antd';
import { getSignList, addSign } from '../../../../state/request/stall';

import './device.less';
import { columns } from './SignatureUtils';
const FormItem = Form.Item;

const layout = { xs: 24, sm: 12, md: 24, lg: 24, xl: 24 };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@Form.create()
class SettlementFee extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      amount: 0,
      pageNo: 1,
      totalSize: 0,
      signList: [],
    };
  }

  async componentDidMount() {
    this.settlementFeeList();
  }

  async settlementFeeList(params) {
    this.setState({ loading: true });
    const { data, code } = await getSignList(this.state.pageNo, {
      stallId: this.props.stallId,
    });
    if (code === 200) {
      this.setState({
        signList: data.pageList.map((item, index) => {
          item.index = index + 1;
          return item;
        }),
        totalSize: data.totalSize,
      });
    }
    this.setState({ loading: false });
  }

  //点击新增和编辑Modal弹框
  showModal = (visible) => {
    this.setState({
      visible,
      amount: undefined,
    });
    if (!visible) {
      this.props.form.resetFields();
    }
  };

  // 新增, 修改
  addPact = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err) => {
      if (!err) {
        const params = {
          stallId: this.props.stallId,
          amount: this.state.amount,
          signQuota: this.state.amount * 2000,
        };
        this.addSettleFee(params);
      }
    });
  };

  async addSettleFee(params) {
    const result = await addSign(params);
    if (result.code === 200) {
      this.setState({ visible: false, amount: undefined });
      this.props.form.resetFields();
      message.success('新增成功');
      this.setState({ pageNo: 1 }, () => this.settlementFeeList());
    } else {
      message.error(result.msg);
    }
  }

  changeAmount = (value) => {
    this.setState({ amount: value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, loading, signList, totalSize } = this.state;

    return (
      <div className='basketSettings'>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type='primary' icon='plus' onClick={() => this.showModal(true)}>
              添加签字额度
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(_, index) => index}
          dataSource={signList}
          columns={columns}
          pagination={{
            total: totalSize,
            onChange: (value) =>
              this.setState({ pageNo: value }, () => {
                this.settlementFeeList();
              }),
          }}
          loading={loading}
        />
        <Modal
          title='添加签字额度'
          visible={visible}
          onCancel={() => this.showModal(false)}
          footer={null}
          className={'deviceModalStyle SignatureModal'}
          width='450px'
          style={{ maxHeight: 700 }}
        >
          <Form layout='inline' onSubmit={this.addPact}>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='实际价值'>
                  {getFieldDecorator('amount', {
                    rules: [
                      {
                        required: true,
                        message: '请输入实际价值',
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder='请输入实际价值'
                      max={999999.99}
                      allowClear
                      onChange={this.changeAmount}
                      autofocus
                    />,
                  )}
                  元
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col {...layout}>
                <FormItem {...formItemLayout} label='签字额度'>
                  <label>{this.state.amount * 2000 || 0}</label>元
                </FormItem>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => this.showModal(false)}>关闭</Button>
              <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                保存
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default SettlementFee;
