import * as actionTypes from '../constant/caixm';

//初始状态
const initState = {
  caixmPhone: {},
  searchCaixmPhone: {},
  dailyAddStall: {},
  dailAddStallParams: {},
  dailyBussinessStall: {},
  dailBussinessStallParams: {},
};

//reducer
export function caixm(state = initState, action) {
  switch (action.type) {
    case actionTypes.CAIXM_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
