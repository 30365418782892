// 车次管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Input,
  Tooltip,
  Select,
  Table,
  Modal,
  message,
  Popconfirm,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  fetchAllConfig,
  fetchConfig,
  getAllSysConfigs,
  saveOrUpdateGroupConfig,
  updateGroupConfig,
} from '../../state/action/stall';
import { fileSelectList } from '../../state/action/batch';

import '../booth-management/index.less';
import { isEmptyObject } from '../../utils/reg';

const FormItem = Form.Item;
const Option = Select.Option;
const { Column } = Table;
const { TextArea } = Input;

@connect(
  (state) => {
    return { stall: state.stall, batch: state.batch };
  },
  {
    fileSelectList,
    fetchAllConfig,
    fetchConfig,
    getAllSysConfigs,
    saveOrUpdateGroupConfig,
    updateGroupConfig,
  },
)
@Form.create()
class BoothConfig extends PureComponent {
  componentDidMount() {
    const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    this.fetchAllConfig({
      searchGroupNo: groupNo,
      pageNo: 1,
      pageSize: 10,
    });
    this.getAllSysConfigs({});
    this.props.fileSelectList();

    this.setState({
      groupNo: groupNo,
      config: {},
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      visibleModal: false,
      value: 1,
      id: '',
      type: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
  }

  async fetchAllConfig(params) {
    await this.props.fetchAllConfig(params);
  }

  // 获取档口修改数据
  async fetchConfig(params) {
    await this.props.fetchConfig(params);
  }

  // 获取配置列表
  async getAllSysConfigs(params) {
    await this.props.getAllSysConfigs(params);
  }

  // 修改档口配置
  async saveOrUpdate(params) {
    await this.props.saveOrUpdateGroupConfig(params);
    this.submit();
  }

  // 删除档口配置
  async stallDelete(params) {
    await this.props.updateGroupConfig(params);
    this.delete();
  }

  // 分页和查询请求
  modifyStatusBtn() {
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const searchDisplayName = fieldsValue.searchDisplayName;
      const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
      this.fetchAllConfig({
        searchDisplayName: searchDisplayName,
        searchGroupNo: groupNo,
        pageNo: 1,
        pageSize: 10,
      });
    });
  }

  // 分页切换
  pageSwitch = (obj) => {
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      const searchDisplayName = fieldsValue.searchDisplayName;
      const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
      this.fetchAllConfig({
        searchDisplayName: searchDisplayName,
        searchGroupNo: groupNo,
        pageSize: obj.pageSize,
        pageNo: obj.current,
      });
    });
  };

  // modal关闭事件
  handleCancel(e) {
    this.setState({
      visibleModal: false,
    });
  }

  // 修改数据
  modify(id) {
    this.fetchConfig({
      id: id,
    });
    this.setState({
      visibleModal: true,
      type: 'modify',
    });
  }

  // 删除档口数据
  deleteMessage(id, configUuid) {
    this.stallDelete({
      id: id,
      configUuid: configUuid,
    });
  }

  // 修改信息的回调
  submit() {
    const {
      stall: { saveOrUpdateData },
    } = this.props;

    if (saveOrUpdateData.code === 200) {
      message.success('操作成功');
    } else {
      message.error(saveOrUpdateData.msg);
    }
    this.modifyStatusBtn();
  }

  // 删除档口信息回调
  delete() {
    const {
      stall: { stallUpdateData },
    } = this.props;

    if (stallUpdateData === 200) {
      message.info('删除成功');
    } else {
      message.info('删除失败');
    }
    this.modifyStatusBtn();
  }

  // 表单提交
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields({ force: true }, (err, fieldsValue) => {
      if (err) {
        return;
      }

      const { channel, configKey, configValue, displayName, enable, remark, channelVersion } =
        fieldsValue;
      const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
      this.saveOrUpdate({
        id: this.state.type === 'add' ? null : this.state.id,
        channel: channel,
        configKey: configKey,
        configValue: configValue,
        displayName: displayName,
        enable: enable,
        remark: remark,
        channelVersion: channelVersion,
        groupNo: groupNo,
        configUuid: this.state.config.configUuid,
      });

      this.setState({
        visibleModal: false,
      });
    });
  };

  // modal radio 单选事件
  selectRadio = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  // 新增档口配置
  add() {
    this.setState({
      visibleModal: true,
      type: 'add',
      id: 'm-' + (Math.random() * 100).toFixed(),
      config: {},
    });
  }

  //返回到档口分组配置
  toBoothAllGroup() {
    this.props.history.push('/booth/boothAllGroup');
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      selectValue: '',
    });
  };

  // 配置选择onchange事件
  onChange = (value) => {
    let {
      stall: { sysConfigs },
    } = this.props;
    let config = {};
    for (var i = 0; i < sysConfigs.length; i++) {
      if (value === sysConfigs[i].configUuid) {
        config = sysConfigs[i];
      }
    }
    this.setState({
      config: config,
    });
  };

  // 启用/禁用档口分组
  updGroup(e, configUuid, enable) {
    e.preventDefault();
    const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    this.updateGroupConfig({
      searchGroupNo: groupNo,
      configUuid: configUuid,
      enable: enable,
    });
  }

  // 修改档口配置
  async updateGroupConfig(params) {
    await this.props.updateGroupConfig(params);
    this.submit();
  }

  render() {
    let {
      stall: { allConfig, getConfigData, sysConfigs },
      batch: { fileSelectListData = [] },
    } = this.props;
    if (allConfig == null) {
      allConfig = {};
    }
    if (sysConfigs == null) {
      sysConfigs = [];
    }
    const { pageEntity } = allConfig;
    const stallListData = allConfig.lst;
    const { getFieldDecorator } = this.props.form;
    let data = [];
    let total = 0;
    if (pageEntity) {
      total = pageEntity.totalSize;
    }

    if (getConfigData !== undefined && getConfigData !== null && this.state.type !== 'add') {
      data = getConfigData;
    }
    let {
      configKey,
      configValue,
      displayName,
      channel,
      channelVersion,
      marketId,
      stallGroupNo,
      configRemark,
    } = data;

    if (!configKey) {
      configKey = this.state.config ? this.state.config.configKey : '';
    }
    if (!configValue) {
      configValue = this.state.config ? this.state.config.configValue : '';
    }
    if (!displayName) {
      displayName = this.state.config ? this.state.config.displayName : '';
    }
    if (!configRemark) {
      configRemark = this.state.config ? this.state.config.remark : '';
    }
    if (!channel) {
      channel = this.state.config ? this.state.config.channel : '';
    }
    if (!channelVersion) {
      channelVersion = this.state.config ? this.state.config.channelVersion : '';
    }

    if (marketId === undefined || marketId === null) {
      marketId = '0';
    }
    if (stallGroupNo === undefined || stallGroupNo === null) {
      stallGroupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;
    }

    if (
      fileSelectListData !== null &&
      fileSelectListData !== undefined &&
      fileSelectListData.length
    ) {
      let tmpFileSelectListData = [];
      const tmp = { id: 0, name: '所有' };
      tmpFileSelectListData.push(tmp);
      for (let i = 0; i < fileSelectListData.length; i++) {
        tmpFileSelectListData.push(fileSelectListData[i]);
      }
      fileSelectListData = tmpFileSelectListData;
    }

    const groupNo = !isEmptyObject(this.props.match) ? this.props.match.params.id : 0;

    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 6,
      style: { marginBottom: 12 },
    };

    const extraContent = (
      <div className={'searchModule'}>
        <Form
          onSubmit={this.modifyStatusBtn}
          className='login-form'
          style={{ marginBottom: '12px', float: 'left', width: '100%' }}
        >
          <Row gutter={24}>
            <Col {...topColResponsiveProps}>
              <FormItem>
                {getFieldDecorator('searchDisplayName', {
                  rules: [{ required: false, message: '请输入配置名称!' }],
                })(<Input placeholder='请输入配置名称' />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <Button
                style={{ width: '100px', textAlign: 'center' }}
                onClick={this.modifyStatusBtn.bind(this)}
              >
                查询
              </Button>
              <Button
                style={{ marginLeft: '5px', width: '100px', textAlign: 'center' }}
                onClick={this.handleReset}
              >
                重置
              </Button>
              <Button
                style={{ marginLeft: '5px', width: '100px', textAlign: 'center' }}
                onClick={this.add.bind(this)}
              >
                新增
              </Button>
              <Button
                style={{ marginLeft: '5px', width: '120px', textAlign: 'center' }}
                onClick={this.toBoothAllGroup.bind(this)}
              >
                返回档口分组
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const pagination = {
      pageSize: 10,
      total: total,
    };

    const marketchoiceData = [
      {
        id: 'all',
        name: 'all',
      },
      {
        id: 'ios',
        name: 'ios',
      },
      {
        id: 'android',
        name: 'android',
      },
      {
        id: 'web',
        name: 'web',
      },
    ];

    return (
      <div className='booth-index'>
        <PageHeaderLayout>
          <div className={'batchLayout'}>
            <Card className={'listCard'} bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={stallListData}
                pagination={pagination}
                bordered
                onChange={this.pageSwitch.bind(this)}
                scroll={{ x: '1400px' }}
              >
                <Column title='key' dataIndex='configKey' key='configKey' />
                <Column title='value' dataIndex='configValue' key='configValue' />
                <Column title='名称' dataIndex='displayName' key='displayName' />
                <Column
                  title='分组编号'
                  dataIndex='stallGroupNo'
                  key='stallGroupNo'
                  render={() => {
                    return groupNo;
                  }}
                />
                <Column title='平台' dataIndex='channel' key='channel' />
                <Column title='版本' dataIndex='channelVersion' key='channelVersion' />
                <Column
                  title='是否启用'
                  dataIndex='enable'
                  key='enable'
                  render={(_, record) => {
                    const { enable } = record;
                    return <span>{enable ? '是' : '否'}</span>;
                  }}
                />
                <Column
                  title='备注'
                  dataIndex='remark'
                  key='remark'
                  render={(text) => {
                    let txtVal = text;
                    if (text.length > 20) {
                      txtVal = text.substring(0, 20) + '...';
                    }
                    return (
                      <Tooltip placement='topLeft' title={text} arrowPointAtCenter>
                        {' '}
                        {txtVal}{' '}
                      </Tooltip>
                    );
                  }}
                />
                <Column
                  title='操作'
                  dataIndex='operating'
                  key='operating'
                  render={(_, record) => {
                    const { configUuid } = record;
                    const title = `确定删除当前配置?`;
                    return (
                      <div>
                        <Popconfirm
                          title={title}
                          onConfirm={(e) => this.updGroup(e, configUuid, false)}
                          okText='确认'
                          cancelText='取消'
                        >
                          <a href='/'>
                            <span>{'删除'}</span>
                          </a>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            </Card>
            <Card>
              <Modal
                title={this.state.type === 'add' ? '新增档口配置' : '修改档口配置'}
                visible={this.state.visibleModal}
                footer={null}
                onCancel={this.handleCancel}
                destroyOnClose='true'
                width='600px'
              >
                <div className={'extraContent'} style={{ width: '100%' }}>
                  <Form key={this.state.id || 0} onSubmit={this.handleSubmit}>
                    <FormItem {...formItemLayout} label='请选择配置'>
                      {getFieldDecorator('selectConfig', {
                        rules: [{ required: false, message: '请选择配置!' }],
                      })(
                        <Select
                          placeholder='请选择配置'
                          style={{ width: '100%' }}
                          onChange={this.onChange.bind(this)}
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {sysConfigs.map((item) => (
                            <Option key={item.configUuid}>{item.displayName}</Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='key'>
                      {getFieldDecorator('configKey', {
                        initialValue: configKey,
                        rules: [
                          {
                            required: true,
                            message: ' 请输入key!',
                          },
                        ],
                      })(<Input placeholder='请输入key' readOnly />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label='value'>
                      {getFieldDecorator('configValue', {
                        initialValue: configValue,
                        rules: [
                          {
                            required: true,
                            message: ' 请输入value!',
                          },
                        ],
                      })(<Input readOnly placeholder='请输入value' />)}
                    </FormItem>

                    <FormItem {...formItemLayout} label='名称'>
                      {getFieldDecorator('displayName', {
                        initialValue: displayName,
                        rules: [
                          {
                            required: true,
                            message: '请输入名称!',
                          },
                        ],
                      })(<Input readOnly placeholder='请输入名称' />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label='配置描述'>
                      {getFieldDecorator('configRemark', {
                        initialValue: configRemark,
                        rules: [
                          {
                            required: true,
                            message: '请输入配置描述!',
                          },
                        ],
                      })(<TextArea readOnly placeholder='请输入名称' />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label='平台'>
                      {getFieldDecorator('channel', {
                        initialValue: channel,
                        rules: [
                          {
                            required: true,
                            message: ' 请选择平台!',
                          },
                        ],
                      })(
                        <Select placeholder='请选择平台' disabled style={{ width: '100%' }}>
                          {marketchoiceData.map((item) => (
                            <Option key={item.id}>{item.name}</Option>
                          ))}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem {...formItemLayout} label='版本'>
                      {getFieldDecorator('channelVersion', {
                        initialValue: channelVersion,
                        rules: [
                          {
                            required: true,
                            message: '请输入版本!',
                          },
                        ],
                      })(<Input readOnly placeholder='请输入版本' />)}
                    </FormItem>
                    <FormItem {...formItemLayout} label='分组编号'>
                      {getFieldDecorator('stallGroupNo', {
                        initialValue: stallGroupNo,
                        rules: [
                          {
                            required: true,
                            message: '请输入分组编号!',
                          },
                        ],
                      })(<Input placeholder='请输入分组编号' readOnly />)}
                    </FormItem>

                    <FormItem>
                      <div style={{ textAlign: 'right' }}>
                        <Button type='primary' htmlType='submit'>
                          保存
                        </Button>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </Modal>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default BoothConfig;
