// 每日新增統計
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Radio, Table, Row, Col, DatePicker } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';

import './caixmPhone.less';
import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';

import { getDailyAddStall } from '../../state/action/caixm';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const dateFormat = 'YYYY-MM-DD';
// 系统类型
const stallTypeSource = [
  { label: '正式专业版', value: 2 },
  { label: '正式基础版', value: 1 },
];
let pagination = {
  pageSize: 10,
  total: 0,
  current: 1,
};
@connect(
  (state) => {
    return { caixm: state.caixm };
  },
  { getDailyAddStall },
)
@Form.create()
class DailyAddStall extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.startDate = moment().startOf('month');
    this.endDate = moment().endOf('month');
  }
  componentDidMount() {
    this.dailyAddStall({
      stallType: 2,
      startTime: this.startDate.valueOf(),
      endTime: this.endDate.valueOf(),
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
  }

  async dailyAddStall(params) {
    this.setState({ loading: true });
    await this.props.getDailyAddStall(params);
    this.setState({ loading: false });
  }

  // 重置按钮
  handleReset = () => {
    this.props.form.resetFields();
    pagination.current = 1;
    this.props.form.validateFields((errors, fieldsValue) => {
      let startTime = !fieldsValue.selectTime.length
        ? undefined
        : fieldsValue.selectTime[0].valueOf();
      let endTime = !fieldsValue.selectTime.length
        ? undefined
        : fieldsValue.selectTime[1].valueOf();
      let params = {
        stallType: fieldsValue.stallType,
        pageNo: pagination.current,
        pageSize: pagination.pageSize,
      };
      if (startTime) {
        params.startTime = startTime;
      }
      if (endTime) {
        params.endTime = endTime;
      }
      this.dailyAddStall(params);
    });
  };

  // 查询
  handleSearch = (e) => {
    e.preventDefault();
    const { dailAddStallParams } = this.props.caixm;
    this.props.form.validateFields((errors, fieldsValue) => {
      let startTime = !fieldsValue.selectTime.length
        ? undefined
        : fieldsValue.selectTime[0].valueOf();
      let endTime = !fieldsValue.selectTime.length
        ? undefined
        : fieldsValue.selectTime[1].valueOf();
      let params = {
        pageNo: dailAddStallParams.pageNo,
        pageSize: dailAddStallParams.pageSize,
        stallType: fieldsValue.stallType,
      };
      if (startTime) {
        params.startTime = startTime;
      }
      if (endTime) {
        params.endTime = endTime;
      }
      this.dailyAddStall(params);
    });
  };
  // 分页
  paginationChange = (e) => {
    pagination.current = e.current;
    const { dailAddStallParams } = this.props.caixm;
    this.dailyAddStall({
      ...dailAddStallParams,
      pageNo: pagination.current,
    });
  };

  // 导出
  exportData = () => {
    this.props.form.validateFields((_, fieldsValue) => {
      let startTime = !fieldsValue.selectTime.length
        ? undefined
        : fieldsValue.selectTime[0].valueOf();
      let endTime = !fieldsValue.selectTime.length
        ? undefined
        : fieldsValue.selectTime[1].valueOf();
      let params = {
        stallType: fieldsValue.stallType,
        startTime: startTime,
        endTime: endTime,
      };
      window.open(
        `${API_ADMIN}dailyIncreasedStatistics/exportIncreasedStatistics?${generalParamsWithPToStr(
          'dailyIncreasedStatistics/exportIncreasedStatistics',
          params,
        )}`,
      );
    });
  };

  exportDailyAddStall = (params) => {
    this.props.exportDailyAddStall(params);
  };

  render() {
    const { loading } = this.state;
    const layout = { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 };
    const { getFieldDecorator } = this.props.form;
    const {
      caixm: { dailyAddStall = {} },
    } = this.props;
    const { pageNo = 1, totalSize = 1, pageList = [] } = dailyAddStall;
    pagination = {
      pageSize: 10,
      total: totalSize,
      current: pageNo,
    };
    const columns = [
      {
        title: '日期',
        dataIndex: 'statisticTime',
        render: (_, record) => {
          return moment(new Date(record.statisticTime)).format(dateFormat);
        },
      },
      {
        title: '新增商行',
        key: 'increasedStallCount',
        dataIndex: 'increasedStallCount',
      },
      {
        title: '累计商行',
        key: 'totalStallCount',
        dataIndex: 'totalStallCount',
      },
      {
        title: '新增买家数',
        key: 'increasedBuyerCount',
        dataIndex: 'increasedBuyerCount',
      },
      {
        title: '累计买家数',
        key: 'totalBuyerCount',
        dataIndex: 'totalBuyerCount',
      },
      {
        title: '新增货主数',
        key: 'increasedSupplierCount',
        dataIndex: 'increasedSupplierCount',
      },
      {
        title: '累计货主数',
        key: 'totalSupplierCount',
        dataIndex: 'totalSupplierCount',
      },
    ];
    return (
      <PageHeaderLayout>
        <div className='caixm-phone'>
          <div className='extraContent' style={{ width: '100%' }}>
            <Form className='ant-advanced-search-form' onSubmit={this.handleSearch}>
              <Col {...layout}>
                <FormItem label='系统类型'>
                  {getFieldDecorator('stallType', {
                    initialValue: stallTypeSource[0].value,
                  })(
                    <Radio.Group>
                      <Radio value={stallTypeSource[0].value}>{stallTypeSource[0].label}</Radio>
                      <Radio value={stallTypeSource[1].value}>{stallTypeSource[1].label}</Radio>
                    </Radio.Group>,
                  )}
                </FormItem>
              </Col>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem label='日期'>
                    {getFieldDecorator('selectTime', {
                      initialValue: [
                        moment(this.startDate, dateFormat),
                        moment(this.endDate, dateFormat),
                      ],
                      rules: [],
                    })(<RangePicker format='YYYY-MM-DD' placeholder={['开始时间', '结束时间']} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem>
                    <Button type='primary' htmlType='submit' style={{ marginTop: '20px' }}>
                      查询
                    </Button>
                    <Button
                      style={{ marginLeft: '20px', marginTop: '20px' }}
                      onClick={this.handleReset}
                    >
                      重置
                    </Button>
                    <Button
                      style={{ marginLeft: '20px', marginTop: '20px' }}
                      onClick={this.exportData}
                    >
                      导出
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
          <Table
            dataSource={pageList}
            columns={columns}
            pagination={pagination}
            rowKey={(record) => record.id}
            align='center'
            onChange={this.paginationChange}
            loading={loading}
          />
        </div>
      </PageHeaderLayout>
    );
  }
}
export default DailyAddStall;
