/**
 * 商行管理--新增设备Modal
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Modal, Select, Row, Col, message } from 'antd';

import { marketList } from '../../../state/action/stall';
import { getStallList } from '../../../state/action/basic';
import {
  getListDeviceModes,
  getListDeviceTypes,
  getAddDevice,
  getUpdateDevice,
} from '../../../state/action/contract';

import './index.less';

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;
@connect(
  (state) => {
    return { stall: state.stall, basic: state.basic, contract: state.contract };
  },
  {
    marketList,
    getStallList,
    getAddDevice,
    getUpdateDevice,
    getListDeviceModes,
    getListDeviceTypes,
  },
)
@Form.create()
class DeviceManagementModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      stallId: null, //商行id
      deviceId: null, //设备id
      search: {
        deviceModel: null,
        deviceType: null,
        name: null,
        stallId: null,
        machineCode: null,
        enable: null,
        status: null,
      },
      remark: null, //备注
      stallList: [], //商行数据
    };
  }
  componentDidMount() {
    const { showModalVisible, sysPrinterConfig = {}, cxmBdStalls = {}, isEdit } = this.props;
    const {
      machineCode,
      name,
      type,
      machineKey,
      remark,
      stallId,
      deviceModel,
      deviceTypeDesc,
      deviceType,
      printer,
      ptype,
      pmaker,
      pseries,
    } = sysPrinterConfig; //id设备id
    const { marketId } = cxmBdStalls; //id 商行id  marketId 市场id
    this.handReset();
    //编辑设备
    if (isEdit) {
      this.setState({
        deviceId: sysPrinterConfig.id, //设备id
        stallBoothId: cxmBdStalls.id,
        machineCode,
        name,
        type,
        machineKey,
        remark,
        stallId: stallId || this.props.stallId,
        marketId: marketId || this.props.marketId,
        deviceModel,
        deviceTypeDesc,
        deviceType,
        printer,
        ptype,
        pmaker,
        pseries,
      });
    }

    this.setState({ showModal: showModalVisible, stallId });
    this.getMarketList(); //获得市场列表
    this.getListDeviceModes(); //获取设备类型
  }

  handReset() {
    this.setState({
      deviceId: '', //设备id
      stallBoothId: '',
      machineCode: '',
      name: '',
      type: '',
      machineKey: '',
      remark: '',
      stallId: '',
      marketId: '',
    });
  }
  // 获取设备类型
  async getListDeviceModes(params) {
    await this.props.getListDeviceModes(params);
  }
  // 获取设备型号
  async getListDeviceTypes(params) {
    await this.props.getListDeviceTypes(params);
  }
  //新增设备
  async getAddDevice(params) {
    await this.props.getAddDevice(params);
  }
  //编辑设备
  async getUpdateDevice(params) {
    await this.props.getUpdateDevice(params);
  }
  // 获得市场列表
  async getMarketList() {
    await this.props.marketList({});
  }
  // 获取档口列表
  async getStallList(value) {
    await this.props.getStallList(value);
  }
  //设备类型改变
  changeDeviceModel = (v) => {
    this.setState({ deviceModelData: v });
    this.getListDeviceTypes({ name: v }); //获取设备型号联动
    this.props.form.setFieldsValue({ deviceTypeDesc: '' }); //先清空之前选择的设备型号
  };
  //设备型号改变
  changeDeviceType = (v) => {
    const {
      contract: { listDeviceTypesData = [] },
    } = this.props;
    const deviceTypeDescData = listDeviceTypesData.filter((item) => item.code === v);
    this.setState({
      deviceType: v,
      deviceTypeDesc:
        deviceTypeDescData && deviceTypeDescData.length > 0 && deviceTypeDescData[0].value,
    });
    this.props.form.setFieldsValue({ printerModel: '' });
  };

  //新增设备
  addSysPrinterConfig = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      [
        'id',
        'name',
        'deviceModel',
        'deviceTypeDesc',
        'machineCode',
        'machineKey',
        'marketId',
        'stallId',
        'remark',
        'printerModel',
      ],
      async (errors, values) => {
        if (!!errors) {
          return;
        }
        const {
          id,
          name,
          deviceModel,
          machineCode,
          machineKey,
          marketId,
          stallId,
          remark,
          printerModel, //是下面4个字段的json字符串，eg:"{"printer":"贾杰","ptype":"51","pmaker":"惠普","pseries":"彩色激光"}"
        } = values;

        const { deviceType, deviceTypeDesc } = this.state;
        values = {
          name: name || '',
          deviceModel: deviceModel || '',
          deviceType: deviceType || '', //设备型号 唯一标识
          machineCode: machineCode || '',
          machineKey: machineKey || '',
          marketId: marketId || '', //市场id
          stallId: stallId || '', //商行id
          remark: remark || '',
          deviceTypeDesc: deviceTypeDesc || '', //设备型号 比如：飞鹅
        };
        if (printerModel != null && printerModel !== '') {
          //打印机型号
          values = { ...values, ...JSON.parse(printerModel) };
        }
        this.setState({ remark: remark });
        // 商行管理下---设备管理--新增状态码（错误码7003已存在，为空闲状态; 7001已存在且被禁用，为空闲状态;7004 已存在，为使用中;7002 已存在且被禁用）
        if (!id) {
          //新增
          await this.getAddDevice({ ...values });
          const {
            contract: { addDeviceMsg },
          } = this.props;
          if (addDeviceMsg && addDeviceMsg.code === 200) {
            message.info('新增设备信息成功！');
            this.props.handleCancel();
            this.props.resetSearch();
          }
        } else {
          //修改
          await this.getUpdateDevice({ ...values, id: id || null });
          const {
            contract: { updateDeviceMsg },
          } = this.props;
          if (updateDeviceMsg && updateDeviceMsg.code === 200) {
            message.info('修改设备信息成功！');
            this.props.handleCancel();
            this.props.resetSearch(); //刷新设备列表
          }
        }
      },
    );
  };

  // 市场选择框的onchange事件
  selectMarketEvent = (value) => {
    let keyCode = { marketId: value };
    this.getStallList(keyCode);
    //清空打商行原先选中的值
    this.props.form.setFieldsValue({ stallId: '' });
  };

  //模态框，请选择打印机品牌
  changePrinterBrand = () => {
    this.props.form.setFieldsValue({ printerModel: '' });
  };

  //商行数据太多的情况下，下拉框会卡，改为搜索出结果
  onSearch = (value) => {
    let stallList = [];
    if (this.props.basic.stallList.code === 200) {
      stallList = this.props.basic.stallList.data;
    }
    this.setState({
      stallList: stallList.filter((v) => v.name.toLowerCase().indexOf(value.toLowerCase()) >= 0),
    });
  };

  render() {
    const {
      contract: {
        listDeviceModesData = [], //设备类型
        listDeviceTypesData = [], //设备型号
        listPrintBoxSupportBrand = [], //打印机品牌
        listPrintBoxSupportModel = [], //获取打印盒子支持的型号
      },
    } = this.props;
    const { showModalVisible, handleCancel } = this.props;
    const {
      stall: { marketData = [] },
    } = this.props;
    const {
      deviceId,
      printer,
      ptype,
      pmaker,
      pseries,
      showModal,
      name,
      deviceModel,
      deviceTypeDesc,
      machineCode,
      machineKey,
      marketId,
      stallBoothId,
      remark,
      stallList,
    } = this.state;

    const stallOption = stallList.map((stall) => (
      <Option value={stall.id} key={stall.id}>
        {stall.name}
      </Option>
    ));
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    //设备型号
    const formDeviceTypeDesc = this.props.form.getFieldValue('deviceTypeDesc');
    const isCaixm =
      formDeviceTypeDesc === '菜小秘' || formDeviceTypeDesc === 'be806fd99e9b42a4a2a4d224437798c5';
    //是否是优获云网关的IprintBOX
    const isIprintBOX =
      formDeviceTypeDesc === 'IprintBox' ||
      formDeviceTypeDesc === 'b60cbd5aa9774852a67e68129b7bd09f';
    let initPrinterModel = JSON.stringify({ printer, ptype, pmaker, pseries }); //打印机型号默认值
    initPrinterModel = initPrinterModel === '{}' ? '' : initPrinterModel;

    return (
      <div className='modal-box'>
        {/* 新增编辑设备Modal */}
        <Modal
          key={`modalKey${showModal}${deviceId || 1 || Math.floor(Math.random() * 100)}`}
          title={`${deviceId ? '修改' : '新增'}设备`}
          visible={showModalVisible}
          onCancel={() => handleCancel()}
          footer={null}
          width='800px'
          className='add-modal'
        >
          <Form
            {...formItemLayout}
            onSubmit={this.addSysPrinterConfig}
            layout='inline'
            className='login-form'
          >
            <FormItem style={{ display: 'none' }}>
              {getFieldDecorator('id', {
                initialValue: deviceId || '', //修改用
                rules: [{ required: false, message: ' 请输入id!' }],
              })(<Input type='hidden' />)}
            </FormItem>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem label='设备编号'>
                  {getFieldDecorator('name', {
                    initialValue: name || '',
                    rules: [{ required: true, message: '请输入设备编号!' }],
                  })(
                    <Input placeholder='请输入设备编号' maxLength='32' style={{ minWidth: 620 }} />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label='设备类型'>
                  {getFieldDecorator('deviceModel', {
                    initialValue: deviceModel || '',
                    rules: [{ required: true, message: '请选择设备类型!' }],
                  })(
                    <Select
                      placeholder='请选择设备类型'
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={this.changeDeviceModel}
                    >
                      {listDeviceModesData &&
                        listDeviceModesData.map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label='设备型号'>
                  {getFieldDecorator('deviceTypeDesc', {
                    initialValue: deviceTypeDesc || '',
                    rules: [{ required: true, message: '请选择设备型号!' }],
                  })(
                    <Select
                      placeholder='请选择设备型号'
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={this.changeDeviceType}
                    >
                      {listDeviceTypesData &&
                        listDeviceTypesData.map((item) => (
                          <Select.Option key={item.code}>{item.value}</Select.Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label='设备SN码'>
                  {getFieldDecorator('machineCode', {
                    initialValue: machineCode || '',
                    rules: [
                      {
                        required:
                          this.props.form.getFieldValue('deviceModel') === '打印机' ? true : false,

                        whitespace: true,
                        message: '请输入设备SN码!',
                      },
                    ],
                  })(<Input placeholder='请输入设备SN码' allowClear maxLength='64' />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label='设备秘钥'>
                  {getFieldDecorator('machineKey', {
                    initialValue: machineKey || '',
                    rules: [
                      {
                        required:
                          isCaixm ||
                          isIprintBOX ||
                          this.props.form.getFieldValue('deviceModel') === '打印机'
                            ? false
                            : true,
                        whitespace: true,
                        message: '请输入设备秘钥!',
                      },
                    ],
                  })(<Input placeholder='请输入设备秘钥' allowClear maxLength='64' />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label='市&emsp;&emsp;场'>
                  {getFieldDecorator('marketId', {
                    initialValue: marketId || this.props.marketId || '',
                  })(
                    <Select
                      placeholder='请选择市场'
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={this.selectMarketEvent}
                      disabled={this.props.marketId ? true : false}
                    >
                      {marketData.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label='商&emsp;&emsp;行'>
                  {getFieldDecorator('stallId', {
                    initialValue: stallBoothId || this.props.stallId || '',
                  })(
                    <Select
                      placeholder='请选择商行'
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.props.stallId ? true : false}
                      onSearch={this.onSearch}
                    >
                      {stallOption}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            {isIprintBOX && (
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='打印机品牌'>
                    {getFieldDecorator('pmaker', {
                      initialValue: pmaker || '',
                      rules: [{ required: true, message: ' 请选择打印机品牌!' }],
                    })(
                      <Select
                        placeholder='请选择打印机品牌'
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={this.changePrinterBrand}
                      >
                        {listPrintBoxSupportBrand &&
                          listPrintBoxSupportBrand.map((item) => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='打印机型号'>
                    {getFieldDecorator('printerModel', {
                      initialValue: initPrinterModel,
                      rules: [{ required: true, message: ' 请选择打印机型号!' }],
                    })(
                      <Select
                        placeholder='请选择打印机型号'
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            JSON.parse(option.props.value)
                              .printer.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {listPrintBoxSupportModel &&
                          listPrintBoxSupportModel.map((item) => {
                            const { printer, ptype, pmaker, pseries } = item;
                            return (
                              <Select.Option
                                value={JSON.stringify({ printer, ptype, pmaker, pseries })}
                              >
                                <div>
                                  <div>型号：{printer}</div>
                                  <div>类型：{ptype}</div>
                                  <div>品牌：{pmaker}</div>
                                  <div>喷墨类型：{pseries}</div>
                                </div>
                              </Select.Option>
                            );
                          })}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
            )}
            <Row gutter={24}>
              <Col span={24}>
                <FormItem label='备&emsp;&emsp;注'>
                  {getFieldDecorator('remark', {
                    initialValue: remark || '',
                    rules: [
                      {
                        required: false,
                        whitespace: true,
                        message: ' 请输入合同备注',
                      },
                    ],
                  })(
                    <TextArea
                      placeholder='请输入合同备注'
                      autosize={{ minRows: 2, maxRows: 4 }}
                      maxLength='60'
                      style={{ minWidth: 620 }}
                    />,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => handleCancel()}>关闭</Button>
              <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                保存
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default DeviceManagementModal;
