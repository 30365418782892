// 异常操作查询
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Col, Button, Table, Row, DatePicker, Select } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getOperationList } from '../../state/action/operationManager';
import { marketList } from '../../state/action/stall';
import { getStallList } from '../../state/request/basic';
import { operationTypes, actionMap } from './utils';
import './index.less';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

@Form.create()
@connect(
  (state) => {
    return { stall: state.stall, list: state.operationManager.operationList };
  },
  { getOperationList, marketList },
)
class PersonManager extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageNo: 1,
      stallPageList: [],
      operationList: [],
      queryConditions: {}, //查询条件  点击搜索时才更新
    };
  }

  componentDidMount() {
    document.body.classList.add('sys-log-index-body');
    this.getMarketList(); //获得市场列表
  }
  //请求市场列表
  async getMarketList() {
    await this.props.marketList({});
  }

  //选择市场 更新商行列表
  searchMarketChange = (value) => {
    //清空商行名称
    this.props.form.setFieldsValue({
      stallId: undefined,
    });
    this.setState({
      stallPageList: [],
    });
    this.getStallByMarket(value);
  };

  //请求市场下商行列表
  async getStallByMarket(marketId) {
    if (!marketId) {
      return;
    }
    const { code, data } = await getStallList({ marketId });
    if (200 === code) {
      this.setState({
        stallPageList: data,
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('sys-log-index-body');
  }

  // 获取table数据
  async getList(params) {
    this.setState({ loading: true });
    await this.props.getOperationList(params);
    this.setState({ loading: false });
  }

  // 根据条件搜索合单配置列表
  handleSubmit = async (e) => {
    e.preventDefault();
    const value = await this.props.form.validateFields();
    const {
      date: [startTime, endTime],
      stallId,
      opeType,
    } = value;
    if (value) {
      const queryConditions = {
        stallId,
        opeType,
        startTime: startTime.startOf('day').valueOf(),
        endTime: moment(endTime).add(1, 'day').startOf('day').valueOf(),
      };
      this.setState(
        {
          pageNo: 1,
        },
        () => {
          this.setState(
            {
              queryConditions,
              pageNo: 1,
            },
            () => {
              let keyInfo = {
                ...queryConditions,
                pageSize: 10,
                pageNo: 1,
              };
              this.getList(keyInfo);
            },
          );
        },
      );
    }
  };

  // 重置
  handleReset = () => {
    this.setState({
      stallPageList: [],
    });
    this.props.form.resetFields();
  };

  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    });
    let pageInfo = {
      pageSize: 10,
      pageNo: pageNumber.current,
      ...this.state.queryConditions,
    };
    this.getList(pageInfo);
  };

  validateDate = (_, value = '', callback) => {
    const [startDate, endDate] = value;
    const diff = moment(endDate).diff(moment(startDate), 'months');
    if (diff > 0) {
      callback('最多支持选择一个月');
    }
    callback();
  };

  render() {
    const {
      stall: { marketData = [] },
    } = this.props;
    const operationList = this.props.list;
    // 表格数据源
    let dataSource = [];
    let pageEntity = {};
    if (operationList) {
      dataSource = operationList.pageList;
      pageEntity = operationList;
    } else {
      dataSource = [];
      pageEntity = {};
    }
    const { getFieldDecorator } = this.props.form;
    var totalSize = 0;
    if (pageEntity === undefined) {
    } else {
      totalSize = pageEntity.totalSize;
    }
    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
    };
    const initialValue = [
      moment(moment().startOf('month').format('YYYY-MM-DD')),
      moment(moment(new Date().getTime()).format('YYYY-MM-DD')),
    ];
    const disabledDate = (current) => {
      return current > new Date().getTime();
    };
    const extraContent = (
      <div className='extraContent'>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col {...topColResponsiveProps} {...{ xl: 5 }}>
              <FormItem label='选择时间'>
                {getFieldDecorator('date', {
                  initialValue: initialValue,
                  rules: [
                    { required: true, message: '请选择时间!' },
                    { validator: this.validateDate },
                  ],
                })(<RangePicker placeholder='请选择时间' allowClear disabledDate={disabledDate} />)}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 5 }}>
              <FormItem label='市场名称'>
                {getFieldDecorator('searchMarketId', {
                  rules: [{ required: true, message: '请选择市场!' }],
                })(
                  <Select
                    placeholder='请选择市场'
                    style={{ minWidth: '100%' }}
                    showSearch
                    allowClear
                    onChange={this.searchMarketChange}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {marketData.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 5 }}>
              <FormItem label='商行名称'>
                {getFieldDecorator('stallId', {
                  rules: [{ required: true, message: '请选择商行!' }],
                })(
                  <Select
                    placeholder='请选择商行'
                    style={{ minWidth: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.stallPageList.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 5 }}>
              <FormItem label='选择操作类型' style={{ minWidth: 300 }}>
                {getFieldDecorator(
                  'opeType',
                  {},
                )(
                  <Select
                    placeholder='选择操作类型'
                    style={{ minWidth: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {operationTypes.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
          </Row>
        </Form>
      </div>
    );
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
      pageSize: 10,
    };
    const formatData = (type, before, after) => {
      if (![1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12].includes(type)) {
        return '';
      }
      return actionMap[type](before, after);
    };
    return (
      <div className='sys-index'>
        <PageHeaderLayout>
          <div className={'system-log'}>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.pageChange.bind(this)}
              >
                <Col align='center' title='操作人' dataIndex='personName' />
                <Col
                  align='center'
                  title='操作时间'
                  dataIndex='createTime'
                  key='createTime'
                  render={(text, record) => {
                    const { createTime } = record;
                    return moment(createTime).format('YYYY-MM-DD HH:mm:ss');
                  }}
                />
                <Col
                  align='center'
                  title='操作类型'
                  dataIndex='opeType'
                  key='opeType'
                  render={(text) => {
                    return operationTypes.find((item) => item.value === text)?.label;
                  }}
                />
                <Col
                  align='center'
                  title='操作内容'
                  dataIndex='opeType1'
                  key='opeType1'
                  render={(text, record) => {
                    const { opeBefore, opeAfter, opeType } = record;
                    return formatData(
                      opeType,
                      JSON.parse(opeBefore),
                      opeAfter ? JSON.parse(opeAfter) : '',
                    );
                  }}
                />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default PersonManager;
