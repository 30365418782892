import request from '../../utils/request';

export function getList(params) {
  return request({
    url: 'sysLog/list/' + params.pageNo + '/' + params.pageSize,
    params,
    method: 'post',
    headers: 'json',
  });
}
