import * as actionTypes from '../constant/market';
import * as req from '../request/market';

// 获取市场列表
export function getMarketList(params) {
  return async (dispatch) => {
    const res = await req.getMarketList(params);
    if (res) {
      dispatch({ type: actionTypes.MARKET_SAVE, payload: { marketList: res.data } });
    }
  };
}
//获取省市区信息
export function getAllDistrictInfo() {
  return async (dispatch) => {
    const res = await req.getAllDistrictInfo();
    if (res) {
      dispatch({ type: actionTypes.MARKET_SAVE, payload: { districtInfo: res } });
    }
  };
}
// 修改id对应的enabled字段
export function updateMarket(params) {
  return async (dispatch) => {
    const res = await req.getMarketInfo(params);
    if (res) {
      dispatch({ type: actionTypes.MARKET_SAVE, payload: { newMarketInfo: res.data } });
    }
  };
}
// 编辑页面的初始化获取数据
export function initMarket(params) {
  return async (dispatch) => {
    const res = await req.getMarketInfoById(params);
    if (res) {
      dispatch({ type: actionTypes.MARKET_SAVE, payload: { marketInfo: res.data } });
    }
    return res;
  };
}
//
export function clearData() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.MARKET_CLEAR });
  };
}
// 大数据账号列表
export function getBigDataAccountList(params) {
  return async (dispatch) => {
    const res = await req.getBigDataAccountList(params);
    if (res) {
      dispatch({ type: actionTypes.MARKET_SAVE, payload: { bigDataAccountData: res.data } });
      return res;
    }
  };
}

// 大数据账号-新增、修改账号、修改密码
export function updateBigDataAccount(params) {
  return async () => {
    const res = await req.updateBigDataAccount(params);
    if (res) {
      return res;
    }
  };
}

// 大数据账号-启用、禁用
export function updateEnable(params) {
  return async () => {
    const res = await req.updateEnable(params);
    if (res) {
      return res;
    }
  };
}
