/**
 * 货款计算模板
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Card, Input, Button, Radio, Select, Checkbox, message } from 'antd';

import styles from '../style.less';

const RadioGroup = Radio.Group;
const { Option } = Select;
const FormItem = Form.Item;

@connect(({ count }) => ({
  count,
}))
@Form.create()
class PagementTypePage extends Component {
  constructor(props) {
    super(props);
    const { data, formulaData } = props;
    const eleCountData = data && formulaData ? data.concat(formulaData) : [];
    this.state = {
      // 货款计算名称下拉数据，由父组件传递过来
      selectData: data,
      // 计算公式下拉框组件，下拉框还需要加上之前选择元素
      formulaData: formulaData,
      // 货款计算单位
      unitData: [],
      // 提交的计算规则数组
      data: [],
      eleCountData: eleCountData,
      // 计算单位1
      unit1: [],
      // 计算单位2
      unit2: [],
      comps: [],
    };
  }

  // 货款名称选择
  onSelectChange = (name) => {
    const { selectData } = this.state;
    let data = [];
    selectData.map((item) => {
      if (name === item.key) {
        data = item.unit;
      }
      return null;
    });
    this.setState({
      unitData: data,
    });
  };

  onSelectChange3 = (name) => {
    const { eleCountData } = this.state;
    let data = [];
    eleCountData.map((item) => {
      if (name === item.key) {
        data = item.unit;
      }
      return null;
    });
    this.setState({
      unit1: data,
    });
  };

  onSelectChange4 = (name) => {
    const { eleCountData } = this.state;
    let data = [];
    eleCountData.map((item) => {
      if (name === item.key) {
        data = item.unit;
      }
      return null;
    });
    this.setState({
      unit2: data,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return false;
      }
      let data = [];
      const { type } = this.props;
      const {
        ruleName,
        desc,
        desc1,
        feeType,
        key1,
        key2,
        operSymbol,
        resultType,
        unit,
        unit1,
        unit2,
      } = fieldsValue;
      if (type !== 'ticketFace') {
        const name = desc1 ? desc1 : desc.split('-')[1];
        data.push({
          key: desc.split('-')[0],
          className: desc.split('-')[2],
          ruleName: ruleName,
          desc: name,
          feeType: feeType,
          key1: key1 ? key1.split('-')[0] : null,
          key2: key2 ? key2.split('-')[0] : null,
          operSymbol: operSymbol,
          resultType: resultType,
          unit: unit,
          unit1: unit1,
          unit2: unit2,
        });
      }

      if (type === 'payment') {
        const countData = localStorage.getItem('count_payment_rule');
        const getData = countData ? JSON.parse(countData) : [];
        const firstData = getData[getData.length - 1];
        // 判断重复提交
        if (firstData && JSON.stringify(firstData) === JSON.stringify(data[0])) return false;
        localStorage.setItem('count_payment_rule', JSON.stringify(getData.concat(data)));
      } else if (type === 'addFee') {
        const countData = localStorage.getItem('count_addFee_rule');
        const getData = countData ? JSON.parse(countData) : [];
        const firstData = getData[getData.length - 1];
        if (firstData && JSON.stringify(firstData) === JSON.stringify(data[0])) return false;
        localStorage.setItem('count_addFee_rule', JSON.stringify(getData.concat(data)));
      } else if (type === 'ticketFace') {
        let keys = [],
          operSymbols = [];
        for (let i in fieldsValue) {
          if (i.indexOf('key') >= 0) {
            if (fieldsValue[i]) keys.push(fieldsValue[i].split('-')[0]);
          } else if (i.indexOf('operSymbol') >= 0) {
            if (fieldsValue[i]) operSymbols.push(fieldsValue[i]);
          }
        }
        const rules = {
          ruleName: fieldsValue.ruleName,
          resultType: fieldsValue.resultType,
          keys: keys,
          operSymbols: operSymbols,
        };
        localStorage.setItem('count_ticketFace_rule', JSON.stringify(rules));
        if (fieldsValue.saleType) {
          localStorage.setItem('saleType', fieldsValue.saleType);
        } else {
          localStorage.setItem('saleType', null);
        }
      } else if (type === 'addBatchItemFee') {
        const countData = localStorage.getItem('count_addBatchItemFee_rule');
        const getData = countData ? JSON.parse(countData) : [];
        const firstData = getData[getData.length - 1];
        if (firstData && JSON.stringify(firstData) === JSON.stringify(data[0])) return false;
        localStorage.setItem('count_addBatchItemFee_rule', JSON.stringify(getData.concat(data)));
      }
      message.info('表单保存成功!');
    });
  };

  delete = () => {};

  // 重置计算公式
  handleReset = () => {
    this.props.form.resetFields(['key1', 'unit1', 'operSymbol', 'key2', 'unit2']);
  };

  render() {
    const { type } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { selectData, unit1, unit2, unitData, eleCountData, comps } = this.state || {};

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div className={styles.fromLayout}>
        <Card
          title={this.props.title}
          bordered={false}
          extra={<Button onClick={this.delete}>删除</Button>}
        >
          <Form onSubmit={this.handleSubmit}>
            {this.props.type === 'ticketFace' ? (
              <Row gutter={24}>
                <Col span={3}>计算方式名称：</Col>
                <Col span={21}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('ruleName', {
                      rules: [{ required: false, message: '' }],
                    })(<Input placeholder='计算方式名称' />)}
                  </FormItem>
                </Col>
              </Row>
            ) : (
              <Row gutter={24}>
                <Col span={3}>货款计算名称：</Col>
                <Col span={7}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('desc', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Select
                        placeholder='货款'
                        onChange={this.onSelectChange.bind(this)}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {selectData &&
                          Array.isArray(selectData) &&
                          selectData.map((item) => (
                            <Option key={`${item.key}-${item.name}-${item.type}`}>
                              {item.name ? item.name : item.desc}
                            </Option>
                          ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={7}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('desc1', {
                      rules: [{ required: false, message: '' }],
                    })(<Input placeholder='修改计算名称' />)}
                  </FormItem>
                </Col>
                <Col span={7}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('unit', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Select
                        placeholder='选择单位'
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {unitData &&
                          Array.isArray(unitData) &&
                          unitData.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
            )}
            <Row gutter={24}>
              <Col span={3}>{this.props.type === 'ticketFace' ? '计算公式：' : '售卖方式：'}</Col>
              {type === 'payment' ? (
                <Col span={12}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('feeType', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <RadioGroup>
                        <Radio value='1'>按数量卖</Radio>
                        <Radio value='2'>按重量卖</Radio>
                        <Radio value='3'>按常量</Radio>
                      </RadioGroup>,
                    )}
                  </FormItem>
                </Col>
              ) : type === 'addFee' || type === 'addBatchItemFee' ? (
                <Col span={15}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('feeType', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <RadioGroup>
                        <Radio value='1'>按数量卖</Radio>
                        <Radio value='2'>按重量卖</Radio>
                        <Radio value='3'>按常量</Radio>
                        <Radio value='4'>按百分比卖</Radio>
                      </RadioGroup>,
                    )}
                  </FormItem>
                </Col>
              ) : (
                <div></div>
              )}
              {this.props.type === 'ticketFace' ? (
                <Col span={6}>
                  <Button
                    type='primary'
                    icon='plus'
                    onClick={() => this.setState({ comps: comps.concat([Date.now()]) })}
                  >
                    新增附加费用计算
                  </Button>
                </Col>
              ) : (
                <div></div>
              )}
              <Col span={6}>
                <Button type='primary' icon='plus' onClick={this.handleReset}>
                  重置计算公式
                </Button>
              </Col>
            </Row>
            {this.props.type === 'ticketFace' ? (
              <div>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem {...formItemLayout} label=''>
                      {getFieldDecorator('key1', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Select
                          key={0}
                          onChange={this.onSelectChange3.bind(this)}
                          showSearch
                          allowClear
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {selectData &&
                            Array.isArray(selectData) &&
                            selectData.map((item, index) => {
                              return <Option key={`${item.key}-${index}`}>{item.desc}</Option>;
                            })}
                        </Select>,
                      )}
                    </FormItem>
                  </Col>
                  {comps &&
                    comps.map((comp, index) => {
                      return (
                        <div>
                          <Col span={3}>
                            <FormItem {...formItemLayout} label=''>
                              {getFieldDecorator(`operSymbol${index}`, {
                                rules: [{ required: false, message: '' }],
                              })(
                                <Select style={{ width: 80 }} key={`rule${index}`}>
                                  <Option value='+'>加</Option>
                                  <Option value='-'>减</Option>
                                  <Option value='*'>乘</Option>
                                  <Option value='/'>除</Option>
                                </Select>,
                              )}
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem {...formItemLayout} label=''>
                              {getFieldDecorator(`key${index + 2}`, {
                                rules: [{ required: false, message: '' }],
                              })(
                                <Select
                                  key={index + 2}
                                  onChange={this.onSelectChange4.bind(this)}
                                  showSearch
                                  allowClear
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {selectData &&
                                    Array.isArray(selectData) &&
                                    selectData.map((item, index) => {
                                      return (
                                        <Option key={`${item.key}-${index}`}>{item.desc}</Option>
                                      );
                                    })}
                                </Select>,
                              )}
                            </FormItem>
                          </Col>
                        </div>
                      );
                    })}
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem {...formItemLayout} label=''>
                      {getFieldDecorator('saleType', {
                        rules: [{ required: false, message: '' }],
                      })(
                        <Checkbox.Group style={{ width: '100%', paddingBottom: '30px' }}>
                          <Checkbox value='isIncludeTrade'>包出门</Checkbox>
                          <Checkbox value='replenishFlag'>补货</Checkbox>
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row gutter={24}>
                <Col span={3}>计算公式：</Col>
                <Col span={6}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('key1', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Select
                        onChange={this.onSelectChange3.bind(this)}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {eleCountData &&
                          Array.isArray(eleCountData) &&
                          eleCountData.map((item) => {
                            if (item.desc && item.desc.length === 1) {
                              return <Option key={`${item.key}-${item.id}`}>{item.desc[0]}</Option>;
                            } else if (item.desc && item.desc.length > 1) {
                              const { desc } = item;
                              desc.map((list) => {
                                return <Option key={`${item.key}-${item.id}`}>{list}</Option>;
                              });
                            } else {
                              return <Option key={`${item.key}-${item.id}`}>{item.name}</Option>;
                            }
                            return null;
                          })}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('unit1', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Select
                        style={{ width: 80 }}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {unit1 &&
                          Array.isArray(unit1) &&
                          unit1.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('operSymbol', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Select style={{ width: 80 }}>
                        <Option value='+'>加</Option>
                        <Option value='-'>减</Option>
                        <Option value='*'>乘</Option>
                        <Option value='/'>除</Option>
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('key2', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Select
                        onChange={this.onSelectChange4.bind(this)}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {eleCountData &&
                          Array.isArray(eleCountData) &&
                          eleCountData.map((item) => {
                            if (item.desc && item.desc.length === 1) {
                              return <Option key={`${item.key}-${item.id}`}>{item.desc[0]}</Option>;
                            } else if (item.desc && item.desc.length > 1) {
                              const { desc } = item;
                              desc.map((list) => {
                                return <Option key={`${item.key}-${item.id}`}>{list}</Option>;
                              });
                            } else {
                              return <Option key={`${item.key}-${item.id}`}>{item.name}</Option>;
                            }
                            return null;
                          })}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem {...formItemLayout} label=''>
                    {getFieldDecorator('unit2', {
                      rules: [{ required: false, message: '' }],
                    })(
                      <Select
                        style={{ width: 80 }}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {unit2 &&
                          Array.isArray(unit2) &&
                          unit2.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
            )}

            <Row gutter={24}>
              <Col span={3}>货款计算结果处理方式：</Col>
              <Col span={21}>
                <FormItem {...formItemLayout} label=''>
                  {getFieldDecorator('resultType', {
                    rules: [{ required: false, message: '' }],
                  })(
                    <RadioGroup>
                      <Radio value={1}>包零</Radio>
                      <Radio value={2}>抹零</Radio>
                      <Radio value={3}>四舍五入</Radio>
                      <Radio value={4}>五舍六入</Radio>
                      <Radio value={5}>不处理</Radio>
                    </RadioGroup>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <FormItem {...tailFormItemLayout}>
              <Button type='primary' htmlType='submit'>
                保存
              </Button>
            </FormItem>
          </Form>
        </Card>
      </div>
    );
  }
}
export default PagementTypePage;
