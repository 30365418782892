/**
 * 货款计算
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Card, Input, Button, Steps, message } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { fetchSave } from '../../state/action/count';
import './style.less';
import PaymentPage from './add/Payment';
import AddFeePage from './add/AddFee';
import LogicPage from './add/Logic';

const FormItem = Form.Item;
const { Step } = Steps;

@connect(
  (state) => {
    return { count: state.count };
  },
  { fetchSave },
)
@Form.create()
class CountPaymentCalculation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      value: null,
      value1: null,
    };
  }

  componentDidMount() {
    localStorage.removeItem('count_payment_rule');
    localStorage.removeItem('count_addFee_rule');
    localStorage.removeItem('count_ticketFace_rule');
    localStorage.removeItem('count_desc_data');
    localStorage.removeItem('reqStallId');
  }

  fetchSave(params) {
    this.props.fetchSave(params).then(() => {
      const {
        count: { saveData },
      } = this.props;
      const { code } = saveData || {};
      if (code === 200) {
        message.info('新增档口配置成功!');
        localStorage.removeItem('count_payment_rule');
        localStorage.removeItem('count_addFee_rule');
        localStorage.removeItem('count_ticketFace_rule');
        localStorage.removeItem('count_desc_data');
        localStorage.removeItem('reqStallId');
        localStorage.removeItem('count_addBatchItemFee_rule');
      }
    });
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  submit = () => {
    const { value, value1 } = this.state;
    const paymentData = JSON.parse(localStorage.getItem('count_payment_rule')) || [];
    const addFeeData = JSON.parse(localStorage.getItem('count_addFee_rule')) || [];
    const ticketFaceData = JSON.parse(localStorage.getItem('count_ticketFace_rule')) || {};
    const batchItemFeeData = JSON.parse(localStorage.getItem('count_addBatchItemFee_rule')) || [];
    const reqStallId = localStorage.getItem('reqStallId');
    const saleType = localStorage.getItem('saleType');
    let saleTypeS = [];
    if (saleType && 'null' !== saleType) {
      saleTypeS = saleType.split(',');
    }

    if (!(ticketFaceData?.keys?.length || ticketFaceData?.operSymbols?.length)) {
      message.warn('请先保存配置规则');
      return;
    }

    const obj = {
      // 如果不选择时默认全部档口
      reqStallId: reqStallId || 0,
      calcTypeName: value,
      remark: value1,
      saleType: saleTypeS,
      calcRule: {
        fee: paymentData.concat(addFeeData || []),
        rule: [ticketFaceData],
        batchItemFee: batchItemFeeData,
      },
    };
    this.fetchSave(obj);
  };

  change = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  change1 = (e) => {
    this.setState({
      value1: e.target.value,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { current } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    const steps = [
      {
        title: '货款计算',
        content: <PaymentPage type='payment' title='货款计算类型' />,
      },
      {
        title: '附加费计算',
        content: <AddFeePage type='addFee' title='附加费计算类型' />,
      },
      {
        title: '票面计算规则',
        content: <LogicPage type='ticketFace' secType='addBatchItemFee' title='票面计算规则' />,
      },
    ];

    const extraContent = (
      <div className='extraContent'>
        <h2>票面计算类型</h2>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={6}>货品票面计算类型名称：</Col>
            <Col span={9}>
              <FormItem {...formItemLayout} label=''>
                {getFieldDecorator('name', {
                  rules: [{ required: false, message: '请输入计算类型名称!' }],
                })(<Input onChange={this.change} />)}
              </FormItem>
            </Col>
            <Col span={9}>
              <FormItem {...formItemLayout} label=''>
                {getFieldDecorator('remark', {
                  rules: [{ required: false, message: '备注信息!' }],
                })(<Input onChange={this.change1} placeholder='备注信息' />)}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    );

    return (
      <div className='count-index'>
        <PageHeaderLayout>
          <div className='layout'>
            <Card className='layoutContent' bordered={false} title='' extra={extraContent}>
              <Steps current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <div className='steps-content'>{steps[current].content}</div>
              <div className='steps-action'>
                {current < steps.length - 1 && (
                  <Button type='primary' onClick={() => this.next()}>
                    下一步
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type='primary' onClick={this.submit}>
                    完成
                  </Button>
                )}
              </div>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default CountPaymentCalculation;
