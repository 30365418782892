import * as actionTypes from '../constant/commodity';

const initState = {
  productsData: [],
  searchForm: {},
  commodityBaseList: {},
  updateCommodityData: {},
  deleteCommodityData: {},
  unrelatedAliaList: [], // 未关联别名的数据
  allAliasList: [], // 已关联别名的数据
  insertAliasData: {},
  unrelatedList: {},
  unrelatedSourceData: {}, // 来源数据
};

export function commodity(state = initState, action) {
  switch (action.type) {
    case actionTypes.COMMODITY_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
