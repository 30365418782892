// 商品库管理-未关联货品
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, Input, Table, Modal, Divider, message, Checkbox } from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import '../commodity-base/index.less';

import {
  getUnrelatedList,
  updateUnrelatedEnable,
  getUnrelatedSource,
  insertAliasName,
} from '../../../state/action/commodity';

const { Column } = Table;
const FormItem = Form.Item;

const pageSize = 10;

const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
const layout1 = { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 };

@connect(
  (state) => {
    return { commodity: state.commodity };
  },
  { getUnrelatedList, updateUnrelatedEnable, getUnrelatedSource, insertAliasName },
)
@Form.create()
class UnassociatedGoods extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      rows: {},
      defaultCurrent: 1,
      addVisible: false,
    };
  }

  componentDidMount() {
    this.getUnrelatedList('', true);
  }

  // 未关联货品-列表
  async getUnrelatedList(name, enable) {
    const { defaultCurrent } = this.state;
    const params = {
      name,
      pageNo: defaultCurrent,
      pageSize: pageSize,
      enable,
    };
    this.setState({ loading: true });
    await this.props.getUnrelatedList(params);
    this.setState({ loading: false });
  }

  // 查询
  handleSumbit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const name = this.isUndefied(values.name);
      let enable = this.isUndefied(values.enable);
      if (enable.length > 0 && enable.length < 2) {
        enable = enable.indexOf('显示') >= 0 ? true : false;
      } else {
        enable = null;
      }
      this.setState(
        {
          defaultCurrent: 1,
        },
        () => {
          this.getUnrelatedList(name, enable);
        },
      );
    });
  };

  isUndefied = (value) => {
    return value || null;
  };

  // 重置
  handleReset = () => {
    this.props.form.resetFields();
    this.setState(
      {
        defaultCurrent: 1,
      },
      () => {
        this.getUnrelatedList('', true);
      },
    );
  };

  pageSwitch = (obj) => {
    this.setState(
      {
        defaultCurrent: obj.current,
      },
      () => {
        this.refreshTable();
      },
    );
  };

  refreshTable = () => {
    const formData = this.props.form.getFieldsValue();
    const name = this.isUndefied(formData.name);
    let enable = this.isUndefied(formData.enable);
    if (enable.length > 0 && enable.length < 2) {
      enable = enable.indexOf('显示') >= 0 ? true : false;
    } else {
      enable = null;
    }
    this.getUnrelatedList(name, enable);
  };

  // 查看来源
  queryOrigin = (record, value) => {
    if (value) {
      this.getUnrelatedSource(record, value);
    } else {
      this.setState({
        rows: record,
        visible: value,
      });
    }
  };

  async getUnrelatedSource(record, value) {
    await this.props.getUnrelatedSource({ name: record.name });
    const {
      commodity: { unrelatedSourceData },
    } = this.props;
    if (unrelatedSourceData.code !== 200) {
      message.error(unrelatedSourceData.msg);
    } else {
      this.setState({
        rows: record,
        visible: value,
      });
    }
  }

  // 显示、隐藏
  updateStatus = (e, record) => {
    //
    let updEnable = false;
    if (record.enable) {
      updEnable = false;
    } else {
      updEnable = true;
    }
    this.updateUnrelatedEnable({ id: record.id, enable: updEnable });
  };

  async updateUnrelatedEnable(params) {
    const result = await this.props.updateUnrelatedEnable(params);
    if (result.code === 200) {
      this.refreshTable();
    } else {
      message.error(result.msg);
    }
  }

  // 新增
  showAddGoodsAlias = (value) => {
    this.setState({
      addVisible: value,
    });
  };

  editHandleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const productAlias = this.isUndefied(values.aliasName);
        this.insertAliasName({ productAlias });
      }
    });
  };

  async insertAliasName(params) {
    const result = await this.props.insertAliasName(params);
    if (result.code === 200) {
      message.success('手动新增成功');
      this.setState(
        {
          addVisible: false,
        },
        () => {
          this.refreshTable();
        },
      );
    } else {
      message.error(result.msg);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, rows, visible, defaultCurrent, addVisible } = this.state;
    const {
      commodity: { unrelatedList, unrelatedSourceData },
    } = this.props;
    const unrelatedSourceMap = unrelatedSourceData.data
      ? JSON.parse(unrelatedSourceData.data).join('、')
      : '';

    const pageList = unrelatedList ? unrelatedList.pageList : [];
    const dataSource = pageList && pageList.length > 0 ? pageList : [];

    const pagination = {
      pageSize: 10,
      total: unrelatedList && unrelatedList.totalSize,
      current: defaultCurrent,
    };

    return (
      <div className='commodity-index'>
        <PageHeaderLayout>
          <div className='commodityManage'>
            <Form onSubmit={this.handleSumbit} layout='inline'>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem label='货品别名'>
                    {getFieldDecorator(
                      'name',
                      {},
                    )(<Input placeholder='请输入货品别名' allowClear />)}
                  </FormItem>
                </Col>
                <Col {...layout}>
                  <FormItem label=''>
                    {getFieldDecorator('enable', {
                      initialValue: ['显示'],
                    })(<Checkbox.Group options={['显示', '隐藏']} />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row gutter={10}>
                <Col span={24}>
                  <Button type='primary' style={{ width: 80 }} htmlType='submit'>
                    查询
                  </Button>
                  <Button style={{ marginLeft: 25, width: 80 }} onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    type='primary'
                    style={{ marginLeft: 25 }}
                    onClick={() => this.showAddGoodsAlias(true)}
                  >
                    手动添加货品别名
                  </Button>
                </Col>
              </Row>
            </Form>
            <Table
              rowKey={(_, index) => index}
              dataSource={dataSource}
              pagination={pagination}
              scroll={{ x: 600 }}
              bordered
              loading={loading}
              onChange={this.pageSwitch.bind(this)}
            >
              <Column title='货品别名' dataIndex='name' key='name' width='200px' />
              <Column
                title='货品别名状态'
                dataIndex='enable'
                key='enable'
                width='100px'
                render={(_, record) => {
                  return record.enable ? (
                    <div>
                      <Button
                        type='primary'
                        shape='circle'
                        style={{ height: 8, minWidth: 8, marginTop: 6 }}
                      />
                      显示
                    </div>
                  ) : (
                    <div>
                      <Button
                        shape='circle'
                        style={{ height: 8, minWidth: 8, marginTop: 6, background: '#ccc' }}
                      />
                      隐藏
                    </div>
                  );
                }}
              />
              <Column
                title='查询来源'
                dataIndex='queryOption'
                key='queryOption'
                width='100px'
                render={(_, record) => {
                  return (
                    <a
                      href='/'
                      onClick={(e) => {
                        e.preventDefault();
                        this.queryOrigin(record, true);
                      }}
                    >
                      查询
                    </a>
                  );
                }}
              />
              <Column
                title='操作'
                dataIndex='option'
                key='option'
                width='200px'
                render={(_, record) => {
                  if (record.enable) {
                    return (
                      <div>
                        <label>显示</label>
                        <Divider type='vertical' />
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.updateStatus(e, record);
                          }}
                        >
                          隐藏
                        </a>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.updateStatus(e, record);
                          }}
                        >
                          显示
                        </a>
                        <Divider type='vertical' /> <label>隐藏</label>
                      </div>
                    );
                  }
                }}
              />
            </Table>
            {/** 来源查询 */}
            {visible && (
              <Modal
                title={null}
                visible={visible}
                onCancel={() => this.queryOrigin({}, false)}
                destroyOnClose='true'
                width='600px'
                className='commodity-modal'
                footer={null}
              >
                <div>
                  <Row style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>
                    <h3>{rows.name}</h3>
                  </Row>
                  <FormItem label='所属商行'>{unrelatedSourceMap}</FormItem>
                  <br />
                  <Row gutter={10} style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Button type='primary' onClick={() => this.queryOrigin({}, false)}>
                        确认
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Modal>
            )}
            {/** 手动新增别名 */}
            {addVisible && (
              <Modal
                title={null}
                visible={addVisible}
                onCancel={() => this.showAddGoodsAlias(false)}
                destroyOnClose='true'
                width='600px'
                className='commodity-modal'
                footer={null}
              >
                <Form ref='addAliasRef'>
                  <Row style={{ textAlign: 'center' }}>
                    <h3>手动添加货品别名</h3>
                  </Row>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='货品别名'>
                        {getFieldDecorator('aliasName', {
                          initialValue: '',
                          rules: [
                            {
                              required: true,
                              validator: (_, value, callback) => {
                                if (value) {
                                  if (value.length > 6) {
                                    callback('货品别名不超过6位');
                                  }
                                } else {
                                  callback('请输入货品别名');
                                }
                                callback();
                              },
                            },
                          ],
                        })(<Input type='text' placeholder='请输入货品名称' allowClear />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={10} style={{ textAlign: 'center' }}>
                    <Col span={24}>
                      <Button type='primary' onClick={this.editHandleSubmit}>
                        保存
                      </Button>
                      <Button
                        style={{ marginLeft: 25 }}
                        onClick={() => this.showAddGoodsAlias(false)}
                      >
                        取消
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            )}
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default UnassociatedGoods;
