import React, { PureComponent } from 'react';
import { Input, Icon, AutoComplete } from 'antd';
import classNames from 'classnames';
import './index.less';

export default class HeaderSearch extends PureComponent {
  static defaultProps = {
    defaultActiveFirstOption: false,
    onPressEnter: () => {},
    onSearch: () => {},
    className: '',
    placeholder: '',
    dataSource: [],
    defaultOpen: false,
  };
  state = {
    searchMode: this.props.defaultOpen,
    value: '',
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.timeout = setTimeout(() => {
        this.props.onPressEnter(this.state.value); // Fix duplicate onPressEnter
      }, 0);
    }
  };
  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange();
    }
  };
  enterSearchMode = () => {
    this.setState({ searchMode: true }, () => {
      if (this.state.searchMode) {
        this.input.focus();
      }
    });
  };
  leaveSearchMode = () => {
    this.setState({
      searchMode: false,
      value: '',
    });
  };
  render() {
    const { className, placeholder, ...restProps } = this.props;
    delete restProps.defaultOpen; // for rc-select not affected
    const inputClass = classNames('input', {
      show: this.state.searchMode,
    });

    return (
      <span className='header-search-index'>
        <span className={classNames(className, 'headerSearch')} onClick={this.enterSearchMode}>
          <Icon type='search' key='Icon' />
          <AutoComplete
            key='AutoComplete'
            {...restProps}
            className={inputClass}
            value={this.state.value}
            onChange={this.onChange}
          >
            <Input
              placeholder={placeholder}
              ref={(node) => {
                this.input = node;
              }}
              onKeyDown={this.onKeyDown}
              onBlur={this.leaveSearchMode}
            />
          </AutoComplete>
        </span>
      </span>
    );
  }
}
