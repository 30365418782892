// 车次管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Select,
  Input,
  InputNumber,
  TreeSelect,
  Upload,
  Icon,
} from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import { initMarket, getAllDistrictInfo, updateMarket } from '../../state/action/market';
import * as req from '../../state/request/market';

import './style.less';

const { Option } = Select;

let FormItem = Form.Item;
@connect(
  (state) => {
    return { market: state.market };
  },
  { initMarket, getAllDistrictInfo, updateMarket },
)
@Form.create()
class ModifyMarket extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cityData: [],
      fileList: [],
    };
    this.id = this.props.match.params.id;
  }
  componentDidMount() {
    this.getAllDistrict();
    if (this.id) this.getMarketById({ id: this.id });
  }
  // 获取对应id的数据
  async getMarketById(params) {
    const res = await this.props.initMarket(params);
    if (res.code === 200) {
      const { businessLicense } = res.data;
      if (businessLicense) {
        this.setState({
          fileList: [
            {
              uid: '-1',
              name: '营业执照',
              status: 'done',
              url: businessLicense,
            },
          ],
        });
      }
    }
  }
  // 获取省市区信息
  async getAllDistrict() {
    await this.props.getAllDistrictInfo();
  }
  // 提交事件
  handleSubmit = (e, id) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      const {
        name,
        title,
        address,
        cityCode,
        stallNum,
        marketType,
        shortName,
        inChargeName,
        inChargePhone,
      } = values;
      const { fileList } = this.state;
      const upFiles = fileList.length > 0 ? fileList[0].url : '';
      const params = {
        id,
        name,
        title,
        address,
        cityCode,
        stallNum: Number(stallNum),
        marketType,
        shortName,
        inChargeName,
        inChargePhone,
        businessLicense: upFiles,
      };
      if (!err) {
        await this.props.updateMarket(params);
        this.props.history.push('/marketList/marketList');
      }
    });
  };
  back = () => {
    this.props.history.push('/marketList/marketList');
  };
  handleChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    if (fileList.length > 0) {
      req
        .uploadMarketBusinessLicense({
          file: fileList[0].originFileObj,
        })
        .then((res) => {
          const { code, data = [] } = res;
          if (code === 200) {
            if (data.length > 0) {
              this.setState({
                fileList: [
                  {
                    uid: '-1',
                    name: '营业执照',
                    status: 'done',
                    url: data[0],
                  },
                ],
              });
            }
          }
        });
    }
  };
  onRemove = () => {
    this.setState({
      fileList: [],
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      market: { marketInfo, districtInfo },
    } = this.props;
    const {
      name,
      title,
      address,
      marketType,
      cityCode,
      stallNum,
      shortName,
      inChargeName,
      inChargePhone,
    } = this.id ? marketInfo : {};
    const { fileList } = this.state;
    const { code, data } = districtInfo;
    let cityData = [];
    if (code === 200) {
      cityData = data.mapping.origins;
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <div className='market-index'>
        <PageHeaderLayout>
          <div className={'newMarketLayout'}>
            <Card bordered={false} title=''>
              <Form
                key={this.id || '0'}
                hideRequiredMark
                onSubmit={(e) => {
                  this.handleSubmit(e, this.id);
                }}
              >
                <FormItem {...formItemLayout} label='市场名称'>
                  {getFieldDecorator('name', { initialValue: name })(
                    <Input placeholder='请输入' />,
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label='数据服务市场简称'>
                  {getFieldDecorator('shortName', { initialValue: shortName })(
                    <Input placeholder='请输入' />,
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label='所在城市'>
                  {getFieldDecorator('cityCode', { initialValue: cityCode })(
                    <TreeSelect
                      showSearch
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      treeData={cityData}
                      placeholder='请选择城市'
                      treeDefaultExpandAll={false}
                      onChange={this.onCityChange}
                      filterTreeNode={(input, treeNode) => {
                        return treeNode.props.title.indexOf(input) >= 0;
                      }}
                    />,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label='市场类型'>
                  {getFieldDecorator('marketType', {
                    rules: [{ required: true, message: '请选择市场类型' }],
                    initialValue: marketType ? marketType + '' : '1',
                  })(
                    <Select>
                      <Option value='1'>蔬菜</Option>
                      <Option value='5'>水果</Option>
                      <Option value='10'>水产</Option>
                      <Option value='15'>二批（零售/支持优惠）</Option>
                    </Select>,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label='详细地址'>
                  {getFieldDecorator('address', { initialValue: address })(
                    <Input placeholder='请输入' />,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label='负责人'>
                  {getFieldDecorator('inChargeName', { initialValue: inChargeName })(
                    <Input placeholder='请输入' />,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label='联系电话'>
                  {getFieldDecorator('inChargePhone', { initialValue: inChargePhone })(
                    <Input placeholder='请输入' />,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label='市场规模（档口数）'>
                  {getFieldDecorator('stallNum', { initialValue: stallNum })(<InputNumber />)}
                </FormItem>
                <FormItem {...formItemLayout} label='打印市场抬头'>
                  {getFieldDecorator('title', { initialValue: title })(
                    <Input placeholder='请输入' />,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label='营业执照'>
                  <Upload
                    listType='picture'
                    fileList={fileList}
                    onRemove={this.onRemove}
                    onChange={this.handleChange}
                    beforeUpload={() => false}
                  >
                    <Button>
                      <Icon type='upload' /> 上传营业执照
                    </Button>
                  </Upload>
                </FormItem>
                <Row>
                  <Col align='center'>
                    <Button type='primary' htmlType='submit'>
                      提交
                    </Button>
                    <Button onClick={this.back} style={{ marginLeft: '5px' }}>
                      返回
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default ModifyMarket;
