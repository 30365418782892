import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Badge,
  Space,
  Popconfirm,
  message,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Image,
} from 'antd-v5';
import { useImmer } from 'use-immer';

import {
  getPayMethodConfigList,
  getPayMethodConfigUpdate,
  getPayMethodConfigEnable,
} from '@/state/request/stall';

import { PAYMENY_ICON, isImageLink, regex, preloadImages } from './const';

import './index.less';

const Option = Select.Option;

const PreviewImage = ({ url, preview = true, style }) => {
  if (!isImageLink(url)) return null;
  return (
    <Image.PreviewGroup>
      {/** 未选中 */}
      <Image src={url} style={{ width: 60, ...style }} preview={preview} />
      {/** 选中 */}
      <Image
        src={url.replace('.png', '_2.png')}
        style={{ width: 60, ...style }}
        preview={preview}
      />
      {/** pos用彩色 */}
      <Image
        src={url.replace('.png', '_1.png')}
        style={{ width: 60, ...style }}
        preview={preview}
      />
    </Image.PreviewGroup>
  );
};

const CashierAccountConfiguration = (props) => {
  const [list, setList] = useState([]);
  const [store, setStore] = useImmer({
    open: false,
    data: {},
  });
  const [form] = Form.useForm();

  const { stallId } = props;
  const { editFlag, payMethod } = store.data || {};
  const disabled = !editFlag;
  // 自定义9种支付方式可以修改图标
  const disabled1 = !editFlag && ![14, 15, 16, 17, 18, 19, 20, 21, 22].includes(payMethod);

  const getData = useCallback(async () => {
    const res = await getPayMethodConfigList(stallId);
    const { code, data = [] } = res || {};
    if (code === 200) {
      setList(data);
    }
  }, [stallId]);

  useEffect(() => {
    getData();
    preloadImages(PAYMENY_ICON)
      .then(() => {})
      .catch(() => {});
  }, [getData]);

  const onConfirm = async (values) => {
    const { enable, payMethod } = values;
    const params = {
      stallId,
      payMethod,
      enable: enable ? false : true,
    };
    await getPayMethodConfigEnable(params);
    getData();
  };

  const onCancel = () => {
    setStore({
      open: false,
      data: {},
    });
    form.setFieldsValue({});
  };

  const onFinish = async (values) => {
    const { unselectedImg, description } = values;
    if (description.length > 10) {
      message.warn('收银账户名称最长十个字符');
      return;
    }
    if (description.indexOf('&') > -1) {
      message.warn('不能包含特殊字符&');
      return;
    }
    const params = {
      ...values,
      selectedImg: unselectedImg.replace('.png', '_2.png'),
      colorImg: unselectedImg.replace('.png', '_1.png'),
      stallId,
    };
    onCancel();
    await getPayMethodConfigUpdate(params);
    getData();
  };

  const columns = [
    {
      title: '收银账户',
      dataIndex: 'description',
      render: (t) => t || '--',
    },
    {
      title: '状态',
      dataIndex: 'enable',
      render: (t) => <Badge status={t ? 'success' : 'error'} text={t ? '启用' : '禁用'} />,
    },
    {
      title: '排序',
      dataIndex: 'sort',
    },
    {
      title: '图标',
      dataIndex: 'unselectedImg',
      render: (url) => {
        return <PreviewImage url={url} style={{ width: 48 }} />;
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_, record) => {
        const { enable } = record;
        const txt = enable ? '禁用' : '启用';
        return (
          <Space>
            <div
              className='target btn'
              onClick={() => {
                setStore({ open: true, data: record });
                form.setFieldsValue({ ...record });
              }}
            >
              编辑
            </div>
            <Popconfirm title={`是否要${txt}收银账户`} onConfirm={() => onConfirm(record)}>
              <span className='btn' style={enable ? { color: 'red' } : { color: 'green' }}>
                {txt}
              </span>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div className='table'>
      <Table
        rowKey='payMethod'
        dataSource={list}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
      <Modal
        title='编辑收银账户'
        open={store.open}
        destroyOnClose
        onCancel={onCancel}
        okButtonProps={{
          htmlType: 'submit',
          form: 'form',
        }}
      >
        <Form
          name='form'
          form={form}
          preserve={false}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout='horizontal'
        >
          <Space direction='vertical' style={{ width: '100%' }}>
            <Form.Item
              name='description'
              label='收银账户名称'
              rules={[
                {
                  required: true,
                  message: '请输入收银账户名称（名称不能以数字结尾）',
                  pattern: regex,
                  max: 10,
                },
              ]}
            >
              <Input placeholder='请输入收银账户名称' maxLength={10} disabled={disabled} />
            </Form.Item>
            <Form.Item
              name='sort'
              label='收银账户排序'
              rules={[
                {
                  required: true,
                  message: '请输入收银账户排序值',
                },
              ]}
            >
              <InputNumber
                placeholder='请输入收银账户排序值'
                controls={false}
                min={1}
                max={99999}
                maxLength={5}
                precision={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item name='payMethod' style={{ display: 'none' }}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='unselectedImg'
              label='收银账户图标'
              rules={[
                {
                  required: true,
                  message: '请选择收银账户图标',
                },
              ]}
            >
              <Select allowClear style={{ height: 80 }} disabled={disabled1}>
                {PAYMENY_ICON.map((url) => {
                  return (
                    <Option key={url} value={url}>
                      <Space>
                        <PreviewImage url={url} preview={false} />
                      </Space>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </div>
  );
};

export default CashierAccountConfiguration;
