import React, { PureComponent } from 'react';
import moment from 'moment';
import { Form, Button, Input, Table, DatePicker, Select, message, Card } from 'antd';
import PageHeaderLayout from '../../component/page-header-layout/index';
import * as req from '../../state/request/dunning';
import './dunningByLaw.less';

import { exportFile } from '../../utils/utils';

const { Option } = Select;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

@Form.create()
class RemotePayAnalysis extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: null,
      pageNo: 1,
      pageSize: 10,
      result: {},
      summery: {},
    };
    this.pageSwitch = this.pageSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.doRequest = this.doRequest.bind(this);
    this.disabledStartDate = this.disabledStartDate.bind(this);

    this.typeList = [
      { value: '', desc: '全部' },
      { value: 5, desc: '赊欠签字' },
      { value: 6, desc: '律师函催款' },
      { value: 7, desc: '组合催款' },
    ];
  }

  //初始加载
  componentDidMount() {
    this.doRequest(1);
  }
  doRequest(pageNo) {
    this.setState({
      pageNo: pageNo,
    });
    const { form } = this.props;
    const { pageSize } = this.state;
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      const rangeValue = fieldsValue['range-picker'];
      const { stallName, businessTypeList, stallId } = fieldsValue;

      let startDT = rangeValue[0];
      let endDT = rangeValue[1];
      if (endDT.diff(startDT, 'months') > 0) {
        message.warn('查询时间不能超过1个月');
        return;
      }
      let tmpBusinessType = null;
      if (businessTypeList === '') {
        tmpBusinessType = [];
      } else if (businessTypeList === 5) {
        tmpBusinessType = [5, 9];
      } else {
        tmpBusinessType = [businessTypeList];
      }
      const params = {
        stallId,
        stallName,
        businessTypeList: tmpBusinessType,
        businessType: businessTypeList,
        startTime: startDT.startOf('day').format('x'),
        endTime: moment(endDT).add(1, 'days').startOf('day').format('x'),
        pageNo: pageNo,
        pageSize: pageSize,
      };
      const { code, data } = await req.fetchDunningRecord(params);
      const res2 = await req.fetchDunningRecordSummery(params);
      if (code === 200) {
        this.setState({ result: data });
      }
      if (res2.code === 200) {
        this.setState({ summery: res2.data });
      }
    });
  }

  //提交请求
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ pageNo: 1 });
    this.doRequest(1);
  };

  exportFile = async () => {
    this.props.form.validateFields(async (errors, fieldsValue) => {
      if (!!errors) {
        return;
      }
      const key = 'range-picker';
      const { [key]: date, businessTypeList, ...rest } = fieldsValue;

      const startDT = date[0];
      const endDT = date[1];

      if (endDT.diff(startDT, 'months') > 0) {
        message.warn('导出时间不能超过1个月');
        return;
      }
      let tmpBusinessType = null;
      if (businessTypeList === '') {
        tmpBusinessType = [];
      } else if (businessTypeList === 5) {
        tmpBusinessType = [5, 9];
      } else {
        tmpBusinessType = [businessTypeList];
      }

      const response = await req.exportDunningRecord({
        startTime: startDT.startOf('day').format('x'),
        endTime: moment(endDT).add(1, 'days').startOf('day').format('x'),
        businessTypeList: tmpBusinessType,
        ...rest,
      });
      exportFile(response, '法律催款服务.xlsx');
    });
  };

  // 分页切换
  pageSwitch = (obj) => {
    this.doRequest(obj.current);
  };
  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.doRequest(1);
  };

  disabledStartDate = (startValue) => {
    return startValue > new Date().getTime(); //大于今天的日期一律返回true，禁止选择
  };

  getExtraContent() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const {
      summery: { amount = 0, buyerCount = 0, stallCount = 0 },
    } = this.state;
    return (
      <div className='PayAnalysis'>
        <Form layout='inline' onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('range-picker', {
              rules: [{ type: 'array', required: true, message: '请输入查询时间!' }],
              initialValue: [moment(), moment()],
            })(
              <RangePicker
                format='YYYY-MM-DD'
                disabledDate={this.disabledStartDate}
                ranges={{
                  今天: [moment(), moment()],
                  当月: [moment().startOf('month'), moment()],
                }}
              />,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('businessTypeList', {
              rules: [{ required: false }],
              initialValue: '',
            })(
              <Select style={{ width: 200 }} allowClear={false}>
                {this.typeList.map((item, index) => (
                  <Option value={item.value} key={index}>
                    {item.desc}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('stallId', {
              rules: [
                {
                  required: false,
                  message: '请输入商行ID',
                },
                {
                  pattern: /^\d+$/,
                  message: '只能输入数字!',
                },
              ],
            })(<Input placeholder='请输商行ID' allowClear autoComplete='off' />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('stallName', {
              rules: [{ required: false, message: '请输商行名称!' }],
            })(<Input placeholder='请输商行名称' allowClear autoComplete='off' />)}
          </FormItem>

          <FormItem>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button style={{ marginLeft: '18px' }} onClick={this.handleReset}>
              重置
            </Button>
            <Button style={{ marginLeft: '18px' }} onClick={this.exportFile}>
              导出
            </Button>
          </FormItem>
        </Form>
        <div className='summery'>
          <div className='item'>
            <div className='title'>签字金额：{amount}</div>
          </div>
          <div className='item'>
            <div className='title'>签字商行数：{stallCount}</div>
          </div>
          <div className='item'>
            <div className='title'>签字买家数：{buyerCount}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { pageList = [], totalSize = 0 } = this.state.result;
    const { pageNo } = this.state;
    const extraContent = this.getExtraContent();

    let repayRecordList = pageList;
    const pagination = {
      pageSize: 10,
      current: pageNo,
      total: totalSize,
    };
    const columns = [
      {
        title: '时间',
        dataIndex: 'createTime',
        width: 180,
        render: (_, record) => {
          const { createTime } = record;
          return (
            <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
              {moment(createTime).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          );
        },
      },
      {
        title: '订单ID',
        dataIndex: 'orderNo',
        width: 220,
      },

      {
        title: '商行ID',
        dataIndex: 'stallId',
      },
      {
        title: '商行名称',
        dataIndex: 'stallName',
        render: (text) => {
          return <span>{text || ''}</span>;
        },
      },
      {
        title: '手机号',
        dataIndex: 'cellphone',
      },
      {
        title: '产品类型',
        dataIndex: 'businessType',
        render: (text) => {
          if (text === 9) {
            return '赊欠签字';
          }
          return this.typeList.find((item) => item.value === text)?.desc || '';
        },
      },
      {
        title: '订单金额',
        dataIndex: 'orderAmount',
        render: (text) => {
          return <span>{text || ''}</span>;
        },
      },
    ];
    return (
      <PageHeaderLayout>
        <Card bordered={false}>
          {extraContent}
          <Table
            rowKey={(_, index) => index}
            dataSource={repayRecordList}
            columns={columns}
            pagination={pagination}
            onChange={this.pageSwitch}
            bordered
            scroll={{ x: true }}
          />
        </Card>
      </PageHeaderLayout>
    );
  }
}
export default RemotePayAnalysis;
