// 多支付方式图标
export const PAYMENY_ICON = [
  'https://images.cxiaomi.com/prod/payMethod/alipay.png',
  'https://images.cxiaomi.com/prod/payMethod/cash.png',
  'https://images.cxiaomi.com/prod/payMethod/extend1.png',
  'https://images.cxiaomi.com/prod/payMethod/extend2.png',
  'https://images.cxiaomi.com/prod/payMethod/other.png',
  'https://images.cxiaomi.com/prod/payMethod/scan.png',
  'https://images.cxiaomi.com/prod/payMethod/unionPay.png',
  'https://images.cxiaomi.com/prod/payMethod/wechat.png',
  'https://images.cxiaomi.com/prod/payMethod/extend3.png',
];

export const regex = /^(?!.*&)(?!.*\d$).*/;

export const isImageLink = (str) => {
  const regex = /^(https|http?:\/\/)?\S+?\.(png|jpe?g|gif|webp|bmp)$/i;
  return regex.test(str);
};

export const preloadImages = (imageUrls) => {
  const promises = [];
  for (let i = 0; i < imageUrls.length; i++) {
    const promise = new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageUrls[i];
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
    });
    promises.push(promise);
  }
  return Promise.all(promises);
};
