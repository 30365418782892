import * as actionTypes from '../constant/voiceCDKEY';
import * as req from '../request/voiceCDKEY';

//商行管理
export function listAll(params) {
  return async (dispatch) => {
    const res = await req.listAll(params);
    if (res) {
      dispatch({ type: actionTypes.VOICE_CDKEY_SAVE, payload: { list: res.data, search: params } });
    }
  };
}
