import React from 'react';
import { Form, Modal, Input, message } from 'antd-v5';

import { addSale, updateSale } from '../../../state/request/salesman';

const AddModal = (props) => {
  const [form] = Form.useForm();
  const { data = {}, open, onCancel, onSubmit } = props;

  const onFinish = async (values) => {
    const fn = data.id ? updateSale : addSale;
    const res = await fn(values);
    const msg = data.id ? '编辑销售人员成功' : '新增销售人员成功';
    if (res && res.code === 200) {
      message.info(msg);
      onSubmit();
    }
  };

  return (
    <Modal
      title={`${data.id ? '编辑' : '新增'}销售人员`}
      open={open}
      destroyOnClose
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Form
        form={form}
        preserve={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
        initialValues={data}
      >
        {data.id && (
          <Form.Item name='id' hidden={data.id}>
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name='name'
          label='姓名'
          rules={[
            {
              required: true,
              message: '请输入销售姓名',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='firstDepartment'
          label='业务线'
          rules={[
            {
              required: true,
              message: '请输入业务线',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='pioneerDivision'
          label='所属部门'
          rules={[
            {
              required: true,
              message: '请输入所属部门',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddModal;
