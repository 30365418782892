import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Table, Modal } from 'antd-v5';
import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getBusinessSequenceUpdate } from '../../../../state/request/serve';

const columns = [
  {
    title: '增值服务名称',
    dataIndex: 'name',
  },
  {
    key: 'sort',
  },
];

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ServeSort = forwardRef((props, ref) => {
  const [dataSource, setDataSource] = useState([]);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setDataSource(data.map(item => {
        return { ...item, key: String(item.id) };
      }));
    },
  }));

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const onSubmit = async () => {
    const list = dataSource.map((item, index) => ({ id: item.id, sequence: index + 1 }));
    await getBusinessSequenceUpdate({ businessSequenceList: list });
    props.refresh && props.refresh();
    setOpen(false);
  }

  return (
    <Modal
      title='增值服务排序'
      open={open}
      destroyOnClose
      onOk={onSubmit}
      onCancel={() => setOpen(false)}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Modal>
  )
});

export default ServeSort;
