// 数据服务管理-数据服务基库货品
// 车次管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Table } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import './goodsSaleDetailTime.less';
import { getHourSaleDetail } from '../../state/request/dataService';
import moment from 'moment';

const { Column } = Table;

@connect((state) => {
  return { market: state.market };
}, {})
@Form.create()
class GoodsSaleDetailTime extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageNo: 1,
      data: [], //列表
    };
    this.id = this.props.match.params.id;
    this.hourPriceId = this.props.match.params.hourPriceId;
  }

  componentDidMount() {
    this.getData(1); //获得页面数据
  }

  //获得商品库货品列表
  getData = async (pageNo) => {
    this.setState({ loading: true });
    const { searchValue1, hourPriceId } = this.props.history.location.state || {};
    const param = {
      basicProductId: searchValue1.basicProductId,
      dateStartTime: searchValue1.dateStartTime,
      marketId: searchValue1.marketId,
      hourPriceId,
      pageNo,
      pageSize: 10,
    };
    const response = await getHourSaleDetail(param);
    const { code, data } = response;
    if (code === 200) {
      this.setState({ data, loading: false });
    }
  };

  //返回
  back = () => {
    const { record1, searchValue1, pageNo1 } = this.props.history.location.state || {};
    this.props.history.push({
      pathname: `/data-service/goodsSale/${this.id}`,
      state: {
        record1,
        searchValue1,
        pageNo1,
      },
    });
  };

  onChangeTable = (pagination) => {
    this.getData(pagination.current); //获得页面数据
  };

  render() {
    const { record1, searchValue1, timeDesc } = this.props.history.location.state || {};
    const { data } = this.state;
    // 表格的分页的设计
    const { pageList = [], pageNo = 1, totalSize = 0 } = data;
    const pagination = {
      pageSize: 10,
      current: pageNo,
      total: totalSize,
    };

    return (
      <div className='goods-sale-detail-time'>
        <PageHeaderLayout>
          <div className='goods-sale-detail-content'>
            <div className='header'>
              <Button onClick={this.back}>返回</Button>
              <span>{record1.marketName || ''}</span>
              <span>{record1.basicProductName || ''}</span>
              <span>{moment(searchValue1.dateStartTime).format('YYYY-MM-DD') || ''}</span>
              <span>{timeDesc}</span>
            </div>
            <div className='content'>
              <Table
                rowKey={(_, index) => index}
                dataSource={pageList}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.onChangeTable}
              >
                <Column
                  title='时间'
                  width={200}
                  dataIndex='createTime'
                  key='createTime'
                  render={(text) => {
                    return moment(text).format('YYYY-MM-DD HH:mm:ss');
                  }}
                />
                <Column title='商行' align='center' dataIndex='stallName' key='stallName' />
                <Column title='货品' dataIndex='productName' key='productName' />
                <Column title='销售数量' dataIndex='saleNumber' key='saleNumber' />
                <Column title='销售重量' dataIndex='saleWeight' key='saleWeight' />
                <Column title='货款' dataIndex='goodsAmount' key='goodsAmount' />
                <Column title='均价' dataIndex='avgUnitPrice' key='avgUnitPrice' />
              </Table>
            </div>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default GoodsSaleDetailTime;
