/**
 * 计算配置--配置分组（分组配置）
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Card, Button, Tooltip, Select, Table, Modal, InputNumber } from 'antd';
import { Space } from 'antd-v5';

import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  getStallConfigGroupById,
  getCalcTypeConfigByStallId,
  addStallConfigGroup,
  removeStallConfigGroup,
  updateStallConfigShowFlag,
} from '../../state/action/basic';

import Edit from './edit';

import './style.less';

const FormItem = Form.Item;
const { Option } = Select;
const { Column } = Table;

@connect(
  (state) => {
    return { basic: state.basic };
  },
  {
    getStallConfigGroupById,
    getCalcTypeConfigByStallId,
    addStallConfigGroup,
    removeStallConfigGroup,
    updateStallConfigShowFlag,
  },
)
@Form.create()
class GroupConfig extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      pageNo: 1,
      modalTital: '新建规则',
      id: null,
      modalInfo: [
        {
          id: null,
          calcTypeName: null,
          text: '保存',
        },
      ],
    };
  }

  componentDidMount() {
    let paramsList = this.props.location.state.data.split('&&');
    this.setState({
      id: paramsList[1],
    });
    let keyCode1 = {
      reqStallId: paramsList[0],
    };
    this.getData();
    this.getCalcTypeConfigByStallId(keyCode1);
  }

  getData() {
    let paramsList = this.props.location.state.data.split('&&');

    let keyCode2 = {
      id: paramsList[1],
    };
    this.getStallConfigGroupById(keyCode2);
  }

  // 获取规则配置列表
  getStallConfigGroupById(value) {
    this.setState({
      loading: true,
    });
    this.props.getStallConfigGroupById(value).then(() => {
      this.setState({
        loading: false,
      });
    });
  }
  // 获取该id对应的档口的某条分组配置信息
  getCalcTypeConfigByStallId(value) {
    this.setState({
      loading: true,
    });
    this.props.getCalcTypeConfigByStallId(value).then(() => {
      this.setState({
        loading: false,
      });
    });
  }
  // 新增函数
  updateStallConfigGroup = (value) => {
    this.setState({
      loading: true,
    });
    this.props.addStallConfigGroup(value).then(() => {
      this.setState({
        loading: false,
      });
      this.getStallConfigGroupById({ id: this.state.id });
    });
  };
  // 删除函数
  removeCalcTypeConfigByStallIds(value) {
    this.setState({
      loading: true,
    });
    this.props.removeStallConfigGroup(value).then(() => {
      this.setState({
        loading: false,
      });
      this.getStallConfigGroupById({ id: this.state.id });
    });
  }
  // 新增函数
  updateStallConfigShowFlag = (value) => {
    this.setState({
      loading: true,
    });
    this.props.updateStallConfigShowFlag(value).then(() => {
      this.setState({
        loading: false,
      });
      this.getStallConfigGroupById({ id: this.state.id });
    });
  };
  // 点击新建按钮
  newStallCalcTypeConfig = () => {
    this.setState({
      show: true,
    });
  };
  // 点击modal框之外的遮罩层或者 x
  modalHide = () => {
    this.setState({
      show: false,
    });
  };
  // 点击modal的表单的提交事件
  saveOrUpdateConfigGroupEvent = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['id', 'calcTypeName', 'order'], (errors, values) => {
      if (!!errors) {
        return;
      }
      this.setState({
        show: false,
      });
      //
      let keyCode = {
        id: this.state.id,
        rules: [{ id: values.id, order: values.order, showFlag: true }],
      };
      this.updateStallConfigGroup(keyCode);
    });
  };
  // 点击table的删除事件
  removeCalcTypeConfigByStallId = (id) => {
    let keyCode = {
      id: this.state.id,
      roleId: id,
    };
    //
    this.removeCalcTypeConfigByStallIds(keyCode);
  };

  filterOptionValue = (input, option) => {
    return option.props.children.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  updateShowFlag = (record, showFlag) => {
    let keyCode = {
      id: this.state.id,
      roleId: record.id,
      rules: [{ id: record.id, order: record.order, showFlag: showFlag }],
    };
    this.updateStallConfigShowFlag(keyCode);
  };

  goBack() {
    this.props.history.goBack();
  }

  render() {
    // selected数据源
    let selectedSource = [];
    if (this.props.basic.stallCalcTypeConfigList.code === 200) {
      selectedSource = this.props.basic.stallCalcTypeConfigList.data;
    }
    let idOption = selectedSource.map((idSelected) => {
      return (
        <Option key={idSelected.id}>
          <Tooltip placement='top' title={idSelected.remark}>
            {idSelected.calcTypeName + '-' + idSelected.remark}{' '}
          </Tooltip>
        </Option>
      );
    });
    // table数据源
    const { code } = this.props.basic.getConfigOfStalledList;
    let tableSource = [];
    if (code === 200) {
      tableSource = this.props.basic.getConfigOfStalledList.data;
    }
    const { getFieldDecorator } = this.props.form;
    const dataSource = tableSource;
    const extraContent = (
      <div className='extraContent'>
        <Button type='danger' onClick={this.newStallCalcTypeConfig}>
          新建
        </Button>
        <Button style={{ margin: '0 12px 12px' }} onClick={(e) => this.goBack()}>
          {' '}
          返回
        </Button>
      </div>
    );
    return (
      <PageHeaderLayout>
        <div className='groupConfig'>
          <Card className='listCard' bordered={false} title='' extra={extraContent}>
            <Table dataSource={dataSource} bordered='none' pagination={false} rowKey='id'>
              <Column title='规则' dataIndex='id' key='id' />
              <Column title='规则名字' dataIndex='calcTypeName' key='calcTypeName' />
              <Column title='排序' dataIndex='order' key='order' />
              <Column
                title='是否显示'
                dataIndex='showFlag'
                key='showFlag'
                render={(_, record) => {
                  const { showFlag } = record;
                  let showDesc = '显示';
                  if (false === showFlag) {
                    showDesc = '不显示';
                  }
                  return showDesc;
                }}
              />
              <Column title='备注' dataIndex='remark' key='remark' />

              <Column
                title='操作'
                dataIndex='enable'
                key='enable'
                render={(_, record) => {
                  const { id, showFlag } = record;
                  let show = true;
                  let showDesc = '不显示';
                  if (false === showFlag) {
                    show = false;
                    showDesc = '显示';
                  }
                  return (
                    <Space>
                      <div
                        className='target'
                        onClick={() => {
                          this.updateShowFlag(record, !show);
                        }}
                      >
                        {showDesc}
                      </div>
                      <Edit data={record} {...this.props} refresh={() => this.getData()} />
                      <div
                        className='target'
                        onClick={() => {
                          this.removeCalcTypeConfigByStallId(id);
                        }}
                      >
                        删除
                      </div>
                    </Space>
                  );
                }}
              />
            </Table>
          </Card>
          <Card className='newConfigModalCard'>
            <Modal
              className='modal'
              title={this.state.modalTital}
              visible={this.state.show}
              onCancel={this.modalHide}
              destroyOnClose='true'
              width='800px'
              footer={[]}
            >
              {this.state.modalInfo.map((inputValue) => {
                return (
                  <Form onSubmit={this.saveOrUpdateConfigGroupEvent}>
                    <FormItem>
                      {getFieldDecorator('id', {
                        rules: [{ required: true, message: '请输入规则id' }],
                      })(
                        <Select
                          onChange={this.selectMarketEvent}
                          placeholder='请输入规则id'
                          allowClear
                          showSearch
                          filterOption={this.filterOptionValue}
                        >
                          {idOption}
                        </Select>,
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('order', {
                        rules: [{ required: true, message: '请输入序号' }],
                      })(
                        <InputNumber
                          style={{ width: '200px' }}
                          step={1}
                          min={1}
                          placeholder='请输入序号'
                        />,
                      )}
                      从小到大排序
                    </FormItem>
                    <div style={{ display: 'flex', height: '36px' }}>
                      <Button
                        style={{ float: 'right', marginRight: '5px', marginTop: '5px' }}
                        type='primary'
                        htmlType='submit'
                      >
                        {inputValue.text}
                      </Button>
                    </div>
                  </Form>
                );
              })}
            </Modal>
          </Card>
        </div>
      </PageHeaderLayout>
    );
  }
}
export default GroupConfig;
