import React, { PureComponent } from 'react';
import { Menu, Icon, Spin, Tag, Dropdown, Avatar, Divider, Tooltip } from 'antd';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import Debounce from 'lodash-decorators/debounce';
import { Link } from 'react-router-dom';
import HeaderSearch from '../header-search/index';
import './index.less';

import logoImg from '../../common/images/WechatIMG874.png';

export default class GlobalHeader extends PureComponent {
  componentDidMount() {
    document.body.classList.add('global-header-body');
  }
  componentWillUnmount() {
    document.body.classList.remove('global-header-body');
    this.triggerResizeEvent.cancel();
  }
  getNoticeData() {
    const { notices = [] } = this.props;
    if (notices.length === 0) {
      return {};
    }
    const newNotices = notices.map((notice) => {
      const newNotice = { ...notice };
      if (newNotice.datetime) {
        newNotice.datetime = moment(notice.datetime).fromNow();
      }
      // transform id to item key
      if (newNotice.id) {
        newNotice.key = newNotice.id;
      }
      if (newNotice.extra && newNotice.status) {
        const color = {
          todo: '',
          processing: 'blue',
          urgent: 'red',
          doing: 'gold',
        }[newNotice.status];
        newNotice.extra = (
          <Tag color={color} style={{ marginRight: 0 }}>
            {newNotice.extra}
          </Tag>
        );
      }
      return newNotice;
    });
    return groupBy(newNotices, 'type');
  }
  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    this.triggerResizeEvent();
  };
  /* eslint-disable*/
  @Debounce(600)
  triggerResizeEvent() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }

  render() {
    const { currentUser = {}, collapsed, isMobile, logo, onMenuClick } = this.props;
    const menu = (
      <Menu className={'menu'} selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item key='logout'>
          <Icon type='logout' />
          退出登录
        </Menu.Item>
      </Menu>
    );

    return (
      <div className='global-header-index'>
        <div className={'header'}>
          {isMobile && [
            <Link to='/' className={'logo'} key='logo'>
              <img src={logo} alt='logo' width='32' />
            </Link>,
            <Divider type='vertical' key='line' />,
          ]}
          <Icon
            className={'trigger'}
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={this.toggle}
          />
          <div className={'right'}>
            <HeaderSearch
              disable
              className={`${'action'} ${'search'}`}
              placeholder='站内搜索'
              dataSource={['搜索提示一', '搜索提示二', '搜索提示三']}
              onSearch={() => {}}
              onPressEnter={() => {}}
            />
            <Tooltip title='关于菜小秘'>
              <a
                target='_blank'
                href='http://www.caixm.cn/#/about'
                rel='noopener noreferrer'
                className={'action'}
              >
                <Icon type='question-circle-o' />
              </a>
            </Tooltip>
            {currentUser.name ? (
              <Dropdown overlay={menu}>
                <span className='action account'>
                  <Avatar size='small' className={'avatar'} src={logoImg} />
                  <span className={'name'}>{currentUser.name}</span>
                </span>
              </Dropdown>
            ) : (
              <Spin size='small' style={{ marginLeft: 8 }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
