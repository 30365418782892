/**
 * 编辑会员服务
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Form, Row, Col, Input, Modal, Button, Select } from 'antd';
import { PRODUCT_TYPE_LIST } from '../../../utils/deviceType';
import { updateMemberServices } from '../../../state/action/memberManager';
import { message } from '../../../../node_modules/antd/lib/index';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { memberManager: state.memberManager };
  },
  { updateMemberServices },
)
@Form.create()
class UpdateMemberServices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tipVisible: false,
      formData: {},
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        this.setState(
          {
            formData: value,
          },
          () => {
            this.showTipVisible(true);
          },
        );
      }
    });
  };

  showTipVisible = (visible) => {
    this.setState({
      tipVisible: visible,
    });
  };

  handleConfirm = () => {
    const { formData } = this.state;
    this.updateMemberServicesFun(formData);
  };

  async updateMemberServicesFun(value) {
    const { rows } = this.props;
    const result = await this.props.updateMemberServices({
      id: rows.id,
      productAmount: value.productAmount ? parseFloat(value.productAmount) : '',
      discountAmount: value.discountAmount ? parseFloat(value.discountAmount) : '',
      productDesc: value.productDesc || '',
    });
    if (result.code === 200) {
      message.success('编辑成功');
      this.showTipVisible(false);
      this.props.showMemberServices(false, {}, true);
    } else {
      message.error(result.msg);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, rows, showMemberServices } = this.props;
    const { tipVisible } = this.state;
    return (
      <div>
        <Modal
          title={'编辑'}
          visible={visible}
          onCancel={() => showMemberServices(false, {})}
          footer={null}
          className={'memberModalStyle'}
          width='600px'
          style={{ maxHeight: 700 }}
        >
          <Form layout='inline' onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='会员服务'>
                  {getFieldDecorator('productType', {
                    initialValue: rows.productType || '',
                  })(
                    <Select disabled>
                      {[...PRODUCT_TYPE_LIST].map(([k, v]) => {
                        return (
                          <Option key={k} value={k}>
                            {v}
                          </Option>
                        );
                      })}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='渠道类型'>
                  {getFieldDecorator('platformType', {
                    initialValue: rows.platformType || '',
                  })(
                    <Select disabled>
                      <Option value={1}>android</Option>
                      <Option value={2}>ios</Option>
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='合同类型'>
                  {getFieldDecorator('pactType', {
                    initialValue: rows.pactType || '',
                  })(
                    <Select disabled>
                      <Option value={1}>新签合同</Option>
                      <Option value={2}>续费合同</Option>
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='服务定价'>
                  {getFieldDecorator('productAmount', {
                    initialValue: rows.productAmount || '',
                    rules: [
                      {
                        required: true,
                        validator: (rule, value, callback) => {
                          if (value) {
                            if (value <= 0) {
                              callback('服务定价应大于0');
                            } else if (!/^[0-9]+(.?[0-9]{1,2})?$/.test(value)) {
                              callback('服务定价只能为数字且最多两位小数');
                            } else if (value >= 100000000) {
                              callback('金额数值不可大于99999999.99!');
                            }
                          } else {
                            callback('请输入服务定价');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入服务定价' allowClear />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='活动价格'>
                  {getFieldDecorator('discountAmount', {
                    initialValue: rows.discountAmount || '',
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value <= 0) {
                              callback('活动价格应大于0');
                            } else if (!/^[0-9]+(.?[0-9]{1,2})?$/.test(value)) {
                              callback('活动价格只能为数字且最多两位小数');
                            } else if (value >= 100000000) {
                              callback('金额数值不可大于99999999.99!');
                            }
                          } else {
                            callback('请输入活动价格');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入活动价格' allowClear />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label='当前活动'>
                  {getFieldDecorator('productDesc', {
                    initialValue: rows.productDesc || '',
                  })(<TextArea rows={4} placeholder='请输入当前活动' allowClear maxLength='100' />)}
                </FormItem>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => showMemberServices(false, {})}>关闭</Button>
              <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                保存
              </Button>
            </Row>
          </Form>
        </Modal>
        {tipVisible && (
          <Modal
            title={'提示'}
            visible={tipVisible}
            onCancel={() => this.showTipVisible(false)}
            footer={null}
            className={'memberModalStyle'}
            width='400px'
            style={{ marginTop: '5%' }}
          >
            <br />
            <Row style={{ textAlign: 'center' }}>确认修改活动吗</Row>
            <br />
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => this.showTipVisible(false, {})}>取消</Button>
              <Button type='primary' onClick={this.handleConfirm} style={{ marginLeft: '18px' }}>
                确认
              </Button>
            </Row>
          </Modal>
        )}
      </div>
    );
  }
}

export default UpdateMemberServices;
