/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-26 16:37:50
 * @LastEditors: lijing
 * @LastEditTime: 2022-05-26 17:43:13
 */

import * as actionTypes from '../constant/bank';
import * as req from '../request/bank';

//支付进件列表查询接口
export function fetchPaymentRecord(params) {
  return async (dispatch) => {
    const res = await req.fetchBankPayment(params);
    if (res.code === 200) {
      dispatch({ type: actionTypes.BANK_GET_LIST, payload: res.data });
    }
  };
}
