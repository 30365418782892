// 系统日志
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Col, Button, Table, Input, Row, DatePicker, Select, Tooltip, Tag } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getList } from '../../state/action/sysLog';
import { APPLICATION_NAME } from '../../utils/deviceType';
import './index.less';

const FormItem = Form.Item;

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@Form.create()
@connect((state) => state.sysLog, { getList })
class SysLog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      startDate: moment({ hour: 0, minute: 0, seconds: 0 }),
      endDate: moment(),
      pageNo: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    document.body.classList.add('sys-log-index-body');
  }

  componentWillUnmount() {
    document.body.classList.remove('sys-log-index-body');
  }

  // 获取table数据
  async getList(params) {
    this.setState({ loading: true });
    await this.props.getList(params);
    this.setState({ loading: false });
  }

  // 根据条件搜索合单配置列表
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['date', 'stallId', 'applicationName', 'classMethod', 'requestArgs'],
      (error, value) => {
        this.setState(
          {
            startDate: value.date[0],
            endDate: value.date[1],
            pageNo: 1,
          },
          () => {
            const { startDate, endDate, pageSize } = this.state;
            let params = {
              ...(startDate ? { startDate: startDate.unix() * 1000 } : {}),
              ...(endDate ? { endDate: endDate.unix() * 1000 } : {}),
              stallId: value.stallId,
              applicationName: value.applicationName,
              classMethod: value.classMethod,
              requestArgs: value.requestArgs,
              pageSize,
              pageNo: 1,
            };
            this.getList(params);
          },
        );
      },
    );
  };

  // 重置
  handleReset = () => {
    this.props.form.resetFields();
    const { pageSize } = this.state;
    this.setState(
      {
        startDate: moment({ hour: 0, minute: 0, seconds: 0 }),
        endDate: moment(),
        pageNo: 1,
      },
      () => {
        this.getList({
          startDate: moment({ hour: 0, minute: 0, seconds: 0 }).unix() * 1000,
          endDate: moment().unix() * 1000,
          pageSize,
          pageNo: 1,
        });
      },
    );
  };

  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    });
    this.props.form.validateFields(
      ['date', 'stallId', 'applicationName', 'classMethod', 'requestArgs'],
      (_, value) => {
        const { startDate, endDate, pageSize } = this.state;
        let params = {
          ...(startDate ? { startDate: startDate.unix() * 1000 } : {}),
          ...(endDate ? { endDate: endDate.unix() * 1000 } : {}),
          stallId: value.stallId,
          applicationName: value.applicationName,
          classMethod: value.classMethod,
          requestArgs: value.requestArgs,
          pageSize,
          pageNo: pageNumber.current,
        };
        this.getList(params);
      },
    );
  };

  disabledDate = (current) => {
    return current && current > moment();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      sysLogList,
      sysLogList: { pageList },
    } = this.props;
    var totalSize = 0;
    if (sysLogList === undefined) {
    } else {
      totalSize = sysLogList.totalSize;
    }
    const topColResponsiveProps = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
      xl: 6,
    };
    // 表格数据源
    const dataSource = pageList;
    const extraContent = (
      <div className='extraContent'>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col {...topColResponsiveProps} {...{ xl: 8 }}>
              <FormItem label='查询时间'>
                {getFieldDecorator('date', {
                  initialValue: [this.state.startDate, this.state.endDate],
                  rules: [{ required: false, message: '请选择时间!' }],
                })(
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format={dateFormat}
                    placeholder={['开始时间', '结束时间']}
                    onChange={this.timeChange}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  ></RangePicker>,
                )}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 4 }}>
              <FormItem label='商行ID'>
                {getFieldDecorator('stallId')(<Input placeholder='请输入商行ID' allowClear />)}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='应用名称'>
                {getFieldDecorator(
                  'applicationName',
                  {},
                )(
                  <Select placeholder='应用名称' allowClear>
                    {[...APPLICATION_NAME].map(([k, v]) => (
                      <Option key={k}>{v}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='请求方法名称'>
                {getFieldDecorator('classMethod')(<Input placeholder='请求方法名称' allowClear />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col {...topColResponsiveProps} {...{ xl: 6 }}>
              <FormItem label='请求参数'>
                {getFieldDecorator('requestArgs')(<Input placeholder='请求参数' allowClear />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
          </Row>
        </Form>
      </div>
    );
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
    };
    return (
      <div className='sys-index'>
        <PageHeaderLayout>
          <div className={'system-log'}>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.pageChange.bind(this)}
                scroll={{ y: 900 }}
              >
                <Col
                  align='center'
                  title='操作时间'
                  dataIndex='createTime'
                  key='createTime'
                  width={120}
                  render={(_, record) => {
                    const time = moment(record.createTime).format('YYYY-MM-DD HH:mm:ss');
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='接口耗时(毫秒)'
                  dataIndex='timeConsuming'
                  key='timeConsuming'
                  width={100}
                />
                <Col align='center' title='服务器IP' dataIndex='host' key='host' width={120} />
                <Col align='center' title='LogId' dataIndex='logId' key='logId' width={170} />
                <Col align='center' title='商行ID' dataIndex='stallId' key='stallId' width={100} />
                <Col
                  align='center'
                  title='日志类型'
                  dataIndex='logLevel'
                  key='logLevel'
                  width={100}
                  render={(text) => {
                    if (text === 2) {
                      return <Tag color='red'>错误</Tag>;
                    }
                    return <Tag color='green'>正常</Tag>;
                  }}
                />
                <Col
                  align='center'
                  title='应用名称'
                  dataIndex='applicationName'
                  key='applicationName'
                  width={110}
                  render={(text) => {
                    return text ? APPLICATION_NAME.get(text) : '';
                  }}
                />
                <Col
                  align='left'
                  title='请求方法名称'
                  dataIndex='classMethod'
                  key='classMethod'
                  width={200}
                />
                <Col
                  align='left'
                  title='请求参数'
                  dataIndex='requestArgs'
                  key='requestArgs'
                  width={200}
                  render={(text) => {
                    if (text.length >= 30) {
                      return (
                        <Tooltip placement='top' title={text}>
                          {text.substring(0, 30) + '...'}
                        </Tooltip>
                      );
                    }
                    return text;
                  }}
                />
                <Col
                  align='left'
                  title='版本号'
                  dataIndex='versionCode'
                  key='versionCode'
                  width={100}
                />
                <Col align='left' title='版本描述' dataIndex='version' key='version' width={140} />
              </Table>
            </Card>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default SysLog;
