import * as actionTypes from '../constant/org';

//初始状态
const initState = {
  loading: false,

  //机构列表
  allOrgDate: [],
  saveOrgDate: [],
  updateOrgDate: [],
  stallData: [],
  stallDelData: [],
};

//reducer
export function org(state = initState, action) {
  switch (action.type) {
    case actionTypes.ORG_SAVE:
      return { ...state, ...action.payload };
    case actionTypes.ORG_RESET_FIELDS:
      return { ...state, [action.payload]: {} };
    default:
      return state;
  }
}
