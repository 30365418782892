// 商品库管理-商品基础库
import React, { PureComponent } from 'react';
import PageHeaderLayout from '../../component/page-header-layout/index';
import {
  Form,
  Input,
  Button,
  Table,
  Row,
  Col,
  Modal,
  Upload,
  message,
  Popconfirm,
  Select,
} from 'antd';
import './goodsLibrary.less';
import {
  basicProductList,
  basicProductAdd,
  basicProductEdit,
  getAllCategory,
  basicProductDelete,
  editPriceRange,
} from '../../state/request/dataService';
import { API_ADMIN } from '../../utils/urls';
import { generalParamsWithPToStr } from '../../utils/math';
import { getAuthorityUserInfo } from '../../utils/authority';
import { isEmptyObject } from '../../utils/reg';
const FormItem = Form.Item;
const { Column } = Table;
const pageSize = 10;
@Form.create()
class GoodsLibrary extends PureComponent {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: {}, // 页面数据
      visibleModal: false, // 展示编辑modal
      visiblePrice: false, // 展示价格范围modal
      isEdit: false, // 是否是编辑状态
      editGoods: {}, // 编辑的货品
      pageNo: 1, // 当前请的页数
      loading: true, // 加载中
      allCategory: [], //所有分类
    };
    this.routeState = this.props.location.state || {};
  }

  componentDidMount() {
    // 读取缓存的路由参数
    const { pageNo = 1, searchName } = this.routeState;
    if (searchName) {
      this.props.form.setFieldsValue({
        productName: searchName,
      });
    }
    this.setState({ pageNo }, () => this.getData());
    this.getAllCategory();
  }

  /**
   * 获取数据源
   */
  getData = () => {
    this.setState({ loading: true });
    const { pageNo } = this.state;
    this.props.form.validateFields(['productName'], async (errors, values) => {
      if (!!errors) {
        return;
      }
      let param = { pageNo, pageSize };
      if (!!values.productName) {
        param.productName = values.productName;
      }
      // 请求货品列表
      const response = await basicProductList(param);
      const { code, data } = response;
      if (code === 200) {
        this.setState({ data, loading: false });
      }
    });
  };
  /**
   * 获取所有分类
   */
  getAllCategory = async () => {
    // 请求货品列表
    const response = await getAllCategory();
    const { code, data } = response;
    if (code === 200) {
      this.setState({ allCategory: data.pageList });
    }
  };

  /**
   * 开始搜索
   */
  handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ pageNo: 1 }, () => this.getData());
  };

  /**
   * 重置
   */
  handleReset = () => {
    this.props.form.resetFields();
    this.handleSearch();
  };

  /**
   * 新增货品
   */
  handleAddGoods = async () => {
    this.setState({ visibleModal: true, isEdit: false, editGoods: {} });
  };

  /**
   * 下载模版
   */
  handleDownloadTemplate = () => {
    window.open(
      `${API_ADMIN}basicProductResource/downloadTemplet?${generalParamsWithPToStr(
        'basicProductResource/downloadTemplet',
        {},
      )}`,
    );
  };

  /**
   * 切换页面
   * @param {*} obj
   */
  pageSwitch = (obj) => {
    this.setState({ pageNo: obj.current }, () => this.getData());
  };

  /**
   * 别名维护
   */
  aliasMaintenance = (record) => {
    this.props.form.validateFields(['productName'], async (errors, values) => {
      const { pageNo } = this.state;
      this.props.history.push({
        pathname: `/data-service/goodsLibrary/${record.id}`,
        state: { ...record, searchName: values.productName, pageNo },
      });
    });
  };

  /**
   * 别名编辑
   */
  aliasEditor = (e, record) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ visibleModal: true, isEdit: true, editGoods: record });
  };

  /**
   * 别名删除
   */
  aliasToDelete = async (record) => {
    // 请求货品列表
    const response = await basicProductDelete(record.id);
    const { code } = response;
    if (code === 200) {
      this.getData();
    }
  };

  /**
   * 创建弹框的Form
   */
  createDialogForm = () => {
    const { visibleModal, isEdit, allCategory = [], editGoods = {} } = this.state;
    let modalTitle = '新增货品';
    let productNameTitle = '货品名称';
    let productNameRuleMsg = '请输入货品名称!';
    let productNamePlaceholder = '请输入货品名称';
    let goodsCategoryTitle = '货品分类';
    let goodsCategoryRuleMsg = '请输入货品分类!';
    if (isEdit) {
      modalTitle = '编辑货品';
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const CreateDialogForm = Form.create()((props) => {
      const { form } = props;
      const submit = () => {
        form.validateFields(async (err, values) => {
          if (err) return;
          const { categoryName, productName } = values;
          // 请求货品列表
          if (isEdit) {
            // 编辑货品
            const response = await basicProductEdit({
              id: editGoods.id,
              categoryName,
              productName,
            });
            const { code } = response;
            if (code === 200) {
              this.setState({ visibleModal: false }, () => this.getData());
            }
          } else {
            // 新增货品
            const response = await basicProductAdd({ categoryName, productName });
            const { code } = response;
            if (code === 200) {
              this.setState({ visibleModal: false, pageNo: 1 }, () => this.getData());
            }
          }
        });
      };
      const cancel = () => {
        this.setState({ visibleModal: false });
      };
      const { getFieldDecorator } = form;
      return (
        <div style={{ width: '100%' }}>
          <FormItem {...formItemLayout} label={productNameTitle}>
            {getFieldDecorator('productName', {
              initialValue: editGoods.productName || '',
              rules: [
                {
                  required: true,
                  message: productNameRuleMsg,
                },
              ],
            })(<Input placeholder={productNamePlaceholder} />)}
          </FormItem>
          <FormItem {...formItemLayout} label={goodsCategoryTitle}>
            {getFieldDecorator('categoryName', {
              initialValue: editGoods.categoryName || '',
              rules: [
                {
                  required: true,
                  message: goodsCategoryRuleMsg,
                },
              ],
            })(
              <Select
                placeholder='请选择市场'
                style={{ minWidth: '100%' }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {allCategory.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem>
            <div style={{ textAlign: 'center' }}>
              <Button type='primary' htmlType='submit' onClick={submit}>
                保存
              </Button>
              <Button style={{ marginLeft: 24 }} onClick={cancel}>
                取消
              </Button>
            </div>
          </FormItem>
        </div>
      );
    });
    return (
      <Modal
        title={modalTitle}
        visible={visibleModal}
        footer={null}
        closable={false}
        destroyOnClose='true'
        width='600px'
        onCancel={() => {
          this.setState({ visibleModal: false });
        }}
      >
        <div style={{ width: '100%' }}>
          <CreateDialogForm />
        </div>
      </Modal>
    );
  };

  //上传之前拦截下
  beforeUpload = (file) => {
    const str = file.name.split('.')[1];
    const isRightType = str === 'xls' || str === 'xlsx';
    const limitSize = file.size / 1024 / 1024 < 1;
    if (!isRightType) {
      message.error('仅支持Excel文件!', 3);
      return false;
    }
    if (!limitSize) {
      message.error('文件大小不超过1M');
      return false;
    }

    return limitSize;
  };
  //上传之后的改变事件
  handleChangeByUpload = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
    }
    if (info.file.status === 'done') {
      this.setState({ loading: false });
      if (info.file.response.code === 200) {
        message.success(`${info.file.name} 上传成功!`, 5);
        // 刷新页面
        this.getData();
      } else if (info.file.response.code === 8009) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(info.file.response.msg, 5);
      }
    } else if (info.file.status === 'error') {
      if (info.file && info.file.response && info.file.response.msg) {
        message.error(info.file.response.msg, 5);
      } else {
        message.error(`${info.file.name} 上传失败`, 5);
      }
      this.setState({ loading: false });
    }
  };

  //显示价格的模态框
  showModalPrice = (e, record) => {
    e.preventDefault();
    this.setState({ visiblePrice: true, editGoods: record });
  };
  //隐藏价格的模态框
  handleCancelPrice = () => {
    this.setState({ visiblePrice: false, editGoods: {} });
  };
  //编辑价格范围
  onSubmitPrice = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['minPrice', 'maxPrice'], async (errors, values) => {
      if (!!errors) {
        return;
      }
      const { editGoods = {} } = this.state;
      const { minPrice, maxPrice } = values;
      if (Number(minPrice) > Number(maxPrice)) {
        message.warning('最高价格必须大于最低价格!');
        return;
      }
      // 编辑货品
      const response = await editPriceRange({ id: editGoods.id, minPrice, maxPrice });
      const { code } = response;
      if (code === 200) {
        this.setState({ visiblePrice: false, editGoods: {} }, () => this.getData());
      }
    });
  };
  /**
   * render
   * @returns
   */
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      data: { pageList, totalSize },
      loading,
      pageNo,
      visiblePrice,
      editGoods,
    } = this.state;
    const pagination = {
      current: pageNo,
      pageSize,
      total: totalSize,
    };

    let userinfo = getAuthorityUserInfo();
    let hash = !isEmptyObject(userinfo) ? userinfo.access_token : '';
    // 批量导入
    const bulkImport = {
      name: 'file',
      action: `${API_ADMIN}basicProductResource/importProduct?`,
      headers: {
        authorization: 'authorization-text',
        accessToken: hash,
        appType: 'admin',
      },
      beforeUpload: this.beforeUpload,
      showUploadList: false,
      onChange: this.handleChangeByUpload,
    };
    return (
      <div className='goods-library'>
        <PageHeaderLayout>
          <div className='content'>
            <div className='header'>
              <Form layout='inline' onSubmit={this.handleSearch}>
                <Row gutter={12}>
                  <Col span={6} style={{ textAlign: 'left' }}>
                    <FormItem label='货品名称:'>
                      {getFieldDecorator('productName', {
                        initialValue: '',
                        rules: [{ required: false, message: '请输入货品名称!' }],
                      })(<Input placeholder='请输入货品名称搜索' allowClear />)}
                    </FormItem>
                  </Col>
                  <Col span={18} style={{ textAlign: 'right' }}>
                    <Button type='primary' htmlType='submit'>
                      查询
                    </Button>
                    <Button style={{ marginLeft: '20px' }} onClick={this.handleReset}>
                      重置
                    </Button>
                    <Button style={{ marginLeft: '20px' }} onClick={this.handleAddGoods}>
                      新增货品
                    </Button>
                    <Button style={{ marginLeft: '20px' }} onClick={this.handleDownloadTemplate}>
                      模版下载
                    </Button>
                    <Upload {...bulkImport}>
                      <Button style={{ marginLeft: '20px' }}>批量导入</Button>
                    </Upload>
                  </Col>
                </Row>
              </Form>
            </div>
            <Table
              rowKey={(_, index) => index}
              dataSource={pageList || []}
              pagination={pagination}
              bordered
              onChange={this.pageSwitch}
              loading={loading}
            >
              <Column
                title='序号'
                dataIndex='index'
                key='index'
                render={(_, __, index) => {
                  return (pageNo - 1) * pageSize + index + 1;
                }}
              />
              <Column title='货品名称' dataIndex='productName' key='productName' />
              <Column
                title='货品分类'
                dataIndex='categoryName'
                key='categoryName'
                render={(text) => {
                  return text || '--';
                }}
              />
              <Column
                title='价格范围'
                dataIndex='categoryName'
                key='categoryName2'
                render={(_, record) => {
                  const { minPrice, maxPrice } = record;
                  return minPrice || maxPrice ? `${minPrice} ~ ${maxPrice}` : '--';
                }}
              />
              <Column
                className='operating'
                title='操作'
                dataIndex='operating'
                key='operating'
                render={(_, record) => {
                  return (
                    <div>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.aliasMaintenance(record);
                        }}
                      >
                        别名维护
                      </a>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.showModalPrice(e, record);
                        }}
                        style={{ marginLeft: 16 }}
                      >
                        价格范围
                      </a>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault();
                          this.aliasEditor(e, record);
                        }}
                        style={{ marginLeft: 16 }}
                      >
                        编辑
                      </a>
                      <Popconfirm
                        title='确定删除吗？'
                        onConfirm={(e) => this.aliasToDelete(record)}
                        okText='确认'
                        cancelText='取消'
                      >
                        <a href='/' className={`close`} style={{ marginLeft: 16 }}>
                          删除
                        </a>
                      </Popconfirm>
                    </div>
                  );
                }}
              />
            </Table>
            {this.createDialogForm()}

            <Modal
              key={editGoods.id || Math.floor(Math.random() * 100)}
              title='编辑价格范围'
              visible={visiblePrice}
              onCancel={this.handleCancelPrice}
              footer={null}
              width='600px'
              className='add-modal'
            >
              <Form
                onSubmit={this.onSubmitPrice}
                layout='inline'
                className='login-form'
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <FormItem label='最低价格'>
                  {getFieldDecorator('minPrice', {
                    initialValue: editGoods.minPrice || '',
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        pattern: /^-?\d+(\.\d{1,2})?$/,
                        message: '请输入正确的金额',
                      },
                    ],
                  })(<Input placeholder='请输入最低价格' allowClear />)}
                </FormItem>
                <FormItem label='最高价格'>
                  {getFieldDecorator('maxPrice', {
                    initialValue: editGoods.maxPrice || '',
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        pattern: /^-?\d+(\.\d{1,2})?$/,
                        message: '请输入正确的金额',
                      },
                    ],
                  })(<Input placeholder='请输入最高价格' allowClear />)}
                </FormItem>
                <FormItem label=''>
                  <div>最高价与最低价均为0，则价格范围不生效</div>
                </FormItem>
                <FormItem label='' wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type='primary' htmlType='submit'>
                    保存
                  </Button>
                  <Button onClick={this.handleCancelPrice} style={{ marginLeft: '18px' }}>
                    关闭
                  </Button>
                </FormItem>
              </Form>
            </Modal>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default GoodsLibrary;
