//商行管理--设备管理列表
import request from '../../utils/request';

// 获取设备类型
export async function getListDeviceModes(params) {
  return request({ url: 'stallDevice/listDeviceModes', params });
}

// 获取设备型号
export async function getListDeviceTypes(params) {
  return request({ url: 'stallDevice/listDeviceTypes', params });
}
//新增设备
export async function getAddDevice(params) {
  return request({ url: 'stallDevice/addDevice', params });
}
//编辑设备
export async function getUpdateDevice(params) {
  return request({ url: 'stallDevice/updateDevice', params });
}
//获取设备列表
export async function getListDevices(params) {
  return request({ url: 'stallDevice/listDevices', params });
}
// 启用/禁用
export async function getConnectPrinter(params) {
  return request({ url: 'stallDevice/ableDevice', params });
}

//商行管理--合同管理列表
export async function getListPacts(params) {
  return request({ url: 'stallPacts/listPacts', params });
}
//设备管理列表  -- 解绑/绑定
export async function getStallConnectPrinter(params) {
  return request({ url: 'stallDevice/stallConnectPrinter', params });
}
//绑定先查询设备id
export async function getDeviceIdByMachineCode(params) {
  return request({ url: 'stallDevice/getDeviceIdByMachineCode', params });
}
// 导出合同
export async function exportPacts(params) {
  return request({
    url: `stallPacts/exportPacts`,
    headers: 'json',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

// 设备升级
export async function upgradeDevice(params) {
  return request({ url: `stallDevice/upgrade`, headers: 'json', method: 'post', params });
}
