import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Table, Input, Select, Button, Space, Image, Popconfirm, message, Typography } from 'antd-v5';

import { getBusinessList, getBusinessEnable } from '../../state/request/serve';
import PageHeaderLayout from '../../component/page-header-layout/index';

import AddNewEditor from './components/AddNewEditor';
import ServeSort from './components/ServeSort';
import { typeEnum, status, layout, tailLayout } from './enum';

import './index.less';

const { Option } = Select;
const { Paragraph } = Typography;

const AddServices = (props) => {
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [query, setQuery] = useState({});
  const ref = useRef();
  const sortRef = useRef();

  const getData = useCallback(async () => {
    const res = await getBusinessList({ ...query });
    if (res.code === 200) {
      setList(res.data);
    }
  }, [query]);

  useEffect(() => {
    getData();
  }, [getData])

  // 提交
  const onFinish = (values) => {
    setQuery(values);
  }

  // 重置
  const onReset = () => {
    form.resetFields();
    setQuery({});
  }

  // 排序
  const onSort = () => {
    sortRef.current.open(list);
  }

  // 停用启用
  const onConfirm = async (record) => {
    const { enable, id } = record;
    await getBusinessEnable({ id, enable: !enable });
    const txt = enable ? '禁用' : '启用';
    message.info(`${txt}增值服务成功`);
    getData();
  }

  const columns = [
    {
      title: '增值服务名称',
      dataIndex: 'name',
      render: (_, { name, id }) => {
        return <div>{name}<Paragraph copyable>{`(服务ID: ${id})`}</Paragraph></div>
      }
    },
    {
      title: '功能说明',
      dataIndex: 'description',
      render: (txt) => <div style={{ width: 200 }}>{txt}</div>
    },
    {
      title: '功能图标',
      dataIndex: 'iconUrl',
      render: (url) => url && <Image width={100} src={url} />
    },
    {
      title: '详情页图片',
      dataIndex: 'extend',
      render: ({ detailUrl }) => detailUrl && <Image width={100} src={detailUrl} />
    },
    {
      title: '类型',
      dataIndex: 'typeEnum',
      render: (type) => typeEnum.find(item => item.key === type)?.label
    },
    {
      title: '状态',
      dataIndex: 'enable',
      render: (enable) => <span className={`dot ${enable ? 'green' : 'red'}`}>{status.find(item => !!item.value === enable)?.label}</span>
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_, record) => {
        const { enable, typeEnum, businessNo, name } = record;
        const txt = enable ? '禁用' : '启用';
        return (
          <Space wrap>
            <Button
              type='link'
              onClick={() => {
                ref.current.open(record);
              }}
            >
              修改
            </Button>
            <Popconfirm
              title={`是否要${txt}增值服务`}
              onConfirm={() => onConfirm(record)}
            >
              <span className='btn' style={enable ? { color: 'red' } : { color: 'green' }}>{txt}</span>
            </Popconfirm>
            {/** 只有赊欠签字可以查看已开通商行列表 */}
            <Button
              type='link'
              disabled={typeEnum === 'CONSULT'}
              onClick={() => props.history.push(`/booth/openStall/${businessNo}/${name}`)}
            >
              已开通商行
            </Button>
          </Space>
        )
      }
    },
  ]

  const ExtraContent = () => {
    return (
      <Form
        {...layout}
        form={form}
        layout='inline'
        onFinish={onFinish}
        className='header'
      >
        <Space wrap>
          <Form.Item
            label='增值服务'
            name='name'
          >
            <Input allowClear style={{ width: 180 }} placeholder='请输入增值服务名称' />
          </Form.Item>
          <Form.Item label='类型' name='typeEnum'>
            <Select style={{ width: 180 }} allowClear placeholder='请选择增值服务类型'>
              {typeEnum.map(item => (
                <Option key={item.value} value={item.key}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label='状态' name='enable' >
            <Select style={{ width: 180 }} allowClear placeholder='请选择状态'>
              {status.map(item => (
                <Option key={item.value} value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button htmlType='button' onClick={onReset}>
                重置
              </Button>
              <Button htmlType='button' onClick={onSort}>
                排序
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    )
  }

  return (
    <PageHeaderLayout>
      <div className='add-services'>
        <ExtraContent />
        <Table
          rowKey="businessNo"
          dataSource={list || []}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <AddNewEditor ref={ref} refresh={getData} />
        <ServeSort ref={sortRef} refresh={getData} />
      </div>
    </PageHeaderLayout>
  );
}

export default AddServices;