/*
 * @Description: 
 * @version: 
 * @Author: lijing
 * @Date: 2022-05-26 17:11:21
 * @LastEditors: lijing
 * @LastEditTime: 2022-05-26 17:22:25
 */
export const BANK_GET_LIST = 'BANK_GET_LIST';
export const BANK_GET_FILES = 'BANK_GET_FILES';

