import * as actionTypes from '../constant/commodity';
import * as req from '../request/commodity';

// 获取货品分类
export function getAllProducts(params) {
  return async (dispatch) => {
    const res = await req.getAllProducts(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { productsData: res.data } });
    }
  };
}

// 获取商品基库列表
export function getCommodityBaseList(params) {
  return async (dispatch) => {
    const res = await req.getCommodityBaseList(params);
    if (res) {
      dispatch({
        type: actionTypes.COMMODITY_SAVE,
        payload: { commodityBaseList: res, searchForm: params },
      });
    }
  };
}

// 商品基础库新增编辑
export function updateCommodity(params) {
  return async (dispatch) => {
    const res = await req.updateCommodity(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { updateCommodityData: res } });
    }
  };
}

// 商品基础库删除
export function deleteCommodity(params) {
  return async (dispatch) => {
    const res = await req.deleteCommodity(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { deleteCommodityData: res } });
    }
  };
}

// 获取所有未关联的别名
export function getUnrelatedAlias(params) {
  return async (dispatch) => {
    const res = await req.getUnrelatedAlias(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { unrelatedAliaList: res.data } });
    }
  };
}

// 货品已关联别名查询
export function getAllAlias(params) {
  return async (dispatch) => {
    const res = await req.getAllAlias(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { allAliasList: res.data } });
    }
  };
}

// 关联别名新增
export function insertAlias(params) {
  return async (dispatch) => {
    const res = await req.insertAlias(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { insertAliasData: res } });
    }
  };
}

// 已关联别名删除
export function deleteAlias(params) {
  return async (dispatch) => {
    const res = await req.deleteAlias(params);
    if (res) {
      return res;
    }
  };
}

// 未关联货品-列表
export function getUnrelatedList(params) {
  return async (dispatch) => {
    const res = await req.getUnrelatedList(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { unrelatedList: res.data } });
    }
  };
}

// 未关联货品显示隐藏
export function updateUnrelatedEnable(params) {
  return async (dispatch) => {
    const res = await req.updateUnrelatedEnable(params);
    if (res) {
      return res;
    }
  };
}

// 未关联货品来源查看
export function getUnrelatedSource(params) {
  return async (dispatch) => {
    const res = await req.getUnrelatedSource(params);
    if (res) {
      dispatch({ type: actionTypes.COMMODITY_SAVE, payload: { unrelatedSourceData: res } });
    }
  };
}

// 手动新增货品别名
export function insertAliasName(params) {
  return async (dispatch) => {
    const res = await req.insertAliasName(params);
    if (res) {
      return res;
    }
  };
}
