import React from 'react';
import './style.less';

export default ({ links, copyright, type = 'login' }) => {
  const isLogin = type === 'login';
  return (
    <div className='component-global-footer'>
      <div className={`${isLogin ? 'login' : 'page'}`}>
        {links && (
          <div className='links'>
            {links.map((link) => (
              <a
                key={link.key}
                target={link.blankTarget ? '_blank' : '_self'}
                href={link.href}
                style={{ color: isLogin ? '#fff' : '' }}
              >
                {link.title}
              </a>
            ))}
          </div>
        )}
        {copyright && (
          <div className='copyright' style={{ color: isLogin ? '#fff' : '' }}>
            {copyright}
          </div>
        )}
      </div>
    </div>
  );
};
