// 商行管理 -- 商行管理（查看详情--关联设备)

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, DatePicker } from 'antd';

import { Yuan } from '../../../../utils/math';
import './statistics.less';

import { statistical, allStatistical } from '../../../../state/action/stall';

const { RangePicker } = DatePicker;
const format1 = 'YYYY-MM-DD 00:00:01';
const format2 = 'YYYY-MM-DD 23:59:59';

@connect(
  (state) => {
    return { stall: state.stall };
  },
  { statistical, allStatistical },
)
class Statistics extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
    this.onChangeRangePicker = this.onChangeRangePicker.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData(type = 0, startTime, endTime) {
    const { stallId } = this.props;
    let params = {};
    if (type === -1) {
      //只时间查询
      params = {
        statisticalStartTime: startTime,
        statisticalEndTime: endTime,
      };
    } else {
      params = {
        statisticalUnit: type,
      };
    }
    if (stallId) {
      this.props.statistical({ ...params, stallId });
    } else {
      this.props.allStatistical(params);
    }
  }

  onClick1 = (e) => {
    this.setState({ selected: 0 });
    this.getData(0);
  };
  onClick2 = (e) => {
    this.setState({ selected: 1 });
    this.getData(1);
  };
  onClick3 = (e) => {
    this.setState({ selected: 2 });
    this.getData(2);
  };
  onClick4 = (e) => {
    this.setState({ selected: 3 });
    this.getData(3);
  };
  onClick5 = (e) => {
    this.setState({ selected: 4 });
    this.getData(4);
  };
  onChangeRangePicker(date) {
    if (date.length === 2) {
      this.getData(
        -1,
        Number(moment(date[0].format(format1)).valueOf()),
        Number(moment(date[1].format(format2)).valueOf()),
      );
    } else {
      this.getData(this.state.selected);
    }
  }

  render() {
    const {
      stall: { statistical = {}, allStatistical = {} },
      stallId,
    } = this.props;
    const { selected } = this.state;
    return (
      <div className='statistics'>
        <div className='search'>
          <Button
            type='link'
            onClick={this.onClick1}
            className={`${selected === 0 ? 'selected' : ''}`}
          >
            昨日
          </Button>
          <Button
            type='link'
            onClick={this.onClick2}
            className={`${selected === 1 ? 'selected' : ''}`}
          >
            本周
          </Button>
          <Button
            type='link'
            onClick={this.onClick3}
            className={`${selected === 2 ? 'selected' : ''}`}
          >
            本月
          </Button>
          <Button
            type='link'
            onClick={this.onClick4}
            className={`${selected === 3 ? 'selected' : ''}`}
          >
            当季
          </Button>
          <Button
            type='link'
            onClick={this.onClick5}
            className={`${selected === 4 ? 'selected' : ''}`}
          >
            今年
          </Button>
          <RangePicker onChange={this.onChangeRangePicker} />
        </div>
        <div className='data'>
          <div>
            <div>{!stallId ? '总' : ''}GMV</div>
            <div className='amount'>{Yuan(stallId ? statistical.gmv : allStatistical.gmv, 0)}</div>
          </div>
          <div>
            <div>{!stallId ? '总' : ''}单据量</div>
            <div className='amount'>
              {Yuan(stallId ? statistical.billsTotalCount : allStatistical.billsTotalCount, 0)}
            </div>
          </div>
          {!stallId ? (
            <div>
              <div>活跃用户数</div>
              <div className='amount'>{Yuan(allStatistical.activeUserNum, 0)}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Statistics;
