//会员管理--详情--新增续费Modal
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Modal, Row, Col, Input, Select } from 'antd';

import { Plat_Form_TYPE, PRODUCT_TYPE_LIST } from '../../../utils/deviceType';
import { isEmptyObject } from '../../../utils/reg';

import {
  listProducts,
  selectedProduct,
  addVipPay,
  listVipPay,
} from '../../../state/action/memberManager';
import '../index.less';
import { message } from '../../../../node_modules/antd/lib/index';
const FormItem = Form.Item;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

@connect(
  (state) => {
    return { memberManager: state.memberManager };
  },
  { listProducts, selectedProduct, addVipPay, listVipPay },
)
@Form.create()
class AddOrderModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderVisible: false,
      okVisible: false,
      stallId: '',
      allProductsList: [],
      submitList: {},
      productIdNew: '',
      platformType: '',
      platformTypeId: '',
    };
  }
  componentDidMount() {
    const { stallDetails } = this.props;
    this.setState({ stallId: stallDetails.id });
    this.listProducts({}); //获取下拉会员服务列表
    this.props.memberManager.selectedProduct = {};
  }

  //获取会员服务列表
  async listProducts(params) {
    await this.props.listProducts(params);
    const {
      memberManager: { productsList = [] },
    } = this.props;
    const allProductsList = productsList && productsList.filter((item) => item.enable === true);
    this.setState({ allProductsList }); //默认获取所有的会员服务项
  }

  handleCancel = (e) => {
    this.setState({ orderVisible: false });
    this.props.memberManager.selectedProduct = {};
    this.listProducts({}); //获取下拉会员服务列表
  };
  //新增按钮
  writeDetail = () => {
    this.setState({ orderVisible: true });
    const { platformType, productIdNew } = this.state;
    if (!platformType) {
      this.listProducts({}); //获取下拉会员服务列表
    }
    if (!productIdNew) {
      this.props.form.setFieldsValue({
        productId: '',
        productAmount: '',
        productDuration: '',
        startTime: '',
        endTime: '',
      }); //先清空
    }
  };
  //表单保存
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, values) => {
      if (!!errors) {
        return;
      }
      this.setState({
        submitList: { ...values },
        orderVisible: true,
        okVisible: true,
      });
    });
  };

  //确定
  handleConfirm = () => {
    const { submitList } = this.state;
    const { stallDetails } = this.props;
    const { productId, productAmount, productDuration, startTime, endTime, money, remark } =
      submitList;
    const timeEnd = moment(endTime).valueOf();
    const timeStart = moment(startTime).valueOf();
    this.props
      .addVipPay({
        stallId: stallDetails.id,
        productId: productId,
        productAmount: productAmount,
        productDuration: productDuration,
        startTime: timeStart,
        endTime: timeEnd,
        orderAmount: money,
        remark: remark,
      })
      .then(() => {
        const {
          memberManager: { addVipPayMsg = {} },
        } = this.props;
        if (addVipPayMsg.code === 200) {
          message.success(addVipPayMsg.msg);
          this.setState({
            orderVisible: false,
            okVisible: false,
          });
          this.props.refreshOrderDetail(stallDetails.id);
        } else {
          message.error(addVipPayMsg.msg);
        }
      });
  };
  //取消
  handleCancelConfirm = () => {
    this.setState({ okVisible: false });
  };
  //会员渠道下拉选择
  handlePlatformType = async (value) => {
    //根据选中的类型以及enable=1去过滤结果
    await this.props.listProducts({});
    const {
      memberManager: { productsList = [] },
    } = this.props;
    const allProductsList =
      productsList &&
      productsList.filter((item) => item.enable === true && item.platformType === value);
    this.props.form.setFieldsValue({ productId: '' }); //先清空 productAmount:'',productDuration:'',startTime:'',endTime:''
    this.props.memberManager.selectedProduct = {};
    this.setState({ allProductsList, platformType: value });
  };
  //会员服务下拉
  handleProduct = async (value) => {
    const { stallDetails } = this.props;
    await this.props.selectedProduct({
      stallId: stallDetails.id,
      productId: value,
    });
    const {
      memberManager: { productsList = [] },
    } = this.props;
    const allProductsList = productsList && productsList.filter((item) => item.id === value);
    this.setState({
      productIdNew: PRODUCT_TYPE_LIST.get(Number(allProductsList[0].productType)) || '',
      platformTypeId: value,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { orderVisible, okVisible, allProductsList, submitList, productIdNew } = this.state;
    const {
      memberManager: { selectedProduct = {} },
      stallDetails = {},
    } = this.props;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    const formItemLayout1 = {
      labelCol: { span: 3 },
      wrapperCol: { span: 19 },
    };
    const timeEnd = !isEmptyObject(selectedProduct) ? selectedProduct.endTime : '';
    return (
      <div>
        <Button type='primary' onClick={this.writeDetail}>
          新增续费
        </Button>
        {orderVisible && (
          <Modal
            title={`新增会员续费`}
            visible={orderVisible}
            onCancel={this.handleCancel}
            footer={null}
            width='800px'
            className='addMerModal'
          >
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='市场名称'>
                    {getFieldDecorator('marketName', {
                      initialValue: stallDetails.marketName || '',
                    })(<Input disabled />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='商行名称'>
                    {getFieldDecorator('name', {
                      initialValue: stallDetails.name || '',
                    })(<Input disabled />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='渠道类型'>
                    {getFieldDecorator('deviceTypeDesc', {
                      initialValue: '',
                      rules: [{ required: true, message: ' 请选择渠道类型!' }],
                    })(
                      <Select
                        placeholder='请选择渠道类型'
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={this.handlePlatformType}
                      >
                        {[...Plat_Form_TYPE].map(([k, v]) => (
                          <Select.Option value={k} label={v} key={k}>
                            {' '}
                            {v}{' '}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='会员服务'>
                    {getFieldDecorator('productId', {
                      initialValue: '',
                      rules: [{ required: true, message: ' 请选择会员服务!' }],
                    })(
                      <Select
                        placeholder='请选择会员服务'
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={this.handleProduct}
                      >
                        {allProductsList &&
                          allProductsList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.productDesc}
                            </Select.Option>
                          ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='产品原价'>
                    {getFieldDecorator('productAmount', {
                      initialValue: selectedProduct.productAmount || '',
                    })(<Input disabled />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='购买时长'>
                    {getFieldDecorator('productDuration', {
                      initialValue: selectedProduct.productDuration || '',
                    })(<Input disabled />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='生效日期'>
                    {getFieldDecorator('startTime', {
                      initialValue: selectedProduct.startTime
                        ? moment(selectedProduct.startTime).format(dateFormat)
                        : '',
                    })(<Input disabled />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label='截止日期'>
                    {getFieldDecorator('endTime', {
                      initialValue: timeEnd ? moment(timeEnd).format(dateFormat) : '',
                    })(<Input disabled />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <FormItem {...formItemLayout1} label='支付金额'>
                    {getFieldDecorator('money', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          validator: (_, value, callback) => {
                            if (value) {
                              if (value <= 0) {
                                callback('支付金额必须大于0');
                              } else if (!/^-?\d+(\.\d{1,2})?$/.test(value)) {
                                callback('支付金额必须为数字且至多两位小数');
                              } else if (value > 999999.99) {
                                callback('支付金额不可大于100万');
                              }
                            } else {
                              callback('请输入支付金额');
                            }
                            callback();
                          },
                        },
                      ],
                    })(<Input placeholder='请输入支付金额' allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <FormItem {...formItemLayout1} label='备注'>
                    {getFieldDecorator('remark', {
                      initialValue: '',
                    })(<Input placeholder='请输入备注' allowClear maxLength={50} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row style={{ textAlign: 'center' }}>
                <Button onClick={() => this.handleCancel()}>关闭</Button>
                <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        )}
        {okVisible && (
          <Modal
            title={`请核对无误后操作`}
            visible={okVisible}
            onCancel={this.handleCancelConfirm}
            footer={null}
            width='560px'
            className='okModal'
          >
            <div className='content'>
              <Row gutter={16}>
                {' '}
                商行名称: <span>{stallDetails.name}</span>
              </Row>
              <Row gutter={16}>
                {' '}
                会员服务：<span>{productIdNew}</span>
              </Row>
              <Row gutter={16}>
                {' '}
                有效期至：
                <span>
                  {moment(timeEnd).format(dateFormat)}(剩余
                  <span style={{ color: '#1D9E60' }}>{selectedProduct.remainderDays}</span>天)
                </span>
              </Row>
              <Row gutter={16}>
                {' '}
                支付金额: <span>{submitList.money}</span>
              </Row>
            </div>
            <div className='btn'>
              <Button onClick={() => this.handleCancelConfirm()}>取消</Button>
              <Button
                type='primary'
                htmlType='submit'
                style={{ marginLeft: '18px' }}
                onClick={() => this.handleConfirm()}
              >
                确定
              </Button>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default AddOrderModal;
