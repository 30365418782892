import React, { PureComponent } from 'react';
import { Form, Button, Modal, Row, Col, Radio, message } from 'antd';
import { STALL_SALE_TYPE } from '../../../../utils/deviceType';
import './device.less';
import './converSaleMode.less';
import { converSaleMode } from '../../../../state/request/stall';

const { confirm } = Modal;
const FormItem = Form.Item;
const warnDescMixed = {
  content: (
    <div>
      <p>请认真核对以下前置条件是否完成：</p>
      <p></p>
      <p>1. 用户需升级到【v6.7.0】及以上版本</p>
      <p>2. 需保证码单中心为空</p>
      <p>3. 需保证商行各员工当班班次没有数据，建议交账后切换</p>
    </div>
  ),
};
const warnDescDM = {
  content: (
    <div>
      <p>请认真核对以下前置条件是否完成：</p>
      <p></p>
      <p>1. 用户需升级到【v6.7.0】及以上版本</p>
      <p>2. 需保证码单中心为空</p>
      <p>3. 需保证商行各员工当班班次没有数据，建议交账后切换</p>
      <p>注意事项：</p>
      <p></p>
      <p>切换后，将无法查询之前创建的代卖批次及代卖销售统计、结算统计。</p>
    </div>
  ),
};
const warnDeszZY = {
  content: (
    <div>
      <p>请认真核对以下前置条件是否完成：</p>
      <p></p>
      <p>1. 用户需升级到【v6.7.0】及以上版本</p>
      <p>2. 需保证码单中心为空</p>
      <p>3. 需保证商行各员工当班班次没有数据，建议交账后切换</p>
      <p>注意事项：</p>
      <p></p>
      <p>切换后，将无法查询之前创建的按货品管理自营的入库数据、库存数据、利润统计等。</p>
    </div>
  ),
};
const converSaleTypeInfo = {
  '1-2': {
    content: (
      <div>
        <p>请认真核对以下前置条件是否完成：</p>
        <p></p>
        <p>1. 用户需升级到【v5.0.4】及以上版本</p>
        <p>2. 需保证码单中心为空</p>
        <p>3. 需保证商行各员工当班班次没有数据，建议交账后切换</p>
        <p>4. 切换成功后系统会自动初始化用户商品库</p>
      </div>
    ),
  },
  '2-1': {
    content: (
      <div>
        <p>请认真核对以下前置条件是否完成：</p>
        <p></p>
        <p>1.用户需升级至【v5.0.6】及以上版本</p>
        <p></p>
        <p>2.需保证码单中心为空</p>
        <p>3.需要保证商行各员工当班批次没有数据，建议交账后切换</p>
        <p>注意事项：</p>
        <p></p>
        <p>切换后，将无法查询之前创建的自营货品库存及入库记录，建议用户拍照保存。</p>
      </div>
    ),
  },

  '1-3': {
    content: (
      <div>
        <p>请认真核对以下前置条件是否完成：</p>
        <p></p>
        <p>1. 用户需升级到【v6.0.0】及以上版本</p>
        <p>2. 需保证码单中心为空</p>
        <p>3. 需保证商行各员工当班班次没有数据，需要交账后切换</p>
      </div>
    ),
  },
  '2-3': {
    content: (
      <div>
        <p>请认真核对以下前置条件是否完成：</p>
        <p></p>
        <p>1. 用户需升级到【v6.0.0】及以上版本</p>
        <p>2. 需保证码单中心为空</p>
        <p>3. 需保证商行各员工当班班次没有数据，需要交账后切换</p>
      </div>
    ),
  },
  '3-1': {
    content: (
      <div>
        <p>请认真核对以下前置条件是否完成：</p>
        <p></p>
        <p>1. 用户需升级到【v6.0.0】及以上版本</p>
        <p>2. 需保证码单中心为空</p>
        <p>3. 需保证商行各员工当班班次没有数据，建议交账后切换</p>
        <p>注意事项：</p>
        <p></p>
        <p>切换后，将无法查询之前创建的自营货品库存及入库记录。</p>
      </div>
    ),
  },
  '3-2': {
    content: (
      <div>
        <p>请认真核对以下前置条件是否完成：</p>
        <p></p>
        <p>1. 用户需升级到【v6.0.0】及以上版本</p>
        <p>2. 需保证码单中心为空</p>
        <p>3. 需保证商行各员工当班班次没有数据，建议交账后切换</p>
        <p>注意事项：</p>
        <p></p>
        <p>切换后，将无法查询之前创建的代卖批次及代卖销售统计、结算统计。</p>
      </div>
    ),
  },
  '1-4': warnDescMixed,
  '2-4': warnDeszZY,
  '3-4': warnDeszZY,
  '5-4': warnDescMixed,
  '4-1': warnDescMixed,
  '4-2': warnDescDM,
  '4-3': warnDescMixed,
  '4-5': warnDescMixed,
  '1-5': warnDescMixed,
  '2-5': warnDeszZY,
  '3-5': warnDeszZY,
  '5-1': warnDescMixed,
  '5-2': warnDescDM,
  '5-3': warnDescMixed,
};

@Form.create()
class ConverSaleMode extends PureComponent {
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (this.props.convertSaleModeVisible !== nextProps.convertSaleModeVisible) {
      this.props.form.setFieldsValue({ saleType: nextProps.saleType });
    }
  }

  //基础版转专业版
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(['saleType'], (errors, values) => {
      if (!!errors) {
        return;
      }
      const { id, saleType, handleCancelForConvertSaleMode, handleDetail } = this.props;
      if (+saleType === +values.saleType) {
        //一样的就不需要做任何操作
        handleCancelForConvertSaleMode();
        return;
      } else {
        const title = `${STALL_SALE_TYPE.get(saleType)}切换为${STALL_SALE_TYPE.get(values.saleType)}`;
        const info = converSaleTypeInfo[saleType + '-' + values.saleType];

        handleCancelForConvertSaleMode();
        confirm({
          title: title,
          content: info.content,
          okText: '确定切换',
          async onOk() {
            const params = { saleType: values.saleType, stallId: id };
            let res = await converSaleMode(params);
            const { code } = res;
            if (code === 200) {
              message.success('切换成功');
              //刷新数据
              handleDetail();
            }
          },
          onCancel() {
            handleCancelForConvertSaleMode();
          },
        });
      }
    });
  };

  render() {
    const { saleType, convertSaleModeVisible, handleCancelForConvertSaleMode } = this.props;

    const { getFieldDecorator } = this.props.form;
    return (
      <div className='device'>
        <Modal title={`转换管理模式`} visible={convertSaleModeVisible} onCancel={handleCancelForConvertSaleMode} footer={null} width='600px'>
          <Form onSubmit={this.handleSubmit} labelAlign='left'>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem label='请更换管理模式'>
                  {getFieldDecorator('saleType', {
                    initialValue: saleType,
                  })(
                    <Radio.Group>
                      {[...STALL_SALE_TYPE].map(([k, v]) => (
                        <Radio style={{ display: 'block' }} value={k} key={k}>
                          {v} {k === 4 || k === 5 ? <span style={{ color: 'red' }}>（新增）</span> : null}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                </FormItem>
              </Col>
            </Row>

            <FormItem wrapperCol={{ span: 16 }}>
              <Button type='primary' htmlType='submit'>
                保存
              </Button>
              <Button onClick={handleCancelForConvertSaleMode} style={{ marginLeft: '18px' }}>
                关闭
              </Button>
            </FormItem>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ConverSaleMode;
