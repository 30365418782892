/**
 * 设备管理（新）
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Table, Row, Col, message, Divider, Checkbox, Icon } from 'antd';
import { Popconfirm } from 'antd-v5';
import moment from 'moment';
import PageHeaderLayout from '../../component/page-header-layout/index';

import AddModal from './salesManagement/add';
import { exportFile } from '../../utils/utils';

import { querySaleList, updateSaleEnable, getSaleDownload } from '../../state/action/salesman';

import './salesManagement.less';

const FormItem = Form.Item;
@connect(
  (state) => {
    return { salesman: state.salesman };
  },
  { querySaleList, updateSaleEnable },
)
@Form.create()
class SalesManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      store: {},
    };
  }
  componentDidMount() {
    this.handleQuerySaleList(); //获取列表信息
  }

  // 销售人员列表
  handleQuerySaleList = async (params = {}) => {
    this.setState({ loading: true });
    const search = this.props.form.getFieldsValue();

    await this.props.querySaleList({ ...search, ...params });
    this.setState({ loading: false });
  };

  //启用/禁用
  async updateSaleEnable(id, enable, personId) {
    this.setState({ loading: true });
    await this.props.updateSaleEnable({ id, enable, personId });

    const { updateSaleEnable } = this.props.salesman;
    if (updateSaleEnable && updateSaleEnable.code && updateSaleEnable.code === 200) {
      message.info(`${enable === 1 ? '启用' : '禁用'}成功！`);
      await this.props.querySaleList();
    } else {
      message.warning(updateSaleEnable.msg);
    }
    this.setState({ loading: false });
  }

  //查询
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['name', 'firstDepartment', 'pioneerDivision', 'enable'],
      (errors, values) => {
        if (!!errors) {
          return;
        }

        //全选或者全不选 则不传值给后端
        const enableCur =
          values.enable && values.enable.length > 1
            ? undefined
            : values.enable && values.enable.length === 1
            ? Number(values.enable[0])
            : undefined;
        this.handleQuerySaleList({ ...values, enable: enableCur });
      },
    );
  };

  handleCancel = (e) => {
    this.setState({ showModal: false });
  };

  //重置
  handleReset = () => {
    this.props.form.resetFields();
    this.handleQuerySaleList();
  };

  //编辑
  showModalByEdit = async (data = {}) => {
    this.setState({ showModal: true, store: data });
  };

  // 导出
  exportData = async () => {
    const values = this.props.form.getFieldsValue();
    const enableCur =
      values.enable && values.enable.length > 1
        ? null
        : values.enable && values.enable.length === 1
        ? Number(values.enable[0])
        : null;
    const params = { ...values, enable: enableCur };
    const response = await getSaleDownload({ ...params });
    exportFile(response, '销售人员.xlsx');
  };

  render() {
    const {
      salesman: { querySaleList = [] },
      form: { getFieldDecorator },
    } = this.props;
    const { showModal, store = {} } = this.state;

    const columns = [
      {
        title: '人员ID',
        dataIndex: 'id',
        width: 90,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '姓名',
        dataIndex: 'name',
        width: 120,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '业务线',
        dataIndex: 'firstDepartment',
        width: 120,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '所属部门',
        dataIndex: 'pioneerDivision',
        width: 120,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '是否启用',
        dataIndex: 'enable',
        width: 100,
        //0-禁用 其他均为启用
        render: (text) => {
          return text === false ? (
            <div>
              <Button
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5, background: '#ccc' }}
              />
              禁用
            </div>
          ) : (
            <div>
              <Button
                type='primary'
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5 }}
              />
              启用
            </div>
          );
        },
      },
      {
        title: '操作人',
        dataIndex: 'operatorName',
        width: 120,
        render: (text) => {
          return text || '--';
        },
      },
      {
        title: '操作时间',
        dataIndex: 'operatorTime',
        width: 120,
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD HH:mm') : '--';
        },
      },
      {
        title: '操作',
        key: 'tags',
        width: 120,
        render: (_, record) => {
          const { id, enable, personId } = record;
          return (
            <div>
              <span
                style={{ color: '#1D9E60', cursor: 'pointer' }}
                onClick={() => {
                  this.showModalByEdit(record);
                }}
              >
                编辑
              </span>
              <Divider type='vertical' />
              <Popconfirm
                title={`确定${enable ? '禁用' : '启用'}该销售人员吗？`}
                onConfirm={() => {
                  this.updateSaleEnable(id, enable ? 0 : 1, personId);
                }}
              >
                <span style={{ color: enable ? '#1D9E60' : '#EC634A', cursor: 'pointer' }}>
                  {enable ? '禁用' : '启用'}
                </span>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    const layout = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 };

    return (
      <div className='sales-management'>
        <PageHeaderLayout>
          <div>
            <div className='searchModule' style={{ padding: 5 }}>
              <Form onSubmit={this.handleSearch} className='ant-advanced-search-form'>
                <Row gutter={24}>
                  <Col {...layout}>
                    <FormItem label='姓名'>
                      {getFieldDecorator('name', {
                        rules: [{ required: false, message: '请输入姓名!' }],
                      })(<Input placeholder='请输入姓名' allowClear maxLength={10} />)}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='业务线'>
                      {getFieldDecorator('firstDepartment', {
                        rules: [
                          {
                            required: false,
                            message: '请输入业务线!',
                          },
                        ],
                      })(<Input placeholder='请输入业务线' allowClear maxLength={10} />)}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='所属部门'>
                      {getFieldDecorator('pioneerDivision', {
                        rules: [{ required: false, message: '请输入所属部门!' }],
                      })(<Input placeholder='请输入所属部门' allowClear maxLength={10} />)}
                    </FormItem>
                  </Col>
                  <Col {...layout}>
                    <FormItem label='是否启用'>
                      {getFieldDecorator('enable', {
                        rules: [{ required: false, message: '' }],
                      })(
                        //是否启用 0-禁用 1-启用
                        <Checkbox.Group style={{ width: '100%', paddingBottom: '30px' }}>
                          <Checkbox value='1'>启用</Checkbox>
                          <Checkbox value='0'>禁用</Checkbox>
                        </Checkbox.Group>,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Button type='primary' htmlType='submit'>
                    查询
                  </Button>
                  <Button onClick={this.handleReset} style={{ marginLeft: '18px' }}>
                    重置
                  </Button>
                  <Button
                    onClick={() => this.showModalByEdit()}
                    type='primary'
                    style={{ marginLeft: '18px' }}
                  >
                    <Icon type='plus' />
                    新增
                  </Button>
                  <Button style={{ marginLeft: '18px' }} onClick={this.exportData}>
                    导出
                  </Button>
                </Row>
              </Form>
            </div>
            <div>
              <Table
                rowKey='id'
                dataSource={querySaleList}
                columns={columns}
                pagination={false}
                loading={this.state.loading}
                scroll={{ x: 'max-content' }}
              />
            </div>
            {showModal ? (
              <AddModal
                data={store}
                open={showModal}
                onCancel={this.handleCancel}
                onSubmit={() => {
                  this.setState({
                    store: {},
                    showModal: false,
                  });
                  this.handleQuerySaleList();
                }}
              />
            ) : null}
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default SalesManagement;
