import React, { useEffect } from 'react';
import { Space, InputNumber, Modal, Form, Button, message } from 'antd-v5';
import { useImmer } from 'use-immer';

import { updateStallConfigShowFlag } from '../../state/request/basic';

import './style.less';

const Edit = (props) => {
  const [store, setStore] = useImmer({
    open: false,
    data: null,
  });
  const [form] = Form.useForm();
  const paramsList = props.location.state.data.split("&&");

  useEffect(() => {
    if (store.open) {
      form.setFieldsValue({
        order: props.data?.order,
      })
    }
  }, [store.open]);

  const onCancel = () => {
    setStore({
      open: false,
      data: {}
    })
  }

  const onFinish = async (values) => {
    const { data } = props;
    const params = {
      id: paramsList[1],
      roleId: data.id,
      rules: [{
        id: data.id,
        order: values.order,
        showFlag: data.showFlag
      }]
    }
    const { code } = await updateStallConfigShowFlag(params);
    if (code === 200) {
      message.info('修改排序成功');
      setStore({
        open: false,
        data: {},
      });
      props.refresh && props.refresh();
    }
  }

  return (
    <>
      <div className='target' onClick={() => setStore({ open: true, data: props.data || {} })}>编辑</div>
      {store.open && (
        <Modal title='编辑' open={store.open} onCancel={onCancel} destroyOnClose footer={null}>
          <Form
            form={form}
            preserve={false}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Space>
              <Form.Item
                name='order'
                rules={[
                  {
                    required: true,
                    message: '请输入序号',
                  },
                ]}
              >
                <InputNumber style={{ width: 200 }} min={1} precision={0} placeholder="请输入序号" />
              </Form.Item>
              <div>从小到大排序</div>
            </Space>
            <Form.Item>
              <Button type='primary' htmlType='submit' style={{ marginTop: 24 }}>
                保存
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}

export default Edit;