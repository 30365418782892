import * as actionTypes from '../constant/appVersion';

//初始状态
const initState = {
  loading: false,
  appListData: [],
  saveOrUpdateVerData: {},
  appVerByIdData: [],
  pushWarningData: {},
  pushSyncData: {},
  getVersionPushListData: [],
  addVersionPushData: {},
  deleteVersionPushData: {},
  resetVersionPushStatusData: {},
  grayReleasedTradingCompanyList: [],
  addStallResult: {},
  deleteStallResult: {},
};

//reducer
export function appVersion(state = initState, action) {
  switch (action.type) {
    case actionTypes.APP_VERSION_SAVE:
      return { ...state, ...action.payload };
    case actionTypes.APP_VERSION_CLEAR:
      return initState;
    default:
      return state;
  }
}
