let user = {};

export function InitUser(u) {
  if (u) {
    localStorage.setItem('cxm-user', JSON.stringify(u));
    user = u;
  }

  if (user.menus) {
    const { menus } = user;
    const permissions = {};
    for (let i = 0; i < menus.length; i++) {
      permissions[menus[i].code] = true;
    }
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }
}

export function expire() {
  user = {};
  const usr = localStorage.getItem('cxm-user');
  if (usr) {
    localStorage.removeItem('cxm-user');
  }

  const permissions = localStorage.getItem('permissions');
  if (permissions) {
    localStorage.removeItem('permissions');
  }
}

export function getUser() {
  return user;
}

/**
 *
 */
export function hasPerm(code) {
  const permissions = localStorage.getItem('permissions');
  if (null != permissions) {
    return JSON.parse(permissions)[code] === true;
  } else {
    return false;
  }
}
