import * as actionTypes from '../constant/onlineStore';

//初始状态
const initState = {
  storeList: [],
  categoriesList: [],
  productList: [],
};

//reducer
export function onlineStore(state = initState, action) {
  switch (action.type) {
    case actionTypes.ONLINE_STORE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
