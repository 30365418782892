import * as actionTypes from '../constant/batch';

//初始状态
const initState = {
  batchContrlList: [],
  batchDataList: [],
  batchSalesList: [],
  batchSettleList: [],
  loading: false,

  trainStatusData: [],
  carSettlementExportData: [],
  carSettlementData: [],
  fileSelectListData: [],

  fileStallData: [],

  stallStatisticsList: [],
  WhiteListByPage: [],
  addWhiteList: [],
  deleteWhiteList: [],
};

//reducer
export function batch(state = initState, action) {
  switch (action.type) {
    case actionTypes.BATCH_SAVE:
      return { ...state, ...action.payload };
    case actionTypes.BATCH_CLEAR:
      return initState;
    default:
      return state;
  }
}
