import axios from 'axios';
import qs from 'qs';
import { notification, message } from 'antd';
import * as cons from './const';
import { isEmptyObject } from './reg';
import { generalParamsWithP, BASE64_encode } from './math';
import { API_ADMIN } from './urls';
import { logout } from '../state/action/login';
import { getAuthorityUserInfo } from './authority';

function checkStatus(response) {
  if (
    response.status >= cons.HTTP_ERROR_STATUS_OK &&
    response.status < cons.HTTP_ERROR_STATUS_NOT_MODIFIED
  ) {
    return response;
  }
  if (response.status !== 401) {
    const errortext = cons.codeMessage[response.status] || response.statusText;
    notification.error({
      message: `请求错误 ${response.status}: ${response.url}`,
      description: errortext,
    });
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  } else if (response.status === 401) {
    const errortext = cons.codeMessage[response.status] || response.statusText;

    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
// @withRouter
async function request(options) {
  try {
    console.group('接口请求信息：');
    console.log('%c request url：', 'color: red', options.url);
    console.log('%c request query：', 'color: orange', options.params);
    console.groupEnd('接口请求信息：');
    let reqHeaders = '',
      data = '';

    let userinfo = getAuthorityUserInfo();
    let hash = !isEmptyObject(userinfo) ? userinfo.access_token : '';
    if (options.headers === 'json') {
      reqHeaders = {
        'content-type': 'application/json;charset=UTF-8',
        accessToken: hash,
        appType: 'admin',
      };
      data = options.params;
    } else {
      let params = generalParamsWithP(options.url, options.params, options.normalP);
      if (options.headers === 'form-data') {
        reqHeaders = {
          Accept: 'application/json',
          accessToken: hash,
          appType: 'admin',
          'Content-Type': 'multipart/form-data',
        };
        let formData = new FormData();
        params = options.params;
        for (var i in params) {
          if (i === 'p') {
            formData.append(i, BASE64_encode(JSON.stringify(params[i])));
          } else {
            formData.append(i, params[i]);
          }
        }
        data = formData;
      } else {
        reqHeaders = { 'content-type': 'application/x-www-form-urlencoded' };
        data = qs.stringify(params);
      }
    }

    //给headers添加额外的参数，比如新增筐子在headers里面添加字段：idempotentToken
    if (options.headersParams) {
      reqHeaders = { ...reqHeaders, ...options.headersParams };
    }

    let response = null;

    if (options.method === 'get') {
      response = await axios({
        url: options.params ? `${options.url}?${qs.stringify(options.params)}` : options.url,
        method: options.method || 'POST',
        baseURL: options.url.indexOf('https') === 0 ? null : options.baseURL || API_ADMIN,
        headers: reqHeaders, //{ 'content-type': 'application/x-www-form-urlencoded' },
        responseType: options.responseType || 'json', // default
      });
    } else {
      response = await axios({
        url: options.url,
        method: options.method || 'POST',
        baseURL: options.baseURL || API_ADMIN,
        headers: reqHeaders, //{ 'content-type': 'application/x-www-form-urlencoded' },
        data: data, // qs.stringify(params),
        responseType: options.responseType || 'json', // default
      });
    }

    response = checkStatus(response);
    //

    const { code } = response.data;

    if (isEmptyObject(response)) {
      window.location.href = '/exception/500';
    }
    if (code === cons.HTTP_ERROR_STATUS_UNAUTHENTICATE) {
      logout();
    }
    if (
      code <= cons.HTTP_ERROR_STATUS_GATEWAY_TIMEOUT &&
      code >= cons.HTTP_ERROR_STATUS_INTERNALSERVERERROR
    ) {
    }
    if (code >= cons.HTTP_ERROR_STATUS_FILENOTFOUND && code < cons.HTTP_ERROR_STATUS_AUTH_ERROR) {
      window.location.href = '/exception/404';
    }

    return response.data;
  } catch (error) {
    console.error('request', error);
    const status = error.response && error.response.status; // status为500，但是data:{code:2001,msg:'错误信息'}
    if (status === cons.HTTP_ERROR_STATUS_UNAUTHENTICATE) {
      logout();
    }
    if (
      status === cons.HTTP_ERROR_STATUS_INTERNALSERVERERROR ||
      status === cons.HTTP_ERROR_STATUS_FILENOTFOUND
    ) {
      if (error.response.data && error.response.data.constructor === Object) {
        message.warn(error.response.data.msg);
        return error.response.data;
      } else {
        window.location.href = '/exception/500';
      }
    }
    if (status === cons.HTTP_ERROR_STATUS_FORBIDEN) {
      window.location.href = '/exception/403';
    }
    if (
      status <= cons.HTTP_ERROR_STATUS_GATEWAY_TIMEOUT &&
      status >= cons.HTTP_ERROR_STATUS_INTERNALSERVERERROR
    ) {
      return;
    }
    if (
      status >= cons.HTTP_ERROR_STATUS_FILENOTFOUND &&
      status < cons.HTTP_ERROR_STATUS_AUTH_ERROR
    ) {
      window.location.href = '/exception/404';
    }
  }
}

export default request;
