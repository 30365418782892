// 商行管理（查看详情--设备管理)
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Input, message, Table, Modal, Row, Col, Divider, Tooltip } from 'antd';
import { strSplit } from '../../../../utils/utils';

import {
  getListDevices,
  marketList,
  searchPrinter,
} from '../../../../state/action/stall';
import { getStallList } from '../../../../state/action/basic';
import {
  getListDeviceModes,
  getListDeviceTypes,
  getAddDevice,
  getUpdateDevice,
  getStallConnectPrinter,
  getDeviceIdByMachineCode,
  upgradeDevice,
} from '../../../../state/action/contract';

import AddDeviceModal from '../../../device-newManagement/addManagement/index';

import './device.less';

const FormItem = Form.Item;
const { TextArea } = Input;

const pageSize = 10;

@connect(
  (state) => {
    return { basic: state.basic, stall: state.stall, contract: state.contract };
  },
  {
    getListDevices,
    marketList,
    searchPrinter,
    getStallList,
    getListDeviceModes,
    getListDeviceTypes,
    getAddDevice,
    getUpdateDevice,
    getStallConnectPrinter,
    getDeviceIdByMachineCode,
    upgradeDevice,
  },
)
@Form.create()
class DeviceManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      defaultCurrent: 1,
      showModal: false,
      rows: {},
      exitsShow: false, //设备是否已存在Modal,
      exitStatus: null, //绑定状态
      exitStatusMsg: null, //绑定信息
      visible: false,
      deviceType: '',
      deviceTypeDesc: '',
      upgradeVisible: false,
    };
  }

  componentDidMount() {
    const { defaultCurrent } = this.state;
    this.getListDevices({
      // 列表
      stallId: this.props.stallId,
      type: 0,
      pageNo: defaultCurrent,
      pageSize,
    });
  }

  async getListDevices(params) {
    this.setState({ loading: true });
    await this.props.getListDevices(params);
    this.setState({ loading: false });
  }

  pageSwitch = (obj) => {
    this.setState(
      {
        defaultCurrent: obj.current,
      },
      () => {
        this.getListDevices({
          // 列表
          stallId: this.props.stallId,
          type: 0,
          pageNo: obj.current,
          pageSize,
        });
      },
    );
  };

  //新增、编辑Modal框
  showModal = (rows, visible) => {
    if (visible) {
      this.setState({
        showModal: visible,
        rows,
      });
    } else {
      this.setState({
        showModal: visible,
        rows,
      });
    }
  };

  // 获得市场
  async getMarketList() {
    await this.props.marketList({});
  }
  // 获取商行
  async getStallList(value) {
    return this.props.getStallList(value);
  }
  // 获取设备类型
  async getListDeviceModes(params) {
    await this.props.getListDeviceModes(params);
  }
  // 获取设备型号
  async getListDeviceTypes(params) {
    await this.props.getListDeviceTypes(params);
  }
  // 获取设备id
  async getDeviceIdByMachineCode(params) {
    await this.props.getDeviceIdByMachineCode(params);
  }

  //模态框，请选择设备类型
  changeDeviceModel = (v) => {
    this.setState({ deviceModelData: v });
    this.getListDeviceTypes({ name: v }); //获取设备型号联动
    this.props.form.setFieldsValue({ deviceTypeDesc: '' });
  };
  //模态框，请选择设备型号
  handleDeviceTypeDesc = (value) => {
    const {
      contract: { listDeviceTypesData = [] },
    } = this.props;
    const deviceTypeDescData = listDeviceTypesData.filter((item) => item.code === value);
    this.setState({
      deviceType: value,
      deviceTypeDesc: deviceTypeDescData[0].value,
      pmaker: '',
    });

    //设备型号
    const deviceTypeDesc = this.props.form.getFieldValue('deviceTypeDesc');
    //是否是优获云网关的IprintBOX
    const isIprintBOX =
      deviceTypeDesc === '菜小秘' || deviceTypeDesc === 'be806fd99e9b42a4a2a4d224437798c5';
    if (isIprintBOX) {
    }
  };

  async addDeviceFun(params) {
    const result = await this.props.getAddDevice(params);
    if (result.code === 200) {
      message.success('新增设备信息成功！');
      this.showModal({}, false);
      const { defaultCurrent } = this.state;
      this.getListDevices({
        // 列表
        stallId: this.props.stallId,
        type: 0,
        pageNo: defaultCurrent,
        pageSize,
      });
    } else if (
      (result && result.code === 7003) ||
      result.code === 7001 ||
      result.code === 7004 ||
      result.code === 7002
    ) {
      //（错误码7003已存在，为空闲状态; 7001已存在且被禁用，为空闲状态;7004 已存在，为使用中;7002 已存在且被禁用）
      this.setState({
        exitStatus: result.code,
        exitsShow: true,
        exitStatusMsg: result.msg,
      });
    }
  }

  async updateDeviceFun(params) {
    const result = await this.props.getUpdateDevice(params);
    if (result.code === 200) {
      message.success('修改设备信息成功！');
      this.showModal({}, false);
      const { defaultCurrent } = this.state;
      this.getListDevices({
        // 列表
        stallId: this.props.stallId,
        type: 0,
        pageNo: defaultCurrent,
        pageSize,
      });
    }
  }

  exitsShowCancel = () => {
    this.setState({ exitsShow: false });
  };

  //绑定商行 解除绑定时,stallId传0 绑定传商行id
  handleConfirm = () => {
    this.props.form.validateFields(
      [
        'machineCode',
        'name',
        'deviceModel',
        'deviceTypeDesc',
        'machineKey',
        'marketId',
        'stallId',
        'remark',
      ],
      (err, values) => {
        if (err) return;
        const { machineCode, name, deviceModel, machineKey, marketId, stallId, remark } = values;
        const { deviceType, deviceTypeDesc } = this.state; //设备型号
        values = {
          name: name || '',
          deviceModel: deviceModel || '',
          machineCode: machineCode || '',
          machineKey: machineKey || '',
          marketId: marketId || '', //市场id
          stallId: stallId || '', //商行id
          remark: remark || '',
          deviceType: deviceType || '', //设备型号 唯一标识
          deviceTypeDesc: deviceTypeDesc || '', //设备型号 比如：飞鹅
        };
        this.getDeviceIdByMachineCode({
          machineCode, //sn码
          deviceType, //设备型号 唯一标识
        }).then(() => {
          const {
            contract: { deviceIdByMachineCode },
          } = this.props;
          //绑定接口改位调编辑设备的接口
          this.updateDeviceFun({
            ...values,
            id: deviceIdByMachineCode || '',
            enable: 1,
          }).then(() => {
            const {
              contract: { updateDeviceMsg },
            } = this.props;
            if (updateDeviceMsg && updateDeviceMsg.code === 200) {
              message.info('绑定成功!');
              this.exitsShowCancel();
              this.showModal({}, false);
            }
          });
        });
      },
    );
  };

  async getStallConnectPrinter(params, flag) {
    await this.props.getStallConnectPrinter(params);
    const {
      contract: { stallConnectPrinterMsg },
    } = this.props;
    if (
      stallConnectPrinterMsg &&
      stallConnectPrinterMsg.code &&
      stallConnectPrinterMsg.code === 200
    ) {
      if (flag) {
        message.success('绑定成功!');
        this.showModal({}, false);
      } else {
        message.success('解绑成功!');
        this.showStallConnect({}, false);
      }
      const { defaultCurrent } = this.state;
      this.getListDevices({
        // 列表
        stallId: this.props.stallId,
        type: 0,
        pageNo: defaultCurrent,
        pageSize,
      });
    } else {
      message.error(stallConnectPrinterMsg.msg);
    }
  }

  //解绑弹框
  showStallConnect = (rows, visible) => {
    this.setState({
      visible,
      rows,
    });
  };

  //解绑
  addStallConnect = (e) => {
    e.preventDefault();
    this.props.form.validateFields((_, values) => {
      const { rows } = this.state;
      this.getStallConnectPrinter(
        {
          id: rows.id || null,
          stallId: 0,
          remark: values.remarks || null,
        },
        false,
      );
    });
  };

  //关闭模态框
  handleCancel = (e) => {
    this.setState({ showModal: false });
  };

  //刷新列表
  resetSearch = () => {
    const { defaultCurrent } = this.state;
    this.getListDevices({
      // 列表
      stallId: this.props.stallId,
      type: 0,
      pageNo: defaultCurrent,
      pageSize,
    });
  };

  //升级版本弹框
  showUpgradeConnect = (rows, upgradeVisible) => {
    this.setState({
      upgradeVisible,
      rows,
    });
  };

  //升级版本
  upgradeDevice = (e) => {
    e.preventDefault();
    this.props.form.validateFields((errors, values) => {
      const { rows } = this.state;
      this.upgradeHuagnshengDevice({
        machineCode: rows.machineCode || null,
        fileName: values.fileName || null,
      });
    });
  };

  async upgradeHuagnshengDevice(params) {
    await this.props.upgradeDevice(params);
    const {
      contract: { upgradeDeviceMsg },
    } = this.props;
    if (upgradeDeviceMsg && upgradeDeviceMsg.code && upgradeDeviceMsg.code === 200) {
      message.success('打印机升级操作成功!');
      this.showUpgradeConnect({}, false);
      const { defaultCurrent } = this.state;
      this.getListDevices({
        // 列表
        stallId: this.props.stallId,
        type: 0,
        pageNo: defaultCurrent,
        pageSize,
      });
    } else {
      message.error(upgradeDeviceMsg.msg);
    }
  }

  render() {
    const {
      stall: { devicesList },
      stallId,
      marketId,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const {
      loading,
      defaultCurrent,
      showModal,
      rows,
      exitStatus,
      exitStatusMsg,
      visible,
      upgradeVisible,
    } = this.state;

    const sysPrinterConfig = rows;
    const cxmBdStalls = {};
    const isEdit = JSON.stringify(rows) !== '{}' ? true : false;

    let dataSource = [];
    let TotalPageEntity = 1;
    if (devicesList) {
      const { pageList, totalSize } = devicesList;
      if (pageList) {
        dataSource = pageList;
        TotalPageEntity = totalSize;
      }
    }

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 100,
        render: (text, record, index) => {
          return index + 1;
        },
      },
      {
        title: '设备编号',
        dataIndex: 'sysPrinterConfig.name',
        key: 'name',
        width: 120,
      },
      {
        title: '设备类型',
        dataIndex: 'sysPrinterConfig.deviceModel',
        key: 'deviceModel',
        width: 120,
      },
      {
        title: '设备型号',
        dataIndex: 'sysPrinterConfig.deviceTypeDesc',
        key: 'deviceTypeDesc',
        width: 120,
      },
      {
        title: 'SN码',
        dataIndex: 'sysPrinterConfig.machineCode',
        key: 'machineCode',
        width: 120,
      },
      {
        title: '是否启用',
        dataIndex: 'sysPrinterConfig.enable',
        key: 'enable',
        width: 100,
        render: (text) => {
          return text !== 0 ? (
            <div>
              <Button
                type='primary'
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5 }}
              />
              启用
            </div>
          ) : (
            <div>
              <Button
                shape='circle'
                style={{ height: 8, minWidth: 8, marginTop: 6, marginRight: 5, background: '#ccc' }}
              />
              禁用
            </div>
          );
        },
      },
      {
        title: '状态更新时间',
        dataIndex: 'sysPrinterConfig.statusUpdateTime',
        key: 'statusUpdateTime',
        width: 120,
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD HH:mm') : '-';
        },
      },
      {
        title: '备注',
        dataIndex: 'sysPrinterConfig.remark',
        key: 'remark',
        width: 250,
        render: (_, record) => {
          const { sysPrinterConfig } = record;
          const textTip = <span>{sysPrinterConfig.remark}</span>;
          return (
            <Tooltip placement='rightTop' title={textTip}>
              <span>{strSplit(sysPrinterConfig.remark, 15)}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'options',
        key: 'options',
        width: 120,
        render: (_, record) => {
          return (
            <span>
              {record.sysPrinterConfig.enable === 0 ? (
                <span style={{ color: '#E3E3E3' }}>编辑</span>
              ) : (
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    this.showModal(record.sysPrinterConfig, true);
                  }}
                >
                  编辑
                </a>
              )}
              <Divider type='vertical' />
              {record.sysPrinterConfig.enable === 0 ? (
                <span style={{ color: '#E3E3E3' }}>解绑</span>
              ) : (
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    this.showStallConnect(record.sysPrinterConfig, true);
                  }}
                >
                  解绑
                </a>
              )}
              <Divider type='vertical' />
              {record.sysPrinterConfig.type === 7 ? (
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    this.showUpgradeConnect(record.sysPrinterConfig, true);
                  }}
                >
                  升级
                </a>
              ) : (
                ''
              )}
            </span>
          );
        },
      },
    ];
    const pagination = {
      pageSize: 10,
      total: TotalPageEntity,
      current: defaultCurrent,
    };

    return (
      <div className='deviceManagement'>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button type='primary' icon='plus' onClick={() => this.showModal({}, true)}>
              新增设备
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(record) => record.sysPrinterConfig.id}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: 1500 }}
          pagination={pagination}
          onChange={this.pageSwitch.bind(this)}
          loading={loading}
        />

        {/* 新增编辑设备Modal  showModalVisible显示隐藏； showModal显示Modal方法； handleCancel隐藏Modal方法 getListDevices设备列表方法  id设备id sysPrinterConfig,cxmBdStalls   */}
        {showModal ? (
          <AddDeviceModal
            showModalVisible={showModal}
            handleCancel={this.handleCancel}
            resetSearch={this.resetSearch}
            sysPrinterConfig={sysPrinterConfig}
            cxmBdStalls={cxmBdStalls}
            isEdit={isEdit}
            stallId={stallId}
            marketId={marketId}
          />
        ) : null}

        {this.state.exitsShow && (
          <Modal
            title={`提示`}
            visible={this.state.exitsShow}
            onCancel={() => this.exitsShowCancel()}
            footer={null}
            width='560px'
            style={{ marginTop: '10%' }}
          >
            {exitStatus === 7004 ? (
              <div style={{ textAlign: 'center', fontSize: '16px' }}>
                <div> {exitStatusMsg}</div>
                <div style={{ margin: '16px 0' }}>是否直接将设备绑定至商行下？</div>
              </div>
            ) : exitStatus === 7003 ? (
              <div style={{ textAlign: 'center', fontSize: '16px' }}>
                <div> {exitStatusMsg}</div>
                <div style={{ margin: '16px 0' }}>是否启用设备并将设备绑定至商行下？</div>
              </div>
            ) : exitStatus === 7002 ? (
              <div style={{ textAlign: 'center', fontSize: '16px' }}>
                <div> {exitStatusMsg.split('-')[0]}</div>
                <div style={{ margin: '16px 0' }}>{exitStatusMsg.split('-')[1]}</div>
              </div>
            ) : exitStatus === 7001 ? (
              <div style={{ textAlign: 'center', fontSize: '16px' }}>
                <div> {exitStatusMsg.split('-')[0]}</div>
                <div style={{ margin: '16px 0' }}>{exitStatusMsg.split('-')[1]}</div>
                <div>请先启用后操作</div>
              </div>
            ) : null}
            {exitStatus === 7003 || exitStatus === 7004 ? (
              <div style={{ textAlign: 'center' }}>
                <Button onClick={this.exitsShowCancel}>关闭</Button>
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{ marginLeft: '8px' }}
                  onClick={this.handleConfirm}
                >
                  绑定
                </Button>
              </div>
            ) : (
              <div style={{ textAlign: 'center', fontSize: '16px' }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{ marginLeft: '8px' }}
                  onClick={this.exitsShowCancel}
                >
                  知道了
                </Button>
              </div>
            )}
          </Modal>
        )}
        {/** 解绑 */}
        {visible && (
          <Modal
            title={'解除设备'}
            visible={visible}
            onCancel={() => this.showStallConnect({}, false)}
            footer={null}
            width='560px'
            className={'deviceModalStyle'}
          >
            <div style={{ textAlign: 'center', fontSize: '16px' }}>
              <div style={{ margin: '16px 0' }}>
                操作后设备将与商行解绑，并变更为<span style={{ color: '#1D9E60' }}>「空闲中」</span>
                状态
              </div>
            </div>
            <Form>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem label='备注'>
                    {getFieldDecorator('remarks', {
                      initialValue: '',
                    })(<TextArea rows={4} placeholder='请输入备注' maxLength='60' />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button onClick={() => this.showStallConnect({}, false)}>关闭</Button>
                <Button
                  type='primary'
                  onClick={(e) => this.addStallConnect(e)}
                  style={{ marginLeft: '18px' }}
                >
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        )}
        {/** 升级 */}
        {upgradeVisible && (
          <Modal
            title={'升级设备'}
            visible={upgradeVisible}
            onCancel={() => this.showUpgradeConnect({}, false)}
            footer={null}
            width='560px'
            className={'deviceModalStyle'}
          >
            <div style={{ textAlign: 'center', fontSize: '16px' }}>
              <div style={{ margin: '16px 0' }}>操作后设备将会升级固件,此过程会持续几分钟</div>
            </div>
            <Form>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem label='升级文件名称'>
                    {getFieldDecorator('fileName', {
                      initialValue: '',
                    })(<Input placeholder='请输入升级文件名称' />)}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button onClick={() => this.showUpgradeConnect({}, false)}>关闭</Button>
                <Button
                  type='primary'
                  onClick={(e) => this.upgradeDevice(e)}
                  style={{ marginLeft: '18px' }}
                >
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

export default DeviceManagement;
