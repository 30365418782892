/*
 * @Description:
 * @version:
 * @Author: lijing
 * @Date: 2022-05-05 10:47:32
 * @LastEditors: lijing
 * @LastEditTime: 2022-07-18 20:21:03
 */
import * as actionTypes from '../constant/personManager';

//初始状态
const initState = {
  personManagerList: {},
};

//reducer
export function personManager(state = initState, action) {
  switch (action.type) {
    case actionTypes.PERSON_MANAGER_GET_LIST:
      return { ...state, personManagerList: action.payload.data };
    default:
      return state;
  }
}
