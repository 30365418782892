/**
 * @description 只允许在1秒内执行一次这个方法
 * @author wangjun
 * @date 2020-06-02
 * @return
 */
export function executeOnceDecorator(time = 1000) {
  let isClick = true;
  return (target, name, descriptor) => {
    // 保存旧的方法add
    const oldValue = descriptor.value;
    descriptor.value = function () {
      if (isClick) {
        isClick = false;
        // 事件
        oldValue.apply(this, arguments);
        // 定时器
        setTimeout(function () {
          isClick = true;
        }, time); // 一秒内不能重复点击
      }
    };
    // 必须返回descriptor对象
    return descriptor;
  };
}
