// 商品库管理-商品基础库-别名维护页面
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Icon, Button, Modal, Select, Tag, Tooltip, message, Input } from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import '../commodity-base/index.less';

import {
  getUnrelatedAlias,
  getAllAlias,
  insertAlias,
  deleteAlias,
} from '../../../state/action/commodity';

const { confirm } = Modal;
const { Search } = Input;

@connect(
  (state) => {
    return { commodity: state.commodity };
  },
  { getUnrelatedAlias, getAllAlias, insertAlias, deleteAlias },
)
@Form.create()
class AddGoodsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      goodsAliasData: [],
      tags: [],
      value: [], // 下拉框的值
      aliasIdList: [], // 下拉框id
      openFlag: false, //下拉框是否展开
      searchValue: '', // 搜索的值
    };
  }

  componentDidMount() {
    this.getUnrelatedAlias(); // 货品未关联的别名查询
    this.getAllAlias(); // 货品已关联别名查询
  }

  // 货品未关联的别名查询
  async getUnrelatedAlias() {
    await this.props.getUnrelatedAlias();
    const { searchValue } = this.state;
    const {
      commodity: { unrelatedAliaList },
    } = this.props;
    let goodsAliasData = unrelatedAliaList && unrelatedAliaList.length > 0 ? unrelatedAliaList : [];
    if (searchValue) {
      // 如果搜索有值，下拉框应该显示的是搜索的内容
      goodsAliasData = goodsAliasData.filter((v) => v.name.indexOf(searchValue) > -1);
    }
    this.setState({
      goodsAliasData,
    });
  }

  // 货品已关联别名查询
  async getAllAlias() {
    const { rows } = this.props.history.location.state;
    await this.props.getAllAlias({ id: rows.id });
    const {
      commodity: { allAliasList },
    } = this.props;
    const tagsArr = allAliasList && allAliasList.length > 0 ? allAliasList : [];
    this.setState({
      tags: tagsArr,
    });
  }

  isUndefied = (value) => {
    return value || null;
  };

  // 搜索框
  onSearchValue = (value) => {
    this.setState(
      {
        searchValue: value,
        openFlag: true,
      },
      () => {
        this.getUnrelatedAlias();
      },
    );
  };

  // 别名保存按钮
  handleSumbit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const { rows } = this.props.history.location.state;
      const { aliasIdList } = this.state;
      if (aliasIdList.length > 0) {
        this.insertAlias({ id: rows.id, aliasIdList });
      } else {
        message.info('请先选择货品别名');
      }
    });
  };

  // 新增
  async insertAlias(params) {
    await this.props.insertAlias(params);
    const {
      commodity: { insertAliasData },
    } = this.props;
    if (insertAliasData.code === 200) {
      this.props.form.resetFields();
      this.setState(
        {
          aliasIdList: [], // 保存后清空数据
          openFlag: false,
        },
        () => {
          this.getUnrelatedAlias();
          this.getAllAlias();
        },
      );
    }
  }

  onSelectChange = (_, option) => {
    const arr = [],
      idArr = [];
    option.map((i) => {
      idArr.push(i.key);
      arr.push({
        id: i.key,
        name: i.props.value,
      });
      return null;
    });
    this.setState({
      value: arr,
      aliasIdList: idArr,
    });
  };

  // 下拉框失去焦点事件
  onFocus = () => {
    this.setState({
      openFlag: true,
    });
  };
  // 获取焦点事件
  onBlur = () => {
    this.setState({
      openFlag: false,
    });
  };

  // 删除已关联的别名
  handleClose = (e, removedTag) => {
    e.preventDefault();
    const that = this;
    confirm({
      title: '取消关联',
      content: `确定要删除【${removedTag.name}】的关联关系么？`,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        that.deleteAlias(removedTag);
      },
      onCancel() {},
    });
  };

  async deleteAlias(removedTag) {
    const result = await this.props.deleteAlias({ id: removedTag.id });
    if (result.code === 200) {
      this.getUnrelatedAlias();
      const tags = this.state.tags.filter((tag) => tag.id !== removedTag.id);
      this.setState({ tags });
    } else {
      message.error(result.msg);
    }
  }

  gotoBack = () => {
    this.props.history.push({ pathname: '/commodityManage/commodityBase', state: {} });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { rows } = this.props.history.location.state;
    const { tags, goodsAliasData, openFlag } = this.state;

    const layout = { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 };
    const layout1 = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };

    return (
      <div className='commodity-index'>
        <PageHeaderLayout>
          <div className='commodityManage'>
            <Form onSubmit={this.handleSumbit} layout='inline'>
              <Row>
                <Col {...layout} style={{ fontSize: 18, fontWeight: 'bold' }}>
                  货品名称：{rows.name}
                </Col>
                <Col {...layout} style={{ fontSize: 18, fontWeight: 'bold' }}>
                  货品分类：{rows.category}
                </Col>
                <Col {...layout1} style={{ textAlign: 'right' }}>
                  <Button type='primary' onClick={this.gotoBack}>
                    返回货品库
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <span>
                  <Icon type='minus' rotate={90} style={{ fontSize: '0.15rem', color: 'green' }} />
                  <span style={{ lineHeight: '35px', verticalAlign: 'top', fontSize: 16 }}>
                    关联别名
                  </span>
                </span>
              </Row>
              <Row gutter={24}>
                <Col span={4}>
                  {getFieldDecorator(
                    'q',
                    {},
                  )(
                    <Search
                      placeholder='请输入筛选名称'
                      onSearch={(value) => this.onSearchValue(value)}
                      enterButton
                    />,
                  )}
                </Col>
                <Col {...layout1}>
                  {getFieldDecorator(
                    'goodsAlias',
                    {},
                  )(
                    <Select
                      onChange={this.onSelectChange}
                      mode='multiple'
                      showArrow
                      placeholder='请选择货品别名'
                      allowClear
                      open={openFlag}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    >
                      {goodsAliasData.map((item, index) => {
                        return (
                          <Select.Option key={item.id} value={item.name}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                </Col>
                <Col span={4}>
                  <Button type='primary' style={{ width: 80 }} htmlType='submit'>
                    保存
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <span>
                  <Icon type='minus' rotate={90} style={{ fontSize: '0.15rem', color: 'green' }} />
                  <span style={{ lineHeight: '35px', verticalAlign: 'top', fontSize: 16 }}>
                    已关联别名
                  </span>
                </span>
              </Row>
              <Row>
                {tags.map((tag) => {
                  const { id, name } = tag;
                  const isLongTag = name.length > 20;
                  const tagElem = (
                    <Tag
                      key={id}
                      closable={rows.name !== name}
                      onClose={(e) => this.handleClose(e, tag)}
                      style={{ margin: 8, padding: 8, fontSize: 16 }}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : name}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={name} key={id}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}
              </Row>
            </Form>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default AddGoodsPage;
