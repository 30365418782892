import * as actionTypes from '../constant/basic';
import * as req from '../request/basic';

//
export function getConfigGroup(params) {
  return async (dispatch) => {
    const res = await req.getConfigGroup(params);
    if (res) {
      dispatch({
        type: actionTypes.BASIC_SAVE,
        payload: { getConfigGroup: res.data, getConfigGroupParams: params },
      });
    }
  };
}
//
export function saveOrUpdateConfigGroup(params) {
  return async (dispatch) => {
    const res = await req.saveOrUpdateConfigGroup(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { configGroupUpdateOrSaveMsg: res } });
    }
  };
}
//
export function getAllDistrict(params) {
  return async (dispatch) => {
    const res = await req.getAllDistrict(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { getAllDistrict: res } });
    }
  };
}
//获取市场列表
export function getMarketList(params) {
  return async (dispatch) => {
    const res = await req.getMarketList(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { marketList: res } });
    }
  };
}
//获取档口列表
export function getStallList(params) {
  return async (dispatch) => {
    const res = await req.getStallList(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { stallList: res } });
    }
  };
}
// 获取收银员列表
export function getCashierList(params) {
  return async (dispatch) => {
    const res = await req.getCashierList(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { cashierList: res } });
    }
  };
}
// 手动帮助收银员交账
export function cashierHandle(params) {
  return async (dispatch) => {
    const res = await req.cashierHandle(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { cashierHandle: res } });
    }
  };
}

//
export function getStallConfigGroupById(params) {
  return async (dispatch) => {
    const res = await req.getStallConfigGroupById(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { getConfigOfStalledList: res } });
    }
  };
}
//
export function getCalcTypeConfigByStallId(params) {
  return async (dispatch) => {
    const res = await req.getCalcTypeConfigByStallId(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { stallCalcTypeConfigList: res } });
    }
  };
}
//
export function addStallConfigGroup(params) {
  return async (dispatch) => {
    const res = await req.addStallConfigGroup(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { addStallConfigGroupMsg: res } });
    }
  };
}
//
export function removeStallConfigGroup(params) {
  return async (dispatch) => {
    const res = await req.removeStallConfigGroup(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { removeStallConfigGroupMsg: res } });
    }
  };
}
//
export function updateStallConfigShowFlag(params) {
  return async (dispatch) => {
    const res = await req.updateStallConfigShowFlag(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { addStallConfigGroupMsg: res } });
    }
  };
}
//
export function getConfigKeys(params) {
  return async (dispatch) => {
    const res = await req.getConfigKeys(params);
    if (res) {
      dispatch({ type: actionTypes.BASIC_SAVE, payload: { allStallConfigData: res.data } });
    }
  };
}

//获得url参数加密的字符串
export function getEncryptStr(params) {
  return async (dispatch) => {
    return await req.getEncryptStr(params);
  };
}

//重置字段
export function resetFields(name) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.RESET_FIELDS, payload: name });
  };
}
