// 商行详情-附加费用配置
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, message, Table, Popconfirm, Modal, Row } from 'antd';
import {
  getReferralStallList,
  referralExchange,
  referralExchangeAll,
} from '../../../../state/request/stall';
import './device.less';

@connect((state) => {
  return { stall: state.stall };
}, {})
@Form.create()
class RecommendStallList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      list: [],
      totalNum: 0,
      unUsedNum: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { stallId } = this.props;
    const res = await getReferralStallList(stallId);
    const newList = res.data || [];
    const unUsedList = newList.filter((v) => !v.reward);
    this.setState({
      list: newList,
      totalNum: newList.length,
      unUsedNum: unUsedList.length,
    });
  }

  async updateItem(record) {
    const { reward, stallId } = record;
    const res = await referralExchange({ stallId, reward: !reward });
    const { code, msg } = res || {};
    if (code === 200) {
      message.info(`${reward ? '取消' : ''}兑换成功`);
      this.getData();
    } else {
      message.info(msg || '请求失败，请重试');
    }
  }

  async updateAll() {
    this.setState({ showModal: false });
    const { stallId } = this.props;
    const res = await referralExchangeAll({ referralStallId: stallId });
    const { code, msg } = res || {};
    if (code === 200) {
      message.info(`一键兑换成功`);
      this.getData();
    } else {
      message.info(msg || '请求失败，请重试');
    }
  }

  render() {
    const { loading, list, totalNum, unUsedNum, showModal } = this.state;
    const columns = [
      {
        title: '介绍商行ID',
        dataIndex: 'stallId',
        key: 'stallId',
      },
      {
        title: '介绍商行市场',
        dataIndex: 'marketName',
        key: 'marketName',
      },
      {
        title: '介绍商行',
        dataIndex: 'stallName',
        key: 'stallName',
      },
      {
        title: '入驻时间',
        dataIndex: 'createTime',
        key: 'createTime',
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD HH:mm') : '- -';
        },
      },
      {
        title: '奖励是否兑换',
        dataIndex: 'reward',
        key: 'reward',
        render: (_, record) => {
          const { reward = false } = record;
          let classStyle = reward ? 'ball green' : 'ball red';
          return (
            <div className='tableItem'>
              <span className={classStyle}></span>
              <span>{reward ? '已兑换' : '未兑换'}</span>
            </div>
          );
        },
      },
      {
        title: '操作人',
        dataIndex: 'personName',
        key: 'personName',
        render: (text) => {
          return text ? text : '- -';
        },
      },
      {
        title: '操作时间',
        dataIndex: 'updateTime',
        key: 'updateTime',
        render: (text) => {
          return text ? moment(text).format('YYYY-MM-DD HH:mm') : '- -';
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        render: (_, record) => {
          const { reward } = record;
          let classStyle = reward ? 'enableTrueText' : 'enableFalseText';
          return (
            <div className='tableItem'>
              <Popconfirm
                title={`确定${reward ? '取消' : ''}兑换奖励吗？`}
                onConfirm={() => this.updateItem(record)}
              >
                <span className={classStyle}>{reward ? '取消兑换' : '兑换'}</span>
              </Popconfirm>
            </div>
          );
        },
      },
    ];
    return (
      <div className='recommendStallList'>
        <div className='header'>
          <div className='header-left'>
            共 {totalNum} 家<span>未兑换奖励 {unUsedNum} 家</span>
          </div>
          <Button
            type='primary'
            onClick={() => {
              if (unUsedNum > 0) {
                this.setState({ showModal: true });
              } else {
                message.info('你没有可兑换的商行');
              }
            }}
          >
            一键兑换
          </Button>
        </div>
        <div className='table'>
          <Table
            rowKey={(_, index) => index}
            dataSource={list}
            columns={columns}
            loading={loading}
            pagination={false}
          />
        </div>

        <Modal
          visible={showModal}
          onCancel={() => this.setState({ showModal: false })}
          destroyOnClose='true'
          className='recommendStallList-modal'
          width='320px'
          footer={
            <Row style={{ textAlign: 'center' }}>
              <Button onClick={() => this.setState({ showModal: false })}>取消</Button>
              <Button
                type='primary'
                style={{ marginLeft: '18px' }}
                onClick={() => this.updateAll()}
              >
                保存
              </Button>
            </Row>
          }
        >
          <div className='recommendStallList-modal-content'>
            未兑换奖励的 {unUsedNum} 家商行会标记为已兑换，确定要操作吗？
          </div>
        </Modal>
      </div>
    );
  }
}

export default RecommendStallList;
