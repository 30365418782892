// 批次明细
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Card, Col, Table, Row, Icon, Tag, Modal, Input, Button, message } from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import { getBatchItemList } from '../../state/action/batchManager';
import { getProductById, updateProductPrice } from '../../state/request/batchManager';
import { regAmount } from '../../utils/utils';
import './index.less';

const FormItem = Form.Item;

@Form.create()
@connect(
  (state) => {
    return { batchManager: state.batchManager };
  },
  { getBatchItemList },
)
class BatchItem extends PureComponent {
  constructor(props) {
    super(props);
    this.record = (this.props.location.state && this.props.location.state.record) || {};
    this.state = {
      loading: false,
      pageNo: 1,
      visible: false, //修改
      modifyData: {}, //修改的数据
    };
  }

  componentDidMount() {
    let pageInfo = {
      stallId: this.record.stallId,
      batchId: this.record.id,
      supplierName: this.record.supplierName,
      carNo: this.record.carNo,
      pageSize: 10,
      pageNo: 1,
    };
    this.getList(pageInfo);
    document.body.classList.add('sys-log-index-body');
  }

  componentWillUnmount() {
    document.body.classList.remove('sys-log-index-body');
  }

  // 获取table数据
  async getList(params) {
    this.setState({ loading: true });
    await this.props.getBatchItemList(params);
    this.setState({ loading: false });
  }

  // 分页函数
  pageChange = (pageNumber) => {
    this.setState({
      pageNo: pageNumber.current,
    });
    let pageInfo = {
      stallId: this.record.stallId,
      batchId: this.record.id,
      supplierName: this.record.supplierName,
      carNo: this.record.carNo,
      pageSize: 10,
      pageNo: pageNumber.current,
    };
    this.getList(pageInfo);
  };

  //返回
  back = (e) => {
    e.preventDefault();
    const record = this.record;
    this.props.history.push({ pathname: '/system/batchManager', state: { record } });
  };

  toPage = (record) => {
    record.marketId = this.record.marketId;
    record.supplierId = this.record.supplierId;
    record.searchCarNo = this.record.searchCarNo;
    this.props.history.push({ pathname: '/system/batchManager/batchItemLogs', state: { record } });
  };

  //修改弹框
  modify = async (e, record) => {
    e.preventDefault();
    const goodsKindType = record.goodsKindType;
    if (7 === goodsKindType) {
      message.info('拆包的货品不能修改');
    } else {
      const { stallId, id: productId } = record;
      const res = await getProductById(stallId, productId);
      const { code, data } = res;
      if (code === 200) {
        const modifyData = {
          stallId,
          productId,
          tradeFee: data.tradeFee || 0,
          billFee: data.billFee || 0,
          packFee: data.packFee || 0,
          unitPrice: data.unitPrice || 0,
        };
        this.setState({ visible: true, modifyData });
      }
    }
  };
  //修改取消
  handleCancel = () => {
    this.setState({ visible: false, modifyData: {} });
  };
  // 修改信息
  update = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ['stallId', 'productId', 'tradeFee', 'billFee', 'packFee', 'unitPrice'],
      async (errors, values) => {
        if (!!errors) {
          return;
        }
        const res = await updateProductPrice(values);
        const { code } = res;
        if (code === 200) {
          message.info('修改成功！');
          this.setState({ visible: false, modifyData: {} });
        }
      },
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { batchItemList } = this.props.batchManager;
    const { visible, modifyData } = this.state;
    let list = [];
    let pageEntity = {};
    if (batchItemList && batchItemList.length > 0) {
      list = batchItemList;
      pageEntity.totalSize = list.length;
    }
    var totalSize = 0;
    if (pageEntity === undefined) {
    } else {
      totalSize = pageEntity.totalSize;
    }

    // 表格数据源
    const dataSource = list;
    const pagination = {
      current: this.state.pageNo,
      total: totalSize,
    };
    let batchSaleType = this.record.batchSaleType;
    let currBatchId = this.record.id;
    return (
      <div className='sys-index'>
        <PageHeaderLayout>
          <div className={'system-log'}>
            <Row className='backRow'>
              <a href='/' onClick={this.back}>
                <Icon type='left' /> 返回
              </a>
            </Row>
            <Card className='' bordered={false} title=''>
              <Table
                rowKey={(_, index) => index}
                dataSource={dataSource}
                pagination={pagination}
                loading={this.state.loading}
                onChange={this.pageChange.bind(this)}
                scroll={{ x: 'max-content' }}
                style={{ whiteSpace: 'nowrap' }}
              >
                <Col
                  align='center'
                  title='货主名称'
                  dataIndex='supplierName'
                  key='supplierName'
                  width={100}
                />
                <Col align='center' title='车次号' dataIndex='carNo' key='carNo' width={100} />
                <Col
                  align='center'
                  title='货品名称'
                  dataIndex='attrName'
                  key='attrName'
                  width={150}
                />
                <Col
                  align='center'
                  title='包装类型'
                  dataIndex='goodsKindType'
                  key='goodsKindType'
                  width={100}
                  render={(_, record) => {
                    const goodsKindType = record.goodsKindType;
                    //1：定装 2：非定装 3：散装  5: 郊菜  7: 拆包
                    if (1 === goodsKindType) {
                      return '定装';
                    } else if (2 === goodsKindType) {
                      return '非定装';
                    } else if (3 === goodsKindType) {
                      return '散装';
                    } else if (5 === goodsKindType) {
                      return '郊菜';
                    } else if (7 === goodsKindType) {
                      return '拆包';
                    }
                    return goodsKindType;
                  }}
                />
                <Col
                  align='center'
                  title='已售数量'
                  dataIndex='saleNumber'
                  key='saleNumber'
                  width={120}
                />
                <Col
                  align='center'
                  title='已售重量'
                  dataIndex='saleWeight'
                  key='saleWeight'
                  width={120}
                />
                <Col
                  align='center'
                  title='当前库存数量'
                  dataIndex='stockNumber'
                  key='stockNumber'
                  width={120}
                />
                <Col
                  align='center'
                  title='当前库存重量'
                  dataIndex='stockWeight'
                  key='stockWeight'
                  width={120}
                />
                <Col
                  align='center'
                  title='库存类型'
                  dataIndex='storageType'
                  key='storageType'
                  width={100}
                  render={(_, record) => {
                    const storageType = record.storageType;
                    //1 弱库存；2 强库存
                    if (1 === storageType) {
                      return '弱库存';
                    } else if (2 === storageType) {
                      return '强库存';
                    }
                    return storageType;
                  }}
                />
                <Col
                  align='center'
                  title='售卖类型'
                  dataIndex='saleType'
                  key='saleType'
                  width={100}
                  render={(_, record) => {
                    const saleType = record.saleType;
                    if (1 === saleType) {
                      return '代卖';
                    } else if (2 === saleType) {
                      return '自营';
                    }
                    return saleType;
                  }}
                />
                <Col
                  align='center'
                  title='状态'
                  dataIndex='enable'
                  key='enable'
                  width={80}
                  render={(_, record) => {
                    const enable = record.enable;
                    if (!enable) {
                      return (
                        <Tag color={'red'} key={'禁用'}>
                          禁用
                        </Tag>
                      );
                    }
                    return (
                      <Tag color={'green'} key={'正常'}>
                        正常
                      </Tag>
                    );
                  }}
                />
                <Col
                  align='center'
                  title='创建时间'
                  dataIndex='createTime'
                  key='createTime'
                  width={120}
                  render={(_, record) => {
                    const time = moment(record.createTime).format('YYYY-MM-DD HH:mm:ss');
                    return time;
                  }}
                />
                <Col
                  align='center'
                  title='操作'
                  dataIndex='oper'
                  key='oper'
                  width={100}
                  fixed={'right'}
                  render={(_, record) => {
                    const goodsKindType = record.goodsKindType;
                    record.batchSaleType = batchSaleType;
                    record.batchId = currBatchId;
                    return (
                      <span>
                        <a
                          href='/'
                          onClick={(e) => {
                            this.modify(e, record);
                          }}
                          style={{ marginRight: 16 }}
                          className={`${7 === goodsKindType ? 'grey' : ''}`}
                        >
                          修改
                        </a>
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault();
                            this.toPage(record);
                          }}
                        >
                          库存记录
                        </a>
                      </span>
                    );
                  }}
                />
              </Table>
            </Card>

            <Modal
              title='修改信息'
              visible={visible}
              onCancel={this.handleCancel}
              destroyOnClose={true}
              className='modalStyle'
              width='600px'
              footer={[]}
            >
              <div className='extraContent' style={{ width: '100%' }}>
                <Form onSubmit={this.update}>
                  <FormItem label='stallId' style={{ display: 'none' }}>
                    {getFieldDecorator('stallId', {
                      initialValue: modifyData.stallId,
                    })(<Input />)}
                  </FormItem>
                  <FormItem label='productId' style={{ display: 'none' }}>
                    {getFieldDecorator('productId', {
                      initialValue: modifyData.productId,
                    })(<Input />)}
                  </FormItem>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem label='力资费'>
                        {getFieldDecorator('tradeFee', {
                          initialValue: modifyData.tradeFee || 0,
                          rules: [
                            {
                              pattern: new RegExp(regAmount),
                              message: `金额只能为数字且最多两位小数!`,
                            },
                          ],
                        })(<Input placeholder='请输入力资费' />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem label='开票费'>
                        {getFieldDecorator('billFee', {
                          initialValue: modifyData.billFee || 0,
                          rules: [
                            {
                              pattern: new RegExp(regAmount),
                              message: `金额只能为数字且最多两位小数!`,
                            },
                          ],
                        })(<Input placeholder='请输入开票费' style={{ width: '100%' }} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem label='灌包费'>
                        {getFieldDecorator('packFee', {
                          initialValue: modifyData.packFee || 0,
                          rules: [
                            {
                              pattern: new RegExp(regAmount),
                              message: `金额只能为数字且最多两位小数!`,
                            },
                          ],
                        })(<Input placeholder='请输入灌包费' style={{ width: '100%' }} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem label='销售单价'>
                        {getFieldDecorator('unitPrice', {
                          initialValue: modifyData.unitPrice || 0,
                          rules: [
                            {
                              pattern: new RegExp(regAmount),
                              message: `金额只能为数字且最多两位小数!`,
                            },
                          ],
                        })(<Input placeholder='请输入销售单价' style={{ width: '100%' }} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24} style={{ paddingTop: '10px' }}>
                      <FormItem
                        label='操作'
                        className='operation'
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Button type='primary' htmlType='submit' style={{ marginRight: '18px' }}>
                          确定
                        </Button>
                        <Button onClick={this.handleCancel}>取消</Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}

export default BatchItem;
