//合单配置
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Card,
  Row,
  Col,
  Button,
  Table,
  Input,
  Modal,
  Radio,
  Select,
  Divider,
  message,
} from 'antd';

import PageHeaderLayout from '../../component/page-header-layout/index';
import './style.less';

import { fetchAssociatedStall, fetchAllStallCalculationResult } from '../../state/action/count';
import { getMergeSetList, fetchMarketAdd, fetchUpdateMergeSet } from '../../state/action/mergeSet';
import { getConfigKeys } from '../../state/action/basic';

const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const { Option } = Select;

@connect(
  (state) => {
    return { count: state.count, mergeSet: state.mergeSet, basic: state.basic };
  },
  {
    getConfigKeys,
    getMergeSetList,
    fetchAssociatedStall,
    fetchAllStallCalculationResult,
    fetchMarketAdd,
    fetchUpdateMergeSet,
  },
)
@Form.create()
class MergeSetList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: null,
      visible: false,
      editVisible: false,
      value: 1,
      comps: [],
      reqStallId: null,
      id: null,
      record: {},
    };
    this.searchMergeSet = this.searchMergeSet.bind(this);
    this.addMergeSet = this.addMergeSet.bind(this);
  }

  componentDidMount() {
    this.getAllBasicConfig();
    this.getMergeSetList({ key: null, pageSize: 10, pageNo: 1, stallId: null });
  }

  onInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  // 省市区选择事件
  onCityChange = (e) => {
    this.fetchAllMarketByCityCode({ cityCode: e });
  };

  // 市场选择
  onSelectChange = (e) => {
    this.fetchStallsByMarket({ marketId: e });
  };

  // 档口选择
  onSelectChange1 = (e) => {
    // localStorage.setItem("reqStallId", e);
    this.setState({
      reqStallId: e,
    });
  };

  // 查询接口口
  async getAllBasicConfig(value) {
    this.setState({
      loading: true,
    });
    await this.props.getConfigKeys(value);
  }

  // 页面渲染获取合单配置列表
  async getMergeSetList(params) {
    await this.props.getMergeSetList(params);
  }

  // 选择档口分配合单配置
  async fetchAssociatedStall(params) {
    await this.props.fetchAssociatedStall(params).then(() => {
      const {
        count: { associatedStall },
      } = this.props;
      const { code } = associatedStall || [];
      if (code === 200) {
        message.info('档口分配配置成功!');
        this.setState({
          editVisible: false,
        });
      }
    });
  }

  async fetchAllStallCalculationResult(params) {
    await this.props.fetchAllStallCalculationResult(params);
  }

  // 新增合单配置
  async fetchMarketAdd(params) {
    await this.props.fetchMarketAdd(params).then(() => {
      const {
        mergeSet: { add },
      } = this.props;
      const { code } = add || {};
      if (code === 200) {
        message.info('新增合单配置成功!');
        this.setState({
          visible: false,
        });
      }
      this.searchMergeSet();
    });
  }

  // 修改合单配置
  async fetchUpdateMergeSet(params) {
    await this.props.fetchUpdateMergeSet(params).then(() => {
      const {
        mergeSet: { update },
      } = this.props;
      const { code } = update || {};
      if (code === 200) {
        message.info('修改合单配置成功!');
        this.setState({
          editVisible: false,
        });
      }
      this.searchMergeSet();
    });
  }

  // 根据条件搜索合单配置列表
  async searchMergeSet() {
    const Keyword = this.state.inputValue;
    await this.props.getMergeSetList({ key: Keyword, pageSize: 10, pageNo: 1 });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleOk1 = () => {
    const { reqStallId, id } = this.state;
    this.fetchAssociatedStall({ ruleId: id, reqStallId: reqStallId });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      editVisible: false,
    });
  };

  addMergeSet = () => {
    this.setState({
      visible: true,
    });
  };

  updateMergeSet = (record, viewInfo) => {
    let modalTitle = '查看合单配置';
    if (!viewInfo) {
      modalTitle = '修改合单配置';
    }
    this.setState({
      editVisible: true,
      record: record,
      modalTitle: modalTitle,
      viewInfo: viewInfo,
    });
  };
  setMergesetGroup(id) {
    this.props.history.push({ pathname: '/count/mergeSet/MergeSetGroup', params: { id } });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return false;
      }
      const { value } = this.state;
      const defaultRule = value === 1 ? true : false;
      let pairList = [];
      for (let i in fieldsValue) {
        if (i.indexOf('key') >= 0) {
          const index = i.split('key')[1];
          pairList.push({ key: fieldsValue[i], value: fieldsValue[`name${index}`] });
        }
      }
      const obj = {
        ruleName: fieldsValue.ruleName,
        defaultRule: defaultRule,
        pairList: pairList,
        ruleRemark: fieldsValue.ruleRemark,
      };
      this.fetchMarketAdd(obj);
    });
  };

  updateMergeSetSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return false;
      }
      const obj = {
        id: this.state.record.id,
        ruleName: fieldsValue.ruleName,
        ruleRemark: fieldsValue.ruleRemark,
        defaultRule: this.state.record.defaultRule,
      };
      this.fetchUpdateMergeSet(obj);
    });
  };

  render() {
    const { visible, editVisible, comps, value } = this.state;
    const {
      mergeSet: {
        mergeList: { lst, pageEntity },
      },
      basic: { allStallConfigData },
    } = this.props;
    const {
      count: { allResultData },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { pageSize, totalSize } = pageEntity || { pageSize: 10, totalSize: 0 };

    // 表格的分页的设计
    const pagination = {
      pageSize: pageSize,
      total: totalSize,
    };
    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 6 };
    const layout1 = { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 };

    const extraContent = (
      <Form layout='inline'>
        <Row gutter={24}>
          <Col {...layout}>
            <FormItem label='商行名称'>
              {getFieldDecorator(
                'Keyword',
                {},
              )(<Input placeholder='请输入商行名字' onChange={this.onInputChange} allowClear />)}
            </FormItem>
          </Col>
        </Row>
        <br />
        <Row gutter={10}>
          <Col span={24}>
            <Button type='primary' onClick={this.searchMergeSet}>
              查询
            </Button>
            <Button style={{ marginLeft: 25, width: 80 }} onClick={this.addMergeSet}>
              新增
            </Button>
          </Col>
        </Row>
      </Form>
    );

    const columns = [
      {
        title: '序号',
        width: 100,
        render: (_, __, index) => `${index + 1}`,
        key: '1',
      },
      {
        title: '配置名称',
        dataIndex: 'ruleName',
        width: 300,
        align: 'center',
        key: 'ruleName',
      },
      {
        title: '配置备注',
        dataIndex: 'ruleRemark',
        width: 500,
        align: 'center',
        key: 'ruleRemark',
      },
      {
        title: '是否合单',
        dataIndex: 'enable',
        align: 'center',
        width: 100,
        key: 'enable',
        render: (_, record) => {
          const { enable } = record;
          return enable ? '合单' : '不合单';
        },
      },
      {
        title: '操作',
        dataIndex: 'mergeHandKey',
        align: 'center',
        key: 'mergeHandKey',
        width: 200,
        render: (_, record) => {
          const { id } = record;
          return (
            <div>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateMergeSet(record, false);
                }}
              >
                修改
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.setMergesetGroup(id, false);
                }}
              >
                分配档口
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.updateMergeSet(record, true);
                }}
              >
                查看
              </a>
            </div>
          );
        },
      },
    ];

    return (
      <div className='count-mergeSet-index'>
        <PageHeaderLayout>
          <div className='count-mergeSet'>
            <Card className='' bordered={false} title='' extra={extraContent}>
              <Table
                dataSource={lst || []}
                columns={columns}
                pagination={pagination}
                rowKey={(record, index) => index}
              />
            </Card>
            {visible && (
              <Modal
                title='新增合单配置'
                visible={visible}
                destroyOnClose
                closable
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                className='mergeSetModal'
                width='600px'
              >
                <Form layout='inline' onSubmit={this.handleSubmit}>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='配置名称'>
                        {getFieldDecorator('ruleName', {
                          rules: [{ required: true, message: '' }],
                        })(<Input placeholder='配置名称' />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='配置备注'>
                        {getFieldDecorator('ruleRemark', {
                          rules: [{ required: true, message: '' }],
                        })(<Input placeholder='配置备注' />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='是否使用默认'>
                        {getFieldDecorator('type', {
                          rules: [{ required: false, message: '' }],
                        })(
                          <RadioGroup onChange={this.onChange}>
                            <Radio value={1}>是</Radio>
                            <Radio value={2}>否</Radio>
                          </RadioGroup>,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  {value === 2 ? (
                    <Row gutter={24}>
                      <Col {...layout}>
                        <FormItem label=''>
                          {getFieldDecorator('key', {
                            rules: [{ required: false, message: '' }],
                          })(
                            <Select
                              placeholder='配置名称'
                              showSearch
                              allowClear
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              }
                            >
                              {allStallConfigData &&
                                Array.isArray(allStallConfigData) &&
                                allStallConfigData.map((item) => (
                                  <Option key={`${item.key}`}>
                                    {Array.isArray(item.desc) ? item.desc[0] : ''}
                                  </Option>
                                ))}
                            </Select>,
                          )}
                        </FormItem>
                      </Col>
                      <Col {...layout}>
                        <FormItem label='='>
                          {getFieldDecorator('name', {
                            rules: [{ required: false, message: '' }],
                          })(<Input placeholder='value' />)}
                        </FormItem>
                      </Col>
                      <Col {...layout}>
                        <Button
                          type='primary'
                          shape='circle'
                          icon='plus'
                          size={24}
                          onClick={() => this.setState({ comps: comps.concat([Date.now()]) })}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <span></span>
                  )}
                  {comps &&
                    comps.map((_, index) => {
                      return (
                        <Row gutter={24}>
                          <Col {...layout1}>
                            <FormItem label=''>
                              {getFieldDecorator(`key${index + 1}`, {
                                rules: [{ required: false, message: '' }],
                              })(
                                <Select
                                  placeholder='配置名称'
                                  showSearch
                                  allowClear
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {allResultData &&
                                    Array.isArray(allResultData) &&
                                    allResultData.map((item) => (
                                      <Option key={`${item.key}`}>
                                        {Array.isArray(item.desc) ? item.desc[0] : ''}
                                      </Option>
                                    ))}
                                </Select>,
                              )}
                            </FormItem>
                          </Col>
                          <Col {...layout1}>
                            <FormItem label='='>
                              {getFieldDecorator(`name${index + 1}`, {
                                rules: [{ required: false, message: '' }],
                              })(<Input placeholder='value' />)}
                            </FormItem>
                          </Col>
                          <Col {...layout}>
                            <Button
                              type='primary'
                              shape='circle'
                              icon='plus'
                              size={24}
                              onClick={() => this.setState({ comps: comps.concat([Date.now()]) })}
                            />
                            <Button type='primary' shape='circle' icon='minus' size={24} />
                          </Col>
                        </Row>
                      );
                    })}
                </Form>
              </Modal>
            )}
            {editVisible && (
              <Modal
                title={this.state.modalTitle}
                visible={editVisible}
                destroyOnClose
                closable
                onOk={this.updateMergeSetSubmit}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: this.state.viewInfo }}
                className='mergeSetModal'
              >
                <Form layout='inline' onSubmit={this.updateMergeSetSubmit}>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='配置名称'>
                        {getFieldDecorator('ruleName', {
                          initialValue: this.state.record.ruleName,
                          rules: [{ required: true, message: '' }],
                        })(<Input placeholder='配置名称' readOnly={this.state.viewInfo} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col {...layout1}>
                      <FormItem label='配置备注'>
                        {getFieldDecorator('ruleRemark', {
                          initialValue: this.state.record.ruleRemark,
                          rules: [{ required: true, message: '' }],
                        })(<Input placeholder='配置备注' readOnly={this.state.viewInfo} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  {this.state.viewInfo ? (
                    <div>
                      {this.state.record.pairList.map((pair, index) => {
                        return (
                          <Row gutter={24} key={index}>
                            <Col {...layout1}>
                              <FormItem label='配置KEY'>
                                {allStallConfigData &&
                                  Array.isArray(allStallConfigData) &&
                                  allStallConfigData.map((item) => {
                                    return pair.key === item.key
                                      ? Array.isArray(item.desc)
                                        ? item.desc[0] + '(' + item.key + ')'
                                        : ''
                                      : '';
                                  })}
                              </FormItem>
                            </Col>
                            <Col {...layout1}>
                              <FormItem label='VALUE'>{pair.value}</FormItem>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  ) : (
                    <div />
                  )}
                </Form>
              </Modal>
            )}
          </div>
        </PageHeaderLayout>
      </div>
    );
  }
}
export default MergeSetList;
