import * as actionTypes from '../constant/login';

//初始状态
const initState = {
  login: {},
};
//reducer
export function login(state = initState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_LOGIN:
      return { ...state, login: action.payload };
    default:
      return state;
  }
}
