//新增网店
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Modal, Row, Col, message, Select, Input } from 'antd';
import { addStore, updateStore } from '../../../state/action/onlineStore';
import { getStallList } from '../../../state/action/basic';
import { marketList } from '../../../state/action/stall';

import '../index.less';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { stall: state.stall, basic: state.basic, onlineStore: state.onlineStore };
  },
  { marketList, getStallList, addStore, updateStore },
)
@Form.create()
class AddOnlineStore extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.isEdit = JSON.stringify(this.props.record) !== '{}' ? true : false;
  }

  async componentDidMount() {
    if (this.isEdit) {
      //编辑的时候
      await this.getStallList({}); //商行名称列表(档口)
    } else {
      await this.getMarketList(); //获得市场列表
    }
  }

  // 获得市场列表
  async getMarketList() {
    await this.props.marketList({});
  }
  // 获取档口列表
  async getStallList(value) {
    await this.props.getStallList(value);
  }
  // 市场选择框的onchange事件
  selectMarketEvent = (value) => {
    let keyCode = { marketId: value };
    this.getStallList(keyCode);
    //清空打商行原先选中的值
    this.props.form.setFieldsValue({ stallId: '' });
  };

  isUndefined = (value) => {
    return value || null;
  };

  isContact = (contactName, contactPhone) => {
    if (contactName && contactPhone) {
      return { contactName, contactPhone };
    } else if (contactName) {
      return { contactName };
    } else if (contactPhone) {
      return { contactPhone };
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { record } = this.props;
        let contactVoList = [];
        const {
          contactName,
          contactName2,
          contactName3,
          contactPhone,
          contactPhone2,
          contactPhone3,
        } = values;
        if (contactName || contactPhone) {
          contactVoList.push(this.isContact(contactName, contactPhone));
        }
        if (contactName2 || contactPhone2) {
          contactVoList.push(this.isContact(contactName2, contactPhone2));
        }
        if (contactName3 || contactPhone3) {
          contactVoList.push(this.isContact(contactName3, contactPhone3));
        }

        const reqObject = {
          stallId: values.stallId,
          storeName: values.name,
          address: values.address,
          mainBusiness: values.mainBusiness,
          contactVoList: contactVoList,
        };

        if (this.isEdit) {
          //编辑
          this.updateStoreFun({ ...reqObject, id: record.id });
        } else {
          //新增
          this.addStoreFun(reqObject);
        }
      }
    });
  };

  async addStoreFun(params) {
    const result = await this.props.addStore(params);
    if (result.code === 200) {
      message.success('新增成功');
      this.props.handleClose(false);
    }
  }

  async updateStoreFun(params) {
    const result = await this.props.updateStore(params);
    if (result.code === 200) {
      message.success('编辑成功');
      this.props.handleClose(false);
    }
  }

  render() {
    const {
      visible,
      content,
      record,
      handleClose,
      basic: { stallList },
      stall: { marketData = [] },
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    // 商行名称列表
    const stallListCur = stallList && stallList.data ? stallList.data : [];
    const newStallListCur = stallListCur || [];

    const stallOption = newStallListCur.map((stall) => (
      <Option value={stall.id} key={stall.id}>
        {stall.name}
      </Option>
    ));
    //联系电话
    let contactName = '',
      contactName2 = '',
      contactName3 = '',
      contactPhone = '',
      contactPhone2 = '',
      contactPhone3 = '';
    if (this.isEdit) {
      contactName = record.contactVoList[0].contactName;
      contactPhone = record.contactVoList[0].contactPhone;
      if (record.contactVoList.length === 2) {
        contactName2 = record.contactVoList[1].contactName;
        contactPhone2 = record.contactVoList[1].contactPhone;
      }
      if (record.contactVoList.length === 3) {
        contactName2 = record.contactVoList[1].contactName;
        contactPhone2 = record.contactVoList[1].contactPhone;
        contactName3 = record.contactVoList[2].contactName;
        contactPhone3 = record.contactVoList[2].contactPhone;
      }
    }
    return (
      <div>
        <Modal
          title={content}
          visible={visible}
          onCancel={() => handleClose()}
          className='modalStyle'
          width='750px'
          footer={null}
        >
          <Form layout='inline' onSubmit={this.handleSubmit}>
            <Row gutter={24}>
              {!this.isEdit ? (
                <Col span={24}>
                  <FormItem label='选择市场' {...formItemLayout}>
                    {getFieldDecorator('marketId', {
                      initialValue: record.marketId || '',
                      rules: [{ required: true, message: '请选择市场' }],
                    })(
                      <Select
                        placeholder='请选择市场'
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={this.isEdit}
                        onChange={this.selectMarketEvent}
                      >
                        {marketData.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              ) : null}
              <Col span={24}>
                <FormItem label='选择商行' {...formItemLayout}>
                  {getFieldDecorator('stallId', {
                    initialValue: record.stallId || null,
                    rules: [{ required: true, message: '请选择商行' }],
                  })(
                    <Select
                      placeholder='请选择商行'
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={this.isEdit}
                    >
                      {stallOption}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label='网店名称' {...formItemLayout}>
                  {getFieldDecorator('name', {
                    initialValue: record.storeName || null,
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 15) {
                              callback('网店名称不超过15个字符');
                            }
                          } else {
                            callback('请输入网店名称');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入网店名称' allowClear />)}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label='档口位置' {...formItemLayout}>
                  {getFieldDecorator('address', {
                    initialValue: record.address || null,
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 20) {
                              callback('档口位置不超过20个字符');
                            }
                          } else {
                            callback('请输入档口位置');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入档口位置' allowClear />)}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label='主营品类' {...formItemLayout}>
                  {getFieldDecorator('mainBusiness', {
                    initialValue: record.mainBusiness || null,
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 30) {
                              callback('主营品类不超过30个字符');
                            }
                          } else {
                            callback('请输入主营品类');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入主营品类' allowClear />)}
                </FormItem>
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <FormItem label='联系电话1' {...formItemLayout}>
                  {getFieldDecorator('contactName', {
                    initialValue: contactName || null,
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 5) {
                              callback('姓名不超过5个字符');
                            }
                          } else {
                            callback('请输入姓名');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入姓名' allowClear />)}
                </FormItem>
                <FormItem label='' {...formItemLayout}>
                  {getFieldDecorator('contactPhone', {
                    initialValue: contactPhone || null,
                    rules: [
                      {
                        required: true,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 11 || !/^[1][0-9]{10}$/.test(value)) {
                              callback('请输入正确的联系方式');
                            }
                          } else {
                            callback('请输入联系方式');
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入联系方式' allowClear />)}
                </FormItem>
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <FormItem label='&nbsp;&nbsp;&nbsp;联系电话2' {...formItemLayout}>
                  {getFieldDecorator('contactName2', {
                    initialValue: contactName2 || null,
                    rules: [
                      {
                        required: false,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 5) {
                              callback('姓名不超过5个字符');
                            }
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入姓名' allowClear />)}
                </FormItem>
                <FormItem label='' {...formItemLayout}>
                  {getFieldDecorator('contactPhone2', {
                    initialValue: contactPhone2 || null,
                    rules: [
                      {
                        required: false,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 11 || !/^[1][0-9]{10}$/.test(value)) {
                              callback('请输入正确的联系方式');
                            }
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入联系方式' allowClear />)}
                </FormItem>
              </Col>
              <Col span={24} style={{ display: 'flex' }}>
                <FormItem label='&nbsp;&nbsp;&nbsp;联系电话3' {...formItemLayout}>
                  {getFieldDecorator('contactName3', {
                    initialValue: contactName3 || null,
                    rules: [
                      {
                        required: false,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 5) {
                              callback('姓名不超过5个字符');
                            }
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入姓名' allowClear />)}
                </FormItem>
                <FormItem label='' {...formItemLayout}>
                  {getFieldDecorator('contactPhone3', {
                    initialValue: contactPhone3 || null,
                    rules: [
                      {
                        required: false,
                        validator: (_, value, callback) => {
                          if (value) {
                            if (value.length > 11 || !/^[1][0-9]{10}$/.test(value)) {
                              callback('请输入正确的联系方式');
                            }
                          }
                          callback();
                        },
                      },
                    ],
                  })(<Input placeholder='请输入联系方式' allowClear />)}
                </FormItem>
              </Col>
            </Row>
            <br />
            <div style={{ textAlign: 'center' }}>
              <Button onClick={() => handleClose()} style={{ marginRight: 12 }}>
                关闭
              </Button>
              <Button type='primary' htmlType='submit'>
                确定
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default AddOnlineStore;
