import request from '../../utils/request';

/**
 * 商行未关联货品列表
 * @param {Object} params
 * @returns
 */
export async function getUnlinkProductUsing(params) {
  return request({
    url: 'productMappingResource/getUnlinkProduct',
    params,
    method: 'post',
    headers: 'json',
  });
}

/**
 * 关联货品弹框 下拉基础货品库列表
 * @param {Object} params
 * @returns
 */
export async function allProducts() {
  return request({ url: 'basicProductResource/allProducts', method: 'get', headers: 'json' });
}

/**
 * 添加货品别名
 * 关联商行货品名称到基础货品
 */
export async function addProductAlias(params) {
  return request({
    url: 'productMappingResource/addProductAlia',
    params,
    method: 'post',
    headers: 'json',
  });
}

/**
 * 基础货品列表
 * @param {Object} params
 * @returns
 */
export async function basicProductList(params) {
  return request({
    url: 'basicProductResource/listProduct',
    params,
    method: 'get',
    headers: 'json',
  });
}

/**
 * 基础货品别名列表
 *
 * @export
 * @param {*} params
 * @return {*}
 */
export async function productMappingAlias(params) {
  // productName，productId，pageSize，pageNo
  let newParams = { ...params };
  const { productId } = newParams;
  delete newParams.productId;
  return request({
    url: `productMappingResource/productAlias/${productId}`,
    params: newParams,
    method: 'get',
    headers: 'json',
  });
}

/**
 * 删除基础货品别名
 *
 * @export
 * @param {*} id
 * @return {*}
 */
export async function productMappingDeleteAlias(id) {
  return request({
    url: 'productMappingResource/deleteProductAlia',
    params: { id },
    method: 'post',
    headers: 'json',
  });
}

//基础货品新增
export async function basicProductAdd({ categoryName, productName }) {
  return request({
    url: 'basicProductResource/add',
    params: { categoryName, productName },
    method: 'post',
    headers: 'json',
  });
}
//基础货品编辑
export async function basicProductEdit({ id, categoryName, productName }) {
  return request({
    url: 'basicProductResource/edit',
    params: { id, categoryName, productName },
    method: 'post',
    headers: 'json',
  });
}
//基础货品所有分类
export async function getAllCategory() {
  return request({
    url: 'basicProductResource/getAllCategory',
    params: null,
    method: 'get',
    headers: 'json',
  });
}
//基础货品删除
export async function basicProductDelete(id) {
  return request({
    url: 'basicProductResource/delete',
    params: { id },
    method: 'post',
    headers: 'json',
  });
}
//基础货品编辑价格范围
export async function editPriceRange({ id, minPrice, maxPrice }) {
  return request({
    url: 'basicProductResource/editPriceRange',
    params: { id, minPrice, maxPrice },
    method: 'post',
    headers: 'json',
  });
}
//市场货品数据列表
export async function getMarketProductList({
  dateStartTime,
  marketId,
  productName,
  pageNo,
  pageSize,
}) {
  return request({
    url: 'marketProductResource/getMarketProductList',
    params: { dateStartTime, marketId, productName, pageNo, pageSize },
    method: 'post',
    headers: 'json',
  });
}
//市场货品数据导出
export async function exportMarketProductList({
  dateStartTime,
  marketId,
  productName,
  pageNo,
  pageSize,
}) {
  return request({
    url: 'marketProductResource/exportMarketProductList',
    params: { dateStartTime, marketId, productName, pageNo, pageSize },
    method: 'post',
    headers: 'json',
    responseType: 'blob',
  });
}
//获取均价详情
export async function getDailyHourPrice({ basicProductId, dateStartTime, marketId }) {
  return request({
    url: 'productPriceStatisticResource/getDailyHourPrice',
    params: { basicProductId, dateStartTime, marketId },
    method: 'post',
    headers: 'json',
  });
}
//货品价格编辑
export async function editPrice({ id, price }) {
  return request({
    url: 'productPriceStatisticResource/editPrice',
    params: { id, price },
    method: 'post',
    headers: 'json',
  });
}
//获取货品时间段内销售明细
export async function getHourSaleDetail({ hourPriceId, pageNo, pageSize }) {
  return request({
    url: `productPriceStatisticResource/hourSaleDetail/${hourPriceId}`,
    params: { pageNo, pageSize },
    method: 'get',
    headers: 'json',
  });
}
