//会员管理,会员服务管理
import request from '../../utils/request';

//会员列表
export async function getListMemebers(params) {
  return request({ url: 'stallMember/listMemebers', params });
}
//新增会员续费记录
export async function addVipPay(params) {
  return request({ url: 'stallMember/addVipPay', params });
}
//新增续费时选中会员服务
export async function selectedProduct(params) {
  return request({ url: 'stallMember/selectedProduct', params });
}
//会员服务列表
export async function listProducts(params) {
  return request({ url: 'stallMember/listProducts', params });
}
//会员续费列表
export async function listVipPay(params) {
  return request({ url: 'stallMember/listVipPay', params });
}
//编辑会员服务
export async function updateMemberServices(params) {
  return request({ url: 'stallMember/updateProduct', params });
}
