import React from 'react';
import moment from 'moment';
import './index.less';

const HtmlPreview = (props) => {
  const { title, createTime, htmlContent = '' } = props || {};
  return (
    <div className='html-preview'>
      <div className='title'>预览</div>
      <div className='txt-title'>{title}</div>
      {createTime ? (
        <div className='time'>{moment(createTime).format('YYYY-MM-DD HH:mm')}</div>
      ) : null}
      <div className='content' dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default HtmlPreview;
