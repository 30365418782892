import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Table, Input, Button, Space, Image, Popconfirm, message, Typography } from 'antd-v5';

import PageHeaderLayout from '../../component/page-header-layout';
import AddNewEditor from './components/AddNewEditor/index';
import { layout, tailLayout, positionEnum } from '../add-services/enum';
import { getAdvertiseList, getAdvertiseEnable } from '../../state/request/serve';

import './index.less';

const { Paragraph } = Typography;

export const status = [
  { label: '启用', value: 1 },
  { label: '禁用', value: 0 },
];

const AdvertisingManagement = (props) => {
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [query, setQuery] = useState({});
  const ref = useRef();

  const getData = useCallback(async () => {
    const res = await getAdvertiseList({ ...query });
    if (res.code === 200) {
      setList(res.data);
    }
  }, [query]);

  useEffect(() => {
    getData();
  }, [getData]);

  // 提交
  const onFinish = (values) => {
    setQuery(values);
  };

  // 停用启用
  const onConfirm = async (record) => {
    const { enable, id } = record;
    await getAdvertiseEnable({ id, enable: !enable });
    const txt = enable ? '禁用' : '启用';
    message.info(`${txt}广告成功`);
    getData();
  };

  const columns = [
    {
      title: '广告名称',
      dataIndex: 'name',
      render: (_, { name, id }) => {
        return (
          <div>
            {name}
            <Paragraph copyable>{`(广告ID: ${id})`}</Paragraph>
          </div>
        );
      },
    },
    {
      title: '广告banner图',
      dataIndex: 'imgUrl',
      render: (url) => url && url.includes('http') && <Image width={100} src={url} />,
    },
    {
      title: '展示位置',
      dataIndex: 'positionEnumList',
      render: (list) => {
        return list
          .map((key) => {
            return positionEnum.find((item) => item.key === key)?.label ?? '';
          })
          .join('-');
      },
    },
    {
      title: '状态',
      dataIndex: 'enable',
      render: (enable) => (
        <span className={`dot ${enable ? 'green' : 'red'}`}>
          {status.find((item) => !!item.value === enable)?.label}
        </span>
      ),
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_, record) => {
        const { enable } = record;
        const txt = enable ? '禁用' : '启用';
        return (
          <Space wrap>
            <Button
              type='link'
              onClick={() => {
                ref.current.open(record);
              }}
            >
              修改
            </Button>
            <Popconfirm title={`是否要${txt}广告`} onConfirm={() => onConfirm(record)}>
              <span className='btn' style={enable ? { color: 'red' } : { color: 'green' }}>
                {txt}
              </span>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const ExtraContent = () => {
    return (
      <Form {...layout} form={form} layout='inline' onFinish={onFinish} className='header'>
        <Space wrap>
          <Form.Item label='广告名称' name='name'>
            <Input placeholder='请输入广告名称' maxLength={20} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type='primary' htmlType='submit'>
                查询
              </Button>
              <Button
                htmlType='button'
                onClick={() => {
                  form.resetFields();
                  setQuery({});
                }}
              >
                重置
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    );
  };

  return (
    <PageHeaderLayout>
      <div className='advertising-management'>
        <ExtraContent />
        <Table
          rowKey='id'
          dataSource={list}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <AddNewEditor ref={ref} refresh={getData} />
      </div>
    </PageHeaderLayout>
  );
};

export default AdvertisingManagement;
