import * as actionTypes from '../constant/mergeSet';

const initState = {
  mergeList: [],
  districtInfo: [],
  marketInfo: [],
  newMarketInfo: [],
  add: [],
};

export function mergeSet(state = initState, action) {
  switch (action.type) {
    case actionTypes.MERGESET_SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
